<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M3.0625 5.3125V13.9375C3.0625 14.7659 3.73407 15.4375 4.5625 15.4375H15.4375C16.2659 15.4375 16.9375 14.7659 16.9375 13.9375V7.5625C16.9375 6.73407 16.2659 6.0625 15.4375 6.0625H10.8028C10.3012 6.0625 9.8329 5.81185 9.5547 5.39455L8.9453 4.48045C8.6671 4.06315 8.19875 3.8125 7.69722 3.8125H4.5625C3.73407 3.8125 3.0625 4.48407 3.0625 5.3125Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'FolderClosedIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>