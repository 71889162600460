<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4.16675 6.83327L6.36201 4.63801C6.62236 4.37766 7.04447 4.37766 7.30482 4.63801L9.50008 6.83327M10.5001 13.1666L12.6953 15.3619C12.9557 15.6222 13.3778 15.6222 13.6382 15.3619L15.8334 13.1666M6.83341 5.33327V15.4999M13.1667 4.49994V14.8333" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'SortIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>