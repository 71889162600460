<template>
  <div
    class="flex h-8 px-2 rounded-full justify-center items-center"
    style="max-width: 120px; border: 1px solid rgba(18, 55, 105, 0.08);"
  >
    <div
      class="rounded-full border border-border-normal h-4 w-4 mr-1.5 transition"
      :style="`background-color: ${color}`"
    />
    <input
      ref="pronunciationInput"
      v-model="color"
      style="width: 61px"
      maxlength="7"
      class="outline-none text-text-loud text-body-sm font-normal"
      @input="onInput"
    >
    <div
      slot="rightIcon"
      class="rounded-full flex items-center justify-center hover:text-secondary-red-100 transition cursor-pointer"
      @click="$emit('remove')"
    >
      <!-- X Icon -->
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.45831 6.45801L13.5416 13.5413M13.5416 6.45801L6.45831 13.5413"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseColorTag',
  props: {
    color: {
      type: String,
      default: '#'
    }
  },
  methods: {
    onInput (e) {
      if (!this.color.startsWith('#')) {
        this.color = '#' + this.color
      }

      this.$emit('input', this.color)
    }
  }
}
</script>

<style>
</style>
