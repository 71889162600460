var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-screen fixed right-0 top-0 w-screen bg-neutral-500 bg-opacity-60",staticStyle:{"z-index":"50000"},on:{"click":_vm.closeDrawer}},[_c('div',{staticClass:"card relative mt-11",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"absolute h-16 w-full top-gradient flex items-start justify-between pt-2"},[_vm._m(0),_c('div',{staticClass:"text-white flex items-center"},[_c('img',{staticClass:"mr-1.5",attrs:{"src":require("../../../assets/icons/magic-stick-top-white.svg")}}),_c('BaseText',{attrs:{"type":"body","size":"sm"}},[_vm._v(" Adding inspiration to "),_c('span',{staticClass:"title-bubble"},[_vm._v("Brief")]),_vm._v(" from "),_c('span',{staticClass:"title-bubble"},[_vm._v("Swipe File")])])],1),_c('div',{staticClass:"mr-5 cursor-pointer",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("../../../assets/icons/close.svg")}})])]),_c('div',{staticClass:"absolute bg-white h-full w-screen top-9 z-30 rounded-t-2xl",staticStyle:{"height":"calc(100vh - 80px)"}},[_c('div',{staticClass:"rounded-t-2xl relative z-50 top-bar flex items-center justify-between p-5"},[_c('BaseInputV2',{attrs:{"value":_vm.textSearch,"placeholder":"Search for inspiration...","icon":""},on:{"input":(newValue) => _vm.newTextSearch(newValue)}},[_c('template',{slot:"icon"},[_c('img',{attrs:{"src":require("../../../assets/icons/search.svg")}})])],2),_c('div',{staticClass:"text-text-muted flex justify-end w-96"},[_c('BaseSelect',{attrs:{"items":[
              { name: 'Newest', rightText: '1 → 9', value: 'desc' },
              { name: 'Oldest', rightText: '9 → 1', value: 'asc' },
            ],"selected":_vm.sortOrder,"item-value":"value","placement":"null","right-icon":""},on:{"update:selected":function($event){_vm.sortOrder=$event},"select":_vm.fetchAdvertisements},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"flex justify-between w-full"},[_c('BaseText',{attrs:{"type":"label","size":"sm"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"text-neutral-500"},[_c('BaseText',{attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(item.rightText)+" ")])],1)],1)]}}])},[_c('BaseText',{attrs:{"slot":"reference","type":"body","size":"sm"},slot:"reference"},[_vm._v(" Sort By ")]),_c('img',{attrs:{"slot":"right-icon","src":require("../../../assets/icons/chevron-down-small.svg")},slot:"right-icon"})],1)],1)],1),_c('div',{staticClass:"px-5 bg-background-disabled overflow-y-scroll scrollbar-hide h-full"},[_c('AdvertisementList',{staticClass:"transition z-0 duration-700 pt-5 pb-52",class:{
            'opacity-100': _vm.showAds,
            'opacity-0': !_vm.showAds
          },attrs:{"loaded":_vm.loadingAdvertisements,"advertisements":_vm.advertisements},on:{"update:loaded":function($event){_vm.loadingAdvertisements=$event},"getMoreAds":() => _vm.getMoreAds({}),"reload":function($event){return _vm.fetchAdvertisements(false)}}}),(_vm.advertisements.length)?_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.getMoreAds}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[(_vm.showAds)?_c('BaseLoadingLogo',{attrs:{"margin":2}}):_vm._e()],1),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]):_vm._e()],1)])]),(_vm.showBanner)?_c('BriefModeBanner',{on:{"reloadBrief":function($event){return _vm.$emit('reloadBrief')},"close":function($event){return _vm.$emit('close')}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"opacity-0 ml-5"},[_c('img',{attrs:{"src":require("../../../assets/icons/close.svg")}})])
}]

export { render, staticRenderFns }