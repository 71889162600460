<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.25 15C9.25 14.5858 9.58579 14.25 10 14.25C10.4142 14.25 10.75 14.5858 10.75 15C10.75 15.4142 10.4142 15.75 10 15.75C9.58579 15.75 9.25 15.4142 9.25 15ZM9.25 10C9.25 9.58579 9.58579 9.25 10 9.25C10.4142 9.25 10.75 9.58579 10.75 10C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 10ZM9.25 5C9.25 4.58579 9.58579 4.25 10 4.25C10.4142 4.25 10.75 4.58579 10.75 5C10.75 5.41421 10.4142 5.75 10 5.75C9.58579 5.75 9.25 5.41421 9.25 5Z" 
    fill="#5E6678" stroke="#5E6678"/>
  </svg>
</template>

<script>
  export default {
    name: 'KebabMenuIcon',
    props: {
      width: {
        type: String,
        default: '20'
      },
      height: {
        type: String,
        default: '20'
      }
    }
  }
</script>

<style scoped></style>