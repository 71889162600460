<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M6.04175 3.95837H4.29175C3.18718 3.95837 2.29175 4.8538 2.29175 5.95837V7.70837M13.9584 3.95837H15.7084C16.813 3.95837 17.7084 4.8538 17.7084 5.95837V7.70837M17.7084 12.2917V14.0417C17.7084 15.1463 16.813 16.0417 15.7084 16.0417H13.9584M6.04175 16.0417H4.29175C3.18718 16.0417 2.29175 15.1463 2.29175 14.0417V12.2917M6.45841 8.12504V11.875M13.5417 8.12504V11.875M10.0001 8.12504V10.2084" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'EmotionalDriverIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>