var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-single-select"},[_c('VuePopper',{ref:"popper",attrs:{"trigger":"clickToOpen","options":{
      placement: 'bottom',
    }}},[_c('div',{staticClass:"base-single-select--dropdown",class:{
        'out-of-bounds': _vm.outOfBounds,
        'out-of-bounds-left': _vm.outOfBoundsLeft,
      },staticStyle:{"z-index":"100000"},style:(_vm.small ? 'width: 120px': 'width: 155px')},[(_vm.items.length)?_c('div',{staticClass:"px-1 py-1"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"flex items-center rounded-r rounded-l px-2 transition duration-200 h-8 select-none",class:{
            'hover:bg-blue-100 hover:text-blue-600': _vm.getTheme === 'blue' && !item.noHighlight,
            'hover:bg-purple-100 hover:text-purple-600': _vm.getTheme === 'purple' && !item.noHighlight,
            'hover:bg-green-100 hover:text-gray-600': _vm.getTheme === 'green' && !item.noHighlight,
          },on:{"click":function($event){return _vm.handleClickItem(item)}}},[_vm._t("item",function(){return [_vm._v(" "+_vm._s(item[_vm.itemText] ? item[_vm.itemText] : item)+" ")]},{"item":item})],2)}),0):_vm._e()]),_c('div',{staticClass:"relative group",attrs:{"slot":"reference"},on:{"click":_vm.closeDrawer},slot:"reference"},[_vm._t("reference")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }