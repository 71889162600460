<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3475_2995)">
      <path
        d="M10.48 3.90833H8.9426L9.45019 2.01519C9.52256 1.74546 9.36263 1.46767 9.09243 1.3953C8.82224 1.32293 8.54435 1.48333 8.47254 1.75306L7.89517 3.90833H4.30117L4.80876 2.01519C4.88113 1.74546 4.7212 1.46767 4.451 1.3953C4.18071 1.32349 3.90347 1.48333 3.83157 1.75306L3.25365 3.90833H1.37117C1.0918 3.90833 0.865153 4.13451 0.865153 4.41435C0.865153 4.69427 1.0918 4.92046 1.37117 4.92046H2.98188L2.16767 7.95674H0.359044C0.0797664 7.95674 -0.146973 8.18292 -0.146973 8.46276C-0.146973 8.74259 0.0797664 8.96877 0.359044 8.96877H1.89646L1.38887 10.8619C1.3165 11.1316 1.47643 11.4094 1.74663 11.4818C1.79018 11.4935 1.83419 11.499 1.87719 11.499C2.1004 11.499 2.30537 11.3502 2.3656 11.124L2.94398 8.96877H6.53789L6.0303 10.8619C5.95802 11.1316 6.11786 11.4094 6.38815 11.4818C6.43161 11.4935 6.47562 11.499 6.51917 11.499C6.74239 11.499 6.94726 11.3502 7.00749 11.124L7.58541 8.96877H9.46789C9.74726 8.96877 9.974 8.74259 9.974 8.46276C9.974 8.18292 9.74726 7.95674 9.46789 7.95674H7.85718L8.67139 4.92046H10.48C10.7594 4.92046 10.986 4.69427 10.986 4.41435C10.986 4.13451 10.7594 3.90833 10.48 3.90833ZM6.80911 7.95674H3.2152L4.0294 4.92046H7.6234L6.80911 7.95674Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3475_2995">
        <rect
          :width="width * 0.93"
          :height="width * 0.93"
          transform="translate(0.226807 0.456909)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'BoardIcon',
  props: {
    selected: {
      type: Boolean
    },
    width: {
      type: Number,
      default: () => 14
    }
  }
}
</script>
<style scoped lang="css"></style>
