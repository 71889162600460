// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get Comments By Ad Id
const getCommentsByAdId = async (adId) => {
  const snapshot = await db.collection('comments').where('adId', '==', adId).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Check if an ad has any comments
const hasComments = async (adId) => {
  const snapshot = await db.collection('comments').where('adId', '==', adId).limit(1).get()
  return !snapshot.empty
}

const create = async (body, adId) => {
  const user = firebase.auth().currentUser

  const { id } = await db.collection('comments').add({ body, adId, created_by: user.uid, created_at: new Date().getTime() })
  const snapshot = await db.collection('comments').doc(id).get()

  return { ...snapshot.data(), id }
}

const remove = async (commentId) => {
  return db.collection('comments').doc(commentId).delete()
}

export default {
  create,
  getCommentsByAdId,
  hasComments,
  remove
}
