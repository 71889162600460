export default {
  namespaced: true,
  state: {
    lastRoute: null
  },
  getters: {
    getLastRoute: state => state.lastRoute
  },
  mutations: {
    SET_LAST_ROUTE (state, routeName) {
      state.lastRoute = routeName
    }
  },
  actions: {}
}
