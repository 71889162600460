var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm:rounded-md"},[_c('div',{staticClass:"bg-white w-full"},[(!_vm.popup)?_c('div',[_c('h2',{staticClass:"text-lg leading-6 font-medium text-gray-900",attrs:{"id":"plan-heading"}},[_vm._v(" Plan ")])]):_vm._e(),(
        (_vm.getSubscriptionState === 'expired' ||
          _vm.getSubscriptionState === 'free' ||
          _vm.getSubscriptionState === 'Inspiration' ||
          _vm.getSubscriptionState === 'Full Workflow') &&
          _vm.step === 1
      )?_c('div',{staticClass:"flex justify-between items-center mt-3 px-6 pb-3"},[_vm._m(0),_c('div',{staticClass:"flex items-center justify-center"},[_c('button',{staticClass:"relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",class:_vm.showAnnual ? 'bg-blue-600' : 'bg-gray-200',attrs:{"type":"button","role":"switch","aria-checked":"false","aria-labelledby":"annual-billing-label"},on:{"click":_vm.changeToShowAnnual}},[_c('span',{staticClass:"pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",class:_vm.showAnnual ? 'translate-x-5' : 'translate-x-0',attrs:{"aria-hidden":"true"}})])])]):_vm._e(),_c('div',{staticClass:"px-6"},[(_vm.step === 1)?_c('div',[(
            _vm.popup ||
              (_vm.getUserSubscription.product &&
                _vm.getUserSubscription.product.name !== 'Full Workflow') ||
              !_vm.getActiveSubscription
          )?_c('div',{staticClass:"flex justify-between flex-col pr-2 mb-2"},_vm._l((_vm.getSortedSubscriptions),function(subscription,index){return _c('SubscriptionCard',{key:index,attrs:{"annual":_vm.showAnnual,"subscription-state":_vm.getSubscriptionState,"subscription":subscription,"selected":subscription.name === _vm.selectedSubscription},nativeOn:{"click":function($event){return _vm.selectSubscription(subscription.name)}}})}),1):_vm._e(),_c('div',{staticClass:"w-full my-1 mt-5 flex justify-center"},[_c('a',{attrs:{"href":"https://foreplay.co/pricing","target":"_blank"}},[_c('BaseButton',{attrs:{"outlined":""}},[_vm._v(" Compare Plans ")])],1)]),(
            _vm.elementsOptions.clientSecret &&
              ['free', 'expired'].includes(_vm.getSubscriptionState)
          )?_c('div',{staticClass:"w-full mb-4",staticStyle:{"max-height":"calc(90vh - 200px)"}},[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"promo-code"}},[_vm._v("Promo Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.promoCode),expression:"promoCode"}],staticClass:"hover:border mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm transition",class:{
              'border-gray-300 cursor-not-allowed': !_vm.selectedSubscription,
              'focus:ring-blue-600 focus:border-blue-600 hover:border-blue-600':
                _vm.selectedSubscription && !_vm.invalidPromoCode,
              'border border-red-400 focus:ring-red-600 focus:border-red-600 hover:border-red-600':
                _vm.invalidPromoCode,
            },attrs:{"id":"promo-code","type":"text","name":"promo-code"},domProps:{"value":(_vm.promoCode)},on:{"input":[function($event){if($event.target.composing)return;_vm.promoCode=$event.target.value},_vm.checkPromoCode]}})]):_vm._e(),_c('div',{staticClass:"flex justify-between mb-3"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('svg',{attrs:{"width":"14","height":"11","viewBox":"0 0 14 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.68756 6.37891L4.50006 9.50391L12.3126 1.37891","stroke":"#14C78C","stroke-width":"1.875","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('div',[_vm._v("Cancel Anytime")])]),_c('div',{staticClass:"flex items-center gap-2"},[_c('svg',{attrs:{"width":"14","height":"11","viewBox":"0 0 14 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.68756 6.37891L4.50006 9.50391L12.3126 1.37891","stroke":"#14C78C","stroke-width":"1.875","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('div',[_vm._v("Unlimited Usage")])]),_c('div',{staticClass:"flex items-center gap-2"},[_c('svg',{attrs:{"width":"14","height":"11","viewBox":"0 0 14 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.68756 6.37891L4.50006 9.50391L12.3126 1.37891","stroke":"#14C78C","stroke-width":"1.875","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('div',[_vm._v("Expert Support")])])])]):_vm._e(),(
          _vm.elementsOptions.clientSecret &&
            ['free', 'expired'].includes(_vm.getSubscriptionState) &&
            _vm.step === 2
        )?_c('StripeElements',{ref:"elms",staticClass:"my-2",attrs:{"stripe-key":_vm.stripeKey,"instance-options":_vm.instanceOptions,"elements-options":_vm.elementsOptions},scopedSlots:_vm._u([{key:"default",fn:function({ elements }){return [_c('StripeElement',{attrs:{"type":"address","elements":elements,"elements-options":_vm.elementsOptions,"options":_vm.addressOptions}}),_c('StripeElement',{attrs:{"type":"payment","elements":elements,"elements-options":_vm.elementsOptions,"options":_vm.paymentOptions}})]}}],null,false,901388053)}):_vm._e(),(
          _vm.elementsOptions.clientSecret &&
            ['free', 'expired'].includes(_vm.getSubscriptionState) &&
            _vm.step === 2
        )?_c('div',{staticClass:"w-1/2 mt-2"},[_c('label',{staticClass:"block text-sm leading-6 text-gray-900 mt-1",attrs:{"for":"taxId"}},[_vm._v("Tax ID")]),_c('input',{ref:"taxId",staticClass:"my-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-100 sm:text-sm sm:leading-6a",attrs:{"type":"text","placeholder":"VAT: 123456789"}})]):_vm._e()],1),_c('div',{staticClass:"flex items-center justify-between border-t pt-4 pl-2 pr-4"},[_c('img',{staticClass:"h-8",attrs:{"src":require("../../assets/images/stripe-logo.png")}}),_c('div',{staticClass:"flex gap-2"},[(_vm.step === 2)?_c('BaseButton',{attrs:{"outlined":""},on:{"click":function($event){return _vm.$emit('changeStep', 1)}}},[_vm._v(" Back ")]):_vm._e(),(_vm.step === 1 && _vm.getSubscriptionState !== 'Inspiration')?_c('BaseButton',{attrs:{"primary":""},on:{"click":_vm.handleStepChange}},[_vm._v(" Next ")]):_vm._e(),(
            ((_vm.getSubscriptionState === 'expired' ||
              _vm.getSubscriptionState === 'free') &&
              _vm.step === 2) ||
              _vm.getSubscriptionState === 'Inspiration'
          )?_c('BaseButton',{attrs:{"primary":"","loading":_vm.loading,"disabled":_vm.loading || !_vm.selectedSubscription},on:{"click":_vm.pay}},[_vm._v(" "+_vm._s(_vm.getSubscriptionState === "Solo" ? "Upgrade" : "Subscribe")+" "+_vm._s(_vm.getPriceText)+" ")]):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-md font-medium text-gray-700 my-2 flex items-center"},[_vm._v(" Pay Annual "),_c('div',{staticClass:"mx-2 h-5 rounded-full bg-gray-300 w-px"}),_c('div',{staticClass:"text-green-500"},[_vm._v(" Save 10% ")])])
}]

export { render, staticRenderFns }