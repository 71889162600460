<template>
  <div class="flex flex-col items-center justify-center w-screen bg-black">
    <div
      class="w-full h-screen card sm:rounded-xl flex flex-col items-center bg-white overflow-scroll scrollbar-hide shadow-sm px-5"
    >
      <form
        v-if="!emailSent"
        class="my-10 w-full"
        @submit.prevent="sendEmailLink"
      >
        <BaseText
          type="label"
          size="lg"
          class="text-text-muted mb-2.5"
        >
          Forgot your password
        </BaseText>
        <BaseText
          type="label"
          size="sm"
          class="text-text-normal mb-10"
        >
          Enter you email to recieve a reset password link
        </BaseText>
        <BaseInputV2
          v-model="email"
          label="Email"
          required="true"
          placeholder="hello@gmail.com"
          class="mb-4"
        />

        <BaseButton
          primary
          class="w-full mb-4"
          :disabled="loadingEmailLink"
          :loading="loadingEmailLink"
          type="submit"
        >
          Send Reset Link
        </BaseButton>
        <router-link
          class="text-primary-blue-100 hover:text-primary-blue-200 underline transition-colors duration-75 ease-out w-max"
          to="/login"
        >
          <BaseText
            type="label"
            size="sm"
          >
            Return to log in
          </BaseText>
        </router-link>
      </form>
      <div
        v-else
        class="my-10 w-full"
      >
        <BaseText
          type="label"
          size="lg"
          class="text-text-muted mb-2.5"
        >
          Forgot your password
        </BaseText>
        <BaseText
          type="label"
          size="sm"
          class="text-text-normal mb-8"
        >
          A link was sent to {{ email }}!
        </BaseText>
        <BaseButton
          primary
          class="w-full text-primary-blue-100"
          @click="$router.push({ name: 'LoginView' })"
        >
          Return to Login
        </BaseButton>
      </div>
    </div>
    <video
      id="9af89768-fe35-2d2e-078f-3e5c86a4b569-video"
      autoplay=""
      loop=""
      style="background-image:url(../../assets/videos/account-background.mp4)"
      muted=""
      playsinline=""
      class="absolute h-screen w-full object-fill"
    ><source
      src="../../assets/videos/account-background.mp4"
    ></video>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'

export default {
  name: 'SignUpView',
  data () {
    return {
      email: null,
      loadingEmailLink: false,
      emailSent: false
    }
  },
  // mounted() {
  //   const { utm_campaign, utm_medium, utm_source } = this.$route.query;
  //   window.analytics.page("Forgot Password", {
  //     pageVariant: "variant-1",
  //     utmCampaign: utm_campaign || "",
  //     utmSource: utm_medium || "",
  //     utmMedium: utm_source || "",
  //   });
  // },
  methods: {
    ...mapActions('AuthModule', ['loginUser']),
    // Send Email Link
    async sendEmailLink () {
      this.loadingEmailLink = true

      try {
        const user = await firebase.auth().sendPasswordResetEmail(this.email)

        this.emailSent = true
      } catch (e) {
        this.$showAlert({
          message: e.message,
          type: 'error'
        })
      } finally {
        this.loadingEmailLink = false
      }
    }
  }
}
</script>

<style scoped>

.card{
  animation: none;
  z-index: 10;
}
@media (min-width: 640px) {
  .card {
    max-width: 470px;
    max-height: 500px;
  }
}
</style>
