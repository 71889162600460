<template>
  <div class="group rounded-lg p-2 flex flex-row gap-3 items-center transition-colors hover:bg-neutral-25">
    <img :src="ad.thumbnail || (ad.cards?.length && ad?.cards[0]?.thumbnail) ||( ad.cards?.length && ad?.cards[0]?.image) || ''"
    alt="" class="w-9 h-9 rounded-md flex-shrink-0 "
    @mouseenter="$emit('thumbnailHovered')" @mouseleave="$emit('thumbnailUnhovered')">
    <img src="../../../assets/icons/video-iconsvg.svg" class="flex-shrink-0 ml-0.5">
    <button v-if="!isPinned" class="group rounded-md p-1.5 items-center justify-center transition-colors hover:bg-neutral-50" 
    @click="$emit('pinAd')">
      <PinIcon class="flex-shrink-0 text-icon-normal transition-colors group-hover:text-icon-muted" />
    </button>
    <button v-else class="flex-shrink-0 group rounded-md p-1.5 items-center justify-center bg-primary-red-10 transition-colors hover:bg-primary-red-25" 
    @click="$emit('unpinAd')" @mouseenter="unpinHovered = true" @mouseleave="unpinHovered = false">
      <PinIcon v-if="!unpinHovered" class="text-primary-red-200" />
      <UnpinIcon v-else class="transition-colors text-primary-red-200 group-hover:text-primary-red-300" />
    </button>
    <div class="relative flex flex-grow h-8 rounded-lg items-center px-3 bg-neutral-25 transition-colors group-hover:bg-neutral-50 min-w-0"
    @mouseenter="isHovered = true" @mouseleave="isHovered = false">
      <BaseText type="body" size="sm" class="text-text-muted whitespace-nowrap truncate"
      :style="{marginRight: isHovered ? '104px' : '8px'}">
        "{{ getHook(ad).trim() }}"
      </BaseText>
      <transition>
        <button v-if="isHovered && !hookCopied"
        class="absolute right-3 flex flex-row gap-1 items-center bg-neutral-50"
        @click="copyToClipboard(getHook(ad));">
          <LayersCopyIcon class="text-icon-normal" />
          <BaseText type="body" size="sm" class="text-text-muted">
            Copy Text
          </BaseText>
        </button>
        <div v-else-if="isHovered && hookCopied"
        class="absolute right-3 flex flex-row gap-1 items-center bg-icon-muted pl-2 pr-4 rounded-lg cursor-default">
          <div class="copied-animation flex flex-row items-center text-xs text-white">
            <img src="../../../assets/icons/new-check.svg" class="w-5 h-5">
            <div>
              Copied!
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="flex-shrink-0 flex flex-row pl-2 items-center gap-2" style="width: 90px">
      <div class="rounded-full w-1.5 h-1.5" :style="{backgroundColor: ad?.live ? '#00A879' : '#DFE1E7'}" />
      <BaseText type="body" size="sm" class="text-text-muted whitespace-nowrap">
        {{ formatDisplayDuration(ad) }}
      </BaseText>
    </div>
    <button class="flex-shrink-0 flex items-center gap-1.5 py-1.5 px-2 rounded-md bg-white button-shadow"
    @mouseenter="detailsHovered = true" @mouseleave="detailsHovered = false"
    @click="$emit('openDetailsDrawer')">
      <AdDetailsAnimatedIcon :width="20" :height="20" :isHovered="detailsHovered" />
      <BaseText type="label" size="sm" class="text-text-muted">
        Ad Details
      </BaseText>
    </button>
  </div>
</template>

<script>
import { getHook } from '../../../utils/adFunctions'
import { mapGetters } from 'vuex'
import moment from 'moment'

// Icons
import PinIcon from '../../globals/Icons/SpyderV2Icons/PinIcon.vue'
import UnpinIcon from '../../globals/Icons/SpyderV2Icons/UnpinIcon.vue'
import AdDetailsAnimatedIcon from '../../globals/Icons/AdDetailsAnimatedIcon.vue'
import LayersCopyIcon from '../../globals/Icons/LayersCopyIcon.vue'

export default {
  name: 'AdvertisementHookRow',
  components: {
    PinIcon,
    UnpinIcon,
    AdDetailsAnimatedIcon,
    LayersCopyIcon
  },
  props: {
    ad: {
      type: Object,
      required: true
    },
    isPinned: {
      type: Boolean,
      required: true
    },
    pinnedAds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isHovered: false,
      hookCopied: false,
      unpinHovered: false,
      detailsHovered: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser'])
  },
  methods: {
    getHook(ad) {
      return getHook(ad)
    },
    copyToClipboard () {
      navigator.clipboard.writeText(getHook(this.ad).trim())
      this.hookCopied = true
      setTimeout(() => {
        this.hookCopied = false
      }, 2000)
    },
    formatRunningDuration (start, end) {
      let endTime
      if (end && !isNaN(end._seconds)) {
        endTime = end._seconds * 1000
      } else {
        endTime = new Date().getTime()
      }
      // eslint-disable-next-line new-cap
      const startDate = new moment.utc(start)
      // eslint-disable-next-line new-cap
      const endDate = new moment.utc(endTime)

      return Math.ceil(moment.duration(endDate.diff(startDate)).as('days'))
    },
    formatDisplayDuration (ad) {
      const runningDuration = this.formatRunningDuration(ad?.startedRunning, ad?.lastChecked || ad?.last_checked)
      if (runningDuration < 0) {
        return 'N/A'
      }
      const displayDays = runningDuration === 0 ? '<1' : runningDuration
      const daysText = this.formatRunningDuration(
        ad?.startedRunning,
        ad?.live ? new Date().getTime() : ad?.lastChecked || ad?.last_checked
      ) > 1 ? 'Days' : 'Day'

      return `${displayDays} ${daysText}`
    }
  }
}
</script>

<style scoped>
.button-shadow {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>