<template>
  <!-- Manage Board Modal -->
  <form @submit.prevent="updateFolder">
    <BaseModal @close="$emit('close')">
      <template #toolbar>
        <div class="bg-white flex w-full rounded-md gap-x-3 -mb-3">
          <img
            src="../../assets/icons/new-folder-icon.svg"
            alt=""
          >
          <!-- Right Side -->
          <div
            class="transform -translate-y-0.5"
          >
            <div class="text-lg font-semibold">
              Edit Folder
            </div>
            <div
              class="text-sm text-gray-500"
            >
              Folders keep your boards organized
            </div>
          </div>
        </div>
      </template>

      <template #default>
        <div class="border-t border-border-normal">
          <BaseInputV2
            v-model="folderName"
            label="Folder Name"
            class="my-5 mx-5"
          />

          <div
            class="pt-5 border-t border-border-normal"
          >
            <div class="flex">
              <BaseButton
                primary
                :loading="loading"
                class="ml-auto mr-5"
                type="submit"
              >
                Save
              </BaseButton>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'ManageFolderModal',
  props: {
    folder: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      folderName: '',
      loadingDelete: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser'])
  },
  mounted () {
    this.folderName = this.folder.name
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchFolders']),
    // Handle Delete Folder
    async deleteFolder () {
      // Make sure the folder being deleted isn't an experts folder
      if (this.getUser.expertProfile?.selectedFolder === this.folder.id) {
        this.$showAlert({
          message: 'This is an experts folder, change your experts folder before deleting this',
          type: 'error'
        })

        return
      }

      this.loadingDelete = true

      await FirebaseAPI.Folders.remove(this.folder.id)

      // Refetch the boards and folders for the sidebar
      this.fetchFolders()

      // finished actions
      this.loadingDelete = false
      this.$emit('close')
    },
    async updateFolder () {
      this.loading = true

      const folderPayload = {
        name: this.folderName
      }

      await FirebaseAPI.Folders.update(this.folder.id, folderPayload)

      // Refetch the boards and folders for the sidebar
      this.fetchFolders()

      // finished actions
      this.loading = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
