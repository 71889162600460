var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VuePopper',{style:(`z-index: ${20000 + _vm.index}`),attrs:{"trigger":"hover","visible-arrow":true,"options":{
    placement: _vm.placement,
    modifiers: {
      strategy: 'fixed',
      offset: {
        offset: _vm.offset
      }
    }
  },"enter-active-class":"z-10"}},[_c('div',[_c('div',{staticClass:"popper relative border-none text-white rounded-md",class:[!_vm.noBackground ? (_vm.dark ? 'bg-gray-900' : 'bg-gray-500') : 'bg-transparent', _vm.noShadow ? 'shadow-none' : 'shadow-lg', {'hidden' : _vm.hidePopper }]},[_vm._t("content")],2)]),_c('div',{attrs:{"slot":"reference"},slot:"reference"},[_vm._t("reference")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }