// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get All By User
const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('folders').where('createdBy', '==', user.uid).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get By BriefId
const getByBriefId = async (briefId) => {
  const snapshot = await db.collection('folders').where('briefIds', 'array-contains', briefId).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By User
const getAllByTeam = async (teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('folders').where('teamId', '==', teamId).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get By ID
const getByID = async (folderId) => {
  const folder = await db.collection('folders').doc(folderId).get()

  return {
    id: folder.id,
    ...folder.data()
  }
}
// Create a Folder
const create = (payload) => {
  const user = firebase.auth().currentUser

  return db
    .collection('folders')
    .add({ ...payload, createdBy: user.uid })
}

// Update Folder
const update = async (folderId, payload) => {
  return await db
    .collection('folders')
    .doc(folderId)
    .update(payload)
}

// Remove/Delete Folder
const remove = async (folderId) => {
  // Add search for board
  // await Search.removeBoard(boardId)
  return db.collection('folders').doc(folderId).delete()
}

// Create sample board with sample ads in it (Only on initial signup)
//   Doesn't work with teams yet
export default {
  create,
  getAllByTeam,
  getAllByUser,
  getByBriefId,
  getByID,
  remove,
  update
}
