<template>
  <div class="p-4">
    <!-- Top Text -->
    <div>
      <!-- Heading -->
      <div class="flex items-center justify-start text-gray-600 text-lg">
        <div class="text-yellow-400 mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              fill-rule="evenodd"
              d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clip-rule="evenodd"
            />
          </svg>
        </div>

        Attention - Legacy Feature
      </div>
      <div class="text-gray-400 text-sm mt-2">
        Saving via ad link is a legacy feature and is not as reliable as saving
        with out chrome extension. This method ONLY works for Facebook Ad
        Library.
      </div>
    </div>
    <!-- Board Dropdown -->
    <BaseSingleSelect
      :selected.sync="selectedBoards"
      icon
      :items="getBoards"
      multiple
      item-value="name"
      class="mt-2"
    >
      <template #icon>
        Board&nbsp;&nbsp;
      </template>

      <template #item="{ item }">
        {{ item.name }}
      </template>

      <template #selection="{ item }">
        {{ item.name }}
      </template>
    </BaseSingleSelect>

    <!-- Link Inputs -->
    <div
      v-for="(ad, index) in ads"
      :key="index"
      class="mt-2"
    >
      <BaseInput
        v-model="ad.value"
        icon
        placeholder="facebook.com/ads/library/?id=?????"
      >
        <template #icon>
          https://
        </template>
      </BaseInput>
    </div>

    <!-- Error Link -->
    <div
      v-if="$v.ads.$invalid"
      class="text-red-500 my-5"
    >
      One or more of the links are not valid Facebook Ad Library Links. For
      saving TikToks please use the chrome extension
    </div>

    <BaseButton
      primary
      class="mt-4 ml-auto"
      :loading="loadingSave"
      :disabled="
        loadingSave ||
          !ads.map((e) => e.value).filter((e) => e).length ||
          adBlockedDetected ||
          $v.ads.$invalid
      "
      @click="handleSaveAds"
    >
      Save Ad
    </BaseButton>
  </div>
</template>
<script>
import AdsAPI from '../../../api'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import detectAdBlock from '../../../utils/adblock.js'

const doesURLHaveAnID = (value) =>
  !value ||
  /^(http[s]?:\/\/)?((www|[a-zA-Z]{2}-[a-zA-Z]{2})\.)?facebook\.com\/(ads\/library)\b[/]?[?&]id=[0-9]+/.test(
    value
  )

export default {
  name: 'CreateAdvertisementModal',
  props: {
    currentBoard: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      adBlockedDetected: false,
      loadingSave: false,
      selectedBoards: [],
      ads: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }]
    }
  },
  validations: {
    ads: {
      $each: {
        value: {
          doesURLHaveAnID
        }
      }
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['isFreeTier', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards']),
    ...mapGetters('AdvertisementsModule', [
      'areAdsBeingSaved',
      'getAdsBeingSaved'
    ])
  },
  async mounted () {
    this.detectAdBlock().then((detected) => {
      if (detected) {
        this.adBlockedDetected = true
      }
    })

    // await this.fetchBoards()

    if (this.currentBoard) {
      this.selectedBoards.push(
        this.getBoards.find((board) => board.id === this.currentBoard)
      )
    }
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards']),
    ...mapMutations('AdvertisementsModule', [
      'SET_ADS_BEING_SAVED',
      'SET_AD_STATUS'
    ]),
    detectAdBlock,
    // Format ad to save
    formatAdToSave (ad) {
      const payload = {
        ad_id: ad.ad_ID,
        avatar: ad.page_profile_picture_url || '',
        board_ids: this.selectedBoards.map((e) => e.id),
        categories: ad.page_categories ? Object.values(ad.page_categories) : [],
        cta_type: ad.cta_type || '',
        display_format: ad.display_format,
        name: ad.page_name || '',
        likes: ad.page_like_count || 0,
        link_url: ad.link_url || '',
        publisher_platform: ad.publisherPlatform || [],
        createdAt: new Date().getTime()
      }

      if (ad.display_format === 'carousel') {
        payload.type = 'carousel'
        payload.description = ad.html

        for (let j = 0; j < ad.cards.length; j++) {
          payload.cards = ad.cards.map((card) => {
            const newCard = {
              title: card.title,
              description: card.html || ''
            }

            if (card.images.length) {
              newCard.type = 'image'
              newCard.image = card.images[0].img_src
            } else if (card.videos.length) {
              newCard.type = 'video'
              newCard.video = card.videos[0].location
            }
            return newCard
          })
        }
      } else if (!ad.error && ad.cards) {
        if (ad.cards[0].videos.length) {
          payload.type = 'video'
          payload.video = ad.cards[0].videos[0].location
          payload.description = ad.cards[0].html
        }

        if (ad.cards[0].images.length) {
          payload.type = 'image'
          payload.image = ad.cards[0].images[0].img_src
          payload.description = ad.cards[0].html
        }
      }

      return payload
    },
    // Handle Save Ads
    async handleSaveAds () {
      const user = firebase.auth().currentUser
      const adsToSave = this.ads.reduce((ads, ad) => {
        if (!ad.value) {
          return ads
        }

        const adId = ad.value.split('?id=')[1]

        const isDuplicate = ads.some((a) => a.value.split('?id=')[1] === adId)
        const isAlreadySaving = this.getAdsBeingSaved.some(
          (a) => a.ad_id === adId
        )

        if (isDuplicate || isAlreadySaving) {
          return ads
        }

        return [...ads, ad]
      }, [])

      this.loadingSave = true

      const mappedAds = adsToSave.map((ad) => {
        return {
          ad_id: ad.value.split('?id=')[1],
          status: 'saving',
          saveRouteName: this.$route.name,
          saveRouteParams: this.$route.params
        }
      })

      this.SET_ADS_BEING_SAVED(
        this.getAdsBeingSaved
          ? mappedAds.concat(this.getAdsBeingSaved)
          : mappedAds
      )

      for (let i = 0; i < adsToSave.length; i++) {
        AdsAPI.Ads.fetchAdvertisement(adsToSave[i].value)
          .then(async (result) => {
            const adToSave = this.formatAdToSave(result)

            if (adToSave.type) {
              const adPayload = {
                ...adToSave,
                needsScreenshot: true,
                created_by: user.uid
              }

              if (this.getTeam) {
                adPayload.teamId = this.getTeam.id
              }

              const adData = await FirebaseAPI.Advertisements.create(adPayload)

              await AdsAPI.Ads.createFiltersAndBrandData(adData.id)

              this.SET_AD_STATUS({
                ad_id: adToSave.ad_id,
                status: 'success'
              })

              window.Intercom('trackEvent', 'Saved Ad', {
                ad_type: adToSave.type,
                publisher_platform: adToSave.publisherPlatform?.join(','),
                name: adToSave.page_name || '',
                likes: adToSave.page_like_count || 0
              })
            } else {
              this.SET_AD_STATUS({
                ad_id: adToSave.ad_id,
                status: 'warning'
              })
            }
          })
          .catch((result) => {
            this.SET_AD_STATUS({
              ad_id: result ? result.split('?id=')[1] : result,
              status: 'fail'
            })
          })
      }

      this.$emit('close')

      this.loadingSave = false
    }
  }
}
</script>
