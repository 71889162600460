<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 11.993 2.92548 15.3027 6.75 15.9028V10.3125H4.71875V8H6.75V6.2375C6.75 4.2325 7.94434 3.125 9.77172 3.125C10.647 3.125 11.5625 3.28125 11.5625 3.28125V5.25H10.5537C9.55992 5.25 9.25 5.86667 9.25 6.49933V8H11.4688L11.1141 10.3125H9.25V15.9028C13.0745 15.3027 16 11.993 16 8Z" 
    fill="#1877F2"/>
  </svg>
</template>

<script>
  export default {
    name: 'FacebookPlatformIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>