<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="width" viewBox="0 0 20 20" fill="none">
    <path d="M3.8125 8.3125H16.1875M6.8125 4.5625V3.0625M13.1875 4.5625V3.0625M5.3125 16.1875H14.6875C15.5159 16.1875 16.1875 15.5159 16.1875 14.6875V6.0625C16.1875 5.23407 15.5159 4.5625 14.6875 4.5625H5.3125C4.48407 4.5625 3.8125 5.23407 3.8125 6.0625V14.6875C3.8125 15.5159 4.48407 16.1875 5.3125 16.1875Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'CalendarIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>