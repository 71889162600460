// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Create a searchable field
const create = async (payload, teamId) => {
  const user = firebase.auth().currentUser

  const visibleBy = [user.uid]

  if (teamId) {
    visibleBy.push(`group/${teamId}`)
  }

  await db.collection('search').doc().set({ ...payload, visible_by: visibleBy })
}

const shouldBrandBeDeleted = async (ad, teamId) => {
  // Check for other ads with the same brandId
  let createdByCondition = teamId ? ['teamId', '==', teamId] : ['created_by', '==', ad.created_by]

  const adSnapshot = await db.collection('ads')
    .where('brandId', '==', ad.brandId)
    .where(...createdByCondition)
    .get()

  if (!adSnapshot.empty) return

  // Because of the different casing
  createdByCondition = teamId ? ['teamId', '==', teamId] : ['createdBy', '==', ad.created_by]

  // If there are no other ads with the same brandId
  // Get the user brand
  const userBrandSnapshot = await db.collection('user-brands')
    .where('brandId', '==', ad.brandId)
    .where(...createdByCondition)
    .get()

  const userBrandId = userBrandSnapshot.docs[0].id

  // Delete the user brand
  await db.collection('user-brands').doc(userBrandId).delete()

  // Get the search item for the user brand
  const searchSnapshot = await db.collection('search')
    .where('id', '==', userBrandId)
    .get()

  // Delete the user brand search item
  await db.collection('search').doc(searchSnapshot.docs[0].id).delete()
}

// BOARDS SPECIFIC

// Updates a board in the search collection
const updateBoard = async (boardId, payload) => {
  const snapshot = await db.collection('search').where('id', '==', boardId).get()

  return await db
    .collection('search')
    .doc(snapshot.docs[0].id)
    .update(payload)
}

// Deletes a board from the seach collection
const removeBoard = async (boardId) => {
  const snapshot = await db.collection('search').where('id', '==', boardId).get()
  if (snapshot.empty) return

  return db.collection('search').doc(snapshot.docs[0].id).delete()
}

export default {
  create,
  shouldBrandBeDeleted,
  removeBoard,
  updateBoard
}
