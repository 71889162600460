<template>
  <BaseCard
    v-if="storyBoardInformation.length > 0"
    class="w-96 h-max mt-5 rounded-xl bg-white shadow-sm custom-width"
    border
  >
    <div
      class="flex justify-between"
      :class="storyBoardExpanded ? 'border-b border-neutral-50 border-opacity-80' : '' "
    >
      <div
        class="py-4 px-6 rounded-full flex"
      >
        <img
          src="../../../assets/icons/layout-icon.svg"
          alt=""
          class=" w-10 h-10 rounded-full p-2.5 overflow-visible"
          style="box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
        >
        <div
          class="w-full ml-3 flex items-center"
        >
          <BaseText
            type="label"
            size="md"
            class="text-text-muted"
          >
            Storyboard
          </BaseText>
        </div>
      </div>
      <img
        src="../../../assets/icons/chevron-down-small.svg"
        alt=""
        :class="storyBoardExpanded ? 'rotate-180 transform' : ''"
        class="w-5 mr-5 transition-transform duration-300 cursor-pointer"
        @click="toggleExpanded()"
      >
    </div>
    <div
      v-if="storyBoardExpanded"
      class="gap-2 grid sm:grid-cols-2"
      :class="storyBoardInformation.length === 0 ? '' : 'm-2'"
    >
      <BaseCard
        v-for="(script, index) in storyBoardInformation"
        :key="index"
        class="h-max rounded-lg"
        border
      >
        <div
          class="p-3 flex items-center gap-x-2.5 border-b border-neutral-50 border-opacity-80"
        >
          <BaseText
            tyle="label"
            size="sm"
            class="text-text-muted p-3 rounded-full border w-6 h-6 flex justify-center items-center"
          >
            {{ index + 1 }}
          </BaseText>
          <BaseText
            v-if="script.scene.length"
            tyle="heading"
            size="overline"
            class="uppercase"
          >
            {{ script.scene[0].title }}
          </BaseText>
        </div>
        <div
          v-if="script.reference?.length"
          class="p-4 flex justify-center items-center"
        >
          <video
            v-if="script.reference[0].format === 'video/mp4' || script.reference[0].format === 'video/quicktime'"
            class="w-full h-auto rounded-md"
            controls
          >
            <source
              :src="script.reference[0].path"
              type="video/mp4"
            >
            Your browser does not support the video tag.
          </video>
          <img
            v-else
            :src="script.reference[0].path"
            alt=""
            class="rounded-md "
          >
        </div>
        <div
          v-if="script.copy"
          class="px-4 py-2 flex items-center flex-wrap gap-y-1.5"
        >
          <img
            src="../../../assets/icons/voice-icon.svg"
            alt=""
          >
          <BaseText
            tyle="label"
            size="xs"
            class="text-text-muted ml-2"
          >
            Script Copy
          </BaseText>
          <BaseText
            tyle="body"
            size="xs"
            class="text-text-normal w-full"
          >
            <BaseContentEditable
              disabled
              no-padding
              :current-value="script.copy"
            />
          </BaseText>
        </div>
        <div
          v-if="script.visuals"
          class="px-4 py-2 flex items-center flex-wrap gap-y-1.5"
        >
          <img
            src="../../../assets/icons/bolt-icon.svg"
            alt=""
          >
          <BaseText
            tyle="label"
            size="xs"
            class="text-text-muted ml-2"
          >
            Action Description
          </BaseText>
          <BaseText
            tyle="body"
            size="xs"
            class="text-text-normal w-full"
          >
            <BaseContentEditable
              disabled
              no-padding
              :current-value="script.visuals"
            />
          </BaseText>
        </div>
        <div
          v-if="script.overlay"
          class="px-4 py-2 mb-2 flex items-center flex-wrap gap-y-1.5"
        >
          <img
            src="../../../assets/icons/typography-icon.svg"
            alt=""
          >
          <BaseText
            tyle="label"
            size="xs"
            class="text-text-muted ml-2"
          >
            Text Overlay
          </BaseText>
          <BaseText
            tyle="body"
            size="xs"
            class="text-text-normal w-full"
          >
            <BaseContentEditable
              disabled
              no-padding
              :current-value="script.overlay"
            />
          </BaseText>
        </div>
      </BaseCard>
    </div>
  </basecard>
</template>

<script>
export default {
  name: 'StoryBoard',
  props: {
    storyBoardExpanded: Boolean,
    storyBoardInformation: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    toggleExpanded () {
      if (this.storyBoardExpanded) this.$emit('update:storyBoardExpanded', false)
      else this.$emit('update:storyBoardExpanded', true)
    }
  }
}
</script>

<style scoped>
.custom-width {
  width: 95%;
}

@media (min-width: 640px) {
  .custom-width {
    width: 584px;
  }
}
</style>
