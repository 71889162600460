<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10M16.75 10C16.75 6.27208 13.728 3.25 10 3.25C6.27208 3.25 3.25 6.27208 3.25 10M16.75 10H12.25M3.25 10H7.75M12.25 10C12.25 11.2427 11.2427 12.25 10 12.25C8.75732 12.25 7.75 11.2427 7.75 10M12.25 10C12.25 8.75732 11.2427 7.75 10 7.75C8.75732 7.75 7.75 8.75732 7.75 10" 
    :stroke="stroke" stroke-width="1.5"/>
  </svg>
</template>

<script>
  export default {
    name: 'KidsNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>