<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M11.1632 11.3379L13.6459 8.85517V13.002C13.6459 14.6676 12.2957 16.0179 10.63 16.0179C8.96442 16.0179 7.61417 14.6676 7.61417 13.002L7.61417 8.0688M7.61417 8.0688C8.65518 8.0688 9.49909 7.22489 9.49909 6.18388C9.49909 5.14286 8.65518 4.29895 7.61417 4.29895C6.57316 4.29895 5.72925 5.14286 5.72925 6.18388C5.72925 7.22489 6.57316 8.0688 7.61417 8.0688Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'HooksIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>