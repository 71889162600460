<template>
  <!-- Board Card -->
  <div class="board-card">
    <div class="text-center">
      <!-- Board Name -->
      <div class="board-card--title">
        {{ board.name }}
      </div>

      <!-- Board Description -->
      <div class="board-card--description">
        {{ board.description }}
      </div>
    </div>

    <!-- View Ads -->
    <BaseButton
      outlined
      @click="$router.push({ name: 'BoardsDetailsView', params: { id: board.id, board: board }})"
    >
      View ads
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: 'BoardCard',
  props: {
    board: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="sass">
.board-card
  background-color: #f9fafb
  height: 300px
  @apply border border-gray-300 flex flex-col items-center justify-between py-8 rounded

  &--title
    @apply font-medium my-4 text-2xl

  &--description
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
    width: 200px
    @apply text-gray-500 mx-auto
</style>
