export default {
  templates: {
    Standard: [
      {
        title: 'Hook'
      },
      {
        title: 'Problem'
      },
      {
        title: 'Benefits'
      },
      {
        title: 'Demo'
      },
      {
        title: 'Features'
      },
      {
        title: 'Social Proof'
      },
      {
        title: 'CTA'
      }
    ],
    AIDA: [
      {
        title: 'Attention'
      },
      {
        title: 'Interest'
      },
      {
        title: 'Desire'
      },
      {
        title: 'Action'
      }
    ],
    PAS: [
      {
        title: 'Problem'
      },
      {
        title: 'Agitate'
      },
      {
        title: 'Solution'
      }
    ],
    // 'Still Image': [
    //   {
    //     title: 'Headline'
    //   },
    //   {
    //     title: 'Copy'
    //   },
    //   {
    //     title: 'CTA'
    //   }
    // ],
    'Four Ps': [
      {
        title: 'Problem'
      },
      {
        title: 'Promise'
      },
      {
        title: 'Proof'
      },
      {
        title: 'Proposal'
      }
    ]
  },
  selectOptions: [
    {
      title: 'Attention'
    },
    {
      title: 'Interest'
    },
    {
      title: 'Desire'
    },
    {
      title: 'Action'
    },
    {
      title: 'Hook'
    },
    {
      title: 'Problem'
    },
    {
      title: 'Benefits'
    },
    {
      title: 'Demo'
    },
    {
      title: 'Features'
    },
    {
      title: 'Social Proof'
    },
    {
      title: 'CTA'
    },
    {
      title: 'Problem'
    },
    {
      title: 'Agitate'
    },
    {
      title: 'Solution'
    },
    {
      title: 'Headline'
    },
    {
      title: 'Copy'
    },
    {
      title: 'Promise'
    },
    {
      title: 'Proof'
    },
    {
      title: 'Proposal'
    }
  ]
}
