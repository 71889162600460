<template>
  <BaseModal @close="$emit('close')" :cardWidth="spyderLimit < 50 ? '980px': '520px'" noToolbar noToolbarPadding noPadding>
    <div class="relative flex w-full">
      <button class="absolute top-3 right-3 p-1.5" @click="$emit('close')">
        <TagRemoveIcon stroke="#5E6678" />
      </button>
      <div class="flex flex-col border-border-normal p-5" style="width: 525px" :class="{'border-r': spyderLimit < 50}">
        <div class="flex flex-col gap-5 mb-5">
          <img src="../../assets/icons/spyder-token.png" class="w-10 h-10">
          <div>
            <BaseText type="heading" size="md" class="text-text-loud mb-2 w-full">
              Get Additional Spyder Credits
            </BaseText>
            <BaseText type="body" size="sm" class="text-text-normal">
              Track and store more competitor data
            </BaseText>
          </div>
        </div>
        <div class="flex items-center justify-between p-4 mt-2 rounded-xl bg-neutral-10">
          <div>
            <BaseText type="heading" size="sm" class="text-text-loud mb-1 w-full whitespace-nowrap">
              You currently have {{ spyderLimit }} {{ spyderLimit <= 2 ? 'complementary' : 'Spyder' }} credits
            </BaseText>
            <BaseText v-if="spyderLimit < 50" type="body" size="sm" class="text-text-normal">
              {{ spyderLimit <= 2
                ? 'Included with your current subscription.'
                : 'Add more credits to track more competitors.'
              }}
            </BaseText>
            <BaseText v-else type="body" size="sm" class="text-text-normal">
              To add more credits, 
              <a class="font-medium text-primary-red-200 transition-colors hover:text-primary-red-100 cursor-pointer"
              @click="showIntercom">
                contact support
              </a>
            </BaseText>
          </div>
          <div class="flex items-center gap-1.5 bg-white pl-0.5 py-0.5 pr-3 rounded-full cursor-default 
          border flex-shrink-0 border-border-normal">
            <img v-if="currentSubCount > 0" src="../../assets/icons/spyder-token.png" class="w-7 h-7">
            <img v-else src="../../assets/icons/spyder-token-disabled.png" class="w-7 h-7" >
            <BaseText type="label" size="sm" class="text-text-muted">
              {{ currentSubCount }}/{{ spyderLimit }} credits
            </BaseText>
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-grow" v-if="spyderLimit < 50">
        <div class="w-full p-5 border-b border-border-normal">
          <BaseText type="heading" size="sm" class="text-text-loud">
            Additional Spyder Packages
          </BaseText>
        </div>
        <button v-for="(brandPackage, index) in availableSpyderPackages" :key="`package-${index}`"
        class="flex gap-3 w-full p-4 border-b border-border-normal transition-colors hover:bg-neutral-10"
        @click="selectedPackage = brandPackage">
          <div class="p-1">
            <div class="radio-button" :class="{'selected': selectedPackage?.brandCount === brandPackage.brandCount}">
              <div class="radio-button-inner" />
            </div>
          </div>
          <div class="flex flex-col gap-1.5 flex-grow">
            <div class="flex items-center gap-2">
              <img src="../../assets/icons/spyder-token.png" class="w-5 h-5">
              <BaseText type="heading" size="md" class="text-text-muted">
                {{ brandPackage.brandCount }}
              </BaseText>
              <BaseText type="body" size="md" class="text-text-normal">
                Brands
              </BaseText>
            </div>
            <BaseText type="label" size="md" class="text-text-muted text-left">
              ${{ brandPackage.monthlyPrice }}<span class="text-text-normal font-normal">/month</span>
            </BaseText>
          </div>
          <div class="flex flex-col gap-1.5">
            <BaseText type="label" size="sm" class="text-text-muted text-right">
              ${{ brandPackage.monthlyPricePerBrand.toFixed(2) }}<span class="text-text-normal font-normal">/brand</span>
            </BaseText>
            <div class="px-2.5 py-1 rounded-full border"
            :class="brandPackage.savingsPercent === 0 
              ? 'border-border-normal text-text-normal' 
              : 'border-transparent bg-primary-green-10 text-primary-green-200'"
            >
              <BaseText type="label" size="sm" class="font-medium">
                Save {{ brandPackage.savingsPercent === 0 ? `$0` : `~${brandPackage.savingsPercent}%` }}
              </BaseText>
            </div>
          </div>
        </button>
        <div class="flex p-5 mt-auto">
          <BaseButton primary class="ml-auto" :disabled="!selectedPackage" :loading="purchaseLoading"
          @click="purchasePackage">
            Add Spyders ${{ selectedPackage?.monthlyPrice || '--' }}/month
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue';
import API from '@/api'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BuySpyderCreditsModal',
  components: {
    TagRemoveIcon
  },
  props: {
    currentSubCount: {
      type: Number,
      default: 2
    },
    spyderLimit: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getTeam', 'getStripeCustomer', 'getUserSubscription', 'getUserFreeTrialDate', 'isFreeTier', 'getTeamSubscriptionState']),
    availableSpyderPackages () {
      return this.spyderPackages.filter(p => p.brandCount > this.spyderLimit)
    }
  },
  data () {
    return {
      selectedPackage: null,
      purchaseLoading: false,
      spyderPackages: [
        {
          brandCount: 10,
          monthlyPrice: 99,
          monthlyPricePerBrand: 9.99,
          savingsPercent: 0,
          monthlyPriceId: 'price_1NpudLAQtq0TW7KZbBqLvDiO',
          yearlyPriceId: 'price_1PMXJqAQtq0TW7KZFrYjI0Dn'
        },
        {
          brandCount: 20,
          monthlyPrice: 149,
          monthlyPricePerBrand: 7.5,
          savingsPercent: 25,
          monthlyPriceId: 'price_1Npue6AQtq0TW7KZblNmFnGu',
          yearlyPriceId: 'price_1PMXLBAQtq0TW7KZsLTwDRFn'
        },
        {
          brandCount: 30,
          monthlyPrice: 199,
          monthlyPricePerBrand: 6.63,
          savingsPercent: 30,
          monthlyPriceId: 'price_1NpufDAQtq0TW7KZ6c4ndclX',
          yearlyPriceId: 'price_1PMXMIAQtq0TW7KZiKxiCJgc'
        },
        {
          brandCount: 50,
          monthlyPrice: 249,
          monthlyPricePerBrand: 4.98,
          savingsPercent: 50,
          monthlyPriceId: 'price_1NpuheAQtq0TW7KZpK9e6t06',
          yearlyPriceId: 'price_1PMXj5AQtq0TW7KZj9duxBru'
        }
      ]
    }
  },
  methods: {
    ...mapActions('SpyderModule', ['fetchSpyderLimit']),
    ...mapActions('AuthModule', ['setUserSubscription']),
    async purchasePackage () {
      this.purchaseLoading = true
      // TODO: Jakeyyyy, JAKEYYYYY, JAKE. This is where you would call the API to purchase the package

      // Check if this mf is annual or monthly
      const billingPeriod = this.getUserSubscription?.items[0]?.price.recurring.interval === 'year' ? 'yearly' : 'monthly'
      console.log('billingPeriod', billingPeriod)

      const priceId = this.selectedPackage[`${billingPeriod}PriceId`]
      console.log('priceId', priceId)

            // Confirm Spyder Subscription
      await API.Stripe.createSpyderSubscription(priceId, this.getStripeCustomer.stripeId, this.getUser.user_id, this.selectedPackage.brandCount)

      // We need to wait here for Firebase to be updated by stripe
      setTimeout(async () => {
        await this.setUserSubscription({ uid: this.getUser.user_id })
        this.showConfirmSubscriptionModal = false

        await this.fetchSpyderLimit()

        this.$showAlert({type: 'success', message: 'Your subscription has been updated successfully'})
        this.$emit('close')
      }, 3000)
      
    },
    showIntercom () {
      window.Intercom('show')
    }
  }
}
</script>

<style scoped>
.radio-button {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 9999px;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(4, 26, 75, 0.13);
  background-color: white;
  transition: box-shadow 150ms ease-in-out, background-color 150ms ease-in-out;
}
.radio-button.selected {
  box-shadow: none;
  background-color: #ED615A;
}
.radio-button-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 9999px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(128, 30, 29, 0.60);
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}
.radio-button.selected .radio-button-inner {
  opacity: 1;
}
</style>