import { render, staticRenderFns } from "./SpyderTutorialModal.vue?vue&type=template&id=87861c50&scoped=true"
import script from "./SpyderTutorialModal.vue?vue&type=script&lang=js"
export * from "./SpyderTutorialModal.vue?vue&type=script&lang=js"
import style0 from "./SpyderTutorialModal.vue?vue&type=style&index=0&id=87861c50&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87861c50",
  null
  
)

export default component.exports