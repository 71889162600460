<!--
  USAGE: Note that there is no positioning applied. Positioning must be handled where the component is used
  by passing a class or style prop to the component. 

  IF NOT USING FIXED OR ABSOLUTE, SET THE COMPONENT TO RELATIVE POSITIONING.
-->

<template>
  <div class="bg-neutral-700 rounded-lg p-1.5 shadow-lg" :style="{width: `${width}px`}">
    <img 
      class="w-full object-contain rounded-md"
      :src="thumbnailSrc"
      @load="thumbnailLoading = false"
    />
    <div v-if="thumbnailLoading" class="absolute top-1 bottom-1 left-1 right-1 rounded-md bg-neutral-600 opacity-75">
      <div class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 scale-125 opacity-50">
        <BaseLoadingSpinner />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePopupThumbnailPreview',
  props: {
    thumbnailSrc: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 120
    }
  },
  data () {
    return {
      thumbnailLoading: true
    }
  }
}
</script>

<style scoped></style>