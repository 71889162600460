<template>
  <svg
    :width="width"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="{ transform: `scale(${scale})` }"
  >
    <g :clip-path="`url(#clip0_1927_${randomId})`">
      <path
        d="M1.96707 1.32812C0.552568 2.79737 0.842068 4.35812 0.842068 9.09962C0.842068 13.0371 0.155068 16.9844 3.75057 17.9136C4.87332 18.2024 14.8213 18.2024 15.9426 17.9121C17.4396 17.5259 18.6576 16.3116 18.8241 14.1944C18.8473 13.8989 18.8473 4.30562 18.8233 4.00412C18.6463 1.74887 17.2581 0.449117 15.4288 0.185867C15.0096 0.125117 14.9256 0.107117 12.7746 0.103367C5.14482 0.107117 3.47232 -0.232633 1.96707 1.32812Z"
        :fill="`url(#paint0_linear_1927_${randomId})`"
      />
      <path
        d="M9.84052 2.45759C7.11727 2.45759 4.53127 2.21534 3.54352 4.75034C3.13552 5.79734 3.19477 7.15709 3.19477 9.10409C3.19477 10.8126 3.14002 12.4183 3.54352 13.4571C4.52902 15.9936 7.13602 15.7506 9.83902 15.7506C12.4468 15.7506 15.1355 16.0221 16.1353 13.4571C16.544 12.3996 16.484 11.0601 16.484 9.10409C16.484 6.50759 16.6273 4.83134 15.368 3.57284C14.093 2.29784 12.3688 2.45759 9.83752 2.45759H9.84052ZM9.24502 3.65534C14.9255 3.64634 15.6485 3.01484 15.2495 11.7876C15.1078 14.8903 12.7453 14.5498 9.84127 14.5498C4.54627 14.5498 4.39402 14.3983 4.39402 9.10109C4.39402 3.74234 4.81402 3.65834 9.24502 3.65384V3.65534ZM13.388 4.75859C12.9478 4.75859 12.5908 5.11559 12.5908 5.55584C12.5908 5.99609 12.9478 6.35309 13.388 6.35309C13.8283 6.35309 14.1853 5.99609 14.1853 5.55584C14.1853 5.11559 13.8283 4.75859 13.388 4.75859ZM9.84052 5.69084C7.95577 5.69084 6.42802 7.21934 6.42802 9.10409C6.42802 10.9888 7.95577 12.5166 9.84052 12.5166C11.7253 12.5166 13.2523 10.9888 13.2523 9.10409C13.2523 7.21934 11.7253 5.69084 9.84052 5.69084ZM9.84052 6.88859C12.7693 6.88859 12.773 11.3196 9.84052 11.3196C6.91252 11.3196 6.90802 6.88859 9.84052 6.88859Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        :id="`paint0_linear_1927_${randomId}`"
        x1="2.00158"
        y1="16.9537"
        x2="18.7307"
        y2="2.47485"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFDD55" />
        <stop
          offset="0.5"
          stop-color="#FF543E"
        />
        <stop
          offset="1"
          stop-color="#C837AB"
        />
      </linearGradient>
      <clipPath :id="`clip0_1927_${randomId}`">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0.842041 0.103394)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'InstagramIcon',
  props: {
    selected: {
      type: Boolean
    },
    width: {
      type: Number,
      default: () => 15
    }
  },
  data () {
    return {
      randomId: Math.random()
    }
  },
  computed: {
    scale () {
      return this.width / 19
    }
  }
}
</script>
<style scoped lang="css"></style>
