<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M3.99707 16H15.9971M9.99873 13.75V16M9.99873 6.25V4M8.49872 4H11.4987M3.99707 13C3.99707 8.875 6.68337 6.25 9.99708 6.25C13.3108 6.25 15.9971 8.875 15.9971 13V13.75H3.99707V13Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'ServiceNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>