<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14 14" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM8.24975 10.5936L8.36153 10.1367C8.30366 10.1638 8.21038 10.1949 8.08247 10.2302C7.9542 10.2656 7.83877 10.2836 7.73731 10.2836C7.52121 10.2836 7.36906 10.2482 7.28073 10.177C7.19304 10.1058 7.1493 9.97183 7.1493 9.77566C7.1493 9.69794 7.1625 9.58207 7.1901 9.43043C7.21685 9.27778 7.2476 9.14213 7.28181 9.02346L7.6991 7.5461C7.73996 7.41051 7.768 7.26145 7.78305 7.09877C7.79847 6.93644 7.80564 6.82287 7.80564 6.75841C7.80564 6.44688 7.69644 6.19406 7.47797 5.99904C7.25951 5.80416 6.9484 5.70672 6.54524 5.70672C6.32089 5.70672 6.08371 5.74659 5.83269 5.82625C5.58167 5.90569 5.31925 6.00141 5.04471 6.11319L4.93265 6.57049C5.01446 6.54023 5.1119 6.50768 5.2259 6.47398C5.3394 6.44042 5.45075 6.42307 5.55908 6.42307C5.78028 6.42307 5.92927 6.46078 6.00735 6.53507C6.08543 6.60956 6.12465 6.74206 6.12465 6.93149C6.12465 7.03624 6.11225 7.15261 6.0865 7.27909C6.06112 7.40636 6.02943 7.54094 5.99215 7.68297L5.57307 9.16629C5.53578 9.32216 5.50854 9.46162 5.4914 9.58544C5.47441 9.70912 5.46623 9.83051 5.46623 9.94853C5.46623 10.2534 5.57887 10.5047 5.80408 10.703C6.02929 10.9006 6.34505 11 6.75101 11C7.01537 11 7.24739 10.9654 7.44707 10.896C7.64654 10.8267 7.91441 10.726 8.24975 10.5936ZM8.17539 4.59223C8.37034 4.41147 8.46735 4.19164 8.46735 3.93424C8.46735 3.67742 8.37049 3.45716 8.17539 3.27411C7.98095 3.09156 7.74656 3 7.47253 3C7.19763 3 6.96231 3.09135 6.766 3.27411C6.56969 3.45716 6.47132 3.67734 6.47132 3.93424C6.47132 4.19164 6.56969 4.4114 6.766 4.59223C6.96267 4.77363 7.19756 4.8644 7.47253 4.8644C7.74663 4.8644 7.98095 4.77363 8.17539 4.59223Z" 
    fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'InfoIcon',
    props: {
      width: {
        type: Number,
        default: 14
      },
      height: {
        type: Number,
        default: 14
      }
    }
  }
</script>

<style scoped></style>