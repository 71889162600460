<template>
  <!-- Manage Board Modal -->
  <BaseModal
    no-padding
    :no-exit="true"
  >
    <template #toolbar>
      <div class="flex items-start">
        <!-- Logo/Image -->
        <img
          class="mr-2 h-10 mb-1"
          src="../../../assets/images/icon-black.svg"
        >

        <!-- Text -->
        <div class="text-lg font-medium text-black flex items-start">
          <div v-if="step === 3">
            <div>
              Welcome to Foreplay!
            </div>
            <div class="text-base font-normal text-gray-600 -mt-1">
              Congrats on unlocking a top-tier workflow.
            </div>
          </div>
          <span v-else-if="getTeamSubscriptionState === 'active'">Manage Subscription</span>
          <span v-else-if="getTeamSubscriptionState === 'canceled'">
            <div>
              Canceled Account
            </div>
            <div class="text-base font-normal text-gray-600 -mt-1">
              Your account has been canceled, upgrade to use foreplay.
            </div>
          </span>
          <span v-else-if="getTeamSubscriptionState === 'past_due'">Past Due</span>
          <div v-else>
            <div>
              Your Free Trial Expired
            </div>
            <div class="text-base font-normal text-gray-600 -mt-1">
              Choose a subscription to continue using Foreplay.
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #default>
      <div class="border-t border-gray-300 pt-4">
        <div>
          <!-- Save Board -->
          <div>
            <!-- Button to go to the manage subscription page -->
            <div class="w-full flex items-center justify-center my-5">
              <router-link :to="{ name: 'PaymentView' }">
                <BaseButton cta>
                  Choose a Subscription
                </BaseButton>
              </router-link>
            </div>

            <div class="flex justify-center w-full">
              <router-link
                class="mr-10"
                :to="{ name: 'DiscoveryView' }"
              >
                <p
                  class="text-center text-sm rounded mt-4 hover:bg-gray-50 transition px-2 py-1 cursor-pointer"
                >
                  Go to Discovery
                </p>
              </router-link>
              <p
                class="text-center text-sm mt-4 rounded hover:bg-gray-50 transition px-2 py-1 cursor-pointer"
                @click="handleLogout"
              >
                Logout of foreplay
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import StripeElement from '../Stripe'
import firebase from '@/api/config/FirebaseConfig'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TrialEndedModal',
  components: {
    StripeElement
  },
  props: {
    // Board
    board: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedSubscription: {},
      subscriptions: [],
      loadingSubscriptions: false,
      loadingGoToStripe: false,
      step: 1
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getTeam', 'getTeamSubscriptionState']),
    getSortedSubscriptions () {
      const subs = [...this.subscriptions]
      return subs.sort((a, b) => (a.order > b.order ? 1 : -1))
    }
  },
  mounted () {
    this.fetchSubscriptions()
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    // Handle Logout User
    handleLogout () {
      this.logoutUser()
    },
    async fetchSubscriptions () {
      this.loadingSubscriptions = true

      try {
        const db = firebase.firestore()

        const data = await db.collection('products').where('active', '==', true).get()

        for (let i = 0; i < data.docs.length; i++) {
          const currentDoc = data.docs[i].data()

          if (currentDoc.name === 'Additional Team Member (Annual)') {
            continue
          }

          const subscription = {
            ...currentDoc,
            perks: currentDoc.metadata.perks ? JSON.parse(currentDoc.metadata.perks) : [],
            price: [],
            order: currentDoc.metadata.order
          }

          const priceSnap = await data.docs[i].ref
            .collection('prices')
            .where('active', '==', true)
            .get()

          priceSnap.docs.forEach((doc, index) => {
            if (!index) {
              subscription.price = {
                ...doc.data(),
                id: doc.id
              }
            }
          })

          this.subscriptions.push(subscription)
        }

        if (this.getUserSubscription.product) {
          this.selectedSubscription = this.getUserSubscription.product
        } else {
          this.selectedSubscription = this.subscriptions.find((e) => e.name === 'Free')
        }
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingSubscriptions = false
      }
    }
  }
}
</script>

<style scoped></style>
