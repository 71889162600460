<template>
  <MainLayout>
    <template #toolbar>
      <div class="flex items-center justify-between px-5 py-3.5">
        <!-- Send Feedback -->
        <div class="flex items-center">
          <BaseButton
            text
            class="ml-3"
            @click="$router.go(-1)"
          >
            <div class="flex items-center gap-1.5">
              <img src="../../assets/icons/back-arrow.svg">
              <BaseText
                type="label"
                size="sm"
              >
                Back
              </BaseText>
            </div>
          </BaseButton>
        </div>

        <!-- Information Section -->
        <div class="flex items-center text-text-normal">
          <!-- Created At -->
          <BaseText
            type="body"
            size="sm"
          >
            Created at {{ formatCreatedDate(brief.createdAt) }}
          </BaseText>

          <BaseText
            v-if="brief.modifiedAt"
            type="body"
            size="sm"
            class="mx-3"
          >
            ·
          </BaseText>

          <!-- Saved Time -->
          <BaseText
            v-if="brief.modifiedAt"
            type="body"
            size="sm"
          >
            Saved {{ formattedModifiedAt }}
          </BaseText>
        </div>

        <!-- Send Feedback -->
        <div class="flex items-center">
          <BaseButton
            text
            class="ml-3"
            @click="showIntercom"
          >
            <div class="flex items-center gap-1.5">
              <img src="../../assets/icons/megaphone.svg">
              <BaseText
                type="label"
                size="sm"
              >
                Send Feedback
              </BaseText>
            </div>
          </BaseButton>
        </div>
      </div>
    </template>

    <!-- Body -->
    <template #content>
      <div
        class="w-full bg-background-normal flex px-2 justify-center pb-2 overflow-y-scroll rounded-2xl"
      >
        <div
          class="w-full flex flex-col gap-4"
          style="max-width: 1200px"
        >
          <!-- Briefs Header -->
          <BaseCardTransition>
            <BriefsHeader
              v-if="!loading"
              :name="brief.name"
              :due-date="brief.dueDate"
              :description="brief.notes"
              :status="selectedStatus"
              :brief-id="brief.id"
              :folder="folder"
              @saveSection="saveSection"
            />
          </BaseCardTransition>

          <!-- Submissions -->
          <BaseCardTransition>
            <Submissions
              v-if="!loading"
              :brief="brief"
              @saveSection="saveSection"
            />
          </BaseCardTransition>

          <!-- Brand Profile -->
          <BaseCardTransition>
            <BrandProfile
              v-if="!loading"
              :brief-id="brief.id"
              :brand-profile-id="brief.brandProfileId"
              @saveSection="saveSection"
            />
          </BaseCardTransition>

          <!-- Inspiration -->
          <BaseCardTransition>
            <Inspiration
              v-if="!loading"
              :ad-ids="brief.adIds"
              :brief-id="brief.id"
              @reloadBrief="fetchBrief(false)"
            />
          </BaseCardTransition>

          <!-- Modular Details -->
          <BaseCardTransition>
            <ModularDetails
              v-if="!loading"
              :brief="brief"
              @saveSection="saveSection"
            />
          </BaseCardTransition>

          <!-- AI Brief Generator -->
          <BaseCardTransition>
            <AIBriefGenerator
              v-if="!loading"
              :brief="brief"
              :ai-brief="true"
              @loadedFrames="loadingReferences = false"
              @loadingReferences="loadingReferences = true"
              @saveSection="saveSection"
              @scrollToElement="scrollToElement('inspiration')"
            />
          </BaseCardTransition>

          <!-- Story Board -->
          <BaseCardTransition>
            <StoryBoard
              v-if="!loading"
              :last-updated="lastUpdated"
              :brief="brief"
              :loading-references="loadingReferences"
              @saveSection="saveSection"
            />
          </BaseCardTransition>
        </div>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import FirebaseAPI from '@/api/firebase'

// Brief Sections
import BriefsHeader from '../../components/briefsV2/BriefsHeader.vue'
import Inspiration from '../../components/briefsV2/Inspiration.vue'
import BrandProfile from '../../components/briefsV2/BrandProfile.vue'
import AIBriefGenerator from '../../components/briefsV2/AIBriefGenerator.vue'
import StoryBoard from '../../components/briefsV2/StoryBoard.vue'
import ModularDetails from '../../components/briefs/ModularDetails.vue'
import MainLayout from '../../layouts/MainLayout.vue'
import Submissions from '../../components/briefs/editForm/Submissions.vue'

export default {
  name: 'EditBriefPage',
  components: {
    AIBriefGenerator,
    BriefsHeader,
    Inspiration,
    ModularDetails,
    MainLayout,
    StoryBoard,
    Submissions,
    BrandProfile
  },
  data () {
    return {
      brief: {},
      dueDate: null,
      selectedStatus: [],
      folder: {},
      lastUpdated: +new Date(),
      loading: true,
      loadingReferences: false,
      intervalId: null, // to store the interval id (for last saved at)
      saveTimeoutId: null, // to store the save timeout id
      now: Date.now(), // current timestamp
      formattedModifiedAt: ''
    }
  },
  watch: {
    now () {
      // whenever 'now' changes, recalculate 'formatModifiedAt'
      if (this.brief.modifiedAt) {
        this.formattedModifiedAt = moment(this.brief.modifiedAt).fromNow()
      }
    }
  },
  async created () {
    // Fetch Brief
    await this.fetchBrief()

    // Set the inspo brief here
    this.SET_INSPO_BRIEF(this.brief)
    this.SET_INSPO_BRIEF_FOLDER_NAME(this.folder.name)

    this.formattedModifiedAt = moment(this.brief.modifiedAt).fromNow()

    // refresh 'now' every minute
    this.intervalId = setInterval(() => {
      this.now = Date.now()
    }, 60000)
  },
  beforeDestroy () {
    // clear the interval when component is being destroyed
    clearInterval(this.intervalId)
  },
  // mounted() {
  //   const { utm_campaign, utm_medium, utm_source } = this.$route.query;
  //   window.analytics.page("Edit Brief", {
  //     pageVariant: "variant-1",
  //     utmCampaign: utm_campaign,
  //     utmSource: utm_medium,
  //     utmMedium: utm_source,
  //   });
  // },
  methods: {
    ...mapMutations('BriefsModule', [
      'SET_INSPO_BRIEF',
      'SET_CURRENT_BRIEF_ID',
      'SET_INSPO_BRIEF_FOLDER_NAME'
    ]),
    ...mapActions('BriefsModule', ['fetchBriefs']),
    ...mapActions('BoardsModule', ['fetchFolders']),
    scrollToElement (element) {
      const scrollTo = document.getElementById(element)
      scrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' })
    },
    async saveSection (section) {
      this.brief[section.section] = section.value
      this.lastUpdated = +new Date()

      this.triggerSave()
    },
    async triggerSave () {
      this.saving = true

      const modifiedAt = +new Date()

      this.brief.modifiedAt = modifiedAt

      const duplicateObj = { ...this.brief, modifiedAt }
      delete duplicateObj.id

      await FirebaseAPI.Briefs.update(this.$route.params.id, duplicateObj)

      this.saving = false
      this.saved = true

      // Update the sidebar
      await this.fetchBriefs()
      await this.fetchFolders()

      // Hide saved
      setTimeout(() => {
        this.saved = false
      }, 5000)
    },
    async fetchBrief (reloadAnimation = true) {
      if (reloadAnimation) {
        this.loading = true
      }
      // this.brief = await FirebaseAPI.Briefs.getByID(this.$route.params.id)
      this.brief = await FirebaseAPI.Briefs.getByID(this.$route.params.id)

      // Set Inputs with saved values
      this.dueDate = this.brief.dueDate
      this.selectedStatus = this.brief.status
        ? [
          [
            {
              itemText: 'In Progress',
              value: 'in-progress'
            },
            {
              itemText: 'Completed',
              value: 'completed'
            },
            {
              itemText: 'Needs Review',
              value: 'needs-review'
            },
            {
              itemText: 'To Do',
              value: 'to-do'
            }
          ].find((status) => status.itemText === this.brief.status)
        ]
        : [
          {
            itemText: 'To Do',
            value: 'to-do'
          }
        ]

      // Fetch the briefs folder
      const folderResponse = await FirebaseAPI.Folders.getByBriefId(
        this.$route.params.id
      )

      if (!folderResponse.length) {
        // Brief is in the default folder
        this.folder = { name: 'Default Folder', id: 'default' }
      } else {
        this.folder = folderResponse[0]
      }

      if (reloadAnimation) {
        this.loading = false
      }
    },
    showIntercom () {
      window.Intercom('show')
    },
    formatCreatedDate (timeStamp) {
      return moment(timeStamp).format('hA, MMM Do')
    }
  }
}
</script>

<style scoped lang="css"></style>
