<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M2.0586 9.26555L1.40697 8.89423L1.40697 8.89423L2.0586 9.26555ZM17.9415 9.26565L18.5931 8.89433L18.5931 8.89432L17.9415 9.26565ZM2.0586 10.7343L1.40697 11.1056L1.40697 11.1056L2.0586 10.7343ZM17.9415 10.7343L18.5931 11.1057L18.5931 11.1057L17.9415 10.7343ZM2.71023 9.63687C4.62237 6.28127 7.36137 4.70829 10 4.70831C12.6387 4.70833 15.3777 6.28135 17.2898 9.63697L18.5931 8.89432C16.4729 5.17355 13.2866 3.20834 10 3.20831C6.71345 3.20829 3.5272 5.17346 1.40697 8.89423L2.71023 9.63687ZM1.40697 11.1056C3.5272 14.8263 6.71344 16.7916 10 16.7916C13.2866 16.7916 16.4729 14.8264 18.5931 11.1057L17.2898 10.363C15.3777 13.7186 12.6387 15.2916 10 15.2916C7.36137 15.2916 4.62237 13.7185 2.71023 10.3629L1.40697 11.1056ZM1.40697 8.89423C1.01636 9.5797 1.01636 10.4201 1.40697 11.1056L2.71023 10.3629C2.58194 10.1378 2.58194 9.862 2.71023 9.63687L1.40697 8.89423ZM17.2898 9.63697C17.4181 9.86209 17.4181 10.1379 17.2898 10.363L18.5931 11.1057C18.9837 10.4202 18.9837 9.57979 18.5931 8.89433L17.2898 9.63697ZM11.9584 9.99998C11.9584 11.0815 11.0816 11.9583 10 11.9583V13.4583C11.91 13.4583 13.4584 11.91 13.4584 9.99998H11.9584ZM10 11.9583C8.91847 11.9583 8.0417 11.0815 8.0417 9.99998H6.5417C6.5417 11.91 8.09005 13.4583 10 13.4583V11.9583ZM8.0417 9.99998C8.0417 8.91842 8.91847 8.04165 10 8.04165V6.54165C8.09005 6.54165 6.5417 8.09 6.5417 9.99998H8.0417ZM10 8.04165C11.0816 8.04165 11.9584 8.91842 11.9584 9.99998H13.4584C13.4584 8.09 11.91 6.54165 10 6.54165V8.04165Z" 
    fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'SetVisibleIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>