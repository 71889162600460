<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M8.49992 13.5V12.75C8.08571 12.75 7.74992 13.0858 7.74992 13.5H8.49992ZM11.4999 13.5H12.2499C12.2499 13.0858 11.9141 12.75 11.4999 12.75V13.5ZM11.4999 16.1667V16.9167C11.9141 16.9167 12.2499 16.5809 12.2499 16.1667H11.4999ZM8.49992 16.1667H7.74992C7.74992 16.5809 8.08571 16.9167 8.49992 16.9167V16.1667ZM3.83325 10.0833H3.08325V11.5833H3.83325V10.0833ZM8.49992 14.25H11.4999V12.75H8.49992V14.25ZM10.7499 13.5V16.1667H12.2499V13.5H10.7499ZM11.4999 15.4167H8.49992V16.9167H11.4999V15.4167ZM9.24992 16.1667V13.5H7.74992V16.1667H9.24992ZM16.1666 10.0833H3.83325V11.5833H16.1666V10.0833ZM5.16659 5.25H14.8333V3.75H5.16659V5.25ZM15.4166 5.83333V12.1667H16.9166V5.83333H15.4166ZM14.8333 12.75H5.16659V14.25H14.8333V12.75ZM4.58325 12.1667V5.83333H3.08325V12.1667H4.58325ZM5.16659 12.75C4.84442 12.75 4.58325 12.4888 4.58325 12.1667H3.08325C3.08325 13.3173 4.01599 14.25 5.16659 14.25V12.75ZM15.4166 12.1667C15.4166 12.4888 15.1554 12.75 14.8333 12.75V14.25C15.9838 14.25 16.9166 13.3173 16.9166 12.1667H15.4166ZM14.8333 5.25C15.1554 5.25 15.4166 5.51117 15.4166 5.83333H16.9166C16.9166 4.68274 15.9838 3.75 14.8333 3.75V5.25ZM5.16659 3.75C4.01599 3.75 3.08325 4.68274 3.08325 5.83333H4.58325C4.58325 5.51117 4.84442 5.25 5.16659 5.25V3.75Z" 
    fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'DesktopDeviceIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>