<template>
  <BaseCard>
    <div class="relative overflow-hidden">
      <!-- Header -->
      <div
        id="inspiration"
        class="flex items-center justify-between p-3.5 px-5 border-b border-border-normal"
        style="height: 72px"
      >
        <!-- left container -->
        <div class="flex">
          <!-- Icon -->
          <div
            style="width: 45px;"
            class="icon-shadow mr-3 p-2.5 rounded-full flex items-center justify-center"
          >
            <img src="../../assets/icons/magic-stick-top.svg">
          </div>
          <!-- Text -->
          <div>
            <!-- Title Container -->
            <div class="flex gap-1">
              <!-- Title -->
              <BaseText
                type="label"
                size="md"
              >
                <span class="text-text-muted">
                  Inspiration
                </span>
              </BaseText>
              <!-- Count -->
              <BaseText
                type="body"
                size="medium"
              >
                <span class="text-neutral-400">
                  {{ adIds.length || 0 }}
                </span>
              </BaseText>
            </div>
            <!-- SubTitle -->
            <BaseText
              type="body"
              size="sm"
            >
              <span class="text-text-normal">
                Attach or upload from your library
              </span>
            </BaseText>
            <div />
          </div>
        </div>

        <!-- Right Button Container -->
        <div
          v-if="advertisements.length"
          class="flex gap-2"
        >
          <!-- Trigger Manual Upload -->
          <!-- <BaseButton outlined>
            Manual Upload
          </BaseButton> -->

          <!-- Trigger Browse Drawer -->
          <BaseButton
            primary
            @click="addInspo"
          >
            <div class="flex items-center gap-1.5">
              <img src="../../assets/icons/search-white.svg">
              <BaseText
                type="label"
                size="sm"
              >
                Browse Library
              </BaseText>
            </div>
          </BaseButton>
        </div>
      </div>

      <!-- Body -->
      <div
        class="w-full relative overflow-y-scroll"
        style="max-height: 650px;"
      >
        <!-- If there are ads -->
        <div
          v-if="advertisements.length"
          class="p-6"
        >
          <AdvertisementList
            :advertisements="advertisements"
            edit-brief-view
            :shared-brief="false"
            @reloadBrief="$emit('reloadBrief')"
            @addInspo="addInspo"
          />
        </div>

        <!-- Empty State -->
        <div
          v-else
          class="empty-state flex flex-col gap-3 items-center w-full justify-center pb-10"
        >
          <!-- Animation -->
          <div class="flex relative items-center justify-center">
            <img
              style="width: 336px;"
              class="pointer-events-none select-none"
              src="../../assets/icons/inspiration-bg.png"
            >

            <!-- Left Box -->
            <div class="outside-box-left absolute" />
            <!-- Center Box -->
            <div class="center-box flex items-center justify-center">
              <img
                class="pointer-events-none select-none"
                src="../../assets/icons/video clapperboard.svg"
              >
            </div>
            <!-- Right Box -->
            <div class="outside-box-right absolute" />
          </div>
          <!-- Buttons -->
          <div class="flex gap-2">
            <!-- <BaseButton outlined>
              Manual Upload
            </BaseButton> -->
            <BaseButton
              primary
              @click="addInspo"
            >
              Browse Swipe File
            </BaseButton>
          </div>
        </div>
      </div>

      <!-- Gradient -->
      <div
        v-if="advertisements.length"
        style="z-index: 10; height: 115px;"
        class="absolute bottom-0 h-1/6 w-full rounded-b-2xl oguz-fader pointer-events-none"
      />

      <!-- Drawer -->
      <BrowseLibraryDrawer
        v-if="showLibraryModal"
        @reloadBrief="$emit('reloadBrief')"
        @close="showLibraryModal = false"
      />
    </div>
  </BaseCard>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import AdvertisementList from '@/components/advertisements/AdvertisementList.vue'
import firebase from '../../api/config/FirebaseConfig'

import BrowseLibraryDrawer from './inspiration/BrowseLibraryDrawer.vue'

export default {
  name: 'Inspiration',
  components: {
    AdvertisementList,
    BrowseLibraryDrawer
  },
  props: {
    adIds: {
      type: Array,
      default: () => []
    },
    briefId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loadingAdvertisements: false,
    advertisements: [],
    showLibraryModal: false
  }),
  computed: {
    ...mapGetters('BriefsModule', ['getInspoAds']),
    ...mapGetters('AuthModule', ['getTeam'])
  },
  watch: {
    adIds: {
      handler: async function (newVal) {
        // Fetch Briefs
        await this.fetchAdvertisements()
      },
      immediate: true
    }
  },
  async mounted () {
    // Fetch Briefs
    await this.fetchAdvertisements()

    this.SET_CURRENT_BRIEF_ID(this.briefId)
  },
  methods: {
    ...mapMutations('BriefsModule', ['SET_IS_INSPO_MODE', 'SET_INSPO_BRIEF', 'SET_CURRENT_BRIEF_ID', 'SET_INSPO_ADS', 'SET_INSPO_BRIEF_FOLDER_NAME']),
    addInspo () {
      // Set the app to inspo mode to show inspiration modal
      this.SET_IS_INSPO_MODE(true)
      this.SET_INSPO_ADS(this.adIds || [])

      this.showLibraryModal = true
    },
    async fetchAdvertisements () {
      this.loadingAdvertisements = true

      try {
        const db = firebase.firestore()

        // Get ads
        this.advertisements = await Promise.all(
          this.adIds ? this.adIds.map(async (adId) => {
            const adDoc = await db.collection('ads').doc(adId).get()

            return {
              ...adDoc.data(),
              id: adId
            }
          }) : []
        )
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    }
  }
}
</script>

<style scoped>
.icon-shadow {
  box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  border-radius: 999px;
}

/* Background Boxes */
.center-box {
  position: absolute;
  width: 46.5px; /* 31px * 1.5 */
  height: 94.5px; /* 63px * 1.5 */
  left: 144px;
  top: 60.75px;

  z-index: 10;

  transform: scale(1);
  transition: all 0.35s cubic-bezier(0.6, 0.6, 0, 1);

  background: linear-gradient(180.15deg, rgba(255, 255, 255, 0.7) 1.82%, rgba(255, 255, 255, 0) 99.99%), rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 8px rgba(9, 25, 72, 0.06), 0px 0px 0px 1px rgba(18, 55, 105, 0.03);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 6px;
}

.outside-box-left {
  width: 33px; /* 22px * 1.5 */
  height: 66px; /* 44px * 1.5 */
  left: 175.5px;
  top: 75px;

  background: linear-gradient(180.15deg, rgba(255, 255, 255, 0.3) 1.82%, rgba(255, 255, 255, 0) 99.99%), rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 8px rgba(9, 25, 72, 0.06), 0px 0px 0px 1px rgba(18, 55, 105, 0.03);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  transition: all 0.35s cubic-bezier(0.6, 0.6, 0, 1);

  border-radius: 4px;
}

.outside-box-right {
  width: 33px; /* 22px * 1.5 */
  height: 66px; /* 44px * 1.5 */
  right:  175.5px;
  top: 75px;

  background: linear-gradient(180.15deg, rgba(255, 255, 255, 0.3) 1.82%, rgba(255, 255, 255, 0) 99.99%), rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 8px rgba(9, 25, 72, 0.06), 0px 0px 0px 1px rgba(18, 55, 105, 0.03);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  transition: all 0.35s cubic-bezier(0.6, 0.6, 0, 1);

  border-radius: 4px;
}

.empty-state:hover .center-box {
  transform: scale(1.1);
}

.empty-state:hover .outside-box-right {
  transform: rotate(-15deg) scale(1.1) translateX(-10px);
}

.empty-state:hover .outside-box-left {
  transform: rotate(15deg) scale(1.1) translateX(10px);
}

.oguz-fader {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}
</style>
