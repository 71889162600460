// Imports
import AxiosConfig from '../config/AxiosConfig'

const login = (data) => {
  return AxiosConfig.post('/tracklogin', data)
}

const planupgraded = (data) => {
  return AxiosConfig.post('/trackplanupgraded', data)
}

const planpurchased = (data) => {
  return AxiosConfig.post('/trackplanpurchased', data)
}

const paymentcharged = (data) => {
  return AxiosConfig.post('/trackpaymentcharged', data)
}

const planselected = (data) => {
  return AxiosConfig.post('/trackplanselected', data)
}
const extensioninstalled = (data) => {
  return AxiosConfig.post('/trackextensioninstalled', data)
}

export default {
  login,
  planupgraded,
  planpurchased,
  paymentcharged,
  planselected,
  extensioninstalled
}
