<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M6.25 4H13.75C14.9927 4 16 6.01472 16 8.5V14.5C16 15.3284 15.3284 16 14.5 16H10C9.17155 16 8.5 15.3284 8.5 14.5V13M6.25 4C7.49264 4 8.5 6.01472 8.5 8.5M6.25 4C5.00736 4 4 6.01472 4 8.5C4 10.9853 5.00736 13 6.25 13M8.5 13V8.5M8.5 13H6.25M8.5 8.5C8.5 10.9853 7.49264 13 6.25 13" 
    :stroke="stroke" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M6.8125 8.5C6.8125 9.0178 6.56066 9.4375 6.25 9.4375C5.93934 9.4375 5.6875 9.0178 5.6875 8.5C5.6875 7.98223 5.93934 7.5625 6.25 7.5625C6.56066 7.5625 6.8125 7.98223 6.8125 8.5Z" 
    :fill="stroke" />
  </svg>
</template>

<script>
  export default {
    name: 'MediaNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>