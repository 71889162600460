<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M8.29022 8.08119V7.38077C8.04709 7.34227 7.80153 7.32117 7.55538 7.31764C5.17241 7.31256 3.06268 8.8568 2.34707 11.1298C1.63146 13.4028 2.47615 15.8771 4.43214 17.2381C3.71488 16.4704 3.23512 15.5116 3.05079 14.4773C2.86646 13.443 2.98543 12.3774 3.39333 11.4093C3.80124 10.4411 4.48065 9.61168 5.34956 9.02115C6.21847 8.4306 7.23974 8.10418 8.29008 8.08128L8.29022 8.08119Z" 
    fill="#25F4EE"/>
    <path d="M8.42248 16.0151C9.75483 16.0133 10.85 14.9637 10.9083 13.6326V1.75454H13.0783C13.034 1.50633 13.0129 1.25455 13.0152 1.00244H10.0471V12.8691C9.99773 14.2069 8.89996 15.2666 7.56124 15.2687C7.16119 15.2653 6.76766 15.167 6.41309 14.9817C6.64289 15.3001 6.9447 15.5597 7.29391 15.7393C7.64311 15.9189 8.02981 16.0134 8.42248 16.0151ZM17.1314 5.78464V5.12441C16.3328 5.12472 15.5519 4.88909 14.8868 4.44704C15.4698 5.1249 16.2578 5.59442 17.1314 5.78464Z" 
    fill="#25F4EE"/>
    <path d="M14.8868 4.44709C14.2315 3.70096 13.8703 2.74179 13.8706 1.74878H13.0783C13.1807 2.29781 13.3938 2.82022 13.7047 3.28414C14.0157 3.74806 14.4179 4.14376 14.8868 4.44709ZM7.55555 10.2857C6.99988 10.2885 6.46108 10.477 6.02483 10.8212C5.58858 11.1654 5.27994 11.6456 5.14797 12.1854C5.01597 12.7252 5.06822 13.2936 5.29642 13.8003C5.52459 14.307 5.91563 14.7228 6.40731 14.9818C6.13846 14.6106 5.97749 14.1723 5.94213 13.7154C5.90679 13.2584 5.99842 12.8006 6.20693 12.3925C6.41547 11.9844 6.73275 11.6419 7.12375 11.4028C7.51473 11.1637 7.96421 11.0373 8.42251 11.0377C8.67167 11.0409 8.91907 11.0797 9.15726 11.1528V8.13278C8.91402 8.09629 8.66848 8.07711 8.42251 8.07539H8.29039V10.3717C8.05094 10.3075 7.80339 10.2785 7.55555 10.2857Z" 
    fill="#FE2C55"/>
    <path d="M17.1315 5.78467V8.08109C15.6531 8.07822 14.213 7.61022 13.0153 6.74343V12.7772C13.0089 15.788 10.5664 18.2254 7.55562 18.2254C6.43744 18.2274 5.34618 17.8824 4.43237 17.238C5.17752 18.0394 6.14677 18.5979 7.21385 18.8407C8.2809 19.0835 9.39638 18.9993 10.4149 18.5991C11.4335 18.1988 12.3079 17.5012 12.9243 16.597C13.5407 15.6927 13.8705 14.6238 13.8707 13.5295V7.51275C15.0724 8.37385 16.5143 8.83574 17.9927 8.83318V5.8763C17.7032 5.87544 17.4147 5.84473 17.1315 5.78467Z" 
    fill="#FE2C55"/>
    <path d="M13.0151 12.7772V6.74345C14.2165 7.6053 15.6586 8.06727 17.1371 8.06385V5.76752C16.2636 5.58304 15.4738 5.1196 14.8867 4.44709C14.4178 4.14376 14.0156 3.74806 13.7046 3.28414C13.3937 2.82022 13.1806 2.29781 13.0782 1.74878H10.9083V13.6328C10.8871 14.1501 10.7054 14.6479 10.3883 15.0572C10.0712 15.4664 9.6345 15.7667 9.13885 15.9164C8.64323 16.066 8.11331 16.0575 7.62272 15.8921C7.13215 15.7267 6.70526 15.4125 6.40141 14.9934C5.90967 14.7345 5.51855 14.3186 5.29032 13.8119C5.06207 13.3052 5.00979 12.7367 5.14179 12.1969C5.27379 11.657 5.58246 11.1768 6.01876 10.8326C6.45507 10.4883 6.99395 10.2998 7.54968 10.2971C7.79893 10.2993 8.0465 10.338 8.28452 10.4119V8.11553C7.22855 8.13344 6.20057 8.45819 5.32598 9.05023C4.45136 9.64225 3.76791 10.476 3.35894 11.4497C2.94997 12.4235 2.83315 13.4952 3.02273 14.5341C3.21231 15.5731 3.70009 16.5345 4.42661 17.3011C5.34938 17.9243 6.44218 18.2471 7.55548 18.2254C10.5662 18.2254 13.0088 15.788 13.0151 12.7772Z" 
    fill="black"/>
  </svg>
</template>

<script>
  export default {
    name: 'TikTokPlatformIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>