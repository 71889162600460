<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10.0003 2.29163L10.0001 5.20829M10.0003 14.7916V17.7083M2.29175 10.0005H5.20841M14.7917 10.0005H17.7084M4.54965 4.54916L6.61186 6.61174M13.3885 13.388L15.4509 15.4504M4.54988 15.451L6.61227 13.3886M13.3887 6.61215L15.4511 4.54976" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'StatusIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>