<template>
  <div class="flex mt-8">
    <!-- IMAGE -->
    <div
      v-if="advertisement.mobileScreenshot"
      class="h-32 w-32 bg-black mr-6 overflow-hidden"
    >
      <img
        class="overflow-hidden"
        :src="advertisement.mobileScreenshot"
      >
    </div>
    <!-- Generating screenshot -->
    <div
      v-else-if="advertisement.needsScreenshot"
      class="h-32 w-32 bg-gray-200 skeleton mr-6 overflow-hidden flex flex-col items-center justify-center text-center"
    >
      <div class="text-xs mb-1">
        Taking Screenshots...
      </div>
      <div class="text-xs text-gray-400">
        This may take a few minutes
      </div>
    </div>
    <!-- Screenshot Errored -->
    <div
      v-else
      class="h-32 w-32 bg-gray-200 skeleton mr-6 overflow-hidden flex flex-col items-center justify-center text-center"
    >
      <div class="text-xs mb-1">
        An Error Occurred
      </div>
      <div class="text-xs text-gray-400">
        We will retry the screenshot soon
      </div>
    </div>
    <div class="flex flex-col items-start">
      <div class="text-gray-500 text-lg semibold -mb-1 -mt-1">
        Landing Page
      </div>
      <a
        class="text-blue-400 cursor-pointer"
        @click="handleWebLandingPage('web', advertisement.link_url)"
      >
        {{ getDomainFromUrl(advertisement.link_url) }}
      </a>
      <div class="flex mt-2">
        <a
          class="flex rounded p-0.5 w-16 items-center mr-2 select-none"
          :class="{
            'cursor-not-allowed bg-gray-100 text-gray-400':
              !advertisement.mobileScreenshot,
            'cursor-pointer bg-gray-200': advertisement.mobileScreenshot,
          }"
          @click="
            handleWebLandingPage('mobile', advertisement.mobileScreenshot)
          "
        >
          <!-- PHONE SVG -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
          <div class="ml-2">View</div>
        </a>
        <a
          target="_blank"
          class="flex rounded p-0.5 w-16 items-center select-none"
          :class="{
            'cursor-not-allowed bg-gray-100 text-gray-400':
              !advertisement.desktopScreenshot,
            'cursor-pointer  bg-gray-200': advertisement.desktopScreenshot,
          }"
          @click="
            handleWebLandingPage('desktop', advertisement.desktopScreenshot)
          "
        >
          <!-- DESKTOP SVG -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <div class="ml-2">View</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'Screenshots',
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    }
  },
  computed: {},
  methods: {
    getDomainFromUrl (url) {
      const domain = new URL(url)
      return `${domain.protocol}//${domain.hostname}`
    },
    async handleWebLandingPage (page, link_url) {
      let pageType
      if (page === 'web') {
        pageType = 'Web'
      } else if (page === 'desktop') {
        pageType = 'Desktop'
      } else {
        pageType = 'Mobile'
      }
      // Track event in analytics and open link in new tab
      await window.analytics.track('Ad Landing Page Viewed', {
        type: pageType,
        adLandingPageViewed: true,
        advertiser: this.advertisement.advertiser,
        link: this.advertisement.link_url
      })

      window.open(link_url, '_blank')
    }
  }
}
</script>

<style scoped></style>
