// Imports
import AxiosConfig from './serverAxiosConfig'
import firebase from '../config/FirebaseConfig'

const getBrandScreenshots = async (brandId, nextPageId = null) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    `/screenshots/brands/${brandId}`,
    {
      nextPageId
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

export default {
  getBrandScreenshots
}
