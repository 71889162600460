<template>
  <!-- Base Multi Select -->
  <div class="base-single-select">
    <!-- Label -->
    <div
      v-if="label"
      class="base-single-select--label"
    >
      {{ label }}
    </div>

    <VuePopper
      ref="popper"
      trigger="clickToOpen"
      :disabled="disabled"
      :options="{
        placement: 'bottom',
        flipVariations: false,
      }"
    >
      <!-- Dropdown -->
      <div :class="clearDropdown ? 'clear-dropdown' : (preventTracking ? 'prevent-tracking' : 'base-single-select--dropdown') ">
        <div
          v-if="!noSearch"
          class="border-b border-gray-300 flex items-center w-full px-3 py-2 sticky top-0"
          :class="clearDropdown ? 'clear-dropdown' : 'bg-white'"
        >
          <i class="fas fa-search pr-2" />
          <input
            v-model="searchQuery"
            placeholder="Search"
            class="pt-1 outline-none w-11/12 flex-shrink-0"
          >
        </div>

        <div v-if="getSearchedItems.length">
          <div
            v-for="(item, index) in getSearchedItems"
            :key="index"
            class="base-single-select--dropdown--item"
            :class="[{ 'bg-teal-light': value === item, 'font-semibold': selected.map(e => e[itemValue]).includes(item[itemValue]) }, `hover:${hoverColor}`]"
            @click="handleSelectItem(item)"
          >
            <slot
              name="item"
              :item="item"
            >
              {{ item[itemText] ? item[itemText] : item }}
            </slot>

            <i
              v-if="selected.map(e => e[itemValue]).includes(item[itemValue]) && !clearDropdown"
              class="fas fa-check text-blue-500 pr-1"
            />
          </div>
        </div>

        <div
          v-else-if="searchQuery"
          class="p-2"
        >
          Not found.
        </div>
      </div>

      <div
        slot="reference"
        v-click-outside="() => openDropdown = false"
        class="relative group"
        @click="closeDrawer"
      >
        <div
          v-if="placeholderOverlay && !selected.length"
          class="opacity-0 group-hover:opacity-100 duration-200 transition-opacity absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 whitespace-nowrap text-gray-500 z-10 select-none"
        >
          {{ placeholderOverlay }}
        </div>
        <div
          class=""
          :class="[
            disabled ? `cursor-default hover:border-gray-300 ${noBorder && 'border-none'}` : 'cursor-pointer border hover:border-blue-600 transition-all duration-300 border-gray-300',
            noIconBorder ? 'dropdown-border hover:border-white hover:border-opacity-0' : 'base-single-select--input',
            openDropdown ? `dropdown-border-${getTheme}--active` : ''
          ]"
        >
          <div
            v-if="hasIconSlot"
            :class="noIconBorder ? 'bg-white' : 'base-single-select--input--icon bg-white'"
          >
            <slot
              name="icon"
            />
          </div>

          <!-- Selection List -->
          <div
            v-if="value && !multiple"
            class="flex flex-wrap"
            @click="handleSelectItem(item)"
          >
            <slot
              name="selected"
              :item="value"
            >
              {{ value }}
            </slot>
          </div>

          <div
            v-else-if="selected.length"
            class="flex items-center px-1 gap-x-1"
            :class="selected.length === 1 ? 'w-full max-w-full' : 'w-6/12'"
          >
            <div
              v-for="(item, index) in selected.slice(0, (maxItems ? maxItems : 2))"
              :key="index"
              :style="(maxItems < 2 && maxItems) ? 'max-width: 110% !important;' : 'max-width: 73% !important;'"
              :class="((!clearDropdown ? ('text-sm rounded py-1 whitespace-nowrap'+ (item.color ? `bg-${item.color}-100  text-${item.color}-600` : 'bg-blue-100 text-blue-600')) : ''))"
            >
              <slot
                name="selection"
                :item="item"
              >
                {{ itemText ? item[itemText] : item }}
              </slot>
            </div>
            <div
              v-if="selected.length > (maxItems ? maxItems : 2)"
              class="text-xs text-white flex py-1 pb-1 items-center absolute right-6 my-1 shadow-sm"
              :class="` bg-gradient-to-t from-${getTheme}-600 via-${getTheme}-500 to-${getTheme}-500 rounded-full p-1.5`"
            >
              <BaseText
                tyoe="body"
                size="xs"
                class="pt-px"
              >
                +{{ selected.length - (maxItems ? maxItems : 2) }}
              </BaseText>
            </div>
          </div>

          <!-- Placeholder -->
          <div
            v-else
            class="text-grey"
          >
            {{ placeholder }}
          </div>

          <i
            v-if="clearable && value"
            class="fas fa-times absolute right-0 cursor-pointer mr-8"
            @click="$emit('input', null)"
          />

          <i
            v-if="!disabled && !clearDropdown"
            class="absolute right-0 fas fa-angle-down"
          />
          <img
            v-if="clearDropdown"
            src="../../assets/icons/chevron-down-small.svg"
            alt=""
            class="absolute right-0 mr-2 transform duration-300"
            :class="openDropdown ? 'rotate-0' : 'rotate-180'"
          >
        </div>
      </div>
    </VuePopper>
  </div>
</template>

<script>
import VuePopper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'BaseSingleSelect',
  components: {
    VuePopper
  },
  directives: {
    ClickOutside
  },
  props: {
    // Value
    value: {
      type: String,
      default: ''
    },
    // A value to pass back up to the parent
    uniqueValue: {
      type: Number,
      default: null
    },
    maxSelection: {
      type: Number,
      default: 10
    },
    // Label
    label: {
      type: String,
      default: ''
    },
    // List Items
    items: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: ''
    },
    placeholderOverlay: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean
    },
    selected: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: ''
    },
    noSearch: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noIconBorder: {
      type: Boolean,
      default: false
    },
    clearDropdown: {
      type: Boolean,
      default: false
    },
    preventTracking: {
      type: Boolean,
      default: false
    },
    maxItems: {
      type: Number,
      default: 0
    },
    hoverColor: {
      type: String,
      default: 'bg-gray-100'
    }
  },
  data () {
    return {
      searchQuery: '',
      openDropdown: false
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    hasIconSlot () {
      return !!this.$slots.icon
    },
    getSearchedItems () {
      return this.items.filter(e => e[this.itemValue]?.toLowerCase().includes(this.searchQuery))
    }
  },
  methods: {
    // Close Drawer
    closeDrawer () {
      this.openDropdown = !this.openDropdown
      console.log(this.openDropdown)
      if (this.$refs.popper.showPopper) {
        setTimeout(() => {
          this.$refs.popper.doClose()
        }, 70)
      }
    },
    // Handle Select Item
    handleSelectItem (item) {
      if (this.disabled) return

      if (!this.multiple) {
        if (this.selected[0] && this.selected[0].name === item.name) {
          this.$emit('update:selected', [])
        } else {
          this.$emit('update:selected', [item])
        }

        this.$emit('input', item, this.uniqueValue)
        this.closeDrawer()
      } else {
        if (!this.selected.map(e => e[this.itemValue]).includes(item[this.itemValue])) {
          if (this.selected.length === this.maxSelection) {
            this.$showAlert({
              message: `Only ${this.maxSelection} values can be selected at once`,
              type: 'error'
            })

            return
          }

          this.$emit('update:selected', [...this.selected, item])
        } else {
          this.$emit('update:selected', this.selected.filter(e => e[this.itemValue] !== item[this.itemValue]))
        }

        this.$emit('select')
      }
    }
  }
}
</script>

<style scoped lang="sass">
.base-single-select
  @apply cursor-pointer flex flex-col relative text-sm

  &--label
    margin-bottom: 3px
    @apply text-gray-500

  &--input
    height: 37px
    font-size: 15px
    @apply bg-white border border-gray-300 flex items-center rounded-r-md rounded-l-md

    i
      @apply text-gray-500 mr-2

    &--icon
      @apply bg-white flex border-r border-gray-300 items-center rounded-l-md px-4 h-full text-gray-500

  &--dropdown
    animation: fade 100ms ease-in
    border-radius: 4px
    margin-top: 8px
    max-height: 50vh
    z-index: 10000
    @apply bg-white border border-gray-200 overflow-y-auto w-full shadow-lg

    &--item
      @apply flex items-center justify-between cursor-pointer p-2 transition-all

@keyframes fade
  0%
    opacity: 0

  100%
    opacity: 100%

.clear-dropdown
  animation: fade 100ms ease-in
  border-radius: 4px
  margin-top: 8px
  max-height: 25vh
  z-index: 10000
  box-shadow: 0px 1px 2px rgba(18, 55, 105, 0.08), 0px 0px 0px 1px rgba(18, 55, 105, 0.08)
  @apply bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg overflow-y-auto w-full rounded-lg
.clear-dropdown:focus-within
  box-shadow: 0px 1px 2px rgba(24, 78, 68, 0.4), 0px 0px 0px 1px #00A879, 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px rgba(0, 168, 121, 0.32)

.dropdown-border
  height: 37px
  box-shadow: 0px 1px 2px rgba(18, 55, 105, 0.08), 0px 0px 0px 1px rgba(18, 55, 105, 0.08)
  @apply bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg overflow-y-auto w-full rounded-lg flex justify-start items-center

.dropdown-border-green--active
  box-shadow: 0px 1px 2px rgba(24, 78, 68, 0.4), 0px 0px 0px 1px #00A879, 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px rgba(0, 168, 121, 0.32)
  &:hover
    @extend .dropdown-border-green--active
  &:active
    @extend .dropdown-border-green--active

.dropdown-border-blue--active
  box-shadow: 0px 1px 2px rgba(0, 121, 193, 0.4), 0px 0px 0px 1px #00AFFF, 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px rgba(0, 121, 255, 0.32)
  &:hover
    @extend .dropdown-border-blue--active
  &:active
    @extend .dropdown-border-blue--active

.dropdown-border-purple--active
  box-shadow: 0px 1px 2px rgba(88, 24, 141, 0.4), 0px 0px 0px 1px #8F00FF, 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px rgba(128, 0, 255, 0.32)
  &:hover
    @extend .dropdown-border-purple--active
  &:active
    @extend .dropdown-border-purple--active

.prevent-tracking
  @apply cursor-pointer flex flex-col relative text-sm
  @apply bg-white border border-gray-200 overflow-y-auto w-full shadow-lg
  animation: fade 100ms ease-in
  margin-top: 8px
  max-height: 50vh
  z-index: 10000
  top: 36px !important
  will-change: unset !important
  transform: unset !important
</style>
