<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M2.5 9.125L5.05073 5.61774C5.333 5.22963 5.78393 5 6.26384 5H13.7213C14.2095 5 14.6672 5.23761 14.9481 5.63691L17.5 9.26428M2.5 9.125H1.75M2.5 9.125V14C2.5 14.8284 3.17157 15.5 4 15.5C4.82843 15.5 5.5 14.8284 5.5 14H14.5C14.5 14.8284 15.1716 15.5 16 15.5C16.8284 15.5 17.5 14.8284 17.5 14V9.26428M17.5 9.26428H18.25M5.5 11H7M13 11H14.5" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'AutomotiveNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>