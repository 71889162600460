// Imports
import AxiosConfig from '../config/AxiosConfig'

// Imports
import firebase from '../config/FirebaseConfig'

const createInvitation = async (toEmail, teamId) => {
  const user = firebase.auth().currentUser

  const { data } = await AxiosConfig.post('/createInvitation', {
    toEmail: toEmail.toLowerCase(),
    teamId,
    createdBy: user.uid
  })

  return data
}

export default {
  createInvitation
}
