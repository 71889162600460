import firebase from 'firebase'

export default {
  namespaced: true,
  state: {
    spyderSubscriptions: [],
    spyderSubscriptionsLoading: true
  },
  getters: {
    // Get Spyder Subscriptions
    getSpyderSubscriptions: state => state.spyderSubscriptions,
    getSpyderSubscriptionsLoading: state => state.spyderSubscriptionsLoading
  },
  mutations: {
    // Set Spyder Subscriptions
    SET_SPYDER_SUBSCRIPTIONS (state, subscriptions) {
      state.spyderSubscriptions = subscriptions
    },
    SET_SPYDER_SUBSCRIPTIONS_LOADING (state, loading) {
      state.spyderSubscriptionsLoading = loading
    }
  },
  actions: {
    async fetchSpyderLimit ({ rootState }) {
      const limitOwner = rootState.AuthModule?.team?.created_by || rootState.AuthModule.user?.user_id

      const db = firebase.firestore()
      const snapshot = await db.collection('auth-users').doc(limitOwner).get()

      return snapshot.data().spyderLimit || 2
    },
    // Fetch Spyder Subscriptions
    async fetchSpyderSubscriptions ({ commit, rootState }) {
      const db = firebase.firestore()

      const subscriptionId = rootState.AuthModule?.team?.id || rootState.AuthModule.user?.user_id
      const snapshot = await db.collection('fb_ads_page_track').where('subscribers', 'array-contains', subscriptionId).get()
      const subscriptions = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })

      const scrapedSubscriptions = await Promise.all(subscriptions.map(async (subscription) => {
        if (subscription?.brandId) {
          const snapshot = await db.collection('brands').doc(subscription.brandId).get()
          return { id: snapshot.id, ...snapshot.data(), subscription }
        } else {
          return { subscription }
        }
      }))

      commit('SET_SPYDER_SUBSCRIPTIONS', scrapedSubscriptions)
    }
  }
}
