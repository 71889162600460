<template>
  <div>
    <h1>Winning Ads View</h1>
  </div>
</template>

<script>
  export default {
    name: 'WinningAdsBrandView'
  }
</script>

<style scoped></style>