<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
  class="star-icon" :class="{'star-icon-active': isActive}">
    <path fill="currentColor" 
    d="M9.42929 1.81639C9.65867 1.33895 10.3413 1.33895 10.5707 1.81639L12.5445 5.9247C12.6369 6.11691 12.8206 6.24962 13.0328 6.2774L17.5741 6.87196C18.1021 6.94109 18.3133 7.58923 17.9266 7.95383L14.6065 11.0841C14.4507 11.2309 14.3803 11.4464 14.4195 11.6564L15.2533 16.1286C15.3504 16.6494 14.7979 17.0497 14.3301 16.7975L10.3012 14.6258C10.1133 14.5245 9.88671 14.5245 9.69879 14.6258L5.66994 16.7975C5.20214 17.0497 4.64963 16.6494 4.74671 16.1286L5.58051 11.6564C5.61966 11.4464 5.54926 11.2309 5.39349 11.0841L2.07338 7.95383C1.68667 7.58923 1.89786 6.94109 2.4259 6.87196L6.96724 6.2774C7.17942 6.24962 7.36312 6.11691 7.45547 5.9247L9.42929 1.81639Z" />
  </svg>
</template>

<script>
  export default {
    name: 'RatingStarIcon',
    props: {
      isActive: {
        type: Boolean,
        default: false
      }
    },
  }
</script>

<style scoped lang="css">
  .star-icon {
    transition: color 0.2s ease-in-out;
    color: #C1C7D0;
  }
  .star-icon:hover {
    color: #FFBE4C;
  }
  .star-icon.star-icon-active {
    color: #FFBE4C;
  }
</style>