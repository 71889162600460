<template>
  <!-- Discover View -->
  <MainLayout
    bg-color="white"
    no-content-padding
    aditional-classes="relative"
  >
    <template #toolbar>
      <div class="flex items-center justify-between px-5 py-3.5">
        <div class="flex items-center">
          <img
            src="../../assets/images/briefs-new.png"
            class="w-10 h-10"
          >
          <div class="ml-3">
            <BaseText
              type="label"
              size="md"
              class="text-neutral-900 font-medium"
            >
              Briefs
            </BaseText>

            <BaseText
              type="body"
              size="sm"
              class="text-text-normal w-52 overflow-visible whitespace-nowrap"
            >
              Transform Ad Inspiration into actionable briefs
            </BaseText>
          </div>
        </div>

        <BaseViewToggle
          :views="['Briefs', 'Brand Profiles']"
          :value="viewToggleValue"
          icons
          @input="
            (value) => (
              (viewToggleValue = value),
              (transitioning = true),
              transitionTimeout()
            )
          "
        >
          <svg
            slot="icon1"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="transition duration-300 mr-1"
            :class="
              viewToggleValue === 'Briefs' ? 'text-black' : 'text-neutral-500'
            "
          >
            <path
              d="M4.88064 15.7625C5.9753 14.1634 7.77653 13.1243 9.99984 13.1243C12.2231 13.1243 14.0244 14.1634 15.119 15.7625M4.88064 15.7625C6.24202 16.9726 8.03508 17.7077 9.99984 17.7077C11.9646 17.7077 13.7577 16.9726 15.119 15.7625M4.88064 15.7625C3.29222 14.3505 2.2915 12.2918 2.2915 9.99935C2.2915 5.74215 5.74264 2.29102 9.99984 2.29102C14.257 2.29102 17.7082 5.74215 17.7082 9.99935C17.7082 12.2918 16.7075 14.3505 15.119 15.7625M12.7082 8.33268C12.7082 9.82845 11.4956 11.041 9.99984 11.041C8.50407 11.041 7.2915 9.82845 7.2915 8.33268C7.2915 6.83691 8.50407 5.62435 9.99984 5.62435C11.4956 5.62435 12.7082 6.83691 12.7082 8.33268Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            slot="icon2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="transition duration-300 mr-1"
            :class="
              viewToggleValue === 'Brand Profiles'
                ? 'text-black'
                : 'text-neutral-500'
            "
          >
            <path
              d="M3.95841 16.875V4.79167C3.95841 3.87119 4.70461 3.125 5.62508 3.125H14.3751C15.2956 3.125 16.0417 3.87119 16.0417 4.79167V16.875M3.95841 16.875H16.0417M3.95841 16.875H2.29175M16.0417 16.875H17.7084M7.29175 6.45833H8.54175M11.4584 6.45833H12.7084M7.29175 9.79167H8.54175M11.4584 9.79167H12.7084M7.29175 13.125H8.54175M11.4584 13.125H12.7084"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </BaseViewToggle>
        <div class="flex items-center">
          <BaseButton
            class="mr-3"
            @click="showTutorialModal = true"
          >
            <div class="flex">
              <img
                src="../../assets/icons/tutorial.svg"
                alt=""
                class="mr-1.5"
              >
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Tutorial
              </BaseText>
            </div>
          </BaseButton>
          <BaseSelect
            no-padding
            white-background
            text-trigger
            item-value="name"
            popper-center
            :items="[
              {
                name: 'Save New Ad',
                icon: 'plus',
                action: openAdModal,
              },
              {
                name: 'Manual Upload',
                icon: 'upload',
                action: openManualUpload,
              },
              {
                name: 'Create Board',
                icon: 'board',
                action: () => (showManageBoardModal = true),
              },
              {
                name: 'Create Folder',
                icon: 'folder',
                action: () => (showFolderModal = true),
              },
              {
                name: 'Create Brief',
                icon: 'brief',
                action: () => (showCreateBriefModal = true),
              },
              {
                name: 'Add Spyder',
                icon: 'spyder',
                action: () => {$router.push({ name: 'SpyderBrandsView' })}
              },
            ]"
            @select="() => {}"
            @input="() => {}"
          >
            <div slot="reference">
              <BaseButton
                primary
                class
              >
                <div class="flex items-center justify-between w-full h-full">
                  <img
                    src="../../assets/icons/library-create-icon.svg"
                    class="w-5 h-5 mr-1.5 -ml-1"
                  >
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    Create New
                  </BaseText>
                </div>
              </BaseButton>
            </div>
            <template #item="{ item }">
              <div class="flex items-center w-full h-full">
                <img
                  v-if="item.icon === 'plus'"
                  src="../../assets/icons/plus-icon.svg"
                  :alt="item.name"
                  class="w-6 h-6 mr-1.5 -ml-px"
                >
                <svg
                  v-if="item.icon === 'upload'"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  class="w-5 h-5 mr-1.5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99999 3.125V12.5M9.99999 3.125L13.75 6.875M9.99999 3.125L6.25 6.875M16.875 10.625V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V10.625"
                    stroke="#5E6678"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <img
                  v-if="item.icon === 'board'"
                  src="../../assets/icons/create-board-icon.svg"
                  :alt="item.name"
                  class="w-5 h-5 mr-1.5"
                >
                <img
                  v-if="item.icon === 'folder'"
                  src="../../assets/icons/create-folder-icon.svg"
                  :alt="item.name"
                  class="w-5 h-5 mr-1.5"
                >
                <img
                  v-if="item.icon === 'brief'"
                  src="../../assets/icons/document-gray.svg"
                  :alt="item.name"
                  class="w-5 h-5 mr-1.5"
                >
                <svg
                  v-if="item.icon === 'spyder'"
                  width="15"
                  height="20"
                  viewBox="0 0 15 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 mr-1.5 text-icon-normal"
                >
                  <path
                    d="M7.4774 14.6967V19.0996C7.4774 19.0996 3.8498 16.1644 2.20665 13.6058C0.192936 10.4846 0.222191 8.67941 0.222191 8.67941L2.57234 7.78902C2.57234 7.86238 2.57234 7.94068 2.57234 8.01894C2.61267 9.87949 3.33879 11.6591 4.61041 13.0138C5.55634 14.0607 6.66315 14.6967 7.4774 14.6967Z"
                    fill="currentColor"
                  />
                  <path
                    d="M14.7374 8.67912C14.7374 8.67912 14.7666 10.4844 12.7529 13.5713C11.1098 16.1054 7.47726 19.0651 7.47726 19.0651V14.6622C8.34519 14.6622 9.42758 14.0507 10.3589 13.0429C11.6386 11.6804 12.3699 9.89046 12.4116 8.0187C12.4116 7.94044 12.4116 7.86703 12.4116 7.79367L14.7374 8.67912Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.47738 0.900574V5.93949L2.57231 7.7887L0.222168 8.67905C0.222168 8.67905 2.07498 0.900574 7.47738 0.900574Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.47738 0.900574C12.8115 0.900574 14.7374 8.67912 14.7374 8.67912L12.4116 7.79367L7.47738 5.93949V0.900574Z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.10724 7.77576C5.1543 7.65645 5.32316 7.65645 5.37022 7.77576L5.61345 8.3925C5.64218 8.46535 5.69985 8.52302 5.77271 8.55175L6.38944 8.79499C6.50875 8.84205 6.50875 9.0109 6.38944 9.05796L5.77271 9.3012C5.69985 9.32993 5.64218 9.3876 5.61345 9.46045L5.37022 10.0772C5.32316 10.1965 5.1543 10.1965 5.10724 10.0772L4.86401 9.46045C4.83528 9.3876 4.77761 9.32993 4.70475 9.3012L4.08802 9.05796C3.96871 9.0109 3.96871 8.84205 4.08802 8.79499L4.70475 8.55175C4.77761 8.52302 4.83528 8.46535 4.86401 8.3925L5.10724 7.77576Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.5739 8.81699L10.4695 8.7795C10.1656 8.67032 9.84509 8.6145 9.52216 8.6145C9.19923 8.6145 8.87874 8.67032 8.57483 8.7795L8.47046 8.81699C8.36141 8.85617 8.36141 8.99674 8.47046 9.03591L8.57483 9.07341C8.87874 9.18258 9.19923 9.2384 9.52216 9.2384C9.84509 9.2384 10.1656 9.18258 10.4695 9.07341L10.5739 9.03591C10.6829 8.99674 10.6829 8.85617 10.5739 8.81699Z"
                    fill="currentColor"
                  />
                </svg>
                <div
                  class="flex items-center cursor-pointer"
                  @click="item.action"
                >
                  {{ item.name }}
                </div>
              </div>
            </template>
          </BaseSelect>
        </div>
      </div>
    </template>

    <template #content>
      <div class="flex flex-col bg-white">
        <!-- Filter and Search -->
        <transition
          name="fade"
          enter-active-class="transition-opacity duration-300"
          leave-active-class="transition-opacity duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="viewToggleValue === 'Briefs' && !transitioning"
            class="flex items-center justify-between border-b border-border-normal py-3 px-5"
          >
            <div class="flex items-center">
              <BaseInputV2
                v-model="textSearch"
                icon
                placeholder="Search for briefs..."
                class="w-80"
              >
                <img
                  slot="icon"
                  src="../../assets/icons/search.svg"
                  class="h-5 w-5"
                >
              </BaseInputV2>
              <BaseSplitSelect
                v-if="brandProfiles && brandProfiles.length > 0"
                :briefs="getBriefs"
                :filtered.sync="filteredBriefs"
                :clear-all.sync="clearFilters"
                filter
                multiple
                item-value="item"
                :items="[
                  {
                    item: 'Brand Profile',
                    icon: 'brand profile',
                    subItems:
                      brandProfiles &&
                      brandProfiles.map((brandProfile) => ({
                        subItem: brandProfile.name,
                        id: brandProfile.id,
                        image: brandProfile.image,
                      })),
                  },
                  {
                    item: 'Folder',
                    icon: 'folder',
                    subItems: getFolders.map((folder) => ({
                      folderIds: folder.briefIds ?? [],
                      subItem: folder.name,
                      id: folder.id,
                      icon: 'folder',
                    })),
                  },
                  {
                    item: 'Status',
                    icon: 'status',
                    noSearch: true,
                    subItems: [
                      {
                        subItem: 'No Status',
                        icon: 'status',
                        id: 'no_status',
                      },
                      {
                        subItem: 'To Do',
                        icon: 'status',
                        id: 'to_do',
                      },
                      {
                        subItem: 'In Progress',
                        icon: 'status',
                        id: 'in_progress',
                      },
                      {
                        subItem: 'Needs Review',
                        icon: 'status',
                        id: 'needs_review',
                      },
                      {
                        subItem: 'Completed',
                        icon: 'status',
                        id: 'completed',
                      },
                    ],
                  },
                  {
                    item: 'Due Date',
                    icon: 'due date',
                    noSearch: true,
                    noCheckboxes: true,
                    subItems: getFormattedTimes(),
                  },
                ]"
              >
                <div
                  slot="reference"
                  class="flex items-center cursor-pointer rounded-md hover:bg-neutral-25 hover:text-text-normal py-1.5 px-2"
                >
                  <div
                    class="flex items-center text-icon-normal object-contain"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="filter 2, sort">
                        <path
                          id="Icon"
                          d="M2.2915 3.95801H17.7082M7.2915 16.0413H12.7082M4.7915 9.99967H15.2082"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </g>
                    </svg>
                  </div>
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted ml-2"
                  >
                    Filters
                  </BaseText>
                </div>
                <template #item="{ item }">
                  <div
                    class="group cursor-pointer mr-4 ml-1 pl-2 py-2 flex items-center w-full h-full transition-all duration-200 gap-x-2"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                    >
                      {{ item.item }}
                    </BaseText>
                  </div>
                </template>
                <template #subItem="{ item }">
                  <img
                    v-if="item.image"
                    :src="item.image"
                    class="w-6 h-6 rounded-full ml-2"
                  >
                  <img
                    v-else-if="item.icon === 'folder'"
                    src="../../assets/icons/folder.svg"
                    alt=""
                    class="w-6 h-6 p-0.5 ml-2"
                  >
                  <img
                    v-else-if="
                      item.icon === 'status' || item.icon === 'due date'
                    "
                  >
                  <img
                    v-else
                    src="../../assets/icons/brand-profile.svg"
                    alt=""
                    class="w-6 h-6 rounded-full bg-background-normal p-0.5 ml-2"
                  >
                  <BaseText
                    type="body"
                    size="sm"
                    class="ml-2 py-2"
                  >
                    {{ item.subItem }}
                  </BaseText>
                  <BaseText
                    v-if="item.count"
                    type="body"
                    size="sm"
                    class="ml-2 text-text-subdued"
                  >
                    {{ item.count && ` ${item.count}` }}
                  </BaseText>
                </template>
              </BaseSplitSelect>
            </div>
            <!-- Sort Dropdown -->
            <div class="text-text-muted flex justify-end w-96">
              <BaseSelect
                :items="[
                  { name: 'Newest', rightText: '1 → 9', value: 'desc' },
                  { name: 'Oldest', rightText: '9 → 1', value: 'asc' },
                ]"
                :selected.sync="sortOrder"
                popper-center
                item-value="value"
                placement="null"
                @select="() => {}"
              >
                <div
                  slot="reference"
                  class="flex gap-2"
                >
                  <img src="../../assets/icons/sort.svg">
                  <BaseText
                    type="body"
                    size="sm"
                  >
                    Sort By
                  </BaseText>

                  <img src="../../assets/icons/chevron-down-small.svg">
                </div>

                <template #item="{ item }">
                  <div class="flex justify-between w-full">
                    <BaseText
                      type="label"
                      size="sm"
                    >
                      {{ item.name }}
                    </BaseText>

                    <div class="text-neutral-500">
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        {{ item.rightText }}
                      </BaseText>
                    </div>
                  </div>
                </template>
              </BaseSelect>
            </div>
          </div>
        </transition>
        <!-- Content Section -->
        <transition
          name="fade"
          enter-active-class="transition-opacity duration-300"
          leave-active-class="transition-opacity duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="viewToggleValue === 'Briefs' && !transitioning"
            class="-my-2 overflow-x-auto min-h-screen"
          >
            <div
              class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
            >
              <!-- Briefs Table -->
              <transition
                name="fade"
                enter-active-class="transition-opacity duration-500"
                leave-active-class="transition-opacity duration-500"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div v-if="getBriefs.length">
                  <div>
                    <!-- HEADERS -->
                    <div class="flex border-b border-neutral-50 py-3">
                      <!-- Bulk Checkbox -->
                      <div class="flex items-center justify-end px-1.5">
                        <!-- Checkbox -->
                        <input
                          type="checkbox"
                          class="h-4 w-4 ml-2 mr-3 rounded border-border-normal text-primary-green-100 focus:ring-0"
                          :checked="selectedBriefs.length === getBriefs.length"
                          @click.stop="selectAllBriefs($event)"
                        >
                      </div>

                      <!-- The PR is to displace the ... options -->
                      <div
                        class="flex items-center justify-between w-full pr-5"
                      >
                        <!-- Name -->
                        <div>
                          <BaseText
                            type="body"
                            size="sm"
                            class="text-neutral-400"
                          >
                            Name
                          </BaseText>
                        </div>

                        <!-- Brand -->
                        <div>
                          <BaseText
                            type="body"
                            size="sm"
                            class="text-neutral-400"
                          >
                            Brand
                          </BaseText>
                        </div>

                        <!-- Folder -->
                        <div>
                          <BaseText
                            type="body"
                            size="sm"
                            class="text-neutral-400"
                          >
                            Folder
                          </BaseText>
                        </div>

                        <!-- Status -->
                        <div>
                          <BaseText
                            type="body"
                            size="sm"
                            class="text-neutral-400"
                          >
                            Status
                          </BaseText>
                        </div>

                        <!-- Due Date -->
                        <div>
                          <BaseText
                            type="body"
                            size="sm"
                            class="text-neutral-400"
                          >
                            Due Date
                          </BaseText>
                        </div>

                        <!-- Blank for settings -->
                        <div class="col-span-1" />
                      </div>
                    </div>
                    <img
                      v-if="!filteredBriefs.length && !showAllBriefs"
                      src="../../assets/images/empty-with-filters.gif"
                      alt=""
                      class="mx-auto mt-10 mb-5 shadow"
                    >
                    <BaseText
                      v-if="!filteredBriefs.length && !showAllBriefs"
                      type="body"
                      size="sm"
                      class="text-center mx-auto mb-10 text-gray-700"
                    >
                      <span class="text-lg font-bold my-5 block text-black">
                        Too many filters
                      </span>
                      No briefs found that meet your current filters
                    </BaseText>
                    <BaseButton
                      v-if="!filteredBriefs.length && !showAllBriefs"
                      class="mx-auto mt-10 mb-5 shadow"
                      cta
                      @click="
                        () => {
                          clearFilters = true;
                        }
                      "
                    >
                      Clear filters
                    </BaseButton>
                    <!-- ROWS -->
                    <transition-group
                      v-if="getBriefs.length"
                      name="fade"
                      enter-active-class="transition-opacity duration-200"
                      leave-active-class="transition-opacity duration-200"
                      enter-class="opacity-0"
                      enter-to-class="opacity-100"
                      leave-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <div
                        v-for="brief of briefs"
                        :key="brief.id"
                        class="flex items-center py-5 border-b border-neutral-50 cursor-pointer hover:bg-background-disabled transition-opacity duration-300"
                        :class="{
                          'bg-background-disabled': selectedBriefs.includes(
                            brief.id
                          ),
                          hidden:
                            !filteredBriefs.includes(brief) && !showAllBriefs,
                        }"
                        @click="goToBrief(brief.id)"
                      >
                        <!-- Checkbox -->
                        <div class="flex items-center justify-end px-1.5">
                          <!-- Checkbox -->
                          <input
                            type="checkbox"
                            class="h-4 w-4 ml-2 mr-3 rounded border-border-normal text-primary-green-100 focus:ring-0 cursor-pointer"
                            :checked="selectedBriefs.includes(brief.id)"
                            @click.stop="toggleSelectedBrief(brief.id)"
                          >
                        </div>

                        <div class="grid grid-cols-5 w-full">
                          <!-- Name -->
                          <div>
                            <BaseTooltip placement="top-start">
                              <BaseText
                                slot="reference"
                                type="label"
                                size="sm"
                                class="text-neutral-700 font-medium w-36 truncate"
                              >
                                {{ brief.name }}
                              </BaseText>

                              <!-- Tooltip content -->
                              <div
                                slot="content"
                                class="px-1.5"
                              >
                                {{ brief.name }}
                              </div>
                            </BaseTooltip>
                          </div>
                          <!-- Brand -->
                          <div class="flex items-center gap-2">
                            <img
                              v-if="brief.brandProfileId"
                              class="w-6 h-6 rounded-full"
                              :src="
                                brandProfiles.find(
                                  (bp) => bp.id === brief.brandProfileId
                                )?.image
                              "
                            >

                            <BaseText
                              v-if="brief.brandProfileId"
                              type="body"
                              size="sm"
                              class="font-normal text-neutral-700 w-32 truncate"
                            >
                              {{
                                brandProfiles.find(
                                  (bp) => bp.id === brief.brandProfileId
                                )?.name
                              }}
                            </BaseText>
                            <BaseText
                              v-else
                              type="body"
                              size="sm"
                              class="text-neutral-400 font-normal"
                            >
                              {{ "No Brand Selected" }}
                            </BaseText>
                          </div>

                          <!-- Folder -->
                          <div class="flex items-center gap-2">
                            <!--  -->
                            <img src="../../assets/icons/folder.svg">

                            <BaseText
                              type="body"
                              size="sm"
                              class="text-neutral-700 font-normal w-36 truncate"
                            >
                              {{ getBriefFolder(brief.id).name }}
                            </BaseText>
                          </div>
                          <!-- Status -->
                          <div class="flex items-center">
                            <!--  -->
                            <BaseBadge
                              slot="reference"
                              :blue="brief.status === 'In Progress'"
                              :green="brief.status === 'Completed'"
                              :yellow="brief.status === 'Needs Review'"
                              :gray="brief.status === 'To Do' || !brief.status"
                              dot
                            >
                              <BaseText
                                type="label"
                                size="xs"
                              >
                                {{ brief.status || "No Status" }}
                              </BaseText>
                            </BaseBadge>
                          </div>
                          <!-- Due Date -->
                          <div>
                            <BaseText
                              type="body"
                              size="sm"
                              class="text-neutral-400 font-normal"
                            >
                              {{
                                brief.dueDate
                                  ? formatDate(brief.dueDate)
                                  : "No Date Set"
                              }}
                            </BaseText>
                          </div>
                        </div>
                        <div class="pr-4">
                          <BaseSelect
                            :items="[
                              {
                                name: 'Share',
                                action: shareBrief,
                                divider: true,
                              },
                              { name: 'Duplicate', action: duplicateBrief },
                              {
                                name: 'Archive',
                                action: archiveBrief,
                                divider: true,
                              },
                              { name: 'Delete', action: deleteBrief },
                            ]"
                            text-trigger
                            popper-center
                            no-padding
                            compact
                            item-value="name"
                            @click.native.stop
                            @select="() => {}"
                          >
                            <!-- Three Dots -->
                            <img
                              slot="reference"
                              class="hover:bg-white border border-transparent hover:border-border-normal rounded-md transition"
                              src="../../assets/icons/dots.svg"
                            >

                            <!-- Dropdown Items -->
                            <template #item="{ item }">
                              <div
                                class="flex max-w-max"
                                style="width: max-content"
                                @click="item.action(brief.id)"
                              >
                                <img
                                  v-if="item.name === 'Delete'"
                                  class="pr-2"
                                  src="../../assets/icons/trash.svg"
                                >
                                <img
                                  v-if="item.name === 'Archive'"
                                  class="pr-2"
                                  src="../../assets/icons/archive.svg"
                                >
                                <img
                                  v-if="item.name === 'Duplicate'"
                                  class="pr-2"
                                  src="../../assets/icons/copy.svg"
                                >
                                <img
                                  v-if="item.name === 'Share'"
                                  class="pr-2"
                                  src="../../assets/icons/share.svg"
                                >
                                <BaseText
                                  type="body"
                                  size="sm"
                                >
                                  {{ item.name }}
                                </BaseText>
                              </div>
                            </template>
                          </BaseSelect>
                        </div>
                      </div>
                    </transition-group>
                  </div>
                </div>
              </transition>

              <!-- Check if briefs has initially loaded -->
              <div
                v-if="!getBriefs.length && !getInitialLoad"
                class="w-full h-96 flex items-center justify-center"
              >
                <BaseLoadingLogo />
              </div>

              <!-- Empty State -->
              <div
                v-if="!getBriefs.length && getInitialLoad"
                class="bg-white"
              >
                <div
                  class="flex flex-col items-center justify-center py-5"
                >
                  <div
                    class=" relative mb-5 mt-2"
                  >
                    <img
                      src="../../assets/images/briefs-new.png"
                      alt=""
                      class="w-14 h-14"
                    >
                  </div>
                  <BaseText
                    type="label"
                    size="md"
                    class="text-text-loud text-center"
                  >
                    Get Stared With Briefs
                  </BaseText>
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-muted text-center"
                  >
                    Transform Your Inspiration Into Actionable Ad Briefs
                  </BaseText>
                  <iframe
                    class=" mt-5 max-w-md rounded-lg"
                    width="100%"
                    height="255"
                    src="https://www.youtube.com/embed/gUAspEVLZ4M?si=UqodsQ343WpyCsar"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  />

                  <BaseButton
                    class="mt-5"
                    cta
                    @click="createNewBrief"
                  >
                    <div
                      class="flex items-center justify-center"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="text-white mr-1"
                      >
                        <path
                          d="M7.29134 5.62484H12.708M7.29134 8.95817H12.708M7.29134 12.2915H9.37467M5.62467 17.7082H14.3747C15.2951 17.7082 16.0413 16.962 16.0413 16.0415V3.95817C16.0413 3.0377 15.2951 2.2915 14.3747 2.2915H5.62467C4.7042 2.2915 3.95801 3.0377 3.95801 3.95817V16.0415C3.95801 16.962 4.7042 17.7082 5.62467 17.7082Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Create Brief
                    </div>
                  </BaseButton>
                </div>
              </div>

              <!-- Archived Table -->
              <transition
                name="fade"
                enter-active-class="transition-opacity duration-500"
                leave-active-class="transition-opacity duration-500"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  v-if="archivedBriefs.length && getInitialLoad"
                  class="mt-10"
                >
                  <div v-if="archivedBriefs.length">
                    <div>
                      <!-- HEADERS -->
                      <div
                        class="flex relative border-b border-neutral-50 bg-background-normal py-3 cursor-pointer rounded-md select-none"
                        @click="toggleShowArchived"
                      >
                        <!-- Bulk Checkbox -->
                        <div class="flex items-center justify-end px-1.5">
                          <!-- Checkbox -->
                          <input
                            type="checkbox"
                            class="h-4 w-4 ml-2 mr-3 rounded border-border-normal text-primary-green-100 focus:ring-0"
                            :checked="
                              selectedArchivedBriefs.length ===
                                archivedBriefs.length
                            "
                            @click.stop="selectAllArchivedBriefs($event)"
                          >
                        </div>

                        <div class="grid grid-cols-5 w-full">
                          <!-- Name -->
                          <div>
                            <BaseText
                              type="body"
                              size="sm"
                              class="text-neutral-400"
                            >
                              Archived
                            </BaseText>
                          </div>

                          <!-- Blank for settings -->
                          <div class="absolute right-3.5">
                            <!--  -->
                            <img
                              class="transition-all duration-300 transform"
                              :class="showArchived ? 'rotate-180' : ''"
                              src="../../assets/icons/chevron-top-small.svg"
                            >
                          </div>
                        </div>
                      </div>

                      <!-- ROWS -->
                      <transition
                        name="fade"
                        enter-active-class="transition-opacity duration-500"
                        leave-active-class="transition-opacity duration-500"
                        enter-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-class="opacity-100"
                        leave-to-class="opacity-0"
                      >
                        <div v-if="showArchived">
                          <div
                            v-for="(brief, index) of archivedBriefs"
                            :key="index"
                            class="flex items-center py-5 border-b border-neutral-50 cursor-pointer hover:bg-background-disabled transition"
                            :class="{
                              'bg-background-disabled':
                                selectedArchivedBriefs.includes(brief.id),
                            }"
                            @click="goToBrief(brief.id)"
                          >
                            <!-- Checkbox -->
                            <div class="flex items-center justify-end px-1.5">
                              <!-- Checkbox -->
                              <input
                                type="checkbox"
                                class="h-4 w-4 ml-2 mr-3 rounded border-border-normal text-primary-green-100 focus:ring-0 cursor-pointer"
                                :checked="
                                  selectedArchivedBriefs.includes(brief.id)
                                "
                                @click.stop="
                                  toggleSelectedArchiveBrief(brief.id)
                                "
                              >
                            </div>

                            <div class="grid grid-cols-5 w-full">
                              <!-- Name -->
                              <div>
                                <BaseText
                                  type="label"
                                  size="sm"
                                  class="text-neutral-700 font-medium"
                                >
                                  {{ brief.name }}
                                </BaseText>
                              </div>
                              <!-- Brand -->
                              <div class="flex items-center gap-2">
                                <img
                                  v-if="brief.brandProfileId"
                                  class="w-6 h-6 rounded-full"
                                  :src="
                                    brandProfiles?.find(
                                      (bp) => bp.id === brief?.brandProfileId
                                    )?.image
                                  "
                                >

                                <BaseText
                                  v-if="brief.brandProfileId"
                                  type="body"
                                  size="sm"
                                  class="font-normal text-neutral-700"
                                >
                                  {{
                                    brandProfiles?.find(
                                      (bp) => bp.id === brief?.brandProfileId
                                    )?.name
                                  }}
                                </BaseText>
                                <BaseText
                                  v-else
                                  type="body"
                                  size="sm"
                                  class="text-neutral-400 font-normal"
                                >
                                  {{ "No Brand Selected" }}
                                </BaseText>
                              </div>

                              <!-- Folder -->
                              <div class="flex items-center gap-2">
                                <!--  -->
                                <img src="../../assets/icons/folder.svg">

                                <BaseText
                                  type="body"
                                  size="sm"
                                  class="text-neutral-700 font-normal"
                                >
                                  {{ getBriefFolder(brief.id).name }}
                                </BaseText>
                              </div>
                              <!-- Status -->
                              <div class="flex items-center">
                                <!--  -->
                                <BaseBadge
                                  slot="reference"
                                  :blue="brief.status === 'In Progress'"
                                  :green="brief.status === 'Completed'"
                                  :yellow="brief.status === 'Needs Review'"
                                  :gray="
                                    brief.status === 'To Do' || !brief.status
                                  "
                                  dot
                                >
                                  <BaseText
                                    type="label"
                                    size="xs"
                                  >
                                    {{ brief.status || "No Status" }}
                                  </BaseText>
                                </BaseBadge>
                              </div>
                              <!-- Due Date -->
                              <div>
                                <BaseText
                                  type="body"
                                  size="sm"
                                  class="text-neutral-400 font-normal"
                                >
                                  {{
                                    brief.dueDate
                                      ? formatDate(brief.dueDate)
                                      : "No Date Set"
                                  }}
                                </BaseText>
                              </div>
                            </div>
                            <!-- 3 Dot Options -->
                            <div class="pr-4">
                              <BaseSelect
                                :items="[
                                  { name: 'Delete', action: deleteBrief },
                                  { name: 'Unarchive', action: unarchiveBrief },
                                ]"
                                text-trigger
                                no-padding
                                popper-center
                                compact
                                item-value="name"
                                @click.native.stop
                                @select="() => {}"
                              >
                                <!-- Three Dots -->
                                <img
                                  slot="reference"
                                  class="hover:bg-white border border-transparent hover:border-border-normal rounded-md transition"
                                  src="../../assets/icons/dots.svg"
                                >

                                <!-- Dropdown Items -->
                                <template #item="{ item }">
                                  <div
                                    class="flex max-w-max"
                                    style="width: max-content"
                                    @click="item.action(brief.id)"
                                  >
                                    <img
                                      v-if="item.name === 'Delete'"
                                      class="pr-2"
                                      src="../../assets/icons/trash.svg"
                                    >
                                    <img
                                      v-if="item.name === 'Unarchive'"
                                      class="pr-2"
                                      src="../../assets/icons/unarchive.svg"
                                    >
                                    <BaseText
                                      type="body"
                                      size="sm"
                                    >
                                      {{ item.name }}
                                    </BaseText>
                                  </div>
                                </template>
                              </BaseSelect>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </transition>
        <!-- Confirmation Modal -->
        <ConfirmDeleteModal
          v-if="showConfirmDeleteModal"
          @confirm="deleteSelected"
          @close="showConfirmDeleteModal = false"
        />

        <!-- Bulk Actions Toolbar -->
        <div
          v-if="
            (selectedBriefs.length || selectedArchivedBriefs.length) &&
              viewToggleValue === 'Briefs'
          "
          class="fixed bottom-nav px-4 py-3 rounded-xl flex items-center appear-animation"
        >
          <!-- Selected Number -->
          <div class="flex gap-2 text-text-muted select-none mr-4">
            <div
              class="rounded-full bg-background-normal w-5 h-5 flex items-center justify-center"
            >
              <BaseText
                type="label"
                size="sm"
              >
                {{
                  selectedBriefs.length
                    ? selectedBriefs.length
                    : selectedArchivedBriefs.length
                }}
              </BaseText>
            </div>

            <BaseText
              type="body"
              size="sm"
            >
              selected
            </BaseText>
          </div>

          <!-- Divider -->
          <div class="h-6 border-r border-border-normal mx-3" />

          <!-- Archive -->
          <BaseButton
            v-if="selectedBriefs.length"
            text
            @click="archiveSelectedBriefs"
          >
            <div class="flex items-center gap-1.5">
              <img src="../../assets/icons/archive.svg">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Archive
              </BaseText>
            </div>
          </BaseButton>
          <BaseButton
            v-else
            text
            @click="unarchiveSelectedBriefs"
          >
            <div class="flex items-center gap-1.5">
              <img src="../../assets/icons/unarchive.svg">
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted"
              >
                Unarchive
              </BaseText>
            </div>
          </BaseButton>

          <!-- Divider -->
          <div class="h-6 border-r border-border-normal mx-3" />

          <!-- Delete -->
          <BaseButton
            text
            @click="showConfirmDeleteModal = true"
          >
            <div class="flex items-center gap-1.5">
              <img src="../../assets/icons/trash.svg">
              <BaseText
                type="label"
                size="sm"
                class="text-secondary-red-100"
              >
                Delete
              </BaseText>
            </div>
          </BaseButton>
        </div>

        <!-- Brand Profile View -->
        <div class="w-full justify-center">
          <transition
            name="fade"
            enter-active-class="transition-opacity duration-700"
            leave-active-class="transition-opacity duration-500"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="viewToggleValue === 'Brand Profiles' && !transitioning"
              class="p-6 text-center"
              style="max-width: 1200px"
            >
              <BaseModal
                v-if="selectedBrandProfile"
                :card-width="'650px'"
                @close="changeProfile(null)"
              >
                <div
                  style="max-height: calc(90vh - 200px)"
                  class="px-4 overflow-y-scroll h-full"
                >
                  <BrandProfileDetails
                    small-card
                    :always-show-more="true"
                    :brand-profile="selectedBrandProfile"
                    @changeProfile="changeProfile"
                  />
                </div>
              </BaseModal>

              <BrandProfileGrid
                v-if="!selectedBrandProfile"
                :brand-profiles="brandProfiles"
                brand-profiles-page
                @createNewBrand="createNewBrand"
                @selectBrandProfile="changeProfile($event)"
              />
            </div>
          </transition>
        </div>
        <CreateAdvertisementModal
          v-if="showAdvertisementModal"
          :starting-tab="manualUpload ? 'manual' : 'extension'"
          @close="showAdvertisementModal = false"
          @save="fetchAdvertisements(false)"
        />

        <CreateBriefModal
          v-if="showCreateBriefModal"
          :initialFolder="modalInitialFolder"
          @close="showCreateBriefModal = false"
        />

        <TutorialModal
          v-if="showTutorialModal"
          @close="showTutorialModal = false"
        />
        <FolderModal
          v-if="showFolderModal"
          @close="showFolderModal = false"
        />
        <ManageBoardModal
          v-if="showManageBoardModal"
          :board="{}"
          @close="showManageBoardModal = false"
        />
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MainLayout from '../../layouts/MainLayout'
import ConfirmDeleteModal from '../../components/globals/ConfirmDeleteModal.vue'
import FirebaseAPI from '@/api/firebase'
import firebase from '@/api/config/FirebaseConfig'
import moment from 'moment'

import TutorialModal from '../../components/advertisements/TutorialModal.vue'
import BrandProfileGrid from '../../components/briefsV2/brandProfile/BrandProfileGrid.vue'
import BrandProfileDetails from '../../components/briefsV2/brandProfile/BrandProfileDetails.vue'
import CreateBriefModal from '../../components/briefsV2/CreateBriefModal.vue'
import FolderModal from '../../components/sidebar/FolderModal.vue'
import ManageBoardModal from '../../components/boards/ManageBoardModal'
import CreateAdvertisementModal from '../../components/advertisements/AdvertisementCreateModal'
import BaseText from '../../components/globals/BaseText.vue'
import { initial } from 'lodash'

export default {
  name: 'BriefsView',
  components: {
    ConfirmDeleteModal,
    MainLayout,
    BrandProfileGrid,
    BrandProfileDetails,
    CreateBriefModal,
    TutorialModal,
    FolderModal,
    ManageBoardModal,
    CreateAdvertisementModal,
    BaseText
  },
  data () {
    return {
      selectedBrandProfile: null,
      selectedFolders: [],
      selectedStatus: [],
      createdByUsers: [],
      filtersApplied: false,
      showArchived: false,
      docOrder: 'desc',
      sortOrder: [{ name: 'Newest', value: 'desc' }],
      viewToggleValue: 'Briefs',
      brandProfiles: [],
      textSearch: '',
      showCreateBriefModal: false,
      modalInitialFolder: { name: 'Default Folder', id: 'default' },
      showTutorialModal: false,
      archivedBriefs: [],
      showManageBoardModal: false,
      showFolderModal: false,
      manualUpload: false,
      showAdvertisementModal: false,
      // For Bulk Actions
      selectedBriefs: [],
      selectedArchivedBriefs: [],
      showConfirmDeleteModal: false,
      transitioning: false,
      filteredBriefs: [],
      showAllBriefs: true,
      clearFilters: false
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', [
      'getTeam',
      'isFreeTier',
      'getUserFreeTrialDate'
    ]),
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('BriefsModule', ['getBriefs', 'getInitialLoad']),
    ...mapGetters('BoardsModule', ['getBoards', 'getFolders']),
    ...mapGetters('TagsModule', ['getTags']),
    briefs () {
      let duplicateArr = [...this.getBriefs]

      // Filter for folder
      if (this.selectedFolders.length) {
        // Find the folder to filter for
        const folder = this.getFolders.find(
          (folder) => folder.id === this.selectedFolders[0].id
        )

        // Get all briefs for that folder
        duplicateArr = folder.briefIds
          ? folder.briefIds
            .map((briefId) =>
              this.getBriefs.find((brief) => brief.id === briefId)
            )
            .filter((brief) => brief)
          : []
      }

      // Filter for status
      if (this.selectedStatus.length) {
        duplicateArr = duplicateArr.filter((brief) => {
          if (this.selectedStatus[0].name === 'No Status') {
            return (
              !brief.status || brief.status === this.selectedStatus[0].name
            )
          }

          return brief.status === this.selectedStatus[0].name
        })
      }

      if (this.textSearch) {
        duplicateArr = duplicateArr.filter((brief) => {
          return brief.name
            .toLowerCase()
            .includes(this.textSearch.toLowerCase())
        })
      }

      if (this.sortOrder[0].value === 'asc') {
        return duplicateArr.sort((a, b) =>
          a.createdAt <= b.createdAt ? 1 : -1
        )
      }

      return duplicateArr.sort((a, b) => (a.createdAt >= b.createdAt ? 1 : -1))
    }
  },
  watch: {
    filteredBriefs () {
      this.showAllBriefs = false
    }
  },
  async mounted () {
    await this.getCreatedByUsers()
    await this.fetchBrandProfiles()
    await this.getArchivedBriefs()

    if (this.$route.query.folderId) {
      const folderId = this.$route.query.folderId
      if (folderId !== 'default') this.modalInitialFolder = this.getFolders.find(folder => folder.id === folderId)
      this.showCreateBriefModal = true
      history.replaceState({}, null, '/briefs')
    }
  },

  // mounted() {
  //   const { utm_campaign, utm_medium, utm_source } = this.$route.query;
  //   window.analytics.page("Brief", {
  //     pageVariant: "variant-1",
  //     utmCampaign: utm_campaign,
  //     utmSource: utm_medium,
  //     utmMedium: utm_source,
  //   });
  // },
  methods: {
    ...mapActions('BriefsModule', ['fetchBriefs']),
    ...mapActions('BoardsModule', ['fetchFolders']),
    async duplicateBrief (briefId) {
      const briefToDuplicate = this.getBriefs.find(
        (brief) => brief.id === briefId
      )

      // Create a new empty brief
      const briefPayload = {
        ...briefToDuplicate,
        name: `${briefToDuplicate.name} - Copy`,
        createdAt: new Date().getTime(),
        modifiedAt: new Date().getTime()
      }

      delete briefPayload.id

      // Check for team
      if (this.getTeam) {
        briefPayload.teamId = this.getTeam.id
      }

      const brief = await FirebaseAPI.Briefs.create(briefPayload)

      // Update sidebar
      await this.fetchBriefs()


      // Send the user to the edit page for the new brief
      this.$router.push({ name: 'EditBriefView', params: { id: brief.id } })

      this.loading = false
    },
    async shareBrief (briefId) {
      await navigator.clipboard.writeText(
        process.env.VUE_APP_BASE_URL + '/share/briefs/' + briefId
      )

      this.$showAlert({
        message: 'Copied share link to clipboard!',
        type: 'success'
      })
    },
    transitionTimeout () {
      setTimeout(() => {
        this.transitioning = false
      }, 400)
    },
    openAdModal () {
      this.manualUpload = false
      this.showAdvertisementModal = true
    },
    openManualUpload () {
      this.manualUpload = true
      this.showAdvertisementModal = true
    },
    async changeProfile (brandProfile) {
      this.selectedBrandProfile = brandProfile

      this.fetchBrandProfiles()
    },
    async createNewBrand () {
      const user = firebase.auth().currentUser

      const newBrandPayload = {
        name: 'Brand Name',
        createdBy: user.id,
        createdAt: +new Date(),
        modifiedAt: +new Date()
      }

      if (this.getTeam) {
        newBrandPayload.teamId = this.getTeam.id
      }

      // Create one in firestore
      const brandProfileInstance = await FirebaseAPI.BrandProfiles.create(
        newBrandPayload
      )
      newBrandPayload.id = brandProfileInstance.id

      // Create a new brand
      this.selectedBrandProfile = newBrandPayload
    },
    async fetchBrandProfiles () {
      if (this.getTeam) {
        const brandProfiles = await FirebaseAPI.BrandProfiles.getAllByTeam(
          this.getTeam.id
        )
        this.brandProfiles = brandProfiles
      } else {
        const brandProfiles = await FirebaseAPI.BrandProfiles.getAllByUser()
        this.brandProfiles = brandProfiles
      }
    },
    selectAllBriefs (e) {
      if (!e.target.checked) {
        this.selectedBriefs = []
        return
      }

      this.selectedArchivedBriefs = []
      this.selectedBriefs = this.briefs.map((brief) => brief.id)
    },
    selectAllArchivedBriefs (e) {
      if (!e.target.checked) {
        this.selectedArchivedBriefs = []
        return
      }

      this.selectedBriefs = []
      this.showArchived = true
      this.selectedArchivedBriefs = this.archivedBriefs.map(
        (brief) => brief.id
      )
    },
    toggleSelectedArchiveBrief (briefId) {
      if (this.selectedBriefs.length) {
        this.selectedBriefs = []
      }

      if (this.selectedArchivedBriefs.includes(briefId)) {
        this.selectedArchivedBriefs = this.selectedArchivedBriefs.filter(
          (id) => id !== briefId
        )
        return
      }

      this.selectedArchivedBriefs = this.selectedArchivedBriefs.concat(briefId)
    },
    toggleSelectedBrief (briefId) {
      if (this.selectedArchivedBriefs.length) {
        this.selectedArchivedBriefs = []
      }

      if (this.selectedBriefs.includes(briefId)) {
        this.selectedBriefs = this.selectedBriefs.filter(
          (id) => id !== briefId
        )
        return
      }

      this.selectedBriefs = this.selectedBriefs.concat(briefId)
    },
    getFormattedTimes () {
      const datesMap = {}
      const formattedTimes = []

      this.getBriefs.forEach((brief) => {
        const dueDate = brief.dueDate
        const formattedDate = this.formatDueDate(dueDate)

        if (!datesMap[formattedDate]) {
          datesMap[formattedDate] = 1
          formattedTimes.push({
            subItem: formattedDate,
            timestamp: [dueDate],
            briefIds: [brief.id],
            count: 1,
            icon: 'due date',
            hover: true,
            id: brief.id
          })
        } else {
          datesMap[formattedDate]++
          const index = formattedTimes.findIndex(
            (item) => item.subItem === formattedDate
          )
          if (index !== -1) {
            formattedTimes[index].briefIds.push(brief.id)
            formattedTimes[index].timestamp.push(dueDate)
            formattedTimes[index].count = datesMap[formattedDate]
          }
        }
      })

      return formattedTimes
    },
    formatDueDates (timestamps) {
      if (!Array.isArray(timestamps)) {
        return []
      }

      const formattedDates = []
      const timestampCount = {}

      for (let i = 0; i < timestamps.length; i++) {
        const timestamp = timestamps[i]
        const count = timestampCount[timestamp] || 0
        timestampCount[timestamp] = count + 1

        const formattedDate = this.formatDueDate(timestamp, count)
        formattedDates.push(formattedDate)
      }

      return formattedDates
    },
    formatDueDate (timestamp) {
      if (!timestamp) {
        return 'No due date'
      }

      const now = new Date()
      const dueDate = new Date(timestamp)
      const timeDiff = dueDate.getTime() - now.getTime()

      const oneDay = 24 * 60 * 60 * 1000
      const oneMonth = 30 * oneDay
      const oneYear = 365 * oneDay

      if (timeDiff < oneDay) {
        const days = Math.abs(Math.ceil(timeDiff / (1000 * 60 * 60 * 24)))
        return `${days} ${days === 1 ? 'day' : 'days'} ${
          timeDiff < 0 ? 'ago' : 'from now'
        }`
      } else if (timeDiff < oneMonth) {
        const months = Math.abs(Math.floor(timeDiff / oneMonth))
        return `${months} ${months === 1 ? 'month' : 'months'} ${
          timeDiff < 0 ? 'ago' : 'from now'
        }`
      } else if (timeDiff < oneYear) {
        const years = Math.abs(Math.floor(timeDiff / oneYear))
        return `${years} ${years === 1 ? 'year' : 'years'} ${
          timeDiff < 0 ? 'ago' : 'from now'
        }`
      } else {
        return 'More than a year from now'
      }
    },
    async deleteSelected () {
      if (!this.selectedBriefs.length) return
      await Promise.all(this.selectedBriefs.map((briefId) => this.deleteBrief(briefId, false)))

      this.selectedBriefs = []
      this.selectedArchivedBriefs = []

      await this.fetchBriefs()
      await this.fetchFolders()

      this.showConfirmDeleteModal = false
      this.$showAlert({
        message: 'Deleted Selected Briefs',
        type: 'success'
      })
    },
    async deleteBrief (briefId, refresh = true) {
      await FirebaseAPI.Briefs.remove(briefId)

      // Remove the brief from its folder (if not default)
      const folder = this.getBriefFolder(briefId)
      if (folder.id !== 'default') {
        const toRemovePayload = {
          briefIds: folder.briefIds.filter((id) => id !== briefId)
        }
        await FirebaseAPI.Folders.update(folder.id, toRemovePayload)
      }

      if (refresh) {
        await this.getArchivedBriefs()
        await this.fetchBriefs()
        await this.fetchFolders()
        this.$showAlert({
          message: 'Deleted Brief',
          type: 'success'
        })
      }
    },
    async archiveSelectedBriefs () {
      if (!this.selectedBriefs.length) return

      await Promise.all(
        this.selectedBriefs.map((briefId) => {
          return FirebaseAPI.Briefs.update(briefId, { archived: true })
        })
      )

      this.selectedBriefs = []

      await this.fetchBriefs()
      await this.getArchivedBriefs()

      this.$showAlert({
        message: 'Archived Selected Briefs',
        type: 'success'
      })
    },
    async unarchiveSelectedBriefs () {
      if (!this.selectedArchivedBriefs.length) return

      await Promise.all(
        this.selectedArchivedBriefs.map((briefId) => {
          return FirebaseAPI.Briefs.update(briefId, { archived: true })
        })
      )

      this.selectedArchivedBriefs = []

      await this.fetchBriefs()
      await this.getArchivedBriefs()

      this.$showAlert({
        message: 'Archived Selected Briefs',
        type: 'success'
      })
    },
    async unarchiveBrief (briefId) {
      await FirebaseAPI.Briefs.update(briefId, { archived: false })
      await this.getArchivedBriefs()
      await this.fetchBriefs()

      this.$showAlert({
        message: 'Unarchived Brief',
        type: 'success'
      })
    },
    async archiveBrief (briefId) {
      await FirebaseAPI.Briefs.update(briefId, { archived: true })
      await this.getArchivedBriefs()
      await this.fetchBriefs()

      this.$showAlert({
        message: 'Archived Brief',
        type: 'success'
      })
    },
    async toggleShowArchived () {
      if (this.showArchived) {
        this.showArchived = false
        return
      }

      this.showArchived = true

      await this.getArchivedBriefs()
    },
    async getArchivedBriefs () {
      this.archivedBriefs = await FirebaseAPI.Briefs.getAllArchived(
        this.getTeam?.id
      )
    },
    async getCreatedByUsers () {
      console.log('getting created by users')
      const createdByUsers = {}

      this.getBriefs.forEach(async (brief) => {
        const user = await FirebaseAPI.Users.getById(brief.createdBy)

        createdByUsers[brief.createdBy] =
          `${user.first_name || ''} ${user.last_name || ''}`.trim() ||
          user.name ||
          user.email
      })

      this.createdByUsers = createdByUsers
      console.log('created by users', this.createdByUsers)
    },
    getCreatedBy () {
      return (userId) => this.createdByUsers[userId]
    },
    getBriefFolder (briefId) {
      const briefFolder = this.getFolders.find((folder) =>
        folder.briefIds?.includes(briefId)
      )
      return briefFolder ? briefFolder : {id: 'default', name: 'Default Folder'}
    },
    formatDate (timeStamp) {
      return moment(timeStamp).format('MMM Do')
    },
    goToBrief (briefId) {
      this.$router.push({ name: 'EditBriefView', params: { id: briefId } })
    },
    async createNewBrief () {
      // Create a new empty brief
      const briefPayload = {
        name: 'Untitled Brief',
        createdAt: new Date().getTime(),
        components: [
          {
            type: 'description',
            value: ''
          }
        ]
      }

      // Check for team
      if (this.getTeam) {
        briefPayload.teamId = this.getTeam.id
      }

      const brief = await FirebaseAPI.Briefs.create(briefPayload)

      // Update sidebar
      await this.fetchBriefs()

      // Send the user to the edit page for the new brief
      this.$router.push({ name: 'EditBriefView', params: { id: brief.id } })
    },
    toggleOrder () {
      if (this.docOrder === 'asc') {
        this.docOrder = 'desc'
      } else {
        this.docOrder = 'asc'
      }

      // Fetch briefs
    }
  },
  metaInfo: {
    title: 'Foreplay.co | Briefs'
  }
}
</script>

<style scoped>
.bottom-nav {
  background: rgba(255, 255, 255, 0.92);
  box-shadow: 0px 8px 24px -12px rgba(2, 23, 59, 0.36),
    0px 1px 2px rgba(164, 172, 185, 0.16),
    0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  backdrop-filter: blur(5px);

  z-index: 57;
  transform: translateX(-50%);

  /* 138 is half of the sidebar width */
  left: calc(50% + 138px);
  bottom: 38px;

  border-radius: 12px;
}

.appear-animation {
  animation: appear 300ms ease-in;
  animation-iteration-count: 1;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
</style>
