<template>
  <div>
    <lottie
      :options="defaultOptions"
      :height="30"
      :width="30"
      @animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import hoverAnimationData from '@/assets/lotties/hover-animation.json'

export default {
  name: 'ShuffleHoveredLottie',
  components: {
    lottie: Lottie
  },
  props: {
    autoplay: {
      type: Boolean,
      default: false
    },
    isHovered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultOptions: { animationData: hoverAnimationData, loop: true, autoplay: this.autoplay }
    }
  },
  watch: {
    isHovered (val) {
      if (val) {
        this.anim.loop = true
        this.anim.setDirection(1)
        this.anim.play()
      } else {
        this.anim.loop = false
        this.anim.setDirection(-1)
        this.anim.play()
      }
    }
  },
  mounted () {
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim
      this.anim.setSpeed(1.5)
    }
  }
}
</script>

<style scoped>

</style>
