<template>
  <div class="relative">
    <div class="w-full overflow-hidden">
      <div class="flex items-center gap-2 px-3 py-1 mb-1 rounded-lg bg-transparent hover:bg-background-normal 
      transition-colors ease-in-out whitespace-nowrap"
      :class="{'anim-del-tag-row': rowIsDeleting, 'bg-background-normal': nameFocused}">
        <div class="hover-shadow relative p-1 rounded-md bg-transparent hover:bg-white transition ease-in-out cursor-pointer"
        @click="showColorPicker = !showColorPicker">
          <TagIcon :stroke="tag.color ? colors[tag.color].stroke : colors['gray'].stroke"
          :fill="tag.color ? colors[tag.color].fill : colors['gray'].fill"/>
        </div>
        <input v-model="editedName" v-on-clickaway="() => {editedName = tag.name}" @keyup.enter.prevent="editTagName"
        ref="editNameInput" @focus="nameFocused = true" @blur="nameFocused = false" autocomplete="off" spellcheck="false"
        class="name-input flex-grow outline-none text-sm text-text-muted rounded-md py-1 px-2"
        :class="{'name-input-focused': nameFocused}"/>
        <!-- Display the number of ads that have this tag assigned -->
        <div class="px-2 py-1 text-xs text-text-subdued text-center no-select">
          {{ tag.adCount ? tag.adCount : 0 }} Ad{{ tag?.adCount !== 1 ? 's' : '' }}
        </div>
        <div class="group flex items-center p-1 rounded-md cursor-pointer bg-transparent no-select"
        :class="{'anim-del-btn-color-to-red z-50': deleteActive, 'anim-del-btn-color-from-red': doAnimateDelete && !deleteActive,
        'hover-shadow hover-bg-white': !deleteActive}"
        @click="deleteClicked" v-on-clickaway="() => {deleteActive = false}">
          <DeleteIcon class="transition-colors ease-in-out" 
          :class="{'text-white': deleteActive, 'text-icon-normal group-hover:text-black': !deleteActive}"/>
          <div class="w-0 leading-3 text-center text-white font-medium overflow-hidden"
          :class="{'anim-del-text-expand': deleteActive, 'anim-del-text-shrink': doAnimateDelete && !deleteActive }">
            Delete
          </div>
        </div>
      </div>
    </div>
    <TagColorPicker v-if="showColorPicker" :tag="tag" @colorChange="editTagColor"
    v-on-clickaway="() => {showColorPicker = false}"/>
  </div>
</template>

<script>
  import TagColorPicker from './TagColorPicker.vue'
  import TagIcon from '../../globals/Icons/TagIcon.vue'
  import DeleteIcon from '../../globals/Icons/DeleteIcon.vue'
  import TAG_COLORS from '../../../utils/TagColors'
  import { mixin as clickaway } from 'vue-clickaway2'

  export default {
    name: 'TagRow',
    mixins: [clickaway],
    components: {
      TagColorPicker,
      TagIcon,
      DeleteIcon
    },
    props: {
      tag: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        editedName: this.tag.name,
        nameFocused: false,
        showColorPicker: false,
        colors: TAG_COLORS,

        // Delete animation flags
        deleteActive: false,
        doAnimateDelete: false,
        rowIsDeleting: false
      }
    },
    methods: {
      deleteClicked () {
        this.doAnimateDelete = true;
        if (!this.deleteActive) {
          this.deleteActive = true;
        } else {
          this.rowIsDeleting = true;
          this.$emit('delete', this.tag);
        }
      },
      editTagName () {
        const newTag = { ...this.tag, name: this.editedName };
        this.$emit('edit', newTag);
        this.$refs.editNameInput.blur();
      },
      editTagColor (color) {
        const newTag = { ...this.tag, color: color };
        this.$emit('edit', newTag);
        this.tag.color = color;
      }
    }
  }
</script>

<style scoped lang="css">
  .hover-shadow {
    transition: box-shadow 150ms ease-in-out;
  }
  .hover-shadow:hover {
    box-shadow: 0px 24px 32px -12px rgba(53, 57, 75, 0.12), 0px 1px 2px 0px rgba(162, 172, 186, 0.08), 0px 1px 2px 0px rgba(162, 172, 186, 0.16);
  }
  .name-input {
    transition: box-shadow 150ms ease-in-out, background-color 150ms ease-in-out;
    background-color: transparent;
  }
  .name-input-focused {
    box-shadow: 0px 1px 2px 0px rgba(162, 172, 186, 0.08), 0px 1px 2px 0px rgba(162, 172, 186, 0.16);
    background-color: white !important;
  }
  .hover-bg-white {
    transition: background-color 150ms ease-in-out;
  }
  .hover-bg-white:hover {
    /* We need !important to override the animation's 'forwards' fill rule, in case the red to transparent anim played */
    background-color: white !important;
  }

  /* ANIMATIONS */
  .anim-del-text-expand { animation: deleteTextExpand 150ms ease-in-out 0s 1 forwards; }
  .anim-del-text-shrink { animation: deleteTextShrink 150ms ease-in-out 0s 1 forwards; }
  .anim-del-btn-color-to-red { animation: deleteBtnColorToRed 150ms ease-in-out 0s 1 forwards; }
  .anim-del-btn-color-from-red { animation: deleteBtnColorFromRed 150ms ease-in-out 0s 1 forwards; }
  .anim-del-tag-row { animation: deleteTagRow 200ms ease-in 0s 1 forwards; }
  @keyframes deleteTextExpand {
    from { width: 0px; }
    to { width: 54px; }
  }
  @keyframes deleteTextShrink {
    from { width: 54px; }
    to { width: 0px; }
  }
  @keyframes deleteBtnColorToRed {
    from { 
      background-color: transparent;
      box-shadow: none;
    }
    to { 
      background-color: #F4003A;
      box-shadow: 0px 1px 2px 0px rgba(124, 0, 31, 0.50), 0px 0px 0px 1px #C80031, 0px 24px 32px -12px rgba(53, 57, 75, 0.12), 0px 1px 2px 0px rgba(162, 172, 186, 0.08), 0px 1px 2px 0px rgba(162, 172, 186, 0.16);
    }
  }
  @keyframes deleteBtnColorFromRed {
    from { 
      background-color: #F4003A;
      box-shadow: 0px 1px 2px 0px rgba(124, 0, 31, 0.50), 0px 0px 0px 1px #C80031, 0px 24px 32px -12px rgba(53, 57, 75, 0.12), 0px 1px 2px 0px rgba(162, 172, 186, 0.08), 0px 1px 2px 0px rgba(162, 172, 186, 0.16);
    }
    to { 
      background-color: transparent;
      box-shadow: none;
    }
  }
  @keyframes deleteTagRow {
    0% {
      opacity: 1;
      height: 28px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-bottom: 4px;
      transform: translateX(0);
    }
    25% {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    100% {
      opacity: 0;
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      transform: translateX(110%);
    }
  }

  /* class is applied to elements we don't want users to be able to select text on */
  .no-select {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
</style>