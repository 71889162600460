<template>
  <BaseModal
    :no-padding="true"
    :card-width="'680px'"
    no-toolbar
    @close="$emit('close')"
  >
    <template
      #default
    >
      <div
        style="max-height: calc(90vh - 50px); min-height: 300px;"
        class="overflow-y-scroll scrollbar-hide h-full flex flex-col justify-between"
      >
        <!-- Header -->
        <div class="relative border-b border-border-normal">
          <!-- Left Side -->
          <div class="flex gap-3 p-5">
            <!-- Image -->
            <div class="p-1 rounded-xl border border-border-normal">
              <img
                style="height: 36px; box-shadow: 0px 8px 5px rgba(6, 7, 15, 0.09), 0px 3px 3px rgba(6, 7, 15, 0.15), 0px 1px 2px rgba(6, 7, 15, 0.18);"
                class="rounded-lg"
                src="../../../../assets/images/ai-rewrite.png"
              >
            </div>
            <!-- Text -->
            <div>
              <BaseText
                class="text-text-muted"
                type="label"
                size="md"
              >
                AI Rewrite
              </BaseText>
              <BaseText
                class="text-text-normal"
                type="body"
                size="sm"
              >
                Generate new versions of sections seamlessly
              </BaseText>
            </div>
          </div>
        </div>

        <!-- Body -->
        <div class="grid grid-cols-2 h-full">
          <!-- Heading -->
          <div class="flex items-center gap-1.5 px-4 py-3 border-r border-border-normal border-b">
            <!-- Icon -->
            <img src="../../../../assets/icons/document-gray.svg">
            <!-- Text -->
            <BaseText
              class="text-text-muted"
              type="label"
              size="md"
            >
              Script
            </BaseText>
          </div>

          <div class="flex items-center gap-1.5 px-4 py-3 border-b border-border-normal">
            <!-- Icon -->
            <img src="../../../../assets/icons/ai-gray.svg">
            <!-- Text -->
            <BaseText
              class="text-text-muted"
              type="label"
              size="md"
            >
              Re-written versions
            </BaseText>
          </div>
        </div>
        <div class="grid grid-cols-2 h-full">
          <!-- Edit Section -->
          <div class="flex flex-col justify-between h-full py-4 px-5 border-r border-border-regular ">
            <BaseContentEditable
              custom-text-classes="text-body-sm font-normal text-text-muted"
              :current-value="rewriteCopy"
            />
            <div class="px-2 w-full flex justify-center">
              <BaseButton
                outlined
                :disabled="loading"
                padding-x="20"
                @click="rewriteWithAi"
              >
                <div class="flex items-center">
                  <img
                    class="mr-1.5"
                    src="../../../../assets/icons/ai-gray.svg"
                  >

                  <BaseText
                    type="label"
                    size="sm"
                  >
                    Re-write Script
                  </BaseText>
                </div>
              </BaseButton>
            </div>
          </div>

          <!-- AI Suggestions -->
          <div class="h-full pt-2">
            <!-- Variations -->
            <div
              class="overflow-y-scroll pb-2"
              style="height: 50vh;"
            >
              <!-- Loader -->
              <div
                v-if="loading"
                class="flex flex-col items-center py-16"
              >
                <div
                  class="border border-border-normal rounded-full p-1"
                >
                  <!-- Loading Video -->
                  <div
                    class="rounded-full overflow-hidden"
                    style="filter: drop-shadow(0px 26px 10px rgba(8, 17, 17, 0.04)) drop-shadow(0px 14px 9px rgba(8, 17, 17, 0.12)) drop-shadow(0px 6px 6px rgba(8, 17, 17, 0.2)) drop-shadow(0px 2px 4px rgba(8, 17, 17, 0.24)) drop-shadow(0px 0px 0px rgba(8, 17, 17, 0.24));"
                  >
                    <video
                      style="height: 96px;"
                      preload="metadata"
                      playsinline
                      autoplay
                      muted
                      loop
                    >
                      <source
                        src="../../../../assets/videos/foreplay-loading_anubis.webm"
                        type="video/mp4"
                      >
                    </video>
                  </div>
                </div>

                <!-- Text -->
                <div class="mt-3 text-text-muted">
                  <BaseText
                    type="label"
                    size="md"
                  >
                    Re-writing your script
                  </BaseText>
                </div>
                <div class="mt-1 text-text-normal">
                  <BaseText
                    type="body"
                    size="sm"
                  >
                    This can take up to a minute
                  </BaseText>
                </div>
              </div>

              <div v-if="!loading && aiVariations.length !== 0">
                <div
                  v-for="(variation, index) of aiVariations"
                  :key="index"
                  class="mt-2 py-3 px-4 border mx-3 border-border-normal rounded-md cursor-pointer transition duration-300"
                  :class="rewriteCopy === variation.copy || rewriteCopy === variation.visuals ? 'ring-2 ring-primary-green-50 ring-offset-1' : 'hover:bg-background-disabled'"
                  :style="rewriteCopy === variation.copy || rewriteCopy === variation.visuals ? 'box-shadow: 0px 0px 0px 1px #1F8E6B, 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px rgba(0, 159, 109, 0.5), inset 0px -1px 12px rgba(255, 255, 255, 0.12);' : ''"
                  @click="rewriteCopy = variation.copy || variation.visuals"
                >
                  <BaseText
                    class="text-text-muted"
                    type="body"
                    size="sm"
                  >
                    {{ variation.copy || variation.visuals }}
                  </BaseText>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Save Area -->
        <div class="w-full border-t flex justify-end px-5 py-5">
          <BaseButton
            primary
            @click="replaceCopy"
          >
            Replace With Selection
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'

import API from '@/api'
import animationData from '../../../../assets/lotties/loadingV2Green.json'

export default {
  name: 'AiRewriteModal',
  components: {
    Lottie
  },
  props: {
    panels: {
      type: Array,
      required: true
    },
    rewriteSection: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rewriteCopy: '',
      aiVariations: [],
      loading: false,
      defaultOptions: { animationData: animationData, loop: true, autoplay: true }
    }
  },
  mounted () {
    this.rewriteCopy = this.rewriteSection.copy
  },
  methods: {
    async rewriteWithAi () {
      this.loading = true

      try {
        const data = await API.Briefs.generateBriefVariations(this.panels, this.rewriteSection.index, this.rewriteCopy, this.rewriteSection.section)
        this.aiVariations = data.variations
        console.log(data)
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: 'Something went wrong generating variations.',
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    replaceCopy () {
      this.$emit('replace-copy', this.rewriteCopy, this.rewriteSection.index, this.rewriteSection.section, this.rewriteSection.id)
      this.$emit('close')
    }
  }
}
</script>
