<template>
  <BaseCard
    v-if="inspirationBoardInformation.length > 0"
    class="w-96 h-max mt-5 rounded-xl bg-white shadow-sm custom-width"
    border
  >
    <div
      class="flex justify-between"
      :class="inspirationBoardExpanded ? 'border-b border-neutral-50 border-opacity-80' : '' "
    >
      <div
        class="px-6 rounded-full flex pt-4 pb-4"
      >
        <img
          src="../../../assets/icons/magic-stick-top.svg"
          alt=""
          class=" w-10 h-10 rounded-full p-2.5 overflow-visible"
          style="box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
        >
        <div
          class="w-full ml-3 flex items-center"
        >
          <BaseText
            type="label"
            size="md"
            class="text-text-muted"
          >
            Inspiration
          </BaseText>
        </div>
      </div>
      <img
        src="../../../assets/icons/chevron-down-small.svg"
        alt=""
        :class="inspirationBoardExpanded ? 'rotate-180 transform' : ''"
        class="w-5 mr-5 transition-transform duration-300 cursor-pointer"
        @click="toggleExpanded()"
      >
    </div>
    <div
      class="px-2 pt-2"
    >
      <AdvertisementList
        v-if="advertisements.length > 0 && inspirationBoardExpanded"
        :advertisements="advertisements && advertisements"
        small-ads
      />
    </div>
  </basecard>
</template>

<script>
import { mapGetters } from 'vuex'
import AdvertisementList from '../../advertisements/AdvertisementList.vue'
import firebase from '@/api/config/FirebaseConfig'
export default {
  name: 'InspirationBoard',
  components: {
    AdvertisementList
  },
  props: {
    inspirationBoardExpanded: Boolean,
    inspirationBoardInformation: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loadingAdvertisements: false,
      advertisements: []
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getInspoAds'])
  },
  async mounted () {
    // Fetch Briefs
    await this.fetchAdvertisements()
  },
  methods: {
    toggleExpanded () {
      if (this.inspirationBoardExpanded) this.$emit('update:inspirationBoardExpanded', false)
      else this.$emit('update:inspirationBoardExpanded', true)
    },
    async fetchAdvertisements () {
      this.loadingAdvertisements = true

      try {
        const db = firebase.firestore()

        // Get ads
        this.advertisements = await Promise.all(
          this.inspirationBoardInformation.length ? this.inspirationBoardInformation.map(async (adId) => {
            const adDoc = await db.collection('ads').doc(adId).get()

            return {
              ...adDoc.data(),
              id: adId
            }
          }) : []
        )
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    }
  }
}
</script>

<style scoped>
.custom-width {
  width: 95%;
}

@media (min-width: 640px) {
  .custom-width {
    width: 584px;
  }
}
</style>
