<template>
  <!-- Advertisement Saving List -->
  <div class="advertisement-saving-list">
    <!-- Title -->
    <div
      class="advertisement-saving-list--title"
    >
      Queue ({{ advertisements.length - getAdsBeingSaved.length }}/{{ advertisements.length }})
    </div>

    <!-- Ads List -->
    <div class="advertisement-saving-list--list">
      <div
        v-for="(ad, index) in advertisements"
        :key="index"
        class="advertisement-saving-list--list--item"
        :class="getAdClasses(ad)"
        @click="handleClick(ad)"
      >
        <i
          class="fas mr-1"
          :class="getAdvertisementIcon(ad)"
        />

        id={{ ad.ad_id }}

        <i
          v-if="ad.status === 'success'"
          class="cursor-pointer ml-2 fas fa-times"
          @click.stop="removeSavedAd(ad.ad_id)"
        />
      </div>
    </div>
    <!-- Close List -->
    <div class="flex items-center pl-2">
      <i
        class="advertisement-saving-list--close  fas fa-times"
        @click="closeAdsBeingSaved"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AdvertisementSavingList',
  props: {
    // Advertisements
    advertisements: {
      type: Array,
      default: () => []
    }
  },
  created () {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['areAdsBeingSaved']),
    // Get Unsuccessful Ads
    getAdsBeingSaved () {
      return this.advertisements.filter(ad => ad.status === 'saving')
    }
  },
  watch: {
    areAdsBeingSaved (newValue) {
      if (newValue === false) {
        this.$emit('reload')
      }
    }
  },
  methods: {
    ...mapMutations('AdvertisementsModule', ['SET_ADS_BEING_SAVED', 'SET_AD_TO_OPEN']),
    // Handle Retry Save
    handleClick (ad) {
      if (ad.status === 'success') {
        if (this.$route.name !== ad.saveRouteName) {
          ({
            name: ad.saveRouteName,
            params: ad.saveRouteParams,
            query: {
              ad: ad.ad_id
            }
          })
        }
        this.SET_AD_TO_OPEN(ad)
      }
    },
    // Close Ads Being Saved
    closeAdsBeingSaved () {
      this.SET_ADS_BEING_SAVED([])
    },
    removeSavedAd (adId) {
      this.SET_ADS_BEING_SAVED(this.advertisements.filter(ad => ad.ad_id !== adId))
    },

    // Confirm Leave If Ads Are Being Saved
    confirmLeave () {
      return window.confirm('The ads in your queue will be cancelled! Are you sure you want to refresh?')
    },

    confirmStayInWindow () {
      return this.areAdsBeingSaved && !this.confirmLeave()
    },

    beforeWindowUnload (e) {
      if (this.confirmStayInWindow()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    },
    // Get Ad Classes
    getAdClasses (ad) {
      switch (ad.status) {
        case ('fail'):
          return 'bg-gray-300 text-gray-600'
        case ('success'):
          return 'bg-green-200 text-green-800'
        case ('warning'):
          return 'bg-yellow-300 text-yellow-600'
        case ('saving'):
          return 'bg-blue-300 text-blue-800'
      }
    },
    // Get Advertisement Icon
    getAdvertisementIcon (ad) {
      switch (ad.status) {
        case ('fail'):
          return 'fa-redo-alt'
        case ('success'):
          return 'fa-check'
        case ('warning'):
          return 'fa-exclamation-triangle'
        case ('saving'):
          return 'animate-spin fa-redo-alt'
      }
    }
  }
}
</script>

<style scoped lang="sass">
.advertisement-saving-list
  @apply border-gray-300 border-t flex px-8 w-full

  &--title
    width: 120px
    @apply border-gray-300 pr-3 border-r flex items-center flex-grow-0 font-semibold text-center

  &--list
    @apply flex items-center gap-3 pl-3 py-3 w-full overflow-x-scroll scrollbar-hide

    &--item
      width: 220px
      min-width: 220px
      padding-top: 5px
      @apply cursor-pointer filter px-3 py-1 rounded-full text-sm transition-all

      &:hover
        @apply brightness-105

  &--close
    @apply cursor-pointer ml-auto text-gray-500 text-lg
</style>
