<template>
  <div
    class="theme-transition w-96 bg-gradient-to-b flex flex-col pb-6 h-screen max-h-screen"
    :class="`from-${getTheme}-dark to-${getTheme}-darkest`"
  >
    <!-- LOGO SECTION -->
    <div class="flex items-start p-4">
      <Logo :width="24" />
      <div class="ml-2 text-2xl text-white font-semibold">
        foreplay
      </div>
    </div>

    <!-- DIVIDER -->
    <div class="w-full border-b my-1 border-white opacity-20" />

    <!-- Shared Info Section -->
    <div class="flex items-center pl-4 my-2">
      <img
        v-if="createdByUser.avatar"
        :src="createdByUser.avatar"
        class="h-9 w-9 rounded-full mr-4"
      >
      <div
        v-else
        class="h-10 w-10 rounded-full mr-4 bg-gray-400 flex items-center justify-center text-white opacity-50"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
          />
        </svg>
      </div>
      <div class="flex flex-col justify-items-start">
        <div class="text-white text-lg">
          Ad Review
        </div>
        <div class="text-gray-300 text-sm">
          Brief created by {{ createdByUser.name }}
        </div>
      </div>
    </div>

    <!-- DIVIDER -->
    <div class="w-full border-b my-1 border-white opacity-20" />

    <div class="pl-4 mt-1">
      <div class="text-gray-300">
        Submissions
      </div>
      <div
        v-if="brief.submissions"
        class="max-h-96 overflow-scroll scrollbar-hide"
      >
        <a
          v-for="(submission, index) in brief.submissions"
          :key="index"
          :href="submission.path"
          target="_blank"
        >
          <div
            class="flex justify-start text-white items-center mt-2 p-1 rounded cursor-pointer hover:bg-gray-200 hover:bg-opacity-10 transition duration-200 mr-2"
          >
            <svg
              width="25"
              height="19"
              viewBox="0 0 25 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.91113"
                y="1.49512"
                width="21.8417"
                height="16.4593"
                rx="3"
                fill="#14C78C"
                fill-opacity="0.2"
              />
              <path
                d="M16.7204 9.12885L11.0954 5.61323C10.8786 5.47776 10.6054 5.47058 10.3818 5.59448C10.1582 5.71837 10.0195 5.95387 10.0195 6.20948V13.2407C10.0195 13.4963 10.1582 13.7318 10.3818 13.8557C10.488 13.9146 10.6054 13.9439 10.7227 13.9439C10.8522 13.9439 10.9815 13.9081 11.0953 13.837L16.7203 10.3214C16.9259 10.1929 17.0508 9.96754 17.0508 9.7251C17.0508 9.48266 16.926 9.25733 16.7204 9.12885ZM11.4258 11.9721V7.47805L15.021 9.7251L11.4258 11.9721Z"
                fill="#14C78C"
              />
              <path
                d="M21.3164 0.584473H4.34766C2.40914 0.584473 0.832031 2.16158 0.832031 4.1001V15.3501C0.832031 17.2886 2.40914 18.8657 4.34766 18.8657H21.3164C23.2549 18.8657 24.832 17.2886 24.832 15.3501V4.1001C24.832 2.16158 23.2549 0.584473 21.3164 0.584473ZM23.4258 15.3501C23.4258 16.5132 22.4795 17.4595 21.3164 17.4595H4.34766C3.18455 17.4595 2.23828 16.5132 2.23828 15.3501V4.1001C2.23828 2.93699 3.18455 1.99072 4.34766 1.99072H21.3164C22.4795 1.99072 23.4258 2.93699 23.4258 4.1001V15.3501Z"
                fill="#14C78C"
              />
            </svg>
            <div class="text-base ml-2 truncate w-48">
              {{ submission.name }}
            </div>
            <div class="text-base ml-auto">
              {{ (new Date(submission.submittedAt)).toLocaleDateString('en-GB') }}
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- BOTTOM SECTION -->
    <div class="mt-auto">
      <!-- DIVIDER -->
      <div class="w-full border-b my-1 border-white opacity-20" />

      <!-- Upload Area -->
      <div>
        <label
          :for="`uploadInput`"
          class="relative block w-full text-center"
          @click="shouldGetUserData"
          @drop.prevent="onDrop"
        >
          <div
            class="flex justify-center items-center m-4 h-64 w-80 border-2 border-dashed rounded-xl transition duration-500 cursor-pointer"
            :class="{
              'border-gray-400 bg-gradient-to-b from-gray-600': uploading,
              'border-green-400 bg-gradient-to-b from-green-800  hover:opacity-70 ': !uploading
            }"
          >
            <div class="flex flex-col items-center justify-center">
              <lottie
                v-if="uploading"
                :options="defaultOptions"
                :height="40"
                :width="40"
              />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-10 h-10 text-green-500"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
              </svg>

              <div v-if="uploading">
                <div class="text-gray-300 text-lg">Uploading your files</div>
                <div class="text-gray-100 text-sm">
                  {{ filesCompleted }} of {{ filesUploading }} finished uploading
                </div>
              </div>
              <div v-else>
                <div class="text-gray-100 text-lg">Upload Content</div>
                <div class="text-gray-300 text-sm">
                  Drag and drop your .mp4, .png or .jpeg file
                </div>
              </div>
            </div>
          </div>
        </label>
        <input
          v-if="usersName && usersEmail"
          :id="'uploadInput'"
          type="file"
          hidden
          @change="handleUploadFile($event)"
        >
      </div>

      <!-- DIVIDER -->
      <div class="w-full my-1 border-white opacity-20" />

      <div class="flex justify-between mx-4 mt-4">
        <a
          class="w-1/2"
          href="https://app.foreplay.co/login"
        >
          <BaseButton
            outlined
            class="w-full mr-2 bg-transparent"
          >
            Login
          </BaseButton>
        </a>
        <a
          class="w-1/2"
          href="https://app.foreplay.co/sign-up"
        >
          <BaseButton
            primary
            class="w-full ml-2"
          >
            Sign Up
          </BaseButton>
        </a>
      </div>
    </div>
    <GetUserInfoModal
      v-if="showGetUserInfoModal"
      :name="createdByUser.name"
      @close="showGetUserInfoModal = false"
      @save="getUserInfo"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { nanoid } from 'nanoid'
import FirebaseAPI from '@/api/firebase'
import firebase from '@/api/config/FirebaseConfig'

import Logo from './Logo'
import GetUserInfoModal from '../briefs/GetUserInfoModal.vue'
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../assets/lotties/loadingV2Green.json'

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

export default {
  name: 'UploadSidebar',
  components: {
    Logo,
    lottie: Lottie,
    GetUserInfoModal
  },
  data () {
    return {
      showGetUserInfoModal: false,
      createdByUser: { name: '', avatar: null },
      brief: {},
      usersName: null,
      usersEmail: null,
      defaultOptions: { animationData: animationData, loop: true, autoplay: true },
      uploading: false,
      filesUploading: 0,
      filesCompleted: 0,
      uploadedFiles: []
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getIsInspoMode']),
    ...mapGetters('MiscModule', ['getTheme']),
    ...mapGetters('AuthModule', ['getUser'])
  },
  async mounted () {
    this.getUserInfo()
    this.getCreatedUser()

    events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  beforeDestroy () {
    events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefaults)
    })
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async getCreatedUser () {
      this.brief = await FirebaseAPI.Briefs.getByID(this.$route.params.id)
      const createdByUser = await FirebaseAPI.Users.getById(this.brief.createdBy)

      const name = `${createdByUser.first_name || ''} ${createdByUser.last_name || ''}`.trim() || createdByUser.name || createdByUser.email

      this.createdByUser = { avatar: createdByUser.avatar, name, id: createdByUser.id, storageUsed: this.createdByUser.storageUsed }
    },
    shouldGetUserData () {
      if (!this.usersName || !this.usersEmail) {
        this.showGetUserInfoModal = true
      }
    },
    // Check whos viewing the page from local storage
    getUserInfo () {
      if (localStorage.userInfo) {
        const { name, email } = JSON.parse(localStorage.userInfo)
        this.usersName = name
        this.usersEmail = email
      }
    },
    onDrop (e) {
      const fakeEvent = { target: { files: [...e.dataTransfer.files] } }
      this.handleUploadFile(fakeEvent)
    },
    preventDefaults (e) {
      e.preventDefault()
    },
    async handleUploadFile (event, index) {
      if (this.uploading) return
      this.filesCompleted = 0

      // Set upload loading
      const filesToUpload = [...event.target.files]

      // Check if the user has enough storage space
      // Round up, saving in bytes will cause huge ints - save in kb
      const kbToSave = Math.ceil(filesToUpload.reduce((total, file) => total + file.size, 0) / 1000)

      // 5MM kb is 1gb - limit at 5gb for now
      if (this.createdByUser.storageUsed && (this.createdByUser.storageUsed + kbToSave) > 5000000) {
        // Show error
        this.$showAlert({
          message: 'Storage Limit Reached (5GB)',
          type: 'error'
        })

        return
      }

      // Validate Files
      const invalidFiles = filesToUpload.find(f => !['image/png', 'image/jpeg', 'video/mp4'].includes(f.type))

      if (invalidFiles) {
        // Show error
        this.$showAlert({
          message: 'Invalid File Type',
          type: 'error'
        })
        return
      }

      this.uploading = true
      this.filesUploading = filesToUpload.length

      for (const fileToUpload of filesToUpload) {
      // Create File Name (Random Folder Path)
        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}`

        const storageRef = firebase.storage().ref(filePath)

        const firstFile = fileToUpload
        const savingFile = await storageRef.put(firstFile)

        // Upload is complete
        const submission = {
          path: `https://storage.googleapis.com/foreplay-submissions/${filePath}`,
          format: savingFile.metadata.contentType,
          submittedAt: +new Date(),
          name: fileToUpload.name,
          submittedBy: {
            name: this.usersName,
            email: this.usersEmail
          }
        }

        await FirebaseAPI.Briefs.update(this.brief.id, {
          submissions: [
            ...(this.brief.submissions || []),
            submission
          ]
        })

        // Update the UI
        this.filesCompleted = this.filesCompleted + 1
        // Add the submission to the brief in state
        this.brief.submissions = this.brief.submissions ? this.brief.submissions.concat(submission) : [submission]
      }

      // Update the users total storage use
      const storageUsed = this.createdByUser.storageUsed ? this.createdByUser.storageUsed + kbToSave : kbToSave

      await FirebaseAPI.Users.update(this.createdByUser.id, { storageUsed })

      // // Set the local user
      // this.SET_USER({ ...this.getUser, storageUsed })

      // Show that all files are uploaded
      this.uploading = false
      this.$showAlert({
        message: 'Files Uploaded',
        type: 'success'
      })
    }
  }
}
</script>
<style scoped lang="css">
theme-transition {
  transition: background-color 4s;
}
</style>
