<template>
  <div
    :class="{
      'fixed top-0 left-0 w-full h-full': fullScreen
    }"
    :style="fullScreen ? 'z-index: 99999;' : ''"
  >
    <div
      :class="{
        'pt-10': fullScreen
      }"
      class="relative p-4 bg-white border-t mb-4"
    >
      <div
        v-if="fullScreen"
        class="absolute top-2 right-4 text-gray-500 cursor-pointer"
        @click="$emit('exitFullscreen')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          class="h-5 w-5"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <draggable
        v-model="storyDuplicate"
        v-bind="dragOptions"
        @change="emitUpdate"
      >
        <div
          v-for="(scene, index) in storyDuplicate"
          :key="index"
          class="px-6 flex gap-x-2"
        >
          <div
            class="flex-1 flex flex-col items-center"
          >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted w-6 h-8 border border-neutral-50 border-opacity-80 rounded-md flex items-center justify-center bg-white"
            >
              {{ index + 1 }}
            </BaseText>
            <div
              class="flex-1 bg-border-normal self-center"
              :class="{ 'opacity-0': index === (storyDuplicate.length - 1) }"
              style="width: 1px;"
            />
          </div>
          <div
            class="flex flex-col gap-4 w-full h-full "
          >
            <div
              class="flex green-background bg-primary-green-10 border border-primary-green-100 border-b-0 border-opacity-10 rounded-lg w-full "
            >
              <BaseContentEditable
                no-hover
                custom-text-classes="text-heading-overline font-medium uppercase w-full"
                class="py-0.5 px-1 h-full"
                no-padding
                placeholder="scene title..."
                :current-value="scene.scene[0].title"
                @update="(newValue) => updatePanelSection(index, 'title', newValue)"
              />
            </div>
            <div
              class="flex rounded-md w-full h-max flex-wrap pb-2"
            >
              <div
                class="h-full  w-full border border-b-0 border-border-normal rounded-t-lg bg-white"
              >
                <div
                  class="group relative px-4 py-3 flex items-center flex-wrap gap-y-1.5 w-full"
                >
                  <div
                    class="flex items-center w-full"
                  >
                    <div
                      class="h-1 w-1 rounded-full bg-primary-green-50 mr-2"
                    />
                    <BaseText
                      style="display"
                      size="xs"
                      class="text-roboto text-neutral-400"
                    >
                      SCRIPT COPY
                    </BaseText>
                  </div>
                  <BaseContentEditable
                    :id="`copy-${index}`"
                    :current-value="scene.copy"
                    no-padding
                    custom-text-classes="text-body-xs font-normal text-text-normal w-full"
                    placeholder="Write Copy here..."
                    @update="(newValue) => updatePanelSection(index, 'copy', newValue)"
                    @click="aiButtonToHide = `copy-${index}`"
                    @blur="aiButtonToHide = null"
                  />

                  <!-- Ai Section -->
                  <div
                    v-if="aiButtonToHide !== `copy-${index}`"
                    class="absolute flex items-center justify-end pr-4 bg-gradient-to-l to-transparent from-white via-white opacity-0 group-hover:opacity-100  pointer-events-none top-0 bottom-0 right-0 w-1/2 h-full transition"
                  >
                    <!-- AI Modal Button -->
                    <div
                      class="p-2 pointer-events-auto cursor-pointer hover:bg-background-disabled rounded transition"
                      @click="openAiModal(index, 'copy')"
                    >
                      <img src="../../../../assets/icons/ai-green.svg">
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="h-full  w-full border border-b-0 border-border-normal p-3 bg-white"
              >
                <div
                  class="group relative flex items-center flex-wrap gap-y-1.5 h-full"
                >
                  <div
                    class="flex items-center w-full"
                  >
                    <div
                      class="h-1 w-1 rounded-full bg-primary-blue-50 mr-2"
                    />
                    <BaseText
                      style="display"
                      size="xs"
                      class="text-roboto text-neutral-400"
                    >
                      ACTION & DESCRIPTION
                    </BaseText>
                  </div>
                  <BaseContentEditable
                    :id="`visuals-${index}`"
                    class="py-0.5 px-1 h-full"
                    :current-value="scene.visuals"
                    placeholder="Write Action & Description here..."
                    no-padding
                    custom-text-classes="text-body-xs font-normal text-text-normal w-full"
                    @update="(newValue) => updatePanelSection(index, 'visuals', newValue)"
                    @click="aiButtonToHide = `action-${index}`"
                    @blur="aiButtonToHide = null"
                  />

                  <!-- Ai Section -->
                  <div
                    v-if="aiButtonToHide !== `action-${index}`"
                    class="absolute flex items-center justify-end pr-4 bg-gradient-to-l to-transparent from-white via-white opacity-0 group-hover:opacity-100  pointer-events-none top-0 bottom-0 right-0 w-1/2 h-full transition"
                  >
                    <!-- AI Modal Button -->
                    <div
                      class="p-2 pointer-events-auto cursor-pointer hover:bg-background-disabled rounded transition"
                      @click="openAiModal(index, 'visuals')"
                    >
                      <img src="../../../../assets/icons/ai-green.svg">
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="h-full  w-full border border-border-normal p-3 rounded-b-lg bg-white"
              >
                <div
                  class="flex items-center w-full"
                >
                  <div
                    class="h-1 w-1 rounded-full bg-primary-purple-50 mr-2"
                  />
                  <BaseText
                    style="display"
                    size="xs"
                    class="text-roboto text-neutral-400"
                  >
                    TEXT OVERLAY
                  </BaseText>
                </div>
                <BaseContentEditable
                  class="py-0.5 px-1 h-full"
                  :current-value="scene.overlay"
                  placeholder="Write Text Overlay here..."
                  no-padding
                  custom-text-classes="text-body-xs font-normal text-text-normal w-full"
                  @update="(newValue) => updatePanelSection(index, 'overlay', newValue)"
                />
              </div>
            </div>
          </div>
        </div>
      </draggable>
      <div class="w-full">
        <div
          class="flex select-none items-center cursor-pointer h-14 pl-5 mt-5 border border-dashed rounded-lg border-border-hover hover:bg-background-disabled transition"
          @click="$emit('addPanel')"
        >
          <!--  -->
          <img
            src="../../../../assets/icons/plus-icon.svg"
            class="mr-2"
          >
          <BaseText
            class="text-black"
            type="label"
            size="sm"
          >
            Add Scene
          </BaseText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { nanoid } from 'nanoid'
import firebase from '@/api/config/FirebaseConfig'

import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../../assets/lotties/loadingV2Green.json'
import BaseContentEditable from '../../../globals/BaseContentEditable.vue'

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

export default {
  name: 'StoryDragView',
  components: {
    draggable,
    lottie: Lottie,
    BaseContentEditable
  },
  props: {
    brief: {
      type: Object,
      default: () => ({})
    },
    shared: {
      type: Boolean
    },
    loadingReferences: {
      type: Boolean
    },
    aiBrief: {
      type: Boolean
    },
    fullScreen: {
      type: Boolean
    },
    // View Settings
    showReference: {
      type: Boolean
    },
    showCopy: {
      type: Boolean
    },
    showActions: {
      type: Boolean
    }
  },
  data () {
    return {
      storyDuplicate: [],
      uploadsLoading: [],
      filesUploading: [],
      filesCompleted: [],
      uploading: false,
      defaultOptions: { animationData: animationData, loop: true, autoplay: true },
      aiButtonToHide: null
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 400,
        group: 'description',
        disabled: this.shared,
        ghostClass: 'ghost'
      }
    }
  },
  watch: {
    'brief.story': function (newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.storyDuplicate = newVal
      }
    },
    loadingReferences: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.storyDuplicate = this.brief.story
      }
    }
  },
  mounted () {
    this.storyDuplicate = this.brief.story

    events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  methods: {
    openAiModal (index, section) {
      this.$emit('openAiModal', index, section)
    },
    updatedBrief () {
      this.$emit('update', this.storyDuplicate)
    },
    handleKeydown (event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault()
      }
    },
    // Upload Methods
    onDrop (e, index) {
      if (!e.dataTransfer.files.length) return
      const fakeEvent = { target: { files: [...e.dataTransfer.files] } }
      this.handleUploadFile(fakeEvent, index)
    },
    preventDefaults (e) {
      e.preventDefault()
    },
    async handleUploadFile (event, index) {
      try {
      // Set upload loading
        this.uploadsLoading = this.uploadsLoading.concat(index)

        const fileToUpload = event.target.files[0]
        const extension = fileToUpload.type.split('/')[1]

        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}.${extension}`

        const storageRef = firebase.storage().ref(filePath)

        const firstFile = fileToUpload
        const savingFile = await storageRef.put(firstFile)

        // Upload is complete
        const reference = {
          path: `https://storage.googleapis.com/foreplay-submissions/${filePath}`,
          format: savingFile.metadata.contentType
        }
        this.updatePanelReference(index, reference)

        this.uploadsLoading = this.uploadsLoading.filter(i => i !== index)

        this.$showAlert({
          message: 'Your image has been uploaded.',
          type: 'success'
        })
      } catch (error) {
        console.error(error)
        this.$showAlert({
          message: 'Something went wrong uploading your image',
          type: 'error'
        })
      }
    },
    updatePanelReference (index, value) {
      const copyArr = [...this.storyDuplicate]

      copyArr[index].reference = [value]

      this.panels = copyArr
      this.emitUpdate()
    },
    deletePanel (index) {
      // Delete previous reference
      this.deleteReference(index, true)

      this.storyDuplicate = this.storyDuplicate.filter((field, i) => i !== index)

      this.emitUpdate()
    },
    getImagePath (referenceString) {
      if (referenceString.includes('.appspot.com')) {
        return referenceString.replace('.appspot.com', '')
      }

      return referenceString
    },
    async deleteReference (index, skipAlert) {
      try {
        // Get the panel to update
        const panel = this.storyDuplicate[index]

        if (!panel?.reference || !panel?.reference[0]?.path) return

        // let referenceUrl
        // // If its using the old reference type
        // if (panel.reference[0].path.includes('appspot.com')) {
        //   referenceUrl = panel.reference[0].path.split('foreplay-submissions.appspot.com/')[1]
        // } else {
        //   referenceUrl = panel.reference[0].path.split('foreplay-submissions/')[1]
        // }

        // // Create a reference to the file to delete
        // var storage = firebase.storage()

        // // Create a storage reference from our storage service
        // var storageRef = storage.ref()
        // var desertRef = storageRef.child(referenceUrl)

        // // Delete the file
        // await desertRef.delete()

        // File deleted successfully
        // Update panel and save new reference
        const copyArr = [...this.storyDuplicate]

        copyArr[index].reference = []

        this.storyDuplicate = copyArr
        this.emitUpdate()

        if (!skipAlert) {
          this.$showAlert({
            message: 'Your image has been deleted.',
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
        // Uh-oh, an error occurred!
        this.$showAlert({
          message: 'Something went wrong deleting your image',
          type: 'error'
        })
      }
    },
    updatePanelSection (index, section, value) {
      if (section === 'title') {
        this.storyDuplicate[index].scene[0].title = value
      } else {
        this.storyDuplicate[index][section] = value
      }

      this.emitUpdate()
    },
    emitUpdate () {
      console.log('emitting story update', this.storyDuplicate)
      if (this.shared) return
      this.$emit('update', this.storyDuplicate)
    }
  }
}
</script>

<style scoped>
.green-background {
  background-image: url('../../../../assets/icons/gantt-bar-live.png');
  background-size: 400px 35px;
  background-position: center;
  background-repeat: repeat-x;
  opacity: 0.98;
}
</style>
