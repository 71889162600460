<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M8.5 16.75H6.25C5.42157 16.75 4.75 16.0784 4.75 15.25V4.75C4.75 3.92157 5.42157 3.25 6.25 3.25H13.75C14.5784 3.25 15.25 3.92157 15.25 4.75V8.5M15.25 13.75H12.25M15.25 13.75C15.6642 13.75 16 14.0858 16 14.5V16C16 16.4142 15.6642 16.75 15.25 16.75H12.25C11.8358 16.75 11.5 16.4142 11.5 16V14.5C11.5 14.0858 11.8358 13.75 12.25 13.75M15.25 13.75V13C15.25 12.1716 14.5784 11.5 13.75 11.5C12.9216 11.5 12.25 12.1716 12.25 13V13.75M7.75 6.25H12.25M7.75 9.25H10" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'InsuranceNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>