<template>
  <contenteditable
    v-model="currentValueLocal"
    tag="div"
    class="break-words rounded focus:outline-none focus:ring-0 focus:border-none hover:outline-none hover:ring-0 hover:border-none"
    :class="customeClasses"
    :style="height ? `height: ${height}px;` : ''"
    style="min-width: 100px;"
    :data-placeholder="placeholder"
    :contenteditable="true"
    :no-html="true"
    :no-n-l="noNewline"
    @click="$emit('click', $event)"
    @keyup="onKeyUp"
    @blur.native="$emit('blur', $event)"
  >
    {{ currentValueLocal }}
  </contenteditable>
</template>
<script>
export default {
  name: 'BaseContentEditable',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    currentValue: {
      type: String,
      default: ''
    },
    noNewline: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: null
    },
    noHover: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    customTextClasses: {
      type: String,
      default: null
    },
    truncate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentValueLocal: ''
  }),
  computed: {
    customeClasses () {
      const classList = []
      if (this.customTextClasses) {
        classList.push(this.customTextClasses)
      }

      if (!this.noHover) {
        classList.push('hover:bg-neutral-25 hover:bg-opacity-80')
      }

      if (!this.noPadding) {
        classList.push('px-3')
      }

      if (this.truncate) {
        classList.push('truncate')
        classList.push('overflow-hidden')
      }
      return classList.join(' ')
    }
  },
  watch: {
    currentValue (val) {
      this.currentValueLocal = val
    }
  },
  mounted () {
    // Dont use vue model to change the prop
    this.currentValueLocal = this.currentValue
  },
  methods: {
    onKeyUp (e) {
      if (e.key === 'Enter' && this.noNewline) {
        return
      }
      this.$emit('update', e.target.innerText)
    }
  }
}
</script>
<style scoped>
[contenteditable=true]:empty:not(:focus):before{
  content:attr(data-placeholder);
  pointer-events: none;
  @apply text-neutral-400
}
</style>
