<template>
  <div>
    <div
      class="w-full h-max bg-white rounded-xl flex flex-wrap overflow-hidden select-none"
      :class="{
        'discount-border': bannerText,
        'border border-border-normal' : !bannerText,
        'cursor-pointer' : subscription,
        'rounded-b-none': additionalInfo
      }"
      @click="$emit('click')"
    >
      <slot name="cardIcon" />
      <div
        v-if="bannerText"
        class="bg-primary-green-50 bg-opacity-10 py-2.5 flex items-center justify-center w-full green-text "
      >
        <BaseText
          type="body"
          size="sm"
        >
          {{ bannerText }}
        </BaseText>
      </div>
      <div
        v-if="bannerText"
        class="w-full h-px green-border--top"
      />
      <div
        v-if="subscription"
        :class="selected ? 'rounded-full bg-primary-blue-100 w-4 h-4 flex items-center justify-center ring-2 ring-offset-2 ring-primary-blue-600' : 'bg-white w-4 h-4 flex items-center justify-center ring-2 ring-offset-2 ring-border-normal rounded-full'"
        class="my-auto ml-5 "
      >
        <div
          class="bg-white w-1.5 h-1.5 rounded-full"
        />
      </div>
      <div
        class="flex flex-col ml-4 w-max my-auto py-2.5"
      >
        <BaseText
          type="body"
          size="sm"
          class="text-text-loud"
        >
          {{ mainText }}
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal"
        >
          {{ subText }}
        </BaseText>
      </div>
      <BaseText
        type="body"
        size="sm"
        class="text-text-disabled ml-auto mr-2 my-auto"
      >
        <s
          class="text-text-disabled h-max w-max"
        >
          {{ discountText }}
        </s>
      </BaseText>
      <BaseText
        v-if="subscription && price"
        type="body"
        size="sm"
        class="text-text-normal my-auto mr-5"
      >
        {{ getProductPrice }}
      </BaseText>
      <BaseFilterSelect
        v-if="!subscription"
        class="ml-auto my-auto"
        align-center
        no-search
        short-dropdown
        item-value="value"
        :items="formatDisabledItems"
        @update="
          (item) => {
            $emit('update-quantity', item)
          }
        "
      >
        <template #icon>
          <BaseButton
            class="flex items-center w-max rounded-full hover:bg-background-normal transition duration-300 h-11 shadow-sm"
            outlined
          >
            <BaseText
              type="body"
              size="sm"
              class="text-text-loud -my-3 flex items-center justify-start h-7 w-36 -ml-4"
            >
              <slot name="icon" />
              <BaseBadge
                blue
                class="flex items-center h-4 w-max pl-2 min-w-10"
                small
              >
                <span
                  class="text-body-xs"
                >
                  {{ `${currentQuantity} ${label}` }}
                </span>
              </BaseBadge>
              <img
                src="../../../assets/icons/chevron-down-small.svg"
                alt="chevron-down"
                class="h-5 w-5 ml-auto -mr-2"
              >
            </BaseText>
          </BaseButton>
        </template>

        <template #item="{ item }">
          <div
            class="flex items-center"
          >
            <BaseText
              type="body"
              size="sm"
              class="text-text-loud"
            >
              {{ item.label ? `${item.name} ${item.label}` : `${item.name}` }}
            </BaseText>
            <BaseText
              v-if="item.offerText"
              type="body"
              size="sm"
              class="text-primary-green-100 ml-1"
            >
              {{ item.offerText }}
            </BaseText>
          </div>
          <BaseText
            v-if="item.formattedPrice"
            type="body"
            size="sm"
            class="text-text-loud"
          >
            {{ item.formattedPrice }}
          </BaseText>
        </template>
      </BaseFilterSelect>
    </div>
    <div
      v-if="additionalInfo"
      class="border border-t-0 border-border-normal px-4 pt-1 pb-2 rounded-b-xl"
    >
      <slot name="additionalInfo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    subscription: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean,
      default: false
    },
    mainText: {
      type: String,
      default: ''
    },
    subText: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    bannerText: {
      type: String,
      default: ''
    },
    currentQuantity: {
      type: Number,
      default: 2
    },
    discountText: {
      type: String,
      default: ''
    },
    paymentSchedule: {
      type: String,
      default: 'Monthly'
    },
    label: {
      type: String,
      default: ''
    },
    additionalInfo: {
      type: Boolean
    },
    maximumSelectableIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    formatDisabledItems () {
      return this.items.map((item, index) => {
        return {
          ...item,
          disabled: index < this.maximumSelectableIndex
        }
      })
    },
    getSelectedSubscription () {
      return this.selectedSubscription
    },
    getProductPrice () {
      if (!this.price) return ''
      if (this.price.substring(0, this.price.length - 2) === '0' && this.paymentSchedule === 'Monthly') {
        return `$${this.price.substring(0, this.price.length - 2)}.${this.price.substring(this.price.length - 2, this.price.length)}/mo`
      }
      const total = this.paymentSchedule === 'Monthly' ? `$${this.price.substring(0, this.price.length - 2)}/mo` : `$${this.price.substring(0, this.price.length - 2)}/yr`
      return total
    }
  },
  methods: {
    selectSubscription (subscription) {
      this.selectedSubscription = subscription
    }
  }
}
</script>

<style scoped>
.custom-rounding {
  border-radius: 10px;
}

.discount-border {
  border: 1px solid #CEEDE8;
}

.green-border--top {
  border-top: 1px solid #CEEDE8;
}

.green-text{
  color: #00A879;
}
</style>
