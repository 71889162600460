<template>
  <!-- Scene -->
  <div
    class="h-full px-6 flex gap-x-2 h-max"
    :class="{
      'pt-3': scene.scene_number === '1',
    }"
  >
    <div
      class="flex-1 flex flex-col items-center"
    >
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted w-6 h-8 border border-neutral-50 border-opacity-80 rounded-md flex items-center justify-center"
      >
        {{ scene.scene_number }}
      </BaseText>
      <div
        class="flex-1 bg-border-normal self-center"
        style="width: 1px;"
      />
    </div>
    <div class="grid grid-cols-2 gap-4 w-full">
      <!-- First Section -->
      <div
        class="h-max flex flex-col pb-6"
      >
        <div
          class="h-8 flex items-center gray-background bg-neutral-25 rounded-md w-full"
        >
          <BaseText
            style="display"
            size="xs"
            class="text-primary-green-300 px-1.5 uppercase"
          >
            {{ scene.scene_type }}
          </BaseText>
        </div>
        <BaseCard
          class="rounded-lg mt-3"
          border
        >
          <div
            class="flex flex-col justify-center gap-x-1.5 py-3 px-4 border-b border-neutral-50 border-opacity-80 w-full"
          >
            <div
              class="flex items-center"
            >
              <div
                class="h-1 w-1 rounded-full bg-primary-green-50 mr-2"
              />
              <BaseText
                style="display"
                size="xs"
                class="text-roboto text-neutral-400"
              >
                SCRIPT COPY
              </BaseText>
            </div>
            <BaseText
              style="body"
              size="sm"
              class="text-text-muted"
            >
              {{ scene.script_copy }}
            </BaseText>
          </div>
        </BaseCard>
      </div>

      <!-- Second Section -->
      <div
        v-if="secondScene.scene_type"
        class="h-max flex flex-col pb-6"
      >
        <div
          class="h-8 flex items-center green-background bg-primary-green-10 rounded-md w-full"
        >
          <BaseText
            style="display"
            size="xs"
            class="text-primary-green-300 px-1.5 uppercase"
          >
            {{ secondScene.scene_type }}
          </BaseText>
        </div>
        <BaseCard
          class="rounded-lg mt-3"
          border
        >
          <div
            class="flex flex-col justify-center gap-x-1.5 py-3 px-4 border-b border-neutral-50 border-opacity-80 w-full"
          >
            <div
              class="flex items-center"
            >
              <div
                class="h-1 w-1 rounded-full bg-primary-green-50 mr-2"
              />
              <BaseText
                style="display"
                size="xs"
                class="text-roboto text-neutral-400"
              >
                SCRIPT COPY
              </BaseText>
            </div>
            <BaseText
              style="body"
              size="sm"
              class="text-text-muted"
            >
              {{ secondScene.script_copy }}
            </BaseText>
          </div>
          <div
            v-if="showAction"
            class="flex flex-col justify-center gap-x-1.5 py-3 px-4 border-b border-neutral-50 border-opacity-80 w-full"
          >
            <div
              class="flex items-center"
            >
              <div
                class="h-1 w-1 rounded-full bg-primary-blue-50 mr-2"
              />
              <BaseText
                style="display"
                size="xs"
                class="text-roboto text-neutral-400"
              >
                ACTION & DESCRIPTION
              </BaseText>
            </div>
            <BaseText
              style="body"
              size="sm"
              class="text-text-muted"
            >
              {{ secondScene.action_description }}
            </BaseText>
          </div>
          <div
            v-if="showOverlay"
            class="flex flex-col justify-center gap-x-1.5 py-3 px-4 border-b border-neutral-50 border-opacity-80 w-full"
          >
            <div
              class="flex items-center"
            >
              <div
                class="h-1 w-1 rounded-full bg-primary-purple-50 mr-2"
              />
              <BaseText
                style="display"
                size="xs"
                class="text-roboto text-neutral-400"
              >
                TEXT OVERLAY
              </BaseText>
            </div>
            <BaseText
              sstyle="body"
              size="sm"
              class="text-text-muted"
            >
              {{ secondScene.text_overlay }}
            </BaseText>
          </div>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScriptSection',
  props: {
    scene: {
      type: Object,
      default: () => ({})
    },
    secondScene: {
      type: Object,
      default: () => ({})
    },
    showAction: {
      type: Boolean,
      default: false
    },
    showOverlay: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 1
    },
    editable: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="css">
.green-background{
  background-image: url('../../../../assets/images/green-striped-background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.gray-background{
  background-image: url('../../../../assets/images/gray-striped-background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
