<template>
  <input
    type="checkbox"
    @input="$emit('input')"
  >
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    value: {
      type: Boolean
    }
  }
}
</script>

<style scoped lang="sass">
input[type="checkbox"]
  position: relative
  width: 19px
  height: 19px
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1)
  @apply appearance-none border border-gray-500 cursor-pointer outline-none rounded

  &::before
    content: ''
    top: 2px
    left: 6px
    width: 5px
    height: 11px
    border-width: 0 2px 2px 0
    transform: rotate(45deg)
    opacity: 0
    @apply absolute block border-white

  &:checked
    @apply bg-blue-500

    &::before
      opacity: 1
    ~ label::before
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)
</style>
