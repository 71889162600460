<template>
  <!-- Manage Board Modal -->
  <BaseModal
    :card-width="'500px'"
    @close="$emit('close')"
  >
    <template #toolbar>
      <div class="text-black w-full flex flex-col items-center font-medium justify-center -mt-16">
        <img
          class="w-28"
          src="../../assets/images/foreplay-icon.png"
        >
        <span class="text-lg border border-green-500 text-green-500 px-2 rounded-md -mt-1 mb-2 bg-green-50">Free Trial</span>
        <span class="text-xl">Sign Up to Save Ads</span>
        <span class="text-md text-gray-400">Foreplay Lets you save, organize, and share ads.</span>
      </div>
    </template>

    <template #default>
      <div
        class="px-4 overflow-y-scroll"
        style="max-height: calc(90vh - 200px);"
      >
        <!-- Save Board -->
        <div>
          <!-- Brand -->
          <form
            @submit.prevent="checkForm"
          >
            <BaseInput
              v-model="firstName"
              placeholder="First Name"
              class="mb-4"
            />

            <BaseInput
              v-model="lastName"
              placeholder="Last Name"
              class="mb-4"
            />

            <BaseInput
              v-model="email"
              placeholder="Email"
              class="mb-4"
            />

            <BaseInput
              v-model="password"
              placeholder="Password"
              input-type="password"
              class="mb-4"
            />

            <p
              v-if="errors.length"
              class="text-white mb-4"
            >
              <b>Please correct the following error(s):</b>
              <ul class="text-red-500">
                <li
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error }}
                </li>
              </ul>
            </p>

            <div class="w-full flex justify-center">
              <BaseButton
                primary
                class="w-full mb-4 max-w-xs"
                :disabled="loadingEmailLink"
                :loading="loadingEmailLink"
                type="submit"
              >
                Sign Up
              </BaseButton>
            </div>
            <div class="w-full flex justify-center">
              <router-link
                class="text-gray-600 underline"
                to="/login"
              >
                Click here to log in
              </router-link>
            </div>
          </form>

          <div class="border-t border-white" />
          <!-- Login Button -->
          <div class="w-full flex justify-center mb-4">
            <BaseButton
              class="flex w-full max-w-xs items-center mt-8 bg-white border border-gray-300 rounded-md"
              secondary
              @click="googleSignin"
            >
              <i class="fab fa-google text-red-500 mr-2" />
              Sign up with Google
            </BaseButton>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions } from 'vuex'

// Agency, Brand, Software Company, UGC Creator, Other

export default {
  name: 'SignUpView',
  data () {
    return {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      loadingEmailLink: false,
      errors: []
    }
  },
  methods: {
    ...mapActions('AuthModule', ['loginUserWithGoogle', 'signUpWithEmailAndPassword']),
    // Send Email Link
    async checkForm (e) {
      this.errors = []

      if (!this.firstName) {
        this.errors.push('First Name required.')
      }
      if (!this.lastName) {
        this.errors.push('Last Name required.')
      }
      if (!this.email) {
        this.errors.push('Email required.')
      }
      if (!this.password) {
        this.errors.push('Password required.')
      }

      if (!this.errors.length) {
        await this.signUpUser()
      }

      e.preventDefault()
    },
    async googleSignin () {
      try {
        await this.loginUserWithGoogle(true)

      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e.message,
          type: 'error'
        })
      }
    },
    async signUpUser () {
      this.loadingEmailLink = true

      try {
        await this.signUpWithEmailAndPassword({
          email: this.email,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
          name: `${this.firstName} ${this.lastName}`
        })
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e.message,
          type: 'error'
        })
      } finally {
        this.loadingEmailLink = false
      }
    }
  }
}
</script>

<style scoped></style>
