<template>
  <button
    class="menu-item transition"
    :class="{
      'is-active-blue': isActive && getTheme === 'blue' ? isActive(): null,
      'is-active-green': isActive && getTheme === 'green' ? isActive(): null,
      'hover:bg-blue-logo focus:bg-blue-logo': getTheme === 'blue',
      'hover:bg-green-logo focus:bg-green-logo': getTheme === 'green'
    }"
    :title="title"
    @click="action"
  >
    <svg
      v-if="icon"
      class="remix"
    >
      <use :xlink:href="`${remixiconUrl}#ri-${icon}`" />
    </svg>
    <div
      v-else
      v-html="html"
    />
  </button>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'
import { mapGetters } from 'vuex'

export default {
  props: {
    icon: {
      type: String,
      default: null
    },
    html: {
      type: String,
      default: null
    },
    title: {
      type: String,
      required: true
    },

    action: {
      type: Function,
      required: true
    },

    isActive: {
      type: Function,
      default: null
    },
    backgroundFill: {
      type: String,
      default: 'bg-black'
    }
  },
  data () {
    return {
      remixiconUrl
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme'])
  }
}
</script>

<style lang="scss">
.menu-item {
  width: 1.75rem;
  height: 1.75rem;
  color: #0D0D0D;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.25rem;
  margin-right: 0.25rem;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  &.is-active-blue {
    color: #FFF;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }

  &.is-active-green {
    color: #FFF;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }

  &:hover {
    color: #FFF;
    background-color: #0D0D0D;
  }
}
</style>
