<template>
  <!-- Main Layout -->
  <div
    class="main-layout m-4 ml-0 rounded-xl overflow-y-auto"
    :class="[aditionalClasses, bgColor ? `bg-${bgColor}` : 'bg-background-normal']"
    :style="{ 'width': isRoutePublic ? '100%' : 'calc(100vw - 230px)' }"
    @scroll="$emit('scroll')"
  >
    <div
      :class="scrollToolbar ? 'main-layout--toolbar-scroll' : 'main-layout--toolbar'"
    >
      <slot name="toolbar" />
    </div>

    <slot name="filter" />

    <div
      class="main-layout--content pb-4"
      :class="[bgColor ? `bg-${bgColor}` : 'bg-background-normal', noContentPadding ? '' : 'px-4 pt-4']"
    >
      <slot name="content" />
    </div>
    <BriefModeBanner v-if="getIsInspoMode" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BriefModeBanner from '../components/briefs/BriefModeBanner.vue'

export default {
  name: 'MainLayout',
  components: { BriefModeBanner },
  props: {
    aditionalClasses: {
      type: String,
      default: ''
    },
    scrollToolbar: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: null
    },
    noContentPadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getIsInspoMode']),
    isRoutePublic () {
      return ['BoardsPublicView', 'DiscoveryExpertPublicView', 'DiscoveryExpertBoardPublicView'].includes(this.$route.name)
    }
  }
}
</script>

<style scoped lang="sass">
.main-layout
  max-height: calc(100vh - 32px)

  &--toolbar
    z-index: 49999
    background: rgba(255, 255, 255, 0.92)
    box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13)
    backdrop-filter: blur(12px)

    @apply border-b border-border-normal sticky top-0

  &--toolbar-scroll
    z-index: 49999
    @apply bg-white border-gray-300 shadow-sm

  &--content
    animation: fade 500ms ease-in-out
    @apply rounded-b-xl
</style>
