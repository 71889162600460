<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M11.4212 4.15757C11.1612 3.20235 10.2876 2.5 9.25 2.5C8.00736 2.5 7 3.50736 7 4.75C7 5.99264 8.00736 7 9.25 7C9.54265 7 9.82232 6.94412 10.0788 6.84243M11.4212 4.15757C11.6777 4.05588 11.9574 4 12.25 4C13.4927 4 14.5 5.00736 14.5 6.25C14.5 7.49264 13.4927 8.5 12.25 8.5C11.2124 8.5 10.3388 7.79765 10.0788 6.84243M11.4212 4.15757C10.3688 4.57478 9.78362 5.758 10.0788 6.84243M6.25 16H7.75C7.75 16 9.5437 16.6925 10.75 16.75C13.4097 16.8767 15.5225 15.6393 17.2338 13.7573C17.6246 13.3275 17.618 12.6787 17.2551 12.2251C16.7873 11.6403 15.9081 11.593 15.3215 12.0585C14.6751 12.5715 13.8174 13.1484 13 13.375C11.871 13.688 10.75 13.75 10.75 13.75C16.75 13.375 14.5 8.49989 6.25 11.8751M4.75 16.75C3.92157 16.75 3.25 16.0784 3.25 15.25V12.25C3.25 11.4216 3.92157 10.75 4.75 10.75C5.57843 10.75 6.25 11.4216 6.25 12.25V15.25C6.25 16.0784 5.57843 16.75 4.75 16.75Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'CharityNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>