<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M5.62504 9.375C5.27986 9.375 5.00004 9.65482 5.00004 10C5.00004 10.3452 5.27986 10.625 5.62504 10.625V9.375ZM14.375 10.625C14.7202 10.625 15 10.3452 15 10C15 9.65482 14.7202 9.375 14.375 9.375V10.625ZM13.125 13.75C12.7799 13.75 12.5 14.0298 12.5 14.375C12.5 14.7202 12.7799 15 13.125 15V13.75ZM13.125 5C12.7799 5 12.5 5.27982 12.5 5.625C12.5 5.97018 12.7799 6.25 13.125 6.25V5ZM6.87504 15C7.22022 15 7.50004 14.7202 7.50004 14.375C7.50004 14.0298 7.22022 13.75 6.87504 13.75V15ZM6.87504 6.25C7.22022 6.25 7.50004 5.97018 7.50004 5.625C7.50004 5.27982 7.22022 5 6.87504 5V6.25ZM5.62504 10.625H14.375V9.375H5.62504V10.625ZM17.9167 7.29167V12.7083H19.1667V7.29167H17.9167ZM16.875 13.75H13.125V15H16.875V13.75ZM13.125 6.25H16.875V5H13.125V6.25ZM0.833374 7.29167V12.7083H2.08337V7.29167H0.833374ZM3.12504 15H6.87504V13.75H3.12504V15ZM6.87504 5H3.12504V6.25H6.87504V5ZM2.08337 7.29167C2.08337 6.71637 2.54974 6.25 3.12504 6.25V5C1.85939 5 0.833374 6.02601 0.833374 7.29167H2.08337ZM17.9167 12.7083C17.9167 13.2836 17.4503 13.75 16.875 13.75V15C18.1407 15 19.1667 13.974 19.1667 12.7083H17.9167ZM0.833374 12.7083C0.833374 13.974 1.85939 15 3.12504 15V13.75C2.54974 13.75 2.08337 13.2836 2.08337 12.7083H0.833374ZM19.1667 7.29167C19.1667 6.02601 18.1407 5 16.875 5V6.25C17.4503 6.25 17.9167 6.71637 17.9167 7.29167H19.1667Z" 
    fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'LinkSquareFlatIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>