<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.875 8.95833V7.29167C16.875 6.37119 16.1288 5.625 15.2083 5.625H10.4459C10.1673 5.625 9.90713 5.48575 9.75257 5.25392L8.82807 3.86717C8.51896 3.4035 7.99858 3.125 7.44132 3.125H3.95829C3.03782 3.125 2.29163 3.87119 2.29163 4.79167V14.7308C2.29163 15.4548 2.87851 16.0417 3.60247 16.0417M16.875 8.95833H7.91369C7.17434 8.95833 6.52337 9.44542 6.31475 10.1547L4.86005 15.1007C4.69597 15.6586 4.18397 16.0417 3.60247 16.0417M16.875 8.95833H17.2196C17.7759 8.95833 18.176 9.49307 18.019 10.0268L16.6018 14.8453C16.3932 15.5546 15.7422 16.0417 15.0029 16.0417H3.60247"
      stroke="#ffffff"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FolderIcon',
  props: {
    width: {
      type: Number,
      default: () => 15
    },
    noTransition: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme', 'getLastTheme', 'getThemeChangedAt']),
    transitionClass () {
      let classStr = `fill-current text-${this.getTheme}-logo`

      if (+new Date() - this.getThemeChangedAt < 1000 && !this.noTransition) {
        // So buttons mounted after the theme changes dont animate
        classStr += ` ${this.getLastTheme}-to-${this.getTheme}`
      }

      return classStr
    },
    fill () {
      if (this.getTheme === 'purple') {
        return '#834BB5'
      }

      return '#3462DA'
    }
  }
}
</script>
<style scoped lang="css">
/* Blue to Purple Animations */
.blue-to-purple {animation: b-to-p 500ms ease-in; animation-iteration-count: 1; }

@keyframes b-to-p {
  0% { color: #3A6FFB; }
  100% { color: #834BB5; }
}

/* Purple to Blue Animations */
.purple-to-blue {animation: p-to-b 500ms ease-in; animation-iteration-count: 1;}

@keyframes p-to-b {
  0% { color: #834BB5; }
  100% { color: #3A6FFB; }
}

/* Purple to Green Animations */
.purple-to-green {animation: p-to-g 500ms ease-in; animation-iteration-count: 1;}

@keyframes p-to-g {
  0% { color: #834BB5; }
  100% { color: #14C78C; }
}

/* Blue to Green Animations */
.blue-to-green {animation: b-to-g 500ms ease-in; animation-iteration-count: 1;}

@keyframes b-to-g {
  0% { color: #3A6FFB; }
  100% { color: #14C78C; }
}

/* Green to Purple Animations */
.green-to-purple {animation: g-to-p 500ms ease-in; animation-iteration-count: 1;}

@keyframes g-to-p {
  0% { color: #14C78C; }
  100% { color: #834BB5; }
}

/* Green to Blue Animations */
.green-to-blue {animation: g-to-b 500ms ease-in; animation-iteration-count: 1;}

@keyframes g-to-b {
  0% { color: #14C78C; }
  100% { color: #3A6FFB; }
}
</style>
