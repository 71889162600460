import { render, staticRenderFns } from "./TagsListDisplay.vue?vue&type=template&id=440c7cbe&scoped=true"
import script from "./TagsListDisplay.vue?vue&type=script&lang=js"
export * from "./TagsListDisplay.vue?vue&type=script&lang=js"
import style0 from "./TagsListDisplay.vue?vue&type=style&index=0&id=440c7cbe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "440c7cbe",
  null
  
)

export default component.exports