import { render, staticRenderFns } from "./BaseInput.vue?vue&type=template&id=1b92fe8c&scoped=true"
import script from "./BaseInput.vue?vue&type=script&lang=js"
export * from "./BaseInput.vue?vue&type=script&lang=js"
import style0 from "./BaseInput.vue?vue&type=style&index=0&id=1b92fe8c&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b92fe8c",
  null
  
)

export default component.exports