<template>
  <!-- Base Tabs -->
  <div class="base-tabs">
    <div
      v-for="tab in tabs"
      :key="tab"
      class="base-tabs--tab"
      :class="{ 'base-tabs--tab--selected': tab === value }"
      @click="$emit('input', tab)"
    >
      {{ tab }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="sass">
.base-tabs
  height: 50px
  font-size: 15px
  @apply flex border border-gray-300 rounded-l-lg rounded-r-lg

  &--tab
    @apply cursor-pointer flex items-center justify-center bg-white border-r border-gray-300 text-center transition-all w-full

    &--selected
      @apply bg-blue-100 text-blue-600

    &:hover
      @apply text-blue-500

    &:last-child
      @apply rounded-r-lg border-r-0

    &:first-child
      @apply rounded-l-lg border-l-0
</style>
