<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path d="M7.99992 5.16659V7.99992L9.83325 9.83325M14.1666 7.99992C14.1666 11.4057 11.4057 14.1666 7.99992 14.1666C4.59416 14.1666 1.83325 11.4057 1.83325 7.99992C1.83325 4.59416 4.59416 1.83325 7.99992 1.83325C11.4057 1.83325 14.1666 4.59416 14.1666 7.99992Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'ClockIcon',
    props: {
      width: {
        type: String,
        default: '16'
      },
      height: {
        type: String,
        default: '16'
      },
      stroke: {
        type: String,
        default: '#9DA3AE'
      }
    }
  }
</script>

<style scoped></style>