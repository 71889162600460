<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M12.2917 12.5C12.2917 11.0042 13.5042 9.79166 15 9.79166C16.4958 9.79166 17.7083 11.0042 17.7083 12.5M12.2917 15C12.2917 16.4958 13.5042 17.7083 15 17.7083C16.4958 17.7083 17.7083 16.4958 17.7083 15M15 13.125V14.375M10.2083 17.7083H5.62501C4.70454 17.7083 3.95834 16.9621 3.95834 16.0417V3.95832C3.95834 3.03785 4.70454 2.29166 5.62501 2.29166H14.375C15.2955 2.29166 16.0417 3.03785 16.0417 3.95832V7.29166M7.29168 5.62499H12.7083M7.29168 8.95832H9.37501" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'LandingPagesIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>