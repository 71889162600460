<template>
  <lottie 
    :options="defaultOptions"
    :width="width"
    :height="height"
  />
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../assets/lotties/saveAdLoading.json'

export default {
  name: 'SaveAdLoadingAnimatedIcon',
  components: {
    lottie: Lottie
  },
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {
      defaultOptions: {
        animationData: animationData,
        loop: true,
        autoplay: true
      }
    }
  }
}
</script>

<style scoped></style>