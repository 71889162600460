<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99985 16.5251H5.27485C4.28074 16.5251 3.47485 15.7192 3.47485 14.7251V5.2751C3.47485 4.28099 4.28074 3.4751 5.27485 3.4751H9.99985H14.7249C15.719 3.4751 16.5249 4.28099 16.5249 5.2751V9.7751" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 3.4751V16.5251" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.525 9.7749H10" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.99985 9.7749H3.47485" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.4999 12.4751V14.5001M14.4999 14.5001V16.5251M14.4999 14.5001H12.4749M14.4999 14.5001H16.5249" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'NewBoardIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>