<template>
  <!-- Subscription Card -->
  <div
    class="border border-gray-300 grid grid-cols-10 p-4 rounded-lg transition-all mt-3 w-full mx-1 cursor-pointer"
    :class="{ 'ring-2 ring-offset-2 ring-blue-600': selected }"
  >
    <!-- Radio Section -->
    <div class="col-span-1 pt-1">
      <!--  -->
      <fieldset v-if="subscriptionStateText !== 'Active'">
        <legend class="sr-only">
          Notification method
        </legend>
        <div>
          <div
            class="flex items-center"
          >
            <input
              name="notification-method"
              type="radio"
              :checked="selected"
              class="h-5 w-5 border-gray-300 text-blue-600 ring-blue-600"
            >
          </div>
        </div>
      </fieldset>
    </div>

    <!-- Info Section -->
    <div class="col-span-7 flex flex-col justify-start items-start h-full">
      <!-- Subscription Name -->
      <div class="font-medium text-lg flex items-center gap-2">
        <div>
          {{ subscription.name }}
        </div>
        <div
          v-if="subscriptionStateText === 'Active'"
          class="text-sm bg-green-100 text-green-600 px-2 py-px rounded-full border border-green-300"
        >
          <!--  -->
          Active
        </div>
      </div>

      <!-- Subscription Description -->
      <div
        class="text-gray-600 mb-1"
        v-html="annual ? subscription.description.replace('$20', '<span class=\'font-semibold\'>$18</span>') : subscription.description"
      />

      <!-- Product Images -->
      <div class="flex gap-2 mt-2">
        <BaseTooltip
          placement="bottom"
          dark
        >
          <img
            slot="reference"
            class="h-8"
            src="../../../../assets/images/library-new.png"
          >
          <div slot="content">
            <div class="arrow absolute top-1 rotate-45 left-1/2 transform -translate-x-1/2 -mt-2 rounded-sm w-4 h-4 bg-gray-900" />
            <div
              class="my-1 font-medium"
            >
              Library
            </div>
          </div>
        </BaseTooltip>
        <BaseTooltip
          placement="bottom"
          dark
        >
          <img
            slot="reference"
            class="h-8"
            src="../../../../assets/images/discovery-new.png"
          >
          <div slot="content">
            <div class="arrow absolute top-1 rotate-45 left-1/2 transform -translate-x-1/2 -mt-2 rounded-sm w-4 h-4 bg-gray-900" />
            <div
              class="my-1 font-medium"
            >
              Discovery
            </div>
          </div>
        </BaseTooltip>
        <BaseTooltip
          v-if="subscription.name !== 'Inspiration'"
          placement="bottom"
          dark
        >
          <img
            slot="reference"
            class="h-8"
            src="../../../../assets/images/briefs-new.png"
          >
          <div slot="content">
            <div class="arrow absolute top-1 rotate-45 left-1/2 transform -translate-x-1/2 -mt-2 rounded-sm w-4 h-4 bg-gray-900" />
            <div
              class="my-1 font-medium"
            >
              AI Brief Builder
            </div>
          </div>
        </BaseTooltip>
      </div>
    </div>

    <!-- Price Section -->
    <div
      v-if="annual"
      class="col-span-1 text-lg font-medium text-gray-600 whitespace-nowrap"
    >
      <div>
        {{ `$${Math.floor(getPrice/12)}/mo` }}
      </div>
      <div class="text-gray-400 line-through">
        {{ `$${subscription.metadata.monthlyPrice}/mo` }}
      </div>
    </div>
    <div
      v-else
      class="col-span-1 text-lg font-medium text-gray-600 ml-auto"
    >
      {{ `$${getPrice}/mo` }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SubscriptionCard',
  props: {
    subscription: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Boolean
    },
    annual: {
      type: Boolean
    },
    subscriptionState: {
      type: String,
      default: () => 'Inspiration'
    }
  },
  computed: {
    ...mapGetters('AuthModule', [
      'getUserSubscription'
    ]),
    showSubscriptionTag () {
      if ((['free', 'expired'].includes(this.subscriptionState) && this.subscription.name === 'Inspiration') || this.subscriptionState === this.subscription.name) {
        return true
      }

      return false
    },
    getPrice () {
      if (this.annual) {
        if (this.subscription.annualPrice.billing_scheme === 'tiered') {
          return this.subscription.annualPrice.tiers[0].flat_amount / 100
        }

        return this.subscription.annualPrice.unit_amount / 100
      }

      if (this.subscription.price.billing_scheme === 'tiered') {
        return this.subscription.price.tiers[0].flat_amount / 100
      }

      return this.subscription.price.unit_amount / 100
    },
    subscriptionStateClasses () {
      if (this.subscriptionState === this.subscription.name && (this.getUserSubscription.price.interval === 'year') === this.annual) {
        return 'bg-green-100 text-green-800'
      } else if (this.subscriptionState === this.subscription.name) {
        return 'hidden'
      } else if (this.subscriptionState === 'free') {
        return 'bg-yellow-100 text-yellow-700'
      }

      return 'bg-red-100 text-red-800'
    },
    subscriptionStateText () {
      if (this.subscriptionState === this.subscription.name && (this.getUserSubscription.price.interval === 'year') === this.annual) {
        return 'Active'
      } else if (this.subscriptionState === 'free') {
        return 'On Trial'
      }

      return 'Expired'
    }
  }
}
</script>
