<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M5.20841 6.24996C5.78371 6.24996 6.25008 5.78359 6.25008 5.20829C6.25008 4.633 5.78371 4.16663 5.20841 4.16663C4.63312 4.16663 4.16675 4.633 4.16675 5.20829C4.16675 5.78359 4.63312 6.24996 5.20841 6.24996Z" 
    :fill="stroke || fill" />
    <path d="M10.8334 10.7839V14.2164C10.8334 14.7221 11.4026 15.0184 11.8168 14.7285L14.2686 13.0122C14.6241 12.7634 14.6241 12.237 14.2686 11.9882L11.8168 10.2719C11.4026 9.98194 10.8334 10.2783 10.8334 10.7839Z" 
    :fill="stroke || fill" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66675 3.95829C1.66675 2.69264 2.69276 1.66663 3.95841 1.66663H11.0417C12.3074 1.66663 13.3334 2.69264 13.3334 3.95829V6.66663H16.0417C17.3074 6.66663 18.3334 7.69264 18.3334 8.95829V16.0416C18.3334 17.3073 17.3074 18.3333 16.0417 18.3333H8.95842C7.69276 18.3333 6.66675 17.3073 6.66675 16.0416V13.3333H3.95841C2.69276 13.3333 1.66675 12.3073 1.66675 11.0416V3.95829ZM12.0834 3.95829V6.66663H8.95842C7.77893 6.66663 6.80757 7.55768 6.68077 8.70336L6.26965 8.42928C5.49988 7.9161 4.49704 7.9161 3.72727 8.42928L2.91675 8.96963V3.95829C2.91675 3.383 3.38312 2.91663 3.95841 2.91663H11.0417C11.617 2.91663 12.0834 3.383 12.0834 3.95829ZM5.57627 9.46934L6.66675 10.1963V12.0833H3.95841C3.38312 12.0833 2.91675 11.6169 2.91675 11.0416V10.4719L4.42065 9.46934C4.77054 9.23608 5.22638 9.23608 5.57627 9.46934ZM7.91675 16.0416V8.95829C7.91675 8.383 8.38312 7.91663 8.95842 7.91663H16.0417C16.617 7.91663 17.0834 8.383 17.0834 8.95829V16.0416C17.0834 16.6169 16.617 17.0833 16.0417 17.0833H8.95842C8.38312 17.0833 7.91675 16.6169 7.91675 16.0416Z" 
    :fill="stroke || fill"/>
  </svg>
</template>

<script>
export default {
  name: 'FormatIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    fill: {
      type: String,
      default: '#5E6678'
    },
    stroke: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="css"></style>
