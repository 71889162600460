<template>
  <div class="px-4">
    <BaseSelect
      item-value="name"
      :items="availableOptions"
      text-trigger
      scrollable
      popper-right-center
      @select="() => {}"
      @input="() => {}"
    >
      <!-- Title -->
      <div
        v-if="!buttonReference"
        slot="reference"
        class="rounded-lg border border-dashed border-border-normal hover:border-border-hover hover:bg-background-normal text-text-muted hover:text-text-normal transition flex items-center gap-2 py-3 px-4"
      >
        <img src="../../../assets/icons/plus-icon.svg">
        <BaseText
          type="label"
          size="sm"
          class=""
        >
          Add New
        </BaseText>
      </div>
      <div
        v-else
        slot="reference"
      >
        <slot name="button" />
      </div>

      <!-- Options -->
      <template #item="{ item }">
        <div
          class="w-max flex items-center py-2.5 px-3 rounded transition cursor-pointer"
          @click="createComponent(item.type)"
        >
          <!-- Icon -->
          <div
            style="width: 36px; height: 36px;"
            class="icon-shadow mr-3 rounded-full flex items-center justify-center bg-white"
          >
            <!-- Icon -->
            <img
              v-if="item.type === 'description'"
              src="../../../assets/icons/document.svg"
            >
            <img
              v-if="item.type === 'aspectRatio'"
              src="../../../assets/icons/size.svg"
            >
            <img
              v-if="item.type === 'format'"
              src="../../../assets/icons/file.svg"
            >
            <img
              v-if="item.type === 'targetDuration'"
              src="../../../assets/icons/clock2.svg"
            >
            <img
              v-if="item.type === 'platforms'"
              src="../../../assets/icons/flag.svg"
            >
            <img
              v-if="item.type === 'contentStyle'"
              src="../../../assets/icons/wand.svg"
            >
            <img
              v-if="item.type === 'products'"
              src="../../../assets/icons/archive.svg"
            >
            <img
              v-if="item.type === 'assets'"
              src="../../../assets/icons/file-chain.svg"
            >
            <img
              v-if="item.type === 'audio'"
              src="../../../assets/icons/note.svg"
            >
            <img
              v-if="item.type === 'loomLinks'"
              src="../../../assets/icons/loom.svg"
            >
          </div>

          <!-- Text -->
          <div>
            <!-- Title -->
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              {{ item.title }}
            </BaseText>

            <!-- Description -->
            <BaseText
              type="body"
              size="sm"
              class="text-text-subdued whitespace-nowrap"
            >
              {{ item.description }}
            </BaseText>
          </div>
        </div>
      </template>
    </BaseSelect>
  </div>
</template>

<script>
export default {
  name: 'AddNewSelector',
  components: {
  },
  props: {
    buttonReference: {
      type: Boolean,
      default: false
    },
    currentItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      options: [
        {
          title: 'Description',
          description: 'Add a project description',
          type: 'description'
        },
        {
          title: 'Size & Aspect Ratios',
          description: 'Add dimension details',
          type: 'aspectRatio'
        },
        {
          title: 'Format',
          description: 'Add file format details',
          type: 'format'
        },
        {
          title: 'Target Duration',
          description: 'Set the time',
          type: 'targetDuration'
        },
        {
          title: 'Platforms',
          description: 'Add platform details',
          type: 'platforms'
        },
        {
          title: 'Content Style',
          description: 'Add content styling',
          type: 'contentStyle'
        },
        {
          title: 'Products',
          description: "Add project's products",
          type: 'products'
        },
        {
          title: 'Assets & Files',
          description: 'Add assets and files',
          type: 'assets'
        },
        {
          title: 'Music and Sound',
          description: 'Add music or sound',
          type: 'audio'
        },
        {
          title: 'Loom Videos',
          description: 'Add Loom video',
          type: 'loomLinks'
        }
      ]
    }
  },
  computed: {
    availableOptions () {
      return this.options.filter(option => {
        return !this.currentItems.find(item => item.type === option.type)
      })
    }
  },
  mounted () {
  },
  methods: {
    createComponent (name) {
      this.$emit('createComponent', name)
    }
  }
}
</script>

<style scoped lang="css">
</style>
