<template>
  <div
    class="px-6 flex-1 flex justify-end "
    @click="showNinja"
  >
    <div class="max-w-lg w-full border-b border-white border-opacity-10 lg:max-w-xs">
      <label
        for="search"
        class="sr-only"
      >Search</label>
      <div class="flex text-white w-full">
        <!-- Heroicon name: solid/search -->
        <div
          class="frosty-card flex cursor-pointer w-full py-2 border-b border-transparent leading-5 text-gray-400 sm:text-sm"
          @click="showNinja"
        >
          <img
            src="../../assets/icons/search.svg"
            alt=""
            class="mr-2 h-6 w-6"
          >
          <BaseText
            tyle="body"
            size="sm"
            class="text-white text-opacity-30 flex justify-center items-center"
          >
            Search
          </BaseText>
          <span class="ml-auto flex opacity-40">
            <div
              class="mr-2 h-5 w-5 text-xs text-white rounded flex items-center justify-center cursor-pointer border border-white border-opacity-5 bg-white bg-opacity-5"
            >
              <svg class="h-4 w-4 fill-white">
                <use :xlink:href="`${remixiconUrl}#ri-command-line`" />
              </svg>
            </div>
            <div
              class="font-semibold h-5 w-5 text-sm leading-5 text-center text-white rounded align-middle  border border-white border-opacity-5 bg-white bg-opacity-5"
            >
              K
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchBar',
  data () {
    return {
      remixiconUrl
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme'])
  },
  methods: {
    // Opens the search modal
    showNinja () {
      const ninja = document.querySelector('ninja-keys')
      ninja.open()
    }
  }
}
</script>
<style scoped lang="sass">
.frosty-card-blue
  padding: 10px
  background-image: radial-gradient(circle farthest-corner at 100% 0%, rgba(52, 98, 218, 0.45), rgba(52, 98, 218, 0) 49%)
  backdrop-filter: saturate(100%) blur(2px)

.frosty-card-purple
  padding: 10px
  background-image: radial-gradient(circle farthest-corner at 100% 0%, rgba(131, 75, 181, 0.45), rgba(131, 75, 181, 0) 49%)
  backdrop-filter: saturate(100%) blur(2px)

.frosty-card-green
  padding: 10px
  background-image: radial-gradient(circle farthest-corner at 100% 0%, rgba(20, 199, 140, 0.45), rgba(20, 199, 140, 0) 49%)
  backdrop-filter: saturate(100%) blur(2px)
</style>
