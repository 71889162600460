<template>
  <!-- Manage Board Modal -->
  <BaseModal :no-exit="true">
    <template #toolbar>
      <div class="text-xl text-black">
        <span>Team Invitation</span>
      </div>
    </template>

    <template #default>
      <div class="px-4">
        <div class="text-base">
          You have been invited to join {{ team.teamName }}
        </div>
        <div class="flex w-full mt-4">
          <BaseButton
            class="border-none bg-blue-700 text-white mr-5"
            @click="acceptInvitation"
          >
            Join Team
          </BaseButton>
          <BaseButton
            class="border-none bg-red-200 text-red-600 w-24"
            @click="rejectInvitation"
          >
            Decline
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import API from '@/api'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TeamInvitationModal',
  props: {
    // Board
  },
  data () {
    return {
      invitation: {},
      team: {}
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getPendingInvitations', 'getTeam'])
  },
  async mounted () {
    this.team = await this.getInvitationTeamTeam()
  },
  methods: {
    ...mapActions('AuthModule', ['fetchUserInvitations', 'fetchUserTeam']),
    ...mapActions('BoardsModule', ['fetchBoards']),
    async rejectInvitation () {
      await FirebaseAPI.Invitations.reject(this.getPendingInvitations[0].id)
      await this.fetchUserInvitations()
    },
    async acceptInvitation () {
      try {
        await FirebaseAPI.Invitations.accept(this.getPendingInvitations[0].id)
        await FirebaseAPI.Teams.join(this.getPendingInvitations[0].teamId)

        await this.fetchUserInvitations()
        await this.fetchUserTeam()
        console.log(this.getTeam.id)

        // migrate users assets to their team
        await API.Teams.migrateUserToTeam(this.getTeam.id)
        await API.Teams.addUserToTeam(this.getTeam.id)
        await this.fetchBoards()
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error.message,
          type: 'error'
        })
      }
    },
    async getInvitationTeamTeam () {
      return FirebaseAPI.Teams.getByID(this.getPendingInvitations[0].teamId)
    }
  }
}
</script>

<style scoped></style>
