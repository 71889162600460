<template>
  <div
    class="cursor-pointer rounded transition duration-200"
    :class="{
      'hover:bg-gray-200 p-1 mx-1': !group,
    }"
    @mouseover="play"
    @mouseleave="reset"
    @click="$emit('click')"
  >
    <lottie
      :options="defaultOptions"
      :height="20"
      :width="20"
      @animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../assets/lotties/copy.json'
export default {
  name: 'CopyIcon',
  components: {
    lottie: Lottie
  },
  props: {
    group: {
      type: Boolean,
      default: false
    },
    groupPlay: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultOptions: { animationData: animationData, loop: false, autoplay: false }
    }
  },
  watch: {
    groupPlay (val) {
      if (val) {
        this.play()
      } else {
        this.reset()
      }
    }
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim
      this.anim.setSpeed(0.4)
    },
    play () {
      if (this.group && !this.groupPlay) return

      this.anim.play()
    },
    reset () {
      this.anim.stop()
    }
  }
}
</script>
