<template>
  <svg xmlns="http://www.w3.org/2000/svg" @click="$emit('click')" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M6.4585 6.45837L13.5418 13.5417M13.5418 6.45837L6.4585 13.5417" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'TagRemoveIcon',
    props: {
      stroke: {
        type: String,
        default: '#303546'
      },
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped lang="css"></style>