<template>
  <!-- Base Button -->
  <VuePopper
    trigger="hover"
    :style="`z-index: ${20000 + index}`"
    :visible-arrow="true"
    :options="{
      placement,
      modifiers: {
        strategy: 'fixed',
        offset: {
          offset: offset
        }
      }
    }"
    enter-active-class="z-10"
  >
    <!-- POPPER CONTENT -->
    <div>
      <div
        class="popper relative border-none text-white rounded-md"
        :class="[!noBackground ? (dark ? 'bg-gray-900' : 'bg-gray-500') : 'bg-transparent', noShadow ? 'shadow-none' : 'shadow-lg', {'hidden' : hidePopper }]"
      >
        <slot
          name="content"
        />
      </div>
    </div>
    <!-- REFRENCE TO OPEN POPPER -->
    <div
      slot="reference"
    >
      <slot
        name="reference"
      />
    </div>
  </VuePopper>
</template>

<script>
import VuePopper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'BaseTooltip',
  components: {
    VuePopper
  },
  props: {
    dark: {
      type: Boolean,
      default: () => false
    },
    placement: {
      type: String,
      default: () => 'top'
    },
    offset: {
      type: String,
      default: () => '0,10px'
    },
    index: {
      type: Number,
      default: () => 0
    },
    hidePopper: {
      type: Boolean,
      default: () => false
    },
    noBackground: {
      type: Boolean,
      default: () => false
    },
    noShadow: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>
</style>
