<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M3.60337 6.16435L2.5 6.56407L4.2298 9.11313L4.04413 9.50943C3.87611 9.86808 3.85674 10.2786 3.99024 10.6515L4.0461 10.8075C4.32952 11.5991 5.20816 12.0031 5.99361 11.703L8.6875 10.674L8.75538 11.6487C8.79003 12.1457 9.29065 12.4714 9.75903 12.3018L10.4893 12.0372C10.7809 11.9316 11.0326 11.7381 11.2098 11.4835L12.8125 9.17958L15.9924 8.0276C16.5733 7.81717 16.9602 7.26553 16.9602 6.64774C16.9602 5.97333 16.5006 5.38573 15.846 5.22331L14.3883 4.86163C14.0947 4.78876 13.7865 4.80615 13.503 4.9119C12.2976 5.36155 8.90425 6.6258 7.45065 7.15238C7.03257 7.30384 6.56851 7.26264 6.18396 7.03993L4.86603 6.27664C4.48242 6.05446 4.02018 6.01336 3.60337 6.16435Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.25 15.25H16.75" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'TravelNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>