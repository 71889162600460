<template>
  <lottie 
    :options="{
      animationData,
      loop: true,
      autoplay: true
    }"
    :width="width"
    :height="height"
  />
</template>

<script>
import lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../../assets/lotties/scrapingLoading.json'

export default {
  name: 'ScrapingLoadingIcon',
  components: {
    lottie: lottie
  },
  data () {
    return {
      animationData: animationData
    }
  },
  props: {
    width: {
      type: Number,
      default: 25
    },
    height: {
      type: Number,
      default: 25
    }
  }
}
</script>

<style scoped></style>