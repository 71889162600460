<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 12.4375H15.4375V10.375L15.1551 10.0926C14.6136 9.55112 14.1981 8.90718 13.9275 8.20636M10 12.4375V16.9375M10 12.4375V12.5M6.5625 12.4375H4.5625V10.375L4.84489 10.0926C5.86462 9.07288 6.4375 7.68983 6.4375 6.24771V4.5625C6.4375 3.73407 7.10907 3.0625 7.9375 3.0625H12.0625C12.8909 3.0625 13.5625 3.73407 13.5625 4.5625V5.4375M15.3137 3.68629L4 15" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'UnpinIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>