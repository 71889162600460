<template #default>
  <div class="flex flex-col items-center justify-center px-8 py-4">
    <!-- Offer text bubble -->
    <div class="flex items-center justify-center border-secondary-green-100 border-opacity-25 bg-secondary-green-100 bg-opacity-15 bg-opacity-10 border rounded px-2 py-1">
      <BaseText
        type="label"
        size="xs"
        class="text-primary-green-100"
      >
        Limited 1-Time Offer
      </BaseText>
    </div>

    <!-- Heading and subheading text -->
    <div class="flex flex-col items-center justify-center mt-4 mb-7">
      <BaseText
        size="md"
        type="body"
        class="text-text-muted font-medium"
      >
        Upgrade Your Account Now & Save
      </BaseText>
      <BaseText
        size="sm"
        type="body"
        class="text-text-normal text-center"
      >
        Add 10 Spyder Brands to your account and get 50% off this package for life!
      </BaseText>
    </div>

    <!-- Offer card -->
    <div class="w-full flex rounded-lg border border-gray-300 p-4">
      <!-- Image -->
      <img
        class="h-14"
        src="../../../../assets/icons/spyder.png"
      >

      <!-- Text -->
      <div class="ml-3 flex flex-col justify-center">
        <BaseText
          type="body"
          size="sm"
          class="text-text-muted"
        >
          10 Spyder Brands
        </BaseText>
        <BaseText
          type="body"
          size="xs"
          class="text-text-normal"
        >
          Track every ad your competitors launch
        </BaseText>
      </div>

      <!-- Price -->
      <div class="ml-auto flex flex-col justify-center items-end">
        <BaseText
          type="body"
          size="sm"
          class="text-text-disabled"
          line-through
        >
          $99
        </BaseText>
        <BaseText
          type="body"
          size="md"
          class="text-primary-green-100"
        >
          $49.50
        </BaseText>
      </div>
    </div>

    <!-- Buttons -->
    <div class="w-full flex flex-col gap-3 mt-8">
      <BaseButton
        primary
        :loading="loading"
        @click="confirmUpsell"
      >
        <BaseText
          type="label"
          size="sm"
        >
          Yes, Upgrade My Subscription!
        </BaseText>
      </BaseButton>
      <BaseButton
        outlined
        @click="$emit('nextStep')"
      >
        <BaseText
          type="label"
          size="sm"
        >
          No Thanks
        </BaseText>
      </BaseButton>
    </div>
  </div>
</template>
<script>
import API from '@/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SpyderUpsell',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getStripeCustomer'])
  },
  methods: {
    ...mapActions('AuthModule', ['setUserSubscription']),
    async confirmUpsell () {
      try {
        this.loading = true

        await API.Stripe.createSpyderSubscription('price_1NpudLAQtq0TW7KZbBqLvDiO', this.getStripeCustomer.stripeId, this.getUser.user_id, 10, true)
        await this.setUserSubscription({ uid: this.getUser.user_id })

        this.$showAlert({
          message: 'Your account has been upgraded. Thank you!',
          type: 'success'
        })

        this.$emit('nextStep')
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: 'Something went wrong upgrading your acount.',
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
