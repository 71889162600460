var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('VuePopper',{ref:"popper",attrs:{"trigger":"clickToOpen","options":{
      placement: _vm.placement,
      flipVariations: false
    }}},[_c('div',{staticClass:"dropdown-card mt-2",class:{
        'popper-right': _vm.popperRight,
        'popper-right-center': _vm.popperRightCenter,
        'popper-center' : _vm.popperCenter,
        'popper': !_vm.popperRight && !_vm.popperRightCenter,
        'overflow-y-scroll max-h-96': _vm.scrollable,
        'overflow-hidden': !_vm.scrollable,
      },staticStyle:{"z-index":"100000"},style:(_vm.compact ? '' : 'min-width: 200px;')},[(_vm.items.length)?_c('div',{class:{
          'p-0': _vm.noPadding,
        }},_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"px-1 pb-1",class:{
              'py-0 pb-1': index === _vm.items.length - 1 ,
              'py-1' : index === 0
            }},[_c('div',{staticClass:"cursor-pointer transition duration-300 py-1.5 px-2 flex justify-between hover:bg-neutral-25 hover:bg-opacity-40 rounded-md",on:{"click":function($event){return _vm.handleSelectItem(item)}}},[_vm._t("item",null,{"item":item}),(_vm.selected.map(e => e[_vm.itemValue]).includes(item[_vm.itemValue]))?_c('div',{staticClass:"pl-2"},[_c('img',{attrs:{"src":require("../../assets/icons/circle-checkmark-confirm.svg")}})]):_c('div',{staticClass:"opacity-0",class:{
                  'pl-2': !_vm.noPadding
                }},[(!_vm.noPadding)?_c('img',{attrs:{"src":require("../../assets/icons/circle-checkmark-confirm.svg")}}):_vm._e()])],2)]),(item.divider)?_c('div',{staticClass:"w-full divider mb-1",staticStyle:{"background":"#12376914"}}):_vm._e()])}),0):_vm._e()]),_c('div',{staticClass:"cursor-pointer select-none",attrs:{"slot":"reference"},on:{"click":_vm.closeDrawer},slot:"reference"},[(_vm.textTrigger)?_c('div',{staticClass:"rounded-md hover:bg-neutral-25 hover:bg-opacity-5 transition-all",class:{
          'px-2 py-1.5': !_vm.noPadding
        }},[_vm._t("reference")],2):_c('div',{staticClass:"default-dropdown-reference py-1.5 px-2 rounded-md flex items-center",class:_vm.noPadding ? 'p-0' : 'default-dropdown-reference'},[(_vm.leftIcon)?_c('div',{staticClass:"mr-2"},[_vm._t("leftIcon")],2):_vm._e(),_vm._t("reference"),(_vm.rightIcon)?_c('div',{staticClass:"ml-2"},[_vm._t("rightIcon")],2):_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }