<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 12.4375H4.5625V10.375L4.84489 10.0926C5.86462 9.07288 6.4375 7.68983 6.4375 6.24771V4.5625C6.4375 3.73407 7.10907 3.0625 7.9375 3.0625H12.0625C12.8909 3.0625 13.5625 3.73407 13.5625 4.5625V6.24771C13.5625 7.68983 14.1354 9.07288 15.1551 10.0926L15.4375 10.375V12.4375H10ZM10 12.4375V16.9375" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'PinIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>