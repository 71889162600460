<template>
  <svg
    :width="width"
    :height="width*1.0435"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5854 0.587891C5.38397 0.587891 0.356689 5.61517 0.356689 11.8166C0.356689 18.0181 5.38397 23.0454 11.5854 23.0454C17.7869 23.0454 22.8141 18.0181 22.8141 11.8166C22.8076 5.61794 17.7841 0.594517 11.5854 0.587891ZM16.3143 12.1744C16.2365 12.3303 16.1102 12.4567 15.9542 12.5345V12.5385L9.53779 15.7467C9.14152 15.9447 8.65982 15.784 8.46178 15.3877C8.40548 15.2751 8.37649 15.1508 8.37719 15.0248V8.60844C8.377 8.16546 8.73591 7.80623 9.17888 7.80599C9.30347 7.80595 9.42636 7.8349 9.53779 7.89059L15.9542 11.0988C16.3506 11.2964 16.5119 11.7779 16.3143 12.1744Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VideoIcon',
  props: {
    selected: {
      type: Boolean
    },
    fill: {
      type: String,
      default: '#778295'
    },
    width: {
      type: Number,
      default: () => 23
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme', 'getLastTheme', 'getThemeChangedAt'])
  }
}
</script>
<style scoped lang="css">
</style>
