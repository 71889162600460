<template>
  <!-- Base Table -->
  <table class="base-table">
    <!-- Table Head -->
    <thead>
      <tr>
        <!-- Table Headers -->
        <th
          v-for="header in headers"
          :key="header.id"
          :class="[header.class]"
          :style="header.style"
          class="base-table--header"
        >
          <div v-if="header.select">
            <BaseCheckbox />
          </div>

          <div v-else>
            {{ header.title }}
          </div>
        </th>
      </tr>
    </thead>

    <tbody v-if="loading">
      <tr
        v-for="row in 6"
        :key="row"
      >
        <td
          :colspan="headers.length"
          class="px-3 border-b border-gray-200"
          style="padding: 10px 15px"
        >
          <ContentLoader height="10">
            <rect
              x="0"
              y="2"
              rx="3"
              ry="3"
              width="100%"
              height="6"
            />
          </ContentLoader>
        </td>
      </tr>
    </tbody>

    <!-- Table Body -->
    <tbody v-else>
      <tr>
        <td
          v-if="!data.length"
          :colspan="headers.length"
          class="py-32 text-sm text-center text-xl"
        >
          <slot
            name="no-data"
          >
            No data found
          </slot>
        </td>
      </tr>

      <!-- Table Rows -->
      <template v-if="!loading">
        <tr
          v-for="(row, index) in data"
          :key="index"
          class="base-table--row"
        >
          <!-- Table Cells -->
          <td
            v-for="header in headers"
            :key="header.id"
            class="base-table--cell"
          >
            <!-- Slot For Cell -->
            <slot
              :name="header.value"
              :item="row"
            >
              {{ row[header.value] }}
            </slot>
          </td>
        </tr>
      </template>
    </tbody>

    <tbody
      v-if="showFooter && !loading"
      class="base-table--footer"
    >
      <tr>
        <td
          v-for="header in headers"
          :key="header.value"
          class="base-table--cell"
        >
          <slot
            :name="`footer-${header.value}`"
            :item="header"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'BaseTable',
  components: {
    ContentLoader
  },
  props: {
    // Table Headers
    headers: {
      type: Array,
      default: () => []
    },
    // Table Data
    data: {
      type: Array,
      default: () => []
    },
    // Loading Table
    loading: {
      type: Boolean
    },
    showFooter: {
      type: Boolean
    }
  }
}
</script>

<style scoped lang="sass">
table
  @apply w-full rounded

table tr td
  height: auto

.base-table
  @apply bg-white table overflow-y-auto w-full

  &--header
    height: 42px
    @apply border-b p-4 border-gray-300 font-medium text-left text-sm sticky top-0 z-20 whitespace-nowrap

  &--row
    @apply border-b border-gray-200 cursor-pointer

    &:hover
      @apply bg-gray-100

    &:first-child
      @apply border-t-0

    &:last-child
      @apply border-b-0

  &--cell
    @apply p-4 text-sm

  &--footer
    height: 42px
    transform: translateY(1px)
    @apply bg-indigo-100 bottom-0 sticky
</style>
