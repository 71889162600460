<template>
  <!-- Brands Table -->
  <div class="bg-white rounded-md border border-gray-200 mb-10">
    <!-- Table Header -->
    <div
      class="grid grid-cols-12 w-full py-2 px-4 bg-gray-100 text-gray-600 pr-5"
    >
      <span class="col-span-7 whitespace-nowrap">
        {{ brandTotal }} Brands
      </span>
      <span class="col-span-2 whitespace-nowrap"> Favorite </span>
      <span class="col-span-2 whitespace-nowrap"> Foreplay Ads </span>
      <span class="col-span-1 whitespace-nowrap"> FB Ad Library </span>
    </div>
    <!-- Rows -->
    <!-- For Each Submission -->
    <router-link
      v-for="(brand, index) in brands"
      :key="index"
      class="group grid grid-cols-12 cursor-pointer w-full py-2 px-4 border-b hover:bg-gray-50 transition duration-200 pr-5"
      :to="{ name: 'DiscoveryBrandView', params: { id: brand.id } }"
    >
      <div
        class="col-span-7 h-8 flex items-center transition text-lg font-semibold"
      >
        <img
          :src="brand.avatar"
          class="h-8 w-8 rounded-full mr-4"
        >
        <!-- File Name -->
        {{ brand.name }}
      </div>
      <!-- Creator -->
      <div class="col-span-2 flex flex-col items-start">
        <div
          class="h-8 flex items-center justify-between bg-gray-100 w-24 p-1 pr-2 pl-0 rounded text-gray-600"
          @click.prevent="favoriteBrand(brand.id, brand.name)"
        >
          <!--  -->
          <FavoriteIcon
            no-hover
            :active="favoritedBrands.includes(brand.id)"
          />
          Favorite
        </div>
      </div>
      <!-- Submitted Date -->
      <div class="text-gray-600 col-span-2 flex items-center h-full">
        <div
          class="h-8 flex items-center bg-gray-100 w-max justify-between p-1 px-2 rounded text-gray-600 whitespace-nowrap"
        >
          <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            class="mr-1 w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.0717773 0.848682C0.0717773 0.41521 0.423176 0.0638123 0.856648 0.0638123H2.88347V3.53677H0.0717773V0.848682Z"
              fill="#434B57"
            />
            <rect
              x="0.0717773"
              y="3.85271"
              width="2.81169"
              height="3.47296"
              fill="#434B57"
            />
            <path
              d="M0.0717773 7.64142H2.88347V11.1144H0.464213C0.247477 11.1144 0.0717773 10.9387 0.0717773 10.7219V7.64142Z"
              fill="#434B57"
            />
            <rect
              x="3.2478"
              y="0.0638275"
              width="2.81169"
              height="3.47296"
              fill="#434B57"
            />
            <rect
              x="3.2478"
              y="3.85271"
              width="2.81169"
              height="3.47296"
              fill="#434B57"
            />
            <path
              d="M6.42365 0.0638275H9.23534V3.53679H6.42365V0.0638275Z"
              fill="#434B57"
            />
          </svg>

          {{ formatNumber(brand.adsSaved) }} Saved
        </div>
      </div>
      <!-- Buttons -->
      <div class="w-24 col-span-1 h-full flex items-center whitespace-nowrap">
        <a
          v-if="brand.adLibraryId"
          :href="`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${brand.adLibraryId}`"
          target="_blank"
          class="flex items-center bg-gray-100 p-1 py-1 rounded text-gray-600"
          @click.stop
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 60 60"
            fill="none"
            class="mr-1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.9148 0H8.08567C3.62047 0 0.000549316 3.61977 0.000549316 8.08512V51.9146C0.000549316 56.3799 3.62032 59.9997 8.08567 59.9997H29.702L29.7389 38.5591H24.1686C23.4447 38.5591 22.8571 37.9737 22.8544 37.2498L22.8276 30.3386C22.8249 29.6108 23.4142 29.0192 24.142 29.0192H29.7022V22.3413C29.7022 14.5915 34.4352 10.3717 41.3485 10.3717H47.0214C47.7472 10.3717 48.3357 10.9602 48.3357 11.6861V17.5137C48.3357 18.2393 47.7476 18.8276 47.0221 18.828L43.5407 18.8297C39.7811 18.8297 39.0531 20.6162 39.0531 23.2381V29.0194H47.3143C48.1015 29.0194 48.7122 29.7067 48.6194 30.4885L47.8003 37.3997C47.7219 38.0611 47.1611 38.5594 46.4952 38.5594H39.0899L39.0531 60H51.9151C56.3803 60 60.0001 56.3802 60.0001 51.915V8.08512C60 3.61977 56.3802 0 51.9148 0Z"
              fill="currentColor"
            />
          </svg>

          Ad Library
        </a>
      </div>
    </router-link>

    <!-- Infinite Load Brands -->
    <infinite-loading
      v-if="brands.length"
      :identifier="infiniteId"
      @infinite="getMoreBrands"
    >
      <div slot="spinner">
        <BaseLoadingLogo :margin="2" />
      </div>
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'

import FavoriteIcon from '../globals/Icons/FavoriteIcon.vue'

export default {
  name: 'DiscoveryBrandView',
  components: {
    FavoriteIcon,
    InfiniteLoading
  },
  props: {
    favoriteFilter: {
      type: Boolean,
      default: () => false
    },
    textSearch: {
      type: String,
      default: () => ''
    },
    sortOrder: {
      type: Array,
      default: () => [
        {
          name: 'Ads Saved',
          value: 'saved'
        }
      ]
    },
    selectedNiches: {
      type: Array,
      default: () => []
    },
    updateQuery: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      brands: [],
      favoritedBrands: [],
      brandTotal: '0'
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', [
      'getUser',
      'getUserEmail',
      'getUserName',
      'getTeam'
    ]),
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  watch: {
    updateQuery (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBrands()
      }
    },
    favoriteFilter (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBrands()
      }
    },
    selectedNiches (newValue, oldValue) {
      if (newValue[0]?.name !== oldValue[0]?.name) {
        this.fetchBrands()
      }
    }
  },
  mounted () {
    this.fetchBrands()
    this.favoritedBrands = this.getUser.favoritedBrands || []
    this.getBrandsCount()
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async getBrandsCount () {
      const db = firebase.firestore()

      // Get the shards subdocuments
      const snapshot = await db
        .collection('counters')
        .doc('brands')
        .collection('shards')
        .get()

      // Sum the total of all the shards (this is to get around the 1 update per second firebase limitation)
      let totalCount = 0
      snapshot.forEach((doc) => {
        totalCount += doc.data().count
      })

      this.brandTotal = totalCount.toLocaleString(undefined, {
        minimumFractionDigits: 0
      })
    },
    async favoriteBrand (brandId, brandName) {
      const favoritedBrands = this.favoritedBrands.includes(brandId)
        ? this.favoritedBrands.filter((bId) => bId !== brandId)
        : [...this.favoritedBrands, brandId]

      window.analytics.track('Brand Favortied', {
        brandFavorited: true,
        brandName: brandName
      })

      // Update the firebase user
      const user = firebase.auth().currentUser
      await FirebaseAPI.Users.update(user.uid, {
        favoritedBrands
      })

      // Add to local state
      this.favoritedBrands = favoritedBrands

      // Update global state
      this.SET_USER({
        ...this.getUser,
        favoritedBrands
      })
    },
    async getMoreBrands ($state) {
      if (!this.lastDocId) {
        $state.complete()
        return
      }

      const { results, nextPage } = await ForeplayAPI.Brands.getDiscoveryBrands(
        this.lastDocId,
        {
          textSearch: this.textSearch.trim(),
          sort: this.textSearch.trim() === '' ? 'saved' : this.sortOrder[0].value,
          favorites: this.favoriteFilter,
          orFilters: {
            niches: this.selectedNiches
          }
        }
      )

      this.lastDocId = nextPage

      this.brands = this.brands.concat(results)
      $state.loaded()
    },
    async fetchBrands (load = true) {
      this.brands = []
      this.loadingBrands = load

      try {
        const { results, nextPage } =
          await ForeplayAPI.Brands.getDiscoveryBrands(null, {
            textSearch: this.textSearch.trim(),
            sort: this.textSearch.trim() === '' ? 'saved' : this.sortOrder[0].value,
            favorites: this.favoriteFilter,
            orFilters: {
              niches: this.selectedNiches
            }
          })

        this.lastDocId = nextPage
        this.brands = results

      } catch (e) {
        console.log(e)
      } finally {
        this.loadingBrands = false
        this.infiniteId += 1
      }

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    formatNumber (number) {
      if (number >= 1e3 && number < 1e6) {
        return (number / 1e3).toFixed(1) + 'k'
      } else if (number >= 1e6 && number < 1e9) {
        return (number / 1e6).toFixed(1) + 'M'
      } else if (number >= 1e9) {
        return (number / 1e9).toFixed(1) + 'B'
      } else {
        return number.toString()
      }
    }
  }
}
</script>

<style scoped lang="sass"></style>
