<template>
  <div
    class="transition"
    :class="{
      'cursor-pointer hover:border-primary-green-100 select-none': clickable,
      'border border-border-normal': border,
      'base-card': !border
    }"
    @click="clickable && $emit('click')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  props: {
    border: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="css">
.base-card {
  background: #FFFFFF;

  box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13);
  border-radius: 20px;
}
</style>
