<template>
  <div class="bg-background-normal w-full h-max overflow-hidden">
    <div
      class="fixed z-20 bg-white shadow-sm border-b border-neutral-50 border-opacity-80 h-max sm:h-14 flex items-center flex-row justify-between flex-wrap sm:flex-nowrap sm:px-12 sm:py-3 w-full backdrop-blur-md p-0 px-4"
    >
      <div
        class="h-14 w-full sm:w-max flex justify-center sm:justify-start items-center flex-row"
      >
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted truncate"
        >
          Brief made with
        </BaseText>
        <a href="https://foreplay.co">
          <img
            src="../../assets/images/brief-foreplay-icon.svg"
            class="ml-2 sm:mr-2"
            alt="foreplay and then logo"
          >
        </a>
      </div>
      <div
        class="flex items-center w-full sm:w-auto justify-center sm:justify-start pb-3 sm:pb-0"
      >
        <router-link
          class="text-white underline"
          to="/login"
        >
          <BaseButton
            outline
            class=""
          >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted h-2 sm:h-auto flex items-center"
            >
              Login
            </BaseText>
          </BaseButton>
        </router-link>
        <router-link
          class="ml-3"
          to="/sign-up"
        >
          <BaseButton primary>
            <BaseText
              type="label"
              size="sm"
              class="h-1 sm:h-auto flex items-center"
            >
              Sign up
            </BaseText>
          </BaseButton>
        </router-link>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center w-full margin-auto pt-28 pb-20 sm:py-20"
    >
      <BaseCardTransition>
        <PublicHeader
          v-if="!loading"
          :public-header-information.sync="publicHeaderInformation"
        />
      </BaseCardTransition>
      <BaseCardTransition>
        <BrandProfile
          v-if="!loading"
          ref="brandProfile"
          :brand-profile-expanded.sync="brandProfileExpanded"
          :brand-profile-information.sync="brandProfileInformation"
          class="scroll-animation"
        />
      </BaseCardTransition>
      <BaseCardTransition>
        <InspirationBoard
          v-if="!loading"
          ref="inspirationBoard"
          :inspiration-board-expanded.sync="inspirationBoardExpanded"
          :inspiration-board-information="inspirationBoardInformation"
          class="scroll-animation"
        />
      </BaseCardTransition>
      <BaseCardTransition>
        <ModularDetails
          v-if="!loading"
          ref="modularDetails"
          :modular-details-expanded.sync="modularDetailsExpanded"
          :modular-details-information.sync="modularDetailsInformation"
          class="scroll-animation"
        />
      </BaseCardTransition>
      <BaseCardTransition>
        <StoryBoard
          v-if="!loading"
          ref="storyBoard"
          :story-board-expanded.sync="storyBoardExpanded"
          :story-board-information.sync="storyBoardInformation"
          class="scroll-animation"
        />
      </BaseCardTransition>
      <BaseCardTransition>
        <VideoScript
          v-if="!loading"
          ref="videoScript"
          :video-script-expanded.sync="videoScriptExpanded"
          :story-board-information.sync="storyBoardInformation"
          class="scroll-animation"
        />
      </BaseCardTransition>
      <BaseCardTransition>
        <BottomNav
          v-if="!loading"
          :brand-profile-expanded.sync="brandProfileExpanded"
          :modular-details-expanded.sync="modularDetailsExpanded"
          :story-board-expanded.sync="storyBoardExpanded"
          :video-script-expanded.sync="videoScriptExpanded"
          :scroll-object.sync="scrollObject"
          :open-upload-modal="{ openModal: openUploadModal }"
          class="transition-opacity duration-300 ease-in-out"
          :class="loading ? 'opacity-0' : 'opacity-100'"
        />
      </BaseCardTransition>
      <UploadModal
        v-if="modularDetailsInformation && uploadModalOpen"
        :upload-modal-information.sync="uploadModalInformation"
        @close="uploadModalOpen = false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PublicHeader from '../../components/briefsV2/publicView/PublicHeader.vue'
import BrandProfile from '../../components/briefsV2/publicView/BrandProfile.vue'
import ModularDetails from '../../components/briefsV2/publicView/ModularDetails.vue'
import StoryBoard from '../../components/briefsV2/publicView/StoryBoard.vue'
import VideoScript from '../../components/briefsV2/publicView/VideoScript.vue'
import InspirationBoard from '../../components/briefsV2/publicView/InspirationBoard.vue'
import BottomNav from '../../components/briefsV2/publicView/BottomNav.vue'
import UploadModal from '../../components/briefsV2/publicView/UploadModal.vue'
import FirebaseAPI from '@/api/firebase'
export default {
  name: 'BriefPublicViewV2',
  components: {
    PublicHeader,
    BrandProfile,
    InspirationBoard,
    ModularDetails,
    StoryBoard,
    VideoScript,
    BottomNav,
    UploadModal
  },
  data () {
    return {
      brandProfileExpanded: true,
      modularDetailsExpanded: true,
      storyBoardExpanded: true,
      videoScriptExpanded: true,
      inspirationBoardExpanded: true,
      publicHeaderInformation: {},
      brandProfileInformation: {},
      storyBoardInformation: [],
      inspirationBoardInformation: [],
      modularDetailsInformation: [],
      uploadModalInformation: {},
      uploadModalOpen: false,
      scrollObject: {},
      loading: true,
      brief: {},
      selectedStatus: [],
      dueDate: new Date(Date.now() + 604800000)
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme'])
  },
  watch: {
    dueDate: function () {
      // So saved status isnt shown on initial load
      if (!this.loading) {
        this.brief.dueDate = +new Date(this.dueDate)
      }
    }
  },
  async mounted () {
    // Fetch Brief
    this.loading = true
    this.scrollObject = {
      brandProfile: this.scrollBrandProfile,
      modularDetails: this.scrollModularDetails,
      storyBoard: this.scrollStoryBoard,
      videoScript: this.scrollVideoScript,
      inspirationBoard: this.scrollInspirationBoard
    }
    await this.fetchBrief()
    await this.getUserByID(this.brief.createdBy)
    await this.getBrandProfile()
    this.storyBoardInformation = this.brief.story
    this.publicHeaderInformation.title = this.brief.name
    this.modularDetailsInformation = this.brief.components
    this.aspectRatios =
      this.modularDetailsInformation?.find(
        (obj) => obj.title === 'Size & Aspect Ratios'
      )?.value || null
    this.uploadModalInformation = {
      briefName: this.brief.name,
      breifID: this.brief.id,
      briefSubmission: this.brief.submission,
      aspectRatios: this.aspectRatios
    }
    this.loading = false
  },
  methods: {
    scrollBrandProfile () {
      this.brandProfileExpanded = true
      setTimeout(() => {
        this.$refs.brandProfile.$el &&
          this.$refs.brandProfile.$el.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
    scrollModularDetails () {
      this.modularDetailsExpanded = true
      setTimeout(() => {
        this.$refs.modularDetails.$el &&
          this.$refs.modularDetails.$el.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
    scrollStoryBoard () {
      if (this.storyBoardInformation.length === 0) {
        return
      }
      this.storyBoardExpanded = true
      setTimeout(() => {
        this.$refs.storyBoard.$el &&
          this.$refs.storyBoard.$el.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
    scrollVideoScript () {
      if (
        this.storyBoardInformation.length === 0 ||
        this.storyBoardInformation[0].scenes.length === 0
      ) {
        return
      }
      this.videoScriptExpanded = true
      setTimeout(() => {
        this.$refs.videoScript.$el &&
          this.$refs.videoScript.$el.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
    scrollInspirationBoard () {
      if (this.inspirationBoardInformation.length === 0) {
        return
      }
      this.inspirationBoardExpanded = true
      setTimeout(() => {
        this.$refs.inspirationBoard.$el &&
          this.$refs.inspirationBoard.$el.scrollIntoView({
            behavior: 'smooth'
          })
      }, 100)
    },
    openUploadModal () {
      this.uploadModalOpen = true
    },
    async fetchBrief () {
      try {
        this.brief = await FirebaseAPI.Briefs.getByID(this.$route.params.id)
        this.publicHeaderInformation = {
          name: this.brief.name,
          description: this.brief.notes,
          status: this.brief.status,
          dueDate: this.brief.dueDate,
          selectedStatus: this.selectedStatus
        }
        this.inspirationBoardInformation = this.brief.adIds
        // Set Inputs with saved values
        this.dueDate = this.brief.dueDate
        this.selectedStatus = this.brief.status
          ? [
            [
              { name: 'No Status', color: 'gray' },
              { name: 'In Progress', color: 'blue' },
              { name: 'Approved', color: 'green' },
              { name: 'Needs Review', color: '️yellow' }
            ].find((status) => status.name === this.brief.status)
          ]
          : [{ name: 'No Status', color: 'gray' }]

        // Track event if brief is shared
        window.analytics.track('Shared Brief Viewed', {
          briefId: this.brief.id,
          briefTitle: this.brief.name
        })
      } catch (e) {
        console.error(e)
      }
    },
    async getUserByID (userId) {
      try {
        const user = await FirebaseAPI.Users.getById(userId)
        this.publicHeaderInformation.name = user.name
        this.publicHeaderInformation.email = user.email
        this.publicHeaderInformation.avatar = user.avatar
      } catch (e) {
        console.error(e)
      }
    },
    async getBrandProfile () {
      try {
        const brandProfile = await FirebaseAPI.BrandProfiles.getByID(
          this.brief.brandProfileId
        )
        this.brandProfileInformation = brandProfile
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped>
.scroll-animation {
  transition: all 0.5s ease-in-out;
  scroll-margin-block: 68px;
}
</style>
