// Money Filter
export default (v) => {
  let value = v

  if (typeof v === 'number') value = value.toFixed(2).toString()

  if (!value) return '$0'

  return '$' + value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
