<template>
  <div>
    <lottie
      :options="defaultOptions"
      :height="30"
      :width="30"
      @animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import shuffleAnimationData from '@/assets/lotties/shuffle-animation.json'

export default {
  name: 'ShuffleButtonLottie',
  components: {
    lottie: Lottie
  },
  data () {
    return {
      defaultOptions: {
        animationData: shuffleAnimationData,
        loop: false,
        autoplay: true
      }
    }
  },
  mounted () {
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim
      this.anim.setSpeed(1.5)
    }
  }
}
</script>

  <style scoped>
  </style>
