<template>
  <!-- Brands Table -->
  <div class="rounded-md mb-10">
    <!-- Display spinner if loading experts -->
    <BaseLoadingSpinner
      v-if="loadingExperts"
      class="mt-24"
    />
    <!-- Display Experts cards -->
    <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
      <!-- Display the cards here -->
      <div
        v-if="experts.length"
        class="flex flex-col items-center rounded-lg border border-primary-purple-25"
        style="background:#F2F1FE;"
      >
        <img
          src="../../assets/images/video-placeholder.jpg"
          class="h-20 w-20 mt-14 rounded-xl select-none"
        >

        <!-- Name -->
        <BaseText
          type="label"
          size="md"
          class=" text-primary-purple-300 mt-3"
        >
          Become a Foreplay Expert
        </BaseText>
        <a
          href="https://www.foreplay.co/experts-application"
          target="_blank"
          class="mt-3 mb-10"
        >
          <BaseButton
            primary
            class=" bg-gray-100 text-gray-600 rounded px-2 py-1 select-none flex items-center duration-300"
          >
            <div
              class="flex items-center gap-1.5 -mx-1.5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M9.75004 18.2083H6.00004C5.07957 18.2083 4.33337 17.4621 4.33337 16.5416V4.45829C4.33337 3.53782 5.07957 2.79163 6.00004 2.79163H14.75C15.6705 2.79163 16.4167 3.53782 16.4167 4.45829V10.7083M14.75 13.2083V15.7083M14.75 15.7083V18.2083M14.75 15.7083H12.25M14.75 15.7083H17.25M7.66671 6.12496H13.0834M7.66671 9.45829H9.75004"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Apply Now
            </div>
          </BaseButton>
        </a>
      </div>
      <div
        v-for="(expert, index) in experts"
        :key="index"
        class="bg-white flex flex-col items-center rounded-lg border border-border-normal "
      >
        <!-- Photo -->
        <img
          :src="expert.avatar"
          class="h-20 w-20 mt-10 rounded-xl select-none"
        >

        <!-- Name -->
        <BaseText
          type="label"
          size="md"
          class="text-neutral-700 mt-5 font-semibold text-center px-2.5"
        >
          {{ expert.fullName }}
        </BaseText>

        <!-- Twitter Username -->
        <BaseText
          v-if="expert.twitter"
          type="body"
          size="sm"
          class="text-text-subdued mt-1"
        >
          @{{ expert.twitter.split('twitter.com/')[1] }}
        </BaseText>

        <!-- Ad Saved Count -->
        <BaseButton
          outlined
          class="mt-3 mb-10 bg-gray-100 text-gray-600 rounded px-2 py-1 select-none flex items-center group-hover:bg-purple-100 group-hover:text-purple-600 duration-300"
          @click="$router.push({ name: 'DiscoveryExpertView', params: { id: expert.id } })"
        >
          <div class="flex items-center">
            <img
              src="../../assets/icons/foreplay-gray.svg"
              alt=""
            >

            <div class="ml-2 text-text-muted">
              {{ expert.boardCount }} Boards
            </div>
          </div>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'DiscoveryExperts',
  components: {
  },
  data () {
    return {
      experts: [],
      loadingExperts: false
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', ['getUser', 'getUserEmail', 'getUserName', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  mounted () {
    this.fetchExperts()
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async fetchExperts () {
      this.loadingExperts = true

      try {
        const db = firebase.firestore()

        const docInstances = await db
          .collection('auth-users')
          .where('expert', '==', true)
          .where('publicExpert', '==', true)
          .get()

        const allExperts = await Promise.all(docInstances.docs.map(async docInstance => {
          const { avatar, expertProfile } = docInstance.data()

          if (!expertProfile || !expertProfile.selectedFolder) return

          const fullName = `${expertProfile.firstName} ${expertProfile.lastName}`

          // Count the boards in the experts folder
          const expertFolder = await FirebaseAPI.Folders.getByID(expertProfile.selectedFolder)

          return {
            ...expertProfile,
            boardCount: expertFolder?.boardIds?.length,
            fullName,
            avatar,
            id: docInstance.id
          }
        }))

        this.experts = allExperts.filter(e => e)

        this.loadingExperts = false
      } catch (error) {
        // Handle error here
        console.error('Error fetching experts:', error)
        this.loadingExperts = false
      }
    }
  }
}
</script>

<style scoped lang="sass">
</style>
