<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M16.75 8.5V7.75C16.75 6.92157 16.0784 6.25 15.25 6.25H10.8028C10.3013 6.25 9.8329 5.99935 9.55472 5.58205L8.94528 4.66795C8.6671 4.25065 8.19876 4 7.69722 4H4.75C3.92157 4 3.25 4.67157 3.25 5.5V13.75C3.25 14.5784 3.92157 15.25 4.75 15.25H9.25M14.5 11.5V13.75M14.5 13.75V16M14.5 13.75H12.25M14.5 13.75H16.75" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'NewFolderIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>