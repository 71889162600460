<template>
  <div
    class="flex items-start w-full justify-center"
    :class="`my-${margin}`"
  >
    <lottie
      v-if="!dark"
      :options="defaultOptions"
      :height="small ? 20 : 24"
      :width="small ? 20 : 24"
    />
    <div
      v-else
      :class="getBaseButtonClasses"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../assets/lotties/button-loading.json'

export default {
  name: 'BaseLoadingLogo',
  components: {
    lottie: Lottie
  },
  props: {
    small: {
      type: Boolean
    },
    margin: {
      type: Number,
      default: 0
    },
    dark: {
      type: Boolean
    }
  },
  data () {
    return {
      defaultOptions: { animationData: animationData, loop: true, autoplay: true }
    }
  },
  computed: {
    getBaseButtonClasses () {
      // Start with the animation classes
      const classes = []
      if (this.small) {
        classes.push('base-loading-spinner-small')
      } else {
        classes.push('base-loading-spinner')
      }
      return classes
    }
  }
}
</script>
<style scoped lang="sass">
.base-loading-spinner
  animation: spin 0.6s linear infinite
  border: 2px white solid
  border-top: 4px black solid
  height: 24px
  width: 24px
  @apply mx-auto rounded-full
.base-loading-spinner-small
  animation: spin 0.6s linear infinite
  border: 2px white solid
  border-top: 4px rgb(156, 163, 175) solid
  height: 16px
  width: 16px
  @apply mx-auto rounded-full
@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
</style>
