<template>
  <!-- Manage Board Modal -->
  <BaseModal
    no-padding
    @close="$emit('close')"
  >
    <template #toolbar>
      <div class="text-black w-full flex flex-col items-center font-medium justify-center">
        <span class="text-xl">Update your Address</span>
      </div>
    </template>

    <template #default>
      <div class="p-4">
        <!-- Divider -->
        <StripeElements
          #default="{ elements }"
          ref="addressElms"
          :stripe-key="stripeKey"
          :instance-options="instanceOptions"
          :elements-options="elementsOptions"
        >
          <StripeElement
            type="address"
            :elements="elements"
            :elements-options="elementsOptions"
            :options="addressOptions"
          />
        </StripeElements>
        <div class="w-1/2 mt-2">
          <!-- The Tax ID label -->
          <label
            for="taxId"
            class="block text-sm leading-6 text-gray-900 mt-1"
          >Tax ID</label>
          <!-- The Tax ID input field -->
          <input
            ref="taxId"
            type="text"
            placeholder="VAT: 123456789"
            class="my-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-100 sm:text-sm sm:leading-6a"
          >
        </div>

        <div
          class="pt-3 text-right flex bg-white justify-center border-t border-gray-300"
        >
          <BaseButton
            primary
            :loading="loading"
            :disabled="loading"
            @click="updateAddress"
          >
            Update Address
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import { mapGetters } from 'vuex'
import API from '@/api'

export default {
  name: 'UpdateAddressModal',
  components: {
    StripeElements,
    StripeElement
  },
  props: {
  },
  data () {
    return {
      stripeKey: 'pk_live_51JS7DeAQtq0TW7KZxoGv8Spu6IJhR335IoLxdfBQMF8VCbWJFDrUl2DfeNJllyC79mZJv8kX0qiaNGFCgbrTznpA00kPK2gMwN',
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
      },
      addressOptions: {
        mode: 'billing'
      },
      stripeValidationError: null,
      loading: false,
      loadingPaymentUpdate: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getStripeCustomer', 'getUser'])
  },
  methods: {
    async updateAddress () {
      try {
        this.loading = true
        // const groupComponent = this.$refs.addressElms
        const groupComponent = this.$refs.addressElms
        const addressElement = groupComponent.elements.getElement('address')

        const address = await addressElement.getValue()

        if (!address.complete) {
          this.$showAlert({
            message: 'Please fill out all fields.',
            type: 'error'
          })
          return
        }

        const { address: updatedAddress, name } = address.value

        const taxId = this.$refs.taxId.value

        // Update the customer object
        await API.Stripe.updateAddress(this.getStripeCustomer.stripeId, updatedAddress, taxId, name)

        this.$showAlert({
          message: 'Your address has been updated.',
          type: 'success'
        })

        this.$emit('close')
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped></style>
