<template>
  <svg
    :width="width"
    :height="width*0.81"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2895 0.628418H1.35763C0.637417 0.628418 0.0534668 1.21233 0.0534668 1.93255V15.3001C0.0534668 16.0202 0.637417 16.6042 1.35763 16.6042H19.2895C20.0098 16.6042 20.5938 16.0202 20.5938 15.3V1.93255C20.5938 1.21233 20.0098 0.628418 19.2895 0.628418ZM19.2895 1.93255V11.4173L16.7194 9.08055C16.3331 8.72941 15.739 8.74343 15.37 9.11285L12.7688 11.7136L7.64001 5.5874C7.25232 5.12442 6.54221 5.11987 6.14871 5.57729L1.35763 11.1454V1.93255H19.2895ZM13.747 5.35593C13.747 4.36542 14.5496 3.56272 15.5401 3.56272C16.5307 3.56272 17.3333 4.36542 17.3333 5.35593C17.3333 6.34643 16.5307 7.1491 15.5401 7.1491C14.5497 7.14914 13.747 6.34643 13.747 5.35593Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ImageIcon',
  props: {
    selected: {
      type: Boolean
    },
    fill: {
      type: String,
      default: '#778295'
    },
    width: {
      type: Number,
      default: () => 21
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme', 'getLastTheme', 'getThemeChangedAt'])
  }
}
</script>
<style scoped lang="css">
</style>
