<template>
  <lottie 
    :options="defaultOptions"
    :width="width"
    :height="height"
    @animCreated="handleAnimation"
  />
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../assets/lotties/selectableBoard.json'

export default {
  name: 'BoardSelectableIcon',
  components: {
    lottie: Lottie
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    hasBeenClicked: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {
      defaultOptions: {
        animationData: animationData,
        loop: false,
        autoplay: true
      }
    }
  },
  beforeDestroy () {
    this.anim.removeEventListener('complete', this.handleAnimationEnded)
  },
  watch: {
    isSelected (val) {
      if (val) {
        this.anim.setDirection(1);
        this.anim.play();
      } else {
        this.anim.setDirection(-1);
        this.anim.play();
      }
    }
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim;
      this.anim.setSpeed(2.5);
      this.anim.addEventListener('complete', this.handleAnimationEnded);

      // If the icon is selected and has not been clicked, we want to show the last frame of the animation
      // (i.e., the checkmark) when the user navigates to the folder subview
      if (this.isSelected && !this.hasBeenClicked) {
        const lastFrame = anim.getDuration(true)
        this.anim.goToAndStop(lastFrame, true)
      }
    },
    handleAnimationEnded () {
      if (!this.isSelected) this.$emit('deselectAnimEnded')
    }
  }
}
</script>