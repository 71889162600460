// Import
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Create a note for an advertisment
export const create = async (payload) => {
  const user = firebase.auth().currentUser

  const { id } = await db.collection('notes').add({ ...payload, created_by: user.uid })
  const snapshot = await db.collection('notes').doc(id).get()

  return { ...snapshot.data(), id }
}

// Update the note for an advertisment
export const update = (noteId, body) => {
  return db.collection('notes').doc(noteId).update({ body })
}

// Get the note for an advertisment
export const get = async (adId) => {
  const notes = await db.collection('notes').where('adId', '==', adId).get()

  if (notes.empty) {
    return { body: '' }
  }

  return { ...notes.docs[0].data(), id: notes.docs[0].id }
}

export default {
  create,
  update,
  get
}
