<template>
  <svg xmlns="http://www.w3.org/2000/svg" :class="className" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M3.48622 6.17335C3.56686 6.13303 3.63224 6.06765 3.67256 5.98701L4.20999 4.91215C4.36354 4.60505 4.8018 4.60505 4.95535 4.91215L5.49278 5.98701C5.5331 6.06765 5.59848 6.13303 5.67912 6.17335L6.75398 6.71078C7.06108 6.86434 7.06108 7.30259 6.75398 7.45614L5.67912 7.99357C5.59848 8.03389 5.5331 8.09927 5.49278 8.17991L4.95535 9.25477C4.80179 9.56188 4.36354 9.56188 4.20999 9.25477L3.67256 8.17991C3.63224 8.09927 3.56686 8.03389 3.48622 7.99357L2.41136 7.45614C2.10425 7.30259 2.10425 6.86434 2.41136 6.71078L3.48622 6.17335Z" 
    class="fill-current"/>
    <path d="M7.96545 3.10041C8.02351 3.07138 8.07058 3.0243 8.09961 2.96624L8.48101 2.20345C8.59156 1.98234 8.90711 1.98234 9.01766 2.20345L9.39906 2.96624C9.42809 3.0243 9.47516 3.07138 9.53322 3.10041L10.296 3.4818C10.5171 3.59236 10.5171 3.9079 10.296 4.01846L9.53322 4.39985C9.47516 4.42888 9.42809 4.47596 9.39906 4.53402L9.01766 5.2968C8.90711 5.51792 8.59156 5.51792 8.48101 5.29681L8.09961 4.53402C8.07058 4.47596 8.02351 4.42888 7.96545 4.39985L7.20266 4.01846C6.98154 3.9079 6.98154 3.59236 7.20266 3.4818L7.96545 3.10041Z" 
    class="fill-current"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4169 2.60206C17.5882 2.30242 17.4843 1.9206 17.1846 1.74924C16.885 1.57789 16.5032 1.68189 16.3318 1.98153L12.0728 9.42908L11.3816 9.17917C9.68667 8.56624 7.98447 9.50354 7.11253 10.8663C6.33211 12.0861 5.2958 12.9225 4.02922 13.7615C3.83691 13.8888 3.73039 14.1118 3.75213 14.3414C3.77386 14.5711 3.92032 14.7701 4.13312 14.8591L12.3216 18.285C12.5571 18.3835 12.8288 18.329 13.008 18.1472C14.61 16.5217 15.46 15.0853 15.741 13.097C15.941 11.6811 14.9794 10.4802 13.7523 10.0364L13.266 9.86056L17.4169 2.60206ZM8.16546 11.54C8.82409 10.5106 9.9705 9.9981 10.9566 10.3547L13.3272 11.2119C14.126 11.5008 14.6029 12.2169 14.5033 12.9221C14.2861 14.4588 13.6821 15.6079 12.4092 16.9667L9.23225 15.6375L9.79683 13.9412C9.86054 13.7497 9.64214 13.5886 9.47801 13.7059L7.68271 14.9892L5.66262 14.144C6.61079 13.4361 7.47014 12.6268 8.16546 11.54Z" 
    class="fill-current"/>
  </svg>
</template>

<script>
  export default {
    name: 'ClearFiltersIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      className: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped></style>