<template>
  <div class="tag-color-picker flex p-2 rounded-lg bg-white z-50" @click.stop
  :class="{'bottom-pos': location === 'bottom'}">
    <div class="flex cursor-pointer">
      <div v-for="key in Object.keys(colors)" :key="key" @click="selectColor(key)"
      class="scale-hover p-1">
        <div :style="{ backgroundColor: colors[key].picker }"
        class="w-4 h-4 rounded-full">
          <div class="tag-selected-indicator absolute w-2 h-2 left-1/2 top-1/2 bg-white rounded-full" 
          :class="{'anim-color-selected': selectedColor === key, 'anim-color-deselected': doAnimateFlags[key] && selectedColor !== key}"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TAG_COLORS from '../../../utils/TagColors'

  // Return an object with all color options set to false
  const createDefaultAnimFlags = () => {
    return Object.keys(TAG_COLORS).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {})
  }

  export default {
    name: 'TagColorPicker',
    props: {
      tag: {
        type: Object,
        default: null
      },
      location: {
        type: String,
        default: 'top' // || 'bottom'
      }
    },
    data () {
      return {
        selectedColor: 'gray',
        colors: TAG_COLORS,
        doAnimateFlags: createDefaultAnimFlags()
      }
    },
    mounted () {
      if (this.tag) {
        if (!this.tag.color) this.tag.color = 'gray'
        this.selectedColor = this.tag.color
      }
    },
    watch: {
      tag () {
        this.selectedColor = this.tag.color // May be unnecessary
      }
    },
    methods: {
      selectColor (color) {
        this.selectedColor = color
        this.doAnimateFlags[color] = true
        this.$emit('colorChange', color)
      },
    }
  }
</script>

<style scoped lang="css">
  .tag-color-picker {
    position: absolute;
    bottom: calc(100% - 1px);
    left: 0;
    box-shadow: 0px 24px 32px -12px rgba(53, 57, 75, 0.12), 0px 1px 2px 0px rgba(162, 172, 186, 0.08), 0px 0px 0px 1px rgba(0, 56, 108, 0.04), 0px 1px 2px 0px rgba(162, 172, 186, 0.16), 0px 0px 0px 1px #EBEFF3;
    box-shadow: 0px 24px 32px -12px color(display-p3 0.21 0.2233 0.29 / 0.12), 0px 1px 2px 0px color(display-p3 0.6433 0.6728 0.7254 / 0.08), 0px 0px 0px 1px color(display-p3 0.0696 0.2157 0.4104 / 0.04), 0px 1px 2px 0px color(display-p3 0.6433 0.6728 0.7254 / 0.16), 0px 0px 0px 1px color(display-p3 0.9255 0.9373 0.9529);
  }
  .tag-color-picker.bottom-pos {
    bottom: auto;
    top: calc(100% - 1px);
  }
  .tag-selected-indicator {
    transform: translate(-50%, -50%) scale(0);
  }
  .scale-hover {
    transition: transform 150ms ease-in-out;
    transform: scale(1);
  }
  .scale-hover:hover {
    transform: scale(0.85);
  }

  /* ANIMATIONS */
  .anim-color-selected { animation: colorSelected 150ms ease-in-out 0s 1 forwards; }
  .anim-color-deselected { animation: colorDeselected 150ms ease-in-out 0s 1 forwards; }
  @keyframes colorSelected {
    from { transform: translate(-50%, -50%) scale(0); }
    to { transform: translate(-50%, -50%) scale(1); }
  }
  @keyframes colorDeselected {
    from { transform: translate(-50%, -50%) scale(1); }
    to { transform: translate(-50%, -50%) scale(0); }
  }
</style>