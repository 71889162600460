<template>
  <div class="px-4 w-full">
    <BaseCard
      border
      class="rounded-xl w-full bg-white"
    >
      <ModularDetailsSectionHeader
        :expanded="expanded"
        :section="{ type: 'audio', title: 'Music and Sound' }"
        badge-text="Filled"
        :filled="!!audioFields.length"
        @toggleExpanded="$emit('toggleExpanded')"
        @deleteSection="$emit('deleteSection')"
      />

      <div v-if="expanded">
        <div
          class="flex flex-wrap gap-2 px-4"
        >
          <div class="w-full">
            <div
              v-for="(audio, index) in audioFields"
              :key="index"
              class="py-3 w-full flex items-end gap-3 pr-6 text-sm border-b border-border-normal"
            >
              <BaseInputV2
                label="Link"
                class="w-2/5"
                icon
                :value="audio.link"
                placeholder="www.example.com"
                @input="updateField(index, 'link', $event)"
              >
                <!-- Icon -->
                <img
                  slot="icon"
                  src="../../../assets/icons/chain.svg"
                >
              </BaseInputV2>

              <BaseInputV2
                label="Notes"
                class="w-2/5"
                :value="audio.notes"
                placeholder="Description..."
                @input="updateField(index, 'notes', $event)"
              >
              <!-- Icon -->
              </BaseInputV2>

              <BaseButton
                outlined
                icon-only
                @click="removeAudio(index)"
              >
                <img src="../../../assets/icons/trash-gray.svg">
              </BaseButton>
            </div>
          </div>
        </div>

        <!-- Add Input Button -->
        <div class="flex flex-wrap gap-2 py-3 px-4">
          <BaseButton
            outlined
            @click="addField"
          >
            <div class="flex text-text-muted items-center">
              <img src="../../../assets/icons/plus-icon.svg">
              Add New
            </div>
          </BaseButton>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModularDetailsSectionHeader from './ModularDetailsSectionHeader.vue'

export default {
  name: 'Audio',
  components: {
    ModularDetailsSectionHeader
  },
  props: {
    value: {
      type: Array,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      audioFields: [{ link: '', notes: '' }],
      timeout: null
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getInspoAds']),
    ...mapGetters('AuthModule', ['getTeam'])
  },
  mounted () {
    this.audioFields = this.value
  },
  methods: {
    removeAudio (index) {
      this.audioFields = this.audioFields.filter((field, i) => i !== index)
    },
    updateField (index, field, newValue) {
      if (this.timeout) { clearTimeout(this.timeout) }

      const cloneArr = this.audioFields
      this.audioFields[index][field] = newValue

      this.audioFields = cloneArr

      this.timeout = setTimeout(() => {
        this.$emit('update', this.index, this.audioFields)
      }, 500)
    },
    addField () {
      this.audioFields.push({ link: '', notes: '' })
    }
  }
}
</script>

<style scoped lang="css">
</style>
