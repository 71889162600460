<template>
  <svg
    :width="width"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="{ transform: `scale(${scale})` }"
  >
    <rect
      x="0.78418"
      y="0.338379"
      width="18.6498"
      height="18.6498"
      :rx="rounded ? '50%' : 3"
      fill="#0274B3"
    />
    <path
      d="M7.76553 5.85579C7.76553 6.13818 7.68179 6.41423 7.5249 6.64903C7.36801 6.88384 7.14502 7.06684 6.88412 7.17491C6.62322 7.28298 6.33614 7.31125 6.05917 7.25616C5.7822 7.20107 5.52779 7.06508 5.32811 6.8654C5.12843 6.66572 4.99244 6.41131 4.93735 6.13434C4.88226 5.85737 4.91053 5.57029 5.0186 5.30939C5.12667 5.04849 5.30967 4.8255 5.54447 4.66861C5.77928 4.51172 6.05533 4.42798 6.33772 4.42798C6.7164 4.42798 7.07957 4.57841 7.34733 4.84617C7.6151 5.11394 7.76553 5.47711 7.76553 5.85579Z"
      fill="white"
    />
    <path
      d="M7.44189 8.23557V14.5436C7.44214 14.5902 7.43319 14.6363 7.41556 14.6793C7.39792 14.7224 7.37195 14.7616 7.33913 14.7946C7.30631 14.8276 7.2673 14.8537 7.22433 14.8716C7.18136 14.8895 7.13528 14.8987 7.08874 14.8987H5.58383C5.5373 14.8988 5.4912 14.8897 5.44818 14.872C5.40516 14.8542 5.36608 14.8281 5.33317 14.7952C5.30026 14.7623 5.27419 14.7232 5.25644 14.6802C5.23869 14.6372 5.22961 14.5911 5.22974 14.5446V8.23557C5.22974 8.14165 5.26704 8.05159 5.33345 7.98518C5.39986 7.91878 5.48992 7.88147 5.58383 7.88147H7.08874C7.18249 7.88172 7.27231 7.91914 7.33851 7.98552C7.40471 8.0519 7.44189 8.14182 7.44189 8.23557Z"
      fill="white"
    />
    <path
      d="M15.3083 11.5194V14.573C15.3084 14.6158 15.3001 14.6582 15.2837 14.6977C15.2674 14.7373 15.2434 14.7732 15.2132 14.8035C15.1829 14.8337 15.147 14.8577 15.1074 14.874C15.0679 14.8903 15.0255 14.8986 14.9827 14.8985H13.3645C13.3218 14.8986 13.2794 14.8903 13.2398 14.874C13.2003 14.8577 13.1643 14.8337 13.1341 14.8035C13.1038 14.7732 13.0798 14.7373 13.0635 14.6977C13.0472 14.6582 13.0389 14.6158 13.039 14.573V11.6136C13.039 11.1719 13.1685 9.6794 11.8844 9.6794C10.8897 9.6794 10.6869 10.7008 10.6469 11.1596V14.573C10.647 14.6585 10.6133 14.7406 10.5533 14.8015C10.4933 14.8624 10.4117 14.8973 10.3262 14.8985H8.76319C8.72048 14.8985 8.6782 14.8901 8.63875 14.8737C8.59931 14.8573 8.56349 14.8334 8.53333 14.8031C8.50318 14.7729 8.47929 14.737 8.46303 14.6975C8.44678 14.658 8.43847 14.6157 8.4386 14.573V8.20781C8.43847 8.1651 8.44678 8.12279 8.46303 8.0833C8.47929 8.04381 8.50318 8.00792 8.53333 7.97767C8.56349 7.94743 8.59931 7.92344 8.63875 7.90707C8.6782 7.8907 8.72048 7.88227 8.76319 7.88227H10.3262C10.4125 7.88227 10.4953 7.91657 10.5564 7.97762C10.6174 8.03867 10.6517 8.12147 10.6517 8.20781V8.75799C11.021 8.204 11.5684 7.77661 12.7363 7.77661C15.3235 7.77661 15.3083 10.1925 15.3083 11.5194Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: 'LinkedInIcon',
  props: {
    selected: {
      type: Boolean
    },
    width: {
      type: Number,
      default: () => 15
    },
    rounded: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    scale () {
      return this.width / 20
    }
  }
}
</script>
<style scoped lang="css"></style>
