<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.45833 6.45833V4.79167C6.45833 3.87119 7.20453 3.125 8.125 3.125H15.2083C16.1288 3.125 16.875 3.87119 16.875 4.79167V11.8833C16.875 12.8038 16.1288 13.55 15.2083 13.55H13.5417M3.125 8.125V15.2083C3.125 16.1288 3.87119 16.875 4.79167 16.875H11.875C12.7955 16.875 13.5417 16.1288 13.5417 15.2083V8.125C13.5417 7.20453 12.7955 6.45833 11.875 6.45833H4.79167C3.87119 6.45833 3.125 7.20453 3.125 8.125Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'LayersCopyIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>