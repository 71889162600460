<template>
  <BaseCard
    class="mt-5 w-1/2 py-5 flex items-center flex-col relative"
    :no-padding="true"
  >
    <div
      class="flex items-center gap-2.5 w-full border-b border-border-normal pb-5"
    >
      <div
        v-if="!getTeam"
        class="flex items-center justify-center p-2 border border-border-hover rounded-full ml-5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17.7501 19.25H20.3461C21.509 19.25 22.4524 18.2489 22.1338 17.1305C21.4008 14.557 19.6689 12.75 17.0001 12.75C16.554 12.75 16.1341 12.8005 15.7407 12.8966M11.25 7C11.25 8.79493 9.79491 10.25 7.99998 10.25C6.20506 10.25 4.74998 8.79493 4.74998 7C4.74998 5.20507 6.20506 3.75 7.99998 3.75C9.79491 3.75 11.25 5.20507 11.25 7ZM19.75 7.5C19.75 9.01878 18.5188 10.25 17 10.25C15.4812 10.25 14.25 9.01878 14.25 7.5C14.25 5.98122 15.4812 4.75 17 4.75C18.5188 4.75 19.75 5.98122 19.75 7.5ZM2.08125 18.126C2.85986 14.9867 4.85884 12.75 7.99998 12.75C11.1411 12.75 13.1401 14.9867 13.9187 18.126C14.1984 19.2539 13.2604 20.25 12.0984 20.25H3.90156C2.73954 20.25 1.80152 19.2539 2.08125 18.126Z"
            stroke="#5E6678"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <input
        v-if="getTeam"
        :id="'newAvatar'"
        type="file"
        hidden
        @change="uploadNewAvatar($event)"
      >
      <BaseTooltip
        v-if="getTeam"
        dark
        class="ml-5"
      >
        <div
          slot="content"
          class="w-max px-2"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-white"
          >
            Add team logo
          </BaseText>
        </div>

        <label
          slot="reference"
          for="newAvatar"
          :class="
            !getTeam?.avatar
              ? 'flex items-center justify-center p-2 border border-border-hover rounded-full cursor-pointer ml-2.5'
              : ''
          "
        >
          <svg
            v-if="!getTeam?.avatar"
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              d="M14.0169 10.3144H6.94581M10.4813 13.8499V6.77882M18.1897 10.314C18.1897 14.5712 14.7385 18.0223 10.4813 18.0223C6.22415 18.0223 2.77301 14.5712 2.77301 10.314C2.77301 6.05678 6.22415 2.60565 10.4813 2.60565C14.7385 2.60565 18.1897 6.05678 18.1897 10.314Z"
              stroke="#5E6678"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>

          <img
            v-if="getTeam?.avatar"
            :src="getTeam?.avatar"
            alt=""
            class="flex items-center justify-center h-11 w-11 rounded-full cursor-pointer hover:opacity-90 bg-background-normal transition-all duration-200 ease-in-out"
          >
        </label>
      </BaseTooltip>
      <div class="flex flex-col justify-between">
        <BaseText
          type="label"
          size="md"
          class="text-text-muted"
        >
          Manage Your Team
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal"
        >
          {{ getTeam?.teamName }}
        </BaseText>
      </div>
    </div>
    <div
      v-if="getTeam && !pageLoading"
      class="flex flex-col items-center gap-1 w-full px-5 h-60 overflow-y-auto overflow-x-hidden scrollbar-hide"
    >
      <div
        v-for="member in teamMembers"
        :key="member?.id"
        class="flex items-center gap-2.5 ml-2.5 w-full pt-5"
      >
        <img
          v-if="!member?.avatar"
          src="../../assets/icons/default-avatar.png"
          class="w-8 h-8 rounded-full"
        >
        <img
          v-if="member?.avatar"
          :src="member?.avatar"
          class="w-8 h-8 rounded-full"
        >
        <div class="flex flex-col justify-between">
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted"
          >
            {{ member?.name }}
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-subdued"
          >
            {{ member?.email }}
          </BaseText>
        </div>
      </div>
      <div
        v-for="invite in createdInvitations"
        :key="invite?.id"
        class="flex items-center gap-2.5 ml-2.5 w-full pt-2.5"
      >
        <div class="w-8 h-8 rounded-full bg-background-hover" />
        <div class="flex flex-col justify-between">
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted"
          >
            {{ invite?.toEmail }}
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-subdued capitalize"
          >
            {{ invite?.status }}
          </BaseText>
        </div>
      </div>
    </div>
    <div
      v-else-if="pageLoading"
      class="w-full h-full mt-7 flex-col items-center justify-between overflow-y-scroll scrollbar-hide"
    >
      <BaseLoadingLogo />
    </div>
    <div
      v-else
      class="flex flex-col h-full w-full items-center justify-center"
    >
      <BaseInputV2
        v-model="teamName"
        placeholder="Agency X"
        label="Team Name"
        class="w-2/3"
      />
      <div
        class="w-2/3 py-4 px-2.5 flex border-style border-border-normal rounded-lg mt-3.5 items-center gap-2.5"
      >
        <input
          :id="'uploadInput'"
          type="file"
          hidden
          @change="handleUploadFile($event)"
        >
        <label
          for="uploadInput"
          :class="
            !avatarUpload
              ? 'flex items-center justify-center border border-border-hover rounded-full cursor-pointer'
              : 'h-10 w-10 flex items-center justify-center rounded-full'
          "
        >
          <svg
            v-if="!avatarUpload"
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            class="m-2"
          >
            <path
              d="M14.0169 10.3144H6.94581M10.4813 13.8499V6.77882M18.1897 10.314C18.1897 14.5712 14.7385 18.0223 10.4813 18.0223C6.22415 18.0223 2.77301 14.5712 2.77301 10.314C2.77301 6.05678 6.22415 2.60565 10.4813 2.60565C14.7385 2.60565 18.1897 6.05678 18.1897 10.314Z"
              stroke="#5E6678"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          <img
            v-if="avatarPreview"
            :src="avatarPreview"
            alt="File Preview"
            class="contain h-10 w-10 flex items-center justify-center rounded-full border border-border-hover cursor-pointer"
          >
        </label>
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Team Logo
        </BaseText>
      </div>
      <BaseButton
        class="mt-3.5 w-2/3"
        cta
        :loading="loadingCreateTeam"
        :disabled="!teamName?.length || loadingCreateTeam"
        @click="createTeam"
      >
        Create Team
      </BaseButton>
    </div>
    <div
      v-if="teamMembers !== null"
      class="absolute bottom-0 w-full flex items-center"
    >
      <div
        v-if="getTeam"
        class="flex items-start gap-3 w-full px-5 py-5 border-t border-border-normal flex-wrap"
      >
        <div class="flex flex-col gap-y-2 mx-2 flex-1">
          <BaseInputV2
            v-model="invitationEmail"
            placeholder="zuck@facebook.com"
            class="w-full transition duration-75"
          />
        </div>
        <BaseButton
          outlined
          :loading="loading"
          :disabled="!invitationEmail?.length"
          class="w-max"
          @click="sendInvitation"
        >
          Invite
        </BaseButton>
        <BaseText
          type="body"
          size="xs"
          class="text-text-disabled ml-3 w-full"
        >
          Additional team members pro-rated at $20/mo
        </BaseText>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import { nanoid } from 'nanoid'
import validator from 'email-validator'
import API from '@/api'

export default {
  name: 'TeamManagmentCard',
  data () {
    return {
      teamMembers: null,
      invitationEmail: '',
      pageLoading: true,
      loading: false,
      loadingCreateTeam: false,
      createdInvitations: [],
      invalidEmail: false,
      teamName: '',
      avatarUpload: '',
      avatarPreview: '',
      errored: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam']),
    ...mapGetters('AuthModule', ['getUser'])
  },
  async mounted () {
    this.createdInvitations = await this.getCreatedInvitations()
    if (this.getTeam) {
      this.teamMembers = await this.getTeamMembers()
    }
    this.pageLoading = false
  },
  methods: {
    ...mapActions('AuthModule', ['fetchUserTeam']),
    ...mapMutations('AuthModule', ['SET_USER_TEAM']),
    async handleUploadFile (event) {
      if (event.target?.files?.length === 0) return
      this.avatarUpload = event.target.files[0]
      this.avatarPreview = event.target.files[0]
      const validFile =
        this.avatarUpload.type === 'image/png' ||
        this.avatarUpload.type === 'image/jpeg'

      if (!validFile) {
        this.avatarUpload = ''
        this.avatarPreview = ''
        // Show error

        this.$showAlert({
          message: 'Invalid File Type',
          type: 'error'
        })

        return
      }
      if (this.avatarUpload) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.avatarPreview = e.target.result
        }
        reader.readAsDataURL(this.avatarPreview)
      }
    },
    async uploadNewAvatar (event) {
      const validFile =
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpeg'

      if (!validFile) {
        // Show error

        this.$showAlert({
          message: 'Invalid File Type',
          type: 'error'
        })

        return
      }
      this.avatarUpload = event.target.files[0]

      if (this.getTeam.avatar) {
        this.deleteOldAvatar(this.getTeam.avatar)
      }

      // Create File Name (Random Folder Path)
      const folderName = nanoid()
      const fileName = nanoid()
      const filePath = `${folderName}/${fileName}`

      const storageRef = firebase
        .app()
        .storage('gs://foreplay-team-avatars')
        .ref(filePath)

      const firstFile = this.avatarUpload
      await storageRef.put(firstFile)

      const fileLocation = `https://storage.googleapis.com/foreplay-team-avatars/${filePath}`

      const toUpdate = {
        avatar: fileLocation
      }
      // Update the experts avatar in firebase and the user avatar
      await FirebaseAPI.Teams.update(this.getTeam.id, toUpdate)

      // // Set the local user
      this.SET_USER_TEAM({ ...this.getTeam, ...toUpdate })

      // Show that all files are uploaded
      this.$showAlert({
        message: 'Profile Image Updated',
        type: 'success'
      })

      this.loading = false
    },
    async createTeam () {
      if (!this.teamName) {
        this.$showAlert({
          message: 'Please enter a team name',
          type: 'error'
        })
        return
      }
      this.loading = true
      this.loadingCreateTeam = true
      await FirebaseAPI.Teams.create(this.teamName)
      this.$emit('createdTeam')

      // set the team in global state
      await this.fetchUserTeam()

      // migrate users assets to their team
      await API.Teams.migrateUserToTeam(this.getTeam.id)
      this.teamMembers = await this.getTeamMembers()

      // checking weather there is files to upload
      if (this.avatarUpload) {
        // Create File Name (Random Folder Path)
        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}`

        const storageRef = firebase
          .app()
          .storage('gs://foreplay-team-avatars')
          .ref(filePath)

        const firstFile = this.avatarUpload
        await storageRef.put(firstFile)

        const fileLocation = `https://storage.googleapis.com/foreplay-team-avatars/${filePath}`

        const toUpdate = {
          avatar: fileLocation
        }
        // Update the experts avatar in firebase and the user avatar
        await FirebaseAPI.Teams.update(this.getTeam.id, toUpdate)

        // // Set the local user
        this.SET_USER_TEAM({ ...this.getTeam, ...toUpdate })

        // Show that all files are uploaded
        this.$showAlert({
          message: 'Profile Image Updated',
          type: 'success'
        })
      }

      this.loading = false
      this.loadingCreateTeam = false
    },

    async getTeamMembers () {
      return FirebaseAPI.Teams.getTeamMembers()
    },
    async getCreatedInvitations () {
      return FirebaseAPI.Invitations.getAllCreatedByUser()
    },
    async sendInvitation () {
      this.loading = true
      const toEmail = this.invitationEmail
      if (!validator.validate(toEmail)) {
        this.$showAlert({
          message: 'Invalid Email',
          type: 'error'
        })
        this.invalidEmail = true
        this.loading = false
        return
      }
      try {
        await API.Invitations.createInvitation(toEmail, this.getTeam?.id)
        this.createdInvitations = await this.getCreatedInvitations()
        window.analytics.track('Teammates Invited', {
          email: this.invitationEmail
        })
        this.invitationEmail = ''
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: 'Something went wrong sending your invitation.',
          type: 'error'
        })
      }
      this.invalidEmail = false
      this.loading = false
    },
    async deleteOldAvatar (filePath) {
      const storageRef = firebase
        .app()
        .storage('gs://foreplay-team-avatars')
        .ref()
      const oldAvatarRef = storageRef.child(
        filePath.split(
          'https://storage.googleapis.com/foreplay-team-avatars/'
        )[1]
      )
      await oldAvatarRef.delete()
    }
  }
}
</script>

<style scoped>
.border-style {
  box-shadow: 0px 1px 2px rgba(18, 55, 105, 0.08),
    0px 0px 0px 1px rgba(18, 55, 105, 0.08);
}
</style>
