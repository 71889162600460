<!--
  USAGE: Note that there is no positioning applied. Positioning must be handled where the component is used
  by passing a class or style prop to the component. 

  IF NOT USING FIXED OR ABSOLUTE, SET THE COMPONENT TO RELATIVE POSITIONING.
-->

<template>
  <div class="flex flex-col gap-1.5 bg-neutral-700 rounded-lg p-1.5 shadow-lg" :style="{width: `${width}px`}">
    <video 
      ref="hook-preview-video-player"
      class="w-full object-contain rounded-md"
      :src="videoSrc"
      @canplay="videoLoading = false"
      @timeupdate="updateProgress"
    />
    <div v-if="videoLoading" class="absolute top-1 bottom-1 left-1 right-1 rounded-md bg-neutral-600 opacity-75">
      <div class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 scale-125 opacity-50">
        <BaseLoadingSpinner />
      </div>
    </div>
    <!-- When autoplay is blocked by the browser, we need to prompt the user to make an input -->
    <div v-else-if="playbackBlocked" class="absolute top-1 bottom-1 left-1 right-1 rounded-md bg-neutral-500 bg-opacity-30"
    style="backdrop-filter: blur(4px);">
      <BaseText type="body" size="xs" class="absolute top-1/2 transform -translate-y-1/2 text-white text-center w-full opacity-90"
      style="text-shadow: 1px 1px 2px black;">
        Click to play<br />
        video preview
      </BaseText>
    </div>
    <!-- Progress bar -->
    <div v-else class="flex w-full bg-white bg-opacity-20 rounded-full h-0.5">
      <div class="bg-white rounded-full h-0.5" 
      style="transition: width 0.3s linear"
      :style="{width: `${progress}%`}" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePopupVideoPreview',
  props: {
    videoSrc: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 120
    },
    attemptManualPlay: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      videoLoading: true,
      playbackBlocked: true,
      progress: 0 // 0-100
    }
  },
  watch: {
    attemptManualPlay () {
      // We're watching an optional prop that can be triggered to re-attempt video playback when it's blocked
      this.attemptVideoAutoplay(this.$refs['hook-preview-video-player'])
    }
  },
  async mounted () {
    const videoPlayer = this.$refs['hook-preview-video-player']
    videoPlayer.volume = 0.5
    this.attemptVideoAutoplay(videoPlayer)
  },
  methods: {
    updateProgress () {
      const videoPlayer = this.$refs['hook-preview-video-player']
      if (videoPlayer.duration > 0) {
        this.progress = (videoPlayer.currentTime / videoPlayer.duration) * 100
      }
    },
    async attemptVideoAutoplay (videoPlayer) {
      try {
        await videoPlayer.play();
        videoPlayer.setAttribute('autoplay', true)
        this.playbackBlocked = false
      } catch (error) {
        console.info('Autoplay was blocked by the browser')
        this.playbackBlocked = true
      }
    }
  }
}
</script>

<style scoped></style>