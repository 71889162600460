// Imports
import BaseNotifyAlert from './BaseNotifyAlert.vue'
import EventBus from './EventBus'

export default {
  install (Vue) {
    // Base Notify Alert Component
    Vue.component('BaseNotifyAlert', BaseNotifyAlert)

    // Show Alert Handle
    Vue.prototype.$showAlert = (alert) => {
      EventBus.$emit('showAlert', alert)
    }
  }
}
