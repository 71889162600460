<template>
  <div
    class="h-max mt-5 px-5 py-5 bg-white rounded-md shadow-sm"
  >
    <div class="flex flex-col gap-2">
      <BaseText
        type="label"
        size="md"
      >
        Link your PayPal account to receive payments for your referrals
      </BaseText>
      <div>
        <BaseText
          type="body"
          size="sm"
          class="text-text-muted overflow-visible text-wrap"
        >
          <p v-if="paypalLinked">
            Your currently linked account is {{ displayedPaypalEmail }}
          </p>
          <p v-else>
            You don't currently have a linked PayPal account.
          </p>
        </BaseText>
      </div>
      <div class="flex flex-row items-center gap-3">
        <BaseInputV2
          v-model="paypalEmail"
          placeholder="Enter PayPal Email"
          class="w-1/2"
          :errored="errored"
          :errorMessage="errorMessage"
        />
        <baseButton
          type="primary"
          outlined
          class="ml-2"
          @click="updatePaypalEmail"
        >
          <template v-slot:default>
            <div class="flex items-center gap-1">
              <span> Update Paypal Email</span>
            </div>
          </template>
        </baseButton>
        <img
          :src="require('@/assets/icons/paypal-icon.svg')"
          alt="paypal icon"
        >
      </div>
    </div>
  </div>
</template>
<script>

// import { mapGetters } from 'vuex'
import AxiosConfig from '../../../api/foreplayServer/serverAxiosConfig'
import firebase from '../../../api/config/FirebaseConfig'

export default {
  name: 'ProvidePaypal',
  data () {
    return {
      paypalEmail: '',
      displayedPaypalEmail: '',
      paypalLinked: false,
      errored: false,
      errorMessage: ''
    }
  },
  computed: {
  },
  async mounted () {
    await this.fetchPaypalEmail()
  },
  methods: {
    async fetchPaypalEmail () {
      try {
        const { Aa: token } = firebase.auth().currentUser
        const response = await AxiosConfig.post('/campaigns/getPaypal', null, {
          headers: {
            Authorization: `Bearer ${token}`

          }
        })
        console.log('user paypal info', response.data)
        if (response.data.error) {
          console.error('Error fetching PayPal email:', response.data.error)
        } else {
          this.paypalEmail = response.data.paypal_email
          this.displayedPaypalEmail = this.paypalEmail
          this.paypalLinked = true
        }
      } catch (error) {
        console.log('referral error', error)
      }
    },
    async updatePaypalEmail () {
      try {
        const { Aa: token } = firebase.auth().currentUser
        const response = await AxiosConfig.post('/campaigns/setPaypal', { paypal_email: this.paypalEmail }, {
          headers: {
            Authorization: `Bearer ${token}`

          }
        })
        console.log('user paypal info', response.data)
        if (response.data.error) {
          console.error('Error updating PayPal email:', response.data.error)
          this.errored = true
          this.errorMessage = 'Error - Please make sure you enter a valid email address!'
        } else {
          this.paypalEmail = response.data.paypal_email
          this.displayedPaypalEmail = this.paypalEmail
          this.paypalLinked = true
          this.errored = false
        }
      } catch (error) {
        console.log('updating referral error', error)
      }
    }
  }
}

</script>
