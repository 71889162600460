// Import
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get By ID
const getByID = async (briefId) => {
  const brief = await db.collection('brand-profiles').doc(briefId).get()

  return {
    id: brief.id,
    ...brief.data()
  }
}

// Create a Brief
const create = (brandProfile, teamId) => {
  const user = firebase.auth().currentUser

  const payload = { ...brandProfile, createdBy: user.uid, createdAt: +new Date() }

  if (teamId) {
    payload.teamId = teamId
  }

  return db
    .collection('brand-profiles')
    .add(payload)
}

// Get All By User
const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('brand-profiles').where('createdBy', '==', user.uid).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Get All By Team
const getAllByTeam = async (teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('brand-profiles').where('teamId', '==', teamId).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

// Update Profile
const update = async (brandProfileId, payload) => {
  return await db
    .collection('brand-profiles')
    .doc(brandProfileId)
    .update(payload)
}

const remove = async (brandProfileId) => {
  return db.collection('brand-profiles').doc(brandProfileId).delete()
}

export default {
  getAllByTeam,
  getAllByUser,
  create,
  getByID,
  remove,
  update
}
