<template>
  <!-- Manage Board Modal -->
  <BaseModal @close="$emit('close')">
    <template #toolbar>
      <div class="bg-white pt-4 flex items-center rounded-md">
        <div>
          <div
            v-if="!board && !template"
            class="text-lg font-semibold"
          >
            {{ `Are you sure you want to delete the selected ${folder ? 'folder' : 'brief'}?` }}
          </div>
          <div
            v-if="template"
            class="text-lg font-semibold"
          >
            {{ `Are you sure you want to change the selected template?` }}
          </div>
          <div
            v-if="!board && !template"
            class="text-sm text-gray-500"
          >
            {{ `Once deleted, ${ folder ? 'folders' : 'briefs' } can not be recovered. ${ folder ? 'Anything contained in this folder will be moved to the default folder.' : ''}` }}
          </div>
          <div
            v-if="board && !template"
            class="text-lg font-semibold"
          >
            {{ `Are you sure you want to delete the selected board?` }}
          </div>
          <div
            v-if="board && !template"
            class="text-sm text-gray-500"
          >
            {{ `Once deleted, boards can not be recovered.` }}
          </div>
          <div
            v-if="template"
            class="text-sm text-gray-500"
          >
            {{ `Once changed, script and reference images can not be recovered.` }}
          </div>
        </div>
      </div>
    </template>

    <template #default>
      <div class="px-4">
        <div class="flex items-center justify-end">
          <BaseButton
            outlined
            class="mr-2"
            @click="$emit('close')"
          >
            Cancel
          </BaseButton>
          <BaseButton
            class="bg-red-100 text-red-600"
            :destroy="template"
            @click="$emit('confirm')"
          >
            {{ template ? 'Change' : 'Delete' }}
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>

export default {
  name: 'ConfirmDeleteModal',
  props: {
    folder: Boolean,
    board: Boolean,
    template: Boolean
  }
  // data () {
  // }
}
</script>
