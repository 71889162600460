<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.0497 8.31764L8.08152 4.48097C6.75049 3.62532 5 4.581 5 6.16333V13.8367C5 15.419 6.7505 16.3747 8.08152 15.519L14.0497 11.6824C15.2743 10.8951 15.2743 9.10491 14.0497 8.31764Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'PlayIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>