<template>
  <svg xmlns="http://www.w3.org/2000/svg" :class="className" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M8.21445 13.5715L7.68412 14.1019C7.83395 14.2517 8.04017 14.3312 8.25181 14.3206C8.46344 14.3101 8.66074 14.2105 8.79492 14.0465L8.21445 13.5715ZM14.1521 7.49885C14.4144 7.17827 14.3671 6.70575 14.0465 6.44346C13.7259 6.18116 13.2534 6.22841 12.9911 6.549L14.1521 7.49885ZM6.36383 10.6603C6.07093 10.3674 5.59606 10.3674 5.30317 10.6603C5.01027 10.9532 5.01027 11.428 5.30317 11.7209L6.36383 10.6603ZM8.79492 14.0465L14.1521 7.49885L12.9911 6.549L7.63398 13.0966L8.79492 14.0465ZM5.30317 11.7209L7.68412 14.1019L8.74478 13.0412L6.36383 10.6603L5.30317 11.7209Z" 
    class="fill-current"/>
  </svg>
</template>

<script>
  export default {
    name: 'CheckmarkIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      className: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped></style>