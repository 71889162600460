<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M11.8701 2.52027L11.0769 5.69762C10.928 6.29414 10.4628 6.76023 9.86649 6.91023L8.00565 7.37835C7.68826 7.4582 7.42124 7.67206 7.27399 7.96435C6.95827 8.59108 6.15275 8.78005 5.59125 8.35911L3.12435 6.50975M11.8701 2.52027C11.2711 2.37091 10.6444 2.29163 9.99911 2.29163C6.99816 2.29163 4.39774 4.0065 3.12435 6.50975M11.8701 2.52027C15.2232 3.35633 17.7074 6.38803 17.7074 9.99996C17.7074 14.2572 14.2563 17.7083 9.99911 17.7083C5.74191 17.7083 2.29077 14.2572 2.29077 9.99996C2.29077 8.74371 2.59129 7.55765 3.12435 6.50975M9.50677 13.4245L8.80204 12.3674C8.53052 11.9601 8.58472 11.4177 8.93147 11.0722C9.13975 10.8647 9.42711 10.7565 9.72054 10.7753L10.4287 10.8205C10.7231 10.8392 11.0072 10.9358 11.2521 11.1004L12.4265 11.8896C12.9459 12.2386 13.1253 12.9191 12.8455 13.4788C12.6347 13.9004 12.2038 14.1666 11.7325 14.1666H10.8935C10.3363 14.1666 9.81588 13.8881 9.50677 13.4245Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'LanguageV2Icon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>