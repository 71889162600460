<template>
  <div class="base-modal fixed flex items-center justify-center left-0 right-0 bottom-0 top-0">
    <div v-on-clickaway="() => { $emit('close') }" class="video-container shadow-lg">
      <div class="w-full h-full rounded-lg overflow-hidden">
        <!-- Youtube embed -->
        <iframe 
          width="800" 
          height="450" 
          :src="embedUrl" 
          title="Spyder Tutorial" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerpolicy="strict-origin-when-cross-origin" 
          allowfullscreen 
        />
      </div>
      <!-- close button -->
      <button class="close-button rounded-full w-10 h-10 border-2 border-white flex items-center justify-center" 
      style="left: calc(100% + 16px);" @click="$emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M7.75 7.75L16.25 16.25M16.25 7.75L7.75 16.25" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </button>
      <!-- Extra help options -->
      <div class="extra-help-container bg-white shadow-lg overflow-hidden" :class="{'expanded': extraHelpHovered}"
      @click.stop @mouseenter="extraHelpHovered = true" @mouseleave="extraHelpHovered = false">
        <BaseText type="label" size="sm" 
        class="text text-black w-max cursor-default">
          Couldn't find what you're looking for?
        </BaseText>
        <div class="links flex items-center gap-2 flex-nowrap w-max">
          <button class="link-button flex items-center gap-1.5 py-1.5 pl-2 pr-2.5 rounded-md"
          @click="showIntercom">
            <div class="relative flex items-center justify-center" style="bottom: 3px">
              <img class="h-3.5 z-10" src="../../assets/images/zach.png">
              <img class="rounded-full h-3.5 -ml-2 -mb-3" src="../../assets/images/jake.png">
            </div>
            <BaseText type="label" size="sm">
              Contact Support
            </BaseText>
          </button>
          <a class="link-button flex items-center gap-1.5 py-1.5 pl-2 pr-2.5 rounded-md"
          href="https://intercom.help/foreplay/en" target="_blank">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 13V13.0075M10 10.75C10 9.5206 11.5 9.94893 11.5 8.5C11.5 7.67157 10.8284 7 10 7C9.44478 7 8.96005 7.30165 8.7007 7.75M16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.728 3.25 16.75 6.27208 16.75 10Z" 
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <BaseText type="label" size="sm">
              Help Center
            </BaseText>
          </a>
          <a class="link-button flex items-center gap-1.5 py-1.5 pl-2 pr-2.5 rounded-md"
          href="https://www.foreplay.co/book-demo" target="_blank">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.81592 7.91336C7.37254 9.01503 8.11102 10.0484 9.03136 10.9687C9.9517 11.889 10.985 12.6275 12.0867 13.1841L13.2232 12.0476C13.4661 11.8048 13.8428 11.7579 14.1378 11.9339L16.1104 13.1107C16.5301 13.3611 16.6021 13.9395 16.2565 14.2851L14.2159 16.3257C13.8373 16.7044 13.2836 16.8581 12.7752 16.6901C11.7798 16.3612 10.8122 15.9246 9.89054 15.3803C8.83142 14.7548 7.83304 13.987 6.92304 13.077C6.01304 12.167 5.24528 11.1686 4.61976 10.1095C4.07545 9.18791 3.63884 8.2203 3.30993 7.22489C3.14194 6.71648 3.29569 6.1628 3.67431 5.78418L5.71496 3.74354C6.06053 3.39797 6.639 3.46992 6.88938 3.88962L8.06619 5.86228C8.24215 6.15722 8.19528 6.534 7.95243 6.77685L6.81592 7.91336Z" 
              stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <BaseText type="label" size="sm">
              Book a Sales Call
            </BaseText>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'SpyderTutorialModal',
  mixins: [clickaway],
  components: {
    TagRemoveIcon
  },
  props: {
    timestamp: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      embedUrl: 'https://www.youtube.com/embed/zNj71_-IIVk?si=C5PRycUlLRkX86l8',
      extraHelpHovered: false
    }
  },
  created () {
    this.embedUrl = this.embedUrl + `&amp;start=${this.timestamp}`
  },
  methods: {
    showIntercom () {
      window.Intercom('show')
    }
  }
}
</script>

<style scoped>
.base-modal {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 500000;
}
.video-container {
  position: relative;
  width: 808px;
  height: 458px;
  border-radius: 12px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(10px);
}
.close-button {
  position: absolute;
  top: 4px;
  left: calc(100% + 16px);
  background-color: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(20px);
}
.close-button svg {
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.15));
}
.extra-help-container {
  position: absolute;
  top: calc(100% + 48px);
  left: 50%;
  transform: translateX(-50%);
  width: 290px;
  height: 36px;
  border-radius: 44px;
  transition: 
    width 300ms ease-in-out, 
    height 300ms ease-in-out,
    border-radius 300ms ease-in-out;
}
.extra-help-container.expanded {
  width: 488px;
  height: 94px;
  border-radius: 12px;
}
.extra-help-container .text {
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  transition: top 300ms ease-in-out;
}
.extra-help-container.expanded .text {
  top: 16px;
}
.extra-help-container .links {
  position: absolute;
  top: 42px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%) scaleX(50%) scaleY(30%);
  transform-origin: top;
  transition: top 300ms ease-in-out, transform 300ms ease-in-out, opacity 300ms ease-in-out;
}
.extra-help-container.expanded .links {
  top: 46px;
  opacity: 1;
  transform: translateX(-50%) scaleX(100%) scaleY(100%);
}

.link-button {
  color: #303546; /* text-text-muted */
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  background-color: white;
  transition: box-shadow 100ms ease-in-out, color 100ms ease-in-out, background-color 100ms ease-in-out;
}
.link-button:hover {
  color: black;
  box-shadow: none;
  background-color: #F6F8FA;
}
</style>