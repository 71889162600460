<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_450_155)">
      <path d="M9.99992 1.45837V6.87504M18.5416 10H13.1249M9.99992 13.125V18.5417M6.87492 10H1.45825M9.99992 16.0417C6.6632 16.0417 3.95825 13.3368 3.95825 10C3.95825 6.66332 6.6632 3.95837 9.99992 3.95837C13.3366 3.95837 16.0416 6.66332 16.0416 10C16.0416 13.3368 13.3366 16.0417 9.99992 16.0417Z" 
      :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_450_155">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: 'NicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped lang="css"></style>