<template>
  <div class="bg-white w-full flex-wrap relative"
  :class="{'h-max': allAdSettings }">
    <BaseText ref="text" type="body" size="sm"
    class="text-text-muted flex items-center gap-2 flex-wrap break-words text-sm font-normal px-4 pt-1 bg-white w-full pb-1"
    :class="{'max-h-36 overflow-hidden': !allAdSettings }"
    v-html="getCopy"/>
    <div v-if="textOverflows && !allAdSettings"
    class="flex justify-center items-end gap-2 w-full px-3 absolute -bottom-px width-full bg-gradient-to-t h-4/5 from-white to-transparent">
      <button class="view-more-button relative bottom-px group px-2.5 py-0.5 rounded-full shadow-sm transition-colors bg-white hover:bg-neutral-25
      border border-border-normal hover:border-transparent"
      @click="openDetailsDrawer">
        <BaseText type="label" size="xs" class="relative text-text-muted transition-colors group-hover:text-text-loud z-50" >
          Read More
        </BaseText>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvertisementInformation',
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    openDetailsDrawer: {
      type: Function,
      default: () => {}
    },
    allAdSettings: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      textOverflows: false
    }
  },
  mounted () {
    // Combining nextTick and requestAnimationFrame ensures the DOM is fully rendered first
    this.$nextTick(() => {
      requestAnimationFrame(() => {
        this.textOverflows = this.$refs.text.$el.scrollHeight > 144
      })
    })
  },
  computed: {
    getCopy () {
      return this.advertisement.description?.replace(/\n/g, '<br>')
    }
  }
}
</script>

<style scoped>
</style>
