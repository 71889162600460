<template>
  <div class="px-4 w-full">
    <BaseCard
      border
      class="rounded-xl w-full bg-white"
    >
      <ModularDetailsSectionHeader
        :expanded="expanded"
        :section="{ type: 'loomLinks', title: 'Loom Video' }"
        badge-text="Filled"
        :filled="!!loomFields.length"
        @toggleExpanded="$emit('toggleExpanded')"
        @deleteSection="$emit('deleteSection')"
      />

      <div v-if="expanded">
        <div
          class="flex flex-wrap gap-2 px-4"
        >
          <div class="w-full">
            <div
              v-for="(links, index) in loomFields"
              :key="index"
            >
              <div
                class="py-3 w-full flex flex-wrap items-start gap-3 text-sm"
              >
                <BaseInputV2
                  label="Link"
                  class="w-2/5"
                  icon
                  :value="links.link"
                  placeholder="www.example.com"
                  @input="updateField(index, 'link', $event)"
                >
                  <!-- Icon -->
                  <img
                    slot="icon"
                    src="../../../assets/icons/chain.svg"
                  >
                </BaseInputV2>

                <BaseButton
                  outlined
                  icon-only
                  class="mt-6"
                  @click="removeLoomLink(index)"
                >
                  <img src="../../../assets/icons/trash-gray.svg">
                </BaseButton>
              </div>
              <div
                class="w-full bg-white flex items-center justify-start pb-5"
              >
                <div
                  v-if="loomHtml"
                  class="w-max h-max rounded-lg overflow-hidden flex items-center justify-center"
                  v-html="loomHtml"
                />
              </div>
            </div>
            <div
              v-if="!loomFields.length"
              class="flex flex-wrap gap-2 py-3 px-4"
            >
              <BaseButton
                outlined
                @click="addField"
              >
                <div class="flex text-text-muted items-center">
                  <img src="../../../assets/icons/plus-icon.svg">
                  Add New
                </div>
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModularDetailsSectionHeader from './ModularDetailsSectionHeader.vue'
import * as loom from '@loomhq/loom-embed'

export default {
  name: 'LoomLinks',
  components: {
    ModularDetailsSectionHeader
  },
  props: {
    value: {
      type: Array,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      loomFields: [{ link: '', notes: '' }],
      timeout: null,
      loomHtml: '',
      loomEmbedOptions: {
        width: 430
      }
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getInspoAds']),
    ...mapGetters('AuthModule', ['getTeam'])
  },
  mounted () {
    this.loomFields = this.value
    this.loomFields.forEach((field, index) => {
      this.createEmbed(field.link)
    })
  },
  methods: {
    removeLoomLink (index) {
      this.loomFields = this.loomFields.filter((field, i) => i !== index)
      this.$emit('update', this.index, this.loomFields)
      this.loomHtml = ''
    },
    updateField (index, field, newValue) {
      if (this.timeout) { clearTimeout(this.timeout) }
      const cloneArr = this.loomFields
      this.loomFields[index][field] = newValue

      this.loomFields = cloneArr

      this.timeout = setTimeout(() => {
        this.$emit('update', this.index, this.loomFields)
        this.createEmbed(newValue)
      }, 500)
    },
    async createEmbed (newValue) {
      this.loomFields = this.value
      const loomEmbed = await loom.oembed(newValue, this.loomEmbedOptions)
      this.loomHtml = loomEmbed.html
    },
    addField () {
      this.loomFields.push({ link: '', notes: '' })
    }
  }
}
</script>

<style scoped lang="css">
</style>
