<template>
  <div>
    <AdvertisementGanttList v-if="advertisements.length"
      discovery
      :advertisements="advertisements"
      :my-chart-start="myChartStart"
      :my-chart-end="myChartEnd"
      :current-zoom="currentZoom"
      :zoom-in="zoomIn"
      @getMoreAds="$emit('getMoreAds')"
      @update:myChartStart="updateMyChartStart($event)"
      @update:myChartEnd="updateMyChartEnd($event)"
    />
    <div v-else-if="!loadingAdvertisements" class="flex flex-col items-center mt-20">
      <img src="../../../assets/images/empty-boards.gif"
      class="border border-gray-400 rounded mx-auto object-cover" style="width: 400px; height: 300px">
      <div class="text-lg font-bold my-4">
        Sorry no ads...
      </div>
      <div class="text-center text-gray-700">
        Spyder brands are automatically updated daily
        <br>
        with new ads from Facebook's Ad Library.
      </div>
      <div class="flex mt-5">
        <router-link :to="{ name: 'SpyderBrandsView' }" class="mr-2">
          <BaseButton primary>
            <div class="flex items-center">
              <img src="../../../assets/icons/spyder-icon.svg" alt="" class="w-5 h-5 mr-1.5 -ml-1.5 filter invert">
              Spyder Brands
            </div>
          </BaseButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import AdvertisementGanttList from '../../advertisements/AdvertisementGanttList.vue'

  export default {
    name: 'TimelineBrandView',
    components: {
      AdvertisementGanttList
    },
    props: {
      advertisements: {
        type: Array,
        required: true
      },
      loadingAdvertisements: {
        type: Boolean,
        required: true
      },
      myChartStart: {
        type: String,
        required: true
      },
      myChartEnd: {
        type: String,
        required: true
      },
      currentZoom: {
        type: String,
        required: true
      },
      zoomIn: {
        type: Function,
        required: true
      }
    },
    methods: {
      updateMyChartStart(value) {
        this.$emit('update:myChartStart', value)
      },
      updateMyChartEnd(value) {
        this.$emit('update:myChartEnd', value)
      }
    }
  }
</script>

<style scoped></style>