<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M14.2606 14.1463L14.0712 15.2514C13.9299 16.0758 13.138 16.6335 12.3386 16.3875C10.347 15.7748 8.47168 14.6804 6.89566 13.1044C5.31964 11.5284 4.22525 9.65307 3.61249 7.66148C3.36655 6.86209 3.92427 6.07015 4.74862 5.92884L5.85373 5.73939M14.2606 14.1463L14.814 10.9186M14.2606 14.1463C13.0666 13.9205 11.9039 13.4876 10.8303 12.8478M5.85373 5.73939C6.23465 7.75344 7.20448 9.67812 8.7632 11.2368C9.39785 11.8714 10.093 12.4084 10.8303 12.8478M5.85373 5.73939L10.0802 5.01485M14.814 10.9186C14.4044 10.3615 13.7445 10 13 10C11.7574 10 10.75 11.0073 10.75 12.25C10.75 12.457 10.778 12.6574 10.8303 12.8478M14.814 10.9186L15.6417 6.09022C15.8166 5.07 14.9301 4.18345 13.9099 4.35834L12.6768 4.56972M10.0802 5.01485C10.0282 5.16703 10 5.33023 10 5.50002C10 6.32845 10.6716 7.00002 11.5 7.00002C12.3285 7.00002 13 6.32845 13 5.50002C13 5.1486 12.8792 4.82541 12.6768 4.56972M10.0802 5.01485L12.6768 4.56972" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'FoodNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>