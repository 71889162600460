var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex mt-8"},[(_vm.advertisement.mobileScreenshot)?_c('div',{staticClass:"h-32 w-32 bg-black mr-6 overflow-hidden"},[_c('img',{staticClass:"overflow-hidden",attrs:{"src":_vm.advertisement.mobileScreenshot}})]):(_vm.advertisement.needsScreenshot)?_c('div',{staticClass:"h-32 w-32 bg-gray-200 skeleton mr-6 overflow-hidden flex flex-col items-center justify-center text-center"},[_c('div',{staticClass:"text-xs mb-1"},[_vm._v(" Taking Screenshots... ")]),_c('div',{staticClass:"text-xs text-gray-400"},[_vm._v(" This may take a few minutes ")])]):_c('div',{staticClass:"h-32 w-32 bg-gray-200 skeleton mr-6 overflow-hidden flex flex-col items-center justify-center text-center"},[_c('div',{staticClass:"text-xs mb-1"},[_vm._v(" An Error Occurred ")]),_c('div',{staticClass:"text-xs text-gray-400"},[_vm._v(" We will retry the screenshot soon ")])]),_c('div',{staticClass:"flex flex-col items-start"},[_c('div',{staticClass:"text-gray-500 text-lg semibold -mb-1 -mt-1"},[_vm._v(" Landing Page ")]),_c('a',{staticClass:"text-blue-400 cursor-pointer",on:{"click":function($event){return _vm.handleWebLandingPage('web', _vm.advertisement.link_url)}}},[_vm._v(" "+_vm._s(_vm.getDomainFromUrl(_vm.advertisement.link_url))+" ")]),_c('div',{staticClass:"flex mt-2"},[_c('a',{staticClass:"flex rounded p-0.5 w-16 items-center mr-2 select-none",class:{
          'cursor-not-allowed bg-gray-100 text-gray-400':
            !_vm.advertisement.mobileScreenshot,
          'cursor-pointer bg-gray-200': _vm.advertisement.mobileScreenshot,
        },on:{"click":function($event){return _vm.handleWebLandingPage('mobile', _vm.advertisement.mobileScreenshot)}}},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"}})]),_c('div',{staticClass:"ml-2"},[_vm._v("View")])]),_c('a',{staticClass:"flex rounded p-0.5 w-16 items-center select-none",class:{
          'cursor-not-allowed bg-gray-100 text-gray-400':
            !_vm.advertisement.desktopScreenshot,
          'cursor-pointer  bg-gray-200': _vm.advertisement.desktopScreenshot,
        },attrs:{"target":"_blank"},on:{"click":function($event){return _vm.handleWebLandingPage('desktop', _vm.advertisement.desktopScreenshot)}}},[_c('svg',{staticClass:"h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"}})]),_c('div',{staticClass:"ml-2"},[_vm._v("View")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }