<template>
  <!-- Manage Board Modal -->
  <BaseModal @close="$emit('close')" noPadding noToolbarPadding noExit>
    <template #toolbar>
      <div class="flex w-full gap-3 pb-5 pt-1">
        <div id="tags-brand-container" class="relative">
          <div id="tags-brand-shadow" /> 
          <img src="../../assets/images/tags-branded-icon.png" class="relative z-50" />
        </div>
        <div class="flex-grow flex flex-col">
          <div class="font-medium text-text-loud">
            Add tags to {{ selectedAds.length === 1 ? '' : selectedAds.length }} {{ selectedAds.length === 1 ? 'ad' : 'ads' }}
          </div>
          <div class="text-sm text-text-normal">
            The below tags will be added to {{ selectedAds.length === 1 ? 'one ad' : 'multiple ads' }}
          </div>
        </div>
        <div class="ml-auto">
          <div id="close-wrapper" @click="$emit('close')">
            <TagRemoveIcon stroke="#5E6678" class="cursor-pointer"/>
          </div>
        </div>
      </div>
    </template>

    <template #default>
      <div class="border-t border-border-normal">
        <div id="bulk-tag-modal-actions" class=" w-full py-2 px-6">
          <div class="flex gap-1.5 items-center">
            <TagIcon stroke="#808899" fill="none" />
            <div class="text-sm font-medium text-text-normal">
              Tags
            </div>
          </div>
          <div class="py-2 px-3">
            <Tags bulkEditMode 
            @update:bulkSelectedTags="selectedTags = $event"
            @update:tableViewTags="forwardTagEdits"
            @tagDeleted="$emit('tagDeleted', $event)" />
          </div>
        </div>

        <!-- Save Board -->
        <div class="px-5 border-t border-border-normal py-5">
          <BaseButton primary class="ml-auto" :loading="loadingSave" :disabled="!selectedTags.length || loadingSave"
          @click="handleAdsUpdate">
            Add {{ selectedTags.length === 1 ? 'Tag' : 'Tags' }}
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import { mapActions, mapGetters } from 'vuex'
import Tags from './DetailsDrawerComponents/Tags.vue'
import TagIcon from '../globals/Icons/TagIcon.vue'
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'

export default {
  name: 'BulkAddTagsModal',
  components: {
    Tags,
    TagIcon,
    TagRemoveIcon
  },
  props: {
    selectedAds: {
      type: Array,
      default: () => []
    },
    advertisements: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedTags: [],
      tags: [],
      loadingSave: false
    }
  },
  computed: {
    ...mapGetters('TagsModule', ['getTags'])
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    ...mapActions('TagsModule', ['fetchTags']),

    // Handle Update Ads with new tags
    async handleAdsUpdate () {
      this.loadingSave = true

      // Update all ads and add the new tag IDs
      const db = firebase.firestore()

      // Create a copy of the selected ads to update
      // const adsToUpdate = [...this.selectedAds]
      const adsToUpdate = [...this.advertisements.filter(ad => this.selectedAds.includes(ad.id))]
      const updatedAds = []

      try {
        for (const ad of adsToUpdate) {
          console.log(ad)

          if (!ad.tags) {
            ad.tags = []
          }

          // Check if the ad already has the tag id
          for (const tag of this.selectedTags) {
            if (ad.tags.includes(tag.id)) {
              continue
            }

            // Update the duplicate array
            ad.tags.push(tag.id)
          }

          updatedAds.push(ad)

          // Add the tag id to the ad and save it to firestore
          await db.collection('ads')
            .doc(ad.id)
            .update({
              tags: ad.tags
            })
        }

        // Now emit the updated ads to update the ads in library view
        this.$emit('updateAds', updatedAds)
        this.$emit('close')

        this.$showAlert({
          message: 'Tags added successfully',
          type: 'success'
        })
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
      } finally {
        this.loadingSave = false
      }
    },
    forwardTagEdits (tagId, updatedTag) {
      console.log("BulkAddTagsModal received tag update, sending to LibraryView")
      this.$emit('update:tableViewTags', tagId, updatedTag)
    }
  }
}
</script>

<style scoped>
  #bulk-tag-modal-actions {
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
  }
  #tags-brand-container {
    width: 44px;
    height: 44px;
  }
  #tags-brand-shadow {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 8px;
    box-shadow: 0px 3px 20px 2px rgba(0,0,0,0.25);
  }
  #close-wrapper {
    transition: transform 150ms ease-in-out;
    transform: scale(1);
  }
  #close-wrapper:hover {
    transform: scale(1.10);
  }
</style>