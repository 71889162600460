<template>
  <!-- Base Tabs -->
  <div class="flex py-0.5" :class="{ 'bg-background-hover rounded-lg': small, ' bg-neutral-25 rounded-full ': !small}">
    <div
      v-for="(view, index) in views"
      :key="index"
      class="flex select-none items-center justify-center py-1 gap-1 cursor-pointer transition-all duration-300 relative"
      :class="{
        'w-24 rounded-md': small,
        'rounded-full w-36': !small,
        'active-shadow bg-white text-neutral-900': view === value,
        'text-neutral-500': view !== value
      }"
      @click="$emit('input', view)"
    >
      <slot
        v-if="icons && index === 0"
        name="icon1"
      />
      <slot
        v-if="icons && index === 1"
        name="icon2"
      />
      <slot
        v-if="icons && index === 2"
        name="icon3"
      />
      <div
        v-if="newBadge === index"
        class="border border-primary-blue-25 bg-primary-blue-10 text-primary-blue-200 flex items-center rounded-full select-none p-1.5 py-0.5 absolute -top-4"
      >
        <BaseText
          type="label"
          size="xs"
        >
          New!
        </BaseText>
      </div>
      <BaseText
        type="body"
        size="sm"
      >
        {{ view }}
      </BaseText>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  props: {
    views: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    small: Boolean,
    icons: Boolean,
    newBadge: {
      type: Number,
      default: -1
    },
    square: Boolean
  }
}
</script>

<style scoped lang="sass">
.active-shadow
  box-shadow: 0px 3px 8px rgba(9, 25, 72, 0.05), 0px 1px 2px rgba(9, 25, 72, 0.13)
</style>
