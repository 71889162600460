// Tags Module
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'

export default {
  namespaced: true,
  state: {
    tags: []
  },
  getters: {
    // Get Tags
    getTags: state => state.tags
  },
  mutations: {
    SET_TAGS (state, tags) {
      state.tags = tags
    }
  },
  actions: {
    // Fetch Tags
    async fetchTags ({ commit, rootState }) {
      const team = rootState.AuthModule.team
      let userTags
      if (team) {
        userTags = await FirebaseAPI.Tags.getAllByTeam(team.id)
      } else {
        userTags = await FirebaseAPI.Tags.getAllByUser()
      }
      userTags = userTags.sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1)
      const adCounts = await Promise.all(userTags.map(async tag => {
        return ForeplayAPI.Ads.getTagAdCount(tag.id)
      }))
      // Extend the tag objects in userTags to include the ad count for each tag
      userTags = userTags.map((tag, index) => {
        return {
          ...tag,
          adCount: adCounts[index]
        }
      })
      commit('SET_TAGS', userTags)
    }
  }
}
