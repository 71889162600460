<template>
  <div
    class="rounded-full shadow-m h-12 fixed align-middle bottom-nav flex items-center p-2 mb-6 gap-x-2 transition-all duration-500 w-4/6 sm:w-max sm:justify-self-center"
    style="background: rgba(1, 2, 9, 0.9); box-shadow: 0px 28px 11px rgba(1, 2, 9, 0.03), 0px 16px 10px rgba(1, 2, 9, 0.11), 0px 7px 7px rgba(1, 2, 9, 0.19), 0px 2px 4px rgba(1, 2, 9, 0.22);"
  >
    <div
      class="flex justify-between sm:justify-center items-center gap-x-3 h-6 transition-all duration-500 w-full sm:w-max pr-4 border-r border-white border-opacity-50"
    >
      <div
        class="cursor-pointer transition-all duration-300"
        :class="selectedNavItem === 'brand profile' ? 'bg-white bg-opacity-10 rounded-full w-max py-1.5 px-3.5 flex gap-x-2' : 'hover:bg-white hover:bg-opacity-10 rounded-full w-max py-1.5 px-1.5 transition-all duration-200' "
        @click="selectedNavItem = 'brand profile', setCurrentExpanded ()"
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="transition-opacity duration-200"
          :class="selectedNavItem === 'brand profile' ? '' : 'opacity-60'"
        >
          <path
            d="M3.95841 16.875V4.79167C3.95841 3.87119 4.70461 3.125 5.62508 3.125H14.3751C15.2956 3.125 16.0417 3.87119 16.0417 4.79167V16.875M3.95841 16.875H16.0417M3.95841 16.875H2.29175M16.0417 16.875H17.7084M7.29175 6.45833H8.54175M11.4584 6.45833H12.7084M7.29175 9.79167H8.54175M11.4584 9.79167H12.7084M7.29175 13.125H8.54175M11.4584 13.125H12.7084"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <BaseText
          v-if="selectedNavItem === 'brand profile'"
          type="label"
          size="sm"
          class="text-white w-max transition-all duration-300 hidden sm:block"
        >
          Brand Profile
        </BaseText>
      </div>
      <div
        class="cursor-pointer flex items-center justify-center h-8 w-8 transition-all duration-300 "
        :class="selectedNavItem === 'inspiration' ? 'bg-white bg-opacity-10 rounded-full w-max py-1.5 px-3.5 flex gap-x-2' : 'hover:bg-white hover:bg-opacity-10 rounded-full w-max py-1.5 transition-all duration-200' "
        @click="selectedNavItem = 'inspiration', setCurrentExpanded ()"
      >
        <img
          src="../../../assets/icons/magic-stick-top-white.svg"
          alt=""
          class="h-5 w-8 transition-opacity duration-200 pb-px"
          :class="selectedNavItem === 'inspiration' ? '' : 'opacity-60'"
        >
        <BaseText
          v-if="selectedNavItem === 'inspiration'"
          type="label"
          size="sm"
          class="text-white w-max transition-all duration-300 hidden sm:block"
        >
          Inspiration
        </BaseText>
      </div>
      <div
        class="cursor-pointer flex items-center h-8 transition-all duration-300"
        :class="selectedNavItem === 'modular details' ? 'bg-white bg-opacity-10 rounded-full w-max py-1.5 px-3.5 flex gap-x-2' : 'hover:bg-white hover:bg-opacity-10 rounded-full w-max py-1.5 transition-all duration-200' "
        @click="selectedNavItem = 'modular details', setCurrentExpanded ()"
      >
        <img
          src="../../../assets/icons/nav-brush-icon.svg"
          alt=""
          class="h-8 transition-opacity duration-300"
          :class="selectedNavItem === 'modular details' ? '' : 'opacity-60'"
        >
        <BaseText
          v-if="selectedNavItem === 'modular details'"
          type="label"
          size="sm"
          class="text-white w-max transition-all duration-300 hidden sm:block"
        >
          Modular Details
        </BaseText>
      </div>
      <div
        class="cursor-pointer flex items-center h-8 transition-all duration-300"
        :class="selectedNavItem === 'story board' ? 'bg-white bg-opacity-10 rounded-full w-max py-1.5 px-3.5 flex gap-x-2' : 'hover:bg-white hover:bg-opacity-10 rounded-full w-max py-1.5 transition-all duration-200' "
        @click="selectedNavItem = 'story board', setCurrentExpanded ()"
      >
        <img
          src="../../../assets/icons/nav-layout-icon.svg"
          alt=""
          class="h-8 transition-opacity duration-300"
          :class="selectedNavItem === 'story board' ? '' : 'opacity-60'"
        >
        <BaseText
          v-if="selectedNavItem === 'story board'"
          type="label"
          size="sm"
          class="text-white w-max transition-all duration-300 hidden sm:block"
        >
          Story Board
        </BaseText>
      </div>
      <div
        class="cursor-pointer flex items-center h-8 transition-all duration-300"
        :class="selectedNavItem === 'video script' ? 'bg-white bg-opacity-10 rounded-full w-max py-1.5 px-3.5 flex gap-x-2' : 'hover:bg-white hover:bg-opacity-10 rounded-full w-max py-1.5 transition-all duration-200' "
        @click="selectedNavItem = 'video script', setCurrentExpanded ()"
      >
        <img
          src="../../../assets/icons/nav-video-script-icon.svg"
          alt=""
          class="h-8 transition-opacity duration-200"
          :class="selectedNavItem === 'video script' ? '' : 'opacity-60'"
        >
        <BaseText
          v-if="selectedNavItem === 'video script'"
          type="label"
          size="sm"
          class="text-white w-max transition-all duration-300 hidden sm:block"
        >
          Video Script
        </BaseText>
      </div>
    </div>
    <div class="pl-2">
      <button
        class=" w-max rounded-full py-1.5 px-3 flex gap-x-2 items-center"
        style="background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #009F6D;
        border: 1px solid rgba(255, 255, 255, 0.12);
        box-shadow: inset 0px -1px 12px rgba(255, 255, 255, 0.12);
        border-radius: 99px;"
        @click="()=> openUploadModal.openModal()"
      >
        <img
          src="../../../assets/icons/upload-icon.svg"
          alt=""
        >
        <BaseText
          type="label"
          size="sm"
          class="text-white"
        >
          Upload Content
        </BaseText>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomNav',
  props: {
    scrollObject: {
      type: Object,
      default: function () {
        return {}
      }
    },
    openUploadModal: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      selectedNavItem: ''
    }
  },
  methods: {
    setCurrentExpanded () {
      if (this.selectedNavItem === 'brand profile') {
        this.scrollObject.brandProfile()
      }
      if (this.selectedNavItem === 'modular details') {
        this.scrollObject.modularDetails()
      }
      if (this.selectedNavItem === 'story board') {
        this.scrollObject.storyBoard()
      }
      if (this.selectedNavItem === 'video script') {
        this.scrollObject.videoScript()
      }
      if (this.selectedNavItem === 'inspiration') {
        this.scrollObject.inspirationBoard()
      }
    }
  }
}
</script>

<style scoped>
.bottom-nav {
  background: rgba(255, 255, 255, 0.92);
  box-shadow: 0px 8px 24px -12px rgba(2, 23, 59, 0.36), 0px 1px 2px rgba(164, 172, 185, 0.16), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  backdrop-filter: blur(5px);
  z-index: 57;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0%;
}
</style>
