<template>
  <!-- Base Drawer -->
  <transition name="slide">
    <div class="base-drawer overflow-y-scroll"
    :class="{'exit-overlay-hovered': exitOverlayHovered}">
      <!-- Takes up the remaining space not covered by the drawer -->
      <div class="relative w-full h-20" 
        @mouseenter="exitOverlayHovered = true"
        @mouseleave="exitOverlayHovered = false"
      >
        <div class="close-drawer-indicator flex items-center gap-3 py-2 pl-2.5 pr-4 cursor-pointer shadow-lg
        bg-neutral-900 text-sm text-white rounded-lg transition-opacity duration-300 ease-in-out"
        :class="{'opacity-100': exitOverlayHovered, 'opacity-0': !exitOverlayHovered}">
          <DownArrowIcon />
          <div>ESC to close details</div>
        </div>
      </div>
      <div
        v-on-clickaway="() => $emit('close')"
        class="base-drawer--card"
        :class="{
          'rounded-2xl': !noBottomRounding,
          'rounded-t-2xl': noBottomRounding,
          'h-auto min-h-screen': heightAuto,
          'h-full': !heightAuto && !heightAutoOnly,
          'h-auto': heightAutoOnly,
          'exit-hover-entering': exitOverlayHovered, 
          'exit-hover-leaving': !exitOverlayHovered
        }"
      >
        <!-- Toolbar -->
        <div class="base-drawer--card--toolbar"
        :class="{'p-4' : !noPadding }">
          <slot name="toolbar" />
        </div>

        <!-- Content -->
        <div class="base-drawer--card--content">
          <slot name="content" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DownArrowIcon from './Icons/DownArrowIcon.vue';
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  name: 'BaseDrawer',
  mixins: [clickaway],
  components: {
    DownArrowIcon
  },
  props: {
    noPadding: {
      type: Boolean,
      default: false
    },
    heightAuto: {
      type: Boolean,
      default: false
    },
    heightAutoOnly: {
      type: Boolean,
      default: false
    },
    noBottomRounding: {
      type: Boolean,
      default: false
    },
    blockKeyboardClose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      exitOverlayHovered: false,
    }
  },
  mounted () {
    console.log("Drawer mounted")
    window.addEventListener('keydown', this.handleCloseKeydown)
  },
  beforeDestroy () {
    // Cleanup the event listener when component is unmounted
    window.removeEventListener('keydown', this.handleCloseKeydown)
  },
  methods: {
    handleCloseKeydown (event) {
      if (!this.blockKeyboardClose && (event.key === 'Escape' || event.keyCode === 27)) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped lang="sass">
.base-drawer
  @apply h-screen fixed right-0 top-0 w-screen

  &--card
    animation: appearBottom 300ms ease-in-out
    transition: transform 300ms ease-in-out
    @apply absolute bg-white w-full right-0

    &--toolbar
      @apply flex justify-end items-center

      i
        @apply cursor-pointer text-gray-400 text-lg

    &.exit-hover-entering
      transform: translateY(20px)

    &.exit-hover-leaving
      transform: translateY(0px)

@keyframes appearBottom
  0%
    opacity: 0
    transform: translateY(400px)

  100%
    opacity: 1

.slide-leave-active
  transition: 300ms

.slide-leave-to
  opacity: 0
</style>

<style scoped lang="css">
.base-drawer {
  background: rgba(0, 0, 0, 0.75);
  z-index: 50000;
  transition: background 0.3s ease-in-out;
}
.base-drawer.exit-overlay-hovered {
  background: rgba(0, 0, 0, 0.5);
}
.close-drawer-indicator {
  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
}
</style>