<template>
  <!-- Manage Board Modal -->
  <BaseModal
    no-padding
    no-exit
  >
    <template #toolbar>
      <div
        class="text-black w-full flex flex-col items-center font-medium justify-center -mt-16"
      >
        <img
          class="w-20 mb-4 shadow-lg rounded-3xl overflow-visible"
          src="../../assets/images/icon-black.svg"
        >
        <span class="text-xl pb-1.5">Welcome to Foreplay!</span>
      </div>
    </template>

    <template #default>
      <div class="p-0 pb-5">
        <!-- Divider -->
        <div class="w-full border-t-2" />
        <!-- Steps Container -->
        <div
          class="h-full overflow-y-scroll scrollbar-hide"
          style="max-height: calc(90vh - 200px)"
        >
          <!-- Step 1 -->
          <div class="p-6">
            <img
              class=""
              src="../../assets/images/chrome-icon.svg"
            >
            <div class="pt-2 text-lg">
              Step 1: Download our Chrome Extension
            </div>
            <div class="text-gray-500 mt-px">
              Our chrome extension is the most efficent way to save ads. This
              will display a “Save” button on multiple platforms including
              Facebook Ad Library & TikTok.
            </div>
            <a
              href="https://chrome.google.com/webstore/detail/ad-library-save-facebook/eaancnanphggbfliooildilcnjocggjm"
              target="_blank"
            >
              <BaseButton
                class="mt-4"
                primary
              > Install Extension </BaseButton>
            </a>
          </div>
          <!-- Divider -->
          <div class="w-full border-t-2" />
          <!-- Step 1 -->
          <div class="p-6">
            <div class="pt-2 text-lg">
              Step 2: See what Foreplay can do for you
            </div>
            <div class="text-gray-500 mt-px mb-4">
              Watch the video to get the most out of Foreplay:
            </div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/m1iEyLoVxOM?si=HyyMMrm6u4k619jL"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>

          <!-- Divider -->
          <div class="w-full border-t-2" />

          <!-- Close the browser -->
        </div>

        <div class="flex justify-center">
          <BaseButton
            class="mt-4"
            primary
            @click="onboardingComplete"
          >
            Start Using Foreplay
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import firebase from '@/api/config/FirebaseConfig'

export default {
  name: 'TrialEndedModal',
  components: {},
  props: {
    // Board
    board: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedSubscription: {},
      subscriptions: [],
      loadingSubscriptions: false,
      loadingGoToStripe: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getUser']),
    getSortedSubscriptions () {
      const subs = [...this.subscriptions]
      return subs.sort((a, b) => (a.order > b.order ? 1 : -1))
    }
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_ONBOARDING_COMPLETE']),
    // Handle Logout User
    async onboardingComplete () {
      // Set Onboarding Complete
      this.SET_ONBOARDING_COMPLETE()
      const user = firebase.auth().currentUser
      await FirebaseAPI.Users.update(user.uid, { onboardingComplete: true })

      // Identify User
      window.analytics.identify({
        name: this.getUser.displayName,
        email: user.email,
        id: user.uid,
        onboardingVarient: 'control2'
      })

      // Track Onboarding
      window.analytics.track('Onboarding', {
        id: user.uid,
        email: user.email,
        onboardingVarient: 'control2',
        onboardingCompleteStep: {
          completed: true
        }
      })
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
