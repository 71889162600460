<template>
  <!-- Boards Details View -->
  <MainLayout
    v-scroll="onScroll"
    scroll-toolbar
  >
    <!-- Toolbar -->
    <template #toolbar>
      <div class="flex items-start justify-between px-2 pt-2">
        <div class="flex flex-col">
          <div class="flex items-center">
            <img
              class="rounded-full w-10 mr-2 border border-gray-300"
              :src="brand.avatar"
            >
            <div class="text-xl font-semibold">
              {{ brand.name }}
            </div>
          </div>
          <div
            class="py-2 flex items-center text-gray-600 cursor-pointer hover:text-gray-400 transition"
            @click="$router.push({ name: 'DiscoveryBrandsView' })"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4 mr-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
            Back to brands
          </div>
        </div>
        <!-- Ad Library Links -->
        <div class="flex">
          <div
            v-if="brand.linkedinId"
            class="flex items-center mr-2"
          >
            <a
              :href="`https://www.linkedin.com/company/${brand.linkedinId}/posts/?feedView=ads`"
              target="_blank"
              @click.stop
            >
              <BaseButton outlined>
                <div class="flex items-center text-gray-600">
                  <LinkedInIcon
                    :width="20"
                    class="mr-2"
                  />
                  LinkedIn Library
                </div>
              </BaseButton>
            </a>
          </div>

          <!-- Ad Library Button -->
          <div
            v-if="brand.adLibraryId"
            class="flex items-center"
          >
            <a
              :href="`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${brand.adLibraryId}`"
              target="_blank"
              @click.stop
            >
              <BaseButton outlined>
                <div class="flex items-center text-gray-600">
                  <FacebookIcon
                    :width="20"
                    class="mr-2"
                  />
                  Facebook Library
                </div>
              </BaseButton>
            </a>
          </div>
        </div>
      </div>

      <AdvertisementSavingList
        v-if="getAdsBeingSaved.length"
        :advertisements="getAdsBeingSaved"
        @reload="fetchAdvertisements(false)"
      />
    </template>
    <template #filter>
      <div ref="filterBar" class="border border-border-normal bg-white bg-opacity-90" style="backdrop-filter: blur(6px)">
        <FilterTopBar
          discovery-brands
          :selected-formats.sync="selectedFormats"
          :selected-platforms.sync="selectedPlatforms"
          :selected-niches.sync="selectedNiches"
          :selected-languages.sync="selectedLanguages"
          :sort-order.sync="sortOrder"
          :text-search.sync="textSearch"
          :live-status.sync="liveStatus"
          @change="fetchAdvertisements"
        />
      </div>
    </template>

    <template #content>
      <!-- Loading State -->
      <BaseLoadingSpinner
        v-if="loadingAdvertisements"
        class="mt-24"
      />

      <!-- Empty State -->
      <div
        v-else-if="!advertisements.length"
        class="flex flex-col items-center mt-20"
      >
        <img
          src="../../assets/images/empty-single-library.gif"
          class="border border-gray-400 rounded mx-auto object-cover"
          style="width: 400px; height: 300px"
        >

        <div class="text-lg font-bold my-4">
          This brand is empty...
        </div>

        <div class="text-center text-gray-700">
          You created this board but haven't save

          <br>

          any ads yet.
        </div>

        <div class="flex mt-5">
          <BaseButton
            primary
            @click="showAdvertisementModal = true"
          >
            <i class="fas fa-plus mr-1 text-xs" />
            Save ads
          </BaseButton>
        </div>
      </div>

      <!-- Ads List -->
      <AdvertisementList
        v-else
        :discovery="true"
        :advertisements="advertisements"
        @getMoreAds="() => getMoreAds({})"
        @reload="fetchAdvertisements(false, true)"
      />

      <!-- Create Advertisement Modal -->
      <CreateAdvertisementModal
        v-if="showAdvertisementModal"
        :current-board="board.id"
        @close="showAdvertisementModal = false"
        @save="fetchAdvertisements(false)"
      />

      <!-- Manage Board Modal -->
      <infinite-loading
        v-if="advertisements.length"
        :identifier="infiniteId"
        @infinite="getMoreAds"
      >
        <div slot="spinner">
          <BaseLoadingLogo :margin="2" />
        </div>
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import ForeplayAPI from '@/api/foreplayServer'
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import AdvertisementSavingList from '../../components/advertisements/AdvertisementSavingList'
import CreateAdvertisementModal from '../../components/advertisements/AdvertisementCreateModal'
import MainLayout from '../../layouts/MainLayout'
import InfiniteLoading from 'vue-infinite-loading'
import LinkedInIcon from '../../components/globals/Icons/FilterIcons/LinkedInIcon.vue'
import FacebookIcon from '../../components/globals/Icons/FilterIcons/FacebookIcon.vue'
import FilterTopBar from '../../components/search/FilterTopBar.vue'

export default {
  name: 'DiscoveryBrandView',
  components: {
    AdvertisementList,
    AdvertisementSavingList,
    CreateAdvertisementModal,
    MainLayout,
    FacebookIcon,
    LinkedInIcon,
    InfiniteLoading,
    FilterTopBar
  },
  data () {
    return {
      brand: {},
      advertisements: [],
      loadingAdvertisements: true,
      showManageBoardModal: false,
      showAdvertisementModal: false,
      selectedBoard: {},
      infiniteId: +new Date(),
      lastDocId: null,
      docOrder: 'desc',
      sortOrder: [],
      infiniteLoaderState: null,
      filtersApplied: false,
      selectedFormats: [],
      selectedNiches: [],
      selectedLanguages: [],
      liveStatus: [],
      selectedPlatforms: [],

      // Search Section
      searchQuery: '',
      searchMode: false,
      searchPage: 0,
      textSearch: '',

      // Brands Section
      favoriteFilter: false,
      shouldUpdateQuery: new Date().toString()
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', [
      'getUserEmail',
      'getUserName',
      'getTeam',
      'getUser'
    ]),
    ...mapGetters('BoardsModule', ['getBoards']),
    // Get Advertisements
    getAdvertisements () {
      return this.advertisements
    }
  },
  async mounted () {
    this.$refs.filterBar.style.position = 'sticky'
    this.$refs.filterBar.style.top = '16px'
    this.$refs.filterBar.style.zIndex = '50000'

    this.sortOrder = [{ name: 'Newest', value: 'desc' }]
    this.niches = await this.getNiches()
    if (this.getUser?.favoritedBrands?.includes(this.brand?.id)) {
      await this.addLastVisited()
    }
    await this.fetchAdvertisements()
    console.log('this.brand', this.brand)
    window.analytics.track('Brand Viewed', {
      brandName: this.brand.name,
      catagory: this.brand.niches,
      platforms: this.brand.publisherPlatforms
    })
  },
  methods: {
    toggleOrder () {
      if (this.docOrder === 'asc') {
        this.docOrder = 'desc'
      } else {
        this.docOrder = 'asc'
      }

      this.fetchAdvertisements()
    },
    async getNiches () {
      // Setup
      const db = firebase.firestore()

      const snapshot = await db.collection('niches').get()

      const newDocs = snapshot.docs?.map((doc) => {
        const docData = {
          ...(doc.data() || [])
        }

        return { name: docData.niche, icon: docData.emoji }
      })

      const sortedDocs = newDocs.sort((a, b) => (a.name > b.name ? 1 : -1))
      const otherIndex = sortedDocs.findIndex((d) => d.name === 'Other')
      const otherNiche = sortedDocs.splice(otherIndex, 1)

      sortedDocs.push(otherNiche[0])

      return sortedDocs
    },
    async addLastVisited () {
      // Setup
      const db = firebase.firestore()

      db.collection('brands-visited')
        .doc(`${this.getUser.user_id}-${this.brand.id}`)
        .set({
          currentCount: this.brand.adsSaved
        })
    },
    async fetchAdvertisements (load = true, refresh = false) {
      this.loadingAdvertisements = load
      this.advertisements = []

      if (refresh) {
        this.lastDocId = null
      }

      const db = firebase.firestore()
      const brandSnapshot = await db
        .collection('brands')
        .doc(this.$route.params.id)
        .get()
      this.brand = { ...brandSnapshot.data(), id: brandSnapshot.id }

      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(
          null,
          {
            textSearch: this.textSearch,
            sort: this.sortOrder[0].value,
            orFilters: {
              formats: this.selectedFormats,
              niches: this.selectedNiches,
              publisher_platform: this.selectedPlatforms,
              liveStatus: this.liveStatus,
              languages: this.selectedLanguages,
              brands: [{ brandId: this.$route.params.id }]
            }
          }
        )

        this.lastDocId = nextPage

        this.advertisements = results
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    },
    async getMoreAds ($state) {
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(
        this.lastDocId,
        {
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            publisher_platform: this.selectedPlatforms,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages,
            brands: [{ brandId: this.$route.params.id }]
          }
        }
      )

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5

      this.$refs.filterBar.style.marginLeft = `${marginValue}px`
      this.$refs.filterBar.style.marginRight = `${marginValue}px`
      this.$refs.filterBar.style.marginTop = `${marginValue}px`
      this.$refs.filterBar.style.borderRadius = `${rounding}px`

      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
          15
        )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
          7
        )}px ${shadowSection(
          10
        )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
          7
        )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
          3
        )}px ${shadowSection(5)}px`
        // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        this.$refs.filterBar.style.boxShadow = null
      }
    }
  }
}
</script>

<style scoped></style>
