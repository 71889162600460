// Imports
import AdsServices from './services/AdsServices'
import BriefsServices from './services/BriefsServices'
import InvitationServices from './services/InvitationServices'
import StripeServices from './services/StripeServices'
import TeamsServices from './services/TeamsServices'
import AnalyticsServices from './services/analyticsServices'

export default {
  Ads: AdsServices,
  Briefs: BriefsServices,
  Invitations: InvitationServices,
  Stripe: StripeServices,
  Teams: TeamsServices,
  Analytics: AnalyticsServices
}
