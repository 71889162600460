<template>
  <!-- Boards View -->
  <MainLayout>
    <!-- Toolbar -->
    <template #toolbar>
      <div class="border-b flex items-center justify-between px-8 py-5">
        <div>
          <div class="text-3xl font-semibold">
            Boards
          </div>

          <div class="text-sm text-gray-500">
            A list of boards you have created.
          </div>
        </div>

        <div class="flex">
          <!-- Create new board -->
          <BaseButton
            class="bg-blue-700 text-white"
            primary
            @click="showManageBoardModal = true"
          >
            <i class="fas fa-plus mr-1 text-xs" />
            New Board
          </BaseButton>
        </div>
      </div>

      <!-- Filters -->
      <div class="flex px-8">
        <div class="border-r border-gray-300 flex items-center pr-8 flex-grow-0 font-semibold">
          Filter
        </div>

        <div class="flex w-full gap-5 py-3 pl-6">
          <BaseInput
            v-model="searchQuery"
            icon
            class="w-1/3"
            placeholder=""
          >
            <template #icon>
              Search
            </template>
          </BaseInput>
        </div>
      </div>

      <AdvertisementSavingList
        v-if="getAdsBeingSaved.length"
        :advertisements="getAdsBeingSaved"
        @reload="fetchAdvertisements()"
      />
    </template>

    <!-- Boards List -->
    <template #content>
      <!-- Loading State -->
      <BaseLoadingSpinner
        v-if="loadingBoards"
        class="mt-24"
      />

      <!-- No boards found message -->
      <div
        v-else-if="!getFilteredBoards.length && searchQuery"
        class="text-center mt-24 text-lg"
      >
        No boards found.
      </div>

      <!-- Empty State -->
      <div
        v-else-if="!getBoards.length"
        class="flex flex-col items-center mt-20"
      >
        <img
          src="../../assets/images/empty-boards.gif"
          class="border border-gray-400 rounded mx-auto object-cover"
          style="width: 400px; height: 300px"
        >

        <div class="text-lg font-bold my-4">
          You have no boards...
        </div>

        <div class="text-center text-gray-700">
          Boards are used to organize ads from your Library.

          <br>

          You can also generate links to share the board with anyone
        </div>

        <div class="flex mt-5">
          <BaseButton
            primary
            @click="showManageBoardModal = true"
          >
            <i class="fas fa-plus mr-1 text-xs" />
            Create a board
          </BaseButton>
        </div>
      </div>

      <!-- Boards -->
      <div
        v-else
        class="list"
      >
        <BoardCard
          v-for="(board, index) in getFilteredBoards"
          :key="index"
          :board="board"
          class="flex-1"
        />
      </div>

      <!-- Manage Board Modal -->
      <ManageBoardModal
        v-if="showManageBoardModal"
        :board="{}"
        @close="showManageBoardModal = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BoardCard from '../../components/boards/BoardCard'
import AdvertisementSavingList from '../../components/advertisements/AdvertisementSavingList'
import MainLayout from '../../layouts/MainLayout'
import ManageBoardModal from '../../components/boards/ManageBoardModal'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'LibraryView',
  components: {
    BoardCard,
    ManageBoardModal,
    MainLayout,
    AdvertisementSavingList
  },
  data () {
    return {
      loadingBoards: [],
      searchQuery: '',
      showManageBoardModal: false
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('BoardsModule', ['getBoards']),
    // Get Filtered Boards
    getFilteredBoards () {
      return this.getBoards.filter(board => board.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
    }
  },
  mounted () {
    this.handleFetchBoards()
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards']),
    // Handle Fetch Boards
    async handleFetchBoards () {
      this.loadingBoards = true

      try {
        await this.fetchBoards()
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      }

      this.loadingBoards = false
    }
  },
  async fetchAdvertisements (load = true) {
    this.loadingAdvertisements = load
    try {
      await this.fetchBoards()

      this.advertisements = await FirebaseAPI.Advertisements.getAllByUser()
    } catch (e) {
      console.log(e)
      this.$showAlert({
        message: e,
        type: 'error'
      })
    } finally {
      this.loadingAdvertisements = false

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    }
  }
}
</script>

<style scoped lang="sass">
.list
  display: grid
  grid-auto-columns: 1fr
  grid-column-gap: 30px
  grid-row-gap: 30px
  grid-template-columns: 1fr 1fr 1fr
  grid-template-rows: auto auto
  @apply grid

</style>
