
<template>
  <BaseCard
    class=" h-max mt-5 w-1/2 py-5 flex items-center flex-col relative"
    :no-padding="true"
  >
    <div
      class="flex items-center gap-2.5 w-full px-5 border-b border-border-normal pb-5"
    >
      <div
        class="flex items-center justify-center w-11 h-11 rounded-full border border-border-normal shadow-sm"
      >
        <img
          src="../../assets/icons/studio.svg"
          class=" rounded-full"
        >
      </div>
      <div
        class="flex flex-col justify-between"
      >
        <BaseText
          type="label"
          size="md"
          class="text-text-muted "
        >
          Learn to Use Foreplay
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal"
        >
          Tips & Tutorials for your creative workflow
        </BaseText>
      </div>
    </div>
    <div
      class="flex flex-col items-center gap-5 w-full px-5 h-full overflow-y-auto"
    >
      <div
        class="flex items-center justify-center w-full pt-5 gap-5 relative px-11"
      >
        <div
          class="absolute flex items-center justify-center pr-0.5 w-10 h-10 rounded-full border border-border-normal shadow-sm left-0 cursor-pointer hover:bg-background-hover transition-colors duration-200 select-none"
          @click="currentTab === 1 ? currentTab = 3 : currentTab--"
        >
          <img
            src="../../assets/icons/chevron-down-small.svg"
            class="rounded-full transform rotate-90"
          >
        </div>
        <div
          class="w-full rounded-md flex items-center justify-center px-2 ease-in-out transition-opacity duration-200"
          style="height: 215px"
        >
          <iframe
            v-if="currentTab === 1"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/O7h2MqJEBKU?si=C7ZceQdG3W_icXCy"
            class="rounded-md h-full transition-opacity duration-200"
            loading="lazy"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
          <iframe
            v-if="currentTab === 2"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/gUAspEVLZ4M?si=hjBJYLmb_fw5XTr7"
            class="rounded-md h-full transition-opacity duration-200"
            loading="lazy"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
          <iframe
            v-if="currentTab === 3"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/4hAAsH0eXG0?si=9fW-1trSXyb_ayip"
            class="rounded-md h-full transition-opacity duration-200"
            loading="lazy"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
        <div
          class="absolute flex items-center justify-center w-10 h-10 rounded-full border border-border-normal shadow-sm pl-0.5 right-0 cursor-pointer hover:bg-background-hover transition-colors duration-200 select-none "
          @click="currentTab === 3 ? currentTab = 1 : currentTab++"
        >
          <img
            src="../../assets/icons/chevron-down-small.svg"
            class="rounded-full transform -rotate-90"
          >
        </div>
      </div>
      <BaseText
        type="body"
        size="sm"
        class="text-text-normal transition-opacity ease-in-out duration-300"
        :class="{
          'opacity-0 h-0 overflow-hidden absolute': currentTab !== 1
        }"
      >
        Save ads from Facebook Ad Library
      </BaseText>
      <BaseText
        type="body"
        size="sm"
        class="text-text-normal transition-opacity ease-in-out duration-300"
        :class="{
          'opacity-0 h-0 overflow-hidden absolute': currentTab !== 2
        }"
      >
        Transform Ad Inspiration into actionable briefs
      </BaseText>
      <BaseText
        type="body"
        size="sm"
        class="text-text-normal transition-opacity ease-in-out duration-300"
        :class="{
          'opacity-0 h-0 overflow-hidden absolute': currentTab !== 3
        }"
      >
        Browse 500k+ ads saved by users
      </BaseText>
    </div>
    <div
      class="flex items-center justify-center w-full px-5 py-5 gap-1.5"
    >
      <div
        class="flex items-center justify-center w-1.5 h-1.5 rounded-full shadow-sm cursor-pointertransition-colors duration-300"
        :class="{
          'bg-icon-muted': currentTab === 1,
          'bg-icon-disabled': currentTab !== 1
        }"
      />
      <div
        class="flex items-center justify-center w-1.5 h-1.5 rounded-full shadow-sm cursor-pointer transition-colors duration-300"
        :class="{
          'bg-icon-muted': currentTab === 2,
          'bg-icon-disabled': currentTab !== 2
        }"
      />
      <div
        class="flex items-center justify-center w-1.5 h-1.5 rounded-full shadow-sm cursor-pointer transition-colors duration-300"
        :class="{
          'bg-icon-muted': currentTab === 3,
          'bg-icon-disabled': currentTab !== 3
        }"
      />
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: 'TutorialCard',
  data () {
    return {
      currentTab: 1
    }
  }
}
</script>

<style scoped>
</style>
