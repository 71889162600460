<template>
  <div class="sm:rounded-md sm:overflow-hidden">
    <div
      class="bg-white space-y-6 px-4 sm:p-6 py-6"
    >
      <div>
        <!-- header -->
        <h2
          id="plan-heading"
          class="text-lg leading-6 font-medium text-gray-900"
        >
          Billing Details
        </h2>
      </div>
      <div
        class="px-4 border-t py-4"
      >
        <div
          class="text-right flex flex-col"
        >
          <div class="flex justify-between mb-3">
            <!-- Left Side -->
            <div class="flex">
              <!-- House Icon -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                class="w-6 text-gray-400"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>

              <!-- Right Side Side -->
              <div class="flex flex-col items-start ml-4">
                <div class="text-lg text-gray-600">
                  Address
                </div>
                <div class="text-md text-gray-400">
                  Add or change your address
                </div>
              </div>
            </div>
            <BaseButton
              outlined
              :loading="loading"
              :disabled="loading"
              @click="openUpdateAddressModal = true"
            >
              Update Address
            </BaseButton>
          </div>
          <div class="overflow-hidden text-left max-w-xl">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-3">
                <label
                  for="country"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >Country</label>
                <input
                  :value="address.country"
                  disabled
                  type="text"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6a bg-gray-50 cursor-not-allowed"
                >
              </div>

              <div class="col-span-3">
                <!-- The Tax ID label -->
                <label
                  for="country"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >Tax ID</label>
                <!-- The Tax ID input field -->
                <input
                  :value="taxId"
                  disabled
                  type="text"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6a bg-gray-50 cursor-not-allowed"
                >
              </div>

              <div class="col-span-6">
                <!-- Address Line 1 -->
                <label
                  for="street-address"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >Street address</label>
                <input
                  disabled
                  :value="address.line1"
                  type="text"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6a bg-gray-50 cursor-not-allowed"
                >
              </div>

              <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                <label
                  for="city"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >City</label>
                <input
                  disabled
                  :value="address.city"
                  type="text"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6a bg-gray-50 cursor-not-allowed"
                >
              </div>

              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  for="region"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >State / Province</label>
                <input
                  disabled
                  :value="address.state"
                  type="text"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6a bg-gray-50 cursor-not-allowed"
                >
              </div>

              <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  for="postal-code"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >ZIP / Postal code</label>
                <input
                  disabled
                  :value="address.postal_code"
                  type="text"
                  class="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6a bg-gray-50 cursor-not-allowed"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Update Billing Details -->
      <div
        class="px-4 text-right flex justify-between items-center border-t py-4"
      >
        <div class="w-full">
          <!-- Section Header -->
          <div class="flex justify-between items-center mb-3">
            <!-- Left Side -->
            <div class="flex justify-between items-center">
              <!-- Card Icon -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                />
              </svg>

              <!-- Right Side Side -->
              <div class="flex flex-col items-start ml-4">
                <div class="text-lg text-gray-600">
                  Payment Method
                </div>
                <div class="text-md text-gray-400">
                  Add or change your billing method
                </div>
              </div>
            </div>
            <BaseButton
              outlined
              :loading="loadingPaymentUpdate"
              :disabled="loadingPaymentUpdate"
              @click="openUpdatePaymentModal = true"
            >
              Update Card
            </BaseButton>
          </div>
          <!-- Card Preview Element -->
          <div class="flex justify-start">
            <div class="w-24 h-16 bg-blue-600 p-2 flex flex-col justify-end rounded-lg">
              <!-- Card Top -->
              <div class="flex flex-col items-start justify-end">
                <!-- Chip -->
                <div class="h-3 w-4 bg-white rounded-sm" />
              </div>
              <!-- Card Bottom -->
              <div class="flex items-baseline justify-between">
                <div class="text-xs font-medium text-white mt-2">
                  {{ paymentMethod.last4 }}
                </div>
                <!--  -->
                <img
                  v-if="paymentMethod.cardBrand === 'visa'"
                  class="w-6"
                  src="../../assets/images/visa.svg"
                >
                <img
                  v-if="paymentMethod.cardBrand === 'mastercard'"
                  class="w-6"
                  src="../../assets/images/mastercard.svg"
                >
                <img
                  v-if="paymentMethod.cardBrand === 'american_express'"
                  class="w-6"
                  src="../../assets/images/americanexpress.svg"
                >
              </div>
            </div>

            <!-- Preview text -->
            <div class="flex flex-col items-start ml-4">
              <div class="text-md text-gray-600">
                {{ paymentMethod.cardBrand.charAt(0).toUpperCase() + paymentMethod.cardBrand.slice(1) }} ending in <span class="font-semibold">{{ paymentMethod.last4 }}</span>
              </div>
              <div class="text-md text-gray-400">
                Expires: {{ paymentMethod.expMonth }}/{{ paymentMethod.expYear }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpdateAddressModal
      v-if="openUpdateAddressModal"
      @close="closeAddressModal"
    />
    <UpdatePaymentMethodModal
      v-if="openUpdatePaymentModal"
      @close="closePaymentModal"
    />
  </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import firebase from '@/api/config/FirebaseConfig'
import { mapGetters } from 'vuex'
import API from '@/api'
import UpdateAddressModal from '@/components/account/subscription/UpdateAddressModal.vue'
import UpdatePaymentMethodModal from '@/components/account/subscription/UpdatePaymentMethodModal.vue'

export default {
  name: 'StripeUpdateDetails',
  components: {
    StripeElements,
    StripeElement,
    UpdateAddressModal,
    UpdatePaymentMethodModal
  },
  props: {
    popup: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      stripeKey: 'pk_live_51JS7DeAQtq0TW7KZxoGv8Spu6IJhR335IoLxdfBQMF8VCbWJFDrUl2DfeNJllyC79mZJv8kX0qiaNGFCgbrTznpA00kPK2gMwN', // test key, don't hardcode
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      cardOptions: {
        style: {
          base: {
            fontSize: '1.2rem'
          }
        }
      },
      elementsOptions: {
      },
      addressOptions: {
        mode: 'billing'
      },
      subscriptions: [],
      annualSubscriptions: [],
      selectedSubscription: null,
      loading: false,
      showAnnual: false,
      loadingPaymentUpdate: false,

      openUpdateAddressModal: false,
      openUpdatePaymentModal: false,

      // Customer Details
      address: {},
      taxId: null,
      paymentMethod: {},

      // Promo Code
      timeout: null,
      promoCode: '',
      invalidPromoCode: false,
      price: null,
      discountMessage: null,
      discountPrice: null,
      perUnitPrice: null
    }
  },
  computed: {
    ...mapGetters('AuthModule', [
      'getStripeCustomer',
      'getUserSubscription',
      'isFreeTier',
      'getUserFreeTrialDate'
    ]),
    getPriceText () {
      if (!this.selectedSubscription) { return '' }

      const priceToShow = this.discountPrice || this.price
      if (this.showAnnual) {
        return `$${this.numberWithCommas(priceToShow / 100)}/yr`
      } else {
        return `$${this.numberWithCommas(priceToShow / 100)}/mo`
      }
    },
    getSubscriptionState () {
      if (this.getUserSubscription.product) { return this.getUserSubscription.product.name }
      if (this.isFreeTier && Date.now() > this.getUserFreeTrialDate + 6.048e8) { return 'expired' }
      return 'free'
    },
    getActiveSubscription () {
      return [...this.subscriptions].find(
        (sub) => sub.name === this.getUserSubscription.product?.name
      )
    }
  },
  async mounted () {
    await this.getCustomerAddress()
    await this.getCustomerPaymentMethod()
  },
  methods: {
    closeAddressModal () {
      this.openUpdateAddressModal = false
      this.getCustomerAddress()
    },
    closePaymentModal () {
      this.openUpdatePaymentModal = false
      this.getCustomerPaymentMethod()
    },
    async getCustomerAddress () {
      const customerId = this.getStripeCustomer.stripeId

      const { address, taxId } = await API.Stripe.getCustomerAddress(customerId)

      this.address = address || {}
      this.taxId = taxId
    },
    async getCustomerPaymentMethod () {
      const customerId = this.getStripeCustomer.stripeId

      const { paymentMethod } = await API.Stripe.getCustomerPaymentMethod(customerId)
      this.paymentMethod = paymentMethod
    },
    numberWithCommas (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
