<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.99947 11V17M9.99947 11L3.89609 7.00505C2.64885 6.18867 3.22691 4.25 4.71758 4.25H15.2814C16.772 4.25 17.3501 6.18867 16.1029 7.00505L9.99947 11ZM9.99947 17H6.99948M9.99947 17H12.9995" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'AlcoholNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>