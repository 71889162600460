<template>
  <div class="px-4 text-sm w-full">
    <BaseCard
      border
      class="rounded-xl w-full bg-white"
    >
      <!-- Header -->
      <ModularDetailsSectionHeader
        :expanded="expanded"
        :section="{ type: 'description', title: 'Description' }"
        badge-text="Filled"
        :filled="editorValue.length > 7"
        @toggleExpanded="$emit('toggleExpanded')"
        @deleteSection="$emit('deleteSection')"
      />

      <div
        v-if="expanded"
        class="py-3 px-4"
      >
        <bubble-menu
          v-if="editor"
          class="border p-1 rounded bg-white grid grid-cols-7 gap-1"
          :editor="editor"
          :tippy-options="{ duration: 100 }"
        >
          <!-- Bold -->
          <button
            :class="{ 'is-active': editor.isActive('bold') }"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <!-- Bold Icon -->
            <svg
              class="remix h-4 w-4"
            >
              <use :xlink:href="`${remixiconUrl}#ri-bold`" />
            </svg>
          </button>

          <!-- Italics -->
          <button
            :class="{ 'is-active': editor.isActive('italic') }"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <!-- Italic Icon -->
            <svg
              class="remix h-4 w-4"
            >
              <use :xlink:href="`${remixiconUrl}#ri-italic`" />
            </svg>
          </button>

          <!-- Hyperlink -->
          <button
            :class="{ 'is-active': editor.isActive('link') }"
            @click="editor.chain().focus().toggleLink().run()"
          >
            <!-- Link Icon -->
            <svg
              class="remix h-4 w-4"
            >
              <use :xlink:href="`${remixiconUrl}#ri-link`" />
            </svg>
          </button>

          <!-- Divider -->
          <div class="text-gray-400 w-full text-center">
            |
          </div>

          <!-- H1 -->
          <button
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          >
            <!-- H1 Icon -->
            <svg
              class="remix h-4 w-4"
            >
              <use :xlink:href="`${remixiconUrl}#ri-h-1`" />
            </svg>
          </button>

          <!-- H2 -->
          <button
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            <!-- H2 Icon -->
            <svg
              class="remix h-4 w-4"
            >
              <use :xlink:href="`${remixiconUrl}#ri-h-2`" />
            </svg>
          </button>

          <!-- H3 -->
          <button
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            <!-- H2 Icon -->
            <svg
              class="remix h-4 w-4"
            >
              <use :xlink:href="`${remixiconUrl}#ri-h-3`" />
            </svg>
          </button>
        </bubble-menu>
        <editor-content
          :editor="editor"
          class="editor__content h-full"
        />
      </div>
    </BaseCard>
  </div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-2'
import Link from '@tiptap/extension-link'
import Heading from '@tiptap/extension-heading'
import ModularDetailsSectionHeader from './ModularDetailsSectionHeader.vue'

export default {
  components: {
    EditorContent,
    BubbleMenu,
    ModularDetailsSectionHeader
  },
  props: {
    value: {
      type: String,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      editor: null,
      isEditable: true,
      remixiconUrl,
      editorValue: null,
      timeout: null
    }
  },
  watch: {
    isEditable (value) {
      this.editor.setEditable(value)
    }
  },
  mounted () {
    this.editorValue = this.value || '<p></p>'

    this.editor = new Editor({
      extensions: [
        StarterKit,
        Link.configure({
          HTMLAttributes: {
            class: 'text-blue-600 cursor-pointer'
          }
        }),
        Heading.configure({
          levels: [1, 2, 3],
          HTMLAttributes: {
            class: 'heading-class'
          }
        })
      ],
      content: this.editorValue,
      onUpdate: () => {
        const currentEditorValue = this.editor.getHTML()
        this.editorValue = currentEditorValue

        this.updateField()
      }
    })
  },
  methods: {
    emitUpdate () {
      this.$emit('saveSection', {
        section: 'notes',
        value: this.editorValue
      })
    },
    updateField () {
      if (this.timeout) { clearTimeout(this.timeout) }

      this.timeout = setTimeout(() => {
        this.$emit('update', this.index, this.editorValue)
      }, 500)
    }
  },
  beforeUnmount () {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }
}

.editor {
  display: flex;
  flex-direction: column;
  color: #0D0D0D;
  background-color: #FFF;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
  }

  &__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

input[type="checkbox"] {
  margin-right: 4px;
}

.ProseMirror:focus {
    outline: none !important;
}
</style>
