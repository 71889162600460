import { render, staticRenderFns } from "./RealEstateNicheIcon.vue?vue&type=template&id=009b219a&scoped=true"
import script from "./RealEstateNicheIcon.vue?vue&type=script&lang=js"
export * from "./RealEstateNicheIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009b219a",
  null
  
)

export default component.exports