// Misc Module
// For things that don't fit in other modules

export default {
  namespaced: true,
  state: {
    // App style (this is set in the router)
    theme: 'blue',
    lastTheme: 'blue',
    themeChangedAt: 0
  },
  getters: {
    // Get Theme color
    getTheme: state => {
      console.log('getting theme')

      return state.theme
    },
    // Get the previous theme to transtion from
    getLastTheme: state => state.lastTheme,
    // Last time the theme was updated
    getThemeChangedAt: state => state.themeChangedAt,
    // Get UTM_source UTM_medium UTM_campaign for tracking purposes
    getUtmSource: state => state.utmSource,
    getUtmMedium: state => state.utmMedium,
    getUtmCampaign: state => state.utmCampaign
  },
  mutations: {
    SET_THEME (state, theme) {
      console.log('setting theme')
      state.themeChangedAt = +new Date()
      state.lastTheme = state.theme
      state.theme = theme

      // Update intercom theme
      // const intercomLauncher = document.getElementsByClassName('intercom-launcher')[0]

      // if (theme === 'blue') {
      //   intercomLauncher.style.backgroundColor = ''
      // } else {
      //   intercomLauncher.style.backgroundColor = '#9333ea'
      // }
    },
    // set UTM_source UTM_medium UTM_campaign for tracking purposes
    SET_UTM_SOURCE (state, utmSource) {
      state.utmSource = utmSource
    },
    SET_UTM_MEDIUM (state, utmMedium) {
      state.utmMedium = utmMedium
    },
    SET_UTM_CAMPAIGN (state, utmCampaign) {
      state.utmCampaign = utmCampaign
    }
  },
  actions: {}
}
