<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7 8.5V10M7 10V11.5M7 10H8.5M7 10H5.5M5.5 5.5H14.5C16.1568 5.5 17.5 6.84314 17.5 8.5V11.5C17.5 13.1568 16.1568 14.5 14.5 14.5H5.5C3.84314 14.5 2.5 13.1568 2.5 11.5V8.5C2.5 6.84314 3.84314 5.5 5.5 5.5Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.4062 11.125C11.4062 11.4874 11.7001 11.7812 12.0625 11.7812C12.4249 11.7812 12.7188 11.4874 12.7188 11.125C12.7188 10.7626 12.4249 10.4688 12.0625 10.4688C11.7001 10.4688 11.4062 10.7626 11.4062 11.125ZM13.6562 8.875C13.6562 9.2374 13.9501 9.53125 14.3125 9.53125C14.6749 9.53125 14.9688 9.2374 14.9688 8.875C14.9688 8.5126 14.6749 8.21875 14.3125 8.21875C13.9501 8.21875 13.6562 8.5126 13.6562 8.875Z" 
    :fill="stroke" :stroke="stroke" stroke-width="0.5625" stroke-linecap="square"/>
  </svg>
</template>

<script>
  export default {
    name: 'GamesNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>