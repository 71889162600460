<template>
  <div class="px-4 w-full text-gray-600">
    <BaseCard
      border
      class="rounded-xl w-full bg-white"
    >
      <ModularDetailsSectionHeader
        :expanded="expanded"
        :section="{ type: 'contentStyle', title: 'Content Style' }"
        :badge-text="`${selectedStyles.length} Selected`"
        :filled="!!selectedStyles.length"
        @toggleExpanded="$emit('toggleExpanded')"
        @deleteSection="$emit('deleteSection')"
      />

      <div
        v-if="expanded"
        class="flex flex-wrap gap-2 px-4 py-4"
      >
        <!-- UGC -->
        <ModularDetailsToggleOption
          :selected="selectedStyles.includes('ugc')"
          @click="toggleSelected('ugc')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 3.75L5.75 3.75C4.64543 3.75 3.75 4.64543 3.75 5.75L3.75 18.25C3.75 19.3546 4.64543 20.25 5.75 20.25H7.25647M20.25 12.75L20.25 18.25C20.25 19.3546 19.3546 20.25 18.25 20.25H16.7435M7.25647 20.25C7.38647 17.7429 9.46051 15.75 12 15.75C14.5395 15.75 16.6135 17.7429 16.7435 20.25M7.25647 20.25L16.7435 20.25M19 1.75L20.0833 3.91667L22.25 5L20.0833 6.08333L19 8.25L17.9167 6.08333L15.75 5L17.9167 3.91667L19 1.75ZM14.75 10.5C14.75 12.0188 13.5188 13.25 12 13.25C10.4812 13.25 9.25 12.0188 9.25 10.5C9.25 8.98122 10.4812 7.75 12 7.75C13.5188 7.75 14.75 8.98122 14.75 10.5Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            UGC
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- Lifestyle -->
        <ModularDetailsToggleOption
          :selected="selectedStyles.includes('lifestyle')"
          @click="toggleSelected('lifestyle')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.25 9.9375C21.25 15.8672 12.7708 20.25 12 20.25C11.2292 20.25 2.75 15.8672 2.75 9.9375C2.75 5.8125 5.31944 3.75 7.88889 3.75C10.4583 3.75 12 5.29687 12 5.29687C12 5.29687 13.5417 3.75 16.1111 3.75C18.6806 3.75 21.25 5.8125 21.25 9.9375Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </svg>

          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Lifestyle
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- Animation -->
        <ModularDetailsToggleOption
          :selected="selectedStyles.includes('animation')"
          @click="toggleSelected('animation')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 5.75C3 4.23122 4.23122 3 5.75 3L18.25 3C19.7688 3 21 4.23122 21 5.75L21 18.25C21 19.7688 19.7688 21 18.25 21L5.75 21C4.23122 21 3 19.7688 3 18.25L3 5.75ZM5.75 4.5C5.05964 4.5 4.5 5.05964 4.5 5.75L4.5 14.4393L6.05546 12.8839C7.1294 11.8099 8.8706 11.8099 9.94454 12.8839L16.5607 19.5H18.25C18.9404 19.5 19.5 18.9404 19.5 18.25L19.5 5.75C19.5 5.05964 18.9404 4.5 18.25 4.5L5.75 4.5ZM14.4393 19.5L8.88388 13.9445C8.39573 13.4564 7.60427 13.4564 7.11612 13.9445L4.5 16.5607L4.5 18.25C4.5 18.9404 5.05964 19.5 5.75 19.5L14.4393 19.5Z"
              fill="currentColor"
            />
            <path
              d="M13.4255 8.53727C13.4738 8.51308 13.5131 8.47385 13.5373 8.42546L14.2764 6.94721C14.3685 6.76295 14.6315 6.76295 14.7236 6.94721L15.4627 8.42546C15.4869 8.47385 15.5262 8.51308 15.5745 8.53727L17.0528 9.27639C17.237 9.36852 17.237 9.63148 17.0528 9.72361L15.5745 10.4627C15.5262 10.4869 15.4869 10.5262 15.4627 10.5745L14.7236 12.0528C14.6315 12.237 14.3685 12.237 14.2764 12.0528L13.5373 10.5745C13.5131 10.5262 13.4738 10.4869 13.4255 10.4627L11.9472 9.72361C11.763 9.63148 11.763 9.36852 11.9472 9.27639L13.4255 8.53727Z"
              fill="currentColor"
            />
          </svg>

          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Animation
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- Studio -->
        <ModularDetailsToggleOption
          :selected="selectedStyles.includes('studio')"
          @click="toggleSelected('studio')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.74775 15.4838L8.74775 17.25C8.74775 17.8023 9.19547 18.25 9.74775 18.25H14.2501C14.8024 18.25 15.2501 17.8023 15.2501 17.25V15.4838M8.74775 15.4838C8.33524 15.2765 7.94523 15.031 7.58239 14.7519C5.85882 13.4264 4.74805 11.3433 4.74805 9.00089C4.74805 4.99633 7.99438 1.75 11.9989 1.75C16.0035 1.75 19.2498 4.99633 19.2498 9.00089C19.2498 11.3433 18.139 13.4264 16.4155 14.7519C16.0526 15.031 15.6626 15.2765 15.2501 15.4838M8.74775 15.4838L15.2501 15.4838M9.74982 21.25H14.2498"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Studio
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- Commercial -->
        <ModularDetailsToggleOption
          :selected="selectedStyles.includes('commercial')"
          @click="toggleSelected('commercial')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.75 8.25H5.75M18.25 15.75L19.25 15.75M1.75 16.75L1.75 7.25C1.75 6.14543 2.64543 5.25 3.75 5.25L20.25 5.25C21.3546 5.25 22.25 6.14543 22.25 7.25L22.25 16.75C22.25 17.8546 21.3546 18.75 20.25 18.75L3.75 18.75C2.64543 18.75 1.75 17.8546 1.75 16.75ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Commercial
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- Editorial -->
        <ModularDetailsToggleOption
          :selected="selectedStyles.includes('editorial')"
          @click="toggleSelected('editorial')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.25 12V14.75C19.25 15.8546 18.3546 16.75 17.25 16.75H7C5.75736 16.75 4.75 17.7574 4.75 19C4.75 20.2426 5.75736 21.25 7 21.25H10M8.75 7L15.25 7M8.75 11L12.25 11M6.75 2.75L17.25 2.75C18.3546 2.75 19.25 3.64543 19.25 4.75V19.25C19.25 20.3546 18.3546 21.25 17.25 21.25L6.75 21.25C5.64543 21.25 4.75 20.3546 4.75 19.25L4.75 4.75C4.75 3.64543 5.64543 2.75 6.75 2.75Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Editorial
          </BaseText>
        </ModularDetailsToggleOption>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import ModularDetailsSectionHeader from './ModularDetailsSectionHeader.vue'
import ModularDetailsToggleOption from './ModularDetailsToggleOption.vue'

export default {
  name: 'ContentStyle',
  components: {
    ModularDetailsSectionHeader,
    ModularDetailsToggleOption
  },
  props: {
    value: {
      type: Array,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      selectedStyles: []
    }
  },
  computed: {
  },
  mounted () {
    this.selectedStyles = this.value
  },
  methods: {
    getContainerClasses (section) {
      if (this.selectedStyles.includes(section)) {
        return 'border-green-600 bg-green-100 text-green-600 cursor-pointer'
      }

      return 'text-gray-700 hover:border-gray-500 cursor-pointer'
    },
    toggleSelected (style) {
      if (this.selectedStyles.includes(style)) {
        this.selectedStyles = this.selectedStyles.filter(s => s !== style)
      } else {
        this.selectedStyles.push(style)
      }

      this.$emit('update', this.index, this.selectedStyles)
    }
  }
}
</script>

<style scoped lang="css">
.show-on-hover {
  display: none
}

.animate-on-hover:hover .show-on-hover {
  display: block
}

.animate-on-hover:hover .hide-on-hover {
  display: none
}
</style>
