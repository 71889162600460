import { render, staticRenderFns } from "./BaseInputV2.vue?vue&type=template&id=06a9490a&scoped=true"
import script from "./BaseInputV2.vue?vue&type=script&lang=js"
export * from "./BaseInputV2.vue?vue&type=script&lang=js"
import style0 from "./BaseInputV2.vue?vue&type=style&index=0&id=06a9490a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a9490a",
  null
  
)

export default component.exports