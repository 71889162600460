// Import
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

const get = async (brandId) => {
  const brand = await db.collection('brands').doc(brandId).get()

  return {
    id: brandId,
    ...brand.data()
  }
}

export default {
  get
}
