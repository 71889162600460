<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M3.25 8.94729H16.75M4.75 15.2631H15.25C16.0784 15.2631 16.75 14.5562 16.75 13.6841V5.7894C16.75 4.91737 16.0784 4.21045 15.25 4.21045H4.75C3.92157 4.21045 3.25 4.91737 3.25 5.7894V13.6841C3.25 14.5562 3.92157 15.2631 4.75 15.2631Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.5 7.17103C5.81066 7.17103 6.0625 6.90593 6.0625 6.57892C6.0625 6.25191 5.81066 5.98682 5.5 5.98682C5.18934 5.98682 4.9375 6.25191 4.9375 6.57892C4.9375 6.90593 5.18934 7.17103 5.5 7.17103ZM7.75 7.17103C8.06066 7.17103 8.3125 6.90593 8.3125 6.57892C8.3125 6.25191 8.06066 5.98682 7.75 5.98682C7.43934 5.98682 7.1875 6.25191 7.1875 6.57892C7.1875 6.90593 7.43934 7.17103 7.75 7.17103ZM10 7.17103C10.3107 7.17103 10.5625 6.90593 10.5625 6.57892C10.5625 6.25191 10.3107 5.98682 10 5.98682C9.68935 5.98682 9.4375 6.25191 9.4375 6.57892C9.4375 6.90593 9.68935 7.17103 10 7.17103Z" 
    :fill="stroke" :stroke="stroke" stroke-width="0.375"/>
  </svg>
</template>

<script>
  export default {
    name: 'SoftwareNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>