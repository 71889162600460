<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M8.13978 7.375L8.48594 16.375M11.861 7.375L11.5148 16.375M4.48374 7.84537L5.45751 15.4407C5.55348 16.1893 6.19065 16.75 6.94533 16.75H13.0554C13.8101 16.75 14.4473 16.1893 14.5432 15.4407L15.517 7.84537C15.5745 7.39697 15.2252 7 14.7731 7H5.22765C4.77558 7 4.42626 7.39697 4.48374 7.84537Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.2373 6.99956C15.3226 6.36502 14.9472 5.74665 14.3574 5.50774C13.9799 5.3548 13.6903 5.07477 13.5317 4.69891C13.2816 4.10676 12.6426 3.75547 12.0088 3.83716C11.601 3.88935 11.2086 3.78909 10.879 3.54244C10.3657 3.15643 9.63378 3.15643 9.12048 3.54244C8.80915 3.7766 8.4144 3.89325 8.02614 3.8432C7.37739 3.75956 6.72597 4.08698 6.46725 4.70075C6.30903 5.07606 6.01938 5.35619 5.64238 5.50865C5.05247 5.74719 4.67679 6.36546 4.76202 6.99997" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'EntertainmentNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>