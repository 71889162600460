<template>
  <div
    class="flex items-start w-full justify-center"
    :class="`my-${margin}`"
  >
    <lottie
      :options="defaultOptions"
      :height="small ? 20 : 24"
      :width="small ? 20 : 24"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../assets/lotties/button-success.json'

export default {
  name: 'BaseLoadingLogo',
  components: {
    lottie: Lottie
  },
  props: {
    small: {
      type: Boolean
    },
    margin: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      defaultOptions: { animationData: animationData, loop: false, autoplay: true }
    }
  },
  computed: {
  }
}
</script>
