<template>
  <!-- Base Modal -->
  <div class="base-modal fixed flex items-center justify-center left-0 right-0 bottom-0 top-0">
    <div
      v-on-clickaway="() => $emit('close')"
      class="base-modal--card"
      :style="`width: ${cardWidth}`"
    >
      <!-- Modal Toolbar -->
      <div
        v-if="!noToolbar"
        class="base-modal--toolbar"
      >
        <slot name="toolbar" />

        <svg
          v-if="!noExit"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          class="my-auto mt-4 cursor-pointer base-modal--toolbar--close"
          @click="$emit('close')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M3.46875 3.02173L12.5312 12.0842M12.5312 3.02173L3.46875 12.0842"
              stroke="#5E6678"
              stroke-width="0.9375"
              stroke-linecap="round"
            />
          </svg>
        </svg>
      </div>

      <!-- Modal Content -->
      <div
        class="base-modal--content"
        :class="{ 'p-0': noPadding, 'py-5': !noPadding }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'

export default {
  name: 'BaseModal',
  mixins: [clickaway],
  props: {
    noExit: {
      type: Boolean
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    noToolbar: {
      type: Boolean
    },
    cardWidth: {
      type: String,
      default: '600px'
    },
    noToolbarPadding: {
      type: Boolean
    }
  },
  mounted () {
    document.body.style.position = 'fixed'
    document.body.style.top = `-${window.scrollY}px`
  },
  destroyed () {
    document.body.style.position = ''
    document.body.style.top = ''
  }
}
</script>

<style scoped lang="sass">
.base-modal
  background-color: rgba(0, 0, 0, 0.7)
  // min-height: calc(100vh - 80px)
  z-index: 500000

  &--card
    animation: slideBottom 300ms ease-in-out
    max-width: calc(100% - 20px)
    max-height: calc(100vh - 90px)
    @apply bg-white flex flex-col items-center relative rounded-xl

  &--toolbar
    @apply bg-white border-t border-r border-l rounded-t-xl border-white flex items-center justify-between pt-4 px-5 top-0 w-full border-b

    &--close
      height: 20px
      width: 20px
      @apply cursor-pointer text-black flex items-center justify-center h-full mb-auto text-gray-400

  &--content
    @apply h-full w-full

  &--actions
    @apply border-t border-gray-300 flex justify-end px-5 py-4 w-full

@keyframes slideBottom
  0%
    opacity: 0
    transform: translateY(20px)

  100%
    opacity: 1
</style>
