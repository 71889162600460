<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M16.75 10H10M16.75 10C16.75 6.27208 13.728 3.25 10 3.25C6.27208 3.25 3.25 6.27208 3.25 10M16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10M10 10H3.25M10 10V3.625M10 10V16.375M5.28124 5.5C6.35008 6.6942 7 8.27121 7 10C7 11.7288 6.35008 13.3058 5.28124 14.5M14.7188 5.5C13.65 6.6942 13 8.27121 13 10C13 11.7288 13.65 13.3058 14.7188 14.5" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'SportsNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>