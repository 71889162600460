<template>
  <lottie 
    :options="defaultOptions"
    :width="width"
    :height="height"
    @animCreated="handleAnimation"
  />
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../assets/lotties/saveAd.json'

export default {
  name: 'SaveAdAnimatedIcon',
  components: {
    lottie: Lottie
  },
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    doAnimate: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      defaultOptions: {
        animationData: animationData,
        loop: false,
        autoplay: false,
      }
    }
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim;
      this.anim.setSpeed(1.25);
      if (this.doAnimate) {
        this.anim.play();
      } else {
        const lastFrame = this.anim.totalFrames - 1;
        this.anim.goToAndStop(lastFrame, true);
      }
    }
  }
}
</script>