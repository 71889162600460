<template>
  <svg
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 122.88"
    :style="{ transform: `scale(${scale})` }"
  >
    <title>twitter-square-color</title>
    <path
      class="cls-1"
      d="M25.2,0H97.68a25.27,25.27,0,0,1,25.2,25.2V97.68a25.27,25.27,0,0,1-25.2,25.2H25.2A25.27,25.27,0,0,1,0,97.68V25.2A25.27,25.27,0,0,1,25.2,0Z"
    />
    <path
      class="cls-2"
      d="M97.52,39.06A29.27,29.27,0,0,1,89,41.39a15,15,0,0,0,6.51-8.19,29.71,29.71,0,0,1-9.4,3.59,14.81,14.81,0,0,0-25.6,10.13,14.45,14.45,0,0,0,.38,3.37A42,42,0,0,1,30.41,34.82a14.86,14.86,0,0,0-2,7.44h0A14.76,14.76,0,0,0,35,54.57a14.85,14.85,0,0,1-6.71-1.84v.19A14.8,14.8,0,0,0,40.15,67.43a14.74,14.74,0,0,1-3.9.52,16.2,16.2,0,0,1-2.8-.26A14.85,14.85,0,0,0,47.28,78,29.86,29.86,0,0,1,25.35,84.1a41.92,41.92,0,0,0,22.7,6.65c27.23,0,42.13-22.56,42.13-42.12,0-.65,0-1.28,0-1.91a29.83,29.83,0,0,0,7.38-7.65h0Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'TwitterIcon',
  props: {
    width: {
      type: Number,
      default: () => 15
    }
  },
  computed: {
    scale () {
      return this.width / 23
    }
  }
}
</script>
<style scoped lang="css">
.cls-1 {
  fill: #1da1f2;
}
.cls-1,
.cls-2 {
  fill-rule: evenodd;
}
.cls-2 {
  fill: #fff;
}
</style>
