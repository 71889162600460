<template>
  <div
    class="group border-b border-border-normal py-3 px-4 cursor-pointer"
    @click="$emit('toggleExpanded')"
  >
    <!-- Title -->
    <div class="flex items-center w-full gap-2 overflow-hidden">
      <!-- Icon -->
      <img
        v-if="section.type === 'description'"
        src="../../../assets/icons/document.svg"
      >
      <img
        v-if="section.type === 'aspectRatio'"
        src="../../../assets/icons/size.svg"
      >
      <img
        v-if="section.type === 'format'"
        src="../../../assets/icons/file.svg"
      >
      <img
        v-if="section.type === 'targetDuration'"
        src="../../../assets/icons/clock2.svg"
      >
      <img
        v-if="section.type === 'platforms'"
        src="../../../assets/icons/flag.svg"
      >
      <img
        v-if="section.type === 'contentStyle'"
        src="../../../assets/icons/wand.svg"
      >
      <img
        v-if="section.type === 'products'"
        src="../../../assets/icons/archive.svg"
      >
      <img
        v-if="section.type === 'assets'"
        src="../../../assets/icons/file-chain.svg"
      >
      <img
        v-if="section.type === 'audio'"
        src="../../../assets/icons/note.svg"
      >
      <img
        v-if="section.type === 'loomLinks'"
        src="../../../assets/icons/loom.svg"
      >

      <!-- Title -->
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted mr-1.5"
      >
        {{ section.title }}
      </BaseText>
      <BaseBadge
        class="transition transform"
        :class="filled && !expanded ? 'opacity-100' : 'opacity-0'"
        green
      >
        <BaseText
          type="label"
          size="xs"
        >
          {{ badgeText }}
        </BaseText>
      </BaseBadge>
      <div class="flex items-center ml-auto gap-4">
        <!-- Garbage Can -->
        <img
          class="opacity-0 group-hover:opacity-100 transition transform"
          src="../../../assets/icons/trash.svg"
          @click="$emit('deleteSection')"
        >

        <!-- Chevron -->
        <img
          class="ml-auto transition duration-300 transform"
          :class="expanded ? 'transform rotate-180' : ''"
          src="../../../assets/icons/chevron-down-small.svg"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModularDetailsSectionHeader',
  props: {
    section: {
      type: Object,
      required: true
    },
    badgeText: {
      type: String,
      default: ''
    },
    filled: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  }
}
</script>
