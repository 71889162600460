export function getHook (ad) {
  // Extracts the hook from an ad (the first sentence or two of the transcription)

  const firstSentence = ad.timestampedTranscription[0]?.sentence || ''
  const secondSentence = ad.timestampedTranscription[1]?.sentence || ''
  const sentenceEndingRegex = /(?<!Dr|Mr|Ms|Mrs)[.!?]/
  const firstSentenceEndIndex = firstSentence.search(sentenceEndingRegex)
  if (firstSentenceEndIndex !== -1) {
    return firstSentence.substring(0, firstSentenceEndIndex + 1)
  } else {
    const secondSentenceEndIndex = secondSentence.search(sentenceEndingRegex)
    if (secondSentenceEndIndex !== -1) {
      return firstSentence + secondSentence.substring(0, secondSentenceEndIndex + 1)
    } else {
      return firstSentence + secondSentence + '...'
    }
  }
}