<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M13.9585 11.0416L15.0002 9.99995C16.3809 8.61924 16.3809 6.38066 15.0002 4.99995C13.6195 3.61924 11.3809 3.61924 10.0002 4.99995L8.9585 6.04162" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.04156 8.95831L4.99989 9.99998C3.61918 11.3807 3.61918 13.6193 4.99989 15C6.3806 16.3807 8.61918 16.3807 9.99989 15L11.0416 13.9583" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.875 8.125L8.125 11.875" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'LinkRoundedIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>