<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1193_192)">
      <path
        d="M12.4452 4.3193H10.65L11.2427 2.10869C11.3272 1.79373 11.1405 1.46935 10.8249 1.38485C10.5094 1.30034 10.185 1.48763 10.1011 1.8026L9.42691 4.3193H5.23021L5.82292 2.10869C5.90743 1.79373 5.72068 1.46935 5.40517 1.38485C5.08955 1.30099 4.76582 1.48763 4.68186 1.8026L4.00702 4.3193H1.80886C1.48264 4.3193 1.21799 4.58342 1.21799 4.91018C1.21799 5.23704 1.48264 5.50116 1.80886 5.50116H3.68968L2.73894 9.04661H0.627006C0.300895 9.04661 0.0361328 9.31072 0.0361328 9.63748C0.0361328 9.96424 0.300895 10.2284 0.627006 10.2284H2.42224L1.82953 12.439C1.74502 12.7539 1.93177 13.0783 2.24728 13.1628C2.29813 13.1764 2.34953 13.1829 2.39973 13.1829C2.66038 13.1829 2.89972 13.0092 2.97005 12.7451L3.64542 10.2284H7.84202L7.24931 12.439C7.16491 12.7539 7.35155 13.0783 7.66717 13.1628C7.71791 13.1764 7.76931 13.1829 7.82016 13.1829C8.08081 13.1829 8.32004 13.0092 8.39037 12.7451L9.0652 10.2284H11.2634C11.5896 10.2284 11.8544 9.96424 11.8544 9.63748C11.8544 9.31072 11.5896 9.04661 11.2634 9.04661H9.38255L10.3333 5.50116H12.4452C12.7714 5.50116 13.0361 5.23704 13.0361 4.91018C13.0361 4.58342 12.7714 4.3193 12.4452 4.3193V4.3193ZM8.15872 9.04661H3.96212L4.91286 5.50116H9.10957L8.15872 9.04661Z"
        :class="transitionClass"
      />
    </g>
    <defs>
      <clipPath id="clip0_1193_192">
        <rect
          :width="width*7.69"
          :height="width*7.69"
          fill="white"
          transform="translate(0.0361328 0.773682)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FolderIcon',
  props: {
    selected: {
      type: Boolean
    },
    noTransition: {
      type: Boolean
    },
    width: {
      type: Number,
      default: () => 14
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme', 'getLastTheme', 'getThemeChangedAt']),
    transitionClass () {
      let classStr = ''
      const colorClass = this.selected ? 'text-white' : `text-${this.getTheme}-logo`

      if (+new Date() - this.getThemeChangedAt < 1000 && !this.noTransition) {
        console.log('were in this condition')
        // So buttons mounted after the theme changes dont animate
        classStr += ` ${this.getLastTheme}-to-${this.getTheme}`
      }

      classStr += ` fill-current ${colorClass}`

      return classStr
    },
    fill () {
      if (this.getTheme === 'purple') {
        return '#834BB5'
      }

      return '#3462DA'
    }
  }
}
</script>
<style scoped lang="css">
/* Blue to Purple Animations */
.blue-to-purple {animation: b-to-p 500ms ease-in; animation-iteration-count: 1; }

@keyframes b-to-p {
  0% { color: #3A6FFB; }
  100% { color: #834BB5; }
}

/* Purple to Blue Animations */
.purple-to-blue {animation: p-to-b 500ms ease-in; animation-iteration-count: 1;}

@keyframes p-to-b {
  0% { color: #834BB5; }
  100% { color: #3A6FFB; }
}

/* Purple to Green Animations */
.purple-to-green {animation: p-to-g 500ms ease-in; animation-iteration-count: 1;}

@keyframes p-to-g {
  0% { color: #834BB5; }
  100% { color: #14C78C; }
}

/* Blue to Green Animations */
.blue-to-green {animation: b-to-g 500ms ease-in; animation-iteration-count: 1;}

@keyframes b-to-g {
  0% { color: #3A6FFB; }
  100% { color: #14C78C; }
}

/* Green to Purple Animations */
.green-to-purple {animation: g-to-p 500ms ease-in; animation-iteration-count: 1;}

@keyframes g-to-p {
  0% { color: #14C78C; }
  100% { color: #834BB5; }
}

/* Green to Blue Animations */
.green-to-blue {animation: g-to-b 500ms ease-in; animation-iteration-count: 1;}

@keyframes g-to-b {
  0% { color: #14C78C; }
  100% { color: #3A6FFB; }
}
</style>
