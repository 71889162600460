<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4 5.49998V14.5M7 4.74998V15.25M10 5.54615V14.4538C10 15.4297 10.9171 16.1457 11.8638 15.909L14.8638 15.159C15.5315 14.9921 16 14.3922 16 13.7038V6.29615C16 5.60785 15.5315 5.00787 14.8638 4.84094L11.8638 4.09094C10.9171 3.85425 10 4.57029 10 5.54615Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'OtherNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>