<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.99994 5.87463C8.32789 5.87463 7.74994 4.06885 7.74994 4.06885L3.76855 5.77516C3.05149 6.08247 2.6897 6.88812 2.9364 7.62822L3.10578 8.13635C3.38461 8.97287 4.32136 9.38957 5.12936 9.03655L5.49994 8.87462V14.4996C5.49994 15.3281 6.17152 15.9996 6.99994 15.9996H12.9999C13.8284 15.9996 14.4999 15.3281 14.4999 14.4996V8.87462L14.8705 9.03655C15.6785 9.38957 16.6152 8.97287 16.8941 8.13635L17.0635 7.62822C17.3102 6.88812 16.9484 6.08247 16.2313 5.77516L12.2499 4.06885C12.2499 4.06885 11.672 5.87463 9.99994 5.87463Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'FashionNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>