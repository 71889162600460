// Imports
import AdvertisementsModule from './AdvertisementsModule'
import AuthModule from './AuthModule'
import BoardsModule from './BoardsModule'
import RouterModule from './RouterModule'
import SpyderModule from './SpyderModule'
import BriefsModule from './BriefsModule'
import MiscModule from './MiscModule'
import TagsModule from './TagsModule'

export default {
  AdvertisementsModule,
  AuthModule,
  BoardsModule,
  BriefsModule,
  MiscModule,
  RouterModule,
  SpyderModule,
  TagsModule
}
