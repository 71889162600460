<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.25 4.75H10.75M7 17.5H13C13.8284 17.5 14.5 16.8284 14.5 16V4C14.5 3.17157 13.8284 2.5 13 2.5H7C6.17157 2.5 5.5 3.17157 5.5 4V16C5.5 16.8284 6.17157 17.5 7 17.5Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'TechNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>