<template>
  <div class="px-4 w-full">
    <BaseCard
      border
      class="rounded-xl w-full bg-white"
    >
      <ModularDetailsSectionHeader
        :expanded="expanded"
        :section="{ type: 'targetDuration', title: 'Target Duration' }"
        badge-text="Filled"
        :filled="!!(timeValue.hh.length || timeValue.mm.length || timeValue.ss.length)"
        @toggleExpanded="$emit('toggleExpanded')"
        @deleteSection="$emit('deleteSection')"
      />

      <div v-if="expanded">
        <div class="flex items-center flex-wrap gap-2 px-4 py-3">
          <!-- Hours -->
          <BaseInputV2
            class="w-10"
            icon
            :value="timeValue.hh"
            :maxlength="2"
            no-padding
            placeholder="00"
            @input="updateField('hh', $event)"
          />
          <BaseText
            type="body"
            size="sm"
            class="text-text-subdued"
          >
            hr
          </BaseText>

          <!-- Mins -->
          <BaseInputV2
            class="w-10 ml-5"
            icon
            no-padding
            :maxlength="2"
            :value="timeValue.mm"
            placeholder="00"
            @input="updateField('mm', $event)"
          />
          <BaseText
            type="body"
            size="sm"
            class="text-text-subdued"
          >
            min
          </BaseText>

          <!-- Seconds -->
          <BaseInputV2
            class="w-10 ml-5"
            icon
            :value="timeValue.ss"
            no-padding
            :maxlength="2"
            placeholder="00"
            @input="updateField('ss', $event)"
          />
          <BaseText
            type="body"
            size="sm"
            class="text-text-subdued"
          >
            sec
          </BaseText>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import ModularDetailsSectionHeader from './ModularDetailsSectionHeader.vue'

export default {
  name: 'TargetDuration',
  components: {
    ModularDetailsSectionHeader
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      shared: false,
      timeValue: { hh: '', mm: '', ss: '' },
      timeout: null
    }
  },
  computed: {
  },
  mounted () {
    this.timeValue = this.value
  },
  methods: {
    updateField (field, newValue) {
      if (this.timeout) { clearTimeout(this.timeout) }

      const cloneArr = this.timeValue
      this.timeValue[field] = newValue

      this.timeValue = cloneArr

      this.timeout = setTimeout(() => {
        this.$emit('update', this.index, this.timeValue)
      }, 500)
    }
  }
}
</script>

<style scoped lang="css">
</style>
