<template>
  <!-- Base Input -->
  <div class="base-input">
    <!-- Label -->
    <div
      v-if="label"
      class="base-input--label"
    >
      {{ label }}
    </div>

    <div
      class="base-input--wrapper"
      :class="{ 'border-blue-500': isFocused, 'border-gray-300': !isFocused, 'border-red-500': error }"
    >
      <div
        v-if="icon"
        class="base-input--icon"
      >
        <slot name="icon" />
      </div>

      <!-- Input -->
      <input
        class="base-input--input"
        :type="inputType"
        :required="required"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="{ 'rounded-r': icon, 'rounded': !icon, 'py-0' : inputType === 'password' }"
        @input="updateValue"
        @focus="isFocused = true"
        @blur="isFocused = false"
      >
    </div>

    <!-- Error Message -->
    <div
      v-if="error"
      class="base-input--error-message"
    >
      {{ error }}
    </div>

    <!-- Hint -->
    <div
      v-else-if="hint"
      class="base-input--hint"
      :class="{ 'text-blue-500': isFocused, 'text-gray-500': !isFocused }"
    >
      {{ hint }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    // Model
    value: {
      type: [String, Number],
      default: ''
    },
    // Label
    label: {
      type: String,
      default: ''
    },
    // Input Type
    inputType: {
      type: String
    },
    hasError: {
      type: Boolean,
      required: false
    },
    required: {
      type: String,
      required: false
    },
    // Error Message
    error: {
      type: String,
      default: ''
    },
    // Input Placeholder
    placeholder: {
      type: String,
      default: ''
    },
    // Input Hint
    hint: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    // Mask Function
    maskFunction: {
      type: Function,
      default: val => val
    }
  },
  data () {
    return {
      isFocused: false
    }
  },
  computed: {
    // Get Base Input Classes
    getBaseInputClasses () {
      const classes = []

      if (this.error) classes.push('base-input--input--error')

      return classes
    }
  },
  methods: {
    // Update Model Value
    updateValue (e) {
      if (this.inputType === 'number') {
        this.$emit('input', Number(e.target.value))
      } else {
        this.$emit('input', this.maskFunction ? this.maskFunction(e.target.value) : e.target.value)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.base-input
  font-size: 15px
  @apply relative

  &--label
    margin-bottom: 3px

  &--icon
    @apply bg-white flex border-r border-gray-300 rounded-l items-center rounded-l px-4 h-full text-gray-500 whitespace-nowrap

  &--wrapper
    height: 40px
    @apply flex border rounded-l rounded-r

    &--error
      @apply border-red-400 border

    &:hover
      @apply border border-blue-600 transition-all duration-300

  &--input
    padding-top: 1px
    border: none !important
    @apply bg-white flex items-center outline-none transition-all w-full pl-2

    &:disabled
      @apply bg-gray-100

      &:hover
        @apply border-none

  &--error-message
    animation: shake 0.3s ease
    @apply absolute mt-1 text-red-400 text-xs transition-all

  &--hint
    transition: all 0.2s ease-in
    @apply absolute italic mt-1 text-xs

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield
</style>
