<template>
  <div
    class="bg-white flex flex-col rounded-lg w-full border border-border-normal mb-16"
    :class="openComments ? 'h-96' : ''"
  >
    <div
      class="flex justify-between px-4 py-4 cursor-pointer select-none"
      :class="openComments ? 'border-b border-border-normal' : ''"
      @click="openComments = !openComments"
    >
      <div
        class="flex w-full items-center"
      >
        <!-- COMMENT ICON -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            d="M14.375 11.9937H16.0435C16.964 11.9937 17.7102 11.2475 17.7102 10.327V4.91032C17.7102 3.98984 16.964 3.24365 16.0435 3.24365H7.50185C6.58138 3.24365 5.83518 3.98984 5.83518 4.91032V6.57699M12.7102 6.57699H3.96018C3.03971 6.57699 2.29352 7.32318 2.29352 8.24365V13.6603C2.29352 14.5808 3.03971 15.327 3.96018 15.327H5.00185V17.4103L8.75185 15.327H12.7102C13.6307 15.327 14.3769 14.5808 14.3769 13.6603V8.24365C14.3769 7.32318 13.6307 6.57699 12.7102 6.57699Z"
            stroke="#5E6678"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="font-semibold text-lg ml-2">
          <BaseText
            type="label"
            size="sm"
            class="flex items-center"
          >
            Comments
            <BaseBadge
              red
              class="w-max ml-2 h-5"
            >
              <BaseText
                type="label"
                size="xs"
                class="text-text-muted flex items-center"
              >
                {{ comments.length }}
              </BaseText>
            </BaseBadge>
          </BaseText>
        </div>
      </div>
      <div>
        <img
          src="../../../assets/icons/chevron-down-small.svg"
          alt=""
          class="cursor-pointer transition-all duration-300"
          :class="openComments ? 'transform rotate-180' : ''"
        >
      </div>
    </div>

    <!-- COMMENTS -->
    <div
      v-if="openComments"
      id="comment-container"
      class="h-96 overflow-y-scroll overflow-x-hidden scrollbar-hide px-2.5 py-1"
    >
      <!-- COMMENT -->
      <div
        v-for="(comment, index) in comments"
        :key="index"
        class="flex items-start"
      >
        <div class="flex items-center justify-start mt-1.5 mb-1 w-full">
          <BaseAvatar
            size="24"
            class="mr-2"
            :avatar="getCommenterAvatar(comment.created_by)"
          />

          <!-- Comment text -->
          <div
            class="bg-background-normal rounded-lg break-words max-w-full px-2.5 py-1.5"
            style="max-width: calc(100% - 60px);"
          >
            <BaseText
              type="body"
              size="sm"
              class="text-text-muted flex items-center mr-1"
            >
              <div
                class="bg-background-normal rounded break-words max-w-full"
                v-html="comment.body"
              />
            </BaseText>
            <div
              class="flex items-center justify-start w-full divide-x divide-text-subdued"
            >
              <BaseText
                type="body"
                size="xs"
                class="text-text-subdued flex items-center mr-1"
              >
                {{ comment.created_by === getUser.id ? 'You' : getCommenterName(comment.created_by) }}
              </BaseText>
              <BaseText
                type="body"
                size="xs"
                class="text-text-subdued flex items-center pl-1"
              >
                {{ getCommentTimeAgo(comment.created_at) }}
              </BaseText>
            </div>
          </div>

          <!-- COMMENT VERTICAL DOTS -->
          <VuePopper
            v-if="canModifyBoard"
            :ref="`popper${comment.id}`"
            class="ml-auto"
            trigger="clickToOpen"
            :options="{
              placement: 'bottom',
            }"
          >
            <!-- POPPER CONTENT -->
            <div class="popper bg-white border border-gray-200 shadow-lg">
              <div
                class="px-3 py-1 transition-all hover:bg-red-200 cursor-pointer"
                @click="deleteComment(comment.id)"
              >
                Delete
              </div>
            </div>
            <!-- REFRENCE TO OPEN POPPER -->
            <div
              slot="reference"
              @click="closeDrawer(comment.id)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer ml-auto text-text-subdued mt-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </div>
          </VuePopper>
        </div>
        <!-- COMMENT TEXT BOX -->
      </div>
    </div>
    <!-- TEXT EDITOR -->
    <div
      v-if="editor && canModifyBoard && openComments"
      class="editor border-t border-border-normal relative"
    >
      <editor-content
        :editor="editor"
        class="editor__content items-center border-b border-border-normal text-text-loud"
      />
      <div
        v-if="valueEmpty"
        class="absolute top-2.5 left-5 text-gray-400 pointer-events-none"
      >
        Add your comment...
      </div>
      <MenuBar
        class="editor__header bg-white"
        :editor="editor"
        :saving-comment="savingComment"
        :value-empty="valueEmpty"
        @createComment="createComment"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import CharacterCount from '@tiptap/extension-character-count'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import VuePopper from 'vue-popperjs'

import MenuBar from './textEditor/MenuBar'

import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'Comments',
  components: {
    EditorContent,
    MenuBar,
    VuePopper
  },
  props: {
    advertisement: {
      type: Object,
      default: () => {}
    },
    canModifyBoard: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      editor: null,
      timeAgo: null,
      savingComment: false,
      valueEmpty: true,
      openComments: true,
      comments: [],
      commentUsers: [],
      value: ''
    }
  },
  computed: {},
  watch: {
    async advertisement (newValue, oldValue) {
      if (this.advertisement?.id && this.advertisement !== undefined) {
        this.comments = await this.getComments(newValue.id)
        this.commentUsers = await this.getCommentUsers(this.comments)
      }
    }
  },
  async mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        Underline,
        StarterKit,
        Link.configure({
          HTMLAttributes: {
            class: 'text-blue-600 cursor-pointer'
          }
        }),
        CharacterCount.configure({
          limit: 3000
        }),
        Placeholder.configure({
          placeholder: 'Add your comment...'
        })
      ],
      editorProps: {
        attributes: {
          class:
            'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl m-5 focus:outline-none h-10'
            // },
            // handleDOMEvents: {
            //   keydown: async (view, event) => {
            //     if (event.key === 'Enter' && !this.savingComment) {
            //       await this.createComment()
            //     }
            //     return false
            //   }
        }
      },
      onUpdate: () => {
        const editorValue = this.editor.getHTML()
        this.value = editorValue

        this.valueEmpty = editorValue === '<p></p>'
      }
    })

    // Init time ago
    TimeAgo.addDefaultLocale(en)

    // Create formatter (English).
    this.timeAgo = new TimeAgo('en-US')

    // Get comments
    // Maybe put comments as a var then set them to state after users loads too
    if (this.advertisement?.id && this.advertisement !== undefined) {
      const comments = await this.getComments(this.advertisement.id)
      const commentUsers = await this.getCommentUsers(comments)
      // Set comments to state after users are loaded so that they appear to load at the same time
      this.comments = comments.sort((a, b) =>
        a.created_at > b.created_at ? 1 : -1
      )
      this.commentUsers = commentUsers
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    ...mapGetters('AuthModule', ['getUser']),
    closeDrawer (commentId) {
      const popperRef = this.$refs[`popper${commentId}`][0]

      if (popperRef.showPopper) {
        setTimeout(() => {
          popperRef.doClose()
        }, 70)
      }
    },
    scrollToBottomOfCommentContainer () {
      // Start scroll at the bottom of the container
      const container = this.$el.querySelector('#comment-container')
      container.scrollTop = container.scrollHeight
    },
    getCommenterAvatar (userId) {
      return this.commentUsers.find((user) => user.id === userId).avatar
    },
    getCommenterName (userId) {
      const foundUser = this.commentUsers.find((user) => user.id === userId)
      const name = foundUser?.first_name || '' + ' ' + foundUser?.last_name || ''

      return name && name.length ? name : foundUser.name || 'foreplay User'
    },
    getCommentTimeAgo (createdAt) {
      return this.timeAgo.format(createdAt)
    },
    async getCommentUsers (comments) {
      const userIds = comments.map((comment) => comment.created_by)

      const uniqueIds = new Set(userIds)

      const getUsersPromises = [...uniqueIds].map((uid) => {
        return FirebaseAPI.Users.getById(uid)
      })
      const commentUsers = await Promise.all(getUsersPromises)

      return commentUsers
    },
    getComments () {
      return FirebaseAPI.Comments.getCommentsByAdId(this.advertisement.id)
    },
    async createComment () {
      this.savingComment = true

      const comment = await FirebaseAPI.Comments.create(
        this.value,
        this.advertisement.id
      )
      this.commentUsers = await this.getCommentUsers([
        ...this.comments,
        comment
      ])
      this.comments = this.comments.concat(comment)
      this.editor.commands.clearContent()
      this.valueEmpty = true
      console.log(this.advertisement)
      const { name, link_url, type, platform } = this.advertisement

      // Track event when comment is added for analytics
      window.analytics.track('Ad Comment Added', {
        commentAdded: true,
        link: link_url,
        adName: name,
        adFormat: type,
        adPlatform: platform
      })

      this.savingComment = false

      return comment
    },
    async deleteComment (commentId) {
      const comment = await FirebaseAPI.Comments.remove(commentId)

      // Find comment in state and remove it
      this.comments = this.comments.filter(
        (comment) => comment.id !== commentId
      )

      // Make sure drawer is closed when comment is deleted
      this.closeDrawer(commentId)

      return comment
    }
  }
}
</script>

<style lang="scss">
.editor {
  display: flex;
  flex-direction: column;
  color: #0d0d0d;
  background-color: #fff;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
  }

  &__content {
    background-color: #ffffff;
    height: 45px;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

div.ProseMirror {
  margin: 0px !important;
  padding-top: 10px !important;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}
</style>
