<template>
  <!-- Boards Details View -->
  <MainLayout>
    <!-- Toolbar -->
    <template #toolbar>
      <div class="border-b flex items-center justify-between px-8 py-5">
        <div class="flex items-center">
          <img
            class="rounded-full w-10 mr-2"
            :src="brand.avatar"
          >
          <div class="text-3xl font-semibold">
            {{ brand.name }}
          </div>
        </div>

        <!-- Ad Library Button -->
        <div
          v-if="adLibraryId"
          class="w-36 col-span-1 h-full flex items-center"
        >
          <a
            :href="`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${adLibraryId}`"
            target="_blank"
            @click.stop
          >
            <BaseButton outlined>
              <div
                class="flex items-center text-gray-600"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 60 60"
                  fill="none"
                  class="mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M51.9148 0H8.08567C3.62047 0 0.000549316 3.61977 0.000549316 8.08512V51.9146C0.000549316 56.3799 3.62032 59.9997 8.08567 59.9997H29.702L29.7389 38.5591H24.1686C23.4447 38.5591 22.8571 37.9737 22.8544 37.2498L22.8276 30.3386C22.8249 29.6108 23.4142 29.0192 24.142 29.0192H29.7022V22.3413C29.7022 14.5915 34.4352 10.3717 41.3485 10.3717H47.0214C47.7472 10.3717 48.3357 10.9602 48.3357 11.6861V17.5137C48.3357 18.2393 47.7476 18.8276 47.0221 18.828L43.5407 18.8297C39.7811 18.8297 39.0531 20.6162 39.0531 23.2381V29.0194H47.3143C48.1015 29.0194 48.7122 29.7067 48.6194 30.4885L47.8003 37.3997C47.7219 38.0611 47.1611 38.5594 46.4952 38.5594H39.0899L39.0531 60H51.9151C56.3803 60 60.0001 56.3802 60.0001 51.915V8.08512C60 3.61977 56.3802 0 51.9148 0Z"
                    fill="currentColor"
                  />
                </svg>

                Ad Library
              </div>
            </BaseButton>
          </a>
        </div>
      </div>

      <AdvertisementSavingList
        v-if="getAdsBeingSaved.length"
        :advertisements="getAdsBeingSaved"
        @reload="fetchAdvertisements(false)"
      />
    </template>

    <template #content>
      <!-- Loading State -->
      <BaseLoadingSpinner
        v-if="loadingAdvertisements"
        class="mt-24"
      />

      <!-- Empty State -->
      <div
        v-else-if="!advertisements.length"
        class="flex flex-col items-center mt-20"
      >
        <img
          src="../../assets/images/empty-single-library.gif"
          class="border border-gray-400 rounded mx-auto object-cover"
          style="width: 400px; height: 300px"
        >

        <div class="text-lg font-bold my-4">
          This brand is empty...
        </div>

        <div class="text-center text-gray-700">
          You created this board but haven't save

          <br>

          any ads yet.
        </div>

        <div class="flex mt-5">
          <BaseButton
            primary
            @click="showAdvertisementModal = true"
          >
            <i class="fas fa-plus mr-1 text-xs" />
            Save ads
          </BaseButton>
        </div>
      </div>

      <!-- Ads List -->
      <AdvertisementList
        v-else
        :advertisements="advertisements"
        @reload="fetchAdvertisements(false, true)"
        @getMoreAds="() => getMoreAds({})"
      />

      <!-- Create Advertisement Modal -->
      <CreateAdvertisementModal
        v-if="showAdvertisementModal"
        :current-board="board.id"
        @close="showAdvertisementModal = false"
        @save="fetchAdvertisements(false)"
      />

      <!-- Manage Board Modal -->
      <infinite-loading
        v-if="advertisements.length"
        :identifier="infiniteId"
        @infinite="getMoreAds"
      >
        <div slot="spinner">
          <BaseLoadingLogo :margin="2" />
        </div>
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from '@/api/config/FirebaseConfig'
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import AdvertisementSavingList from '../../components/advertisements/AdvertisementSavingList'
import CreateAdvertisementModal from '../../components/advertisements/AdvertisementCreateModal'
import MainLayout from '../../layouts/MainLayout'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'BrandView',
  components: {
    AdvertisementList,
    AdvertisementSavingList,
    CreateAdvertisementModal,
    MainLayout,
    InfiniteLoading
  },
  data () {
    return {
      brand: {},
      advertisements: [],
      adLibraryId: null,
      loadingAdvertisements: false,
      searchQuery: '',
      showManageBoardModal: false,
      showAdvertisementModal: false,
      selectedBoard: {},
      infiniteId: +new Date(),
      lastDocId: null,
      docOrder: 'desc'
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', ['getUserEmail', 'getUserName', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards']),
    // Get Advertisements
    getAdvertisements () {
      return this.advertisements
    }
  },
  async mounted () {
    await this.fetchAdvertisements()
    this.getUniqueBrand()
  },
  methods: {
    toggleOrder () {
      if (this.docOrder === 'asc') {
        this.docOrder = 'desc'
      } else {
        this.docOrder = 'asc'
      }

      this.fetchAdvertisements()
    },
    async getUniqueBrand () {
      const db = firebase.firestore()
      const brandInstance = await db.collection('brands').doc(this.brand.brandId).get()
      this.adLibraryId = brandInstance.data().adLibraryId
    },
    async getMoreAds ($state) {
      // Setup

      const user = firebase.auth().currentUser
      const db = firebase.firestore()

      const createdByCondition = this.getTeam ? ['teamId', '==', this.getTeam.id] : ['created_by', '==', user.uid]

      const query = await db.collection('ads').where(...createdByCondition).where('brandId', '==', this.brand.brandId).orderBy('createdAt', this.docOrder)
      const doc = await db.collection('ads').doc(this.lastDocId).get()

      const offsetQuery = query.startAfter(doc)
      const snapshot = await offsetQuery.limit(20).get()

      const size = snapshot.size

      if (!size) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.lastDocId = snapshot.docs[size - 1].id

      const newDocs = snapshot.docs?.map((doc) => {
        return {
          id: doc.id,
          ...(doc.data() || [])
        }
      })

      this.advertisements = this.advertisements.concat(newDocs)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    // Fetch Advertisements
    async fetchAdvertisements (load = true, refresh = false) {
      this.loadingAdvertisements = load

      if (refresh) {
        this.lastDocId = null
      }

      try {
        const db = firebase.firestore()
        const user = firebase.auth().currentUser

        const createdByCondition = this.getTeam ? ['teamId', '==', this.getTeam.id] : ['created_by', '==', user.uid]

        // Get the user brand
        const brandSnapshot = await db.collection('user-brands').doc(this.$route.params.id).get()
        this.brand = { ...brandSnapshot.data(), id: brandSnapshot.id }

        // get all adds using the brandId on the user brand and the createdBy field
        // MAKE SURE THE USER HAS ACCESS TO THIS MF AD (teamId or uid)
        const query = await db.collection('ads').where(...createdByCondition).where('brandId', '==', this.brand.brandId).orderBy('createdAt', this.docOrder)
        const snapshot = await query.limit(20).get()

        const size = snapshot.size
        this.lastDocId = snapshot.size ? snapshot.docs[size - 1].id : null

        this.advertisements = snapshot.docs?.map(doc => {
          return {
            id: doc.id,
            ...doc.data() || []
          }
        })
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }
    }
  }
}
</script>

<style scoped lang="sass">
</style>
