<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 10.8333L10 6.45833M2.29167 10C2.29167 5.7428 5.74281 2.29166 10 2.29166C14.2572 2.29166 17.7083 5.7428 17.7083 10C17.7083 14.2572 14.2572 17.7083 10 17.7083C5.74281 17.7083 2.29167 14.2572 2.29167 10Z"
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 14C10.3682 14 10.6667 13.7015 10.6667 13.3333C10.6667 12.9651 10.3682 12.6667 10 12.6667C9.63181 12.6667 9.33334 12.9651 9.33334 13.3333C9.33334 13.7015 9.63181 14 10 14Z"
    fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
  </svg>
</template>

<script>
export default {
  name: 'ErrorResponseIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>