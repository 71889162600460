<template>
  <div class="relative">
    <VuePopper
      ref="popper"
      trigger="clickToOpen"
      :options="{
        placement: placement,
        flipVariations: false
      }"
    >
      <!-- Dropdown -->
      <div
        style="z-index: 100000;"
        :style="compact ? '' : 'min-width: 200px;'"
        class="dropdown-card mt-2"
        :class="{
          'popper-right': popperRight,
          'popper-right-center': popperRightCenter,
          'popper-center' : popperCenter,
          'popper': !popperRight && !popperRightCenter,
          'overflow-y-scroll max-h-96': scrollable,
          'overflow-hidden': !scrollable,
        }"
      >
        <div
          v-if="items.length"
          :class="{
            'p-0': noPadding,
          }"
        >
          <div
            v-for="(item, index) in items"
            :key="index"
          >
            <div
              class="px-1 pb-1"
              :class="{
                'py-0 pb-1': index === items.length - 1 ,
                'py-1' : index === 0
              }"
            >
              <div
                class="cursor-pointer transition duration-300 py-1.5 px-2 flex justify-between hover:bg-neutral-25 hover:bg-opacity-40 rounded-md"
                @click="handleSelectItem(item)"
              >
                <slot
                  name="item"
                  :item="item"
                />
                <div
                  v-if="selected.map(e => e[itemValue]).includes(item[itemValue])"
                  class="pl-2"
                >
                  <img src="../../assets/icons/circle-checkmark-confirm.svg">
                </div>
                <!-- Keep the same spacing -->
                <div
                  v-else
                  class="opacity-0"
                  :class="{
                    'pl-2': !noPadding
                  }"
                >
                  <img
                    v-if="!noPadding"
                    src="../../assets/icons/circle-checkmark-confirm.svg"
                  >
                </div>
              </div>
            </div>
            <div
              v-if="item.divider"
              class="w-full divider mb-1"
              style="background:#12376914;"
            />
          </div>
        </div>
      </div>

      <!-- Reference to click and trigger the popper -->
      <div
        slot="reference"
        class="cursor-pointer select-none"
        @click="closeDrawer"
      >
        <div
          v-if="textTrigger"
          class="rounded-md hover:bg-neutral-25 hover:bg-opacity-5 transition-all"
          :class="{
            'px-2 py-1.5': !noPadding
          }"
        >
          <slot
            name="reference"
          />
        </div>
        <div
          v-else
          class="default-dropdown-reference py-1.5 px-2 rounded-md flex items-center"
          :class="noPadding ? 'p-0' : 'default-dropdown-reference'"
        >
          <div
            v-if="leftIcon"
            class="mr-2"
          >
            <slot name="leftIcon" />
          </div>

          <slot
            name="reference"
          />

          <div
            v-if="rightIcon"
            class="ml-2"
          >
            <slot name="rightIcon" />
          </div>
        </div>
      </div>
    </VuePopper>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import VuePopper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'BaseSelect',
  components: {
    VuePopper
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    multiple: {
      type: Boolean
    },
    scrollable: {
      type: Boolean,
      default: false
    },

    placement: {
      type: String,
      default: 'bottom-end'
    },

    // To Style Text References Properly
    textTrigger: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },

    // Icons
    leftIcon: {
      type: Boolean,
      default: false
    },
    rightIcon: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    noShadow: {
      type: Boolean
    },
    popperRight: {
      type: Boolean
    },
    popperRightCenter: {
      type: Boolean
    },
    closeDropdown: {
      type: Boolean
    },
    popperCenter: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    hasIconSlot () {
      return !!this.$slots.icon
    }
  },
  watch: {
    closeDropdown (val) {
      if (val) {
        this.closeDrawer()
      }
    }
  },
  methods: {
    // Close Drawer
    closeDrawer () {
      // Make sure not to close on initial click
      if (!this.$refs.popper.showPopper) return

      if (this.$refs.popper.showPopper) {
        setTimeout(() => {
          this.$refs.popper.doClose()
        }, 7)
      }
    },
    // Handle Select Item
    handleSelectItem (item) {
      if (!this.multiple) {
        if (this.selected[0] && this.selected[0][this.itemValue] === item[this.itemValue]) {
          this.$emit('update:selected', [])
        } else {
          this.$emit('update:selected', [item])
        }

        this.$emit('select')
        this.closeDrawer()
      } else {
        if (!this.selected.map(e => e[this.itemValue]).includes(item[this.itemValue])) {
          this.$emit('update:selected', [...this.selected, item])
        } else {
          this.$emit('update:selected', this.selected.filter(e => e[this.itemValue] !== item[this.itemValue]))
        }

        this.$emit('select')
      }
    }
  }
}
</script>

<style scoped>
.dropdown-card {
  background: rgba(255, 255, 255, 1);

  box-shadow: 0px 24px 32px -12px rgba(54, 57, 74, 0.24), 0px 1px 2px rgba(164, 172, 185, 0.16), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  backdrop-filter: blur(10px);

  border-radius: 10px;
}

.divider {
  border-top: 1px solid #1238690a;
  height: 1px;
}
.popper{
  padding: 0px !important;
  border: 0px !important;
  transform: translate3d(-164px, 30px, 0px) !important;
}

.popper-right{
  transform: translate3d(7px, 40px, 0px) !important;
}

.popper-right-center{
  transform: translate3d(40px, -50px, 0px) !important;
}

.popper-center{
  transform: translate3d(-63px, 30px, 0px) !important;
}
</style>
