<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M14.4194 11.2375L14.7729 10.8839C16.335 9.32181 16.335 6.78915 14.7729 5.22705C13.2108 3.66496 10.6782 3.66496 9.11608 5.22705L8.76253 5.58061M11.2374 14.4194L10.8838 14.773C9.32175 16.3351 6.78909 16.3351 5.22699 14.773C3.6649 13.2109 3.6649 10.6782 5.22699 9.11614L5.58055 8.76259M11.591 8.40903L8.40897 11.591" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'LinkWideRoundedIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>