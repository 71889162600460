var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-full h-max bg-white rounded-xl flex flex-wrap overflow-hidden select-none",class:{
      'discount-border': _vm.bannerText,
      'border border-border-normal' : !_vm.bannerText,
      'cursor-pointer' : _vm.subscription,
      'rounded-b-none': _vm.additionalInfo
    },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("cardIcon"),(_vm.bannerText)?_c('div',{staticClass:"bg-primary-green-50 bg-opacity-10 py-2.5 flex items-center justify-center w-full green-text"},[_c('BaseText',{attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.bannerText)+" ")])],1):_vm._e(),(_vm.bannerText)?_c('div',{staticClass:"w-full h-px green-border--top"}):_vm._e(),(_vm.subscription)?_c('div',{staticClass:"my-auto ml-5",class:_vm.selected ? 'rounded-full bg-primary-blue-100 w-4 h-4 flex items-center justify-center ring-2 ring-offset-2 ring-primary-blue-600' : 'bg-white w-4 h-4 flex items-center justify-center ring-2 ring-offset-2 ring-border-normal rounded-full'},[_c('div',{staticClass:"bg-white w-1.5 h-1.5 rounded-full"})]):_vm._e(),_c('div',{staticClass:"flex flex-col ml-4 w-max my-auto py-2.5"},[_c('BaseText',{staticClass:"text-text-loud",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.mainText)+" ")]),_c('BaseText',{staticClass:"text-text-normal",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.subText)+" ")])],1),_c('BaseText',{staticClass:"text-text-disabled ml-auto mr-2 my-auto",attrs:{"type":"body","size":"sm"}},[_c('s',{staticClass:"text-text-disabled h-max w-max"},[_vm._v(" "+_vm._s(_vm.discountText)+" ")])]),(_vm.subscription && _vm.price)?_c('BaseText',{staticClass:"text-text-normal my-auto mr-5",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.getProductPrice)+" ")]):_vm._e(),(!_vm.subscription)?_c('BaseFilterSelect',{staticClass:"ml-auto my-auto",attrs:{"align-center":"","no-search":"","short-dropdown":"","item-value":"value","items":_vm.formatDisabledItems},on:{"update":(item) => {
          _vm.$emit('update-quantity', item)
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BaseButton',{staticClass:"flex items-center w-max rounded-full hover:bg-background-normal transition duration-300 h-11 shadow-sm",attrs:{"outlined":""}},[_c('BaseText',{staticClass:"text-text-loud -my-3 flex items-center justify-start h-7 w-36 -ml-4",attrs:{"type":"body","size":"sm"}},[_vm._t("icon"),_c('BaseBadge',{staticClass:"flex items-center h-4 w-max pl-2 min-w-10",attrs:{"blue":"","small":""}},[_c('span',{staticClass:"text-body-xs"},[_vm._v(" "+_vm._s(`${_vm.currentQuantity} ${_vm.label}`)+" ")])]),_c('img',{staticClass:"h-5 w-5 ml-auto -mr-2",attrs:{"src":require("../../../assets/icons/chevron-down-small.svg"),"alt":"chevron-down"}})],2)],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"flex items-center"},[_c('BaseText',{staticClass:"text-text-loud",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(item.label ? `${item.name} ${item.label}` : `${item.name}`)+" ")]),(item.offerText)?_c('BaseText',{staticClass:"text-primary-green-100 ml-1",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(item.offerText)+" ")]):_vm._e()],1),(item.formattedPrice)?_c('BaseText',{staticClass:"text-text-loud",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(item.formattedPrice)+" ")]):_vm._e()]}}],null,true)}):_vm._e()],2),(_vm.additionalInfo)?_c('div',{staticClass:"border border-t-0 border-border-normal px-4 pt-1 pb-2 rounded-b-xl"},[_vm._t("additionalInfo")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }