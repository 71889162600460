<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M2.29175 5.62492C2.29175 4.70444 3.03794 3.95825 3.95841 3.95825H11.0417C11.9622 3.95825 12.7084 4.70444 12.7084 5.62492V14.3749C12.7084 15.2954 11.9622 16.0416 11.0417 16.0416H3.95841C3.03794 16.0416 2.29175 15.2954 2.29175 14.3749V5.62492Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M12.7084 8.33325L16.5024 6.43626C17.0565 6.15922 17.7084 6.56213 17.7084 7.18161V12.8182C17.7084 13.4377 17.0565 13.8406 16.5024 13.5636L12.7084 11.6666V8.33325Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'VideoFormatIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>