<template>
  <div
    class="theme-transition flex flex-col h-screen min-w-72 w-72"
    :class="`${getTheme}-gradient`"
  >
    <!-- LOGO SECTION -->
    <div class="flex p-6 pr-0 pb-4 justify-between items-center">
      <img
        src="../../assets/images/brief-foreplay-icon.svg"
        alt=""
        class="filter invert h-8"
      >
      <div class="flex justify-center items-center">
        <div
          id="olvy-whats-new"
          class="cursor-pointer select-none"
        >
          <img src="../../assets/images/ship-icon.svg">
        </div>
        <BaseSelect
          v-click-outside="()=>{closeDropdown()}"
          :close-dropdown="leaveDropdown"
          popper-right
          no-shadow
          no-padding
          white-background
          item-value="name"
          :items="[
            { name: 'Profile' , divider: true},
            { name: 'Settings' },
            { name: 'Feature Releases', divider: true},
            { name: 'Perks & Discounts' },
            { name: 'Become an Affiliate'},
            { name: 'Help', divider: true},
            { name: 'Logout' }
          ]"
          @select="() => {}"
          @input="() => {}"
        >
          <div
            slot="reference"
            class="flex items-center justify-center object-fit text-white"
            @click="() => {
              closeDropdown()
            }"
          >
            <BaseAvatar
              :avatar="getUserAvatar"
              size="32"
              class="flex-shrink-0 rounded-full object-fit"
            />
          </div>
          <template #item="{ item }">
            <router-link
              v-if="item.name !== 'Become an Affiliate'"
              :to="getRoute(item.name) || {name: ''}"
            >
              <div
                class="flex items-center w-72"
                @click="handleDropdownClick(item.name)"
              >
                <BaseAvatar
                  v-if="item.name === 'Profile'"
                  :avatar="getUserAvatar"
                  size="36"
                  class="flex-shrink-0 rounded-full object-fit ml-1 mr-2"
                />
                <img
                  v-if="item.name === 'Settings'"
                  src="../../assets/icons/settings-icon.svg"
                  alt=""
                  class="ml-1 mr-2"
                >
                <img
                  v-if="item.name === 'Feature Releases'"
                  src="../../assets/icons/notifications-icon.svg"
                  alt=""
                  class="ml-1 mr-2"
                >
                <img
                  v-if="item.name === 'Perks & Discounts'"
                  src="../../assets/icons/gift-icon.svg"
                  alt=""
                  class="ml-1 mr-2"
                >
                <img
                  v-if="item.name === 'Help'"
                  src="../../assets/icons/help-icon.svg"
                  alt=""
                  class="ml-1 mr-2"
                >
                <svg
                  v-if="item.name === 'Logout'"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-1 mr-2 transform rotate-90"
                >
                  <path
                    d="M9.99999 3.125V12.5M9.99999 3.125L13.75 6.875M9.99999 3.125L6.25 6.875M16.875 10.625V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V10.625"
                    stroke="#5E6678"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <BaseText
                  v-if="item.name !== 'Profile'"
                  type="body"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ item.name }}
                </BaseText>
                <div
                  v-if="item.name === 'Profile'"
                  class="ml-1 mr-2 w-max h-max"
                >
                  <BaseText
                    v-if="item.name === 'Profile'"
                    type="label"
                    size="sm"
                    class="text-neutral-600"
                  >
                    {{ getUserName }}
                  </BaseText>
                  <BaseText
                    v-if="item.name === 'Profile'"
                    type="body"
                    size="sm"
                    class="text-neutral-400"
                  >
                    {{ getUserEmail }}
                  </BaseText>
                </div>
              </div>
            </router-link>
            <a
              v-if="item.name === 'Become an Affiliate'"
              href="https://www.foreplay.co/affiliates"
              target="_blank"
            >
              <div
                v-if="item.name === 'Become an Affiliate'"
                class="flex items-center w-72"
              >
                <img
                  v-if="item.name === 'Become an Affiliate'"
                  src="../../assets/icons/plant-icon.svg"
                  alt=""
                  class="ml-1 mr-2"
                >
                <BaseText
                  v-if="item.name === 'Become an Affiliate'"
                  type="body"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ item.name }}
                </BaseText>
              </div>
            </a>
          </template>
        </BaseSelect>
      </div>
    </div>

    <!-- DIVIDER -->

    <!-- SEACH BAR AND APP SECTIONS CONTAINER -->
    <div class="pt-0">
      <!-- APP SECTIONS -->

      <div class="p-3 pl-0">
        <router-link
          :to="{ name: 'DashboardView' }"
        >
          <div
            class="flex items-center justify-between mb-1 h-max"
          >
            <svg
              v-if="$route.name === 'DashboardView'"
              xmlns="http://www.w3.org/2000/svg"
              width="4"
              height="20"
              viewBox="0 0 4 20"
              fill="none"
            >
              <path
                d="M0 0C2.20914 0 4 1.79086 4 4V16C4 18.2091 2.20914 20 0 20V0Z"
                fill="white"
              />
              <path
                d="M0.5 0.535444C2.19615 0.778059 3.5 2.23676 3.5 4V16C3.5 17.7632 2.19615 19.2219 0.5 19.4646V0.535444Z"
                stroke="url(#paint0_linear_7869_61703)"
                stroke-opacity="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_7869_61703"
                  x1="4"
                  y1="9.5"
                  x2="1"
                  y2="9.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#B1B1B1" />
                  <stop
                    offset="1"
                    stop-color="#CBCBCB"
                    stop-opacity="0"
                  />
                </linearGradient>
              </defs>
            </svg>
            <div
              class="w-full transition-colors duration-300 ease-in-out flex items-center justify-between h-fit rounded-lg"
              :class="$route.name === 'DashboardView' ? ' ml-2' : 'ml-3 '"
            >
              <div
                class="p-1 duration-500 rounded-lg flex justify-between items-center w-full cursor-pointer"
                :class="$route.name === 'DashboardView' ? `gradient-dashboard` : `hover:bg-white hover:bg-opacity-5 hover-border`"
              >
                <img
                  src="../../assets/images/dashboard-icon.png"
                  class="w-8 h-8"
                >
                <div class="ml-3 mr-auto text-lg text-white">
                  <BaseText
                    type="body"
                    size="sm"
                  >
                    Dashboard
                  </BaseText>
                </div>
                <div
                  v-if="!($route.name === 'DashboardView')"
                  class="flex opacity-50"
                >
                  <div
                    class="mr-2 h-5 w-5 text-xs text-white rounded flex items-center justify-center cursor-pointer border border-white border-opacity-5"
                    :class="$route.name === 'DashboardView' ? ``: `bg-white bg-opacity-5 `"
                  >
                    <svg class="h-4 w-4 fill-white">
                      <use :xlink:href="`${remixiconUrl}#ri-command-line`" />
                    </svg>
                  </div>
                  <div
                    class=" h-5 w-5 text-center text-white rounded align-middle flex justify-center items-center cursor-pointer border border-white border-opacity-5"
                    :class="$route.name === 'DashboardView' ? ``: `bg-white bg-opacity-5`"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-white "
                    >
                      H
                    </BaseText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
        <!-- Swipe Library -->
        <router-link
          :to="{ name: 'LibraryView' }"
        >
          <div
            class="flex items-center justify-between mb-1 h-max"
          >
            <div
              v-if="$route.name === 'LibraryView'"
              class="w-1 h-5 rounded-r-xl bg-primary-blue-100"
            />
            <div
              class="w-full flex items-center justify-between h-fit rounded-lg"
              :class="$route.name === 'LibraryView' ? ' ml-2' : 'ml-3 '"
            >
              <div
                class="p-1 transition-colors duration-300 ease-in-out rounded-lg flex justify-between items-center w-full cursor-pointer"
                :class="$route.name === 'LibraryView' ? `gradient-library` : `hover:bg-white hover:bg-opacity-5 hover-border`"
                :style="
                  $route.name === 'LibraryView' ? `background : linear-gradient(90deg, rgba(31, 105, 255, 0.24) 0%, rgba(6, 7, 15, 0) 80.36%), rgba(255, 255, 255, 0.06);` : 'transparent'
                "
              >
                <img
                  src="../../assets/images/library-new.png"
                  class="w-8 h-8"
                >
                <div class="ml-3 mr-auto text-lg text-white">
                  <BaseText
                    type="body"
                    size="sm"
                  >
                    Swipe File
                  </BaseText>
                </div>
                <div
                  v-if="!($route.name === 'LibraryView')"
                  class="flex opacity-50"
                >
                  <div
                    class="mr-2 h-5 w-5 text-xs text-white rounded flex items-center justify-center cursor-pointer border border-white border-opacity-5"
                    :class="$route.name === 'LibraryView' ? ``: `bg-white bg-opacity-5 `"
                  >
                    <svg class="h-4 w-4 fill-white">
                      <use :xlink:href="`${remixiconUrl}#ri-command-line`" />
                    </svg>
                  </div>
                  <div
                    class=" h-5 w-5 text-center text-white rounded align-middle flex justify-center items-center cursor-pointer border border-white border-opacity-5"
                    :class="$route.name === 'LibraryView' ? ``: `bg-white bg-opacity-5`"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-white "
                    >
                      L
                    </BaseText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>

        <!-- Briefs -->
        <div
          :class="getIsInspoMode ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''"
        >
          <router-link
            :is="canSeeBriefs ? 'router-link' : 'span'"
            :to="{ name: 'BriefsView' }"
            @click="sendToManagePlan"
          >
            <div
              class="flex w-full items-center h-max"
            >
              <div
                v-if="$route.name === 'BriefsView' || $route.name === 'EditBriefView'"
                class="w-1 h-5 rounded-r-xl bg-primary-green-100"
              />
              <div
                class="w-full flex items-center justify-between h-fit rounded-xl"
                :class="$route.name === 'BriefsView' || $route.name === 'EditBriefView' ? ' ml-2' : 'ml-3 '"
              >
                <div
                  class=" flex transition-colors duration-300 ease-in-out items-center w-full justify-between h-fit rounded-lg"
                  :class="$route.name === 'BriefsView' || $route.name === 'EditBriefView' ? ' gradient-briefs' : ` hover:bg-white hover:bg-opacity-5 hover-border`"
                  :style="
                    $route.name === 'BriefsView' || $route.name === 'EditBriefView' ? `background: linear-gradient(90deg, rgba(0, 168, 121, 0.24) 0%, rgba(6, 7, 15, 0) 80.36%), rgba(255, 255, 255, 0.04);` : 'transparent'"
                >
                  <div
                    class="p-1 rounded-lg flex justify-between items-center w-full cursor-pointer h-10"
                  >
                    <img
                      src="../../assets/images/briefs-new.png"
                      class="w-8 h-8"
                    >
                    <div class="ml-3 mr-auto text-lg text-white">
                      <BaseText
                        type="body"
                        size="sm"
                      >
                        Briefs
                      </BaseText>
                    </div>
                    <div
                      v-if="!($route.name === 'BriefsView' || $route.name === 'EditBriefView')"
                      class="flex opacity-50 gap-x-2"
                    >
                      <div
                        v-if="canSeeBriefs"
                        class="h-5 w-5 text-sm text-white rounded flex items-center justify-center border border-white border-opacity-5"
                        :class="$route.name === 'BriefsView' || $route.name === 'EditBriefView' ? ``: `bg-white bg-opacity-5`"
                      >
                        <svg class="h-4 w-4 fill-current">
                          <use :xlink:href="`${remixiconUrl}#ri-command-line`" />
                        </svg>
                      </div>
                      <div
                        v-if="canSeeBriefs"
                        class=" h-5 w-5 text-center text-white rounded align-middle flex justify-center items-center cursor-pointer border border-white border-opacity-5"
                        :class="$route.name === 'BriefsView' || $route.name === 'EditBriefView' ? ``: `bg-white bg-opacity-5`"
                      >
                        <BaseText
                          type="body"
                          size="sm"
                          class="text-white"
                        >
                          B
                        </BaseText>
                      </div>
                      <img
                        v-else
                        src="../../assets/icons/locked.svg"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <!-- Discovery -->
        <div
          :class="getIsInspoMode ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''"
        >
          <router-link
            :to="{ name: 'DiscoveryView' }"
          >
            <div
              class="flex w-full items-center h-max"
            >
              <div
                v-if="$route.name === 'DiscoveryView'"
                class="w-1 h-5 rounded-r-xl bg-primary-purple-100 "
              />
              <div
                class="p-1 flex transition-colors duration-300 ease-in-out justify-between items-center w-full cursor-pointer mt-1 border-opacity-25 rounded-lg"
                :class="$route.name === 'DiscoveryView' ? 'ml-2 gradient-briefs' : `ml-3 hover:bg-white hover:bg-opacity-5 hover-border`"
                :style="$route.name === 'DiscoveryView' ? 'background: linear-gradient(90deg, rgba(101, 52, 223, 0.24) 0%, rgba(6, 7, 15, 0) 80.36%), rgba(255, 255, 255, 0.04);' : ''"
              >
                <img
                  src="../../assets/images/discovery-new.png"
                  class="w-8 h-8"
                >
                <div class="ml-3 mr-auto text-lg text-white">
                  <BaseText
                    type="body"
                    size="sm"
                  >
                    Discovery
                  </BaseText>
                </div>
                <div
                  v-if="!($route.name === 'DiscoveryView')"
                  class="flex opacity-50"
                >
                  <div
                    class="mr-2 h-5 w-5 text-xs text-white rounded flex items-center justify-center cursor-pointer border border-white border-opacity-5"
                    :class="$route.name === 'DiscoveryView' ? ``: `bg-white bg-opacity-5`"
                  >
                    <svg class="h-4 w-4 fill-white">
                      <use :xlink:href="`${remixiconUrl}#ri-command-line`" />
                    </svg>
                  </div>
                  <div
                    class=" h-5 w-5 text-white rounded align-middle text-center flex justify-center items-center cursor-pointer border border-white border-opacity-5"
                    :class="$route.name === 'DiscoveryView' ? ``: `bg-white bg-opacity-5`"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-white h-max w-max text-center"
                    >
                      D
                    </BaseText>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <!-- Spyder -->
        <div
          :class="getIsInspoMode ? 'opacity-50 cursor-not-allowed pointer-events-none' : ''"
        >
          <router-link
            :to="{ name: 'SpyderBrandsView' }"
          >
            <div
              class="flex w-full items-center mb-2 h-max"
            >
              <div
                v-if="$route.name === 'SpyderBrandsView' || $route.name === 'SpyderView'"
                class="w-1 h-5 rounded-r-xl bg-primary-red-200 "
              />
              <div
                class="p-1 flex transition-colors duration-300 ease-in-out justify-between items-center w-full cursor-pointer mt-1 border-opacity-25 rounded-lg"
                :class="$route.name === 'SpyderBrandsView' || $route.name === 'SpyderView'
                  ? 'ml-2 gradient-briefs' 
                  : `ml-3 hover:bg-white hover:bg-opacity-5 hover-border`"
                :style="$route.name === 'SpyderBrandsView' || $route.name === 'SpyderView'
                  ? `background : linear-gradient(90deg, rgba(237, 97, 90, 0.24) 0%, rgba(6, 7, 15, 0) 80.36%), rgba(255, 255, 255, 0.06);` 
                  : ''"
              >
                <img
                  src="../../assets/icons/spyder.png"
                  class="w-8 h-8"
                >
                <div class="ml-3 mr-auto text-lg text-white">
                  <BaseText
                    type="body"
                    size="sm"
                  >
                    Spyder
                  </BaseText>
                </div>
                <div
                  v-if="!($route.name === 'SpyderBrandsView' || $route.name === 'SpyderView')"
                  class="flex opacity-50"
                >
                  <div
                    class="mr-2 h-5 w-5 text-xs text-white rounded flex items-center justify-center cursor-pointer border border-white border-opacity-5"
                    :class="$route.name === 'SpyderBrandsView' || $route.name === 'SpyderView' ? ``: `bg-white bg-opacity-5`"
                  >
                    <svg class="h-4 w-4 fill-white">
                      <use :xlink:href="`${remixiconUrl}#ri-command-line`" />
                    </svg>
                  </div>
                  <div
                    class=" h-5 w-5 text-white rounded align-middle text-center flex justify-center items-center cursor-pointer border border-white border-opacity-5"
                    :class="$route.name === 'SpyderBrandsView' || $route.name === 'SpyderView' ? ``: `bg-white bg-opacity-5`"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-white h-max w-max text-center"
                    >
                      I
                    </BaseText>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- DIVIDER -->
    <div
      class="w-full border-b border-white opacity-10 -mt-1"
    />
    <!-- FOLDERS/BOARDS -->
    <div
      class="flex items-center group text-white text-opacity-25 focus-within:text-opacity-100 border transition-all duration-100 ease-in border-white border-opacity-10 mt-3.5 mx-3.5 focus-within:border-white focus-within:border-opacity-30 fill-white bg-white bg-opacity-5 rounded-lg pr-1"
    >
      <!-- Search Icon -->
      <div class="rounded m-2">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.875 16.875L13.4388 13.4388M13.4388 13.4388C14.5321 12.3454 15.2083 10.835 15.2083 9.16667C15.2083 5.82995 12.5034 3.125 9.16667 3.125C5.82995 3.125 3.125 5.82995 3.125 9.16667C3.125 12.5034 5.82995 15.2083 9.16667 15.2083C10.835 15.2083 12.3454 14.5321 13.4388 13.4388Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <!-- Text Input -->
      <input
        ref="searchInput"
        :value="search"
        class="ml-1 text-sm rounded flex items-center outline-none border-none w-full text-text-white bg-white bg-opacity-0 h-full placeholder-white placeholder-opacity-20 focus:placeholder-opacity-100"
        placeholder="Search..."
        @input="folderSearch($event)"
      >
      <span class="ml-auto flex opacity-40">
        <div
          class="mr-2 h-5 w-5 text-xs text-white rounded flex items-center justify-center border border-white border-opacity-5 bg-white bg-opacity-5"
        >
          <svg class="h-4 w-4">
            <use :xlink:href="`${remixiconUrl}#ri-command-line`" />
          </svg>
        </div>
        <div
          class=" h-5 w-5 text-white rounded align-middle text-center flex justify-center items-center border border-white border-opacity-5"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-white h-max w-max text-center"
          >
            K
          </BaseText>
        </div>
      </span>
    </div>
    <div class="flex w-full justify-between items-center px-3.5 ">
      <BaseText
        type="body"
        size="sm"
        class="text-white text-opacity-50 py-3 pl-2"
      >
        Folders
      </BaseText>
      <div class="relative select-none">
        <BaseSelect
          v-click-outside="
            () => {
              closeDropdown();
            }
          "
          no-shadow
          no-padding
          white-background
          :close-dropdown="leaveDropdown"
          item-value="name"
          :items="[
            { name: 'Create Folder', action: () => folderModalOpen = true },
            {
              name: 'Create Board',
              action: () => showManageBoardModal = true,
            },
          ]"
          @select="() => {}"
          @input="() => {}"
          @click.stop.native="() => {}"
        >
          <div
            slot="reference"
            class="flex items-center justify-center object-fit opacity-60 hover:opacity-90 text-white transition duration-300 select-none"
            @click="closeDropdown()"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <template #item="{ item }">
            <div
              class="flex items-center w-full h-full cursor-pointer"
              @click="() => item.action()"
            >
              <img
                v-if="item.name === 'Create Folder'"
                src="../../assets/icons/create-folder-icon.svg"
                alt=""
                class="w-5 mr-2"
              >
              <img
                v-if="item.name === 'Create Board'"
                src="../../assets/icons/create-board-icon.svg"
                alt=""
                class="w-5 mr-2"
              >
              <BaseText
                type="body"
                size="sm"
                class="text-text-muted w-max"
              >
                {{ item.name }}
              </BaseText>
            </div>
          </template>
        </BaseSelect>
      </div>
    </div>
    <Folders
      :is-beta-user="isBetaUser"
      :leave-dropdown.sync="leaveDropdown"
      :folder-modal-open.sync="folderModalOpen"
      :close-dropdown="closeDropdown"
      :search="search"
      @showManageBoardModal="showManageBoardModal = true"
    />

    <!-- BOTTOM SECTION -->
    <div class="mt-auto">
      <!-- DIVIDER -->
      <div
        v-if="isFreeTier && !getTeam"
        class="w-full border-b my-0 mb-0 border-white opacity-20"
      />

      <!-- FREE TRIAL TIMER -->
      <div
        v-if="(isFreeTier && !getTeam) || (isFreeTier && getTeam && isTeamOwner)"
        class="h-max w-full flex flex-col pt-2 px-5 justify-center"
      >
        <div
          class="items-center mb-2 p-1.5 rounded-xl gradient-discovery h-max"
        >
          <div class=" justify-between py-2 px-3 ">
            <!-- Text -->
            <div class="flex mb-2">
              <span class="text-white text-md">
                Free Trial
              </span>
            </div>
            <!-- Timer -->
            <div class="flex">
              <div
                class="duration-500 bg-opacity-50 h-5 w-max text-sm leading-5 text-center text-white rounded align-middle mr-2 flex items-center"
              >
                <div class="gradient-discovery rounded align-middle mr-2 flex justify-center items-center bg-white bg-opacity-5 py-0.5 px-1.5">
                  <BaseText
                    type="body"
                    size="xs"
                    class="text-white"
                  >
                    {{ days }}
                  </BaseText>
                </div>
                <BaseText
                  type="body"
                  size="xs"
                  class="text-white"
                >
                  days
                </BaseText>
              </div>
              <div
                class="duration-500 bg-opacity-50 h-5 w-max rounded align-middle mr-2 flex ml-1 items-center"
              >
                <div
                  class="gradient-discovery text-sm leading-5 text-center text-white rounded align-middle mr-2 flex justify-center items-center bg-white bg-opacity-5 py-0.5 px-1.5"
                >
                  <BaseText
                    type="body"
                    size="xs"
                    class="text-white"
                  >
                    {{ hours }}
                  </BaseText>
                </div>
                <BaseText
                  type="body"
                  size="xs"
                  class="text-white"
                >
                  hours
                </BaseText>
              </div>
            </div>
          </div>

          <router-link
            :to="{ name: 'PaymentView' }"
          >
            <div
              class="w-full h-max gradient-discovery rounded-lg overflow-hidden mt-2"
            >
              <BaseButton
                class="w-full rounded-xl"
                style="background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%), linear-gradient(93.57deg, rgba(0, 0, 0, 0) 29.62%, rgba(255, 255, 255, 0.04) 64.81%, rgba(0, 0, 0, 0) 100%), linear-gradient(94.89deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.04) 26.97%, rgba(0, 0, 0, 0) 53.94%), rgba(255, 255, 255, 0.01);
            box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.08);"
                @click="goToManageSubscriptions"
              >
                <div
                  class="flex h-full w-full justify-start pr-10"
                >
                  <img
                    src="../../assets/icons/stars-icon.svg"
                    alt=""
                    class="h-5 w-5 mr-2"
                  >
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-white w-max"
                  >
                    Upgrade Foreplay
                  </BaseText>
                </div>
              </BaseButton>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="absolute">
      <SearchModal
        @focusSearch="focusSearch()"
      />
    </div>

    <!-- MODALS -->
    <PostPurchaseModal v-if="getShowUpsell && getInitialLoad" />
    <TeamInvitationModal v-else-if="getPendingInvitations.length && getInitialLoad" />
    <PaymentPastDueModal v-else-if="getTeam && getTeamSubscriptionState === 'past_due'" />
    <TeamMemberPastDueModal v-else-if="trialExpired && getInitialLoad && getTeam && !isTeamCreator" />
    <TrialEndedModal v-else-if="trialExpired && getInitialLoad" />
    <OnboardingModal
      v-else-if="needsOnboarding && getInitialLoad"
      @close="completedOnboarding = true"
    />
    <ProductHuntModal
      v-else-if="showProductHuntModal"
      @close="() => { closeProductHuntModal = true}"
      @showIgModal="() => { showInstagramModal = true }"
    />
    <InstagramModal
      v-if="showInstagramModal"
      @close="() => { showInstagramModal = false }"
    />

    <!-- Manage Board Modal -->
    <ManageBoardModal
      v-if="showManageBoardModal"
      :board="{}"
      @close="showManageBoardModal = false"
    />
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'

import firebase from '../../api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import Folders from '../sidebar/Folders'
import InstagramModal from '../dashboard/Modals/InstagramModal.vue'
import ManageBoardModal from '../boards/ManageBoardModal.vue'
import OnboardingModal from '../../components/account/OnboardingModal.vue'
import PaymentPastDueModal from '../../components/account/subscription/PaymentPastDueModal.vue'
import PostPurchaseModal from '../account/subcriptionv2/PostPurchaseModal.vue'
import ProductHuntModal from '../account/ProductHuntModal.vue'
import SearchBar from '../search/SearchBar'
import SearchModal from '../search/SearchModal'
import TeamInvitationModal from '../../components/account/teams/TeamInvitationModal.vue'
import TeamMemberPastDueModal from '../account/subscription/TeamMemberPastDueModal.vue'
import TrialEndedModal from '../../components/account/subscription/TrialEndedModal.vue'

export default {
  name: 'Sidebar',
  components: {
    Folders,
    InstagramModal,
    ManageBoardModal,
    OnboardingModal,
    PaymentPastDueModal,
    PostPurchaseModal,
    ProductHuntModal,
    SearchBar,
    SearchModal,
    TeamInvitationModal,
    TeamMemberPastDueModal,
    TrialEndedModal
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      betaIds: [],
      search: '',
      trialEnd: moment().format('X'),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      remixiconUrl,
      completedOnboarding: true,
      showManageBoardModal: false,
      closeProductHuntModal: false,
      unseenUpdates: false,
      leaveDropdown: false,
      editBoard: {},
      folderModalOpen: false,
      showInstagramModal: false
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getIsInspoMode']),
    ...mapGetters('AuthModule', [
      'getInitialLoad',
      'getPendingInvitations',
      'getShowUpsell',
      'getSurveyViewed',
      'getTeam',
      'getTeamSubscriptionState',
      'getUser',
      'getUserAvatar',
      'getUserEmail',
      'getUserFreeTrialDate',
      'getUserName',
      'getUserSubscription',
      'isFreeTier',
      'isTeamOwner'
    ]),
    ...mapGetters('MiscModule', ['getTheme']),
    showProductHuntModal () {
      return !this.getUser.viewedProductHuntModal && !this.closeProductHuntModal
    },
    isDiscovery () {
      return this.$route.name === 'DiscoveryView' || this.$route.name === 'DiscoveryBrandsView'
    },
    isTeamCreator () {
      return this.getTeam && this.getTeam.created_by === this.getUser.user_id
    },
    trialExpired () {
      // check if a week has passed since trial and if they're free tier
      if (this.getTeam && ['past_due', 'canceled', 'inactive'].includes(this.getTeamSubscriptionState)) {
        return true
      }
      return this.isFreeTier && Date.now() > this.getUserFreeTrialDate + 6.048e8 && !this.getTeam && !this.isDiscovery
    },
    shouldSeeSurvey () {
      return !this.isFreeTier && !this.getSurveyViewed
    },
    canSeeBriefs () {
      return this.getUserSubscription?.product?.name === 'Full Workflow' || !this.getUserSubscription?.product?.name
    },
    isBetaUser () {
      return this.betaIds.map(user => user.user_id).includes(this.getUser.user_id)
    },
    needsOnboarding () {
      return !this.completedOnboarding && this.getUser.trialStarted > 1663193252602 && !this.getUser.onboardingComplete
    }
  },
  watch: {
    trialEnd (val, oldVal) {
      this.compute()
    }
  },
  created () {
    this.compute()
    this.addOneSecondToActualTimeEverySecond()
  },
  async mounted () {
    await this.fetchFreeTrialDate()
    await this.getBetaUsers()
    const user = firebase.auth().currentUser
    const authUser = await FirebaseAPI.Users.getById(user.uid)
    this.completedOnboarding = authUser.onboardingComplete

    if (this.isFreeTier && Date.now() < this.getUserFreeTrialDate + 6.048e8) {
      this.trialEnd = moment(this.getUserFreeTrialDate).add(7, 'days')
    }
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    ...mapActions('BoardsModule', ['fetchBoards']),
    ...mapActions('AuthModule', ['fetchFreeTrialDate', 'checkTeamExpired']),
    handleLogout () {
      console.log('logout')
      this.logoutUser()
    },
    sendToManagePlan () {
      this.$router.push({ name: 'PaymentView' })
    },
    async getBetaUsers () {
      this.betaIds = await FirebaseAPI.Beta.getBetaUserIds()
    },
    goToManageSubscriptions () {
      this.$router
        .push({ name: 'AccountView', query: { tab: 'subscription' } })
        .catch((e) => e)
    },
    addOneSecondToActualTimeEverySecond () {
      var component = this
      component.trialEnd = moment().format('X')
      setTimeout(function () {
        component.addOneSecondToActualTimeEverySecond()
      }, 1000)
    },
    focusSearch () {
      this.$refs.searchInput.focus()
    },
    getDiffInSeconds () {
      return moment(this.getUserFreeTrialDate).add(7, 'days').add(10, 'seconds').format('X') - this.trialEnd
    },
    compute () {
      const duration = moment.duration(this.getDiffInSeconds(), 'seconds')
      const days = duration.days() > 0 ? duration.days() : 0
      const hours = duration.hours() > 0 ? duration.hours() : 0
      const minutes = duration.minutes() > 0 ? duration.minutes() : 0
      const seconds = duration.seconds() > 0 ? duration.seconds() : 0

      this.days = `${days < 10 ? `0${days}` : days}`
      this.hours = `${hours < 10 ? `0${hours}` : hours}`
      this.minutes = `${minutes < 10 ? `0${minutes}` : minutes}`
      this.seconds = `${seconds < 10 ? `0${seconds}` : seconds}`
    },
    handleDropdownClick (itemName) {
      if (itemName === 'Logout') {
        this.handleLogout()
      } else if (itemName === 'Help') {
        this.showIntercom(itemName)
      } else if (itemName === 'Feature Releases') {
        window.open('https://foreplay.co/ships', '_blank')
      }
    },
    getRoute (itemName) {
      if (itemName === 'Profile' || itemName === 'Settings') {
        return { name: 'AccountView' }
      }
      if (itemName === 'Notifications') {
        return ''
      }
      if (itemName === 'Perks & Discounts') {
        return { name: 'PerksView' }
      }
    },
    showIntercom (itemName) {
      if (itemName === 'Help') {
        window.Intercom('show')
      }
    },
    closeDropdown () {
      this.leaveDropdown = true
      setTimeout(() => {
        this.leaveDropdown = false
      }, 70)
    },
    folderSearch ($event) {
      this.search = $event.target.value
    }
  }
}
</script>
<style scoped lang="css">
theme-transition {
  transition: background-color 4s;
}

.gradient-library {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.06);
}

.gradient-dashboard {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.24) 0%, rgba(6, 7, 15, 0.00) 100%), rgba(255, 255, 255, 0.06);
}

.gradient-briefs {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.06);
}

.gradient-discovery {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.06);
}

.hover-border:hover{
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.06);
}

.HW_badge_cont {
  height: auto !important;
}

.purple-gradient{
  background-image: radial-gradient(circle at 100% 0, #0e061b, rgba(0, 0, 0, 0) 37%), radial-gradient(circle at -200%, #7740ff, rgba(0, 0, 0, 0) 88%);
}
.blue-gradient{
  background-image: radial-gradient(circle at 100% 0, #06081b, rgba(0, 0, 0, 0) 37%), radial-gradient(circle at -200%, #1f69ff, rgba(0, 0, 0, 0) 88%);
}

.green-gradient{
  background-image: radial-gradient(circle at 100% 0, #061b16, rgba(0, 0, 0, 0) 37%), radial-gradient(circle at -200%, #00a879, rgba(0, 0, 0, 0) 79%);
}
.red-gradient{
  background-image: radial-gradient(circle at 100% 0, #0e080c, rgba(0, 0, 0, 0) 37%), radial-gradient(circle at -200%, #ED615A, rgba(0, 0, 0, 0) 88%);
}
</style>
