<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7 6.25H4.75C3.92157 6.25 3.25 6.92157 3.25 7.75V14.5C3.25 15.3284 3.92157 16 4.75 16H15.25C16.0784 16 16.75 15.3284 16.75 14.5V7.75C16.75 6.92157 16.0784 6.25 15.25 6.25H13M7 6.25V4.75C7 3.92157 7.67157 3.25 8.5 3.25H11.5C12.3284 3.25 13 3.92157 13 4.75V6.25M7 6.25H13" 
    :stroke="stroke" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'BusinessNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>