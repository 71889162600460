<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 10V9.25C10 6.35051 7.64949 4 4.75 4H4V4.75C4 7.64949 6.35051 10 9.25 10H10ZM10 10V11.5M10 11.5C10 8.6005 12.3505 6.25 15.25 6.25H16V7C16 9.8995 13.6495 12.25 10.75 12.25H10M10 11.5V12.25M10 12.25V16" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'HealthNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>