<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.29289 5.70711L6.85355 8.14645C6.53857 8.46143 6.76165 9 7.20711 9H12.7929C13.2383 9 13.4614 8.46143 13.1464 8.14645L10.7071 5.70711C10.3166 5.31658 9.68342 5.31658 9.29289 5.70711Z" 
    fill="currentColor" />
    <path d="M9.29289 14.2929L6.85355 11.8536C6.53857 11.5386 6.76165 11 7.20711 11H12.7929C13.2383 11 13.4614 11.5386 13.1464 11.8536L10.7071 14.2929C10.3166 14.6834 9.68342 14.6834 9.29289 14.2929Z" 
    fill="currentColor" />
  </svg>
</template>

<script>
  export default {
    name: 'ChevronGrabberIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>