<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M18.25 7.75L10 11.5L1.75 7.75L10 4L18.25 7.75ZM18.25 7.75V13M4.75 9.25V12.4769C4.75 13.0298 5.05424 13.538 5.54167 13.7991L9.2917 15.808C9.73413 16.0451 10.2659 16.0451 10.7083 15.808L14.4583 13.7991C14.9458 13.538 15.25 13.0298 15.25 12.4769V9.25" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'EducationNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>