<template>
  <div class="flex flex-col items-center justify-center w-screen">
    <div
      class="w-1/3 p-20 rounded flex flex-col justify-center"
      style="min-width: 450px"
    >
      <!-- Brand -->
      <img
        src="https://assets.website-files.com/60d8c5270097eb0a6ccd32b7/60e09cff43b07dff3b341792_Adison.svg"
      >

      <div class="my-10 text-white font-bold text-center text-lg">
        Check your email, you should have been sent a magic link to access!
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoginEmailSentView'
}
</script>

<style scoped>

</style>
