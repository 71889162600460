<template>
  <div class="px-4 w-full">
    <BaseCard
      border
      class="rounded-xl w-full bg-white"
    >
      <ModularDetailsSectionHeader
        :expanded="expanded"
        :section="{ type: 'products', title: 'Products' }"
        badge-text="Filled"
        :filled="!!productFields.length"
        @toggleExpanded="$emit('toggleExpanded')"
        @deleteSection="$emit('deleteSection')"
      />

      <div v-if="expanded">
        <div class="flex flex-wrap gap-2 px-4">
          <div class="w-full">
            <div
              v-for="(product, index) in productFields"
              :key="index"
              class="py-3 w-full flex items-end gap-3 pr-6 text-sm border-b border-border-normal"
            >
              <BaseInputV2
                label="Link"
                class="w-2/5"
                icon
                :value="product.link"
                placeholder="www.example.com"
                @input="updateField(index, 'link', $event)"
              >
                <!-- Icon -->
                <img
                  slot="icon"
                  src="../../../assets/icons/chain.svg"
                >
              </BaseInputV2>

              <!-- Note -->
              <BaseInputV2
                label="Notes"
                class="w-2/5"
                :value="product.notes"
                placeholder="Description..."
                @input="updateField(index, 'notes', $event)"
              >
              <!-- Icon -->
              </BaseInputV2>

              <BaseButton
                outlined
                icon-only
                @click="removeProduct(index)"
              >
                <img src="../../../assets/icons/trash-gray.svg">
              </BaseButton>
            </div>
          </div>
        </div>

        <!-- Add Input Button -->
        <div class="flex flex-wrap gap-2 py-3 px-4">
          <BaseButton
            outlined
            @click="addField"
          >
            <div class="flex text-text-muted items-center">
              <img src="../../../assets/icons/plus-icon.svg">
              Add New
            </div>
          </BaseButton>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModularDetailsSectionHeader from './ModularDetailsSectionHeader.vue'

export default {
  name: 'Product',
  components: {
    ModularDetailsSectionHeader
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      productFields: [{ link: '', notes: '' }],
      timeout: null
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getInspoAds']),
    ...mapGetters('AuthModule', ['getTeam'])
  },
  mounted () {
    this.productFields = this.value
  },
  methods: {
    removeProduct (index) {
      this.productFields = this.productFields.filter((field, i) => i !== index)
    },
    updateField (index, field, newValue) {
      if (this.timeout) { clearTimeout(this.timeout) }

      const cloneArr = this.productFields
      this.productFields[index][field] = newValue

      this.productFields = cloneArr

      this.timeout = setTimeout(() => {
        this.$emit('update', this.index, this.productFields)
      }, 500)
    },
    addField () {
      this.productFields.push({ link: '', notes: '' })
    }
  }
}
</script>

<style scoped lang="css">
</style>
