<template>
  <div
    class="py-1.5 px-3 rounded-full flex items-center border transition no-select cursor-pointer"
    :class="selected ? 'text-primary-green-100 bg-primary-green-10 border-primary-green-100' : 'border-border-normal text-icon-normal' "
    @click="$emit('click')"
  >
    <!-- Icon -->
    <div class="pr-1.5">
      <slot name="icon" />
    </div>
    <!-- Text -->
    <slot />
  </div>
</template>
<script>
export default {
  name: 'ModularDetailsToggleOption',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>
