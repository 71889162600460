<template>
  <!-- Manage Board Modal -->
  <form @submit.prevent="handleCreateFolder">
    <BaseModal @close="$emit('close')">
      <template #toolbar>
        <div class="bg-white flex w-full rounded-md gap-x-3 -mb-3">
          <img
            src="../../assets/icons/new-folder-icon.svg"
            alt=""
          >
          <!-- Right Side -->
          <div class="transform -translate-y-0.5">
            <div class="text-lg font-semibold">
              Create Folder
            </div>
            <div class="text-sm text-gray-500">
              Folders keep your boards organized
            </div>
          </div>
        </div>
      </template>

      <template #default>
        <div class="border-t border-border-normal">
          <BaseInputV2
            v-model="folderName"
            label="Folder Name"
            placeholder="Client Boards"
            class="mt-5 mx-5"
          />
          <div class="w-full h-0 border-t border-border-normal my-5" />
          <div class="mx-5">
            <BaseButton
              primary
              :loading="loading"
              class="ml-auto"
              type="submit"
            >
              Create Folder
            </BaseButton>
          </div>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'FolderModal',
  data () {
    return {
      folderName: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['isFreeTier', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  mounted () {},
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    // Handle Delete Board
    async handleCreateFolder () {
      this.loading = true

      const folderPayload = {
        name: this.folderName,
        boardIds: [],
        createdAt: +new Date()
      }

      // Check for team
      if (this.getTeam) {
        folderPayload.teamId = this.getTeam.id
      }
      // Track event if folder is created
      window.analytics.track('Folder Created', { folderCreated: true })

      await FirebaseAPI.Folders.create(folderPayload)

      // Refetch the boards and folders for the sidebar
      this.fetchFolders()

      // finished actions
      this.loading = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
