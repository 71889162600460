const conditionalFilterMaps = {
  and: {
    tags: {
      name: 'andTag',
      value: 'id',
      transform: (value) => value
    },
    formats: {
      name: 'andFormat',
      value: 'name',
      transform: (value) => value.toLowerCase()
    },
    niches: {
      name: 'andNiche',
      value: 'name',
      transform: (value) => value
    },
    publisher_platform: {
      name: 'andPublisherPlatform',
      value: 'name',
      transform: (value) => value.toLowerCase()
    },
    board_ids: {
      name: 'andBoardId',
      value: 'id',
      transform: (value) => value
    },
    languages: {
      name: 'andLanguages',
      value: 'name',
      transform: (value) => value
    },
    brands: {
      name: 'andBrands',
      value: 'brandId',
      transform: (value) => value
    }
  },
  or: {
    tags: {
      name: 'orTag',
      value: 'id',
      transform: (value) => value
    },
    formats: {
      name: 'orFormat',
      value: 'name',
      transform: (value) => value.toLowerCase()
    },
    niches: {
      name: 'orNiche',
      value: 'name',
      transform: (value) => value
    },
    publisher_platform: {
      name: 'orPublisherPlatform',
      value: 'name',
      transform: (value) => value.toLowerCase()
    },
    board_ids: {
      name: 'orBoardId',
      value: 'id',
      transform: (value) => value
    },
    liveStatus: {
      name: 'orLiveStatus',
      value: 'value',
      transform: (value) => value
    },
    languages: {
      name: 'orLanguages',
      value: 'name',
      transform: (value) => value
    },
    brands: {
      name: 'orBrands',
      value: 'brandId',
      transform: (value) => value
    }
  }
}

export function createQuery (filters) {
  if (!filters) return {}

  const { andFilters, orFilters, sort, favorites } = filters

  let mappedAndFilters = {}
  let mappedOrFilters = {}

  if (andFilters) {
    mappedAndFilters = Object.keys(andFilters).reduce((formattedAndFilters, filterKey) => {
      const { name, value, transform } = conditionalFilterMaps.and[filterKey]

      andFilters[filterKey].forEach(filter => {
        if (formattedAndFilters[name]) {
          formattedAndFilters[name].push(transform(filter[value]))
        } else {
          formattedAndFilters[name] = [transform(filter[value])]
        }
      })

      return formattedAndFilters
    }, {})
  }

  if (orFilters) {
    mappedOrFilters = Object.keys(orFilters).reduce((formattedOrFilters, filterKey) => {
      const { name, value, transform } = conditionalFilterMaps.or[filterKey]

      orFilters[filterKey].forEach(filter => {
        if (formattedOrFilters[name]) {
          formattedOrFilters[name].push(transform(filter[value]))
        } else {
          formattedOrFilters[name] = [transform(filter[value])]
        }
      })

      return formattedOrFilters
    }, {})
  }

  const textSearch = filters.textSearch
  const ranBetweenStart = filters.ranBetweenStart
  const ranBetweenEnd = filters.ranBetweenEnd
  const startedRunningStart = filters.startedRunningStart
  const startedRunningEnd = filters.startedRunningEnd
  const savedBetweenStart = filters.savedBetweenStart
  const savedBetweenEnd = filters.savedBetweenEnd

  const transciptionOnly = filters.transciptionOnly
  const adLibraryIdExists = filters.adLibraryIdExists
  const size = filters.size

  return {
    sort,
    size,
    transciptionOnly,
    ranBetweenStart,
    ranBetweenEnd,
    savedBetweenStart,
    savedBetweenEnd,
    startedRunningStart,
    startedRunningEnd,
    adLibraryIdExists,
    favorites,
    textSearch,
    ...mappedAndFilters,
    ...mappedOrFilters
  }
}