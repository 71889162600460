<template>
  <div class="grid gap-5 w-4/5 grid-cols-2 mt-5">
    <InlineCard
      v-for="item in items"
      :key="item.title"
      :item="item"
    />
  </div>
</template>
<script>
import InlineCard from './InlineCard.vue'
export default {
  name: 'BottomCards',
  components: {
    InlineCard
  },
  data () {
    return {
      items: [
        {
          title: 'Book a Demo',
          description: 'Get a 1:1 Walkthrough',
          link: 'https://www.foreplay.co/book-demo'
        },
        {
          title: 'Help Articles',
          description: 'Foreplay Knowledgebase',
          commingSoon: true
        },
        {
          title: 'Follow us on Twitter',
          description: 'Real-Time product updates',
          link: 'https://twitter.com/foreplay_co'
        },
        {
          title: 'Perks & Discounts',
          description: 'Get Foreplay exclusive discounts',
          action: () => {
            this.$router.push({ name: 'PerksView' })
          }
        },
        {
          title: 'Affiliate Program',
          description: 'Earn 20% on everyone you send our way',
          link: 'https://www.foreplay.co/affiliates'
        },
        {
          title: 'Chat with Support',
          description: 'Open 9am - 5pm EST',
          action: () => {
            window.Intercom('show')
          }
        }
      ]
    }
  }
}
</script>
<style scoped>
