<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7.75 3.625H5.875C5.04657 3.625 4.375 4.29657 4.375 5.125V14.875C4.375 15.7034 5.04657 16.375 5.875 16.375H7.75M7.75 3.625H14.125C14.9534 3.625 15.625 4.29657 15.625 5.125V14.875C15.625 15.7034 14.9534 16.375 14.125 16.375H7.75M7.75 3.625V16.375M10.75 7H12.625M10.75 10H12.625" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'BookNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>