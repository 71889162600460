<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.16683 5.83317H10.8335M7.16683 16.8332H12.8335C13.5699 16.8332 14.1668 16.2362 14.1668 15.4998V4.49984C14.1668 3.76346 13.5699 3.1665 12.8335 3.1665H7.16683C6.43045 3.1665 5.8335 3.76346 5.8335 4.49984V15.4998C5.8335 16.2362 6.43045 16.8332 7.16683 16.8332Z"
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'MobileDeviceIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>