<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M16 5.875L14.0836 7.7379C13.4591 7.73371 12.8097 7.81436 12.1575 7.9891C11.5054 8.16383 10.9028 8.41865 10.3641 8.73453L7.17944 7.99506L7.83821 11.643C7.59542 12.3512 7.55011 13.0952 7.74416 13.8194C7.9749 14.6805 8.51268 15.3966 9.25 15.9222M5.5 16H14.5C15.3284 16 16 15.3284 16 14.5V5.5C16 4.67157 15.3284 4 14.5 4H5.5C4.67157 4 4 4.67157 4 5.5V14.5C4 15.3284 4.67157 16 5.5 16Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M11.4913 11.8674C11.6288 12.3806 11.3936 12.8895 10.9659 13.0041C10.5383 13.1187 10.0801 12.7956 9.94257 12.2823C9.80509 11.7692 10.0403 11.2602 10.4679 11.1456C10.8956 11.031 11.3538 11.3542 11.4913 11.8674Z" 
    :fill="stroke" />
    <path d="M16.137 10.6223C16.2746 11.1355 16.0393 11.6444 15.6116 11.759C15.184 11.8736 14.7258 11.5505 14.5883 11.0372C14.4508 10.524 14.6861 10.0151 15.1137 9.90053C15.5414 9.78593 15.9995 10.1091 16.137 10.6223Z" 
    :fill="stroke" />
    <path d="M14.5503 13.3715C14.642 13.7136 14.3003 14.1024 13.787 14.2399C13.2739 14.3774 12.7835 14.2116 12.6919 13.8694C12.6002 13.5273 12.9419 13.1385 13.4551 13.001C13.9683 12.8634 14.4586 13.0293 14.5503 13.3715Z" 
    :fill="stroke" />
  </svg>
</template>

<script>
  export default {
    name: 'PetsNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>