<template>
  <!-- Container -->
  <div
    class="h-screen fixed right-0 top-0 w-screen bg-neutral-500 bg-opacity-60"
    style="z-index: 50000"
    @click="closeDrawer"
  >
    <div
      class="card relative mt-11"
      @click.stop
    >
      <!-- Gradient Div  -->
      <div class="absolute h-16 w-full top-gradient flex items-start justify-between pt-2">
        <!-- Stupid but doing this to keep the text centered -->
        <div class="opacity-0 ml-5">
          <img src="../../../assets/icons/close.svg">
        </div>

        <!-- Drawer Text -->
        <div class="text-white flex items-center">
          <img
            class="mr-1.5"
            src="../../../assets/icons/magic-stick-top-white.svg"
          >
          <!--  -->
          <BaseText
            type="body"
            size="sm"
          >
            Adding inspiration to <span class="title-bubble">Brief</span> from <span class="title-bubble">Swipe File</span>
          </BaseText>
        </div>

        <div
          class="mr-5 cursor-pointer"
          @click="$emit('close')"
        >
          <img src="../../../assets/icons/close.svg">
        </div>
      </div>

      <!-- Drawer -->
      <div
        style="height: calc(100vh - 80px);"
        class="absolute bg-white h-full w-screen top-9 z-30 rounded-t-2xl"
      >
        <!-- Search Bar -->
        <div class="rounded-t-2xl relative z-50 top-bar flex items-center justify-between p-5">
          <!-- Text Input -->
          <BaseInputV2
            :value="textSearch"
            placeholder="Search for inspiration..."
            icon
            @input="(newValue) => newTextSearch(newValue)"
          >
            <template slot="icon">
              <img src="../../../assets/icons/search.svg">
            </template>
          </BaseInputV2>

          <!-- Sort Dropdown -->
          <div class="text-text-muted flex justify-end w-96">
            <BaseSelect
              :items="[
                { name: 'Newest', rightText: '1 → 9', value: 'desc' },
                { name: 'Oldest', rightText: '9 → 1', value: 'asc' },
              ]"
              :selected.sync="sortOrder"
              item-value="value"
              placement="null"
              right-icon
              @select="fetchAdvertisements"
            >
              <BaseText
                slot="reference"
                type="body"
                size="sm"
              >
                Sort By
              </BaseText>

              <template #item="{ item }">
                <div class="flex justify-between w-full">
                  <BaseText
                    type="label"
                    size="sm"
                  >
                    {{ item.name }}
                  </BaseText>

                  <div class="text-neutral-500">
                    <BaseText
                      type="body"
                      size="sm"
                    >
                      {{ item.rightText }}
                    </BaseText>
                  </div>
                </div>
              </template>

              <img
                slot="right-icon"
                src="../../../assets/icons/chevron-down-small.svg"
              >
            </BaseSelect>
          </div>
        </div>

        <!-- Advertisments -->
        <div class="px-5 bg-background-disabled overflow-y-scroll scrollbar-hide h-full">
          <AdvertisementList
            class="transition z-0 duration-700 pt-5 pb-52"
            :class="{
              'opacity-100': showAds,
              'opacity-0': !showAds
            }"
            :loaded.sync="loadingAdvertisements"
            :advertisements="advertisements"
            @getMoreAds="() => getMoreAds({})"
            @reload="fetchAdvertisements(false)"
          />
          <infinite-loading
            v-if="advertisements.length"
            :identifier="infiniteId"
            @infinite="getMoreAds"
          >
            <div slot="spinner">
              <BaseLoadingLogo
                v-if="showAds"
                :margin="2"
              />
            </div>
            <div slot="no-more" />
            <div slot="no-results" />
          </infinite-loading>
        </div>
      </div>
    </div>
    <BriefModeBanner
      v-if="showBanner"
      @reloadBrief="$emit('reloadBrief')"
      @close="$emit('close')"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import ForeplayAPI from '@/api/foreplayServer'

import AdvertisementList from '../../advertisements/AdvertisementList.vue'
import BriefModeBanner from '../../briefs/BriefModeBanner.vue'

export default {
  name: 'BrowseLibraryDrawer',
  components: {
    AdvertisementList,
    BriefModeBanner,
    InfiniteLoading
  },
  data: () => {
    return {
      textSearch: '',
      advertisements: [],
      loadingAdvertisements: false,
      infiniteId: +new Date(),
      sortOrder: [{ name: 'Newest', value: 'desc' }],
      showBanner: false,
      showAds: false
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getInspoAds', 'getIsInspoMode', 'getCurrentBriefId'])
  },
  async mounted () {
    await this.fetchAdvertisements()

    // Show Ads after 0.3 second
    setTimeout(() => {
      this.showAds = true
    }, 300)

    // Show the banner after 2 second
    setTimeout(() => {
      this.showBanner = true
    }, 1000)
  },
  methods: {
    ...mapMutations('BriefsModule', ['SET_INSPO_ADS', 'SET_IS_INSPO_MODE', 'SET_INSPO_BRIEF', 'SET_INSPO_ADS']),
    newTextSearch (value) {
      this.textSearch = value

      this.fetchAdvertisements()
    },
    closeDrawer () {
      this.SET_INSPO_ADS([])
      this.SET_IS_INSPO_MODE(false)

      this.SET_INSPO_ADS([])

      this.$emit('close')
    },
    async fetchAdvertisements (load = true) {
      this.loadingAdvertisements = load
      this.advertisements = []
      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getLibraryAds(
          null,
          {
            textSearch: this.textSearch,
            sort: this.sortOrder[0].value,
            orFilters: {
            }
          }
        )

        this.lastDocId = nextPage

        this.advertisements = results
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1
      }

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    async getMoreAds ($state) {
      console.log('getting more ads')
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        $state.complete()
        return
      }

      const { results, nextPage } = await ForeplayAPI.Ads.getLibraryAds(
        this.lastDocId,
        {
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
          }
        }
      )

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        $state.complete()
        return
      }

      this.advertisements = this.advertisements.concat(results)
      $state.loaded()
    }
  }
}
</script>
<style>
.top-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(90deg, #0085FF 0%, #0047FF 50%, #001650 100%);
  background-blend-mode: overlay, normal;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px 20px 0px 0px;
}

.title-bubble {
  padding: 0 6px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

.card {
  animation: appearBottom 300ms ease-in-out
}

@keyframes appearBottom {
  0% {
    opacity: 0;
    transform: translateY(400px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.top-bar {
  background: rgba(255, 255, 255, 0.92);
  /* Brief Header Card */

  box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13);
  backdrop-filter: blur(8px);
}
</style>
