<template>
  <!-- Main Container -->
  <div>
    <!-- Video and Button Container -->
    <div class="flex gap-14 py-8 px-8">
      <!-- Left Side -->
      <div class="flex flex-col items-center justify-center ml-10">
        <div
          class="flex justify-center items-center relative h-max w-max mb-4 "
        >
          <img
            class="w-22"
            src="../../../assets/images/icon-black.svg"
          >
          <div
            class="p-0.5 h-max rounded-full bg-white absolute -bottom-1.5 -right-1.5 w-9 h-9 flex justify-center items-center"
          >
            <img
              src="../../../assets/images/chrome-icon.svg"
              alt=""
              class="w-9 h-9 rounded-full box-border"
            >
          </div>
        </div>
        <a
          target="_blank"
          href="https://chrome.google.com/webstore/detail/ad-library-save-facebook/eaancnanphggbfliooildilcnjocggjm"
        >
          <BaseButton
            primary
          >
            Install Extension
          </BaseButton>
        </a>
      </div>
      <!-- Right Side Video -->
      <div>
        <iframe
          width="350"
          height="196.875"
          src="https://www.youtube.com/embed/HjFH4wtcdZU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </div>

    <!-- Description Text -->
    <div class="px-8 text-sm text-gray-500 pb-5">
      Our Chrome Extension is the <span class="font-semibold">best way</span> to save ads to your Library. Simply install the extension to display a “Save Button” accross multiple platforms.
    </div>
    <!-- Divider -->
    <div class="border-t border-gray-300 w-full mb-4" />

    <!-- Platforms Container -->
    <div class="px-8">
      <!-- Title -->
      <div class="text-gray-500 mb-2">
        Platforms
      </div>

      <!-- Platforms -->
      <div class="grid gap-4 grid-cols-2 grid-rows-3 text-gray-600">
        <a
          href="https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&media_type=all"
          class="group"
          target="_blank"
        >
          <BaseButton
            outlined
            class="w-full flex justify-start items-center rounded-md transition duration-200"
          >
            <div class="flex justify-start items-center rounded-md transition w-full  duration-200 gap-16">
              <div
                class="flex justify-start items-center rounded-md transition w-full  duration-200 -mr-3"
              >
                <img
                  src="../../../assets/images/alternate-facebook-icon.svg"
                  alt=""
                  class="w-6 h-6 mr-1"
                >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-muted"
                >
                  Facebook Ad Library
                </BaseText>
              </div>
              <img
                src="../../../assets/icons/external-tab.svg"
                class="w-4 h-4 ml-8"
              >
            </div>
          </BaseButton>
        </a>
        <a
          href="https://ads.tiktok.com/business/creativecenter/inspiration/topads/pc/en?rid=ik4uhbnw4kl&region=CA"
          class="group"
          target="_blank"
        >
          <BaseButton
            outlined
            class="w-full flex justify-start items-center rounded-md transition duration-200"
          >
            <div class="flex justify-start items-center rounded-md transition w-full  duration-200 gap-14">
              <div
                class="flex justify-start items-center rounded-md transition w-full  duration-200 -mr-3.5"
              >
                <img
                  src="../../../assets/images/alternate-tiktok-icon.svg"
                  alt=""
                  class="w-6 h-6 mr-1"
                >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-muted"
                >
                  TikTok Creative Center
                </BaseText>
              </div>

              <img
                src="../../../assets/icons/external-tab.svg"
                class="w-4 h-4 ml-7 "
              >
            </div>
          </BaseButton>
        </a>
        <a
          href="https://www.tiktok.com/"
          class="group"
          target="_blank"
        >
          <BaseButton
            outlined
            class="w-full flex justify-start items-center rounded-md transition duration-200"
          >
            <div class="flex justify-start items-center rounded-md transition w-full  duration-200 gap-24">
              <div
                class="flex justify-start items-center rounded-md transition w-full  duration-200"
              >
                <img
                  src="../../../assets/images/alternate-tiktok-icon.svg"
                  alt=""
                  class="w-6 h-6 mr-1"
                >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-muted"
                >
                  TikTok Organic
                </BaseText>
              </div>

              <img
                src="../../../assets/icons/external-tab.svg"
                class="w-4 h-4 ml-7"
              >
            </div>
          </BaseButton>
        </a>
        <a
          href="https://www.foreplay.co/post/save-ads-from-linkedin"
          class="group"
          target="_blank"
        >
          <BaseButton
            outlined
            class="w-full flex justify-start items-center rounded-md transition duration-200"
          >
            <div class="flex justify-between items-center rounded-md transition w-full  duration-200 gap-32">
              <div
                class="flex justify-start items-center rounded-md transition w-full  duration-200"
              >
                <img
                  src="../../../assets/images/alternate-linkden-icon.svg"
                  alt=""
                  class="w-6 h-6 mr-1"
                >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-muted"
                >
                  LinkedIn
                </BaseText>
              </div>
              <img
                src="../../../assets/icons/external-tab.svg"
                class="w-4 h-4 ml-10"
              >
            </div>
          </BaseButton>
        </a>
        <BaseButton
          outlined
          disabled
          class="w-full flex justify-start items-center rounded-md transition duration-200"
        >
          <div class="flex justify-start items-center rounded-md transition w-full  duration-200">
            <img
              src="../../../assets/images/alternate-instagram-icon.svg"
              alt=""
              class="w-6 h-6 mr-1"
            >
            <BaseText
              type="body"
              size="sm"
              class="text-text-muted mr-1"
            >
              Instagram Organic
            </BaseText>
            <div class=" pl-6 pr-2 text-text-disabled text-label-xs">
              Coming Soon
            </div>
          </div>
        </BaseButton>
        <BaseButton
          outlined
          disabled
          class="w-full flex justify-start items-center rounded-md transition duration-200"
        >
          <div class="flex justify-start items-center rounded-md transition w-full duration-200">
            <img
              src="../../../assets/images/alternate-youtube-icon.svg"
              alt=""
              class="w-6 h-6 mr-1"
            >
            <BaseText
              type="body"
              size="sm"
              class="text-text-muted mr-2"
            >
              YouTube Ads
            </BaseText>
            <div class=" ml-7 pl-8 text-text-disabled text-label-xs pr-2">
              Coming Soon
            </div>
          </div>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Extension'
}
</script>
