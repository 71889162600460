// Imports
import AdvertisementsServices from './AdvertisementsServices'
import BoardsServices from './BoardsServices'
import BrandServices from './BrandServices'
import BrandProfileServices from './BrandProfileServices'
import BriefsServices from './BriefsServices'
import BetaServices from './BetaServices'
import CommentsServices from './CommentsServices'
import FoldersServices from './FoldersServices'
import InvitationsServices from './InvitationsServices'
import NotesServices from './NotesServices'
import SearchServices from './SearchServices'
import SpyderServices from './SpyderServices'
import TagsServices from './TagsServices'
import TeamsServices from './TeamsServices'
import UniqueBrandsServices from './UniqueBrandsServices'
import UsersServices from './UsersServices'

export default {
  Advertisements: AdvertisementsServices,
  Beta: BetaServices,
  Boards: BoardsServices,
  BrandProfiles: BrandProfileServices,
  Brands: BrandServices,
  Briefs: BriefsServices,
  Comments: CommentsServices,
  Folders: FoldersServices,
  Invitations: InvitationsServices,
  Notes: NotesServices,
  Search: SearchServices,
  Spyder: SpyderServices,
  Tags: TagsServices,
  Teams: TeamsServices,
  UniqueBrands: UniqueBrandsServices,
  Users: UsersServices
}
