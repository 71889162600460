<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7.91667 10.625L9.16667 11.875L12.0833 8.95829M7.35123 4.21667L5.63249 3.94402C5.08945 3.85788 4.59579 4.27211 4.58633 4.82186L4.55637 6.56183C4.551 6.87386 4.38517 7.16109 4.11764 7.32175L2.62575 8.21768C2.15439 8.50074 2.04248 9.13539 2.38861 9.5626L3.48409 10.9147C3.68055 11.1572 3.73814 11.4838 3.63647 11.7789L3.06951 13.4242C2.89037 13.944 3.21259 14.5021 3.75234 14.6069L5.46068 14.9385C5.76703 14.998 6.0211 15.2112 6.13287 15.5026L6.75612 17.1274C6.95303 17.6407 7.5586 17.8611 8.03943 17.5944L9.56127 16.7504C9.83418 16.599 10.1658 16.599 10.4387 16.7504L11.9606 17.5944C12.4414 17.8611 13.047 17.6407 13.2439 17.1274L13.8671 15.5026C13.9789 15.2112 14.233 14.998 14.5393 14.9385L16.2477 14.6069C16.7874 14.5021 17.1096 13.944 16.9305 13.4242L16.3635 11.7789C16.2619 11.4838 16.3195 11.1572 16.5159 10.9147L17.6114 9.5626C17.9575 9.13539 17.8456 8.50074 17.3743 8.21768L15.8824 7.32175C15.6148 7.16108 15.449 6.87386 15.4436 6.56183L15.4137 4.82186C15.4042 4.27211 14.9106 3.85788 14.3675 3.94402L12.6488 4.21667C12.3406 4.26556 12.0289 4.15213 11.8242 3.91656L10.6828 2.60291C10.3222 2.18786 9.67779 2.18786 9.31717 2.60291L8.17579 3.91656C7.97111 4.15213 7.65945 4.26556 7.35123 4.21667Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'BrandV2Icon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>