<template>
  <v-date-picker
    v-model="dueDateLocal"
    :masks="{ input:['MMM DD, YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'] }"
    :attributes="calendarAttrs"
    :min-date="new Date()"
    title-position="left"
    color="gray"
    @input="emitUpdate"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div
        slot="reference"
        class="cursor-pointer"
        @click="togglePopover"
      >
        <div
          class="px-2 py-1.5 rounded-md hover:bg-neutral-25 hover:bg-opacity-80 transition"
        >
          <BaseText
            type="body"
            size="sm"
          >
            {{ inputValue }}
          </BaseText>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>
<script>
export default {
  name: 'BaseCalendar',
  props: {
    dueDate: {
      type: Number,
      default: +new Date()
    }
  },
  data () {
    return {
      dueDateLocal: this.dueDate,
      calendarAttrs: [
        [
          {
            highlight: {
              backgroundColor: '#fff'
            },
            // Just use a normal style
            contentStyle: {
              color: '#fff'
            }
          }
        ]
      ]
    }
  },
  methods: {
    emitUpdate (newDate) {
      this.$emit('update', +newDate)
    }
  }
}
</script>
