<template>
  <!-- Manage Board Modal -->
  <form>
    <BaseModal @close="$emit('close')">
      <template #toolbar>
        <div class="bg-white flex w-full rounded-md gap-x-3 -mb-3">
          <img
            src="../../assets/icons/new-folder-icon.svg"
            alt=""
          >
          <!-- Right Side -->
          <div
            class="transform -translate-y-0.5"
          >
            <BaseText
              type="label"
              size="md"
              class="text-text-muted"
            >
              You've created your team!
            </BaseText>
            <BaseText
              type="body"
              size="sm"
              class="text-sm text-gray-500"
            >
              We're setting up your team...
            </BaseText>
          </div>
        </div>
      </template>

      <template #default>
        <div class="border-t border-border-normal">
          <BaseText
            type="body"
            size="sm"
            class="mx-5 my-4"
          >
            We're moving your boards and folders to your new team. This may take a minute.
          </BaseText>
          <div
            class="pt-5 border-t border-border-normal"
          >
            <div class="flex mr-5">
              <BaseButton
                primary
                class="ml-auto"
                @click="$emit('close')"
              >
                Close
              </BaseButton>
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
  </form>
</template>

<script>
export default {
  name: 'CreateTeamModal'
}
</script>

<style scoped>

</style>
