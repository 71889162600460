<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path d="M5.16675 5.16663L10.8334 10.8333M10.8334 5.16663L5.16675 10.8333" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BoardRemoveIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 16
    }
  }
}
</script>

<style scoped></style>