<template>
  <BaseCard>
    <div
      class="rounded mb-10"
    >
      <!-- Header -->
      <div
        class="flex items-center justify-between p-3.5 px-5 border-b border-border-normal"
        style="height: 72px"
      >
        <!-- left container -->
        <div class="flex">
          <!-- Icon -->
          <div
            style="width: 45px;"
            class="icon-shadow mr-3 p-2.5 rounded-full flex items-center justify-center"
          >
            <img src="../../assets/icons/brush.svg">
          </div>
          <!-- Text -->
          <div>
            <!-- Title Container -->
            <div class="flex gap-1">
              <!-- Title -->
              <BaseText
                type="label"
                size="md"
              >
                <span class="text-text-muted">
                  Modular Details
                </span>
              </BaseText>
            </div>
            <!-- SubTitle -->
            <BaseText
              type="body"
              size="sm"
            >
              <span class="text-text-normal">
                Write free-form or add the sections you need
              </span>
            </BaseText>
            <div />
          </div>
        </div>

        <div
          v-if="brief.components?.length"
          class="flex gap-3"
        >
          <BaseButton
            text
            @click="expandAll"
          >
            <div class="flex items-center gap-1.5">
              <img src="../../assets/icons/expand.svg">
              <BaseText
                type="label"
                size="sm"
              >
                Expand
              </BaseText>
            </div>
          </BaseButton>
          <BaseButton
            text
            @click="collapseAll"
          >
            <div class="flex items-center gap-1.5">
              <img src="../../assets/icons/collapse.svg">
              <BaseText
                type="label"
                size="sm"
              >
                Collapse
              </BaseText>
            </div>
          </BaseButton>
        </div>
      </div>

      <div class="pt-6">
        <draggable
          v-if="brief.components?.length"
          v-model="brief.components"
          v-bind="dragOptions"
          handle=".trigger-drag"
          @change="emitUpdate"
        >
          <div
            v-for="(component, index) in brief.components"
            :key="index"
          >
            <div class="trigger-drag flex items-start w-full">
              <!-- Drag Icon -->
              <img
                class="ml-4 mt-3 cursor-move"
                src="../../assets/icons/drag1.svg"
              >
              <!-- Component UI -->
              <Assets
                v-if="component.type === 'assets'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('assets')"
                @deleteSection="deleteComponent(index)"
                @toggleExpanded="toggleCollapsed('assets')"
                @update="updateComponent"
              />

              <Audio
                v-if="component.type === 'audio'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('audio')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('audio')"
              />

              <Format
                v-if="component.type === 'format'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('format')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('format')"
              />

              <Description
                v-if="component.type === 'description'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('description')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('description')"
              />

              <AspectRatio
                v-if="component.type === 'aspectRatio'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('aspectRatio')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('aspectRatio')"
              />

              <ContentStyle
                v-if="component.type === 'contentStyle'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('contentStyle')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('contentStyle')"
              />

              <TargetDuration
                v-if="component.type === 'targetDuration'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('targetDuration')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('targetDuration')"
              />

              <Platforms
                v-if="component.type === 'platforms'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('platforms')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('platforms')"
              />

              <Products
                v-if="component.type === 'products'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('products')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('products')"
              />

              <LoomLinks
                v-if="component.type === 'loomLinks'"
                :value="component.value"
                :index="index"
                :expanded="!collapsedItems.includes('loomLinks')"
                @deleteSection="deleteComponent(index)"
                @update="updateComponent"
                @toggleExpanded="toggleCollapsed('loomLinks')"
              />
            </div>

            <!-- Selector to add modules -->
            <div class="opacity-0 hover:opacity-100 focus-within:opacity-100 transition-all duration-200 relative w-full px-4 pl-12 h-6 flex items-center">
              <div
                v-if="brief.components?.length && brief.components.length < componentItems.length"
                class="w-full bg-primary-green-25 h-0.5"
              />
              <AddNewSelector
                v-if="brief.components?.length && brief.components.length < componentItems.length"
                :current-items="brief.components"
                class="absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 "
                button-reference
                @createComponent="createComponent($event, index)"
              >
                <button
                  slot="button"
                  class="add-component focus:ring-2 ring-primary-green-100 ring-offset-1 ring-opacity-50"
                >
                  <img src="../../assets/icons/plus-icon-white.svg">
                </button>
              </AddNewSelector>
              <div
                v-if="brief.components?.length && brief.components.length < componentItems.length"
                class="w-full bg-primary-green-25 h-0.5"
              />
            </div>
          </div>
        </draggable>

        <!-- Empty State -->
        <div
          v-else
          class="grid grid-cols-4 h-96 p-5 gap-4"
        >
          <div
            v-for="(component, index) of componentItems"
            :key="index"
            class="rounded-xl border border-border-normal hover:bg-background-normal p-4 hover:border-border-hover transition duration-200 cursor-pointer select-none"
            @click="createComponent(component.type)"
          >
            <!-- Icon -->
            <img
              v-if="component.type === 'description'"
              src="../../assets/icons/document.svg"
            >
            <img
              v-if="component.type === 'aspectRatio'"
              src="../../assets/icons/size.svg"
            >
            <img
              v-if="component.type === 'format'"
              src="../../assets/icons/file.svg"
            >
            <img
              v-if="component.type === 'targetDuration'"
              src="../../assets/icons/clock2.svg"
            >
            <img
              v-if="component.type === 'platforms'"
              src="../../assets/icons/flag.svg"
            >
            <img
              v-if="component.type === 'contentStyle'"
              src="../../assets/icons/wand.svg"
            >
            <img
              v-if="component.type === 'products'"
              src="../../assets/icons/archive.svg"
            >
            <img
              v-if="component.type === 'assets'"
              src="../../assets/icons/file-chain.svg"
            >
            <img
              v-if="component.type === 'audio'"
              src="../../assets/icons/note.svg"
            >
            <img
              v-if="component.type === 'loomLinks'"
              src="../../assets/icons/loom.svg"
            >

            <!-- Title -->
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted pb-1 mt-3"
            >
              {{ component.title }}
            </BaseText>
            <!-- Description -->
            <BaseText
              type="body"
              size="sm"
              class="text-text-subdued"
            >
              {{ component.description }}
            </BaseText>
          </div>
        </div>

        <AddNewSelector
          v-if="brief.components?.length && brief.components.length < componentItems.length"
          :current-items="brief.components"
          @createComponent="createComponent"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

import AddNewSelector from './modularComponents/AddNewSelector.vue'
import Assets from './modularComponents/Assets.vue'
import AspectRatio from './modularComponents/AspectRatio.vue'
import Audio from './modularComponents/Audio.vue'
import ContentStyle from './modularComponents/ContentStyle.vue'
import Format from './modularComponents/Format.vue'
import Description from './modularComponents/Description.vue'
import TargetDuration from './modularComponents/TargetDuration.vue'
import Platforms from './modularComponents/Platforms.vue'
import Products from './modularComponents/Products.vue'
import LoomLinks from './modularComponents/LoomLinks.vue'

// Components and their default values
const componentItems = [
  {
    title: 'Description',
    description: 'Add a project description',
    type: 'description',
    value: ''
  },
  {
    title: 'Size & Aspect Ratios',
    description: 'Add dimension details',
    type: 'aspectRatio',
    value: []
  },
  {
    title: 'Format',
    description: 'Add file format details',
    type: 'format',
    value: ''
  },
  {
    title: 'Target Duration',
    description: 'Set the time',
    type: 'targetDuration',
    value: { hh: '', mm: '', ss: '' }
  },
  {
    title: 'Platforms',
    description: 'Add platform details',
    type: 'platforms',
    value: []
  },
  {
    title: 'Content Style',
    description: 'Add content styling',
    type: 'contentStyle',
    value: []
  },
  {
    title: 'Products',
    description: "Add project's products",
    type: 'products',
    value: [{ link: '', notes: '' }]
  },
  {
    title: 'Assets & Files',
    description: 'Add assets and files',
    type: 'assets',
    value: [{ link: '', notes: '' }]
  },
  {
    title: 'Music and Sound',
    description: 'Add music or sound',
    type: 'audio',
    value: [{ link: '', notes: '' }]
  },
  {
    title: 'Loom Video',
    description: 'Add Loom video',
    type: 'loomLinks',
    value: [{ link: '', notes: '' }]
  }
]

export default {
  name: 'ModularDetails',
  components: {
    Assets,
    AddNewSelector,
    AspectRatio,
    Audio,
    ContentStyle,
    draggable,
    Description,
    Format,
    TargetDuration,
    Products,
    Platforms,
    LoomLinks
  },
  props: {
    // Brief
    brief: {
      type: Object,
      default: () => {}
    },
    shared: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      editor: null,
      valueEmpty: true,
      value: '',
      componentItems: [],
      collapsedItems: []
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getInspoAds']),
    ...mapGetters('AuthModule', ['getTeam']),
    dragOptions () {
      return {
        animation: 400,
        group: 'description',
        ghostClass: 'ghost'
      }
    }
  },
  created () {
    this.componentItems = componentItems
    this.collapseAll()
  },
  methods: {
    collapseAll () {
      this.collapsedItems = this.brief.components.map(item => item.type)
    },
    expandAll () {
      this.collapsedItems = []
    },
    toggleCollapsed (section) {
      if (this.collapsedItems.includes(section)) {
        this.collapsedItems = this.collapsedItems.filter(item => item !== section)
      } else {
        this.collapsedItems.push(section)
      }
    },
    emitUpdate () {
      this.$emit('saveSection', {
        section: 'components',
        value: this.brief.components
      })
    },
    updateComponent (index, value) {
      this.brief.components[index].value = value
      this.emitUpdate()
    },
    deleteComponent (index) {
      this.brief.components.splice(index, 1)
      this.emitUpdate()
    },
    duplicateComponent (index) {
      const component = { ...this.brief.components[index] }
      this.brief.components = this.brief.components.concat(component)
      this.emitUpdate()
    },
    createComponent (type, index) {
      const componentToMake = componentItems.find(component => component.type === type)

      // Initialize components array if it doesn't exist
      if (!this.brief.components) {
        this.brief.components = []
      }

      // If an index is provided, insert the component at that index
      // Otherwise, add the component to the end of the array
      if (index !== undefined) {
        this.brief.components.splice(index + 1, 0, componentToMake)
      } else {
        this.brief.components.push(componentToMake)
      }

      this.emitUpdate()
    }
  }
}
</script>

<style>
.ghost {
  opacity: 0;
}

.add-component {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), #009F6D;
  /* Form/Button Stroke */

  border: 1px solid rgba(255, 255, 255, 0.12);
  /* Button/Primary/Briefs/Normal */

  box-shadow: 0px 0px 0px 1px #1F8E6B, inset 0px -1px 12px rgba(255, 255, 255, 0.12);
  border-radius: 999px;
}
</style>
