<template>
  <div>
    <!-- Top Container -->
    <div
      style="border-bottom: 1px solid rgba(18, 55, 105, 0.08)"
      class="flex items-center justify-between pb-4"
    >
      <div>
        <!-- Pronunciation/Niche -->
        <div class="flex items-center mb-2">
          <input
            v-show="pronunciation?.length || showPronunciationInput"
            ref="pronunciationInput"
            v-model="pronunciation"
            class="outline-none text-text-loud font-medium focus:w-24 w-24 transition-all"
            :style="`width: ${
              pronunciation?.length ? pronunciation?.length * 9 : 113
            }px;`"
            @blur="hidePronunciation"
            @input="saveBrandProfile"
          >
          <div
            v-if="!(pronunciation?.length || showPronunciationInput)"
            @click="showPronunciation"
          >
            <BaseBadge
              class="cursor-pointer"
              gray
              left-icon
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                reference="leftIcon"
              >
                <path
                  d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
                  stroke="#5E6678"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
              <BaseText
                type="label"
                size="xs"
              >
                Pronunciation
              </BaseText>
            </BaseBadge>
          </div>
          <div class="px-3">
            ·
          </div>
          <input
            v-show="niche?.length || showNicheInput"
            ref="nicheInput"
            v-model="niche"
            class="outline-none text-text-loud font-medium"
            @blur="hideNiche"
            @input="saveBrandProfile"
          >
          <div
            v-if="!(niche?.length || showNicheInput)"
            @click="showNiche"
          >
            <BaseBadge
              class="cursor-pointer"
              gray
              left-icon
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                reference="leftIcon"
              >
                <path
                  d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
                  stroke="#5E6678"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>

              <BaseText
                type="label"
                size="xs"
              >
                Niche / Vertical
              </BaseText>
            </BaseBadge>
          </div>
        </div>

        <!-- Name/Image -->
        <div class="flex items-center">
          <!-- Change Image -->
          <label
            v-if="!image"
            :for="`uploadInput`"
            class="text-center"
          >
            <div
              class="flex mr-3 cursor-pointer justify-center items-center rounded-full border border-border-normal hover:border-border-hover text-icon-normal p-2.5 transition"
            >
              <!-- Plus Icon -->
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5356 10.0005H6.46455M10.0001 13.536V6.46493M17.7084 10.0001C17.7084 14.2573 14.2573 17.7084 10.0001 17.7084C5.74289 17.7084 2.29175 14.2573 2.29175 10.0001C2.29175 5.74289 5.74289 2.29175 10.0001 2.29175C14.2573 2.29175 17.7084 5.74289 17.7084 10.0001Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </label>
          <label
            v-else
            :for="`uploadInput`"
            class="text-center"
          >
            <div
              class="group w-10 h-10 relative flex mr-3 cursor-pointer justify-center items-center rounded-full border border-border-normal hover:border-border-hover text-icon-normal transition"
            >
              <!-- Plus Icon -->
              <img
                :src="image"
                class="w-full h-full rounded-full"
              >
              <div
                class="absolute w-5 h-5 bg-background-hover opacity-0 group-hover:opacity-90 transition-opacity"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5356 10.0005H6.46455M10.0001 13.536V6.46493M17.7084 10.0001C17.7084 14.2573 14.2573 17.7084 10.0001 17.7084C5.74289 17.7084 2.29175 14.2573 2.29175 10.0001C2.29175 5.74289 5.74289 2.29175 10.0001 2.29175C14.2573 2.29175 17.7084 5.74289 17.7084 10.0001Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </label>
          <input
            :id="'uploadInput'"
            type="file"
            hidden
            :disabled="uploading"
            @drop.prevent="handleUploadFile"
            @change="handleAddFile($event)"
          >

          <!-- Change Brand Name -->
          <contenteditable
            v-model="name"
            class="text-display-xs text-text-loud whitespace-nowrap overflow-ellipsis font-medium caret-primary-green-100 rounded focus:bg-transparent focus:outline-none focus:ring-0 focus:border-none hover:bg-neutral-25 hover:bg-opacity-80 hover:outline-none hover:ring-0 hover:border-none flex-grow"
            onkeypress="return (this.innerText.length <= 32)"
            tag="div"
            :contenteditable="true"
            :no-html="true"
            @input="saveBrandProfile"
          />

          <!-- Border -->
          <div
            v-if="collapsed"
            class="mx-4 h-5 border-r border-border-normal"
          />

          <!-- Socials -->
          <div
            v-if="collapsed && !alwaysShowMore"
            class="flex justify-center gap-4 border-opacity-80"
          >
            <a
              v-if="website"
              :href="website"
              target="_blank"
            >
              <img
                src="../../../assets/images/safari-compass.svg"
                alt="safari compass"
                class="h-6 w-6"
              >
            </a>
            <a
              v-if="twitter"
              :href="twitter"
              target="_blank"
            >
              <img
                src="../../../assets/images/twitter-icon.svg"
                alt="twitter icon"
                class="h-6 w-6"
              >
            </a>
            <a
              v-if="facebook"
              :href="facebook"
              target="_blank"
            >
              <img
                src="../../../assets/images/alternate-facebook-icon.svg"
                alt="facebook icon"
                class="h-6 w-6"
              >
            </a>
            <a
              v-if="tiktok"
              :href="tiktok"
              target="_blank"
            >
              <img
                src="../../../assets/images/alternate-tiktok-icon.svg"
                alt="tiktok icon"
                class="h-6 w-6"
              >
            </a>
            <a
              v-if="linkedin"
              :href="linkedin"
              target="_blank"
            >
              <img
                src="../../../assets/images/alternate-linkden-icon.svg"
                alt="linkedin icon"
                class="h-6 w-6"
              >
            </a>
            <a
              v-if="pinterest"
              :href="pinterest"
              target="_blank"
            >
              <img
                src="../../../assets/images/pintrest-icon.svg"
                alt="pinterest icon"
                class="h-6 w-6"
              >
            </a>
            <a
              v-if="instagram"
              :href="instagram"
              target="_blank"
            >
              <img
                src="../../../assets/images/alternate-instagram-icon.svg"
                alt="instagram icon"
                class="h-6 w-6"
              >
            </a>
            <a
              v-if="youtube"
              :href="youtube"
              target="_blank"
            >
              <img
                src="../../../assets/images/alternate-youtube-icon.svg"
                alt="youtube icon"
                class="h-6 w-6"
              >
            </a>
          </div>
        </div>
      </div>

      <!-- Change Profile Button -->
      <BaseButton
        v-if="!alwaysShowMore"
        outlined
        @click="$emit('changeProfile')"
      >
        <BaseText
          type="label"
          size="sm"
        >
          Change Profile
        </BaseText>
      </BaseButton>
    </div>

    <!-- Brand Guidelines -->
    <div class="mt-4">
      <!-- Text -->
      <div>
        <!-- Title Container -->
        <div class="flex gap-1">
          <!-- Title -->
          <BaseText
            type="label"
            size="sm"
          >
            <span class="text-text-loud"> Brand Guidelines </span>
          </BaseText>
        </div>
        <!-- SubTitle -->
        <BaseText
          type="body"
          size="sm"
          class="text-left"
        >
          <span class="text-text-normal">
            Add overarching brand context & details.
          </span>
        </BaseText>
        <div />
      </div>

      <!-- Inputs -->
      <div class="mt-4">
        <!-- Brand Colors -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/calendar.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Brand Colors
            </BaseText>
          </div>

          <div
            class="text-neutral-900 flex items-center overflow-x-auto scrollbar-hide"
            :class="smallCard ? 'max-w-sm -mr-7' : 'max-w-3xl ml-2'"
          >
            <div
              v-for="(color, index) in brandColors"
              :key="index"
              class="mr-1"
            >
              <BaseColorTag
                :color="color"
                @input="updateBrandColors($event, index)"
                @remove="removeBrandColor(index)"
              />
            </div>
            <!-- Add New Button -->
          </div>
          <div
            class="w-11 pr-3 pt-1 h-full bg-white flex justify-center item-center"
          >
            <div
              class="cursor-pointer h-6 w-6 rounded-full flex items-center justify-center bg-background-normal hover:bg-background-hover hover:border-border-hover text-text-normal transition"
              @click="brandColors = [...brandColors, '#FFFFFF']"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                reference="leftIcon"
              >
                <path
                  d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
                  stroke="#5E6678"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <!-- Brand Guidelines -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/book.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Brand Guidelines Doc
            </BaseText>
          </div>

          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              no-newline
              placeholder="https://example.com/brand-guidelines"
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              :current-value="brandGuidelines"
              @update="updateFieldAndSave('brandGuidelines', $event)"
            />
          </div>
        </div>

        <!-- Fonts -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/font.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Fonts
            </BaseText>
          </div>

          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              no-newline
              placeholder="https://example.com/fonts"
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              :current-value="fonts"
              @update="updateFieldAndSave('fonts', $event)"
            />
          </div>
        </div>

        <!-- Music Genres -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/add-user.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Music Genres
            </BaseText>
          </div>

          <div
            class="flex items-center text-text-muted"
            :class="smallCard ? 'max-w-sm' : 'max-w-3xl ml-2'"
          >
            <div
              v-if="musicGenres.length"
              class="flex overflow-auto scrollbar-hide"
            >
              <div
                v-for="(genre, index) of musicGenres"
                :key="index"
                class="mr-1 w-max"
                @click.stop
              >
                <BaseBadge
                  right-icon
                  gray
                  class="w-max"
                >
                  <BaseText
                    type="label"
                    size="xs"
                    class="w-max"
                  >
                    {{ genre.itemText }}
                  </BaseText>

                  <div
                    slot="rightIcon"
                    class="rounded-full flex items-center justify-center hover:text-secondary-red-100 transition cursor-pointer"
                    @click.stop="removeMusicGenre(index)"
                  >
                    <!-- X Icon -->
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.45831 6.45801L13.5416 13.5413M13.5416 6.45801L6.45831 13.5413"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </BaseBadge>
              </div>
            </div>

            <BaseSelect
              :selected.sync="musicGenres"
              text-trigger
              multiple
              popper-left-center
              scrollable
              :items="[
                {
                  itemText: 'Acoustic',
                  value: 'acoustic',
                },
                {
                  itemText: 'Ambient',
                  value: 'ambient',
                },
                {
                  itemText: 'Blues',
                  value: 'blues',
                },
                {
                  itemText: 'Children',
                  value: 'children',
                },
                {
                  itemText: 'Cinematic',
                  value: 'cinematic',
                },
                {
                  itemText: 'Classical',
                  value: 'classical',
                },
                {
                  itemText: 'Corporate',
                  value: 'corporate',
                },
                {
                  itemText: 'Country',
                  value: 'country',
                },
                {
                  itemText: 'Electronic',
                  value: 'electronic',
                },
                {
                  itemText: 'Fantasy',
                  value: 'fantasy',
                },
                {
                  itemText: 'Folk',
                  value: 'folk',
                },
                {
                  itemText: 'Funk',
                  value: 'funk',
                },
                {
                  itemText: 'Hip Hop',
                  value: 'hip-hop',
                },
                {
                  itemText: 'Holiday',
                  value: 'holiday',
                },
                {
                  itemText: 'Indie',
                  value: 'indie',
                },
                {
                  itemText: 'Jazz',
                  value: 'jazz',
                },
                {
                  itemText: 'Latin',
                  value: 'latin',
                },
                {
                  itemText: 'Lofi & Chill Beats',
                  value: 'lofi-chill-beats',
                },
                {
                  itemText: 'Lounge',
                  value: 'lounge',
                },
                {
                  itemText: 'Pop',
                  value: 'pop',
                },
                {
                  itemText: 'Reggae',
                  value: 'reggae',
                },
                {
                  itemText: 'Retro',
                  value: 'retro',
                },
                {
                  itemText: 'Rock',
                  value: 'rock',
                },
                {
                  itemText: 'Singer-Songwriter',
                  value: 'singer-songwriter',
                },
                {
                  itemText: 'Soul & RnB',
                  value: 'soul-rnb',
                },
                {
                  itemText: 'World',
                  value: 'world',
                },
                {
                  itemText: 'Worship',
                  value: 'worship',
                },
              ]"
              @select="updateFieldAndSave('musicGenres', musicGenres)"
            >
              <!-- Reference point -->
              <!-- Add New Button -->
              <div
                slot="reference"
                class="cursor-pointer h-6 w-6 rounded-full flex items-center justify-center bg-background-normal hover:bg-background-hover hover:border-border-hover text-text-normal transition"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  reference="leftIcon"
                >
                  <path
                    d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
                    stroke="#5E6678"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>

              <!-- Item Slot -->
              <template #item="{ item }">
                <BaseText
                  type="label"
                  size="xs"
                >
                  {{ item.itemText }}
                </BaseText>
              </template>
            </BaseSelect>
          </div>
        </div>

        <!-- Voice / Brand Personality -->
        <div
          v-if="!collapsed || alwaysShowMore"
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/smiley.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Voice / Brand Personality
            </BaseText>
          </div>

          <div
            class="flex items-center text-text-muted"
            :class="smallCard ? 'max-w-sm' : 'max-w-3xl ml-2'"
          >
            <div
              v-if="brandVoice.length"
              class="flex overflow-auto scrollbar-hide"
            >
              <div
                v-for="(genre, index) of brandVoice"
                :key="index"
                class="mr-1 w-max"
              >
                <BaseBadge
                  right-icon
                  gray
                >
                  <BaseText
                    type="label"
                    size="xs"
                  >
                    {{ genre.itemText }}
                  </BaseText>

                  <div
                    slot="rightIcon"
                    class="rounded-full flex items-center justify-center hover:text-secondary-red-100 transition cursor-pointer"
                    @click.stop="removeBrandVoice(index)"
                  >
                    <!-- X Icon -->
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.45831 6.45801L13.5416 13.5413M13.5416 6.45801L6.45831 13.5413"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                </BaseBadge>
              </div>
            </div>

            <BaseSelect
              :selected.sync="brandVoice"
              text-trigger
              multiple
              scrollable
              popper-left-center
              :items="[
                {
                  itemText: 'Adventurous',
                  value: 'adventurous',
                },
                {
                  itemText: 'Authentic',
                  value: 'authentic',
                },
                {
                  itemText: 'Authoritative',
                  value: 'authoritative',
                },
                {
                  itemText: 'Bold',
                  value: 'bold',
                },
                {
                  itemText: 'Caring',
                  value: 'caring',
                },
                {
                  itemText: 'Edgy',
                  value: 'edgy',
                },
                {
                  itemText: 'Friendly',
                  value: 'friendly',
                },
                {
                  itemText: 'Informative',
                  value: 'informative',
                },
                {
                  itemText: 'Innovative',
                  value: 'innovative',
                },
                {
                  itemText: 'Inspiring',
                  value: 'inspiring',
                },
                {
                  itemText: 'Minimalistic',
                  value: 'minimalistic',
                },
                {
                  itemText: 'Nostalgic',
                  value: 'nostalgic',
                },
                {
                  itemText: 'Playful',
                  value: 'playful',
                },
                {
                  itemText: 'Quirky',
                  value: 'quirky',
                },
                {
                  itemText: 'Rebellious',
                  value: 'rebellious',
                },
                {
                  itemText: 'Romantic',
                  value: 'romantic',
                },
                {
                  itemText: 'Sophisticated',
                  value: 'sophisticated',
                },
                {
                  itemText: 'Trustworthy',
                  value: 'trustworthy',
                },
                {
                  itemText: 'Whimsical',
                  value: 'whimsical',
                },
                {
                  itemText: 'Witty',
                  value: 'witty',
                },
              ]"
              @select="updateFieldAndSave('brandVoice', brandVoice)"
            >
              <!-- Reference point -->

              <!-- Add New Button -->
              <div
                slot="reference"
                class="cursor-pointer h-6 w-6 rounded-full flex items-center justify-center bg-background-normal hover:bg-background-hover hover:border-border-hover text-text-normal transition"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  reference="leftIcon"
                >
                  <path
                    d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
                    stroke="#5E6678"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>

              <!-- Item Slot -->
              <template #item="{ item }">
                <BaseText
                  type="label"
                  size="xs"
                >
                  {{ item.itemText }}
                </BaseText>
              </template>
            </BaseSelect>
          </div>
        </div>

        <!-- Tagline -->
        <div
          v-if="!collapsed || alwaysShowMore"
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/speaker.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Tagline
            </BaseText>
          </div>

          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              no-newline
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              placeholder="Your tagline...."
              :current-value="tagline"
              @update="updateFieldAndSave('tagline', $event)"
            />
          </div>
        </div>

        <!-- Mission Statement -->
        <div
          v-if="!collapsed"
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/pencil.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Mission Statement
            </BaseText>
          </div>

          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              placeholder="Your mission statement...."
              no-newline
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              :current-value="missionStatement"
              @update="updateFieldAndSave('missionStatement', $event)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Web Properties -->
    <div
      v-if="!collapsed || alwaysShowMore"
      class="mt-4"
    >
      <!-- Text -->
      <div>
        <!-- Title Container -->
        <div class="flex gap-1">
          <!-- Title -->
          <BaseText
            type="label"
            size="sm"
          >
            <span class="text-text-loud"> Web Properties </span>
          </BaseText>
        </div>
        <!-- SubTitle -->
        <BaseText
          type="body"
          size="sm"
          class="text-left"
        >
          <span class="text-text-normal">
            Link out to all areas where creatives can find you online.
          </span>
        </BaseText>
        <div />
      </div>

      <!-- Inputs -->
      <div class="mt-4">
        <!-- Website -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Website
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="yourwebsite.com"
              :current-value="website"
              @update="updateFieldAndSave('website', $event)"
            />
          </div>
        </div>

        <!-- Website Alt -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Website Alt
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="altwebsite.com"
              :current-value="websiteAlt"
              @update="updateFieldAndSave('websiteAlt', $event)"
            />
          </div>
        </div>

        <!-- Facebook -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Facebook
            </BaseText>
          </div>

          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="facebook.com/pages/pageId"
              :current-value="facebook"
              @update="updateFieldAndSave('facebook', $event)"
            />
          </div>
        </div>

        <!-- Instagram -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Instagram
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="instagram.com/username"
              :current-value="instagram"
              @update="updateFieldAndSave('instagram', $event)"
            />
          </div>
        </div>

        <!-- TikTok -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              TikTok
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="tiktok.com/@username"
              :current-value="tiktok"
              @update="updateFieldAndSave('tiktok', $event)"
            />
          </div>
        </div>

        <!-- LinkedIn -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              LinkedIn
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="linkedin.com/in/username"
              :current-value="linkedin"
              @update="updateFieldAndSave('linkedin', $event)"
            />
          </div>
        </div>

        <!-- Youtube -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              YouTube
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="youtube.com/user/username"
              :current-value="youtube"
              @update="updateFieldAndSave('youtube', $event)"
            />
          </div>
        </div>

        <!-- Twitter -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Twitter
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="twitter.com/username"
              :current-value="twitter"
              @update="updateFieldAndSave('twitter', $event)"
            />
          </div>
        </div>

        <!-- Pinterest -->
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/link.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Pinterest
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl max-h-2">
            <BaseContentEditable
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              no-newline
              placeholder="pinterest.com/username"
              :current-value="pinterest"
              @update="updateFieldAndSave('pinterest', $event)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Brand Assets -->
    <div
      v-if="!collapsed || alwaysShowMore"
      class="mt-4"
    >
      <!-- Text -->
      <div>
        <!-- Title Container -->
        <div
          class="flex flex-col mt-4 flex-nowrap text-left justify-start flex-start gap-1"
        >
          <!-- Title -->
          <BaseText
            type="label"
            size="sm"
            class="flex"
          >
            <span class="text-text-loud"> Brand Assets </span>
          </BaseText>
        </div>
        <!-- SubTitle -->
        <BaseText
          type="body"
          size="sm"
          class="text-left"
        >
          <span class="text-text-normal">
            Include links to re-useable asset folders to inject on every brief.
          </span>
        </BaseText>
        <div />
      </div>

      <div class="mt-4">
        <!-- Pinterest -->
        <div
          v-for="(asset, index) of assetLinks"
          :key="index"
          class="group flex items-start mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/cloud-file.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Asset Link
            </BaseText>
          </div>
          <div class="text-text-muted flex max-w-2xl">
            <BaseContentEditable
              no-newline
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              placeholder="Empty..."
              :current-value="asset"
              @update="updateAssetLink($event, index)"
            />

            <!-- Put an X here on focus within -->
            <div
              class="opacity-0 group-hover:opacity-100 text-icon-normal cursor-pointer"
              @click="removeAssetLink(index)"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.45831 6.45801L13.5416 13.5413M13.5416 6.45801L6.45831 13.5413"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <BaseButton
          text
          @click="addAssetLink"
        >
          <div class="flex text-text-muted items-center">
            <img src="../../../assets/icons/plus-icon.svg">
            Add Field
          </div>
        </BaseButton>
      </div>
    </div>

    <!-- Additional Information -->
    <div
      v-if="!collapsed || alwaysShowMore"
      class="mt-4"
    >
      <!-- Text -->
      <div>
        <!-- Title Container -->
        <div class="flex gap-1 flex-col justify-start">
          <!-- Title -->
          <BaseText
            type="label"
            size="sm"
            class="text-left"
          >
            <span class="text-text-loud"> Additional Information </span>
          </BaseText>
        </div>
        <!-- SubTitle -->
        <BaseText
          type="body"
          size="sm"
          class="text-left"
        >
          <span class="text-text-normal">
            Write, link or explain any crucial information not offered in the
            form.
          </span>
        </BaseText>
        <div />
      </div>

      <!-- Aditional Information -->
      <div class="mt-4">
        <div
          class="flex items-center mb-2"
          style="height: 32px"
        >
          <div
            class="text-text-normal w-56 flex items-center whitespace-nowrap"
          >
            <img
              src="../../../assets/icons/pencil.svg"
              class="mr-1.5"
            >
            <BaseText
              type="body"
              size="sm"
            >
              Additional Information
            </BaseText>
          </div>
          <div class="text-text-muted max-w-2xl">
            <BaseContentEditable
              no-newline
              class="w-max text-left overflow-auto scrollbar-hide whitespace-nowrap"
              style="min-width: 300px"
              :class="smallCard ? 'max-w-sm' : 'max-w-2xl'"
              placeholder="linkedin.com/in/username"
              :current-value="aditionalInformation"
              @update="updateFieldAndSave('aditionalInformation', $event)"
            />
          </div>
        </div>
      </div>

      <BaseButton
        class="mt-8"
        destroy
        :disabled="loadingDelete"
        @click="deleteBrand"
      >
        <BaseText
          type="label"
          size="sm"
        >
          Delete brand
        </BaseText>
      </BaseButton>
    </div>

    <!-- Collapse -->
    <div
      v-if="!collapsed && !alwaysShowMore"
      class="flex items-center justify-between cursor-pointer mt-12 h-8"
      @click="collapsed = true"
    >
      <!-- Left Line -->
      <div class="border-b border-border-normal w-full" />
      <!-- Text -->
      <div class="whitespace-nowrap select-none flex justify-center mx-6">
        <BaseText
          type="label"
          size="sm"
        >
          Show Less
        </BaseText>
        <div class="w-5">
          <img
            class="ml-1.5"
            src="../../../assets/icons/chevron-top-small.svg"
          >
        </div>
      </div>
      <!-- Left Line -->
      <div class="border-b border-border-normal w-full" />
      <div />
    </div>

    <!-- Expand -->
    <div
      v-if="collapsed && !alwaysShowMore"
      class="flex items-center justify-between cursor-pointer mt-12 h-8"
      @click="collapsed = false"
    >
      <!-- Left Line -->
      <div class="border-b border-border-normal w-full" />
      <!-- Text -->
      <div class="whitespace-nowrap select-none flex justify-center mx-6">
        <BaseText
          type="label"
          size="sm"
        >
          Show More
        </BaseText>
        <div class="w-5 transform rotate-180">
          <img
            class="mr-1.5"
            src="../../../assets/icons/chevron-top-small.svg"
          >
        </div>
      </div>
      <!-- Left Line -->
      <div class="border-b border-border-normal w-full" />
      <div />
    </div>
  </div>
</template>
<script>
import FirebaseAPI from '@/api/firebase'
import firebase from '@/api/config/FirebaseConfig'
import { nanoid } from 'nanoid'

export default {
  name: 'BrandProfileDetails',
  components: {},
  props: {
    brandProfile: {
      type: Object,
      default: () => {}
    },
    alwaysShowMore: {
      type: Boolean,
      default: () => false
    },
    smallCard: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    // pronunciation
    pronunciation: null,

    // Timeout
    timeoutId: null,

    // Niche
    niche: null,

    // Profile Image
    profileImage: null,

    // Name
    name: 'Brand Name',

    // Brand Colors
    brandColors: [],

    // Brand Guidline Docs
    brandGuidelines: '',

    // Fonts
    fonts: '',

    // Music Genres
    musicGenres: [],

    // Brand Voice
    brandVoice: [],

    // Tagline
    tagline: '',

    // Mission Statement
    missionStatement: '',

    // Website
    website: '',

    // Website Alt
    websiteAlt: '',

    // Facebook
    facebook: '',

    // Instagram
    instagram: '',

    // TikTok
    tiktok: '',

    // LinkedIn
    linkedin: '',

    // Youtube
    youtube: '',

    // Twitter
    twitter: '',

    // Pinterest
    pinterest: '',

    // Asset Links
    assetLinks: [],

    // Aditional Information
    aditionalInformation: '',

    // Image
    image: null,

    // Settings
    showPronunciationInput: false,
    showNicheInput: false,
    uploading: false,
    loadingDelete: false,
    collapsed: true
  }),
  async mounted () {
    console.log('BrandProfileDetails.vue - Mounted', this.brandProfile)
    // Copy the prop data over to data
    this.pronunciation = this.brandProfile.pronunciation || null
    this.niche = this.brandProfile.niche || null
    this.profileImage = this.brandProfile.profileImage || null
    this.name = this.brandProfile.name || 'Brand Name'
    this.brandColors = this.brandProfile.brandColors || []
    this.brandGuidelines = this.brandProfile.brandGuidelines || ''
    this.fonts = this.brandProfile.fonts || ''
    this.musicGenres = this.brandProfile.musicGenres || []
    this.brandVoice = this.brandProfile.brandVoice || []
    this.tagline = this.brandProfile.tagline || ''
    this.missionStatement = this.brandProfile.missionStatement || ''
    this.website = this.brandProfile.website || ''
    this.websiteAlt = this.brandProfile.websiteAlt || ''
    this.facebook = this.brandProfile.facebook || ''
    this.instagram = this.brandProfile.instagram || ''
    this.tiktok = this.brandProfile.tiktok || ''
    this.linkedin = this.brandProfile.linkedin || ''
    this.youtube = this.brandProfile.youtube || ''
    this.twitter = this.brandProfile.twitter || ''
    this.pinterest = this.brandProfile.pinterest || ''
    this.assetLinks = this.brandProfile.assetLinks || []
    this.aditionalInformation = this.brandProfile.aditionalInformation || ''
    this.image = this.brandProfile.image || null
  },
  methods: {
    // Save Brand Profile
    async saveBrandProfile () {
      const savePayload = {
        pronunciation: this.pronunciation,
        niche: this.niche,
        profileImage: this.profileImage,
        name: this.name,
        brandColors: this.brandColors,
        brandGuidelines: this.brandGuidelines,
        fonts: this.fonts,
        musicGenres: this.musicGenres,
        brandVoice: this.brandVoice,
        tagline: this.tagline,
        missionStatement: this.missionStatement,
        website: this.website,
        websiteAlt: this.websiteAlt,
        facebook: this.facebook,
        instagram: this.instagram,
        tiktok: this.tiktok,
        linkedin: this.linkedin,
        youtube: this.youtube,
        twitter: this.twitter,
        pinterest: this.pinterest,
        assetLinks: this.assetLinks,
        aditionalInformation: this.aditionalInformation,
        image: this.image
      }

      // Save the brand profile
      await FirebaseAPI.BrandProfiles.update(this.brandProfile.id, savePayload)
      //

      if (this.timeoutId) clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        window.analytics.track('Brand Profile Saved', savePayload)
      }, 5000)
    },
    updateFieldAndSave (field, value) {
      console.log('updating', field, value)
      // Update the field
      this[field] = value

      // Save the brand profile
      this.saveBrandProfile()
    },
    // Delete Brand
    async deleteBrand () {
      this.loadingDelete = true

      // Delete Profile Image
      if (this.image) {
        try {
          // Create a reference to the file to delete
          const storage = firebase.app().storage('gs://foreplay-manual-upload')

          // // Create a storage reference from our storage service
          const storageRef = storage.ref()
          const desertRef = storageRef.child(
            this.image.split('foreplay-manual-upload/')[1]
          )

          // // Delete the file
          await desertRef.delete()
        } catch (error) {
          console.error('Error Deleting Profile Image', error)
        }
      }

      // Delete the brand
      await FirebaseAPI.BrandProfiles.remove(this.brandProfile.id)

      this.loadingDelete = false

      this.$emit('changeProfile')
    },
    removeMusicGenre (index) {
      // Remove the music genre
      this.musicGenres.splice(index, 1)

      // Save the brand profile
      this.saveBrandProfile()
    },
    removeBrandColor (index) {
      // Remove the music genre
      this.brandColors.splice(index, 1)

      // Save the brand profile
      this.saveBrandProfile()
    },
    removeBrandVoice (index) {
      // Remove the music genre
      this.brandVoice.splice(index, 1)

      // Save the brand profile
      this.saveBrandProfile()
    },
    updateBrandColors (value, index) {
      this.brandColors[index] = value

      // Save the brand profile
      this.saveBrandProfile()
    },
    updateAssetLink (value, index) {
      this.assetLinks[index] = value

      // Save the brand profile
      this.saveBrandProfile()
    },
    addAssetLink () {
      this.assetLinks = [...this.assetLinks, '']

      this.saveBrandProfile()
    },
    removeAssetLink (index) {
      // Remove the music genre
      this.assetLinks.splice(index, 1)

      // Save the brand profile
      this.saveBrandProfile()
    },
    // Inputs
    showPronunciation () {
      // Show the pronunciation input
      this.showPronunciationInput = true

      // Auto focus the input
      setTimeout(() => {
        // Wait for it to render
        this.$refs.pronunciationInput.focus()
      }, 100)
    },
    hidePronunciation () {
      if (this.pronunciation?.length) {
        return
      }

      // Hide the pronunciation input
      this.showPronunciationInput = false
    },
    showNiche () {
      // Show the pronunciation input
      this.showNicheInput = true

      // Auto focus the input
      setTimeout(() => {
        // Wait for it to render
        this.$refs.nicheInput.focus()
      }, 100)
    },
    hideNiche () {
      if (this.niche?.length) {
        return
      }

      // Hide the pronunciation input
      this.showNicheInput = false
    },
    // File Upload
    async handleAddFile (event, index) {
      if (this.uploading) return
      this.filesCompleted = 0

      const filesToUpload = [...event.target.files]

      // Validate Files
      const invalidFiles = filesToUpload.find(
        (f) => !['image/png', 'image/jpeg'].includes(f.type)
      )

      if (invalidFiles) {
        // Show error
        this.$showAlert({
          message: 'Invalid File Type (PNG or JPEG)',
          type: 'error'
        })
        return
      }

      this.uploading = true

      // Check for a current image
      if (this.image) {
        // Create a reference to the file to delete
        const storage = firebase.app().storage('gs://foreplay-manual-upload')

        // // Create a storage reference from our storage service
        const storageRef = storage.ref()
        const desertRef = storageRef.child(
          this.image.split('foreplay-manual-upload/')[1]
        )

        // // Delete the file
        await desertRef.delete()
      }

      for (const fileToUpload of filesToUpload) {
        // Create File Name (Random Folder Path)
        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}`

        // Dont use the default brief submissions bucket
        const storageRef = firebase
          .app()
          .storage('gs://foreplay-manual-upload')
          .ref(filePath)

        const firstFile = fileToUpload
        await storageRef.put(firstFile)

        const fileLocation = `https://storage.googleapis.com/foreplay-manual-upload/${filePath}`

        this.image = fileLocation

        await this.saveBrandProfile()
      }

      // Show that all files are uploaded
      this.uploading = false
      this.$showAlert({
        message: 'Files Image Uploaded',
        type: 'success'
      })
    }
  }
}
</script>
<style scoped></style>
