<template>
  <TransitionGroup
    name="list"
    tag="div"
    enter-active-class="transition-all duration-500 ease-out"
    enter-class="transform translate-y-full opacity-0"
    enter-to-class="transform translate-y-0 opacity-100"
    leave-active-class="transition-all duration-500 ease-in"
    leave-class="transform translate-y-0 opacity-100"
    leave-to-class="transform translate-y-full opacity-0"
  >
    <slot />
  </TransitionGroup>
</template>

<script>
export default {
  name: 'BaseCardTransitionGroup'
}
</script>
