<template>
  <!-- Base Multi Select -->
  <div class="base-single-select select-none px-px">
    <!-- Label -->
    <div
      v-if="label"
      class="base-single-select--label"
    >
      {{ label }}
    </div>

    <VuePopper
      ref="popper"
      trigger="clickToOpen"
      :disabled="disabled"
      :options="{
        placement: 'bottom',
        offset: {offset: '10px'}
      }"
      @show="$emit('focused')"
      @hide="$emit('unfocused')"
    >

      <!-- Dropdown -->
      <div
        class="base-single-select--dropdown"
        :class="{
          'out-of-bounds': outOfBounds,
          'in-bounds': !outOfBounds,
          'near-bounds': nearBounds,
          'align-top': alignTop,
          'align-center': alignCenter,
          'short-dropdown': shortDropdown
        }"
      >
        <div
          v-if="!noSearch"
          class="bg-white border-b border-gray-300 flex items-center w-full px-3 py-2 sticky top-0"
        >
          <svg
            class="h-5 w-5 text-gray-400 mt-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
          <input
            v-model="searchQuery"
            placeholder="Search"
            class="pl-1 pt-1 outline-none w-11/12 flex-shrink-0"
          >
        </div>

        <div
          v-else-if="message"
          class="bg-white border-b border-gray-300 text-xs flex items-center w-full px-2 py-2 sticky top-0 cursor-default text-gray-600"
        >
          <slot name="messageIcon" />
          {{ message }}
        </div>

        <div
          v-if="getSearchedItems.length"
          class="px-1 py-1"
        >
          <div
            v-for="(item, index) in getSearchedItems"
            :key="index"
            style="border-radius: 5px"
            class="base-single-select--dropdown--item group cursor-pointer"
            :class="{
              'hover:bg-background-normal hover:text-text-loud': getTheme === 'blue' && !item.disabled,
              'bg-teal-light': value === item && !item.disabled,
              'text-text-loud': selected.map((e) => e[itemValue]).includes(item[itemValue]) && getTheme === 'blue' && !item.disabled,
              'text-gray-600': !selected.map((e) => e[itemValue]).includes(item[itemValue]) && !item.disabled,
              'opacity-20 cursor-not-allowed': item.disabled,
              'hover:bg-background-normal': toggle
            }"
            @click="handleSelectItem(item)"
          >
            <slot
              name="item"
              :item="item"
            >
              {{ item[itemText] ? item[itemText] : item }}
            </slot>
            <div v-if="toggle">
              <BaseToggle
                :value="selected.map((e) => e[itemValue]).includes(item[itemValue])"
                class="ml-auto"
                @click="item.action('selected')"
              />
            </div>
            <svg
              v-if="selected.map((e) => e[itemValue]).includes(item[itemValue]) && !toggle"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-5 h-5 text-blue-500 pr-1 transform rotate-12"
              :class="{
                'text-blue-500': getTheme === 'blue',
                'text-purple-500': getTheme === 'purple',
              }"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </div>
        </div>

        <div
          v-else-if="searchQuery"
          class="p-2"
        >
          Not found.
        </div>
      </div>

      <div
        slot="reference"
        class="group rounded transition"
        :class="{
          'bg-blue-100 text-blue-600':
            (selected.length || focused) && getTheme === 'blue' && !noHighlight,
          'bg-purple-100 text-purple-600':
            (selected.length || focused) && getTheme === 'purple' && !noHighlight,
        }"
        @click="closeDrawer"
      >
        <div
          class="flex items-center rounded-r rounded-l px-2 transition duration-200 h-8 divide-x border-border-normal"
          :class="{
            'bg-blue-100 text-blue-600':
              (selected.length || focused) && getTheme === 'blue' && !noHighlight,
            'bg-purple-100 text-purple-600':
              selected.length && getTheme === 'purple' && !noHighlight,
            'text-gray-600': !selected.length && !focused,
          }"
        >
          <div
            v-if="hasIconSlot"
            class="mr-1 my-1"
          >
            <slot name="icon" />
          </div>

          <!-- Selection List -->
          <div
            v-if="value && !multiple"
            class="flex flex-wrap"
            @click="handleSelectItem(item)"
          >
            <slot
              name="selected"
              :item="value"
            >
              {{ value }}
            </slot>
          </div>

          <div
            v-else-if="selected.length && multiple && !toggle"
            class="flex items-start pr-0.5 overflow-x-auto scrollbar-hide w-9/12 font-medium"
          >
            {{ selected.length }}
          </div>

          <!-- Placeholder -->
          <div
            v-else
            class="text-grey"
          >
            {{ placeholder }}
          </div>
        </div>
      </div>
    </VuePopper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VuePopper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'BaseFilterSelect',
  components: {
    VuePopper
  },
  props: {
    // Value
    value: {
      type: String,
      default: ''
    },
    // A value to pass back up to the parent
    uniqueValue: {
      type: Number,
      default: null
    },
    // Label
    label: {
      type: String,
      default: ''
    },
    // List Items
    items: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean
    },
    selected: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: ''
    },
    noSearch: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    noHighlight: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outOfBounds: {
      type: Boolean,
      default: false
    },
    nearBounds: {
      type: Boolean,
      default: false
    },
    alignCenter: {
      type: Boolean,
      default: false
    },
    shortDropdown: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
    alignTop: {
      type: Boolean,
      default: false
    },
    toggle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchQuery: '',
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    hasIconSlot () {
      return !!this.$slots.icon
    },
    getSearchedItems () {
      if (this.noSearch) return this.items
      return this.items.filter((e) =>
        e[this.itemValue]?.toLowerCase().includes(this.searchQuery)
      )
    }
  },
  watch: {
    selected (newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.selectedItems = this.selected
      }
    }
  },
  methods: {
    // Close Drawer
    closeDrawer () {
      if (this.$refs.popper.showPopper) {
        setTimeout(() => {
          this.$refs.popper.doClose()
        }, 70)
      }
    },
    // Handle Select Item
    handleSelectItem (item) {
      if (this.disabled || item.disabled) return

      if (!this.multiple) {
        if (this.selectedItems[0] && this.selectedItems[0].name === item.name) {
          this.selectedItems = []
        } else {
          this.selectedItems = [item]
        }

        this.closeDrawer()
      } else {
        if (
          !this.selectedItems
            .map((e) => e[this.itemValue])
            .includes(item[this.itemValue])
        ) {
          this.selectedItems = [...this.selectedItems, item]
        } else {
          this.selectedItems = this.selectedItems.filter(
            (e) => e[this.itemValue] !== item[this.itemValue]
          )
        }
      }
      this.$emit('update', this.selectedItems)
    }
  }
}
</script>

<style scoped lang="sass">
.in-bounds
  top: 25px !important

.near-bounds
  top: 25px !important
  left: -80px !important

.out-of-bounds
  left: -170px !important

.align-top
  top: 0px !important
  left: -80px !important

.align-center
  top: -10px !important
  left: 0px !important

.short-dropdown
  max-height: 200px !important

.base-single-select
  @apply cursor-pointer flex flex-col text-xs lg:text-sm

  &--label
    margin-bottom: 3px
    @apply text-gray-500

  &--dropdown
    animation: fade 100ms ease-in
    margin-top: 15px
    max-height: 50vh
    min-width: 200px
    max-width: 250px
    z-index: 10000
    @apply bg-white border border-gray-200 overflow-y-auto w-full shadow-lg rounded-lg

    &--item
      @apply flex items-center justify-between p-2 w-full transition-all my-px

@keyframes fade
  0%
    opacity: 0

  100%
    opacity: 100%
</style>
