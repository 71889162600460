<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M8.12544 7L6.62544 8.5L8.12544 10M11.0611 15.6894L17.1465 9.60392C17.7489 9.00152 17.7293 8.01893 17.1032 7.44107L13.8064 4.39779C13.5293 4.14203 13.166 4 12.7889 4H7.21192C6.83483 4 6.47159 4.14203 6.1945 4.39779L2.89762 7.44107C2.27161 8.01893 2.25197 9.00152 2.85438 9.60392L8.9398 15.6894C9.52555 16.2751 10.4753 16.2751 11.0611 15.6894Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'JewelryNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>