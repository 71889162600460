<template>
  <!--  -->
  <div style="max-width: 1000px">
    <transition
      name="fade"
      mode="out-in"
    >
      <section aria-labelledby="payment-details-heading">
        <div class="shadow overflow-visible">
          <div class="bg-white py-6">
            <div class="px-4">
              <h2
                id="payment-details-heading"
                class="text-lg leading-6 font-medium text-gray-900"
              >
                Public Details
              </h2>
            </div>

            <div class="mt-6 px-4">
              <div class="flex items-end">
                <img
                  :src="getUser.avatar"
                  class="w-24 h-24 rounded-md border border-gray-300"
                >
                <div class="ml-2 cursor-pointer">
                  <label
                    for="uploadInput"
                    class="relative underline block w-full text-center cursor-pointer"
                  >
                    Chose an Image
                  </label>
                  <input
                    :id="'uploadInput'"
                    type="file"
                    hidden
                    @change="handleUploadFile($event)"
                  >
                </div>
              </div>

              <BaseInput
                v-model="expertProfile.firstName"
                class="mt-6 max-w-sm"
                label="First Name"
                placeholder="John"
              />

              <BaseInput
                v-model="expertProfile.lastName"
                class="mt-2 max-w-sm"
                label="Last Name"
                placeholder="Smith"
              />

              <BaseInput
                v-model="expertProfile.twitter"
                class="mt-2 max-w-sm"
                label="Twitter"
                placeholder="https://twitter.com/JohnSmith"
              />

              <BaseInput
                v-model="expertProfile.instagram"
                class="mt-2 max-w-sm"
                label="Instagram"
                placeholder="https://instagram.com/JohnSmith"
              />

              <BaseInput
                v-model="expertProfile.linkedin"
                class="mt-2 max-w-sm"
                label="LinkedIn"
                placeholder="https://linkedin.com/li/JohnSmith"
              />

              <BaseInput
                v-model="expertProfile.youtube"
                class="mt-2 max-w-sm"
                label="YouTube"
                placeholder="https://youtube.com/JohnSmith"
              />

              <BaseInput
                v-model="expertProfile.facebook"
                class="mt-2 max-w-sm"
                label="Facebook"
                placeholder="https://facebook.com/JohnSmith"
              />

              <BaseInput
                v-model="expertProfile.website"
                class="mt-2 max-w-sm"
                label="Website"
                placeholder="https://johnsmith.com"
              />
            </div>
            <div class="border-t border-gray-300 mt-6 px-6 py-4">
              <div class="text-lg leading-6 font-medium text-gray-900">
                Expert Folder
              </div>

              <BaseSingleSelect
                class="w-1/2 max-w-sm mt-4"
                :items="getFolders"
                :selected.sync="selectedFolders"
                item-value="name"
                @select="changeSelectedFolder"
                @input="changeSelectedFolder"
              >
                <template #icon>
                  Folder
                </template>

                <template #item="{ item }">
                  <div class="flex items-center">
                    {{ item.icon }} {{ item.name }}
                  </div>
                </template>

                <template #selection="{ item }">
                  {{ item.icon }} {{ item.name }}
                </template>
              </BaseSingleSelect>

              <div class="text-lg leading-6 font-medium text-gray-900 mt-4">
                Affiliate UTM
              </div>

              <BaseInput
                v-model="expertProfile.utm"
                class="mt-2 max-w-sm"
                label="UTM ID"
                placeholder="john-smith"
              />
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end">
            <BaseButton
              primary
              :loading="loadingSave"
              :disabled="loadingSave"
              @click="handleSaveUser"
            >
              Save
            </BaseButton>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { nanoid } from 'nanoid'
import FirebaseAPI from '@/api/firebase'
import firebase from '@/api/config/FirebaseConfig'

import { incrementCount } from '../../../api/firebase/CounterServices'

export default {
  name: 'ExpertsProfileInformation',
  data () {
    return {
      expertProfile: {},
      loadingSave: false,
      previousExpertFolderId: null,
      selectedFolders: []
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('BoardsModule', ['getFolders'])
  },
  mounted () {
    this.expertProfile = this.getUser.expertProfile || {
      firstName: this.getUser.first_name,
      lastName: this.getUser.last_name
    }

    this.previousExpertFolderId = this.expertProfile.selectedFolder
    this.selectedFolders = this.expertProfile.selectedFolder ? [this.getFolders.find(f => f.id === this.expertProfile.selectedFolder)] : []
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    changeSelectedFolder () {
      this.expertProfile.selectedFolder = this.selectedFolders[0].id
    },
    // Handle Save User
    async handleSaveUser () {
      // const db = firebase.firestore()
      // Ensure utm is correct
      if (this.expertProfile.utm?.length && this.expertProfile.utm?.includes('?via=')) {
        this.$showAlert({
          message: 'Your UTM must not include "?via="',
          type: 'error'
        })

        return
      }

      if (!this.expertProfile.firstName || !this.expertProfile.lastName) {
        this.$showAlert({
          message: 'Your provide a first name and last name',
          type: 'error'
        })

        return
      }

      try {
        this.loadingSave = true

        const user = firebase.auth().currentUser
        await FirebaseAPI.Users.update(user.uid, { expertProfile: this.expertProfile })

        this.previousExpertFolderId = this.expertProfile.selectedFolder

        // Update the global user state with the updated experts object
        this.SET_USER({
          ...this.getUser,
          expertProfile: this.expertProfile
        })

        this.$showAlert({
          message: 'Your information has been saved',
          type: 'success'
        })
      } catch (error) {
        console.log(error)

        this.$showAlert({
          message: 'Something went wrong saving your information',
          type: 'error'
        })
      } finally {
        this.loadingSave = false
      }
    },
    async handleUploadFile (event, index) {
      // Set upload loading
      const fileToUpload = event.target.files[0]

      // Validate Files
      const invalidFiles = !['image/png', 'image/jpeg'].includes(fileToUpload.type)

      if (invalidFiles) {
        // Show error
        this.$showAlert({
          message: 'Invalid File Type, must be a JPEG or PNG',
          type: 'error'
        })
        return
      }

      // Create File Name (Random Folder Path)
      const folderName = nanoid()
      const fileName = nanoid()
      const filePath = `${folderName}/${fileName}`

      const storageRef = firebase.app().storage('gs://foreplay-user-avatars').ref(filePath)

      const firstFile = fileToUpload
      await storageRef.put(firstFile)

      const fileLocation = `https://storage.googleapis.com/foreplay-user-avatars/${filePath}`

      const toUpdate = {
        avatar: fileLocation
      }
      // Update the experts avatar in firebase and the user avatar
      const user = firebase.auth().currentUser
      await FirebaseAPI.Users.update(user.uid, toUpdate)

      // // Set the local user
      this.SET_USER({ ...this.getUser, ...toUpdate })

      // Show that all files are uploaded
      this.uploading = false
      this.$showAlert({
        message: 'Profile Image Updated',
        type: 'success'
      })
    }
  }
}
</script>
