
<template>
  <!-- Manage Board Modal -->
  <BaseModal
    :no-exit="true"
    no-toolbar
  >
    <template #default>
      <div>
        <div class="flex px-4 border-b">
          <img
            class="h-14 mr-4"
            src="../../../assets/images/paymentIcon.png"
          >
          <div>
            <div class="text-md font-medium text-black w-full">
              <span>Payment Past Due</span>
            </div>
            <div class="text-sm text-red-600">
              Your recent subscription payment failed.
            </div>
          </div>
        </div>
      </div>
      <div
        class="px-4"
      >
        <!-- Save Board -->
        <div>
          <div class="flex flex-col items-center justify-center py-10">
            <div class="font-medium text-base text-gray-600">
              Contact Account Admin
            </div>
            <div class="text-sm text-gray-500 my-4">
              You do not have permission to update payment details.
            </div>

            <div class="text-sm text-gray-500">
              Please contact <span class="font-semibold text-gray-600">{{ teamOwnerEmail }}</span> to regain access.
            </div>
          </div>
          <div class="flex justify-center w-full">
            <p
              class="text-center mt-4 underline hover:text-blue-400 cursor-pointer"
              @click="handleLogout"
            >
              Logout of foreplay
            </p>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TrialEndedModal',
  props: {
    // Board
    board: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      teamOwnerEmail: ''
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getTeam', 'getTeamSubscriptionState'])
  },
  async mounted () {
    await this.getTeamOwnerEmail()
  },
  methods: {
    ...mapActions('AuthModule', ['logoutUser']),
    // Handle Logout User
    async getTeamOwnerEmail () {
      const owner = this.getTeam.created_by

      const teamOwner = await firebase.firestore().collection('auth-users').doc(owner).get()
      this.teamOwnerEmail = teamOwner.data().email || teamOwner.data().firstName
    },
    handleLogout () {
      this.logoutUser()
    }
  }
}
</script>

<style scoped></style>
