<template>
  <div
    class="relative"
    :class="brandProfilesPage ? '' : 'h-52 overflow-y-scroll'"
  >
    <!-- Brand Grid -->
    <div
      v-if="brandProfiles.length"
      class="grid grid-cols-4 gap-4"
    >
      <div
        class="group h-32 pt-4 select-none cursor-pointer flex flex-col items-center justify-start rounded-xl border-2 border-dashed border-border-normal hover:border-border-hover focus:border-border-pressed transition duration-200"
        @click="$emit('createNewBrand')"
      >
        <!-- Icon -->
        <div class="rounded-full mb-2.5 w-10 h-10 bg-background-normal group-hover:bg-background-hover focus:bg-background-pressed transition duration-200 flex items-center justify-center p-2.5">
          <!--  -->
          <img src="../../../assets/icons/plus-icon.svg">
        </div>

        <!-- Text -->
        <div class="text-text-muted group-hover:text-text-normal transition duration-200">
          <BaseText
            type="label"
            size="sm"
          >
            Create brand profile
          </BaseText>
        </div>
      </div>

      <!-- Brand Profile -->
      <div
        v-for="(brandProfile, index) of brandProfiles"
        :key="index"
        class="group flex flex-col items-center justify-start h-32 border border-border-normal hover:border-border-hover duration-200 rounded-xl pt-4 cursor-pointer"
        @click="$emit('selectBrandProfile', brandProfile)"
      >
        <!-- Image -->
        <img
          v-if="brandProfile.image"
          :src="brandProfile.image"
          class="w-10 h-10 rounded-full object-cover"
        >
        <div
          v-else
          class="w-10 h-10 rounded-full bg-background-normal"
        />

        <!-- Text -->
        <div class="mt-2.5 text-center">
          <!-- Name -->
          <div class="text-text-muted group-hover:text-text-normal transition duration-200">
            <BaseText
              type="label"
              size="sm"
            >
              {{ brandProfile.name }}
            </BaseText>
          </div>

          <!-- Website -->
          <div class="text-text-disabled group-hover:text-text-normal transition duration-200">
            <BaseText
              type="body"
              size="sm"
              class="w-56 overflow-hidden overflow-ellipsis whitespace-nowrap"
            >
              {{ brandProfile.website }}
            </BaseText>
          </div>
        </div>
      </div>

      <div
        v-if="brandProfiles.length && brandProfiles.length > 3 && !brandProfilesPage"
        style="z-index: 10; height: 100px; background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);"
        class="absolute bottom-0 w-full rounded-b-2xl pointer-events-none"
      />
    </div>

    <!-- Empty State -->
    <div
      v-else
      class="group select-none w-full cursor-pointer flex flex-col items-center justify-center h-32 rounded-xl border-2 border-dashed border-border-normal hover:border-border-hover focus:border-border-pressed transition duration-200"
      @click="$emit('createNewBrand')"
    >
      <!-- Icon -->
      <div class="rounded-full mb-3 bg-background-normal group-hover:bg-background-hover focus:bg-background-pressed transition duration-200 flex items-center justify-center p-2.5">
        <!--  -->
        <img src="../../../assets/icons/plus-icon.svg">
      </div>

      <!-- Text -->
      <div class="text-text-muted group-hover:text-text-normal transition duration-200">
        <BaseText
          type="label"
          size="sm"
        >
          Create brand profile
        </BaseText>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandProfileGrid',
  props: {
    brandProfiles: {
      type: Array,
      default: () => []
    },
    brandProfilesPage: {
      type: Boolean
    }
  }
}
</script>
<style scoped>
.new-brand-container {
  width: 100%;
  height: 100%;
  border: 1px dashed #e5e5e5;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
