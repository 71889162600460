// Imports
import AxiosConfig from '../config/AxiosConfig'

const createSubscription = async (priceId, customerId, promoCode, paymentMethodId, taxId, teamMembers, spyderQuantity, paymentSchedule, showDeal) => {
  const { data } = await AxiosConfig.post('/createSubscription', {
    priceId,
    teamMembers,
    customerId,
    promoCode,
    paymentMethodId,
    taxId,
    spyderQuantity,
    paymentSchedule,
    showDeal
  })

  return data
}

const createSpyderSubscription = async (priceId, customerId, userId, newLimit, upsellOffer = false) => {
  const { data } = await AxiosConfig.post('/createSpyderSubscription', {
    priceId,
    userId,
    customerId,
    newLimit,
    upsellOffer
  })

  return data
}

const checkPromoCode = async (priceId, promoCode, spyderQuantity, paymentSchedule) => {
  const { data } = await AxiosConfig.post('/checkPromoCode', {
    priceId,
    promoCode,
    spyderQuantity,
    paymentSchedule
  })

  return data
}

const getCustomerAddress = async (customerId) => {
  const { data } = await AxiosConfig.post('/getCustomerAddress', {
    customerId
  })

  return data
}

const getCustomerPaymentMethod = async (customerId) => {
  const { data } = await AxiosConfig.post('/getCustomerPaymentMethod', {
    customerId
  })

  return data
}

const createSubscriptionSetup = async (customerId) => {
  const { data } = await AxiosConfig.post('/createSubscriptionSetup', {
    customerId
  })

  return data
}

const updatePaymentMethod = async (paymentMethodId, customerId) => {
  const { data } = await AxiosConfig.post('/updatePaymentMethod', {
    customerId,
    paymentMethodId
  })

  return data
}

const updateAddress = async (customerId, address, taxId, name) => {
  const { data } = await AxiosConfig.post('/updateAddress', {
    customerId,
    address,
    taxId,
    name
  })

  return data
}

const upgradeSubscription = async (priceId, customerId) => {
  const { data } = await AxiosConfig.post('/upgradeSubscription', {
    priceId,
    customerId
  })

  return data
}

const getHostedReciept = async (chargeId) => {
  const { data } = await AxiosConfig.post('/getHostedReciept', {
    chargeId
  })

  return data
}

const cancelSubscription = async (subscriptionId) => {
  const { data } = await AxiosConfig.post('/cancelSubscription', {
    subscriptionId
  })

  return data
}

const retryPastDuePayment = async (customerId) => {
  await AxiosConfig.post('/retryPastDuePayment', {
    customerId
  })
}

const updateSubscriptionQuantity = async (userEmail, quantity) => {
  const { data } = await AxiosConfig.post('/updateSubscriptionQuantity', {
    userEmail,
    quantity
  })

  return data
}

export default {
  cancelSubscription,
  checkPromoCode,
  createSubscription,
  createSubscriptionSetup,
  createSpyderSubscription,
  getCustomerAddress,
  getCustomerPaymentMethod,
  getHostedReciept,
  retryPastDuePayment,
  updateAddress,
  updatePaymentMethod,
  upgradeSubscription,
  updateSubscriptionQuantity
}
