<template>
  <BaseModal
    no-exit
    no-toolbar
    card-width="490px"
  >
    <template #default>
      <SpyderUpsell
        v-if="currentStep === 1"
        @nextStep="nextStep"
      />
      <ReviewOffer
        v-else
        @complete="$emit('close')"
      />
    </template>
  </BaseModal>
</template>
<script>
import SpyderUpsell from './postPurchaseModalStates/SpyderUpsell'
import ReviewOffer from './postPurchaseModalStates/ReviewOffer'
import { mapGetters } from 'vuex'

export default {
  name: 'PostPurchaseModal',
  components: {
    SpyderUpsell,
    ReviewOffer
  },
  data: function () {
    return {
      currentStep: 1
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUpsellStep'])
  },
  mounted () {
    this.currentStep = this.getUpsellStep
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    nextStep () {
      this.currentStep = this.currentStep + 1
    }
  }
}
</script>
