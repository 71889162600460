<template>
  <!-- Brands Table -->
  <div class="bg-white rounded-md border border-gray-200 mb-10">
    <!-- Table Header -->
    <div class="grid grid-cols-4 lg:grid-cols-6 2xl:grid-cols-10 w-full py-2 px-4 bg-gray-100 text-gray-600 rounded-t-md">
      <span class="col-span-2 lg:col-span-4 2xl:col-span-8">
        Brands
      </span>
      <span class="col-span-1">
        Favorites
      </span>
      <span class="col-span-1 whitespace-nowrap">
        FB Ad Libraries
      </span>
    </div>
    <!-- Rows -->
    <!-- For Each Submission -->
    <BaseLoadingLogo v-if="loadingBrands" />

    <div v-else-if="brands.length">
      <router-link
        v-for="(brand, index) in brands"
        :key="index"
        class="group grid grid-cols-4 lg:grid-cols-6 2xl:grid-cols-10 cursor-pointer w-full py-2 px-4 border-b hover:bg-gray-50 transition duration-200"
        :to="{name: 'BrandView', params: {id: brand.id}}"
      >
        <div class="col-span-2 lg:col-span-4 2xl:col-span-8 h-8 flex items-center transition text-lg font-semibold">
          <img
            :src="brand.avatar"
            class="h-8 w-8 rounded-full mr-4"
          >
          <!-- File Name -->
          {{ brand.name }}
        </div>
        <!-- Creator -->
        <div class="col-span-1 flex flex-col items-start">
          <div
            class="h-8 flex items-center justify-between bg-gray-100 w-24 p-1 pr-2 rounded text-gray-600"
            @click.prevent="favoriteBrand(brand.brandId)"
          >
            <!--  -->
            <FavoriteIcon
              no-hover
              :active="favoritedBrands.includes(brand.brandId)"
            />
            Favorite
          </div>
        </div>
        <!-- Buttons -->
        <div class="w-36 col-span-1 h-full flex items-center">
          <a
            v-if="brand.adLibraryId"
            :href="`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${brand.adLibraryId}`"
            target="_blank"
            class="flex items-center bg-gray-100 p-1 px-2 rounded text-gray-600"
            @click.stop
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 60 60"
              fill="none"
              class="mr-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M51.9148 0H8.08567C3.62047 0 0.000549316 3.61977 0.000549316 8.08512V51.9146C0.000549316 56.3799 3.62032 59.9997 8.08567 59.9997H29.702L29.7389 38.5591H24.1686C23.4447 38.5591 22.8571 37.9737 22.8544 37.2498L22.8276 30.3386C22.8249 29.6108 23.4142 29.0192 24.142 29.0192H29.7022V22.3413C29.7022 14.5915 34.4352 10.3717 41.3485 10.3717H47.0214C47.7472 10.3717 48.3357 10.9602 48.3357 11.6861V17.5137C48.3357 18.2393 47.7476 18.8276 47.0221 18.828L43.5407 18.8297C39.7811 18.8297 39.0531 20.6162 39.0531 23.2381V29.0194H47.3143C48.1015 29.0194 48.7122 29.7067 48.6194 30.4885L47.8003 37.3997C47.7219 38.0611 47.1611 38.5594 46.4952 38.5594H39.0899L39.0531 60H51.9151C56.3803 60 60.0001 56.3802 60.0001 51.915V8.08512C60 3.61977 56.3802 0 51.9148 0Z"
                fill="currentColor"
              />
            </svg>

            Ad Library
          </a>
        </div>
      </router-link>
    </div>

    <!-- Infinite Load Brands -->
    <infinite-loading
      v-if="brands.length"
      :identifier="infiniteId"
      @infinite="getMoreBrands"
    >
      <div slot="spinner">
        <BaseLoadingLogo :margin="2" />
      </div>
      <div slot="no-more" />
      <div slot="no-results" />
    </infinite-loading>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'

import FavoriteIcon from '../globals/Icons/FavoriteIcon.vue'

export default {
  name: 'LibraryBrandView',
  components: {
    FavoriteIcon,
    InfiniteLoading
  },
  props: {
    // Advertisements
    favoriteFilter: {
      type: Boolean,
      default: () => false
    },
    selectedNiches: {
      type: Array,
      default: () => []
    },
    sortOrder: {
      type: Array,
      default: () => [{ name: 'A-Z', value: 'asc' }]
    },
    updateQuery: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      brands: [],
      favoritedBrands: [],
      brandTotal: '0',
      loadingBrands: false,
      adLibraryId: null
    }
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('AuthModule', ['getUser', 'getUserEmail', 'getUserName', 'getTeam']),
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  watch: {
    updateQuery (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBrands()
      }
    },
    favoriteFilter (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchBrands()
      }
    },
    selectedNiches (newValue, oldValue) {
      if (newValue[0]?.name !== oldValue[0]?.name) {
        this.fetchBrands()
      }
    }
  },
  mounted () {
    this.fetchBrands()
    this.favoritedBrands = this.getUser.favoritedBrands || []
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async favoriteBrand (brandId) {
      const favoritedBrands = this.favoritedBrands.includes(brandId)
        ? this.favoritedBrands.filter(bId => bId !== brandId)
        : [...this.favoritedBrands, brandId]

      // Update the firebase user
      const user = firebase.auth().currentUser
      await FirebaseAPI.Users.update(user.uid, {
        favoritedBrands
      })

      // Add to local state
      this.favoritedBrands = favoritedBrands

      // Update global state
      this.SET_USER({
        ...this.getUser,
        favoritedBrands
      })
    },
    async firebaseQuery () {
      // Setup
      const db = firebase.firestore()

      let query
      const createdCondition = this.getTeam ? ['teamId', '==', this.getTeam.id] : ['createdBy', '==', this.getUser.user_id]

      if (this.selectedNiches.length) {
        // Check for selected niches
        const nichesArray = this.selectedNiches.map(n => n.name)
        query = await db
          .collection('user-brands')
          .where('niches', 'array-contains-any', nichesArray)
          .where(...createdCondition)
          .orderBy('name', this.sortOrder[0].value)
      } else {
        query = await db
          .collection('user-brands')
          .where(...createdCondition)
          .orderBy('name', this.sortOrder[0].value)
      }

      return query
    },
    async fetchFavoritedBrands () {
      const db = firebase.firestore()
      this.lastDocId = null

      // Loop through all the favorited brands and query them
      const nichesArray = this.selectedNiches.map(n => n.name)

      const allBrands = await Promise.all(
        this.favoritedBrands.map(async brandId => {
          let query = db
            .collection('user-brands')
            .where('brandId', '==', brandId)

          if (nichesArray.length) {
            query = query.where('niches', 'array-contains-any', nichesArray)
          }

          const snapshot = await query.get()

          // If someone has favourited a discovery brand and havent saved it
          if (snapshot.empty) return

          const docInstance = snapshot.docs[0]

          return {
            ...docInstance.data(),
            id: docInstance.id
          }
        })
      )

      // Sort all the values Based on the other filters
      const sortedBrands = allBrands.filter(brand => brand).sort((brandA, brandB) => brandA.name < brandB.name ? -1 : 1)

      // Set the filtered values into local state
      this.brands = sortedBrands

      this.loadingBrands = false
    },
    async getMoreBrands ($state) {
      if (!this.lastDocId) {
        $state.complete()
        return
      }

      const query = await this.firebaseQuery()

      const db = firebase.firestore()
      const doc = await db.collection('user-brands').doc(this.lastDocId).get()

      // Start after requires a snapshot not a doc id
      const offsetQuery = query.startAfter(doc)
      const snapshot = await offsetQuery.limit(20).get()

      const size = snapshot.size

      if (!size) {
        $state.complete()
        return
      }

      this.lastDocId = snapshot.size ? snapshot.docs[size - 1].id : null

      const newDocs = await Promise.all(snapshot.docs?.map(async (doc) => {
        // Get the unique brand here so we can find the brandId
        const uniqueBrand = await FirebaseAPI.UniqueBrands.get(doc.data().brandId)
        console.log(uniqueBrand)

        return {
          id: doc.id,
          adLibraryId: uniqueBrand.adLibraryId || null,
          ...(doc.data() || [])
        }
      }))
      this.brands = this.brands.concat(newDocs)
      $state.loaded()
    },
    async fetchBrands (load = true) {
      this.brands = []
      this.loadingBrands = load

      if (this.favoriteFilter) {
        await this.fetchFavoritedBrands()
        return
      }

      try {
        // Setup
        const query = await this.firebaseQuery()
        const snapshot = await query.limit(20).get()

        const { size } = snapshot
        this.lastDocId = size ? snapshot.docs[size - 1].id : null

        const newDocs = await Promise.all(snapshot.docs?.map(async (doc) => {
          // Get the unique brand here so we can find the brandId
          const uniqueBrand = await FirebaseAPI.UniqueBrands.get(doc.data().brandId)
          console.log(uniqueBrand)

          return {
            id: doc.id,
            adLibraryId: uniqueBrand.adLibraryId || null,
            ...(doc.data() || [])
          }
        }))

        this.brands = newDocs
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingBrands = false
        this.infiniteId += 1
      }

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    }
  }
}
</script>

<style scoped lang="sass">
</style>
