<template>
  <div
    ref="confettiAnchor"
    class="flex flex-col items-start px-9 py-4"
  >
    <img
      src="../../../../assets/images/teamImage.png"
    >
    <BaseText
      type="body"
      size="md"
      class="text-text-muted mb-4 font-medium mt-12"
    >
      Welcome to the Foreplay Family!
    </BaseText>
    <BaseText
      type="body"
      size="sm"
      class="text-text-normal mb-6"
    >
      We work incredibly hard building Foreplay and your support means we can keep going!
    </BaseText>
    <BaseText
      type="body"
      size="sm"
      class="text-text-normal mb-6"
    >
      If you have 2 minutes it would mean the world if you could write us a review.
    </BaseText>
    <BaseText
      type="body"
      size="sm"
      class="text-text-muted font-medium mb-6"
    >
      Send us a screenshot and we will give you a $10 Amazon Giftcard
    </BaseText>

    <div class="flex gap-4 w-full mt-8">
      <a
        href="https://www.g2.com/products/foreplay/reviews"
        target="_blank"
        class="w-1/2"
      >
        <!--  -->
        <div class="flex gap-2 items-center justify-center rounded-md border border-gray-300 py-4">
          <img
            class="h-5"
            src="../../../../assets/images/g2Icon.png"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Write a G2 Review
          </BaseText>
        </div>
      </a>
      <a
        href="https://chromewebstore.google.com/detail/ad-library-save-facebook/eaancnanphggbfliooildilcnjocggjm"
        target="_blank"
        class="w-1/2"
      >
        <!--  -->
        <div class="flex gap-2 items-center justify-center rounded-md border border-gray-300 py-4">
          <img
            class="h-5"
            src="../../../../assets/images/chrome-icon.svg"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Write a Chrome Review
          </BaseText>
        </div>
      </a>
    </div>
    <BaseButton
      class="w-full mt-8"
      large
      outlined
      @click="SET_SHOW_UPSELL(false)"
    >
      <BaseText
        type="label"
        size="sm"
      >
        Continue to Foreplay
      </BaseText>
    </BaseButton>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  name: 'ReviewOffer',
  mounted () {
    this.$confetti.start({
      defaultType: 'rect'
    })

    setTimeout(() => {
      this.$confetti.stop()
    }, 1500)
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_SHOW_UPSELL'])
  }
}
</script>
<style>
#confetti-canvas {
  z-index: 10000000;
}
</style>
