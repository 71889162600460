<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10 6.625V5.875M10 13.375V14.125M11.6241 7.75C11.3 7.30165 10.6941 7 10 7H9.79165C8.87118 7 8.125 7.59695 8.125 8.33333V8.39058C8.125 8.9173 8.49699 9.3988 9.0859 9.63437L10.9141 10.3656C11.503 10.6012 11.875 11.0827 11.875 11.6094C11.875 12.3774 11.0968 13 10.1368 13H10C9.30595 13 8.70003 12.6984 8.37585 12.25M16.75 10C16.75 13.728 13.728 16.75 10 16.75C6.27208 16.75 3.25 13.728 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.728 3.25 16.75 6.27208 16.75 10Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'FinanceNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>