<template>
  <BaseCard
    v-if="extensionInstalled"
    class="h-max mt-5 w-4/5 flex justify-between items-center bg-black overflow-hidden shadow"
    :no-padding="true"
  >
    <div
      class="flex justify-between items-center gap-5 w-full px-5 bg-black h-full pr-5 py-5"
    >
      <div class="flex overflow-visible">
        <div class="flex justify-center items-center gap-5 w-max h-full">
          <img
            src="../../assets/images/chrome-icon.svg"
            class="w-11 h-11 rounded-full mr-5"
          >
        </div>
        <div class="flex flex-col justify-between h-full">
          <BaseText
            type="label"
            size="md"
            class="text-white"
          >
            Download the Chrome Extension
          </BaseText>
          <BaseText
            type="body"
            size="sm"
            class="text-text-disabled"
          >
            Save ads from Facebook, Tik Tok, and LinkedIn
          </BaseText>
        </div>
      </div>
      <a
        href="https://chrome.google.com/webstore/detail/ad-library-save-facebook/eaancnanphggbfliooildilcnjocggjm"
        class="w-max flex justify-between items-center gap-2 flex-nowrap ml-auto"
        target="_blank"
      >
        <BaseButton
          type="primary"
          size="sm"
          cta
          class="w-max flex justify-between items-center gap-2 flex-nowrap ml-auto"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-white"
          >
            Add to Chrome
          </BaseText>
        </BaseButton>
      </a>
    </div>
  </BaseCard>
  <div v-else />
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import { mapGetters } from 'vuex'
export default {
  name: 'ExtentionCard',
  data () {
    return {
      extensionInstalled: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser'])
  },
  async mounted () {
    const createTokenFunction = firebase
      .functions()
      .httpsCallable('createToken')
    const token = await createTokenFunction(this.getUser.uid)
    try {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(
        'eaancnanphggbfliooildilcnjocggjm',
        {
          event: 'auth',
          data: { token }
        },
        function (response) {
          if (response.status === 'worked') {
            this.extensionInstalled = true
          }
        }
      )

      // Also send this token to the plugin to authenticate the user
      const root = document.querySelector('#FP-PLUGIN')
      const iframe = root?.shadowRoot?.querySelector('.FP-plugin-iframe')
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({
          source: 'fp:app',
          type: 'auth',
          data: { token }
        }, '*') // FIX ME: This is a security issue
      }
    } catch (e) {
      console.log(e)
      this.extensionInstalled = true
    }
  }
}
</script>
<style scoped></style>
