<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" :fill="fill">
    <path d="M16.6592 9.47812L15.8647 5.15224C15.7612 4.58899 15.3205 4.14826 14.7572 4.0448L10.4313 3.25025C9.98531 3.16833 9.5275 3.31088 9.20684 3.63155L3.62323 9.21516C3.08457 9.75381 3.08457 10.6271 3.62323 11.1658L8.74366 16.2862C9.28231 16.8249 10.1556 16.8249 10.6943 16.2862L16.2779 10.7026C16.5986 10.382 16.7411 9.92415 16.6592 9.47812Z" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0832 7.25C11.715 7.25 11.4165 7.54848 11.4165 7.91667C11.4165 8.28486 11.715 8.58333 12.0832 8.58333C12.4514 8.58333 12.7498 8.28486 12.7498 7.91667C12.7498 7.54848 12.4514 7.25 12.0832 7.25Z" 
    :fill="stroke" :stroke="stroke" stroke-width="0.5"/>
  </svg>
</template>

<script>
  export default {
    name: 'TagIcon',
    props: {
      fill: {
        type: String,
        default: 'none'
      },
      stroke: {
        type: String,
        default: '#808899'
      },
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped lang="css">
</style>