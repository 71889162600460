// Imports
import Axios from 'axios'

// Axios Config
let AxiosConfig
if (process.env.NODE_ENV === 'development') {
  AxiosConfig = Axios.create({
  // baseURL: 'http://localhost:5001/adison-foreplay/us-central1' // Local host Prod emulator
     baseURL: 'http://localhost:5001/foreplay-dev-67500/us-central1' // Local host Dev emulator
    // baseURL: 'https://us-central1-foreplay-dev-67500.cloudfunctions.net'
  })
} else {
  AxiosConfig = Axios.create({
  // baseURL: 'http://localhost:5001/adison-foreplay/us-central1' // Local host Prod emulator
    //  baseURL: 'http://localhost:5001/foreplay-dev-67500/us-central1' // Local host Dev emulator
    baseURL: 'https://us-central1-adison-foreplay.cloudfunctions.net'
  })
}

export default AxiosConfig
