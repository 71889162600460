<template>
  <div class="w-full flex items-center justify-center">
    <div class="bg-white p-24 rounded-xl">
      <img
        src="../../assets/images/thank-you.gif"
        class="border border-gray-400 rounded mx-auto object-cover"
        style="height: 400px"
      >
      <div
        class="w-full text-center mt-4 text-4xl font-semibold"
        style="width: 450px"
      >
        Thanks for Subscribing to foreplay
      </div>
      <router-link :to="{ name: 'LibraryView' }">
        <button class="border-none bg-blue-700 hover:bg-blue-600 text-white rounded w-full text-lg p-3 mt-4">
          Return to foreplay
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYouRedirectView',
  metaInfo: {
    title: 'Foreplay.co | Thank you!'
  }
}
</script>

<style scoped lang="sass"></style>
