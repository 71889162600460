<template>
  <div
    class="brief-banner appear-animation flex items-center justify-start px-4 py-3 bg-white fixed border-t shadow-xl mb-10 mx-10 rounded-xl"
    @click.stop
  >
    <!-- Left Content -->
    <img
      src="../../assets/images/brief-icon-black-white.png"
      class="w-9 h-9"
    >
    <div class="ml-2">
      <div class="text-text-muted">
        <BaseText
          type="label"
          size="sm"
        >
          {{ getInspoBrief.name }}
        </BaseText>
      </div>
      <div>
        <BaseText
          type="body"
          size="sm"
        >
          <span class="text-text-normal">
            {{ getInspoBriefFolderName || 'Default Folder' }}
          </span>
        </BaseText>
      </div>
    </div>

    <!-- Right Content -->
    <div class="ml-auto flex">
      <!-- Ad Count -->
      <div class="flex justify-center items-center mr-8">
        <BaseText
          type="body"
          size="sm"
        >
          {{ getInspoAds.length }} Ads Selected
        </BaseText>
      </div>
      <BaseButton
        outlined
        @click="cancelAdSelection"
      >
        Cancel
      </BaseButton>
      <BaseButton
        class="ml-4"
        primary
        bubbly
        @click="handleContinue"
      >
        Continue
      </BaseButton>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import FirebaseAPI from '@/api/firebase'

export default {
  name: 'BriefModeBanner',
  computed: {
    ...mapGetters('BriefsModule', ['getInspoAds', 'getInspoBrief', 'getInspoBriefFolderName'])
  },
  methods: {
    ...mapMutations('BriefsModule', ['SET_INSPO_ADS', 'SET_IS_INSPO_MODE', 'SET_INSPO_BRIEF', 'SET_INSPO_ADS']),
    cancelAdSelection () {
      this.SET_IS_INSPO_MODE(false)

      this.$emit('close')
    },
    async handleContinue () {
      // Save the inspo ads on the Brief - Set to make sure everything is unique
      await FirebaseAPI.Briefs.update(this.getInspoBrief.id, {
        adIds: [
          ...new Set([...(this.getInspoAds || [])])
        ]
      })

      this.SET_IS_INSPO_MODE(false)

      this.$emit('reloadBrief')
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="css">
/* Initial Animation */
.appear-animation {animation: appear 500ms ease-in; animation-iteration-count: 1; }

@keyframes appear {
  0% { opacity: 0;  transform: translate(-50%, 20px); }
  100% { opacity: 1; transform: translate(-50%, 0); }
}

.brief-banner {
  background: rgba(255, 255, 255, 0.92);
  box-shadow: 0px 8px 24px -12px rgba(2, 23, 59, 0.36), 0px 1px 2px rgba(164, 172, 185, 0.16), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  backdrop-filter: blur(5px);

  width: 70%;
  z-index: 57;
  transform: translateX(-50%);

  left: 50%;
  bottom: 0%;

  max-width: 1000px;

  border-radius: 12px;
}
</style>
