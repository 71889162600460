<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.47855 3.07517C9.68676 2.64161 10.3064 2.64161 10.5146 3.07517L12.3062 6.80596C12.3901 6.98051 12.5568 7.10102 12.7494 7.12625L16.8715 7.66617C17.3508 7.72895 17.5425 8.31754 17.1915 8.64863L14.1779 11.4912C14.0365 11.6246 13.9726 11.8203 14.0081 12.011L14.765 16.0722C14.8531 16.5451 14.3516 16.9086 13.9269 16.6796L10.27 14.7075C10.0994 14.6155 9.89375 14.6155 9.72318 14.7075L6.06622 16.6796C5.64161 16.9086 5.14009 16.5451 5.22821 16.0722L5.98505 12.011C6.02058 11.8203 5.95668 11.6246 5.81528 11.4912L2.80165 8.64863C2.45063 8.31754 2.64233 7.72895 3.12163 7.66617L7.24377 7.12625C7.43637 7.10102 7.60311 6.98051 7.68693 6.80596L9.47855 3.07517Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'StarredIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>