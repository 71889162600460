var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-single-select select-none px-px"},[(_vm.label)?_c('div',{staticClass:"base-single-select--label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('VuePopper',{ref:"popper",attrs:{"trigger":"clickToOpen","disabled":_vm.disabled,"options":{
      placement: 'bottom',
      offset: {offset: '10px'}
    }},on:{"show":function($event){return _vm.$emit('focused')},"hide":function($event){return _vm.$emit('unfocused')}}},[_c('div',{staticClass:"base-single-select--dropdown",class:{
        'out-of-bounds': _vm.outOfBounds,
        'in-bounds': !_vm.outOfBounds,
        'near-bounds': _vm.nearBounds,
        'align-top': _vm.alignTop,
        'align-center': _vm.alignCenter,
        'short-dropdown': _vm.shortDropdown
      }},[(!_vm.noSearch)?_c('div',{staticClass:"bg-white border-b border-gray-300 flex items-center w-full px-3 py-2 sticky top-0"},[_c('svg',{staticClass:"h-5 w-5 text-gray-400 mt-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z","clip-rule":"evenodd"}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"pl-1 pt-1 outline-none w-11/12 flex-shrink-0",attrs:{"placeholder":"Search"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}})]):(_vm.message)?_c('div',{staticClass:"bg-white border-b border-gray-300 text-xs flex items-center w-full px-2 py-2 sticky top-0 cursor-default text-gray-600"},[_vm._t("messageIcon"),_vm._v(" "+_vm._s(_vm.message)+" ")],2):_vm._e(),(_vm.getSearchedItems.length)?_c('div',{staticClass:"px-1 py-1"},_vm._l((_vm.getSearchedItems),function(item,index){return _c('div',{key:index,staticClass:"base-single-select--dropdown--item group cursor-pointer",class:{
            'hover:bg-background-normal hover:text-text-loud': _vm.getTheme === 'blue' && !item.disabled,
            'bg-teal-light': _vm.value === item && !item.disabled,
            'text-text-loud': _vm.selected.map((e) => e[_vm.itemValue]).includes(item[_vm.itemValue]) && _vm.getTheme === 'blue' && !item.disabled,
            'text-gray-600': !_vm.selected.map((e) => e[_vm.itemValue]).includes(item[_vm.itemValue]) && !item.disabled,
            'opacity-20 cursor-not-allowed': item.disabled,
            'hover:bg-background-normal': _vm.toggle
          },staticStyle:{"border-radius":"5px"},on:{"click":function($event){return _vm.handleSelectItem(item)}}},[_vm._t("item",function(){return [_vm._v(" "+_vm._s(item[_vm.itemText] ? item[_vm.itemText] : item)+" ")]},{"item":item}),(_vm.toggle)?_c('div',[_c('BaseToggle',{staticClass:"ml-auto",attrs:{"value":_vm.selected.map((e) => e[_vm.itemValue]).includes(item[_vm.itemValue])},on:{"click":function($event){return item.action('selected')}}})],1):_vm._e(),(_vm.selected.map((e) => e[_vm.itemValue]).includes(item[_vm.itemValue]) && !_vm.toggle)?_c('svg',{staticClass:"w-5 h-5 text-blue-500 pr-1 transform rotate-12",class:{
              'text-blue-500': _vm.getTheme === 'blue',
              'text-purple-500': _vm.getTheme === 'purple',
            },attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M4.5 12.75l6 6 9-13.5"}})]):_vm._e()],2)}),0):(_vm.searchQuery)?_c('div',{staticClass:"p-2"},[_vm._v(" Not found. ")]):_vm._e()]),_c('div',{staticClass:"group rounded transition",class:{
        'bg-blue-100 text-blue-600':
          (_vm.selected.length || _vm.focused) && _vm.getTheme === 'blue' && !_vm.noHighlight,
        'bg-purple-100 text-purple-600':
          (_vm.selected.length || _vm.focused) && _vm.getTheme === 'purple' && !_vm.noHighlight,
      },attrs:{"slot":"reference"},on:{"click":_vm.closeDrawer},slot:"reference"},[_c('div',{staticClass:"flex items-center rounded-r rounded-l px-2 transition duration-200 h-8 divide-x border-border-normal",class:{
          'bg-blue-100 text-blue-600':
            (_vm.selected.length || _vm.focused) && _vm.getTheme === 'blue' && !_vm.noHighlight,
          'bg-purple-100 text-purple-600':
            _vm.selected.length && _vm.getTheme === 'purple' && !_vm.noHighlight,
          'text-gray-600': !_vm.selected.length && !_vm.focused,
        }},[(_vm.hasIconSlot)?_c('div',{staticClass:"mr-1 my-1"},[_vm._t("icon")],2):_vm._e(),(_vm.value && !_vm.multiple)?_c('div',{staticClass:"flex flex-wrap",on:{"click":function($event){return _vm.handleSelectItem(_vm.item)}}},[_vm._t("selected",function(){return [_vm._v(" "+_vm._s(_vm.value)+" ")]},{"item":_vm.value})],2):(_vm.selected.length && _vm.multiple && !_vm.toggle)?_c('div',{staticClass:"flex items-start pr-0.5 overflow-x-auto scrollbar-hide w-9/12 font-medium"},[_vm._v(" "+_vm._s(_vm.selected.length)+" ")]):_c('div',{staticClass:"text-grey"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }