<template>
  <!-- Account View -->
  <MainLayout>
    <!-- Toolbar -->
    <template #toolbar />
  </MainLayout>
</template>

<script>
import MainLayout from '../../layouts/MainLayout'
import { mapActions } from 'vuex'

export default {
  name: 'ChromeExtensionAuthView',
  components: { MainLayout },
  async mounted () {
    await this.loginUserWithToken({
      token: this.$route.params.token
    })
  },
  methods: {
    ...mapActions('AuthModule', ['loginUserWithToken'])
  }
}
</script>

<style scoped lang="sass"></style>
