<template>
  <BaseModal @close="$emit('close')" cardWidth="400px" noPadding noToolbarPadding noExit>
    <template #toolbar>
      <div class="flex w-full gap-3 pb-5 pt-1">
        <div class="relative" style="width: 44px; height: 44px">
          <img src="../../assets/icons/new-folder-icon.svg" />
        </div>
        <div class="flex-grow flex flex-col">
          <div class="font-medium text-text-loud">
            Move '{{ item.name }}'
          </div>
          <div class="text-sm text-text-normal">
            Change the folder for this {{ itemType }}
          </div>
        </div>
        <div class="ml-auto">
          <div id="close-wrapper" @click="$emit('close')">
            <TagRemoveIcon stroke="#5E6678" class="cursor-pointer"/>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div class="flex items-center gap-4 p-6 border-t border-border-normal">
        <div class="relative flex-grow">
          <button class="flex items-center w-full pl-3 pr-1.5 py-2 border border-border-normal flex-nowrap"
          :class="expanded ? 'rounded-t-lg' : 'rounded-lg'"
          @click="expanded = !expanded">
            <FolderIcon class="text-icon-normal mr-2 flex-shrink-0" />
            <BaseText type="body" size="sm" class="flex-grow text-text-muted truncate text-left mr-2">
              {{ selectedFolder.name || '' }}
            </BaseText>
            <div class="transform transition-transform flex-shrink-0"
            :style="{transform: expanded ? 'scaleY(-1)' : ''}">
              <ChevronIcon class="text-icon-normal" />
            </div>
          </button>
          <div class="folder-dropdown-container rounded-b-lg" v-on-clickaway="() => { expanded = false }">
            <div class="folder-dropdown flex flex-col gap-1 px-1 rounded-b-lg bg-white cursor-pointer overflow-y-scroll scrollbar-hide"
            :class="{'expanded': expanded}" :style="{height: expanded ? `${Math.min(folderOptions.length * 36 + 4, 203)}px` : '0px'}">
              <button v-for="(folder, index) in folderOptions" :key="`change-folder-${index}`"
              class="group flex items-center rounded-lg px-2 py-1.5 transition duration-100 hover:bg-neutral-10 whitespace-nowrap"
              :class="`${index === 0 ? 'mt-1' : index === folderOptions.length - 1 ? 'mb-1' : ''} ${expanded ? 'opacity-100' : 'opacity-0'}`"
              @click="selectedFolder = folder, expanded = false">
                <FolderIcon class="transition-colors text-neutral-400 group-hover:text-icon-normal mr-2 flex-shrink-0" />
                <BaseText type="body" size="sm" class="text-text-muted flex-grow truncate text-left">
                  {{ folder.name }}
                </BaseText>
              </button>
            </div>
            <div v-if="folderOptions.length > 5" class="dropdown-fade-overlay" />
          </div>
        </div>
        <BaseButton primary iconOnly animate class="w-20"
          :disabled="!changesMade || loading" 
          :loading="loading" 
          :class="{'cursor-default': !changesMade}"
          @click="() => { if (changesMade && !loading) applyFolderChange() }"
        >
          <BaseText type="label" size="sm" class="w-full text-center transition-colors"
          :class="changesMade ? 'text-white' : 'text-text-disabled'">
            Apply
          </BaseText>
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import FirebaseAPI from '@/api/firebase'

// Icons
import TagRemoveIcon from '../globals/Icons/TagRemoveIcon.vue'
import FolderIcon from '../globals/Icons/SpyderV2Icons/FolderIcon.vue'
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'
import NewCheckmarkIcon from '../globals/Icons/NewCheckmarkIcon.vue'

export default {
  name: 'ChangeFolderModal',
  mixins: [clickaway],
  components: {
    TagRemoveIcon,
    FolderIcon,
    ChevronIcon,
    NewCheckmarkIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      currentFolder: {},
      selectedFolder: {},
      expanded: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getFolders']),
    folderOptions () {
      const folders = this.getFolders.filter(folder => folder.id !== this.selectedFolder.id)
      if (this.selectedFolder.id) {
        folders.push({ id: null, name: 'Default Folder' })
      }
      return folders.sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically
    },
    changesMade () {
      return this.currentFolder.id !== this.selectedFolder.id
    }
  },
  mounted () {
    console.log('ChangeFolderModal mounted')
    this.currentFolder = (this.itemType === 'brand'
      ? this.getFolders.find(folder => folder?.spyderBrands?.includes(this.item.subscription.pageId))
      : this.itemType === 'board'
        ? this.getFolders.find(folder => folder?.boardIds?.includes(this.item.id))
        : this.getFolders.find(folder => folder?.briefIds?.includes(this.item.id))
    ) || { id: null, name: 'Default Folder'}
    this.selectedFolder = this.currentFolder
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchFolders']),
    async applyFolderChange () {
      this.loading = true
      try {
        // Remove brand/board from old folder (if old is not default)
        if (this.currentFolder.id) {
          const toRemovePayload = this.itemType === 'brand'
            ? { spyderBrands: this.currentFolder.spyderBrands.filter(id => id !== this.item.subscription.pageId) }
            : this.itemType === 'board' 
              ? { boardIds: this.currentFolder.boardIds.filter(id => id !== this.item.id) }
              : { briefIds: this.currentFolder.briefIds.filter(id => id !== this.item.id) }
          await FirebaseAPI.Folders.update(this.currentFolder.id, toRemovePayload)
        }
        // Add brand/board to new folder (if new is not default)
        if (this.selectedFolder.id) {
          const toAddPayload = this.itemType === 'brand'
            ? { spyderBrands: [...(this.selectedFolder?.spyderBrands || []), this.item.subscription.pageId] }
            : this.itemType === 'board' 
              ? { boardIds: [...this.selectedFolder.boardIds, this.item.id] }
              : { briefIds: [...(this.selectedFolder?.briefIds || []), this.item.id] }
          await FirebaseAPI.Folders.update(this.selectedFolder.id, toAddPayload)
        }
        this.$showAlert({
          message: `${this.item.name} moved to ${this.selectedFolder.name}`,
          type: 'success'
        })
      } catch (error) {
        console.error('Failed to change folder:', error)
        this.$showAlert({
          message: 'Failed to change folder, see console for details.',
          type: 'error'
        })
      }
      this.fetchFolders() // Refresh folders in store
      this.loading = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.folder-dropdown-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}
.folder-dropdown {
  transition: height 100ms ease-in-out;
}
.folder-dropdown.expanded {
  border-left: 1px solid; 
  border-right: 1px solid; 
  border-bottom: 1px solid; 
  border-color: #ECEFF3;
}
.dropdown-fade-overlay {
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px;
  z-index: 40;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffffb9 100%);
}
</style>