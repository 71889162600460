<template>
  <div />
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import { mapMutations } from 'vuex'

export default {
  name: 'AuthCallbackView',
  created () {
    this.confirmSignIn()
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    async confirmSignIn () {
      try {
        if (firebase.auth().isSignInWithEmailLink(this.$route.fullPath)) {
          const email = window.localStorage.getItem('emailForSignIn')

          // If missing email, prompt user for it
          if (!email) {
            this.$router.push({ name: 'LoginView' })

            return
          }

          await firebase.auth().signInWithEmailLink(email, this.$route.fullPath).then(result => {
            result.user.getIdToken().then(token => {
              this.SET_USER({
                email: result.user.email,
                name: result.user.displayName,
                avatar: result.user.photoURL
              })

              this.$router.push({ name: 'LibraryView' }).catch(e => e)
              window.localStorage.removeItem('emailForSignIn')
            })
          })
        }
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e.message,
          type: 'error'
        })

        this.$router.push({ name: 'LoginView' }).catch(e => e)
      }
    }
  }
}
</script>

<style scoped>

</style>
