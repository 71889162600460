<template>
  <transition name="fade">
    <div v-if="activeAlert" class="notification flex items-center p-0.5 rounded-full transition-colors duration-500"
    :class="[getAlertBackgroundColor(activeAlert.type), {'sidebar': !$route.meta.hideSidebar}]">
      <!-- Alert Icon -->
      <div tag="div" class="relative w-8 h-8 flex-shrink-0">
        <transition name="icon">
          <SuccessResponseIcon v-if="activeAlert.type === 'success'" class="alert-icon text-white" />
        </transition>
        <transition name="icon">
          <ErrorResponseIcon v-if="['error', 'warning'].includes(activeAlert.type)" class="alert-icon text-white" />
        </transition>
        <transition name="icon">
          <InfoResponseIcon v-if="!['success', 'error', 'warning'].includes(activeAlert.type)" class="alert-icon text-white" />
        </transition>
      </div>
      <!-- Alert Message -->
      <transition name="initmessage">
        <div v-if="activeAlert.message" class="relative">
          <div ref="active-message" class="flex items-center gap-1 py-1.5 pl-0.5 pr-3 w-max whitespace-nowrap">
            <BaseText type="label" size="sm" class="text-white">
              {{ activeAlert.message }}
            </BaseText>
            <transition name="fade">
              <BaseText v-if="activeAlert.count > 1" type="body" size="sm" class="text-white">
                ({{ activeAlert.count }})
              </BaseText>
            </transition>
          </div>
          <div ref="previous-message" class="previous-message flex items-center gap-1 py-1.5 pl-0.5 pr-3 w-max whitespace-nowrap">
            <BaseText type="label" size="sm" class="text-white">
              {{ previousAlert?.message || '' }}
            </BaseText>
            <transition name="fade">
              <BaseText v-if="previousAlert?.count > 1" type="body" size="sm" class="text-white">
                ({{ previousAlert?.count || 0 }})
              </BaseText>
            </transition>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="activeAlert.message" class="fade-overlay absolute right-0 top-0 bottom-0 w-3 z-10" :class="activeAlert.type" />
      </transition>
    </div>
  </transition>
</template>

<script>
import EventBus from './EventBus'
import smoothReflow from 'vue-smooth-reflow'

// Icons
import SuccessResponseIcon from '../../components/globals/Icons/ResponseAlertIcons/SuccessResponseIcon.vue'
import ErrorResponseIcon from '../../components/globals/Icons/ResponseAlertIcons/ErrorResponseIcon.vue'
import InfoResponseIcon from '../../components/globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'

const MESSAGE_EXPANSION_DELAY = 300

export default {
  name: 'BaseNotifyAlert',
  mixins: [smoothReflow],
  components: {
    SuccessResponseIcon,
    ErrorResponseIcon,
    InfoResponseIcon
  },
  data () {
    return {
      activeAlert: null,
      alertTimeout: null,
      previousAlert: null,
    }
  },
  mounted () {
    EventBus.$on('showAlert', (alert) => this.enqueueAlert(alert))
    this.$smoothReflow({
      el: '.notification',
      property: ['width'],
      transition: 'width 0.5s ease-in-out'
    })
  },
  methods: {
    enqueueAlert (alert) {
      const newAlert = {
        ...alert,
        count: 1,
        nextAlert: null
      }
      newAlert.timeout = newAlert.timeout ?? getDefaultAlertDuration(newAlert.type)
      if (this.activeAlert) {
        let mostRecentAlert = this.activeAlert
        while (mostRecentAlert.nextAlert) {
          // Traverse to the back of the alert queue
          mostRecentAlert = mostRecentAlert.nextAlert
        }
        if (mostRecentAlert.message === newAlert.message) {
          mostRecentAlert.count++
          // Reset the current timeout
          clearTimeout(this.alertTimeout)
          this.alertTimeout = setTimeout(this.dequeueAlert, newAlert.timeout)
        } else {
          mostRecentAlert.nextAlert = newAlert
        }
      } else {
        this.activeAlert = { ...newAlert, message: null }
        setTimeout(() => {
          this.activeAlert.message = newAlert.message
        }, MESSAGE_EXPANSION_DELAY + 300)
        // Start the timeout
        this.alertTimeout = setTimeout(this.dequeueAlert, newAlert.timeout)
      }
    },
    dequeueAlert () {
      if (this.activeAlert.nextAlert) {
        this.displayNextAlert(this.activeAlert)
      } else {
        const leavingAlert = { ...this.activeAlert }
        this.activeAlert.message = null
        setTimeout(() => {
          if (!this.activeAlert.nextAlert) {
            this.activeAlert = null
          } else {
            // In case a new alert was added during the collapse animation
            this.displayNextAlert(leavingAlert)
          } 
        }, MESSAGE_EXPANSION_DELAY + 200)
      }
    },
    displayNextAlert (previousAlert) {
      this.previousAlert = previousAlert
      const nextAlert = this.activeAlert.nextAlert
      this.activeAlert = nextAlert
      this.executeMessageSwapAnim()
      this.alertTimeout = setTimeout(this.dequeueAlert, nextAlert.timeout)
    },
    executeMessageSwapAnim () {
      const activeMessage = this.$refs['active-message']
      const previousMessage = this.$refs['previous-message']
      if (!activeMessage || !previousMessage) return

      activeMessage.classList.remove('message-enter-anim')
      activeMessage.offsetWidth // Trigger reflow
      activeMessage.classList.add('message-enter-anim')

      previousMessage.classList.remove('message-leave-anim')
      previousMessage.offsetWidth // Trigger reflow
      previousMessage.classList.add('message-leave-anim')
    },
    getAlertBackgroundColor (type) {
      switch (type) {
        case 'success': return 'bg-secondary-green-100'
        case 'warning': return 'bg-secondary-yellow-100'
        case 'error': return 'bg-primary-red-100'
        default: return 'bg-primary-blue-100'
      }
    }
  }
}

const getDefaultAlertDuration = (type) => {
  switch (type) {
    case 'error':
    case 'warning': return 3500
    case 'info': return 3000
    default: return 2500
  }
}
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 500000;
}
.notification.sidebar {
  left: calc(50% + 118px);
}
.alert-icon {
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 6px;
  right: 6px;
}
.previous-message {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
}

/* ========= Gradient overlay ========= */
@property --gradient-overlay-color {
  syntax: '<color>';
  initial-value: rgba(0, 0, 0, 0);
  inherits: false;
}
.fade-overlay {
  background: linear-gradient(to left, var(--gradient-overlay-color), transparent);
  transition: --gradient-overlay-color 0.5s ease-in-out;
  --gradient-overlay-color: #1F69FF; /* bg-primary-blue-100 */
}
.fade-overlay.success {
  --gradient-overlay-color: #40C4AA; /* bg-secondary-green-100 */
}
.fade-overlay.warning {
  --gradient-overlay-color: #FFBD4C; /* bg-secondary-yellow-100 */
}
.fade-overlay.error {
  --gradient-overlay-color: #ED615A; /* bg-primary-red-100 */
}

/* ========= Animation classes ========= */
.message-enter-anim {
  animation-name: messageEnter;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.message-leave-anim {
  animation-name: messageLeave;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes messageEnter {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes messageLeave {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

/* ========= Vue <transition> classes ========= */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter-from, .fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
.icon-enter-active, .icon-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.icon-enter-from, .icon-enter {
  transform: translateY(-100%);
  opacity: 0;
}
.icon-enter-to, .icon-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.icon-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.initmessage-enter-active {
  transition: opacity 0.3s ease-in-out;
}
.initmessage-leave-active {
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  left: 34px;
}
.initmessage-enter-from, .initmessage-enter, .initmessage-leave-to {
  opacity: 0;
}
.initmessage-enter-to, .initmessage-leave-from {
  opacity: 1;
}
</style>