<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 18 12" fill="none">
    <path d="M17.189 1.87912C16.989 1.14 16.4117 0.562639 15.6725 0.362637C14.3429 0 8.99122 0 8.99122 0C8.99122 0 3.63957 0.010989 2.3099 0.373626C1.57078 0.573628 0.993417 1.15099 0.793415 1.89011C0.391217 4.25275 0.235173 7.85275 0.804404 10.1209C1.00441 10.86 1.58177 11.4374 2.32089 11.6374C3.65056 12 9.00221 12 9.00221 12C9.00221 12 14.3539 12 15.6835 11.6374C16.4226 11.4374 17 10.86 17.2 10.1209C17.6242 7.75494 17.755 4.15714 17.189 1.87912Z" 
    fill="#FF0000"/>
    <path d="M7.28784 8.57132L11.7274 5.9999L7.28784 3.42847V8.57132Z" 
    fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: 'YouTubePlatformIcon',
    props: {
      width: {
        type: Number,
        default: 18
      },
      height: {
        type: Number,
        default: 12
      }
    }
  }
</script>

<style scoped></style>