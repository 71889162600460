<template>
  <!-- App -->
  <div
    id="app"
    class="duration-1000"
    :class="`${getTheme}-background `"
  >
    <!-- Left Navigation -->
    <Sidebar v-if="isUserLoggedIn && !isRoutePublic && !hideSidebar" />

    <!-- Briefs Submissions -->
    <!-- <UploadSidebar v-if="this.$route.name === 'BriefPublicView'" /> -->

    <!-- Main Routes -->
    <router-view :key="$route.fullPath" />

    <!-- Base Notifications -->
    <BaseNotifyAlert />
  </div>
</template>

<script>
import Sidebar from './components/globals/Sidebar'
import UploadSidebar from './components/globals/UploadSidebar'
import { mapGetters } from 'vuex'

export default {
  components: { Sidebar, UploadSidebar },
  computed: {
    ...mapGetters('AuthModule', ['isUserLoggedIn']),
    ...mapGetters('MiscModule', ['getTheme']),
    hideSidebar () {
      return this.$route.meta.hideSidebar
    },
    isRoutePublic () {
      return !this.$route.meta.requiresAuth
    }
  }
}
</script>

<style lang="sass" scoped>
#app
  font-family: "Inter var", "system-ui"
  font-size: 15px
  @apply flex h-full min-h-screen w-screen

.green-background
  background: #061B16

.blue-background
  background: #06081B

.purple-background
  background: #0F061B

.red-background
  background: #0e080c

</style>
