<template>
  <BaseCard>
    <div class="overflow-hidden">
      <!-- Header -->
      <div
        class="flex items-center justify-between p-3.5 px-5 border-b border-border-normal"
        style="height: 72px"
      >
        <!-- left container -->
        <div class="flex">
          <!-- Icon -->
          <div
            style="width: 45px"
            class="icon-shadow mr-3 p-2.5 rounded-full flex items-center justify-center"
          >
            <img src="../../assets/icons/brand-profile.svg">
          </div>
          <!-- Text -->
          <div>
            <!-- Title Container -->
            <div class="flex gap-1">
              <!-- Title -->
              <BaseText
                type="label"
                size="md"
              >
                <span class="text-text-muted"> Brand Profile </span>
              </BaseText>
            </div>
            <!-- SubTitle -->
            <BaseText
              type="body"
              size="sm"
            >
              <span class="text-text-normal">
                Select the brand you are briefing for
              </span>
            </BaseText>
            <div />
          </div>
        </div>
      </div>

      <!-- Body -->
      <div class="p-5 transition-height">
        <!-- Selected Brand -->
        <BrandProfileDetails
          v-if="selectedBrandProfile"
          :brand-profile="selectedBrandProfile"
          @changeProfile="changeProfile"
        />

        <!-- Brand Grid -->
        <BrandProfileGrid
          v-else
          :brand-profiles="brandProfiles"
          @createNewBrand="createNewBrand"
          @selectBrandProfile="changeProfile($event)"
        />
      </div>
    </div>
  </BaseCard>
</template>
<script>
import { mapGetters } from 'vuex'

import BrandProfileGrid from './brandProfile/BrandProfileGrid.vue'
import BrandProfileDetails from './brandProfile/BrandProfileDetails.vue'
import FirebaseAPI from '@/api/firebase'
import firebase from '@/api/config/FirebaseConfig'

export default {
  name: 'BrandProfile',
  components: {
    BrandProfileDetails,
    BrandProfileGrid
  },
  props: {
    brandProfileId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    brandProfiles: [],
    selectedBrandProfile: null
  }),
  computed: {
    ...mapGetters('AuthModule', ['getTeam'])
  },
  async mounted () {
    // Fetch Brand Profiles
    await this.fetchBrandProfiles()

    // Check if the brief has a brand profile
    if (this.brandProfileId) {
      this.brandProfiles.find((brandProfile) => {
        if (brandProfile.id === this.brandProfileId) {
          this.selectedBrandProfile = brandProfile
        }
      })
    }
  },
  methods: {
    async changeProfile (brandProfile) {
      this.selectedBrandProfile = brandProfile

      this.fetchBrandProfiles()

      // Set Chosen Brand Profile
      if (this.selectedBrandProfile) {
        this.$emit('saveSection', {
          section: 'brandProfileId',
          value: this.selectedBrandProfile.id
        })
      }
    },
    async createNewBrand () {
      const user = firebase.auth().currentUser

      const newBrandPayload = {
        name: 'Brand Name',
        createdBy: user.id,
        createdAt: +new Date(),
        modifiedAt: +new Date()
      }

      if (this.getTeam) {
        newBrandPayload.teamId = this.getTeam.id
      }
      // Track event for analytics when a brand profile is created
      window.analytics.track('Brand Profile Created', newBrandPayload)

      // Create one in firestore
      const brandProfileInstance = await FirebaseAPI.BrandProfiles.create(
        newBrandPayload
      )
      newBrandPayload.id = brandProfileInstance.id

      // Create a new brand
      this.selectedBrandProfile = newBrandPayload
    },
    async fetchBrandProfiles () {
      if (this.getTeam) {
        const brandProfiles = await FirebaseAPI.BrandProfiles.getAllByTeam(
          this.getTeam.id
        )
        this.brandProfiles = brandProfiles
      } else {
        const brandProfiles = await FirebaseAPI.BrandProfiles.getAllByUser()
        this.brandProfiles = brandProfiles
      }
    }
  }
}
</script>
<style>
.icon-shadow {
  box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13),
    0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  border-radius: 999px;
}
</style>
