<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.0625 9.25H9.99999L10 13.1875M16.9375 10C16.9375 13.8315 13.8315 16.9375 10 16.9375C6.16852 16.9375 3.0625 13.8315 3.0625 10C3.0625 6.16852 6.16852 3.0625 10 3.0625C13.8315 3.0625 16.9375 6.16852 16.9375 10Z"
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="9.34375" y="6.34375" width="1.3125" height="1.3125" rx="0.65625" fill="currentColor" stroke="currentColor" stroke-width="0.5625"/>
  </svg>
</template>

<script>
export default {
  name: 'InfoResponseIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>