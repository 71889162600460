<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4 6.93994C4 6.39803 4.29229 5.89825 4.76461 5.63258L9.26462 3.10133C9.72122 2.84448 10.2788 2.84448 10.7354 3.10133L15.2354 5.63258C15.7077 5.89825 16 6.39803 16 6.93994V14.5002C16 15.3286 15.3284 16.0002 14.5 16.0002H5.5C4.67157 16.0002 4 15.3286 4 14.5002V6.93994Z" 
    :stroke="stroke" stroke-width="1.5"/>
    <path d="M12.25 10C12.25 11.2426 11.2427 12.25 10 12.25C8.75732 12.25 7.75 11.2426 7.75 10C7.75 8.75734 8.75732 7.75 10 7.75C11.2427 7.75 12.25 8.75734 12.25 10Z" 
    :stroke="stroke" stroke-width="1.5"/>
  </svg>
</template>

<script>
  export default {
    name: 'HomeNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>