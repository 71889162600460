<template>
  <!-- Base Multi Select -->
  <div class="base-single-select">
    <VuePopper
      ref="popper"
      trigger="clickToOpen"
      :options="{
        placement: 'bottom',
      }"
    >
      <!-- Dropdown -->
      <div
        class="base-single-select--dropdown"
        style="z-index: 100000;"
        :style="small ? 'width: 120px': 'width: 155px'"
        :class="{
          'out-of-bounds': outOfBounds,
          'out-of-bounds-left': outOfBoundsLeft,
        }"
      >
        <div
          v-if="items.length"
          class="px-1 py-1"
        >
          <div
            v-for="(item, index) in items"
            :key="index"
            class="flex items-center rounded-r rounded-l px-2 transition duration-200 h-8 select-none"
            :class="{
              'hover:bg-blue-100 hover:text-blue-600': getTheme === 'blue' && !item.noHighlight,
              'hover:bg-purple-100 hover:text-purple-600': getTheme === 'purple' && !item.noHighlight,
              'hover:bg-green-100 hover:text-gray-600': getTheme === 'green' && !item.noHighlight,
            }"
            @click="handleClickItem(item)"
          >
            <slot
              name="item"
              :item="item"
            >
              {{ item[itemText] ? item[itemText] : item }}
            </slot>
          </div>
        </div>
      </div>

      <div
        slot="reference"
        class="relative group"
        @click="closeDrawer"
      >
        <slot
          name="reference"
        />
      </div>
    </VuePopper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VuePopper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'BaseDropdownActions',
  components: {
    VuePopper
  },
  props: {
    // List Items
    items: {
      type: Array,
      default: () => []
    },
    outOfBounds: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    outOfBoundsLeft: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme']),
    hasIconSlot () {
      return !!this.$slots.icon
    }
  },
  methods: {
    // Close Drawer
    closeDrawer () {
      if (this.$refs.popper.showPopper) {
        setTimeout(() => {
          this.$refs.popper.doClose()
        }, 70)
      }
    },
    // Handle Select Item
    handleClickItem (item) {
      if (this.disabled || item.noClick) return

      item.action()
      this.$emit('click', item)
      this.closeDrawer()
    }
  }
}
</script>

<style scoped lang="sass">
.out-of-bounds
  left: -50px !important

.out-of-bounds-left
  left: 80px !important

.base-single-select
  @apply cursor-pointer flex flex-col relative text-sm

  &--label
    margin-bottom: 3px
    @apply text-gray-500

  &--input
    height: 37px
    font-size: 15px
    @apply bg-white border border-gray-300 flex items-center rounded-r rounded-l

    i
      @apply text-gray-500 mr-2

    &--icon
      @apply bg-white flex border-r border-gray-300 rounded-l items-center rounded-l px-4 h-full text-gray-500

  &--dropdown
    animation: fade 100ms ease-in
    border-radius: 4px
    margin-top: 8px
    max-height: 50vh
    z-index: 10000
    @apply bg-white border border-gray-200 overflow-y-auto w-full shadow-lg

    &--item
      @apply flex items-center justify-between cursor-pointer p-2 w-full transition-all text-xs

      &:hover
        @apply bg-gray-100

@keyframes fade
  0%
    opacity: 0

  100%
    opacity: 100%
</style>
