// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get All By User
const getBetaUserIds = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('beta-users').get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

export default {
  getBetaUserIds
}
