<template>
  <BaseCard
    v-if="brandProfileInformation"
    class="h-max mt-5 rounded-xl bg-white shadow-sm custom-width"
    border
  >
    <div
      class="flex justify-between"
      :class="brandProfileExpanded ? 'border-b border-neutral-50 border-opacity-80' : '' "
    >
      <div
        class="py-4 px-6 rounded-full flex"
      >
        <img
          src="../../../assets/icons/brand-profile.svg"
          alt=""
          class="p-2.5 rounded-full w-10 h-10"
          style="box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
        >
        <div
          class="w-full ml-3 flex items-center"
        >
          <BaseText
            type="label"
            size="md"
            class="text-text-muted"
          >
            Brand Profile
          </BaseText>
        </div>
      </div>
      <img
        src="../../../assets/icons/chevron-down-small.svg"
        alt=""
        :class="brandProfileExpanded ? 'rotate-180 transform' : ''"
        class="w-5 mr-5 transition-transform duration-300 cursor-pointer"
        @click="toggleExpanded()"
      >
    </div>
    <div
      v-if="brandProfileExpanded"
    >
      <div
        class="flex justify-center flex-col py-5"
      >
        <img
          v-if="brandProfileInformation.image"
          :src="brandProfileInformation.image"
          alt=""
          class="h-10 w-10 rounded-full mx-auto"
        >
        <BaseText
          v-if="brandProfileInformation.name"
          type="display"
          size="xs"
          class="text-text-muted mt-3 text-center"
        >
          {{ brandProfileInformation.name && brandProfileInformation.name }}
        </BaseText>
        <div
          class=" flex flex-nowrap justify-center"
        >
          <BaseText
            v-if="brandProfileInformation.pronunciation"
            type="body"
            size="sm"
            class="text-text-normal mt-3 text-center truncate pl-5"
          >
            {{ brandProfileInformation.pronunciation && brandProfileInformation.pronunciation }}
          </BaseText>
          <BaseText
            v-if="brandProfileInformation.pronunciation"
            type="body"
            size="sm"
            class="text-text-normal mt-3 text-center mx-3"
          >
            ·
          </BaseText>
          <BaseText
            v-if="brandProfileInformation.niche"
            type="body"
            size="sm"
            class="text-text-normal mt-3 text-center truncate pr-5"
          >
            {{ brandProfileInformation.niche }}
          </BaseText>
          <div
            v-if="typeof brandProfileInformation.niche === 'array'"
            class="flex flex-wrap justify-center"
          >
            <BaseText
              v-for="( niches , index) in brandProfileInformation.niche"
              :key="index"
              type="body"
              size="sm"
              class="text-text-normal mt-3 text-center"
            >
              {{ niches }}
            </BaseText>
          </div>
        </div>
        <div
          class="flex justify-center mt-3 gap-2 sm:gap-4 border-b border-neutral-50 border-opacity-80 pb-5"
        >
          <a
            v-if="brandProfileInformation.website"
            :href="brandProfileInformation.website"
            target="blank"
          >
            <img
              src="../../../assets/images/safari-compass.svg"
              alt="safari compass"
              class="h-7 w-7"
            >
          </a>
          <a
            v-if="brandProfileInformation.twitter"
            :href="brandProfileInformation.twitter"
            target="blank"
          >
            <img
              src="../../../assets/images/twitter-icon.svg"
              alt="twitter icon"
              class="h-7 w-7"
            >
          </a>
          <a
            v-if="brandProfileInformation.facebook"
            :href="brandProfileInformation.facebook"
            target="blank"
          >
            <img
              src="../../../assets/images/alternate-facebook-icon.svg"
              alt="twitter icon"
              class="h-7 w-7"
            >
          </a>
          <a
            v-if="brandProfileInformation.tiktok"
            :href="brandProfileInformation.tiktok"
            target="blank"
          >
            <img
              src="../../../assets/images/alternate-tiktok-icon.svg"
              alt="twitter icon"
              class="h-7 w-7"
            >
          </a>
          <a
            v-if="brandProfileInformation.linkedin"
            :href="brandProfileInformation.linkedin"
            target="blank"
          >
            <img
              src="../../../assets/images/alternate-linkden-icon.svg"
              alt="twitter icon"
              class="h-7 w-7"
            >
          </a>
          <a
            v-if="brandProfileInformation.pinterest"
            :href="brandProfileInformation.pinterest"
            target="blank"
          >
            <img
              src="../../../assets/images/pintrest-icon.svg"
              alt="twitter icon"
              class="h-7 w-7"
            >
          </a>
          <a
            v-if="brandProfileInformation.instagram"
            :href="brandProfileInformation.instagram"
            target="blank"
          >
            <img
              src="../../../assets/images/alternate-instagram-icon.svg"
              alt="twitter icon"
              class="h-7 w-7"
            >
          </a>
          <a
            v-if="brandProfileInformation.youtube"
            :href="brandProfileInformation.youtube && brandProfileInformation.youtube"
            target="blank"
          >
            <img
              src="../../../assets/images/alternate-youtube-icon.svg"
              alt=""
              class="h-7 w-7"
            >
          </a>
        </div>
      </div>
      <div
        class="px-6"
      >
        <BaseText
          v-if="brandProfileInformation.brandGuidelines || (brandProfileInformation.assetLinks && brandProfileInformation.assetLinks[0]) || brandProfileInformation.assetLinks || (brandProfileInformation.brandColors && brandProfileInformation.brandColors[0]) || brandProfileInformation.brandColors ||( brandProfileInformation.fonts && brandProfileInformation.fonts[0] )|| brandProfileInformation.fonts"
          type="body"
          size="sm"
          class="text-text-normal mb-2"
        >
          Brand Guidelines
        </BaseText>
        <div
          class="flex w-full gap-4 sm:gap-x-4 mb-5 flex-wrap sm:flex-nowrap"
        >
          <a
            v-if="brandProfileInformation.brandGuidelines && brandProfileInformation.brandGuidelines"
            :href="brandProfileInformation.brandGuidelines && brandProfileInformation.brandGuidelines"
            target="blank"
          >
            <div
              class="flex flex-row bg-primary-green-10 w-max py-1.5 px-3 rounded-md"
            >

              <BaseText
                type="body"
                size="sm"
                class="text-primary-green-200 w-max mr-1.5"
              >
                Brand Guidelines Doc
              </BaseText>
              <img
                src="../../../assets/icons/green-link-arrow-icon.svg"
                alt=""
              >
            </div>
          </a>
          <a
            v-if="brandProfileInformation.fonts && brandProfileInformation.fonts"
            :href="brandProfileInformation.fonts && brandProfileInformation.fonts"
            target="blank"
          >
            <div
              class="flex flex-row bg-primary-green-10 w-max py-1.5 px-3 rounded-md"
            >
              <BaseText
                type="body"
                size="sm"
                class="text-primary-green-200 w-max mr-1.5"
              >
                Fonts
              </BaseText>
              <img
                src="../../../assets/icons/green-link-arrow-icon.svg"
                alt=""
              >
            </div>
          </a>
          <a
            v-if="brandProfileInformation.assetLinks[0] || brandProfileInformation.assetLinks"
            :href="brandProfileInformation.assetLinks[0] ?? brandProfileInformation.assetLinks"
            target="blank"
          >
            <div
              class="flex flex-row bg-primary-green-10 w-max py-1.5 px-3 rounded-md"
            >
              <BaseText
                type="body"
                size="sm"
                class="text-primary-green-200 w-max mr-1.5"
              >
                Brand Assets
              </BaseText>
              <img
                src="../../../assets/icons/green-link-arrow-icon.svg"
                alt=""
              >
            </div>
          </a>
        </div>
      </div>
      <div
        class="px-6 mb-5"
      >
        <BaseText
          v-if="brandProfileInformation.brandColors.length"
          type="body"
          size="sm"
          class="text-text-normal my-2"
        >
          Brand Colors
        </BaseText>
        <div
          v-if="brandProfileInformation.brandColors.length"
          class="flex w-full gap-x-2 flex-wrap gap-y-2 mb-5"
        >
          <div
            v-for="(color, index) in brandProfileInformation.brandColors"
            :key="index"
            class="flex flex-row h-9 px-2 rounded-full justify-center items-center border border-border-normal"
          >
            <div
              class="rounded-full border border-border-normal h-4 w-4 mr-1.5"
              :style="'background-color:' + color"
            />
            <BaseText
              type="body"
              size="sm"
              class="w-max pr-1"
            >
              {{ color }}
            </BaseText>
          </div>
        </div>
        <div
          v-if="brandProfileInformation.musicGenres.length"
          class="mb-5"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal my-2"
          >
            Music Genre(s)
          </BaseText>
          <div
            class="flex w-full gap-2 flex-wrap"
          >
            <div
              v-for="(genre, index) in brandProfileInformation.musicGenres"
              :key="index"
              class="bg-background-normal rounded-full py-1.5 px-3 border border-border-normal"
            >
              <BaseText
                type="label"
                size="sm"
                class="text-neutral-900"
              >
                {{ genre.itemText }}
              </BaseText>
            </div>
          </div>
        </div>
        <div
          v-if="brandProfileInformation.brandVoice"
          class="mb-5"
        >
          <BaseText
            v-if="brandProfileInformation.brandVoice"
            type="body"
            size="sm"
            class="text-text-normal my-2"
          >
            Voice / Brand Personality
          </BaseText>
          <div
            v-if="(typeof brandProfileInformation.brandVoice.length)"
            class="flex w-full gap-2 flex-wrap"
          >
            <div
              v-for="(voice, index) in brandProfileInformation.brandVoice"
              :key="index"
              class="bg-background-normal rounded-full py-1.5 px-3 border border-border-normal"
            >
              <BaseText
                type="label"
                size="sm"
                class="text-neutral-900"
              >
                {{ voice.itemText }}
              </BaseText>
            </div>
          </div>
          <div
            v-if="typeof brandProfileInformation.brandVoice === 'string'"
            class="flex w-full gap-x-2"
          >
            <div
              class="bg-background-normal rounded-full py-1.5 px-3 border border-border-normal"
            >
              <BaseText
                type="label"
                size="sm"
                class="text-neutral-900"
              >
                {{ brandProfileInformation.brandVoice }}
              </BaseText>
            </div>
          </div>
        </div>
        <div
          v-if="brandProfileInformation.tagline"
          class="mb-5"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal my-2"
          >
            Tagline
          </BaseText>
          <div
            class="flex w-full gap-x-2"
          >
            <div
              class="bg-background-normal rounded-md py-1.5 px-3 border border-border-normal w-full"
            >
              <BaseText
                type="body"
                size="sm"
                class="text-neutral-900 break-all w-full "
              >
                {{ brandProfileInformation.tagline }}
              </BaseText>
            </div>
          </div>
        </div>
        <div
          v-if="brandProfileInformation.missionStatement"
          class="mb-5"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal my-2"
          >
            Mission Statement
          </BaseText>
          <div
            class="flex w-full gap-x-2"
          >
            <div
              class="bg-background-normal rounded-md py-1.5 px-3 border border-border-normal w-full"
            >
              <BaseText
                type="body"
                size="sm"
                class="text-text-loud w-full break-all"
              >
                {{ brandProfileInformation.missionStatement }}
              </BaseText>
            </div>
          </div>
        </div>
        <div
          v-if="brandProfileInformation.aditionalInformation && brandProfileInformation.aditionalInformation"
          class="mb-8"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal my-2"
          >
            Additional Information
          </BaseText>
          <div
            class="flex w-full gap-x-2"
          >
            <div
              class="bg-background-normal rounded-md py-1.5 px-3 border border-border-normal w-full"
            >
              <BaseText
                type="body"
                size="sm"
                class="text-text-loud break-all"
              >
                {{ brandProfileInformation.aditionalInformation }}
              </BaseText>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: 'BrandProfile',
  props: {
    brandProfileExpanded: Boolean,
    brandProfileInformation: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    toggleExpanded () {
      if (this.brandProfileExpanded) this.$emit('update:brandProfileExpanded', false)
      else this.$emit('update:brandProfileExpanded', true)
    }
  }
}
</script>

<style scoped>
.custom-width {
  width: 95%;
}

@media (min-width: 640px) {
  .custom-width {
    width: 584px;
  }
}
</style>
