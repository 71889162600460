<template>
  <div class="px-4 w-full">
    <BaseCard
      border
      class="rounded-xl w-full bg-white"
    >
      <ModularDetailsSectionHeader
        :expanded="expanded"
        :section="{ type: 'platforms', title: 'Platforms' }"
        :badge-text="`${selectedPlatforms.length} Selected`"
        :filled="!!selectedPlatforms.length"
        @toggleExpanded="$emit('toggleExpanded')"
        @deleteSection="$emit('deleteSection')"
      />

      <div
        v-if="expanded"
        class="flex flex-wrap gap-2 px-4 py-4"
      >
        <!-- Instagram -->
        <ModularDetailsToggleOption
          :selected="selectedPlatforms.includes('instagram')"
          @click="toggleSelected('instagram')"
        >
          <img
            slot="icon"
            src="../../../assets/images/alternate-instagram-icon.svg"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Instagram
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- Facebook -->
        <ModularDetailsToggleOption
          :selected="selectedPlatforms.includes('facebook')"
          @click="toggleSelected('facebook')"
        >
          <img
            slot="icon"
            src="../../../assets/images/alternate-facebook-icon.svg"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Facebook
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- LinkedIn -->
        <ModularDetailsToggleOption
          :selected="selectedPlatforms.includes('linkedin')"
          @click="toggleSelected('linkedin')"
        >
          <img
            slot="icon"
            src="../../../assets/images/alternate-linkden-icon.svg"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            LinkedIn
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- TikTok -->
        <ModularDetailsToggleOption
          :selected="selectedPlatforms.includes('tiktok')"
          @click="toggleSelected('tiktok')"
        >
          <img
            slot="icon"
            src="../../../assets/images/alternate-tiktok-icon.svg"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            TikTok
          </BaseText>
        </ModularDetailsToggleOption>

        <!-- YouTube -->
        <ModularDetailsToggleOption
          :selected="selectedPlatforms.includes('youtube')"
          @click="toggleSelected('youtube')"
        >
          <img
            slot="icon"
            src="../../../assets/images/alternate-youtube-icon.svg"
          >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            YouTube
          </BaseText>
        </ModularDetailsToggleOption>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import ModularDetailsSectionHeader from './ModularDetailsSectionHeader.vue'
import ModularDetailsToggleOption from './ModularDetailsToggleOption.vue'

export default {
  name: 'Platforms',
  components: {
    ModularDetailsSectionHeader,
    ModularDetailsToggleOption
  },
  props: {
    value: {
      type: Array,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      selectedPlatforms: [],
      shared: false
    }
  },
  computed: {
  },
  mounted () {
    this.selectedPlatforms = this.value
  },
  methods: {
    getAspectClasses (section) {
      if (this.selectedPlatforms.includes(section)) {
        return this.shared ? 'cursor-default text-gray-700 border-gray-300 hover:border-gray-300' : 'border-green-600 bg-green-100 text-green-600 cursor-pointer'
      }

      return 'text-gray-700 hover:border-gray-500 cursor-pointer'
    },
    toggleSelected (ratio) {
      if (this.shared) return
      if (this.selectedPlatforms.includes(ratio)) {
        this.selectedPlatforms = this.selectedPlatforms.filter(r => r !== ratio)
      } else {
        this.selectedPlatforms.push(ratio)
      }

      this.$emit('update', this.index, this.selectedPlatforms)
    }
  }
}
</script>

<style scoped lang="css">
</style>
