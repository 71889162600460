<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path d="M13.2083 9.66667V12.2083C13.2083 13.1418 13.2083 13.6085 13.0267 13.965C12.8669 14.2786 12.6119 14.5336 12.2983 14.6933C11.9418 14.875 11.4751 14.875 10.5417 14.875H3.79167C2.85825 14.875 2.39154 14.875 2.03502 14.6933C1.72141 14.5336 1.46644 14.2786 1.30666 13.965C1.125 13.6085 1.125 13.1418 1.125 12.2083V5.39583C1.125 4.52132 1.125 4.08407 1.28502 3.74574C1.44988 3.39717 1.7305 3.11655 2.07907 2.95168C2.4174 2.79167 2.85466 2.79167 3.72917 2.79167H5.70833M9.45833 1.125H14.875M14.875 1.125V6.54167M14.875 1.125L7.16667 8.83333" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'ExternalLinkIcon',
    props: {
      width: {
        type: Number,
        default: 16
      },
      height: {
        type: Number,
        default: 16
      }
    }
  }
</script>