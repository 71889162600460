import { render, staticRenderFns } from "./ChangeFolderModal.vue?vue&type=template&id=b7bd7f14&scoped=true"
import script from "./ChangeFolderModal.vue?vue&type=script&lang=js"
export * from "./ChangeFolderModal.vue?vue&type=script&lang=js"
import style0 from "./ChangeFolderModal.vue?vue&type=style&index=0&id=b7bd7f14&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7bd7f14",
  null
  
)

export default component.exports