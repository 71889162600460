<template>
  <div class="px-4 w-full">
    <BaseCard
      border
      class="rounded-xl w-full bg-white"
    >
      <ModularDetailsSectionHeader
        :expanded="expanded"
        :section="{ type: 'aspectRatio', title: 'Size & Aspect Ratios' }"
        :badge-text="`${selectedRatios.length} Selected`"
        :filled="!!selectedRatios.length"
        @deleteSection="$emit('deleteSection')"
        @toggleExpanded="$emit('toggleExpanded')"
      />

      <div
        v-if="expanded"
        class="flex flex-wrap gap-2 px-4 py-4"
      >
        <!-- 16:9 -->
        <ModularDetailsToggleOption
          :selected="selectedRatios.includes('16:9')"
          @click="toggleSelected('16:9')"
        >
          <!-- Icon -->
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="3"
              y="6"
              width="18"
              height="12"
              rx="2"
              fill="url(#paint0_linear_5369_22401)"
              fill-opacity="0.2"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5369_22401"
                x1="12"
                y1="6"
                x2="12"
                y2="18"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="currentColor" />
                <stop
                  offset="1"
                  stop-color="currentColor"
                  stop-opacity="0"
                />
              </linearGradient>
            </defs>
          </svg>

          <!-- Content -->
          <div class="flex gap-1 items-center">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              16:9
            </BaseText>
            <BaseText
              type="label"
              size="sm"
            >
              (1920 x 1080)
            </BaseText>
          </div>
        </ModularDetailsToggleOption>
        <ModularDetailsToggleOption
          :selected="selectedRatios.includes('9:16')"
          @click="toggleSelected('9:16')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 3C17.1046 3 18 3.89543 18 5L18 19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19L6 5C6 3.89543 6.89543 3 8 3L16 3Z"
              fill="url(#paint0_linear_5369_22408)"
              fill-opacity="0.2"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5369_22408"
                x1="12"
                y1="3"
                x2="12"
                y2="21"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="currentColor" />
                <stop
                  offset="1"
                  stop-color="currentColor"
                  stop-opacity="0"
                />
              </linearGradient>
            </defs>
          </svg>

          <!-- Content -->
          <div class="flex gap-1 items-center">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              9:16
            </BaseText>
            <BaseText
              type="label"
              size="sm"
            >
              (1080 x 1920)
            </BaseText>
          </div>
        </ModularDetailsToggleOption>

        <!-- 1:1 Aspect Ratio -->
        <ModularDetailsToggleOption
          :selected="selectedRatios.includes('1:1')"
          @click="toggleSelected('1:1')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="4"
              y="4"
              width="16"
              height="16"
              rx="2"
              fill="url(#paint0_linear_5369_22415)"
              fill-opacity="0.2"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5369_22415"
                x1="12"
                y1="4"
                x2="12"
                y2="20"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="currentColor" />
                <stop
                  offset="1"
                  stop-color="currentColor"
                  stop-opacity="0"
                />
              </linearGradient>
            </defs>
          </svg>

          <div class="flex gap-1 items-center">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              1:1
            </BaseText>
            <BaseText
              type="label"
              size="sm"
            >
              (1080 x 1080)
            </BaseText>
          </div>
        </ModularDetailsToggleOption>

        <!-- 4:5 Aspect Ratio -->
        <ModularDetailsToggleOption
          :selected="selectedRatios.includes('4:5')"
          @click="toggleSelected('4:5')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19L5 19C3.89543 19 3 18.1046 3 17L3 7C3 5.89543 3.89543 5 5 5L19 5Z"
              fill="url(#paint0_linear_5369_22430)"
              fill-opacity="0.2"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5369_22430"
                x1="12"
                y1="5"
                x2="12"
                y2="19"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="currentColor" />
                <stop
                  offset="1"
                  stop-color="currentColor"
                  stop-opacity="0"
                />
              </linearGradient>
            </defs>
          </svg>

          <div class="flex gap-1 items-center">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              4:5
            </BaseText>
            <BaseText
              type="label"
              size="sm"
            >
              (1080 x 1350)
            </BaseText>
          </div>
        </ModularDetailsToggleOption>

        <!-- 2:3 Aspect Ratio -->
        <ModularDetailsToggleOption
          :selected="selectedRatios.includes('2:3')"
          @click="toggleSelected('2:3')"
        >
          <svg
            slot="icon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="6"
              y="4"
              width="12"
              height="16"
              rx="2"
              fill="url(#paint0_linear_5369_22437)"
              fill-opacity="0.2"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5369_22437"
                x1="12"
                y1="4"
                x2="12"
                y2="20"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="currentColor" />
                <stop
                  offset="1"
                  stop-color="currentColor"
                  stop-opacity="0"
                />
              </linearGradient>
            </defs>
          </svg>

          <div class="flex gap-1 items-center">
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              2:3
            </BaseText>
            <BaseText
              type="label"
              size="sm"
            >
              (1080 x 1620)
            </BaseText>
          </div>
        </ModularDetailsToggleOption>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import ModularDetailsSectionHeader from './ModularDetailsSectionHeader.vue'
import ModularDetailsToggleOption from './ModularDetailsToggleOption.vue'

export default {
  name: 'AspectRatio',
  components: {
    ModularDetailsSectionHeader,
    ModularDetailsToggleOption
  },
  props: {
    value: {
      type: Array,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    expanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      selectedRatios: [],
      shared: false
    }
  },
  computed: {
  },
  mounted () {
    this.selectedRatios = this.value
  },
  methods: {
    getAspectClasses (section) {
      if (this.selectedRatios.includes(section)) {
        return this.shared ? 'cursor-default text-gray-700 border-gray-300 hover:border-gray-300' : 'border-green-600 bg-green-100 text-green-600 cursor-pointer'
      }

      return 'text-gray-700 hover:border-gray-500 cursor-pointer'
    },
    toggleSelected (ratio) {
      if (this.shared) return
      if (this.selectedRatios.includes(ratio)) {
        this.selectedRatios = this.selectedRatios.filter(r => r !== ratio)
      } else {
        this.selectedRatios.push(ratio)
      }

      this.$emit('update', this.index, this.selectedRatios)
    }
  }
}
</script>

<style scoped lang="css">
</style>
