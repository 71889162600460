<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4.96876 14.5C6.20473 13.1191 8.00088 12.25 10 12.25C11.9991 12.25 13.7953 13.1191 15.0312 14.5M4.96876 14.5C6.20473 15.8809 8.00088 16.75 10 16.75C11.9991 16.75 13.7953 15.8809 15.0312 14.5M4.96876 14.5C3.89992 13.3058 3.25 11.7288 3.25 10C3.25 6.27208 6.27208 3.25 10 3.25C13.728 3.25 16.75 6.27208 16.75 10C16.75 11.7288 16.1 13.3058 15.0312 14.5" 
    :stroke="stroke" stroke-width="1.5"/>
    <path d="M7.96578 7.40085L8.9728 6.89735C9.04832 6.85958 9.1096 6.79832 9.14732 6.72278L9.65087 5.71578C9.79472 5.42807 10.2053 5.42807 10.3491 5.71578L10.8527 6.72278C10.8904 6.79832 10.9517 6.85958 11.0272 6.89735L12.0342 7.40085C12.3219 7.54471 12.3219 7.95529 12.0342 8.09915L11.0272 8.60267C10.9517 8.6404 10.8904 8.70167 10.8527 8.7772L10.3491 9.78422C10.2053 10.0719 9.79472 10.0719 9.65087 9.78422L9.14732 8.7772C9.1096 8.70167 9.04832 8.6404 8.9728 8.60267L7.96578 8.09915C7.67807 7.95529 7.67807 7.54471 7.96578 7.40085Z" 
    :fill="stroke" />
  </svg>
</template>

<script>
  export default {
    name: 'BeautyNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>