<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14 14" fill="none" class="animate-spin">
    <circle cx="7.0166" cy="7" r="6.05811" stroke="url(#paint0_linear_8671_11533)"/>
    <defs>
      <linearGradient id="paint0_linear_8671_11533" gradientUnits="userSpaceOnUse"
      x1="7.0166" y1="0.441895" x2="7.0166" y2="9.21771">
        <stop stop-color="#A4ABB8" />
        <stop offset="1" stop-color="#A4ABB8" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: 'AnalyticsSpinnerIcon',
    props: {
      width: {
        type: Number,
        default: 14
      },
      height: {
        type: Number,
        default: 14
      }
    }
  }
</script>

<style scoped></style>