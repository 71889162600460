<template>
  <svg
    :width="width"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :style="{ transform: `scale(${scale})` }"
  >
    <rect
      x="0.78418"
      y="0.103394"
      width="18.6498"
      height="18.6498"
      rx="3"
      fill="#E60000"
    />
    <path
      d="M14.8684 7.026C14.6687 6.41146 14.0078 6.03928 13.3996 5.95395C11.2121 5.72157 9.00604 5.72157 6.81846 5.95395C6.21027 6.03928 5.54761 6.40783 5.34973 7.026C5.03837 8.61244 5.03837 10.2443 5.34973 11.8307C5.54943 12.4444 6.21027 12.8174 6.81846 12.9028C9.00604 13.1352 11.2121 13.1352 13.3996 12.9028C14.0078 12.8174 14.6705 12.4489 14.8684 11.8307C15.1797 10.2443 15.1797 8.61244 14.8684 7.026ZM8.91446 11.3414V7.51437L12.0299 9.42791C10.9796 10.0733 9.96018 10.6988 8.91446 11.3414Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: 'YouTubeIcon',
  props: {
    width: {
      type: Number,
      default: () => 15
    }
  },
  computed: {
    scale () {
      return this.width / 20
    }
  }
}
</script>
<style scoped lang="css"></style>
