<template>
  <div
    class="flex items-center select-none py-0.5 px-2"
    :class="classes"
  >
    <!-- dot -->
    <div
      v-if="dot"
      class="rounded-full h-1 w-1 mr-2"
      :class="iconClasses"
    />

    <!-- Left Icon -->
    <div
      v-if="leftIcon"
      class="mx-0.5"
    >
      <slot name="leftIcon" />
    </div>

    <!-- Text -->
    <slot />

    <!-- Right Icon -->
    <div
      v-if="rightIcon"
      class="ml-0.5"
    >
      <slot
        name="rightIcon"
      />
    </div>
  </div>
</template>
<script>

export default {
  name: 'BaseBadge',
  props: {
    // Colors
    blue: {
      type: Boolean
    },
    yellow: {
      type: Boolean
    },
    red: {
      type: Boolean
    },
    green: {
      type: Boolean
    },
    purple: {
      type: Boolean
    },
    gray: {
      type: Boolean
    },

    // Sizes
    large: {
      type: Boolean
    },
    small: {
      type: Boolean
    },

    // Icons
    dot: {
      type: Boolean
    },
    leftIcon: {
      type: Boolean
    },
    rightIcon: {
      type: Boolean
    },

    // Styles
    square: {
      type: Boolean
    }
  },
  data: function () {
    return {
      // classes: '',
      // iconClasses: ''
    }
  },
  computed: {
    classes () {
      // Default Classes
      const classesArr = ['border']
      const rounding = this.square ? 'rounded-md' : 'rounded-full'
      classesArr.push(rounding)

      // Colors
      if (this.blue) {
        classesArr.push('border-primary-blue-25 bg-primary-blue-10 text-primary-blue-200')
      } else if (this.yellow) {
        classesArr.push('border-secondary-yellow-25 bg-secondary-yellow-10 text-secondary-yellow-200')
      } else if (this.red) {
        classesArr.push('border-secondary-red-25 bg-secondary-red-10 text-secondary-red-200')
      } else if (this.green) {
        classesArr.push('border-primary-green-25 bg-primary-green-10 text-primary-green-200')
      } else if (this.purple) {
        classesArr.push('border-primary-purple-25 bg-primary-purple-10 text-primary-purple-200')
      } else if (this.gray) {
        classesArr.push('border-border-normal-50 bg-background-normal-25 text-neutral-900')
      }

      // Icons
      if (this.leftIcon) {
        classesArr.push('pr-2')
      } else if (this.rightIcon) {
        classesArr.push('pl-2')
      }

      if (!this.leftIcon && !this.rightIcon) {
        classesArr.push('px-2')
      }

      return classesArr.join(' ')
    },
    iconClasses () {
    // Default Classes

      // Colors
      if (this.blue) {
        return 'bg-primary-blue-200'
      } else if (this.yellow) {
        return 'bg-secondary-yellow-200'
      } else if (this.red) {
        return 'bg-secondary-red-200'
      } else if (this.green) {
        return 'bg-primary-green-200'
      } else if (this.purple) {
        return 'bg-primary-purple-200'
      } else {
        return 'bg-neutral-900'
      }
    }
  }
}
</script>
