// Imports
import AxiosConfig from '../config/AxiosConfig'

const generateBrief = async (adId, language, brandDetails) => {
  const { data } = await AxiosConfig.post('/generateUserBrief', {
    adId,
    language,
    ...brandDetails
  })

  return data
}

const generateBriefVariations = async (story, index, rewriteText, field) => {
  const { data } = await AxiosConfig.post('/generateBriefVariations', {
    story, index, rewriteText, field
  })

  return data
}

const generateFrameScreenshots = async (videoUrl, adId, scenes) => {
  const { data } = await AxiosConfig.post('/generateFrameScreenshots', {
    adId,
    videoUrl,
    scenes
  })

  return data
}

export default {
  generateBrief,
  generateBriefVariations,
  generateFrameScreenshots
}
