<template>
  <BaseCard
    v-if="modularDetailsInformation.length > 0"
    class="w-96 h-max mt-5 rounded-xl bg-white shadow-sm custom-width"
    :class="modularDetailsExpanded ? 'pb-2' : '' "
    border
  >
    <div
      class="flex justify-between"
      :class="modularDetailsExpanded ? 'border-b border-neutral-50 border-opacity-80' : '' "
    >
      <div
        class="py-4 px-6 rounded-full flex"
      >
        <img
          src="../../../assets/icons/brush-icon.png"
          alt=""
          class=" w-10 h-10 rounded-full p-2.5 overflow-visible"
          style="box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);"
        >
        <div
          class="w-full ml-3 flex items-center"
        >
          <BaseText
            type="label"
            size="md"
            class="text-text-muted"
          >
            Modular Details
          </BaseText>
        </div>
      </div>
      <img
        src="../../../assets/icons/chevron-down-small.svg"
        alt=""
        :class="modularDetailsExpanded ? 'rotate-180 transform' : ''"
        class="w-5 mr-5 transition-transform duration-300 cursor-pointer"
        @click="toggleExpanded()"
      >
    </div>
    <div
      v-if="modularDetailsExpanded"
      class="px-6 flex flex-col pb-2 pt-3 gap-y-2.5"
    >
      <div
        v-for="(item, index) in modularDetailsInformation"
        :key="index"
      >
        <div
          v-if="item.type === 'description' && item.value !== ''"
        >
          <div>
            <BaseText
              type="body"
              size="sm"
              class="text-text-normal mb-2.5"
            >
              Description
            </BaseText>
            <div
              class="flex w-full gap-x-2"
            >
              <div
                class="bg-background-normal rounded-md px-4 py-3 border border-border-normal w-full"
              >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-loud break-words"
                >
                  <editor-content
                    :editor="editor"
                    class="editor__content h-full"
                  />
                </BaseText>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'loomLinks' && item.value.length"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mb-2.5"
          >
            Loom Video
          </BaseText>
          <div
            v-if="loomHtml"
            class="w-max h-max rounded-lg overflow-hidden flex items-center justify-center "
            v-html="loomHtml"
          />
        </div>
        <div
          v-if="item.type === 'audio' && item.value !== ''"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mb-2.5"
          >
            Music & Sound
          </BaseText>
          <div
            style="border-radius: 10px;"
            class="border border-border-normal pb-3"
          >
            <div
              v-for="(link, iteration) in item.value"
              :key="iteration"
              style="border-radius: 10px;"
              class="flex flex-row px-3 gap-x-3 flex-wrap sm:gap-y-0 sm:flex-nowrap mt-3"
            >
              <div
                style="width:148px;"
              >
                <BaseText
                  v-if="iteration === 0"
                  type="body"
                  size="sm"
                  class="text-text-normal mb-2.5"
                >
                  Link:
                </BaseText>
                <a
                  :href="link.link"
                  target="_blank"
                  class=""
                >
                  <div
                    class="flex flex-row bg-primary-green-10 py-1.5 px-3 rounded-md justify-between"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-primary-green-200 mr-1.5"
                    >
                      Music & Sound
                    </BaseText>
                    <img
                      src="../../../assets/icons/green-link-arrow-icon.svg"
                      alt=""
                    >
                  </div>
                </a>
              </div>
              <div
                class="sm:flex-1"
                :class="link.notes === '' ? 'opacity-0' : ''"
              >
                <BaseText
                  v-if="iteration === 0"
                  type="body"
                  size="sm"
                  class="text-text-normal mb-2.5 w-1/5"
                >
                  Notes:
                </BaseText>
                <div
                  class="flex flex-row bg-background-normal py-1.5 px-3 rounded-md"
                >
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-loud mr-1.5 flex-1 break-all"
                  >
                    {{ link.notes }}
                  </BaseText>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'contentStyle'
            && item.value !== ''"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mb-2.5"
          >
            Content Style
          </BaseText>
          <div
            class="flex gap-x-3 gap-2 flex-wrap"
          >
            <div
              v-for="(style, iteration) in item.value"
              :key="iteration"
              class="flex flex-row py-1.5 px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 p-x-1"
            >
              <img
                v-if="style === 'ugc'"
                src="../../../assets/icons/avatar-star-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'lifestyle'"
                src="../../../assets/icons/heart-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'animation'"
                src="../../../assets/icons/landscape-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'studio'"
                src="../../../assets/icons/studio.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'commercial'"
                src="../../../assets/icons/commercial.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'editorial'"
                src="../../../assets/icons/editorial.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <BaseText
                type="body"
                size="sm"
                class="mr-1"
              >
                {{ style }}
              </BaseText>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'assets' && item.value !== ''"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mb-2.5"
          >
            Assets & Files
          </BaseText>
          <div
            style="border-radius: 10px;"
            class="border border-border-normal pb-3"
          >
            <div
              v-for="(link, iteration) in item.value"
              :key="iteration"
              style="border-radius: 10px;"
              class="flex flex-row px-3 gap-x-3 flex-wrap sm:gap-y-0 sm:flex-nowrap mt-3"
            >
              <div
                style="width:148px;"
              >
                <BaseText
                  v-if="iteration === 0"
                  type="body"
                  size="sm"
                  class="text-text-normal mb-2.5"
                >
                  Link:
                </BaseText>
                <a
                  :href="link.link"
                  target="_blank"
                  class=""
                >
                  <div
                    class="flex flex-row bg-primary-green-10 py-1.5 px-3 rounded-md justify-between"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-primary-green-200 mr-1.5"
                    >
                      Assets & Files
                    </BaseText>
                    <img
                      src="../../../assets/icons/green-link-arrow-icon.svg"
                      alt=""
                    >
                  </div>
                </a>
              </div>
              <div
                class="sm:flex-1"
                :class="link.notes === '' ? 'opacity-0' : ''"
              >
                <BaseText
                  v-if="iteration === 0"
                  type="body"
                  size="sm"
                  class="text-text-normal mb-2.5 w-1/5"
                >
                  Notes:
                </BaseText>
                <div
                  class="flex flex-row bg-background-normal py-1.5 px-3 rounded-md"
                >
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-loud mr-1.5 flex-1 break-all "
                  >
                    {{ link.notes }}
                  </BaseText>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'products' && item.value !== ''"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mb-2.5"
          >
            Products
          </BaseText>
          <div
            style="border-radius: 10px;"
            class="border border-border-normal pb-3"
          >
            <div
              v-for="(link, iteration) in item.value"
              :key="iteration"
              style="border-radius: 10px;"
              class="flex flex-row px-3 gap-x-3 flex-wrap sm:gap-y-0 sm:flex-nowrap mt-3"
            >
              <div
                style="width:148px;"
              >
                <BaseText
                  v-if="iteration === 0"
                  type="body"
                  size="sm"
                  class="text-text-normal mb-2.5"
                >
                  Link:
                </BaseText>
                <a
                  :href="link.link"
                  target="_blank"
                  class=""
                >
                  <div
                    class="flex flex-row bg-primary-green-10 py-1.5 px-3 rounded-md justify-between"
                  >
                    <BaseText
                      type="body"
                      size="sm"
                      class="text-primary-green-200 mr-1.5"
                    >
                      Product
                    </BaseText>
                    <img
                      src="../../../assets/icons/green-link-arrow-icon.svg"
                      alt=""
                    >
                  </div>
                </a>
              </div>
              <div
                class="sm:flex-1"
                :class="link.notes === '' ? 'opacity-0' : ''"
              >
                <BaseText
                  v-if="iteration === 0"
                  type="body"
                  size="sm"
                  class="text-text-normal mb-2.5 w-1/5"
                >
                  Notes:
                </BaseText>
                <div
                  class="flex flex-row bg-background-normal py-1.5 px-3 rounded-md"
                >
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-loud w-full mr-1.5 flex-1 break-all"
                  >
                    {{ link.notes }}
                  </BaseText>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'platforms' && item.value !== ''"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mb-2.5"
          >
            Content Style
          </BaseText>
          <div
            class="flex gap-x-3 gap-2 flex-wrap"
          >
            <div
              v-for="(style, iteration) in item.value"
              :key="iteration"
              class="flex flex-row py-1.5 px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 p-x-1"
            >
              <img
                v-if="style === 'instagram'"
                src="../../../assets/images/alternate-instagram-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'linkedin'"
                src="../../../assets/images/alternate-linkden-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'facebook'"
                src="../../../assets/images/alternate-facebook-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'tiktok'"
                src="../../../assets/images/alternate-tiktok-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <img
                v-if="style === 'youtube'"
                src="../../../assets/images/alternate-youtube-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <BaseText
                type="body"
                size="sm"
                class="mr-1"
              >
                {{ style }}
              </BaseText>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'format' && item.value !== ''"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mb-2.5"
          >
            Format
          </BaseText>
          <div
            class="flex gap-x-3 gap-2 flex-wrap"
          >
            <div
              v-if="item.value !== 'video' || item.value !== 'image' || item.value !== 'carousel' || item.value !== 'gif'"
              class="flex flex-row py-1.5 px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 p-x-1"
            >
              <img
                v-if="item.value === 'video'"
                src="../../../assets/icons/video-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <BaseText
                v-if="item.value === 'video'"
                type="body"
                size="sm"
                class="mr-1"
              >
                Video
              </BaseText>
              <img
                v-if="item.value === 'image'"
                src="../../../assets/icons/image-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <BaseText
                v-if="item.value === 'image'"
                type="body"
                size="sm"
                class="mr-1"
              >
                Still Image
              </BaseText>
              <img
                v-if="item.value === 'carousel'"
                src="../../../assets/icons/carousel.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <BaseText
                v-if="item.value === 'carousel'"
                type="body"
                size="sm"
                class="mr-1"
              >
                Carousel
              </BaseText>
              <img
                v-if="item.value === 'gif'"
                src="../../../assets/icons/gif.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <BaseText
                v-if="item.value === 'gif'"
                type="body"
                size="sm"
                class="mr-1"
              >
                GIF
              </BaseText>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'aspectRatio' && item.value !== ''"
          class="w-full flex flex-wrap gap-x-3"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal w-full"
          >
            Aspect Ratios
          </BaseText>
          <div
            v-for="(ratio, iteration) in item.value"
            :key="iteration"
            class="flex flex-wrap mt-2.5"
          >
            <!-- Icon -->
            <div
              v-if="ratio === '16:9'"
              class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
            >
              <svg
                slot="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3"
                  y="6"
                  width="18"
                  height="12"
                  rx="2"
                  fill="url(#paint0_linear_5369_22401)"
                  fill-opacity="0.2"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5369_22401"
                    x1="12"
                    y1="6"
                    x2="12"
                    y2="18"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="currentColor" />
                    <stop
                      offset="1"
                      stop-color="currentColor"
                      stop-opacity="0"
                    />
                  </linearGradient>
                </defs>
              </svg>
              <!-- Content -->
              <div
                class="flex gap-1 items-center"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  16:9
                </BaseText>
                <BaseText
                  type="body"
                  size="sm"
                  class="text-neutral-500"
                >
                  (1920 x 1080)
                </BaseText>
              </div>
            </div>
            <div
              v-if="ratio === '9:16'"
              class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
            >
              <svg
                slot="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 3C17.1046 3 18 3.89543 18 5L18 19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19L6 5C6 3.89543 6.89543 3 8 3L16 3Z"
                  fill="url(#paint0_linear_5369_22408)"
                  fill-opacity="0.2"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5369_22408"
                    x1="12"
                    y1="3"
                    x2="12"
                    y2="21"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="currentColor" />
                    <stop
                      offset="1"
                      stop-color="currentColor"
                      stop-opacity="0"
                    />
                  </linearGradient>
                </defs>
              </svg>
              <!-- Content -->
              <div
                class="flex gap-1 items-center"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  9:16
                </BaseText>
                <BaseText
                  type="body"
                  size="sm"
                  class="text-neutral-500"
                >
                  (1080 x 1920)
                </BaseText>
              </div>
            </div>

            <!-- 1:1 Aspect Ratio -->
            <div
              v-if="ratio === '1:1'"
              class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
            >
              <svg
                slot="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="4"
                  y="4"
                  width="16"
                  height="16"
                  rx="2"
                  fill="url(#paint0_linear_5369_22415)"
                  fill-opacity="0.2"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5369_22415"
                    x1="12"
                    y1="4"
                    x2="12"
                    y2="20"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="currentColor" />
                    <stop
                      offset="1"
                      stop-color="currentColor"
                      stop-opacity="0"
                    />
                  </linearGradient>
                </defs>
              </svg>

              <div class="flex gap-x-1">
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  1:1
                </BaseText>
                <BaseText
                  type="body"
                  size="sm"
                  class="text-neutral-500"
                >
                  (1080 x 1080)
                </BaseText>
              </div>
            </div>
            <!-- 4:5 Aspect Ratio -->
            <div
              v-if="ratio === '4:5'"
              class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
            >
              <svg
                slot="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 5C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19L5 19C3.89543 19 3 18.1046 3 17L3 7C3 5.89543 3.89543 5 5 5L19 5Z"
                  fill="url(#paint0_linear_5369_22430)"
                  fill-opacity="0.2"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5369_22430"
                    x1="12"
                    y1="5"
                    x2="12"
                    y2="19"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="currentColor" />
                    <stop
                      offset="1"
                      stop-color="currentColor"
                      stop-opacity="0"
                    />
                  </linearGradient>
                </defs>
              </svg>

              <div
                class="flex gap-1 items-center"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  4:5
                </BaseText>
                <BaseText
                  type="body"
                  size="sm"
                  class="text-neutral-500"
                >
                  (1080 x 1350)
                </BaseText>
              </div>
            </div>
            <!-- 2:3 Aspect Ratio -->
            <div
              v-if="ratio === '2:3'"
              class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
            >
              <svg
                slot="icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="6"
                  y="4"
                  width="12"
                  height="16"
                  rx="2"
                  fill="url(#paint0_linear_5369_22437)"
                  fill-opacity="0.2"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_5369_22437"
                    x1="12"
                    y1="4"
                    x2="12"
                    y2="20"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="currentColor" />
                    <stop
                      offset="1"
                      stop-color="currentColor"
                      stop-opacity="0"
                    />
                  </linearGradient>
                </defs>
              </svg>

              <div
                class="flex gap-1 items-center"
              >
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  2:3
                </BaseText>
                <BaseText
                  type="body"
                  size="sm"
                  class="text-neutral-500"
                >
                  (1080 x 1620)
                </BaseText>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'targetDuration'"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-normal mb-2.5"
          >
            Target Duration
          </BaseText>
          <div
            class="flex gap-x-3 gap-2 flex-wrap"
          >
            <div
              class="flex flex-row py-1.5 px-2.5 rounded-full justify-center items-center border border-border-normal w-max h-9 p-x-1"
            >
              <img
                src="../../../assets/icons/clock-icon.svg"
                alt=""
                class="w-6 mr-1.5"
              >
              <BaseText
                type="body"
                size="sm"
                class="mr-1"
              >
                {{ item.value.hh ? item.value.hh + ' ' + (item.value.hh > 1 ? 'hours' : 'hour') : '' }}  {{ item.value.mm ? item.value.mm + ' ' + (item.value.mm > 1 ? 'mins' : 'min') : '' }} {{ item.value.ss ? item.value.ss : '0' }} sec
              </BaseText>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Link from '@tiptap/extension-link'
import * as loom from '@loomhq/loom-embed'
import Heading from '@tiptap/extension-heading'

export default {
  name: 'ModularDetails',
  components: {
    EditorContent
  },
  props: {
    modularDetailsExpanded: Boolean,
    modularDetailsInformation: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      editor: null,
      remixiconUrl,
      editorValue: null,
      loomHtml: '',
      loomEmbedOptions: {
        width: 536
      }
    }
  },
  mounted () {
    const description = this.modularDetailsInformation.find(item => item.type === 'description')
    const loomEmbedLink = this.modularDetailsInformation?.filter(item => item?.type === 'loomLinks')[0]?.value[0]?.link
    if (loomEmbedLink) {
      this.createEmbed(loomEmbedLink)
    }

    // will return if description is empty
    if (!description) return

    this.editorValue = description.value || '<p></p>'

    this.editor = new Editor({
      editable: false,
      extensions: [
        StarterKit,
        Link.configure({
          HTMLAttributes: {
            class: 'text-blue-600 cursor-pointer'
          }
        }),
        Heading.configure({
          levels: [1, 2, 3],
          HTMLAttributes: {
            class: 'heading-class'
          }
        })
      ],
      content: this.editorValue
    })
  },
  methods: {
    toggleExpanded () {
      console.log(this.modularDetailsInformation)
      if (this.modularDetailsExpanded) this.$emit('update:modularDetailsExpanded', false)
      else this.$emit('update:modularDetailsExpanded', true)
    },
    async createEmbed (embedLink) {
      if (!embedLink) return
      const loomEmbed = await loom.oembed(embedLink, this.loomEmbedOptions)
      this.loomHtml = loomEmbed.html
    }
  }
}
</script>

<style scoped>
.custom-width {
  width: 95%;
}

@media (min-width: 640px) {
  .custom-width {
    width: 584px;
  }
}
</style>
