<template>
  <a
    :href="item?.link && item?.link"
    target="_blank"
    rel="noopener noreferrer"
    class="w-full flex items-center justify-between"
    @click="() => { !item?.link && item?.action() }"
  >
    <BaseCard
      class="h-max w-full flex-col justify-between items-center py-6 pr-5 pl-2 group"
      :class="{
        'cursor-pointer': !item.commingSoon,
        '': item.commingSoon
      }"
      :no-padding="true"
    >
      <div class="flex items-center w-full ">
        <div
          v-if="item.title=== 'Book a Demo'"
          class="ml-3 w-11 h-11 border-2 border-border-normal rounded-full flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            view-box="0 0 25 25"
            fill="none"
          >
            <path
              d="M5.7464 10.469V10.219C5.7464 6.49107 8.99233 3.46899 12.9964 3.46899C17.0005 3.46899 20.2464 6.49107 20.2464 10.219V10.469M12.9964 20.3619V20.969C12.9964 21.5213 13.4441 21.969 13.9964 21.969H15.9964C18.4817 21.969 20.4964 19.9543 20.4964 17.469M5.2464 10.469H6.7464V16.969H5.2464C4.41797 16.969 3.7464 16.2974 3.7464 15.469V11.969C3.7464 11.1406 4.41797 10.469 5.2464 10.469ZM19.2464 10.469H20.7464C21.5748 10.469 22.2464 11.1406 22.2464 11.969V15.469C22.2464 16.2974 21.5748 16.969 20.7464 16.969H19.2464V10.469Z"
              stroke="#5E6678"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          v-if="item.title === 'Help Articles' "
          class="ml-3 w-11 h-11 border-2 border-border-normal rounded-full flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <g id="questionmark, faq, help, questionaire">
              <g id="Icon">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.9964 3.80225C8.30198 3.80225 4.4964 7.60783 4.4964 12.3022C4.4964 16.9967 8.30198 20.8022 12.9964 20.8022C17.6908 20.8022 21.4964 16.9967 21.4964 12.3022C21.4964 7.60783 17.6908 3.80225 12.9964 3.80225ZM2.9964 12.3022C2.9964 6.7794 7.47355 2.30225 12.9964 2.30225C18.5192 2.30225 22.9964 6.7794 22.9964 12.3022C22.9964 17.8251 18.5192 22.3022 12.9964 22.3022C7.47355 22.3022 2.9964 17.8251 2.9964 12.3022Z"
                  fill="#5E6678"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.2464 8.80225C11.8322 8.80225 11.4964 9.13803 11.4964 9.55225C11.4964 9.96646 11.1606 10.3022 10.7464 10.3022C10.3322 10.3022 9.9964 9.96646 9.9964 9.55225C9.9964 8.30961 11.0038 7.30225 12.2464 7.30225H13.4551C14.8586 7.30225 15.9964 8.44003 15.9964 9.84356C15.9964 10.6933 15.5717 11.4867 14.8648 11.9581L14.303 12.3326C13.9553 12.5644 13.7464 12.9547 13.7464 13.3726V13.5522C13.7464 13.9665 13.4106 14.3022 12.9964 14.3022C12.5822 14.3022 12.2464 13.9665 12.2464 13.5522V13.3726C12.2464 12.4531 12.7059 11.5945 13.471 11.0845L14.0327 10.71C14.3224 10.5169 14.4964 10.1917 14.4964 9.84356C14.4964 9.26846 14.0302 8.80225 13.4551 8.80225H12.2464Z"
                  fill="#5E6678"
                />
                <path
                  d="M11.9964 16.3022C11.9964 15.75 12.4441 15.3022 12.9964 15.3022C13.5487 15.3022 13.9964 15.75 13.9964 16.3022C13.9964 16.8545 13.5487 17.3022 12.9964 17.3022C12.4441 17.3022 11.9964 16.8545 11.9964 16.3022Z"
                  fill="#5E6678"
                />
              </g>
            </g>
          </svg>
        </div>
        <div
          v-if="item.title === 'Follow us on Twitter'"
          class="ml-3 w-11 h-11 border-2 border-border-normal rounded-full flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <g
              id="twitter-line-icon"
              clip-path="url(#clip0_7947_62626)"
            >
              <path
                id="Vector 868"
                d="M7.63204 14.1101C6.38938 15.4128 3.75792 15.6493 2.59752 15.6047C6.49219 17.9785 11.4797 17.672 14.7109 14.359C17.942 11.046 17.7256 7.49696 17.682 6.78951C18.6514 6.17377 19.1449 5.44449 19.3938 5.02526C19.1493 5.28728 18.0401 5.44449 17.4723 5.52309C18.4505 4.91609 18.8217 4.09073 18.9134 3.64982C17.9562 4.23687 17.0924 4.46953 16.7801 4.51249C16.3459 3.97118 14.97 3.04082 12.94 3.64982C10.9099 4.25883 10.7101 6.55119 10.864 7.62124C6.22904 7.26214 4.18299 4.69832 3.73203 4.03033C2.56954 6.44884 3.94916 8.10572 4.78428 8.63185C3.76543 8.66525 3.50655 8.38131 3.23931 8.2059C3.38963 11.0537 5.43567 11.538 5.97015 11.6382C5.80313 11.7719 4.75923 11.7802 4.43353 11.705C5.01811 13.6759 6.99735 14.0517 7.63204 14.1101Z"
                stroke="#5E6678"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_7947_62626">
                <rect
                  width="20"
                  height="20"
                  fill="white"
                  transform="translate(0.996399 0.718994)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          v-if="item.title === 'Perks & Discounts'"
          class="ml-3 w-11 h-11 border-2 border-border-normal rounded-full flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <g id="gift side view, present">
              <path
                id="Icon"
                d="M12.9964 8.05225V6.38558M12.9964 8.05225H11.3297C9.48878 8.05225 7.9964 6.55986 7.9964 4.71891C7.9964 3.79844 8.74259 3.05225 9.66307 3.05225C11.504 3.05225 12.9964 4.54463 12.9964 6.38558M12.9964 8.05225H14.6631C16.504 8.05225 17.9964 6.55986 17.9964 4.71891C17.9964 3.79844 17.2502 3.05225 16.3297 3.05225C14.4888 3.05225 12.9964 4.54463 12.9964 6.38558M12.9964 8.05225H6.7464C5.64183 8.05225 4.7464 8.94768 4.7464 10.0522V10.3022C4.7464 11.0425 5.1486 11.6889 5.7464 12.0347M12.9964 8.05225H19.2464C20.351 8.05225 21.2464 8.94768 21.2464 10.0522V10.3022C21.2464 11.0425 20.8442 11.6889 20.2464 12.0347M12.9964 8.05225V12.3022M12.9964 20.5522H7.7464C6.64183 20.5522 5.7464 19.6568 5.7464 18.5522V12.0347M12.9964 20.5522H18.2464C19.351 20.5522 20.2464 19.6568 20.2464 18.5522V12.0347M12.9964 20.5522V12.3022M12.9964 12.3022H19.2464C19.6107 12.3022 19.9522 12.2049 20.2464 12.0347M12.9964 12.3022H6.7464C6.38211 12.3022 6.04057 12.2049 5.7464 12.0347M5.7464 11.8022V12.0347M20.2464 12.0347V11.8022"
                stroke="#5E6678"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>
        <div
          v-if="item.title === 'Affiliate Program'"
          class="ml-3 w-11 h-11 border-2 border-border-normal rounded-full flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <g id="people like, love, heart">
              <path
                id="Icon"
                d="M14.2464 14.0639C13.8439 14.0014 13.4267 13.969 12.9964 13.969C9.60525 13.969 7.03239 15.9833 5.97888 18.8319C5.57008 19.9372 6.51473 20.969 7.69324 20.969H13.2568M18.9964 21.9968C19.2672 21.9968 22.2464 20.4621 22.2464 18.3857C22.2464 16.9412 21.3436 16.219 20.4408 16.219C19.5381 16.219 18.9964 16.7607 18.9964 16.7607C18.9964 16.7607 18.4547 16.219 17.552 16.219C16.6492 16.219 15.7464 16.9412 15.7464 18.3857C15.7464 20.4621 18.7256 21.9968 18.9964 21.9968ZM16.7464 7.21899C16.7464 9.29006 15.0675 10.969 12.9964 10.969C10.9253 10.969 9.2464 9.29006 9.2464 7.21899C9.2464 5.14793 10.9253 3.46899 12.9964 3.46899C15.0675 3.46899 16.7464 5.14793 16.7464 7.21899Z"
                stroke="#5E6678"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>
        <div
          v-if="item.title === 'Chat with Support'"
          class="ml-3 w-11 h-11 border-2 border-border-normal rounded-full flex items-center justify-center"
        >
          <svg
            src="../../assets/icons/new-folder-icon.svg"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <g id="bubbles 2, messages, chat, communicate">
              <path
                id="Icon"
                d="M18.2464 14.5522H20.2486C21.3532 14.5522 22.2486 13.6568 22.2486 12.5522V6.05225C22.2486 4.94768 21.3532 4.05225 20.2486 4.05225H9.9986C8.89403 4.05225 7.9986 4.94768 7.9986 6.05225V8.05225M16.2486 8.05225H5.7486C4.64403 8.05225 3.7486 8.94768 3.7486 10.0522V16.5522C3.7486 17.6568 4.64403 18.5522 5.7486 18.5522H6.9986V21.0522L11.4986 18.5522H16.2486C17.3532 18.5522 18.2486 17.6568 18.2486 16.5522V10.0522C18.2486 8.94768 17.3532 8.05225 16.2486 8.05225Z"
                stroke="#5E6678"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </div>

        <div class="ml-3">
          <BaseText
            type="label"
            size="md"
            class="text-neutral-900 font-medium"
          >
            {{ item.title }}
          </BaseText>

          <BaseText
            type="body"
            size="sm"
            class="text-text-normal w-52 overflow-visible whitespace-nowrap"
          >
            {{ item.description }}
          </BaseText>
        </div>
        <img
          v-if="!item.commingSoon"
          src="../../assets/icons/external-tab.svg"
          alt=""
          class="ml-auto mr-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in"
        >
        <BaseText
          v-if="item.commingSoon"
          type="label"
          size="sm"
          class="text-text-disabled ml-auto"
        >
          Coming Soon
        </BaseText>
      </div>
    </BaseCard>
  </a>
</template>
<script>
export default {
  name: 'InlineCard',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped>
