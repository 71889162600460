<template>
  <BaseModal @close="$emit('close')">
    <template #toolbar>
      <div class="bg-white flex w-full rounded-md items-center gap-x-3 -mb-3">
        <img
          src="../../../assets/images/brief-icon-black-white.png"
          alt=""
          class="w-11 h-11"
        >
        <!-- Right Side -->
        <div class="transform -translate-y-0.5">
          <BaseText
            type="label"
            size="md"
          >
            Submit your content
          </BaseText>
          <div class="text-sm text-gray-500">
            Upload your completed assets for
            {{
              uploadModalInformation?.briefName &&
              uploadModalInformation?.briefName
            }}
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div
        class="border-t border-border-normal p-5 overflow-scroll scrollbar-hide h-max max-h-128"
      >
        <div
          v-if="uploadModalInformation?.aspectRatios"
          class="border border-border-normal rounded-md p-4 flex flex-col gap-y-4 overflow-y-scroll"
        >
          <div class="overflow-y-scroll">
            <BaseText
              type="body"
              size="sm"
              class="text-text-muted"
            >
              Deliverables
            </BaseText>
            <div class="flex flex-wrap mt-2.5 gap-3">
              <!-- Icon -->
              <div
                v-if="
                  uploadModalInformation?.aspectRatios &&
                  arrayContainsValue(
                    uploadModalInformation?.aspectRatios,
                    '16:9'
                  )
                "
                class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
              >
                <svg
                  slot="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3"
                    y="6"
                    width="18"
                    height="12"
                    rx="2"
                    fill="url(#paint0_linear_5369_22401)"
                    fill-opacity="0.2"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_5369_22401"
                      x1="12"
                      y1="6"
                      x2="12"
                      y2="18"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="currentColor" />
                      <stop
                        offset="1"
                        stop-color="currentColor"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                </svg>
                <!-- Content -->
                <div class="flex gap-1 items-center">
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    16:9
                  </BaseText>
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-neutral-500"
                  >
                    (1920 x 1080)
                  </BaseText>
                </div>
              </div>
              <div
                v-if="
                  uploadModalInformation?.aspectRatios &&
                  arrayContainsValue(
                    uploadModalInformation?.aspectRatios,
                    '9:16'
                  )
                "
                class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
              >
                <svg
                  slot="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 3C17.1046 3 18 3.89543 18 5L18 19C18 20.1046 17.1046 21 16 21H8C6.89543 21 6 20.1046 6 19L6 5C6 3.89543 6.89543 3 8 3L16 3Z"
                    fill="url(#paint0_linear_5369_22408)"
                    fill-opacity="0.2"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_5369_22408"
                      x1="12"
                      y1="3"
                      x2="12"
                      y2="21"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="currentColor" />
                      <stop
                        offset="1"
                        stop-color="currentColor"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                </svg>
                <!-- Content -->
                <div class="flex gap-1 items-center">
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    9:16
                  </BaseText>
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-neutral-500"
                  >
                    (1080 x 1920)
                  </BaseText>
                </div>
              </div>

              <!-- 1:1 Aspect Ratio -->
              <div
                v-if="
                  uploadModalInformation?.aspectRatiosn &&
                  arrayContainsValue(
                    uploadModalInformation?.aspectRatios,
                    '1:1'
                  )
                "
                class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
              >
                <svg
                  slot="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4"
                    y="4"
                    width="16"
                    height="16"
                    rx="2"
                    fill="url(#paint0_linear_5369_22415)"
                    fill-opacity="0.2"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_5369_22415"
                      x1="12"
                      y1="4"
                      x2="12"
                      y2="20"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="currentColor" />
                      <stop
                        offset="1"
                        stop-color="currentColor"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                </svg>

                <div class="flex gap-x-1">
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    1:1
                  </BaseText>
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-neutral-500"
                  >
                    (1080 x 1080)
                  </BaseText>
                </div>
              </div>
              <!-- 4:5 Aspect Ratio -->
              <div
                v-if="
                  uploadModalInformation?.aspectRatios &&
                  arrayContainsValue(
                    uploadModalInformation?.aspectRatios,
                    '4:5'
                  )
                "
                class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
              >
                <svg
                  slot="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 5C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19L5 19C3.89543 19 3 18.1046 3 17L3 7C3 5.89543 3.89543 5 5 5L19 5Z"
                    fill="url(#paint0_linear_5369_22430)"
                    fill-opacity="0.2"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_5369_22430"
                      x1="12"
                      y1="5"
                      x2="12"
                      y2="19"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="currentColor" />
                      <stop
                        offset="1"
                        stop-color="currentColor"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                </svg>

                <div class="flex gap-1 items-center">
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    4:5
                  </BaseText>
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-neutral-500"
                  >
                    (1080 x 1350)
                  </BaseText>
                </div>
              </div>
              <!-- 2:3 Aspect Ratio -->
              <div
                v-if="
                  uploadModalInformation?.aspectRatios &&
                  arrayContainsValue(
                    uploadModalInformation?.aspectRatios,
                    '2:3'
                  )
                "
                class="flex flex-row px-3 rounded-full justify-center items-center border border-border-normal w-max h-9 gap-x-2"
              >
                <svg
                  slot="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="6"
                    y="4"
                    width="12"
                    height="16"
                    rx="2"
                    fill="url(#paint0_linear_5369_22437)"
                    fill-opacity="0.2"
                    stroke="currentColor"
                    stroke-width="1.5"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_5369_22437"
                      x1="12"
                      y1="4"
                      x2="12"
                      y2="20"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="currentColor" />
                      <stop
                        offset="1"
                        stop-color="currentColor"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                </svg>

                <div class="flex gap-1 items-center">
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    2:3
                  </BaseText>
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-neutral-500"
                  >
                    (1080 x 1620)
                  </BaseText>
                </div>
              </div>
            </div>
          </div>
          <!-- <BaseText
          type="body"
          size="sm"
          class="text-text-muted"
        >
          Notes
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-muted bg-background-normal rounded-md p-2 -mt-1 whitespace-nowrap"
        >
          Notes
        </BaseText> -->
        </div>
        <div class="flex gap-x-4">
          <BaseInputV2
            :value="firstName"
            label="First Name"
            class="w-1/2 mt-5"
            placeholder="John"
            @input="(value) => (firstName = value)"
          />
          <BaseInputV2
            :value="lastName"
            label="Last Name"
            optional
            class="w-1/2 mt-5"
            placeholder="Doe"
            base-input-v2
            @input="(value) => (lastName = value)"
          />
        </div>
        <BaseInputV2
          v-model="email"
          :value="email"
          label="Email"
          class="w-full mt-5"
          placeholder="example@email.com"
          :errored="validEmail"
          @input="
            (value) => {
              (email = value), (validEmail = false);
            }
          "
        />

        <div
          class="flex pb-1 pt-5"
        >
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted mr-auto"
          >
            Upload Content
          </BaseText>
          <label
            class="h-5 rounded-full"
            :for="`uploadInput`"
            @click="shouldGetUserData"
            @drop.prevent="onDrop"
          >
            <BaseBadge
              grey
              class="bg-background-normal cursor-pointer hover:bg-background-hover"
            >
              <BaseText
                type="label"
                size="xs"
                class="text-text-muted"
              >
                Add file +
              </BaseText>
            </BaseBadge>
          </label>
        </div>
        <div>
          <label
            v-if="!filePreviews[0]"
            :for="`uploadInput`"
            class="block w-full text-center mb-16"
            @click="shouldGetUserData"
            @drop.prevent="onDrop"
          >
            <div
              class="flex justify-center items-center h-36 w-full border-2 border-border-hover border-dashed rounded-xl transition duration-500 cursor-pointer"
              :class="{
                'bg-white': !uploading,
              }"
            >
              <div class="flex flex-col items-center justify-center">
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-muted"
                >
                  Drag & Drop or
                  <span class="text-primary-green-100">Choose File</span> to
                  upload
                </BaseText>
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-subdued"
                >
                  MP4, MOV, JPG or PNG
                </BaseText>
              </div>
            </div>
          </label>
          <div
            v-else
            class="flex-col items-center h-max gap-x-5 w-full border-2 border-border-hover border-dashed rounded-xl transition duration-500 cursor-pointer overflow-y-auto mb-20 scrollbar-hide"
            style="max-height: 230px"
            :class="{
              'border-gray-400 bg-gradient-to-b from-gray-600': uploading,
              'bg-white': !uploading,
            }"
            @drop.prevent="onDrop"
          >
            <div
              v-for="(preview, index) in filePreviews"
              :key="index + 293920"
              class="flex items-center justify-center w-full h-full gap-x-6 pr-6 my-3"
            >
              <label
                :for="`uploadInput`"
                class="flex items-center justify-center w-full h-full gap-x-6"
                @click="shouldGetUserData"
                @drop.prevent="onDrop"
              >
                <div
                  class="w-24 h-24 border border-border-normal rounded-md flex items-center justify-center"
                >
                  <div
                    class="w-24 h-24 border border-border-normal rounded-md flex items-center justify-center contain overflow-auto p-2"
                  >
                    <img
                      v-if="preview.file.type.includes('image/')"
                      :src="preview.preview"
                      alt="File Preview"
                      class="contain"
                    >
                    <video
                      v-else
                      class="contain max-h-full max-w-full"
                      :src="preview.preview"
                    />
                  </div>
                </div>
                <div
                  class="flex flex-col whitespace-nowrap truncate items-start justify-center w-1/2"
                >
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-muted truncate"
                  >
                    {{
                      preview.file.name.substring(
                        0,
                        preview.file.name.length - 4
                      )
                    }}
                    <span class="text-text-subdued -ml-1">{{
                      getFileExtension(preview.file.type)
                    }}</span>
                  </BaseText>
                  <BaseText
                    type="body"
                    size="sm"
                    class="text-text-subdued"
                  >
                    {{ convertSize(preview.file.size) }}
                  </BaseText>
                </div>
              </label>
              <BaseButton
                type="button"
                size="sm"
                outlined
                danger
                class=""
                @click="
                  () => {
                    removeFile(preview.file);
                  }
                "
              >
                Delete
              </BaseButton>
            </div>
          </div>
          <input
            :id="'uploadInput'"
            type="file"
            hidden
            @change="filePreviewing"
          >
          <!-- <div
            class="flex flex-col"
          >
            <div
              class="w-full h-4 flex whitespace-nowrap justify-center items-center gap-4 mt-2"
            >
              <div
                class="w-1/2 h-px bg-border-normal"
              />
              <BaseText
                type="body"
                size="sm"
                class="text-text-subdued"
              >
                OR
              </BaseText>
              <div
                class="w-1/2 h-px bg-border-normal"
              />
            </div>
          </div>
          <div
            class="flex justify-end items-center gap-4 mt-2 -mb-9"
          >
            <img
              src="../../../assets/images/dropbox-logo.png"
              alt=""
              class="h-3.5"
            >
            <img
              src="../../../assets/images/googledrive-logo.png"
              alt=""
              class="h-3.5"
            >
            <img
              src="../../../assets/images/box-logo.png"
              alt=""
              class="h-3.5"
            >
            <img
              src="../../../assets/images/air.inclogo.png"
              alt=""
              class="h-3.5"
            >
          </div>
          <BaseInputV2
            :value="fileUrl"
            label="Submit link"
            class="w-full mt-5"
            placeholder="File URL"
            @input="(value) => fileUrl = value"
          /> -->
        </div>
        <div
          class="flex justify-end items-center gap-4 border-t border-border-normal mt-5 -mx-5 px-5 pt-5 absolute bottom-0 left-5 w-full self-center bg-white h-max py-5 rounded-b-xl"
        >
          <BaseButton
            v-if="!filePreviews.length || !firstName || !email"
            type="button"
            size="sm"
            class="text-text-disabled bg-neutral-50"
          >
            Submit Content
          </BaseButton>
          <BaseButton
            v-else
            type="button"
            size="sm"
            cta
            @click="() => onSubmit()"
          >
            Submit Content
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import animationData from '../../../assets/lotties/loadingV2Green.json'
import FirebaseAPI from '@/api/firebase'
import firebase from '@/api/config/FirebaseConfig'
import { nanoid } from 'nanoid'
const events = ['dragenter', 'dragover', 'dragleave', 'drop']
export default {
  name: 'UploadContentModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    uploadModalInformation: {
      type: Object,
      default: () => {}
    },
    toggleUploadModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      file: null,
      uploading: false,
      filePreview: null,
      filePreviews: [],
      fileUrl: '',
      ContentType: '',
      firstName: '',
      lastName: '',
      email: '',
      submission: {},
      filesCompleted: 0,
      filesUploading: 0,
      usersName: null,
      usersEmail: null,
      defaultOptions: {
        animationData: animationData,
        loop: true,
        autoplay: true
      },
      createdByUser: { name: '', avatar: null },
      brief: {},
      fakeEvent: {},
      validEmail: false
    }
  },
  computed: {
    ...mapGetters('BriefsModule', ['getIsInspoMode']),
    ...mapGetters('MiscModule', ['getTheme']),
    ...mapGetters('AuthModule', ['getUser'])
  },
  async mounted () {
    this.getUserInfo()
    this.getCreatedUser()

    events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  beforeDestroy () {
    events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefaults)
    })
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    getUserInfo () {
      if (localStorage.userInfo) {
        const { name, email } = JSON.parse(localStorage.userInfo)
        this.usersName = name
        this.usersEmail = email
      }
    },
    async getCreatedUser () {
      this.brief = await FirebaseAPI.Briefs.getByID(this.$route.params.id)
      const createdByUser = await FirebaseAPI.Users.getById(
        this.brief.createdBy
      )

      const name =
        `${createdByUser.first_name || ''} ${
          createdByUser.last_name || ''
        }`.trim() ||
        createdByUser.name ||
        createdByUser.email

      this.createdByUser = {
        avatar: createdByUser.avatar,
        name,
        id: createdByUser.id,
        storageUsed: this.createdByUser.storageUsed
      }
    },
    getFileExtension (fileType) {
      const fileTypes = {
        'image/png': '.png',
        'image/jpeg': '.jpg',
        'image/gif': '.gif',
        'video/mp4': '.mp4'
      }
      this.ContentType = fileTypes[fileType] || ''
      return this.ContentType
    },
    onSubmit () {
      this.validateEmail()

      window.analytics.track('Content Submitted', {
        briefTitle: this.brief.title,
        contentSubmited: true,
        contentSubmitedEmail: this.email,
        ContentType: this.ContentType
      })

      // identify user after uploading content
      window.analytics.identify(this.email)

      // Check if the email is valid
      if (this.validEmail) {
        // Do something if the email is valid
        return
      }
      if (!(this.firstName && this.email && (!this.file || !this.fileUrl))) {
        this.$showAlert({
          message: 'Invalid Submission',
          type: 'error'
        })
        return
      }
      this.handleUploadFile(this.fakeEvent)
      this.$emit('close')
    },
    preventDefaults (e) {
      e.preventDefault()
    },
    removeFile (file) {
      // Find the index of the file in the filePreviews array
      const index = this.filePreviews.findIndex(
        (preview) => preview.file === file
      )

      if (index !== -1) {
        // Remove the file from the filePreviews array
        this.filePreviews.splice(index, 1)
      }
    },
    async handleUploadFile (event) {
      if (this.uploading) return
      this.filesCompleted = 0
      this.file = event.target.files[0]
      const filesToUpload = [...event.target.files]

      // Check if user has enough storage
      const kbToSave = Math.ceil(
        filesToUpload.reduce((total, file) => total + file.size, 0) / 1000
      )
      // 5MM kb is 1gb - limit at 5gb for now
      if (
        this.createdByUser.storageUsed &&
        this.createdByUser.storageUsed + kbToSave > 5000000
      ) {
        // Show error
        this.$showAlert({
          message: 'Storage Limit Reached (5GB)',
          type: 'error'
        })

        return
      }

      // 5MM kb is 1gb - limit at 5gb for now
      if (kbToSave > 5000000) {
        // Show error
        this.removeFile()
        this.$showAlert({
          message: 'Storage Limit Reached (5GB)',
          type: 'error'
        })

        return
      }

      // Validate Files
      const invalidFiles = filesToUpload.find(
        (f) => !['image/png', 'image/jpeg', 'video/mp4'].includes(f.type)
      )

      if (invalidFiles) {
        // Show error'
        this.removeFile()
        this.$showAlert({
          message: 'Invalid File Type',
          type: 'error'
        })
        return
      }

      for (const fileToUpload of filesToUpload) {
        // Create File Name (Random Folder Path)
        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}`

        const storageRef = firebase.storage().ref(filePath)

        const firstFile = fileToUpload
        const savingFile = await storageRef.put(firstFile)

        // Upload is complete
        const submission = {
          path: `https://storage.googleapis.com/foreplay-submissions/${filePath}`,
          format: savingFile.metadata.contentType,
          submittedAt: +new Date(),
          name: fileToUpload.name,
          submittedBy: {
            name: this.firstName + (this.lastName ? ` ${this.lastName}` : ''),
            email: this.email
          }
        }

        await FirebaseAPI.Briefs.update(this.brief.id, {
          submissions: [...(this.brief.submissions || []), submission]
        })

        // Update the UI
        this.filesCompleted = this.filesCompleted + 1
        // Add the submission to the brief in state
        this.brief.submissions = this.brief.submissions
          ? this.brief.submissions.concat(submission)
          : [submission]
      }

      const storageUsed = this.createdByUser.storageUsed
        ? this.createdByUser.storageUsed + kbToSave
        : kbToSave

      await FirebaseAPI.Users.update(this.createdByUser.id, { storageUsed })

      // Create a FileReader object to read the file
      const reader = new FileReader()

      // Set up the event handler for when the FileReader finishes reading the file
      reader.onload = (e) => {
        // Get the file's data URL
        const fileDataURL = e.target.result

        // Display the preview of the uploaded file
        this.filePreview = fileDataURL
        this.uploading = false
        this.$showAlert({
          message: 'Files Uploaded',
          type: 'success'
        })
      }

      // Read the file as a data URL
      reader.readAsDataURL(this.file)
    },
    filePreviewing (e) {
      const filesToUpload = [...e.target.files]
      const filePreviews = this.filePreviews.map((preview) => preview.file)

      // Check for duplicate files
      const duplicates = filesToUpload.filter((file) =>
        filePreviews.some(
          (preview) => preview.name === file.name && preview.size === file.size
        )
      )

      if (duplicates.length) {
        // Show error or handle duplicate files
        this.$showAlert({
          message: 'Duplicate files detected',
          type: 'error'
        })

        return
      }
      // Calculate total size of files to be uploaded in kilobytes
      const kbToSave = Math.ceil(
        filesToUpload.reduce((total, file) => total + file.size, 0) / 1000
      )

      // Storage limit validation
      if (
        this.createdByUser.storageUsed &&
        this.createdByUser.storageUsed + kbToSave > 5000000
      ) {
        // Show error
        this.$showAlert({
          message: 'Storage Limit Reached (5GB)',
          type: 'error'
        })

        return
      }

      // File size validation
      if (kbToSave > 5000000) {
        // Show error
        this.removeFile()
        this.$showAlert({
          message: 'Storage Limit Reached (5GB)',
          type: 'error'
        })

        return
      }

      // File type validation
      const invalidFile = filesToUpload.find(
        (file) => !['image/png', 'image/jpeg', 'video/mp4'].includes(file.type)
      )

      if (invalidFile) {
        // Show error
        this.removeFile()
        this.$showAlert({
          message: 'Invalid File Type',
          type: 'error'
        })

        return
      }

      const fileToPreview = filesToUpload[filesToUpload.length - 1]
      const reader = new FileReader()

      reader.onload = (event) => {
        const fileDataURL = event.target.result
        this.filePreviews.unshift({
          file: fileToPreview,
          preview: fileDataURL
        })
      }

      reader.readAsDataURL(fileToPreview)

      // Combine the original files and the file previews
      const allFiles = [...filesToUpload, ...filePreviews]

      // Create a fake event object with the combined files
      this.fakeEvent = {
        target: {
          files: allFiles
        }
      }
      console.log(this.filePreviews)
    },
    arrayContainsValue (arr, value) {
      return arr.includes(value)
    },
    shouldGetUserData () {
      if (!this.usersName || !this.usersEmail) {
        this.showGetUserInfoModal = true
      }
    },
    convertSize (sizeInBytes) {
      const sizeInKilobytes = sizeInBytes / 1024
      const sizeInMegabytes = sizeInKilobytes / 1024
      const sizeInGigabytes = sizeInMegabytes / 1024

      if (sizeInGigabytes >= 1) {
        return sizeInGigabytes.toFixed(2) + 'GB'
      } else if (sizeInMegabytes >= 1) {
        return sizeInMegabytes.toFixed(2) + 'MB'
      } else {
        return sizeInKilobytes.toFixed(2) + 'KB'
      }
    },
    onDrop (e) {
      e.preventDefault()
      const fakeEvent = { target: { files: [...e.dataTransfer.files] } }
      this.filePreviewing(fakeEvent)
    },
    validateEmail () {
      this.validEmail = !(
        this.email !== '' &&
        this.email.includes('@') &&
        this.email.includes('.')
      )
    }
  }
}
</script>
