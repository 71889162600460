<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M10.0001 16.0417V10.8333M10.0001 10.8333L12.0834 12.9167M10.0001 10.8333L7.91675 12.9167M6.14591 16.0417H3.95841C3.03794 16.0417 2.29175 15.2955 2.29175 14.375V4.79167C2.29175 3.87119 3.03794 3.125 3.95841 3.125H7.44144C7.9987 3.125 8.51908 3.4035 8.82819 3.86717L9.75269 5.25392C9.90725 5.48575 10.1674 5.625 10.4461 5.625H16.0417C16.9622 5.625 17.7084 6.37119 17.7084 7.29167V14.375C17.7084 15.2955 16.9622 16.0417 16.0417 16.0417H13.8542" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'ChangeFolderIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>