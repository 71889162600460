<template>
  <!-- Boards Public View -->
  <div class="w-full h-full">
    <!-- Brand -->
    <img
      class="mx-auto my-4"
      src="https://assets.website-files.com/60d8c5270097eb0a6ccd32b7/60e09cff43b07dff3b341792_Adison.svg"
    >

    <MainLayout style="height: calc(100vh - 70px); width: calc(100vw - 30px)">
      <!-- Toolbar -->
      <template #toolbar>
        <div
          v-if="!loadingAdvertisements"
          class="border-b flex items-center justify-between px-8 py-5"
        >
          <div>
            <div class="text-3xl font-semibold">
              {{ board.name }}
            </div>

            <div class="text-sm text-gray-500 mt-1">
              This Ad was shared with you by {{ $route.query.user }} using

              <a
                href="http://foreplay.co/?utm_source=APP&utm_medium=SHARE-PAGE&utm_campaign=HEADER"
                target="_blank"
                class="text-blue-700"
              >
                foreplay.co
              </a>
            </div>
          </div>

          <div class="flex">
            <BaseButton
              primary
              class="ml-3"
              @click="goToSignup"
            >
              Sign up
            </BaseButton>
          </div>
        </div>
      </template>

      <template #content>
        <!-- Loading State -->
        <BaseLoadingSpinner
          v-if="loadingAdvertisements || loadingSubscription"
          class="mt-24"
        />

        <!-- Empty State -->
        <div
          v-else-if="!advertisements.length"
          class="flex flex-col items-center mt-20"
        >
          <img
            src="../../assets/images/empty-single-library.gif"
            class="border border-gray-400 rounded mx-auto object-cover"
            style="width: 400px; height: 300px"
          >

          <div class="text-lg font-bold my-4">
            No Ad to Show...
          </div>
        </div>

        <!-- Ads List -->
        <AdvertisementList
          v-else
          hide-boards
          :advertisements="advertisements"
          :shared-board="true"
        />
      </template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from '../../layouts/MainLayout'
import { mapGetters, mapMutations } from 'vuex'
import FirebaseAPI from '@/api/firebase'
import AdvertisementList from '../../components/advertisements/AdvertisementList'

export default {
  name: 'BoardsPublicView',
  components: {
    AdvertisementList,
    MainLayout
  },
  data () {
    return {
      board: {},
      advertisements: [],
      loadingAdvertisements: true,
      userSubscribed: true
    }
  },
  computed: {
    ...mapGetters('BoardsModule', ['getBoards'])
  },
  async mounted () {
    await this.fetchAdvertisements()
  },
  methods: {
    ...mapMutations('BoardsModule', ['SET_BOARDS']),
    // Go to Signup
    goToSignup () {
      this.SET_BOARDS([])
      this.$router.push({ name: 'LoginView' })
    },
    // Fetch Advertisements
    async fetchAdvertisements () {
      try {
        let ad = await FirebaseAPI.Advertisements.get(this.$route.params.id)

        // Make share backwards compatible
        if (!ad.type) {
          ad = await FirebaseAPI.Advertisements.getByID(this.$route.params.id)
        }

        this.advertisements = [ad]
      } catch (e) {
        console.log(e)
        this.$showAlert({
          message: e,
          type: 'error'
        })
      } finally {
        this.loadingAdvertisements = false
      }
    }
  }
}
</script>
z
<style scoped lang="sass">
</style>
