<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M11.5 16.75H8.5C7.67157 16.75 7 16.0784 7 15.25C7 14.4216 7.67157 13.75 8.5 13.75H11.5C12.3284 13.75 13 14.4216 13 15.25C13 16.0784 12.3284 16.75 11.5 16.75Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 9.25L9.25 13.375M10.75 13.375L16 9.25" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 3.25C6.68629 3.25 4 5.93629 4 9.25C4 9.25 7.65228 8.875 10 8.875C12.3477 8.875 16 9.25 16 9.25C16 5.93629 13.3137 3.25 10 3.25Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'AdChangelogIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>