<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M7.75 8.50645H6.82869C5.66608 8.59398 4.75 9.565 4.75 10.75C4.75 11.162 4.8607 11.5448 5.05398 11.875M11.5 8.5H13C14.2427 8.5 15.25 9.50732 15.25 10.75C15.25 11.162 15.1393 11.5448 14.946 11.875M10 12.25H5.125C4.08947 12.25 3.25 13.0895 3.25 14.125C3.25 15.1605 4.08947 16 5.125 16H14.875C15.9105 16 16.75 15.1605 16.75 14.125C16.75 13.0895 15.9105 12.25 14.875 12.25H13.75M13.092 8.125C13.5037 7.6114 13.75 6.95946 13.75 6.25C13.75 4.59314 12.4068 3.25 10.75 3.25V4.75H8.5C7.25736 4.75 6.25 5.75736 6.25 7C6.25 7.40982 6.35957 7.79405 6.55101 8.125" 
    :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'GovernmentNicheIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      stroke: {
        type: String,
        default: '#5E6678'
      }
    }
  }
</script>

<style scoped></style>