<template>
  <div :class="[fontTypes[type][size], `font-${weight ? weight : fontTypes[type].weight}`, lineThrough ? 'line-through' : '']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseText',
  props: {
    type: {
      type: String,
      default: 'body'
    },
    size: {
      type: String,
      default: 'md'
    },
    lineThrough: {
      type: Boolean,
      default: false
    },
    weight: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      fontTypes: {
        display: {
          '2xs': 'text-display-2xs',
          xs: 'text-display-xs',
          sm: 'text-display-sm',
          md: 'text-display-md',
          lg: 'text-display-lg',

          // Default Weight is medium
          weight: 'medium'
        },

        heading: {
          overline: 'text-heading-overline',
          sm: 'text-heading-sm',
          md: 'text-heading-md',
          lg: 'text-heading-lg',

          // Default Weight is medium
          weight: 'medium'
        },

        label: {
          xs: 'text-label-xs',
          sm: 'text-label-sm',
          md: 'text-label-md',
          lg: 'text-label-lg',

          // Default Weight is medium
          weight: 'medium'
        },

        body: {
          xs: 'text-body-xs',
          sm: 'text-body-sm',
          md: 'text-body-md',
          lg: 'text-body-lg',

          // Default Weight is normal
          weight: 'normal'
        }
      }
    }
  }
}
</script>
