<template>
  <div />
</template>

<script>
import firebase from '../../api/config/FirebaseConfig'
export default {
  name: 'ExpertRedirectView',
  async mounted () {
    const db = firebase.firestore()

    // Get the Expert by their UTM
    console.log('utm', this.$route.params)
    const expertSnapshot = await db.collection('auth-users').where('expertProfile.utm', '==', this.$route.params.utm).get()

    if (expertSnapshot.empty) {
      this.$showAlert({
        message: 'Invalid Expert',
        type: 'error'
      })

      this.$router.push({ name: 'LoginView' })
    }

    // Redirect to the Experts shared board
    this.$router.push({ name: 'DiscoveryExpertPublicView', params: { id: expertSnapshot.docs[0].id } })
  }
}
</script>

<style scoped lang="sass"></style>
