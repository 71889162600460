<template>
  <div
    class="relative grid grid-cols-3 items-start gap-5 overflow-hidden"
    style="height: calc(100vh - 175px)"
  >
    <!-- Loading state -->
    <div
      v-if="loadingLandingPages"
      class="absolute left-1/2 transform -translate-x-1/2"
    >
      <BaseLoadingLogo />
    </div>
    <!-- Empty state -->
    <div
      v-if="!loadingLandingPages && landingPages.length === 0"
      class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-2 items-center"
      style="top: 40%; width: 350px"
    >
      <img
        src="../../../assets/images/nothing-here-yet.png"
        class="w-20 h-20"
      >
      <BaseText
        type="label"
        size="sm"
        class="text-text-muted"
      >
        Nothing here yet...
      </BaseText>
      <BaseText
        type="body"
        size="sm"
        class="text-text-normal text-center"
      >
        Once your brand's active landing pages are scraped, their screenshots will appear here.
      </BaseText>
    </div>
    <!-- Landing pages -->
    <div
      v-if="!loadingLandingPages && landingPages.length > 0"
      class="border border-border-normal col-span-1 rounded-2xl"
      style="height: calc(-175px + 100vh)"
    >
      <!-- Section Title -->
      <div class="flex justify-start gap-2 p-4">
        <img
          class="h-5"
          src="../../../assets/icons/link-document.svg"
        >
        <BaseText
          type="label"
          size="sm"
          class="text-text-muted"
        >
          Landing Pages
        </BaseText>
      </div>

      <!-- Landing Page List -->
      <div class="flex flex-col gap-2 px-2 pb-20 overflow-y-scroll h-full scrollbar-hide">
        <div
          v-for="(page, index) in landingPages"
          :key="index"
          class="flex gap-2 w-full p-2 cursor-pointer rounded-md transition-all duration-200"
          :class="{
            'bg-primary-red-10 border border-primary-red-100 text-primary-red-300': selectedLandingPage.url === page.url,
            'hover:bg-background-normal border border-transparent text-text-muted': selectedLandingPage.url !== page.url,
          }"
          @click="() => { if (selectedLandingPage !== page) { selectedLandingPage = page; loadingImage = true }}"
        >
          <!-- Icon -->
          <LinkRoundedIcon
            class="transition-colors flex-shrink-0"
            :class="selectedLandingPage.url === page.url ? 'text-primary-red-300' : 'text-icon-normal'"
          />
          <!-- Landing Page Text -->
          <BaseText
            type="body"
            size="sm"
            class="whitespace-nowrap overflow-ellipsis overflow-hidden"
          >
            {{ page.url }}
          </BaseText>
          <!-- Numbers -->
          <div class="flex justify-end gap-2 w-20 ml-auto mr-0.5">
            <BaseText
              type="body"
              size="sm"
            >
              {{ page.adIds.length }}
            </BaseText>
          </div>
        </div>
        <infinite-loading
          v-if="landingPages.length"
          :identifier="infiniteId"
          @infinite="getMoreLandingPages"
        >
          <div slot="spinner">
            <BaseLoadingLogo :margin="2" />
          </div>
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
      </div>
    </div>

    <!-- Screenshot Section -->
    <div
      v-if="!loadingLandingPages && landingPages.length > 0"
      class="col-span-2 relative rounded-2xl w-full p-2 bg-background-normal"
      style="height: calc(-175px + 100vh)"
    >
      <!-- Top Bar -->
      <div class="flex pb-2 bg-background-normal backdrop-blur-sm">
        <!-- Url Display -->
        <div
          class="flex justify-start rounded-lg gap-1.5 p-2.5 bg-white box-shadow mr-2"
          style="max-width: 50%"
        >
          <LinkRoundedIcon class="text-icon-normal flex-shrink-0" />
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted truncate"
          >
            {{ selectedLandingPage.url }}
          </BaseText>
        </div>
        <!-- <div class="ml-3 flex bg-white p-2.5 rounded-lg box-shadow"> -->
        <transition>
          <button
            v-if="!urlCopied || copiedUrl !== selectedLandingPage.url"
            class="flex flex-row gap-1 items-center bg-neutral-50 rounded-lg p-2"
            @click="copyUrl(selectedLandingPage.url)"
          >
            <LayersCopyIcon class="text-icon-normal" />
            <BaseText
              type="body"
              size="sm"
              class="text-text-muted"
            >
              Copy URL
            </BaseText>
          </button>
          <div
            v-else-if="urlCopied && copiedUrl === selectedLandingPage.url"
            class="flex flex-row gap-1 items-center bg-icon-muted pl-2 pr-4 rounded-lg cursor-default"
          >
            <div class="flex flex-row items-center text-xs text-white">
              <img
                src="../../../assets/icons/new-check.svg"
                class="w-5 h-5"
              >
              <div>
                Copied!
              </div>
            </div>
          </div>
        </transition>
        <!-- </div> -->
        <!-- Device Toggle -->
        <div class="ml-auto flex p-0.5 rounded-lg bg-neutral-50">
          <button
            class="switcher group flex items-center gap-1 py-2 pl-2 pr-3 rounded-md"
            :class="imageDisplay === 'Mobile' ? 'selected bg-white' : 'bg-transparent'"
            @click="imageDisplay = 'Mobile'"
          >
            <MobileDeviceIcon
              class="text-icon-normal transition-colors duration-200"
              :class="{'text-icon-muted': imageDisplay === 'Mobile'}"
            />
            <BaseText
              type="label"
              size="sm"
              class="text-text-normal transition-colors duration-200"
              :class="{'text-text-muted': imageDisplay === 'Mobile'}"
            >
              Mobile
            </BaseText>
          </button>
          <button
            class="switcher group flex items-center gap-1 py-2 pl-2 pr-3 rounded-md"
            :class="imageDisplay === 'Desktop' ? 'selected bg-white' : 'bg-transparent'"
            @click="imageDisplay = 'Desktop'"
          >
            <DesktopDeviceIcon
              class="text-icon-normal transition-colors duration-200"
              :class="{'text-icon-muted': imageDisplay === 'Desktop'}"
            />
            <BaseText
              type="label"
              size="sm"
              class="text-text-normal transition-colors duration-200"
              :class="{'text-text-muted': imageDisplay === 'Desktop'}"
            >
              Desktop
            </BaseText>
          </button>
        </div>
      </div>
      <!-- Image Section -->
      <div
        class="w-full relative flex items-center justify-center px-20 overflow-y-scroll pb-20"
        style="height: calc(-175px + 100vh)"
      >
        <transition name="spinner">
          <div
            v-if="loadingImage"
            class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 shadow-lg p-1.5 rounded-lg"
            style="background-color: rgba(0, 0, 0, 0.35); backdrop-filter: blur(36px);"
          >
            <BaseLoadingSpinner />
          </div>
        </transition>
        <div class="absolute top-0 pb-20">
          <img
            v-if="mostRecentLandingPageImage && imageDisplay === 'Desktop'"
            class="w-full max-w-md mt-5 rounded-2xl box-shadow"
            :src="mostRecentLandingPageImage"
            alt="Landing Page"
            @load="loadingImage = false"
          >
          <img
            v-else
            class="w-full max-w-sm mt-5 rounded-2xl box-shadow"
            :src="mostRecentLandingPageImage"
            alt="Landing Page"
            @load="loadingImage = false"
          >
        </div>
      </div>

      <!-- Bottom Bar -->
      <a
        :href="mostRecentLandingPageImage"
        target="_blank"
        download
      >
        <button class="group absolute bottom-2.5 left-2.5 rounded-md p-1.5 button-shadow bg-white">
          <DownloadIcon class="transition-colors text-icon-normal group-hover:text-icon-muted" />
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import ForeplayAPI from '@/api/foreplayServer'
import firebase from 'firebase/app'

// Icons
import ScrapingLoadingIcon from '../../globals/Icons/SpyderV2Icons/ScrapingLoadingIcon.vue'
import LinkRoundedIcon from '../../globals/Icons/LinkRoundedIcon.vue'
import DownloadIcon from '../../globals/Icons/DownloadIcon.vue'
import MobileDeviceIcon from '../../globals/Icons/MobileDeviceIcon.vue'
import DesktopDeviceIcon from '../../globals/Icons/DesktopDeviceIcon.vue'

export default {
  name: 'LandingPagesBrandView',
  components: {
    InfiniteLoading,
    ScrapingLoadingIcon,
    LinkRoundedIcon,
    DownloadIcon,
    MobileDeviceIcon,
    DesktopDeviceIcon
  },
  data () {
    return {
      landingPages: [],
      selectedLandingPage: {},
      brand: {},
      lastDocId: null,
      imageDisplay: 'Desktop',
      infiniteId: +new Date(),
      loadingLandingPages: true,
      loadingImage: false,
      urlCopied: false,
      copiedUrl: ''
    }
  },
  computed: {
    mostRecentLandingPageImage () {
      // Check which view to get
      if (this.imageDisplay === 'Mobile') {
        return this.selectedLandingPage.versions
          ? this.selectedLandingPage.versions[0]?.mobileScreenshot
          : null
      }

      return this.selectedLandingPage.versions
        ? this.selectedLandingPage.versions[0]?.desktopScreenshot
        : null
    }
  },
  async mounted () {
    this.loadingLandingPages = true

    // Get the landing pages from the api
    const landingPageData = await ForeplayAPI.Screenshots.getBrandScreenshots(
      this.$route.params.id
    )

    if (landingPageData.length !== 0) {
      this.lastDocId = landingPageData[landingPageData.length - 1].id
    }

    // Set the selected landing page
    this.selectedLandingPage = landingPageData[0]

    // Get the brand data
    await this.getBrand()

    // Make sure we only have unique paths
    const uniquePaths = new Set()
    this.landingPages = landingPageData.filter((page) => {
      if (uniquePaths.has(page.url)) {
        return false
      }

      uniquePaths.add(page.url)
      return true
    })

    this.infiniteId += 1
    this.loadingLandingPages = false
  },
  methods: {
    copyUrl (url) {
      navigator.clipboard.writeText(url)
      this.urlCopied = true
      this.copiedUrl = url
    },
    async getBrand () {
      const db = firebase.firestore()
      const brandInstance = await db
        .collection('brands')
        .doc(this.$route.params.id)
        .get()

      this.brand = brandInstance.data()
    },
    async getMoreLandingPages ($state) {
      try {
        if (!this.lastDocId) {
          if (Object.keys($state).length) {
            $state.complete()
          }
          return
        }

        // Get the next page of landing pages
        let landingPageData =
          await ForeplayAPI.Screenshots.getBrandScreenshots(
            this.$route.params.id,
            this.lastDocId
          )

        // Set the next page id
        this.lastDocId =
          landingPageData.length > 1
            ? landingPageData[landingPageData.length - 1].id
            : null

        // Make sure we only have unique paths
        const uniquePaths = new Set()
        landingPageData = landingPageData.filter((page) => {
          if (uniquePaths.has(page.url)) {
            return false
          }

          uniquePaths.add(page.url)
          return true
        })

        // Add the new landing pages to the existing list
        this.landingPages = [...this.landingPages, ...landingPageData]

        if (!this.lastDocId) {
          if (Object.keys($state).length) {
            $state.complete()
          }
          return
        }

        $state.loaded()
      } catch (error) {
        console.log()
      }
    }
  }
}
</script>

<style scoped>
.box-shadow {
  box-shadow: 0px 0.1px 0.25px 0px rgba(4, 26, 75, 0.02),
    0px 0.25px 0.5px 0px rgba(4, 26, 75, 0.06),
    0px 1px 2px 0px rgba(4, 26, 75, 0.12);

  box-shadow: 0px 0.1px 0.25px 0px color(display-p3 0.0368 0.0984 0.2832 / 0.02),
    0px 0.25px 0.5px 0px color(display-p3 0.0368 0.0984 0.2832 / 0.06),
    0px 1px 2px 0px color(display-p3 0.0368 0.0984 0.2832 / 0.12);
}
.switcher {
  box-shadow: none;
  transition: box-shadow 200ms ease-in-out, background-color 200ms ease-in-out;
}
.switcher.selected {
  box-shadow: 0px 0.1px 0.25px 0px rgba(4, 26, 75, 0.02), 0px 0.25px 0.5px 0px rgba(4, 26, 75, 0.06), 0px 1px 2px 0px rgba(4, 26, 75, 0.12);
}
.button-shadow {
  transition: box-shadow 300ms ease-in-out;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.button-shadow:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.16);
}
.spinner-enter-active {
  transition: opacity 150ms ease-in-out;
  transition-delay: 200ms;
}
.spinner-leave-active {
  transition: opacity 150ms ease-in-out;
}
.spinner-enter-from, .spinner-enter, .spinner-leave-to {
  opacity: 0;
}
.spinner-enter-to, .spinner-leave-from {
  opacity: 1;
}
.v-enter-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>
