<template>
  <svg
    :width="width"
    :height="width * 1.15"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.530273 1.72203C0.530273 0.830541 1.26542 0.107849 2.17228 0.107849H6.41254V7.25039H0.530273V1.72203Z"
      :class="`${transitionClass}-1`"
    />
    <path
      d="M6.41254 7.90015H0.530273V15.0427H6.41254V7.90015Z"
      :class="`${transitionClass}-2`"
    />
    <path
      d="M0.530273 15.692H6.41254V22.8346H1.35128C0.897852 22.8346 0.530273 22.4732 0.530273 22.0275V15.692Z"
      :class="`${transitionClass}-3`"
    />
    <path
      d="M13.0571 0.107849H7.1748V7.25039H13.0571V0.107849Z"
      :class="`${transitionClass}-2`"
    />
    <path
      d="M13.0571 7.90015H7.1748V15.0427H13.0571V7.90015Z"
      :class="`${transitionClass}-2`"
    />
    <path
      d="M13.8193 0.107849H19.7016V7.25039H13.8193V0.107849Z"
      :class="`${transitionClass}-3`"
    />
  </svg>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Logo',
  props: {
    width: {
      type: Number,
      default: () => 20
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme', 'getLastTheme']),
    transitionClass (position) {
      console.log('transioning')
      if (this.loading) return 'fill-current '

      return `fill-current text-${this.getTheme}-logo ${this.getLastTheme}-to-${this.getTheme}`
    },
    fill () {
      if (this.loading) {
        return 'currentColor'
      }

      if (this.getTheme === 'purple') {
        return '#834BB5'
      }

      return '#3A6FFB'
    }
  }
}
</script>
<style scoped lang="css">
/* Blue to Purple Animations */
.blue-to-purple-1 {animation: b-to-p 1s ease-in; animation-iteration-count: 1; }
.blue-to-purple-2 {animation: b-to-p 1.5s ease-in; animation-iteration-count: 1; }
.blue-to-purple-3 {animation: b-to-p 2s ease-in; animation-iteration-count: 1; }

@keyframes b-to-p {
  0% { color: #3A6FFB; }
  100% { color: #834BB5; }
}

/* Purple to Blue Animations */
.purple-to-blue-1 {animation: p-to-b 1s ease-in; animation-iteration-count: 1;}
.purple-to-blue-2 {animation: p-to-b 1.5s ease-in; animation-iteration-count: 1;}
.purple-to-blue-3 {animation: p-to-b 2s ease-in; animation-iteration-count: 1;}

@keyframes p-to-b {
  0% { color: #834BB5; }
  100% { color: #3A6FFB; }
}

/* Purple to Green Animations */
.purple-to-green-1 {animation: p-to-g 1s ease-in; animation-iteration-count: 1;}
.purple-to-green-2 {animation: p-to-g 1.5s ease-in; animation-iteration-count: 1;}
.purple-to-green-3 {animation: p-to-g 2s ease-in; animation-iteration-count: 1;}

@keyframes p-to-g {
  0% { color: #834BB5; }
  100% { color: #14C78C; }
}

/* Blue to Green Animations */
.blue-to-green-1 {animation: b-to-g 1s ease-in; animation-iteration-count: 1;}
.blue-to-green-2 {animation: b-to-g 1.5s ease-in; animation-iteration-count: 1;}
.blue-to-green-3 {animation: b-to-g 2s ease-in; animation-iteration-count: 1;}

@keyframes b-to-g {
  0% { color: #3A6FFB; }
  100% { color: #14C78C; }
}

/* Green to Purple Animations */
.green-to-purple-1 {animation: g-to-p 1s ease-in; animation-iteration-count: 1;}
.green-to-purple-2 {animation: g-to-p 1.5s ease-in; animation-iteration-count: 1;}
.green-to-purple-3 {animation: g-to-p 2s ease-in; animation-iteration-count: 1;}

@keyframes g-to-p {
  0% { color: #14C78C; }
  100% { color: #834BB5; }
}

/* Green to Blue Animations */
.green-to-blue-1 {animation: g-to-b 1s ease-in; animation-iteration-count: 1;}
.green-to-blue-2 {animation: g-to-b 1.5s ease-in; animation-iteration-count: 1;}
.green-to-blue-3 {animation: g-to-b 2s ease-in; animation-iteration-count: 1;}

@keyframes g-to-b {
  0% { color: #14C78C; }
  100% { color: #3A6FFB; }
}

</style>
