import { render, staticRenderFns } from "./TeamCreatedModal.vue?vue&type=template&id=3d6e89b8&scoped=true"
import script from "./TeamCreatedModal.vue?vue&type=script&lang=js"
export * from "./TeamCreatedModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d6e89b8",
  null
  
)

export default component.exports