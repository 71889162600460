<template>
  <!-- Account View -->
  <MainLayout>
    <!-- Toolbar -->
    <template #content>
      <div style="max-width: 1200px">
        <transition
          name="fade"
          mode="out-in"
        >
          <div>
            <div class="text-gray-600 font-semibold text-lg mb-4">
              All Perks
            </div>
            <!-- Perks -->
            <div class="grid grid-cols-3 gap-4">
              <div
                v-for="(perk, index) in perks"
                :key="index"
                class="flex flex-col px-4 pt-2 pb-2 bg-white rounded-lg border border-gray-300"
              >
                <!-- Title -->
                <div class="flex justify-between items-center text-xl font-semibold">
                  {{ perk.title }}
                  <img
                    :src="perk.logo"
                    class="h-12"
                  >
                </div>

                <!-- Deal -->
                <div class="p-8 my-4 border-2 border-dashed border-gray-400 text-gray-600 rounded-md bg-gray-50 text-lg">
                  {{ perk.perk }}
                </div>

                <!-- Description -->
                <div class="text-gray-500 text-md mb-4">
                  {{ perk.description }}
                </div>

                <!-- Get Perk -->
                <div class="flex items-center justify-between mt-auto mb-2">
                  <a
                    :href="perk.link"
                    target="_blank"
                  >
                    <BaseButton primary>
                      <div class="flex items-center">
                        Get Perk
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="ml-2 w-5 h-5 mb-0.5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                          />
                        </svg>
                      </div>
                    </BaseButton>
                  </a>

                  <div
                    v-if="perk.promoCode"
                    class="flex-col"
                  >
                    <div class="text-gray-600 text-right">
                      Promo Code
                    </div>
                    <div class="flex items-center text-blue-500 font-medium">
                      <CopyIcon
                        v-clipboard:copy="perk.promoCode"
                        v-clipboard:success="handleClipboardCopy"
                        class="ml-auto"
                      />
                      {{ perk.promoCode }}
                    </div>
                  </div>
                  <a
                    v-else
                    :href="perk.link"
                    target="_blank"
                    class="text-gray-600 hover:text-gray-400 transition cursor-pointer mb-1"
                  >
                    {{ perk.link.includes('mailto') ? 'Email to Claim' : 'Visit link and Sign Up'}}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from '../../layouts/MainLayout'
import firebase from '@/api/config/FirebaseConfig'
import CopyIcon from '../../components/globals/Icons/CopyIcon.vue'

export default {
  name: 'AccountView',
  components: {
    MainLayout,
    CopyIcon
  },
  data () {
    return {
      perks: []
    }
  },
  computed: {
  },
  async mounted () {
    // Get Perks From Firebase
    const db = firebase.firestore()

    const perks = await db.collection('perks').get()

    this.perks = perks.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data() || []
      }
    })
  },
  methods: {
    handleClipboardCopy () {
      this.$showAlert({
        message: 'Copied the code to your clipboard!',
        type: 'success'
      })
    }
  }
}
</script>
