// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get fb_ads_page_track
const getByPageId = async (pageId) => {
  const snapshot = await db
    .collection('fb_ads_page_track')
    .where('pageId', '==', pageId)
    .limit(1)
    .get()

  const spyderInstance = snapshot.docs[0]

  return {
    ...spyderInstance.data(),
    id: spyderInstance.id
  }
}

// Update fb_ads_page_track
const update = (spyderId, payload) => {
  return db
    .collection('fb_ads_page_track')
    .doc(spyderId)
    .update(payload)
}

// Adds new doc to fb_ads_page_track
const create = (payload) => {
  return db
    .collection('fb_ads_page_track')
    .add(payload)
}

export default {
  create,
  getByPageId,
  update
}
