<template>
  <button
    type="button"
    class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none"
    :class="value ? `bg-${color}` : 'bg-neutral-50'"
    style="padding-top: 1.5px; padding-left: 2px;"
    role="switch"
    aria-checked="false"
    @click="updateValue"
  >
    <span
      :class="{
        'translate-x-5': value,
        'translate-x-0': !value
      }"
      class="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
    />
  </button>
</template>

<script>
export default {
  name: 'BaseToggle',
  props: {
    // Model
    value: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'green-500'
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    // Update Model Value
    updateValue () {
      this.$emit('toggle')
    }
  }
}
</script>

<style scoped lang="css">
</style>
