import { render, staticRenderFns } from "./BaseTabs.vue?vue&type=template&id=14a62649&scoped=true"
import script from "./BaseTabs.vue?vue&type=script&lang=js"
export * from "./BaseTabs.vue?vue&type=script&lang=js"
import style0 from "./BaseTabs.vue?vue&type=style&index=0&id=14a62649&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a62649",
  null
  
)

export default component.exports