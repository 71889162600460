<template>
  <BaseCard
    class="h-max mt-5 w-4/5"
    :no-padding="true"
  >
    <div class="flex flex-row items-center w-full px-2 py-2 xl:gap-20 lg:gap-20 md:gap:10 sm:gap:4">
      <div class="mb-0 md:pl-5">
        <img
          :src="require('@/assets/images/referral-icon.svg')"
          alt="referral icon"
        >
        <BaseText
          type="label"
          size="md"
          class="text-text-loud-900 position-relative mt-3"
        >
          Refer A Friend & Earn Cash
          <span class="animation-container">
            <span :class="{ 'rocket-ship': animateRocket }">🚀<span
              v-if="animateFire"
              :class="{ 'fire': animateFire }"
            >🔥</span></span></span>
        </BaseText>
        <BaseText
          type="body"
          size="md"
          class="text-text-muted overflow-visible text-wrap mb-3"
        >
          Get 50% commissions on the first 3 months for every customer you refer - it's that simple!
        </BaseText>
        <BaseText
          type="body"
          size="md"
          class="text-text-muted overflow-visible text-wrap"
        >
          Your link has received {{ visitors }} visitor{{ visitors !== 1 ? 's' : '' }}.
        </BaseText>
      </div>
      <div
        class="flex flex-col items-start gap-3 w-full max-w-screen-md h-max p-4 rounded-xl bg-white border border-gray-100"
      >
        <div class="flex flex-row justify-between w-full">
          <div
            class="border-none py-2 px-3 text-white bg-neutral-700"
            :style="{
              borderRadius: '10px',
            }"
          >
            <BaseText
              type="label"
              size="md"
            >
              referred customers: {{ conversions }}
            </BaseText>
          </div>
          <div
            class="flex flex-row gap-1 text-text-muted text-sm cursor-pointer"
            @click="$router.push({ name: 'AccountView' })"
          >
            <div>
              Payout Setup:
            </div>
            <div>
              <img
                src="../../assets/icons/paypal-icon.svg"
                class="cursor-pointer"
                alt="learn more"
              >
            </div>
          </div>
        </div>
        <BaseText
          type="body"
          size="md"
          class="text-text-muted"
        >
          Share your referral link with your social network.
        </BaseText>
        <div class="flex flex-row w-full rounded-r-none items-center">
          <baseInputV2
            v-model="referralLink"
            class="flex-grow "
            icon
            disabled
          >
            <template v-slot:icon>
              <img
                src="../../assets/icons/link2.svg"
                alt="link"
              >
            </template>
          </baseInputV2>
          <baseButton
            type="primary"
            outlined
            class="ml-2"
            @click="copyReferralLink"
          >
            <template v-slot:default>
              <div class="flex items-center gap-1">
                <img
                  src="../../assets/icons/link2.svg"
                  alt="link"
                >
                <span> Copy Link</span>
              </div>
            </template>
          </baseButton>
        </div>
        <div class="flex flex-row space-x-3 text-sm items-center">
          <a
            href="https://www.facebook.com"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/facebook-icon2.svg')"
              alt="facebook"
            >
          </a>
          <a
            href="https://www.facebook.com"
            target="_blank"
          >
            <span>
              Facebook
            </span>
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/alternate-instagram-icon.svg')"
              alt="instagram"
            >
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
          >
            <span>
              Instagram
            </span>
          </a>
          <a
            href="https://www.linkedin.com"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/linkedin-icon.svg')"
              alt="linkedin"
            >
          </a>
          <a
            href="https://www.linkedin.com"
            target="_blank"
          >
            <span>
              LinkedIn
            </span>
          </a>
          <a
            :href="`https://twitter.com/intent/tweet?text=${referralLink}`"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/x-icon.svg')"
              alt="x"
            >
          </a>
          <a
            :href="`https://twitter.com/intent/tweet?text=${referralLink}`"
            target="_blank"
          >
            <span>
              Twitter/X
            </span>
          </a>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<style scoped>
.reset-styles {
  all: unset;
  border-right: none;
}
.animation-container {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.rocket-ship {
  display: inline-block;
  animation: rocket-ship-animation 3s forwards;
}
.fire {
  display: inline-block;
  animation: fire-animation 0.5s infinite;
  /* animation-timing-function: ease-in; */
}

@keyframes rocket-ship-animation {
  0% { transform: translate(0, 0); opacity: 1;}
  100% { transform: translate(200px, -200px); opacity: 0;}
}

@keyframes fire-animation {
  0% { transform: translate(-25px, 8px) rotate(-135deg); opacity: 1 }
  50% { transform: translate(-25px, 8px) rotate(-135deg) scaleX(-1); }
  100% { transform: translate(-25px, 8px) rotate(-135deg); opacity: 0}
}
</style>

<script>

import { mapGetters } from 'vuex'
import AxiosConfig from '../../api/foreplayServer/serverAxiosConfig'
import firebase from '../../api/config/FirebaseConfig'

export default {
  name: 'ReferralCard',
  data () {
    return {
      referralLink: '',
      visitors: '',
      conversions: '',
      animateRocket: false,
      animateFire: false
    }
  },
  computed: {
    encodedReferralLink () {
      return encodeURIComponent(this.referralLink)
    },
    ...mapGetters('AuthModule', ['getUser'])
  },
  async mounted () {
    await this.fetchOrCreateReferral()
  },
  methods: {
    async fetchOrCreateReferral () {
      try {
        const { Aa: token } = firebase.auth().currentUser
        const response = await AxiosConfig.post('/campaigns/referral', null, {
          headers: {
            Authorization: `Bearer ${token}`

          }
        })
        console.log('user link info', response.data)
        this.referralLink = response.data.url
        this.conversions = response.data.conversions
        this.visitors = response.data.visitors
      } catch (error) {
        console.log('referral error', error)
      }
    },
    async copyReferralLink () {
      try {
        await navigator.clipboard.writeText(this.referralLink)
        this.animateRocket = true
        this.animateFire = true
      } catch (err) {
        console.error('Failed to copy referral link: ', err)
      }
    }
  }
}

</script>
