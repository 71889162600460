<template>
  <div class="w-full border border-border-normal" style="border-radius: 10px">
    <button class="flex items-center gap-3 w-full px-5 py-4" @click="isOpen = !isOpen">
      <AnalyticsIcon class="text-icon-normal" />
      <BaseText type="heading" size="sm" class="text-text-loud">
        Analytics
      </BaseText>
      <div class="ml-auto transition-transform duration-200" 
      :style="{ transform: isOpen ? 'scaleY(-1)' : 'scaleY(1)' }">
        <ChevronIcon class="text-icon-normal" />
      </div>
    </button>
    <div class="flex overflow-hidden" style="transition: height 200ms ease-in-out" :style="{height: isOpen ? '420px' : '0px'}">
      <!-- ACTIVE ADS - Media Mix -->
      <div v-if="!loadingBrand && !brand.analytics" 
      class="flex flex-col gap-1.5 justify-center items-center border-t border-r border-border-normal pb-6 flex-shrink-0" style="width: 332px">
        <ScrapingLoadingIcon :width="80" :height="80" />
        <BaseText type="label" size="sm" class=" text-text-muted">
          Gathering Brand Analytics
        </BaseText>
        <BaseText type="body" size="sm" class="text-text-normal">
          This may take a few minutes
        </BaseText>
      </div>
      <div v-else class="flex flex-col border-t border-r border-border-normal pb-2 flex-shrink-0" style="width: 332px">
        <div class="flex items-center gap-2 p-4">
          <ActiveAdsMediaMixIcon class="text-icon-normal" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Active Ads - Media Mix
          </BaseText>
        </div>
        <!-- Radial donut chart breakdown -->
        <div class="donut-chart relative w-full" style="height: 251px">
          <div id="active-ads-chart" class="z-20" />
          <div class="absolute left-1/2 transform -translate-x-1/2 flex flex-col text-center z-50 pointer-events-none"
          style="bottom: 83px">
            <BaseText type="display" size="2xs" class="text-text-loud -mb-1">
              {{ total }}
            </BaseText>
            <BaseText type="heading" size="overline" class="text-text-normal">
              ADS RUNNING
            </BaseText>
          </div>
          <!-- Faded inner ring for chart (purely for visuals) -->
          <svg xmlns="http://www.w3.org/2000/svg" width="160" height="115" viewBox="0 0 160 115" fill="none"
          class="absolute left-1/2 transform -translate-x-1/2 pointer-events-none" style="bottom: 71px">
            <path d="M7.94855 113.598C2.29824 101.481 -0.204902 88.1346 0.670687 74.7935C1.54628 61.4525 5.77231 48.5477 12.9579 37.273C20.1434 25.9983 30.0563 16.7179 41.7797 10.2902C53.503 3.86258 66.658 0.495338 80.0278 0.500005C93.3975 0.504672 106.55 3.88109 118.269 10.3169C129.988 16.7527 139.894 26.0401 147.072 37.3198C154.25 48.5995 158.467 61.5072 159.333 74.8489C160.199 88.1906 157.687 101.535 152.028 113.648" 
            stroke="url(#paint0_linear_1613_10321)" style="" stroke-linecap="round"/>
            <defs>
              <linearGradient id="paint0_linear_1613_10321" x1="80" y1="-2.43076e-08" x2="80" y2="160" gradientUnits="userSpaceOnUse">
                <stop stop-color="#ECEFF3"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="flex items-center gap-3 px-5 py-2 transition-opacity duration-200"
        :class="hoveredSlice && hoveredSlice !== 0 ? 'opacity-30' : 'opacity-100'">
          <div class="w-2 h-2 rounded-sm transition-colors duration-200" 
          :style="{backgroundColor: hoveredSlice === 0 ? '#ED615A' : '#5C667A'}" />
          <BaseText type="body" size="sm" class="text-text-muted">
            Videos
          </BaseText>
          <BaseText type="body" size="sm" class="ml-auto text-text-muted">
            {{ series[0] }}
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal w-7">
            {{ percents[0] || 0 }}%
          </BaseText>
        </div>
        <div class="flex items-center gap-3 px-5 py-2 transition-opacity duration-200"
        :class="hoveredSlice !== null && hoveredSlice !== 1 ? 'opacity-30' : 'opacity-100'">
          <div class="w-2 h-2 rounded-sm transition-colors duration-200"
          :style="{backgroundColor: hoveredSlice === 1 ? '#ED615A' : '#A4ACB9'}" />
          <BaseText type="body" size="sm" class="text-text-muted">
            Images
          </BaseText>
          <BaseText type="body" size="sm" class="ml-auto text-text-muted">
            {{ series[1] }}
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal w-7">
            {{ percents[1] || 0 }}%
          </BaseText>
        </div>
        <div class="flex items-center gap-3 px-5 py-2 transition-opacity duration-200"
        :class="hoveredSlice !== null && hoveredSlice !== 2 ? 'opacity-30' : 'opacity-100'">
          <div class="w-2 h-2 rounded-sm transition-colors duration-200"
          :style="{backgroundColor: hoveredSlice === 2 ? '#ED615A' : '#DFE1E7'}" />
          <BaseText type="body" size="sm" class="text-text-muted">
            Carousel
          </BaseText>
          <BaseText type="body" size="sm" class="ml-auto text-text-muted">
            {{ series[2] }}
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal w-7">
            {{ percents[2] || 0 }}%
          </BaseText>
        </div>
      </div>
      <!-- LANDING PAGES -->
      <div class="relative flex flex-col border-t border-border-normal pb-10" style="width: 388px">
        <div class="flex items-center gap-2 p-4">
          <LandingPagesIcon class="text-icon-normal" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Landing Pages
          </BaseText>
        </div>
        <!-- Loading state -->
        <div v-if="loadingHooks" class="flex flex-col flex-grow px-2 gap-1.5">
          <div v-for="_ in 7" class="flex items-center gap-2.5 p-2">
            <LinkRoundedIcon class="text-neutral-100" />
            <div class="skeleton w-full h-6 rounded-md mr-3" />
          </div>
        </div>
        <!-- Empty state -->
        <div v-else-if="urlCounts.length < 1" class="absolute left-0 right-0 top-1/2 transform -translate-y-2/3 px-10">
          <BaseText type="heading" size="sm" class="text-text-loud text-center w-full">
            No Active Landing Pages
          </BaseText>
          <BaseText type="body" size="sm" class="text-text-normal text-center w-full mt-2">
            {{ brand.name }} is currently not running any ads with landing pages.
          </BaseText>
        </div>
        <div v-else class="flex flex-col flex-grow px-2 gap-1.5 overflow-y-scroll scrollbar-hide pb-3">
          <!-- Removed until later: @click="selectLandingPage(urlCount.key) and hover:bg-neutral-25" -->
          <div v-for="(urlCount, index) in urlCounts" :key="`landing-${index}`"
          class="group flex items-center gap-1.5 p-2 rounded-md transition-colors border"
          :class="landingPageSelected[urlCount.key] ? 'border-primary-red-100 bg-primary-red-10' : 'border-transparent'">
            <LinkRoundedIcon class="transition-colors flex-shrink-0" 
            :class="landingPageSelected[urlCount.key] ? 'text-primary-red-300' : 'text-text-subdued group-hover:text-icon-normal'" />
            <BaseText type="body" size="sm" class="flex-grow truncate transition-colors text-left mr-6"
            :class="landingPageSelected[urlCount.key] ? 'text-primary-red-300' : 'text-text-normal group-hover:text-text-muted'">
              {{ formatUrl(urlCount.key) || 'No Link' }}
            </BaseText>
            <BaseText type="body" size="sm" class="transition-colors text-right" 
            :class="landingPageSelected[urlCount.key] ? 'text-primary-red-300' : 'text-text-muted'">
              {{ urlCount.doc_count }}
            </BaseText>
            <BaseText type="body" size="sm" class="transition-colors text-right flex-shrink-0" style="width: 30px" 
            :class="landingPageSelected[urlCount.key] ? 'text-primary-red-300' : 'text-text-normal'">
              {{ urlPercents[index] }}%
            </BaseText>
          </div>
        </div>
        <div class="absolute bottom-8 left-0 right-0 h-12 bg-gradient-to-t from-white to-transparent z-10 pointer-events-none" />
        <BaseButton class="absolute bottom-3 left-3 right-3 z-20 bg-white rounded-md view-more-button transition-all duration-200"
        :class="isOpen ? 'opacity-100 text-black' : 'opacity-0 text-transparent'"
        @click="$emit('tabSwitch', 'landing-pages')">
          View All Landing Pages
        </BaseButton>
      </div>
      <!-- HOOKS -->
      <div class="relative flex flex-col flex-grow border-t border-l border-border-normal pb-10">
        <div class="flex items-center gap-2 p-4">
          <HooksIcon class="text-icon-normal" />
          <BaseText type="label" size="sm" class="text-text-muted">
            Top Performing Hooks
          </BaseText>
        </div>
        <div v-if="!loadingHooks && hooks.length > 0" class="flex flex-col flex-grow px-2 gap-1.5 overflow-y-scroll scrollbar-hide pb-5">
          <button v-for="(hookData, index) in hooks" :key="`hook-${index}`" :ref="`hook-${hookData.id}`"
          class="group flex gap-2.5 p-2 rounded-md transition-colors hover:bg-neutral-25"
          @click="expandHook(hookData.ad)">
            <div class="flex-shrink-0" @mouseenter="hoveredHookPreview = hookData" @mouseleave="hoveredHookPreview = null"
            @click.stop.prevent="attemptManualPreviewPlay = !attemptManualPreviewPlay">
              <VoiceIcon :isHovered="hoveredHookPreview === hookData" />
            </div>
            <BaseText type="body" size="sm" class="flex-grow transition-colors text-text-muted group-hover:text-text-loud text-left mr-6">
              "{{ hookData.hook.trim() }}"
            </BaseText>
            <div class="flex items-center gap-1.5 flex-shrink-0 whitespace-nowrap">
              <div class="w-1.5 h-1.5 rounded-full flex-shrink-0" style="background-color: #00A879;" />
              <BaseText type="body" size="sm" class="text-text-muted">
                {{ hookData.liveTime }}
              </BaseText>
            </div>
          </button>
        </div>
        <!-- Loading state -->
        <div v-else-if="loadingHooks" class="flex flex-col flex-grow px-2 gap-1.5">
          <div v-for="_ in 7" class="flex items-center gap-2.5 p-2">
            <VoiceIcon class="opacity-20" />
            <div class="skeleton w-full h-6 rounded-md mr-3" />
          </div>
        </div>
        <!-- Empty state -->
        <div v-else-if="hooks.length < 1" class="absolute left-0 right-0 top-1/2 transform -translate-y-2/3">
          <div class="flex flex-col items-center mx-auto" style="max-width: 400px">
            <BaseText type="heading" size="sm" class="text-text-loud text-center w-full">
              No Hooks to Display
            </BaseText>
            <BaseText type="body" size="sm" class="text-text-normal text-center w-full mt-2">
              {{ brand.name }} is currently not running any video ads with dialogue.
            </BaseText>
          </div>
        </div>
        <div class="absolute bottom-8 left-0 right-0 h-12 bg-gradient-to-t from-white to-transparent z-10 pointer-events-none" />
        <BaseButton class="absolute bottom-3 left-3 right-3 z-20 bg-white rounded-md view-more-button transition-all duration-200"
        :class="isOpen ? 'opacity-100 text-black' : 'opacity-0 text-transparent'"
        @click="$emit('tabSwitch', 'hooks')">
          View All Hooks
        </BaseButton>
      </div>
    </div>
    <!-- Hook video preview -->
    <transition>
      <BasePopupVideoPreview v-if="hoveredHookPreview"
        class="fixed z-50 transform -translate-y-1/2"
        :style="{ top: hookPreviewPosition.y + 'px', left: hookPreviewPosition.x + 'px' }"
        :videoSrc="hoveredHookPreview.video"
        :attemptManualPlay="attemptManualPreviewPlay"
      />
    </transition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import ApexCharts from 'apexcharts'
import FirebaseAPI from '@/api/firebase'
import ForeplayAPI from '@/api/foreplayServer'
import { getHook } from '../../utils/adFunctions'

// Icons
import AnalyticsIcon from '../globals/Icons/SpyderV2Icons/AnalyticsIcon.vue'
import ChevronIcon from '../globals/Icons/ChevronIcon.vue'
import ActiveAdsMediaMixIcon from '../globals/Icons/SpyderV2Icons/ActiveAdsMediaMixIcon.vue'
import LandingPagesIcon from '../globals/Icons/SpyderV2Icons/LandingPagesIcon.vue'
import LinkRoundedIcon from '../globals/Icons/LinkRoundedIcon.vue'
import HooksIcon from '../globals/Icons/SpyderV2Icons/HooksIcon.vue'
import VoiceIcon from '../globals/Icons/SpyderV2Icons/VoiceIcon.vue'
import ScrapingLoadingIcon from '../globals/Icons/SpyderV2Icons/ScrapingLoadingIcon.vue'

export default {
  name: 'ContentAnalysis',
  components: {
    AnalyticsIcon,
    ChevronIcon,
    ActiveAdsMediaMixIcon,
    LandingPagesIcon,
    LinkRoundedIcon,
    HooksIcon,
    VoiceIcon,
    ScrapingLoadingIcon
  },
  data () {
    return {
      brand: {},
      isOpen: true,
      selectedFormats: [],
      adsSaved: 155,
      series: [],
      percents: [],
      total: 0,
      urlCounts: [],
      urlPercents: [],
      selectedLandingPages: new Set(), // Use a set for O(1)
      updateSelectedLandingPages: 0,
      hooks: [],
      hoveredSlice: null,
      hoveredHookPreview: null,
      hookPreviewPosition: { x: 0, y: 0},
      attemptManualPreviewPlay: false,
      loadingHooks: true,
      loadingBrand: true,
      chartOptions: {
        labels: ['Videos', 'Images', 'Carousel'],
        chart: {
          type: 'donut',
          events: {
            dataPointMouseEnter: (_, __, config) => {
              this.hoveredSlice = config.dataPointIndex
            },
            dataPointMouseLeave: (_, __, ___) => {
              this.hoveredSlice = null
            }
          }
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            startAngle: -115,
            endAngle: 115,
            offsetY: 20,
            donut: {
              size: '77%',
              labels: {
                show: false
              }
            }
          }
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 3
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'none'
            }
          }
        },
        colors: ['#7E889B', '#C0C7D1', '#EBEFF3']
      },
    }
  },
  computed: {
    landingPageSelected () {
      const version = this.updateSelectedLandingPages // trigger reactivity
      const statusMap = {}
      this.urlCounts.forEach(url => {
        statusMap[url.key] = this.selectedLandingPages.has(url.key)
      })
      return statusMap
    }
  },
  watch: {
    hoveredHookPreview (hookData) {
      if (!hookData) return
      const hookElement = this.$refs[`hook-${hookData.id}`][0]
      const hookRect = hookElement.getBoundingClientRect()
      this.hookPreviewPosition = {
        x: hookRect.left - 126,
        y: hookRect.top + window.scrollY + (hookRect.height / 2)
      }
    }
  },
  async mounted () {
    this.brand = await FirebaseAPI.Brands.get(this.$route.params.id)
    this.loadingBrand = false
    if (!this.brand.analytics) return

    // series
    const brandDisplayFormatCounts = this.brand.analytics.brandDisplayFormatCounts
    this.series = [brandDisplayFormatCounts.videos.doc_count, brandDisplayFormatCounts.images.doc_count, brandDisplayFormatCounts.carousels.doc_count]
    this.percents = this.getPercents(this.series)
    this.total = this.series.reduce((a, b) => a + b, 0)

    // landing pages
    const analytics = this.brand.analytics
    this.urlCounts = analytics.urlCounts
    this.urlPercents = this.getPercents(this.urlCounts.map(url => url.doc_count))

    // chart
    this.$nextTick(() => {
      const chartContainer = document.querySelector('#active-ads-chart')
      if (!chartContainer?.children.length > 0) { // Prevent re-rendering
        const chart = new ApexCharts(chartContainer, { ...this.chartOptions, series: this.series, labels: this.chartOptions.labels })
        chart.render()
      }
    })

    // hooks - Get the top 25 longest running active hooks
    try {
      const { results } = await ForeplayAPI.Ads.getSpyderAds(null, {
        sort: 'longest',
        transciptionOnly: true,
        orFilters: {
          liveStatus: [{ value: 'live' }],
          brands: [{ brandId: this.$route.params.id }]
        },
        size: 100
      })
      // TODO: WE SHOULD NOT BE FILTERING HERE, BACKEND SHOULD RETURN ONLY ADS WITH TRANSCRIPTIONS
      this.hooks = results.filter(ad => ad.timestampedTranscription.length > 0).map(ad => ({
        id: ad.id,
        ad: ad,
        video: ad.video,
        liveTime: `${getHookLiveDays(ad.startedRunning)} days`,
        hook: getHook(ad)
      }))
    } catch (error) {
      console.log('ERROR FETCHING HOOKS')
      console.error(error)
    }
    console.log('HOOKS FETCHED')
    this.loadingHooks = false
  },
  methods: {
    ...mapMutations('AdvertisementsModule', ['SET_AD_TO_OPEN']),
    getPercents (series) {
      const total = series.reduce((a, b) => a + b, 0)
      console.log('total:>>', total, 'series :>>', series)
      return series.map((item) => {
        return Math.round((item / total) * 100)
      })
    },
    selectLandingPage(url) {
      if (this.selectedLandingPages.has(url))
        this.selectedLandingPages.delete(url)
      else
        this.selectedLandingPages.add(url)
      this.updateSelectedLandingPages++ // trigger reactivity
    },
    expandHook (advertisement) {
      this.SET_AD_TO_OPEN(advertisement) // Triggers the ad details drawer in AdvertisementList
    },
    formatUrl (url) {
      // Remove protocol (http/https) and www
      return url.replace(/(^\w+:|^)\/\//, '').replace('www.', '')
    }
  }
}

const getHookLiveDays = (startedRunning) => {
  const now = +new Date()
  const diff = now - startedRunning
  return Math.floor(diff / (1000 * 60 * 60 * 24)) // Convert ms to days
}

</script>

<style scoped>
.view-more-button {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}
.v-enter-active, .v-leave-active {
  transition: opacity 150ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}
</style>

<style>
#active-ads-chart .apexcharts-pie-area {
  transition: fill 150ms ease-in-out;
}

#active-ads-chart .apexcharts-pie-area:hover {
  fill: #FF5453;
}
</style>
