<template>
  <div>
    <!-- Label -->
    <div
      v-if="label"
      class="mb-1 text-text-muted flex gap-1"
    >
      <BaseText
        type="label"
        size="sm"
      >
        {{ label }}
      </BaseText>
      <BaseText
        type="body"
        size="sm"
        class=" text-text-subdued"
      >
        {{ optional ? ' (Optional)' : '' }}
      </BaseText>
    </div>
    <!-- Input -->
    <div
      class="input-container flex items-center group rounded-md py-1.5 transition-all ease-in duration-75"
      :class="{
        'errored': errored,
        'border-border-normal': disabled,
        'px-0': noPadding,
        'px-2': !noPadding,
        'border focus-within:border-green-500  input-container-green': getTheme === 'green',
        'border focus-within:border-purple-500 input-container-purple': getTheme === 'purple',
        'border focus-within:border-blue-500 input-container-blue': getTheme === 'blue'
      }"
    >
      <div
        v-if="icon"
        class="mr-2"
      >
        <slot
          name="icon"
        />
      </div>

      <input
        :maxlength="maxlength"
        class="input w-full text-body-sm h-5 focus:outline-none bg-white"
        :class="{
          'text-text-disabled cursor-not-allowed placeholder-text-disabled': disabled,
          'text-text-loud': !disabled
        }"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="updateValue"
      >
    </div>

    <!-- Hint -->
    <div
      v-if="!errored && hint"
      class="py-1 mt-1.5"
      :class="{
        'text-text-disabled': disabled,
        'text-text-normal': !disabled
      }"
    >
      <BaseText
        type="body"
        size="xs"
      >
        {{ hint }}
      </BaseText>
    </div>

    <!-- Error -->
    <div
      v-if="errored"
      class="py-1 flex items-center mt-1.5 text-secondary-red-100"
    >
      <img
        src="../../assets/icons/warning.svg"
        class="mr-1"
      >
      <BaseText
        type="body"
        size="xs"
      >
        {{ errorMessage }}
      </BaseText>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BaseInputV2',
  props: {
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    errored: {
      type: Boolean
    },
    errorMessage: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    maskFunction: {
      type: Function,
      default: val => val
    },
    noPadding: {
      type: Boolean
    },
    maxlength: {
      type: Number,
      default: null
    },
    optional: {
      type: Boolean,
      default: false
    }
  },
  computed: { ...mapGetters('MiscModule', ['getTheme']) },
  methods: {
    updateValue (e) {
      if (this.inputType === 'number') {
        this.$emit('input', Number(e.target.value))
      } else {
        this.$emit('input', this.maskFunction ? this.maskFunction(e.target.value) : e.target.value)
      }
    }
  }
}
</script>
<style scoped>
.input-container {
  box-shadow: 0px 1px 2px rgba(18, 55, 105, 0.08), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
}

.input-container-green:focus-within {
  outline: 2px solid #00A87950;
  outline-offset: 1.5px;
}

.input-container-purple:focus-within {
  outline: 2px solid #6434df50;
  outline-offset: 1.5px;
}

.input-container-blue:focus-within {
  outline: 2px solid #1F69FF50;
  outline-offset: 1.5px;
}

.errored {
  box-shadow: 0px 1px 2px rgba(150, 19, 44, 0.32), 0px 0px 0px 1px rgba(221, 3, 45, 0.9), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px rgba(223, 28, 65, 0.24);
}

</style>
