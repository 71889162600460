// Imports
import Axios from "axios";

let AxiosConfig
if (process.env.NODE_ENV === 'development') {
  // Axios Config
  AxiosConfig = Axios.create({
    baseURL: 'https://foreplay-dev-67500.ue.r.appspot.com'
    // baseURL: 'http://localhost:5050'
  })
} else {
  // Axios Config
  AxiosConfig = Axios.create({
    // baseURL: 'http://localhost:5050'
    baseURL: 'https://api.foreplay.co'
  })
}

export default AxiosConfig;