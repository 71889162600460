<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M9.79167 3.12499H4.79167C3.87119 3.12499 3.125 3.87118 3.125 4.79166V15.2083C3.125 16.1288 3.87119 16.875 4.79167 16.875H15.2083C16.1288 16.875 16.875 16.1288 16.875 15.2083V10.2083M16.9151 3.08491C17.9728 4.14258 17.9728 5.8574 16.9151 6.91507C15.8574 7.97274 14.1426 7.97274 13.0849 6.91507C12.0272 5.8574 12.0272 4.14258 13.0849 3.08491C14.1426 2.02724 15.8574 2.02724 16.9151 3.08491Z" 
    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'ActiveAdsMediaMixIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      }
    }
  }
</script>

<style scoped></style>