<template>
  <BaseCard
    class="h-max mt-5 w-4/5 pt-5 flex-col justify-between items-center pb-3"
    :no-padding="true"
  >
    <div class="flex items-center w-full border-b mb-4">
      <img
        src="../../assets/icons/new-folder-icon.svg"
        class="ml-3 w-15 h-15"
      >
      <div class="ml-3 -mt-3.5">
        <BaseText
          type="label"
          size="md"
          class="text-neutral-900 font-medium"
        >
          Integrations & Notifications
        </BaseText>

        <BaseText
          type="body"
          size="sm"
          class="text-text-normal w-52 overflow-visible whitespace-nowrap"
        >
          Build Foreplay into your Workflow
        </BaseText>
      </div>
    </div>
    <div
      class="flex w-full ml-px mt-2"
    >
      <img
        src="../../assets/images/instagram-image.png"
        alt=""
        class="ml-3 w-12"
      >
      <div class="flex-col  items-center">
        <BaseText
          type="label"
          size="md"
          class="text-text-muted w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Connect Instagram
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Save Ads Using the Foreplay Mobile App
        </BaseText>
      </div>
      <!-- <BaseButton
        class="ml-auto mr-2 mt-1.5"
        outlined
      >
        <div
          class="flex items-center -mx-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            class="mr-1"
          >
            <path
              d="M7.28993 13.7784V15.2503C7.28993 15.7105 7.66303 16.0836 8.12327 16.0836H11.8752C12.3355 16.0836 12.7086 15.7105 12.7086 15.2503V13.7784M7.28993 13.7784C6.94617 13.6057 6.62117 13.4011 6.3188 13.1686C4.8825 12.0639 3.95685 10.3281 3.95685 8.37602C3.95685 5.03889 6.66212 2.33362 9.99925 2.33362C13.3364 2.33362 16.0417 5.03889 16.0417 8.37602C16.0417 10.3281 15.116 12.0639 13.6797 13.1686C13.3773 13.4011 13.0523 13.6057 12.7086 13.7784M7.28993 13.7784H12.7086M8.12499 18.5836H11.875"
              stroke="#5E6678"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted"
          >
            Tutorial
          </BaseText>
        </div>
      </BaseButton> -->
      <BaseButton
        v-if="!getTeam"
        class="mr-6 mt-1.5 ml-auto"
        outlined
        @click=" () => $emit('showModal')"
      >
        <div
          class="flex items-center -mx-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            class="mr-1"
          >
            <path
              d="M14.9559 17.4308C13.7775 18.1616 12.3875 18.5835 10.8989 18.5835C6.64172 18.5835 3.19058 15.1324 3.19058 10.8752C3.19058 6.61801 6.64172 3.16687 10.8989 3.16687C15.1561 3.16687 18.6072 6.61801 18.6072 10.8752C18.6072 12.5249 17.7874 14.2287 15.8751 14.0549C14.302 13.9118 13.1697 12.48 13.3931 10.9162L13.8344 7.87752M13.3468 11.2756C13.0906 13.0986 11.5898 14.3948 9.99464 14.1706C8.39949 13.9464 7.31406 12.2868 7.57027 10.4638C7.82648 8.64072 9.32731 7.34459 10.9225 7.56878C12.5176 7.79296 13.603 9.45256 13.3468 11.2756Z"
              stroke="#808899"
              stroke-width="1.25"
              stroke-linecap="round"
            />
          </svg>
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted"
          >
            {{ getUser.igUsername ? 'Change IG' : 'Connect IG' }}
          </BaseText>
        </div>
      </BaseButton>
      <BaseButton
        v-else
        class="mr-6 mt-1.5 ml-auto"
        outlined
        @click="$emit('showModal')"
      >
        <div
          class="flex items-center -mx-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            class="mr-1.5"
          >
            <path
              d="M11.9406 6.08349L14.3037 3.72033C14.9546 3.06946 16.0099 3.06946 16.6608 3.72033L18.0537 5.11331C18.7046 5.76418 18.7046 6.81946 18.0537 7.47033L15.6906 9.83349M11.9406 6.08349L3.67874 14.3453C3.36618 14.6579 3.19058 15.0818 3.19058 15.5238V18.5835H6.25023C6.69225 18.5835 7.11618 18.4079 7.42874 18.0953L15.6906 9.83349M11.9406 6.08349L15.6906 9.83349"
              stroke="#808899"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted"
          >
            Edit Connection
          </BaseText>
        </div>
      </BaseButton>
    </div>
    <InstagramChart
      v-if="getTeam"
      :team="team"
      @showIgModal="$emit('showModal')"
    />
    <div
      class="flex w-full ml-px mt-2"
    >
      <img
        src="../../assets/images/gmail-image.png"
        alt=""
        class="ml-3 w-12"
      >
      <div class="flex-col  items-center">
        <BaseText
          type="label"
          size="md"
          class="text-text-muted w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Get personalized inspo sent to your inbox!
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          As you use Foreplay these emails will become more personalized to you.
        </BaseText>
      </div>
      <BaseButton
        class="ml-auto mr-6 mt-1.5 bg-background-disabled"
        disabled
      >
        <div
          class="flex items-center -mx-2"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-disabled"
          >
            Coming Soon
          </BaseText>
        </div>
      </BaseButton>
    </div>
    <div
      class="flex w-full ml-px mt-2"
    >
      <img
        src="../../assets/images/slack-image.png"
        alt=""
        class="ml-3 w-12"
      >
      <div class="flex-col  items-center">
        <BaseText
          type="label"
          size="md"
          class="text-text-muted w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Slack Report Cards & Notifications
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Keep track of inspiration across your entire team.
        </BaseText>
      </div>
      <BaseButton
        class="ml-auto mr-6 mt-1.5 bg-background-disabled"
        disabled
      >
        <div
          class="flex items-center -mx-2"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-disabled"
          >
            Coming Soon
          </BaseText>
        </div>
      </BaseButton>
    </div>
    <div
      class="flex w-full ml-px mt-2"
    >
      <img
        src="../../assets/images/notion-image.png"
        alt=""
        class="ml-3 w-12"
      >
      <div class="flex-col  items-center">
        <BaseText
          type="label"
          size="md"
          class="text-text-muted w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Embed Content in Notion
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Keep track of inspiration across your entire team.
        </BaseText>
      </div>
      <!-- <BaseButton
        class="ml-auto mr-6 mt-1.5"
        outlined
      >
        <div
          class="flex items-center -mx-2"
        >
          <img
            src="../../assets/icons/external-tab.svg"
            alt=""
          >
          <BaseText
            type="body"
            size="sm"
            class="text-text-muted ml-2"
          >
            Read the Guide
          </BaseText>
        </div>
      </BaseButton> -->
      <BaseButton
        class="ml-auto mr-6 mt-1.5 bg-background-disabled"
        disabled
      >
        <div
          class="flex items-center -mx-2"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-disabled"
          >
            Coming Soon
          </BaseText>
        </div>
      </BaseButton>
    </div>
    <div
      class="flex w-full ml-px mt-2"
    >
      <img
        src="../../assets/images/zapier-image.png"
        alt=""
        class="ml-3 w-12"
      >
      <div class="flex-col  items-center">
        <BaseText
          type="label"
          size="md"
          class="text-text-muted w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Integrate Everywhere with Zapier
        </BaseText>
        <BaseText
          type="body"
          size="sm"
          class="text-text-normal w-52 overflow-visible whitespace-nowrap ml-3 "
        >
          Keep track of inspiration across your entire team.
        </BaseText>
      </div>
      <BaseButton
        class="ml-auto mr-6 mt-1.5 bg-background-disabled"
        disabled
      >
        <div
          class="flex items-center -mx-2"
        >
          <BaseText
            type="body"
            size="sm"
            class="text-text-disabled"
          >
            Coming Soon
          </BaseText>
        </div>
      </BaseButton>
    </div>
  </BaseCard>
</template>
<script>
import { mapGetters } from 'vuex'
import InstagramChart from './InstagramChart.vue'
export default {
  name: 'IntegrationsCard',
  components: {
    InstagramChart
  },
  props: {
    team: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser', 'getTeam'])
  }
}
</script>
<style scoped>
