<template>
  <transition
    enter-active-class="transition-all duration-500 ease-out"
    enter-class="transform translate-y-full opacity-0"
    enter-to-class="transform translate-y-0 opacity-100"
    leave-active-class="transition-all duration-500 ease-in"
    leave-class="transform translate-y-0 opacity-100"
    leave-to-class="transform translate-y-full opacity-0"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'BaseCardTransition'
}
</script>
