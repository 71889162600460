<template>
  <!-- Manage Board Modal -->
  <BaseModal @close="$emit('close')">
    <template #toolbar>
      <div class="bg-white pt-4 flex items-center rounded-md">
        <div>
          <div
            class="text-lg font-semibold"
          >
            Are you sure you want to delete the {{ selectedAds.length > 1 ? selectedAds.length : "" }} selected {{ selectedAds.length > 1 ? 'Ads' : 'Ad' }}?
          </div>

          <div
            class="text-sm text-gray-500"
          >
            Once deleted, ads can not be recovered.
          </div>
        </div>
      </div>
    </template>

    <template #default>
      <div class="px-4">
        <div class="flex items-center justify-end">
          <BaseButton
            outlined
            class="mr-2"
            @click="$emit('close')"
          >
            Cancel
          </BaseButton>
          <BaseButton
            class="bg-red-100 text-red-600"
            @click="$emit('confirm')"
          >
            Delete
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>

export default {
  name: 'ConfirmBulkDeleteModal',
  props: {
    folder: Boolean,
    board: Boolean,
    selectedAds: {
      type: Array,
      default: () => []
    }
  }
}
</script>
