// Imports
import AxiosConfig from '../config/AxiosConfig'
import Bugsnag from '@bugsnag/js'

// Fetch AD
const fetchAdvertisement = (adURL) => new Promise((resolve, reject) => {
  const payload = {
    api_key: '55d831a7-3bcb-47dd-9099-0c233bc1f27d',
    ad_url: adURL
  }

  return AxiosConfig.get('/getFBAd', { params: payload })
    .then(data => {
      if (!data) {
        resolve({})
      } else {
        resolve(data.data)
      }
    })
    .catch(e => {
      Bugsnag.notify(e)
      reject(adURL)
    })
})

const createFiltersAndBrandData = (adId) => {
  return AxiosConfig.post('/createFiltersAndBrandData', {
    adId
  })
}

export default {
  fetchAdvertisement,
  createFiltersAndBrandData
}
