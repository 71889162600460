<template>
  <!-- Manage Board Modal -->
  <BaseModal
    no-padding
    @close="$emit('close')"
  >
    <template #toolbar>
      <div class="text-black w-full flex flex-col items-center font-medium justify-center">
        <span class="text-xl">Update your Payment Method</span>
      </div>
    </template>

    <template #default>
      <div class="p-4">
        <!-- Divider -->
        <div class="border p-2 rounded-md">
          <StripeElements
            #default="{ elements }"
            ref="cardElms"
            :stripe-key="stripeKey"
            :instance-options="instanceOptions"
            :elements-options="elementsOptions"
          >
            <StripeElement
              ref="card"
              type="card"
              :elements="elements"
              :options="cardOptions"
            />
          </StripeElements>
        </div>

        <div class="flex justify-between">
          <div
            class="pt-3 text-right flex justify-end"
          >
            <BaseButton
              primary
              :loading="loadingPaymentUpdate"
              :disabled="loadingPaymentUpdate"
              @click="updatePaymentMethod"
            >
              Update Address
            </BaseButton>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'
import { mapGetters } from 'vuex'
import API from '@/api'

export default {
  name: 'UpdatePaymentMethodModal',
  components: {
    StripeElements,
    StripeElement
  },
  props: {
  },
  data () {
    return {
      stripeKey: 'pk_live_51JS7DeAQtq0TW7KZxoGv8Spu6IJhR335IoLxdfBQMF8VCbWJFDrUl2DfeNJllyC79mZJv8kX0qiaNGFCgbrTznpA00kPK2gMwN',
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
      },
      cardOptions: {
        style: {
          base: {
            fontSize: '1.2rem'
          }
        }
      },
      loadingPaymentUpdate: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUserSubscription', 'getStripeCustomer', 'getUser'])
  },
  methods: {
    async updatePaymentMethod () {
      this.loadingPaymentUpdate = true
      try {
        // ref in template
        const groupComponent = this.$refs.cardElms
        const cardElement = groupComponent.elements.getElement('card')

        // Make an api call to create a payment intent using the current setup intent
        const stripe = groupComponent.instance

        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement
        })
        if (error) {
          console.log(error)
          this.$showAlert({
            message: error.message,
            type: 'error'
          })
        } else {
          await API.Stripe.updatePaymentMethod(
            paymentMethod.id,
            this.getStripeCustomer.stripeId
          )

          this.$showAlert({
            message: 'Your payment method has been updated.',
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
        this.$showAlert({
          message: error,
          type: 'error'
        })
      }
      this.loadingPaymentUpdate = false
    }
  }
}
</script>

<style scoped></style>
