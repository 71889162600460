<template>
  <div
    class="flex justify-center items-center cursor-pointer rounded p-1"
    :class="{ 'hover:bg-gray-100': !noHover}"
    @click="$emit('click')"
  >
    <div
      v-if="!active"
      style="padding: 0 2.4px"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="15"
        width="15"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
      </svg>
    </div>
    <lottie
      v-else
      :options="defaultOptions"
      :height="width"
      :width="width"
      @animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie.vue'
import animationData from '../../../assets/lotties/favorite.json'

export default {
  name: 'FavoriteIcon',
  components: {
    lottie: Lottie
  },
  props: {
    active: {
      type: Boolean,
      default: () => false
    },
    noHover: {
      type: Boolean,
      default: () => false
    },
    width: {
      type: Number,
      default: () => 20
    }
  },
  data () {
    return {
      defaultOptions: { animationData: animationData, loop: false, autoplay: false }
    }
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim
      this.anim.setSpeed(0.4)
      this.play()
    },
    play () {
      this.anim.play()
    },
    reset () {
      this.anim.stop()
    }
  }
}
</script>
