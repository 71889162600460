<template>
  <!-- Manage Board Modal -->
  <BaseModal @close="$emit('close')">
    <template #toolbar>
      <div class="bg-white pt-4 flex items-center rounded-md">
        <div>
          <div class="text-lg font-semibold">
            Want to upload a file?
          </div>

          <div
            class="text-sm text-gray-500"
          >
            Leave your name and email so {{ name }} knows who uploaded the file.
          </div>
        </div>
      </div>
    </template>

    <!-- 1.06222222222, 0.30222222222, 0.30222222222 -->

    <template #default>
      <div class="px-4">
        <!-- Body Content -->
        <BaseInput
          v-model="fullName"
          icon
          placeholder="Name"
          class="mb-4"
        >
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
          </template>
        </BaseInput>

        <BaseInput
          v-model="email"
          icon
          placeholder="Email"
          class="mb-4"
        >
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
          </template>
        </BaseInput>

        <div class="flex justify-end">
          <BaseButton
            outlined
            class="mr-2"
            @click="$emit('close')"
          >
            Cancel
          </BaseButton>
          <BaseButton
            primary
            :disabled="!fullName || !email"
            @click="saveUserInfo"
          >
            Continue
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>

export default {
  name: 'GetUserInfoModal',
  props: {
    name: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      fullName: null,
      email: null
    }
  },
  methods: {
    saveUserInfo () {
      const { fullName: name, email } = this
      localStorage.userInfo = JSON.stringify({ name, email })

      this.$emit('save')
      this.$emit('close')
    }
  }
}
</script>
