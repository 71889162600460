<template>
  <!-- Billing history -->
  <section
    v-if="getUserInvoices.length"
    aria-labelledby="billing-history-heading"
    class="mt-5"
  >
    <div class="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
      <div class="px-4 sm:px-6">
        <h2
          id="billing-history-heading"
          class="text-lg leading-6 font-medium text-gray-900"
        >
          Invoices
        </h2>
      </div>
      <div class="mt-6 flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden border-t border-gray-200">
              <table
                v-if="!loading"
                class="min-w-full divide-y divide-gray-200"
              >
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Amount
                    </th>
                    <!--
                            `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                          -->
                    <th
                      scope="col"
                      class="relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <span class="sr-only">View receipt</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="(invoice, index) in invoices"
                    :key="index"
                  >
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <time datetime="2020-01-01">{{ new Date(invoice.created * 1000).toLocaleDateString('en-US') }}</time>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ invoice.lines.data[0].description }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ formatMoney(invoice.amount_paid/100) }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a
                        :href="invoice.recieptUrl"
                        target="_blank"
                        class="text-orange-600 hover:text-orange-900"
                      >View Invoice</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="py-7 px-5 overflow-hidden border-t border-gray-200">
              <p class="mt-1 text-sm text-gray-500">
                Click the button to retrieve all historical invoices or edit VAT information.
              </p>
              <a :href="'https://zenvoice.io/p/65d3580c42a0dad9d67fe601?email=' + getStripeCustomer.email" target="_blank">
                <BaseButton primary class="">
                    Edit & Download Invoices
                </BaseButton>
              </a>

              <p class="mt-1 text-sm text-gray-500">
                Powered By Zenvoice
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@/api'

export default {
  name: 'Invoices',
  components: {
  },
  data: () => {
    return {
      invoices: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getStripeCustomer', 'getUserInvoices'])
  },
  mounted () {
    this.getInvoices()
  },
  methods: {
    async getInvoices () {
      const sortedInvoices = [...this.getUserInvoices].sort((a, b) => a.created < b.created ? 1 : -1)
      const invoicesWithReciepts = await Promise.all(sortedInvoices.map(async invoice => {
        const invoiceCopy = { ...invoice }
        if (invoiceCopy.charge) {
          const data = await API.Stripe.getHostedReciept(invoiceCopy.charge)
          invoiceCopy.recieptUrl = data.receipt_url
        }
        return invoiceCopy
      }))
      this.invoices = invoicesWithReciepts
      this.loading = false
    },
    formatMoney (amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })

      return formatter.format(amount)
    }
  }
}
</script>
