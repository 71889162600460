var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('div',{staticClass:"mb-1 text-text-muted flex gap-1"},[_c('BaseText',{attrs:{"type":"label","size":"sm"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('BaseText',{staticClass:"text-text-subdued",attrs:{"type":"body","size":"sm"}},[_vm._v(" "+_vm._s(_vm.optional ? ' (Optional)' : '')+" ")])],1):_vm._e(),_c('div',{staticClass:"input-container flex items-center group rounded-md py-1.5 transition-all ease-in duration-75",class:{
      'errored': _vm.errored,
      'border-border-normal': _vm.disabled,
      'px-0': _vm.noPadding,
      'px-2': !_vm.noPadding,
      'border focus-within:border-green-500  input-container-green': _vm.getTheme === 'green',
      'border focus-within:border-purple-500 input-container-purple': _vm.getTheme === 'purple',
      'border focus-within:border-blue-500 input-container-blue': _vm.getTheme === 'blue'
    }},[(_vm.icon)?_c('div',{staticClass:"mr-2"},[_vm._t("icon")],2):_vm._e(),_c('input',{staticClass:"input w-full text-body-sm h-5 focus:outline-none bg-white",class:{
        'text-text-disabled cursor-not-allowed placeholder-text-disabled': _vm.disabled,
        'text-text-loud': !_vm.disabled
      },attrs:{"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.updateValue}})]),(!_vm.errored && _vm.hint)?_c('div',{staticClass:"py-1 mt-1.5",class:{
      'text-text-disabled': _vm.disabled,
      'text-text-normal': !_vm.disabled
    }},[_c('BaseText',{attrs:{"type":"body","size":"xs"}},[_vm._v(" "+_vm._s(_vm.hint)+" ")])],1):_vm._e(),(_vm.errored)?_c('div',{staticClass:"py-1 flex items-center mt-1.5 text-secondary-red-100"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../assets/icons/warning.svg")}}),_c('BaseText',{attrs:{"type":"body","size":"xs"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }