<template>
  <div class="flex items-center gap-0.5 cursor-pointer"
  @mouseleave="hoveredStar = null">
    <div v-for="index in 5" :key="index" class="star-wrapper"
    :class="{'star-clicked': doAnimateClick && selectedRating === index}" 
    @click.stop="setRating(index)" @mouseenter="hoveredStar = index">
      <RatingStarIcon :isActive="activeStars[index - 1]"/>
    </div>
  </div>
</template>

<script>
  import RatingStarIcon from '../../globals/Icons/RatingStarIcon.vue';

  export default {
    name: 'RatingSelector',
    components: {
      RatingStarIcon
    },
    props: {
      adRating: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        selectedRating: this.adRating,
        hoveredStar: null,
        doAnimateClick: false,
      }
    },
    computed: {
      activeStars () {
        const arr = new Array(5).fill(false);
        if (this.hoveredStar) return arr.map((_, i) => i < this.hoveredStar)
        return arr.map((_, i) => i < this.selectedRating)
      }
    },
    methods: {
      setRating (newRating) {
        this.doAnimateClick = true
        this.selectedRating = newRating
        setTimeout(() => {
          this.doAnimateClick = false
        }, 300)
        this.$emit('updateAdRating', newRating)
      },
    }
  }
</script>

<style scoped lang="css">
  .star-wrapper {
    transition: transform 0.15s ease-in-out;
    transform: scale(1);
  }
  .star-wrapper:hover {
    transform: scale(1.15);
  }
  .star-wrapper.star-clicked {
    animation: starPop 0.3s ease-in-out;
  }

  @keyframes starPop {
    0% {
      transform: scale(1.15);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1.15);
    }
  }
</style>