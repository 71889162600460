<template>
  <svg xmlns="http://www.w3.org/2000/svg" :class="className" :width="width" :height="height" viewBox="0 0 20 20" fill="none">
    <path d="M4.69114 16.149L5.43959 16.1007L4.69114 16.149ZM15.309 16.149L14.5606 16.1007L14.5606 16.1007L15.309 16.149ZM2.29175 4.04169C1.87753 4.04169 1.54175 4.37747 1.54175 4.79169C1.54175 5.2059 1.87753 5.54169 2.29175 5.54169V4.04169ZM17.7084 5.54169C18.1226 5.54169 18.4584 5.2059 18.4584 4.79169C18.4584 4.37747 18.1226 4.04169 17.7084 4.04169V5.54169ZM8.87508 8.95835C8.87508 8.54414 8.5393 8.20835 8.12508 8.20835C7.71087 8.20835 7.37508 8.54414 7.37508 8.95835H8.87508ZM7.37508 13.5417C7.37508 13.9559 7.71087 14.2917 8.12508 14.2917C8.5393 14.2917 8.87508 13.9559 8.87508 13.5417H7.37508ZM12.6251 8.95835C12.6251 8.54414 12.2893 8.20835 11.8751 8.20835C11.4609 8.20835 11.1251 8.54414 11.1251 8.95835H12.6251ZM11.1251 13.5417C11.1251 13.9559 11.4609 14.2917 11.8751 14.2917C12.2893 14.2917 12.6251 13.9559 12.6251 13.5417H11.1251ZM12.5021 4.97863C12.6053 5.37977 13.0142 5.62126 13.4154 5.51801C13.8165 5.41477 14.058 5.00588 13.9547 4.60474L12.5021 4.97863ZM3.20997 4.83997L3.9427 16.1973L5.43959 16.1007L4.70686 4.7434L3.20997 4.83997ZM6.35435 18.4584H13.6458V16.9584H6.35435V18.4584ZM16.0575 16.1973L16.7902 4.83997L15.2933 4.7434L14.5606 16.1007L16.0575 16.1973ZM16.0417 4.04169H3.95841V5.54169H16.0417V4.04169ZM2.29175 5.54169H3.95841V4.04169H2.29175V5.54169ZM16.0417 5.54169H17.7084V4.04169H16.0417V5.54169ZM13.6458 18.4584C14.9201 18.4584 15.9754 17.4689 16.0575 16.1973L14.5606 16.1007C14.5295 16.5831 14.1292 16.9584 13.6458 16.9584V18.4584ZM3.9427 16.1973C4.02474 17.4689 5.08007 18.4584 6.35435 18.4584V16.9584C5.871 16.9584 5.47071 16.5831 5.43959 16.1007L3.9427 16.1973ZM7.37508 8.95835V13.5417H8.87508V8.95835H7.37508ZM11.1251 8.95835V13.5417H12.6251V8.95835H11.1251ZM10.0001 3.04169C11.2028 3.04169 12.2153 3.86426 12.5021 4.97863L13.9547 4.60474C13.5015 2.84373 11.9038 1.54169 10.0001 1.54169V3.04169ZM7.49811 4.97863C7.78493 3.86426 8.7974 3.04169 10.0001 3.04169V1.54169C8.0964 1.54169 6.4987 2.84373 6.04545 4.60474L7.49811 4.97863Z" 
    class="fill-current"/>
  </svg>
</template>
<!-- fill="#5E6678" -->
<script>
  export default {
    name: 'DeleteIcon',
    props: {
      width: {
        type: Number,
        default: 20
      },
      height: {
        type: Number,
        default: 20
      },
      className: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style scoped></style>