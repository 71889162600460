<template>
  <svg
    :width="width"
    :height="width*1.11"
    viewBox="0 0 35 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="transitionClass"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.31914 4.93487C5.31914 2.51309 3.02155 1.95574 1.87276 1.83155C1.37194 1.83155 0.829914 1.83155 0.829914 1.34686C0.829914 0.845218 1.20181 0.830204 2.08401 0.82607C7.20593 0.802022 17.6383 0.786189 20.1778 0.843906C23.3523 0.916052 28.4025 1.18059 31.2154 4.82023C34.8493 9.92353 32.6253 13.3787 31.3743 14.9474C30.1233 16.5161 27.2326 17.678 26.6573 17.7964C26.0819 17.9149 25.7096 18.1349 25.7435 18.4733C25.7773 18.8117 26.0565 18.9217 26.5751 19.0763C29.9793 19.9928 34.9219 22.2514 34.9219 28.0123C34.7648 36.4966 25.0711 38.5165 20.518 38.461C18.2759 38.4593 15.4384 38.4545 12.6371 38.4497L12.6299 38.4497H12.6277C7.25944 38.4405 2.02651 38.4315 1.37337 38.4457C0.703505 38.4602 0.537113 37.398 1.51827 37.398C4.38704 37.398 5.1006 35.6359 5.26078 35.0097C5.38893 34.5088 5.41643 33.0099 5.41415 32.3231C5.38248 24.2027 5.31914 7.35665 5.31914 4.93487ZM24.5749 14.88C20.403 14.88 16.1185 16.1609 14.4978 16.8014C14.1846 16.9198 14.214 16.7776 14.2678 16.6918C17.6816 12.9064 24.5522 5.29397 24.7246 5.12735C24.897 4.96072 24.9304 5.08669 24.9255 5.1705C24.9298 5.42637 24.9247 5.90099 24.9188 6.4522C24.9085 7.4144 24.8957 8.60997 24.9255 9.28275C24.9951 10.8502 25.2905 12.3627 25.4456 13.1566L25.4766 13.3158C25.6192 14.0527 25.359 14.8212 24.5749 14.88ZM14.1744 20.3399V33.795C14.1744 34.754 14.7339 36.954 18.5708 36.2802C22.4077 35.6065 25.2341 32.8225 25.1299 28.341C25.0541 25.0849 23.8551 19.9117 15.9592 19.9303H14.6036C14.3731 19.9304 14.1744 19.9929 14.1744 20.3399Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BriefIcon',
  props: {
    selected: {
      type: Boolean
    },
    noTransition: {
      type: Boolean,
      default: () => false
    },
    fill: {
      type: String,
      default: () => 'currentColor'
    },
    width: {
      type: Number,
      default: () => 35
    }
  },
  computed: {
    ...mapGetters('MiscModule', ['getTheme', 'getLastTheme', 'getThemeChangedAt']),
    transitionClass () {
      let classStr = ''
      const colorClass = this.selected ? 'text-white' : `text-${this.getTheme}-logo`

      if (+new Date() - this.getThemeChangedAt < 1000 && !this.noTransition) {
        // So buttons mounted after the theme changes dont animate
        classStr += ` ${this.getLastTheme}-to-${this.getTheme}`
      }

      if (!this.fill) {
        classStr += ' fill-current'
      }

      if (!this.noTransition) {
        classStr += ` ${colorClass}`
      }

      return classStr
    }
  }
}
</script>
<style scoped lang="css">
/* Blue to Purple Animations */
.blue-to-purple {animation: b-to-p 500ms ease-in; animation-iteration-count: 1; }

@keyframes b-to-p {
  0% { color: #3A6FFB; }
  100% { color: #834BB5; }
}

/* Purple to Blue Animations */
.purple-to-blue {animation: p-to-b 500ms ease-in; animation-iteration-count: 1;}

@keyframes p-to-b {
  0% { color: #834BB5; }
  100% { color: #3A6FFB; }
}

/* Purple to Green Animations */
.purple-to-green {animation: p-to-g 500ms ease-in; animation-iteration-count: 1;}

@keyframes p-to-g {
  0% { color: #834BB5; }
  100% { color: #14C78C; }
}

/* Blue to Green Animations */
.blue-to-green {animation: b-to-g 500ms ease-in; animation-iteration-count: 1;}

@keyframes b-to-g {
  0% { color: #3A6FFB; }
  100% { color: #14C78C; }
}

/* Green to Purple Animations */
.green-to-purple {animation: g-to-p 500ms ease-in; animation-iteration-count: 1;}

@keyframes g-to-p {
  0% { color: #14C78C; }
  100% { color: #834BB5; }
}

/* Green to Blue Animations */
.green-to-blue {animation: g-to-b 500ms ease-in; animation-iteration-count: 1;}

@keyframes g-to-b {
  0% { color: #14C78C; }
  100% { color: #3A6FFB; }
}
</style>
