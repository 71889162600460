// Imports
import firebase from '../config/FirebaseConfig'

// Setup
const db = firebase.firestore()

// Get All By User
const getAllByUser = async () => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('tags').where('created_by', '==', user.uid).get()

  return snapshot.docs?.map(doc => {
    const data = doc.data() || []
    return {
      id: doc.id,
      color: 'gray', // If the color is not defined, default to gray
      ...data
    }
  })
}

const getAllByTeam = async (teamId) => {
  const snapshot = await db.collection('tags').where('teamId', '==', teamId).get()

  return snapshot.docs?.map(doc => {
    const data = doc.data() || []
    return {
      id: doc.id,
      color: 'gray', // If the color is not defined, default to gray
      ...data
    }
  })
}

// Get All By User
const getAdsByTagId = async (tagId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const snapshot = await db.collection('ads').where('tags', 'array-contains', tagId).get()

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      ...doc.data() || []
    }
  })
}

const getTagsArray = async (tagsToGet) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  const tags = await Promise.all(
    tagsToGet.map(async tagId => {
      const tag = await db.collection('tags').doc(tagId).get()

      return {
        id: tag.id,
        color: 'gray', // If the color is not defined, default to gray
        ...tag.data()
      }
    })
  )

  return tags.filter(tag => tag.name);
}

const getTagByName = async (tagName, teamId) => {
  const user = firebase.auth().currentUser

  if (!user) return []

  let snapshot
  if (teamId) {
    snapshot = await db.collection('tags').where('teamId', '==', teamId).where('name', '==', tagName).get()
  } else {
    snapshot = await db.collection('tags').where('created_by', '==', user.uid).where('name', '==', tagName).get()
  }

  return snapshot.docs?.map(doc => {
    return {
      id: doc.id,
      color: 'gray', // If the color is not defined, default to gray
      ...doc.data() || []
    }
  })
}

const update = async (tagId, payload) => {
  const user = firebase.auth().currentUser
  if (!user) return []

  await db.collection('tags').doc(tagId).update(payload)
}

const create = async (tagName, teamId, tagColor = 'gray') => {
  const user = firebase.auth().currentUser

  const payload = { name: tagName, color: tagColor, created_by: user.uid, created_at: new Date().getTime() }

  if (teamId) {
    payload.teamId = teamId
  }

  const { id } = await db.collection('tags').add(payload)

  return { ...payload, id }
}

// named deleteTag to avoid conflict with JS delete keyword
const deleteTag = async (tagId) => {
  const user = firebase.auth().currentUser
  if (!user) return []

  const tagAds = await getAdsByTagId(tagId)

  // Remove the tag from all ads that have it
  await Promise.all(tagAds.map(ad => {
    const newTags = ad.tags.filter(tag => tag !== tagId)
    return db.collection('ads').doc(ad.id).update({ tags: newTags })
  }));

  await db.collection('tags').doc(tagId).delete()
}

export default {
  update,
  create,
  deleteTag,
  getAdsByTagId,
  getAllByUser,
  getTagByName,
  getAllByTeam,
  getTagsArray,
}
